import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactNullValue } from '../../../../../../shared/utils/Nulls'
import PlantGoalCard from '../PlantGoalCard'

const messages = defineMessages({
    progress: {
        defaultMessage: 'plant goal',
        description: 'title of the plant goal card'
    }
})

export type YourPlantGoal = {
    size?: 'small' | 'large'
    thresholdValue: number
    thresholdAchieved: number
    error: boolean
}

const YourPlantGoal: React.FC<YourPlantGoal> = ({
    size = 'small',
    thresholdValue,
    thresholdAchieved,
    error
}) => {
    const { formatMessage } = useIntl()

    const displayedStat = useMemo(() => {
        return thresholdAchieved > thresholdValue
            ? thresholdValue
            : thresholdAchieved
    }, [thresholdAchieved, thresholdValue])

    if (error) return ReactNullValue

    return (
        <PlantGoalCard
            title={formatMessage(messages.progress)}
            stat={displayedStat}
            threshold={thresholdValue}
            size={size}
        />
    )
}

export default memo(YourPlantGoal)
