// External libraries
import React, { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Stack, useMediaQuery } from '@mui/material'
import {
    ConfettiExplosion,
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'

// Components
import GardenPresent from '../../../../../../Shared/GardenPreset'

export type CompletedPlantThresholdDialogProps = {
    open: boolean
    onClose: () => void
}

const CompletedPlantThresholdDialog: React.FC<
    CompletedPlantThresholdDialogProps
> = ({ open, onClose }) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const [showConfetti, setShowConfetti] = useState<boolean>(true)

    return (
        <LeafDialog
            open={open}
            maxWidth="sm"
            onClose={() => onClose()}
            PaperProps={{
                sx: {
                    position: 'relative',
                    overflow: 'hidden'
                }
            }}
        >
            <Stack
                px={isMobile ? 0 : 4}
                pb={isMobile ? 1.5 : 5}
                alignItems="center"
                justifyContent="center"
                data-testid="completed-plant-threshold-dialog"
            >
                <Stack gap={4} alignItems="center" justifyContent="center">
                    <Box
                        maxWidth={isMobile ? '233px' : '269.92px'}
                        maxHeight={isMobile ? '167px' : '202.44px'}
                    >
                        <GardenPresent />
                    </Box>
                    <Stack gap={1} px={isMobile ? 0 : 4} pb={isMobile ? 3 : 0}>
                        <CoreTypography variant="h3" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Way to dig deep!"
                                description="Completed goal dialog title"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" textAlign="center">
                            <FormattedMessage
                                defaultMessage="You reached your plant goal for the challenge. When the challenge is complete, lookout for a special reward in your personal garden."
                                description="Completed goal dialog description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Button
                        variant="contained"
                        fullWidth={isMobile}
                        onClick={() => onClose()}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Keep growing"
                                description="Completed goal dialog button"
                            />
                        </CoreTypography>
                    </Button>
                </Stack>
            </Stack>
            {open && (
                <Stack
                    position="absolute"
                    top="25%"
                    left="50%"
                    zIndex={2}
                    sx={{ transform: 'translate(-50%, -50%)' }}
                >
                    <ConfettiExplosion
                        show={showConfetti}
                        onComplete={() => setShowConfetti(false)}
                    />
                </Stack>
            )}
        </LeafDialog>
    )
}

export default memo(CompletedPlantThresholdDialog)
