import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useCreateSocialGroupMutation } from '../../../graphql/generated/autogenerated'
import SocialGroupAction from '../SocialGroupAction/SocialGroupAction'

export type CreateSocialGroupProps = {
    displayNameExists: boolean
    onSubmit: () => void
}

const messages = defineMessages({
    createGroup: {
        defaultMessage: 'Create Your Group',
        description: 'title of the create group popup'
    },
    finishCreateGroup: {
        defaultMessage: 'Finish Creating Your Group',
        description:
            'title of the create group popup which appears after the display name popup'
    },
    betterWithFriends: {
        defaultMessage:
            'Challenges are better with friends. Reminder: Anyone from your company will be able to join and see this group was created by you.',
        description:
            'message explaining that social groups are publicly available to anyone within the same company'
    },
    recommend: {
        defaultMessage: 'We recommend using a name your co-workers would know.',
        description:
            'description recommending users to use the same name they use at work for their group name'
    },
    yourGroupName: {
        defaultMessage: 'Your Group Name',
        description: 'placeholder text for the group name text field'
    }
})

const CreateSocialGroup: React.FC<CreateSocialGroupProps> = ({
    displayNameExists,
    onSubmit
}) => {
    const intl = useIntl()
    const [socialGroup, setSocialGroup] = React.useState<string>()

    const [createSocialGroup, { loading: createSocialGroupLoading }] =
        useCreateSocialGroupMutation({
            variables: {
                newSocialGroup: {
                    name: String(socialGroup)
                }
            }
        })

    function onHandleSubmit() {
        onSubmit()
    }

    const setActionValue = useCallback(
        (value: any) => setSocialGroup(value),
        []
    )

    return (
        <Box data-testid="create-social-group">
            <SocialGroupAction
                loading={createSocialGroupLoading}
                title={
                    displayNameExists
                        ? intl.formatMessage(messages.createGroup)
                        : intl.formatMessage(messages.finishCreateGroup)
                }
                description={intl.formatMessage(messages.betterWithFriends)}
                caption={intl.formatMessage(messages.recommend)}
                actionValue={socialGroup}
                testId="create-social-group"
                inputPlaceholder={intl.formatMessage(messages.yourGroupName)}
                onSubmitComplete={onHandleSubmit}
                mutation={createSocialGroup}
                onMutationComplete={(response) =>
                    response?.data?.socialGroups?.createSocialGroup
                }
                setActionValue={setActionValue}
            />
        </Box>
    )
}

export default CreateSocialGroup
