// External imports
import { Box, LinearProgress, Stepper, SxProps, Theme } from '@mui/material'
import { defineMessage, useIntl } from 'react-intl'

type LeafStepperProps = {
    /** The total number of steps, 1-indexed */
    totalSteps: number
    /** The current step, 1-indexed */
    currentStep: number
    sx?: SxProps<Theme>
}

const STEP_ARIA_LABEL = defineMessage({
    defaultMessage: 'step {stepNumber, number} of {totalSteps, number}',
    description: 'Label for the current step in the process'
})

const StepperComponent = ({
    totalSteps,
    currentStep,
    sx
}: LeafStepperProps) => {
    const intl = useIntl()
    return (
        <Stepper
            sx={{
                columnGap: 2,
                // to disable the transition animation of the progress bar
                '& .MuiLinearProgress-bar': {
                    transitionDuration: '.25s',
                    transitionTimingFunction: 'ease'
                },
                ...sx
            }}
            activeStep={currentStep}
            aria-label={intl.formatMessage(STEP_ARIA_LABEL, {
                stepNumber: currentStep,
                totalSteps
            })}
            role="progressbar"
        >
            {Array.from({ length: totalSteps }, (_, index) => {
                const isStepCompleted = currentStep > index
                return (
                    <Box
                        key={`step-${index}`}
                        sx={{
                            width: '100%',
                            height: '10px',
                            borderRadius: '10px'
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={isStepCompleted ? 100 : -1}
                            color="success"
                        />
                    </Box>
                )
            })}
        </Stepper>
    )
}

export default StepperComponent
