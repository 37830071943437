import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useFeelingIcon } from '../../../../hooks/useFeelingIcon/useFeelingIcon'
import FeelingIconAvatar from '../DailyGoals/Goals/FeelingGoal/FeelingIconAvatar'
import WaveCard from '../WaveCard'
import type { FeelingQuestionStats } from '../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    averageSleep: {
        defaultMessage: 'Average Sleep Reflection',
        description: 'average sleep reflection title'
    }
})

export type AverageSleepReflectionProps = {
    hideTitle?: boolean
    elevation?: number
    data: FeelingQuestionStats
}

const AverageSleepReflection = ({
    hideTitle,
    elevation,
    data
}: AverageSleepReflectionProps) => {
    const { formatMessage } = useIntl()
    const getFeelingIcon = useFeelingIcon()

    const { icon, label } = useMemo(
        () => getFeelingIcon(data?.averageAnswer),
        [getFeelingIcon, data]
    )

    return (
        <WaveCard
            {...(!hideTitle && {
                title: formatMessage(messages.averageSleep)
            })}
            elevation={elevation}
        >
            <FeelingIconAvatar icon={icon} label={label} />
        </WaveCard>
    )
}

export default memo(AverageSleepReflection)
