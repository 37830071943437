import {
    Button,
    DialogActions,
    DialogContent,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export type WhereIsMyActivityProps = {
    open: boolean
    onClose: () => void
}

const WhereIsMyActivity: React.FC<WhereIsMyActivityProps> = ({
    open,
    onClose
}) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={isSmallScreen}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Where is my activity?"
                        description="Where's my activity title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <Stack gap={2} component="output" aria-live="polite" pt={1.5}>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            defaultMessage="Depending on your device, steps can take up to an hour to sync to Thrive. Opening your steps tracker app on your phone is a fast way of triggering a sync."
                            description="depending on device description"
                        />
                    </CoreTypography>
                    <Stack>
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                defaultMessage="Using Apple Health?"
                                description="title on how to sync apple health data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Open the Thrive Global app on your phone and navigate to your Challenge page. This automatically syncs your Apple Health data with the Thrive Global app."
                                description="description on how to sync apple health data"
                            />
                        </CoreTypography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Close"
                            description="close popup button text"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default WhereIsMyActivity
