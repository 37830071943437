import { SvgIcon, SvgIconProps } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

const QuoteIcon: React.FC<SvgIconProps> = (props) => {
    const { palette } = useTheme()
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 48 44'}
            sx={{ height: '100%', width: '100%' }}
            fill={props.fill}
        >
            <path
                opacity="0.1"
                d="M15.6674 0.714844L19.4624 7.97485C11.2124 11.6049 9.56238 15.2348 9.56238 23.3199V23.8149H20.1224V43.2848H0.817383V24.9698C0.817383 11.7698 4.28238 5.49985 15.6674 0.714844ZM42.7274 0.714844L46.5224 7.97485C38.2724 11.6049 36.6224 15.2348 36.6224 23.3199V23.8149H47.1824V43.2848H27.8774V24.9698C27.8774 11.7698 31.3424 5.49985 42.7274 0.714844Z"
                fill={palette.common.black}
            />
        </SvgIcon>
    )
}

export default QuoteIcon
