import { useMemo } from 'react'
import { Measurements } from '../../enums/measurements'
import { MeasureUnit } from '../../graphql/generated/autogenerated'
import { useIntl } from 'react-intl'

const OUNCE_AS_CUP = 8
const MILLILITER_AS_CUP = 250

export const useGlassesMeasurementConversion = (
    glasses: number,
    intakeMeasurement?: MeasureUnit
) => {
    const { formatNumber } = useIntl()

    return useMemo(() => {
        const format = (measurement: number, unit: Measurements) =>
            formatNumber(measurement ?? 0, {
                style: 'unit',
                unit,
                unitDisplay: 'short'
            })

        switch (intakeMeasurement) {
            case MeasureUnit.Oz:
                return format(glasses * OUNCE_AS_CUP, Measurements.ounce)
            case MeasureUnit.Ml:
                return format(
                    glasses * MILLILITER_AS_CUP,
                    Measurements.milliliter
                )
            default:
                return format(
                    glasses * MILLILITER_AS_CUP,
                    Measurements.milliliter
                )
        }
    }, [intakeMeasurement, glasses, formatNumber])
}

export default useGlassesMeasurementConversion
