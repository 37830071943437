import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const GardenPresent: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            aria-hidden="true"
            width="270"
            height="203"
            viewBox="0 0 270 203"
            sx={{ height: '100%', width: '100%' }}
        >
            <g clipPath="url(#clip0_2220_188838)">
                <path
                    d="M199.412 140.782C201.012 135.409 199.665 129.663 199.221 124.076C198.064 109.391 203.667 94.3093 214.121 83.9432C218.052 80.044 222.632 76.7709 226.119 72.4755C228.988 68.9409 231.065 64.7881 234.1 61.3962C237.143 58.0042 241.629 55.3572 246.099 56.2448C251.107 57.2434 254.373 62.4581 254.8 67.5461C255.228 72.634 253.453 77.6189 251.67 82.3978C249.879 87.1767 248.024 92.114 248.27 97.2099C248.54 102.892 251.424 108.448 250.394 114.043C248.936 121.92 240.504 126.47 236.969 133.658C233.363 140.996 235.313 150.126 231.73 157.473C228.703 163.678 221.65 167.672 214.771 167.062"
                    fill="#F7F7F8"
                />
                <path
                    d="M212.338 162.299C212.338 162.299 212.29 162.299 212.274 162.299C212.116 162.259 212.013 162.101 212.052 161.942C218.916 132.477 227.514 103.074 237.611 74.5598C237.666 74.4013 237.841 74.322 237.991 74.3775C238.15 74.433 238.229 74.5994 238.174 74.7579C228.085 103.257 219.486 132.635 212.631 162.077C212.599 162.212 212.48 162.307 212.338 162.307V162.299Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M231.128 180.123C227.808 183.887 223.14 186.431 218.178 187.192C219.296 184.862 219.613 179.877 218.939 177.381L229.131 170.129L231.128 180.123Z"
                    fill="#7C9B6A"
                />
                <path
                    d="M231.12 180.21C230.97 178.593 229.519 177.333 227.958 176.897C226.397 176.461 224.732 176.652 223.124 176.858C219.526 177.309 215.928 177.769 212.322 178.221C210.784 178.419 209.049 178.554 207.852 177.563C206.631 176.549 206.465 174.718 206.782 173.165C207.907 167.522 213.851 163.876 219.589 163.488C223.734 163.211 228.172 164.415 230.89 167.554C233.609 170.692 234.092 177.309 231.12 180.21Z"
                    fill="#9AB557"
                />
                <path
                    d="M173.948 94.7372C171.341 92.6767 168.123 91.6226 164.906 90.7113C165.199 90.6954 165.484 90.6875 165.777 90.6637C170.461 90.2833 175.184 88.5477 178.101 85.0606C181.017 81.5736 181.628 76.1369 178.735 72.5072C176.318 69.4719 172.173 68.291 168.274 67.6332C163.598 66.8407 159.16 65.6044 154.769 64.8119C160.119 62.8147 164.993 59.5575 168.757 55.2621C172.466 51.03 175.058 44.4442 171.642 39.9586C168.622 35.996 162.726 36.0039 157.789 36.7093C152.693 37.4463 147.645 38.5321 142.699 39.9507C143.357 30.4643 143.103 20.8907 141.907 11.4122C141.407 7.43378 140.361 2.91645 136.716 0.911385C133.736 -0.72912 129.853 -0.0079318 127.166 1.94165C124.479 3.89124 122.807 6.84732 121.539 9.85095C117.743 18.8618 117.038 29.0932 119.526 38.643C114.755 36.7093 109.747 35.3541 104.651 34.6487C102.051 34.2842 99.3487 34.1019 96.8919 34.9024C94.4351 35.7028 92.2716 37.7158 92.0814 40.2201C91.8278 43.4457 94.7125 46.1085 97.4388 48.0819C102.241 51.5689 107.369 54.6518 112.726 57.2671C107.004 56.9897 101.259 57.3622 95.6635 58.4004C91.7089 59.1295 87.1599 60.8493 86.4624 64.6534C85.7413 68.6001 89.6642 71.9207 93.2939 74.0209C99.3408 77.5159 105.847 80.258 112.584 82.2076C106.759 81.6528 100.799 82.4612 95.3545 84.6168C91.5583 86.1226 87.5957 88.8805 87.2708 92.9541C87.0727 95.4584 88.3724 97.8994 90.2031 99.6112C92.0338 101.331 94.34 102.433 96.6542 103.415C117.664 112.339 141.217 113.147 163.986 111.562C166.736 111.372 169.526 111.134 172.086 110.112C174.646 109.09 176.96 107.124 177.76 104.493C178.838 100.943 176.865 97.0355 173.956 94.7372H173.948Z"
                    fill="#F7F7F8"
                />
                <path
                    d="M124.567 185.044C124.4 185.044 124.273 184.902 124.273 184.743C125.755 126.525 128.727 67.5698 133.102 9.50223C133.118 9.33581 133.26 9.22485 133.419 9.22485C133.585 9.2407 133.704 9.38336 133.696 9.54186C129.322 67.6015 126.35 126.549 124.868 184.759C124.868 184.917 124.733 185.052 124.567 185.052V185.044Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M188.847 140.624C195.687 138.674 202.653 136.661 208.534 132.675C214.414 128.689 219.137 122.301 219.288 115.192C219.502 104.889 210.364 95.078 213.661 85.3142C216.173 77.8725 225.2 73.6722 226.539 65.9372C227.538 60.1439 223.782 54.5805 223.465 48.7079C223.155 43.0177 226.088 37.5652 226.04 31.867C225.969 24.1717 219.668 17.0073 212.044 15.9612C204.42 14.9151 196.424 20.1061 194.276 27.5002C192.683 33.0003 194.038 39.1423 191.827 44.4204C188.776 51.7274 179.908 55.1273 176.397 62.2362C171.285 72.5864 179.631 85.7739 174.899 96.3064C172.197 102.314 165.825 105.943 162.758 111.768C158.304 120.232 163.289 132.184 172.45 134.965"
                    fill="#F7F7F8"
                />
                <path
                    d="M193.61 126.279C193.444 126.279 193.317 126.145 193.309 125.986C192.961 98.5176 197.066 71.3264 205.498 45.1813C205.546 45.0228 205.72 44.9436 205.871 44.9911C206.029 45.0387 206.116 45.2051 206.061 45.3636C197.644 71.4453 193.555 98.5731 193.896 125.978C193.896 126.145 193.769 126.279 193.603 126.279H193.61Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M130.178 53.6215L130.098 53.5977C125.557 52.0603 120.834 50.4752 116.102 48.8902C111.403 47.3131 106.703 45.736 102.194 44.2144C102.035 44.1589 101.956 43.9925 102.012 43.834C102.067 43.6755 102.233 43.5962 102.392 43.6517C106.901 45.1812 111.601 46.7583 116.293 48.3275C120.992 49.9046 125.7 51.4817 130.217 53.0113C135.757 51.8463 141.193 50.6179 146.456 49.4212C151.742 48.2245 157.21 46.9882 162.782 45.8152C162.948 45.7835 163.099 45.8866 163.13 46.0451C163.162 46.2036 163.059 46.3621 162.9 46.3938C157.337 47.5588 151.869 48.803 146.591 49.9997C141.304 51.1964 135.836 52.4327 130.265 53.6057L130.185 53.6215H130.178Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M132.817 79.8063C131.454 79.8063 130.178 79.7825 128.997 79.727C119.265 77.3495 109.168 73.8624 100.522 69.8998C100.371 69.8285 100.308 69.6542 100.379 69.5036C100.45 69.353 100.625 69.2896 100.775 69.3609C109.398 73.3077 119.447 76.7868 129.092 79.1485C134.489 79.3863 142.2 78.99 149.658 78.6096C157.123 78.2292 164.85 77.8329 170.326 78.0786C170.493 78.0786 170.62 78.2213 170.612 78.3877C170.612 78.5462 170.469 78.673 170.311 78.673C170.311 78.673 170.303 78.673 170.295 78.673C164.842 78.4273 157.131 78.8236 149.681 79.204C143.817 79.5051 137.794 79.8142 132.825 79.8142L132.817 79.8063Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M172.347 100.99C172.347 100.99 172.331 100.99 172.324 100.99C159.873 99.8489 128.125 99.0326 127.808 99.0247C127.562 98.9771 107.971 94.9987 97.2011 93.1125C97.0426 93.0808 96.9316 92.9302 96.9633 92.7717C96.995 92.6132 97.1456 92.5023 97.3041 92.534C108.082 94.4202 127.681 98.4065 127.879 98.4461C128.149 98.4461 159.913 99.2624 172.387 100.412C172.553 100.427 172.672 100.57 172.656 100.737C172.641 100.887 172.514 101.006 172.363 101.006L172.347 100.99Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M119.811 124.211C118.179 119.17 112.473 116.793 107.313 115.596C103.303 114.669 99.2219 114.09 95.1167 113.829C96.3926 113.678 97.6686 113.512 98.9366 113.29C103.723 112.458 108.613 110.738 111.807 107.077C115.001 103.415 115.881 97.4159 112.695 93.7466C109.802 90.4101 104.817 90.0059 100.403 90.2278C93.9596 90.5527 87.5561 91.7098 81.3903 93.6356C88.9351 87.1766 95.3703 79.4338 100.3 70.8191C101.94 67.9502 103.454 64.8356 103.422 61.5308C103.391 58.2261 101.338 54.7231 98.1045 54.0654C95.5763 53.5502 93.0482 54.8182 90.845 56.1655C83.752 60.5085 77.4357 66.1195 72.2764 72.6498C74.5509 62.4105 75.177 51.8067 74.0913 41.3693C73.489 35.5601 71.2065 28.586 65.4529 27.5874C61.9499 26.9772 58.3995 29.0853 56.3627 32.0017C54.3339 34.9182 53.5493 38.5162 53.05 42.0429C51.4412 53.3917 52.305 65.0892 55.5702 76.0814C50.0147 68.7982 41.9152 63.4884 33.0152 61.3169C30.1384 60.6115 27.0714 60.2311 24.2262 61.0791C21.3811 61.9271 18.8292 64.2412 18.4329 67.1815C17.9495 70.7874 20.6678 73.9813 23.3386 76.446C29.2983 81.9619 36.0188 86.6377 43.2466 90.3388C38.4598 90.2833 33.6651 90.9173 29.0685 92.2646C25.4942 93.3107 21.3652 95.8784 21.9438 99.5557C22.3955 102.393 25.4308 103.946 28.1254 104.945C37.6039 108.448 47.5579 110.667 57.6307 111.531C49.3806 112.688 41.2019 114.368 33.1658 116.571C29.8452 117.482 26.0728 118.925 25.0504 122.206C24.361 124.425 25.2248 126.905 26.7464 128.673C28.2681 130.432 30.3524 131.597 32.4525 132.604C41.9786 137.192 59.2792 137.636 98.786 138.936C103.272 139.356 107.797 139.395 111.942 137.747C117.283 135.623 121.571 129.687 119.804 124.219L119.811 124.211Z"
                    fill="#F7F7F8"
                />
                <path
                    d="M72.8233 174.908L72.7678 173.022C71.5711 131.225 68.084 89.0311 62.4017 47.6064C62.3779 47.44 62.4968 47.2973 62.6553 47.2735C62.8138 47.2498 62.9644 47.3686 62.9882 47.5271C68.5913 88.3496 72.0625 129.917 73.3067 171.12L73.6951 169.796C73.7426 169.638 73.9011 169.551 74.0596 169.59C74.2181 169.638 74.3053 169.804 74.2577 169.955L72.8154 174.9L72.8233 174.908Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M68.2742 94.7848L68.0365 94.5867C59.8181 87.7711 44.0154 78.7839 32.4843 72.2219C30.2494 70.9539 28.1889 69.781 26.3819 68.7348C26.2393 68.6556 26.1917 68.4733 26.271 68.3307C26.3502 68.188 26.5325 68.1405 26.6751 68.2197C28.4821 69.2658 30.5426 70.4388 32.7775 71.7068C44.2214 78.2133 59.8577 87.1053 68.1791 93.9289C75.2405 84.5059 87.889 70.1455 96.8127 60.7463C96.9237 60.6274 97.1139 60.6195 97.2327 60.7384C97.3516 60.8493 97.3595 61.0395 97.2407 61.1584C88.2456 70.6369 75.4782 85.1399 68.4644 94.5391L68.2742 94.7927V94.7848Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M69.8672 107.243H69.8117C68.4327 107.005 35.9951 101.624 29.0844 98.6443C28.9338 98.5809 28.8625 98.4066 28.9259 98.256C28.9893 98.1054 29.1636 98.0341 29.3142 98.0975C36.0268 100.99 68.2663 106.371 69.8434 106.633C71.4205 106.276 103.7 98.9059 107.527 98.2877C107.694 98.2639 107.844 98.3749 107.868 98.5334C107.892 98.6919 107.781 98.8504 107.623 98.8742C103.739 99.5003 70.2555 107.148 69.9147 107.219L69.8593 107.235L69.8672 107.243Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M77.9191 129.616C75.605 129.616 73.2829 129.529 70.9529 129.323C60.7612 128.435 51.354 127.088 43.7935 126.01C38.9116 125.312 34.6954 124.71 31.24 124.361C31.0736 124.346 30.9547 124.195 30.9785 124.037C31.0023 123.878 31.137 123.759 31.3034 123.775C34.7667 124.124 38.9908 124.734 43.8806 125.431C51.4333 126.509 60.8246 127.856 71.0084 128.744C81.5489 129.671 92.1844 128.189 100.72 127.001C105.007 126.406 108.716 125.891 111.767 125.748C111.934 125.748 112.069 125.867 112.077 126.034C112.077 126.2 111.958 126.335 111.791 126.343C108.764 126.477 105.071 126.993 100.799 127.587C94.1102 128.514 86.1375 129.624 77.9191 129.624V129.616Z"
                    fill="#DBDDE0"
                />
                <path
                    d="M97.3991 180.646C97.9142 173.188 93.4128 166.182 87.7859 161.261C82.1591 156.339 75.3831 152.94 69.2728 148.636C64.6445 145.371 60.3728 141.567 55.3641 138.92C42.8345 132.287 27.745 133.769 13.6699 135.433C23.1246 143.715 27.959 136.78 32.8488 142.193C37.7386 147.606 41.8914 154.303 48.707 156.902C52.3129 158.281 56.4578 158.392 59.6199 160.603C63.1308 163.068 64.589 167.53 67.5213 170.66C71.579 174.995 77.8319 176.232 83.5618 177.793C89.2917 179.354 95.4812 182.017 97.6923 187.525"
                    fill="#9AB557"
                />
                <path
                    d="M98.5879 178.831L97.1218 177.563C97.1218 177.563 97.0584 177.492 97.0425 177.452C79.8925 162.259 60.5789 149.857 39.6169 140.568C39.4663 140.505 39.4029 140.323 39.4663 140.172C39.5297 140.022 39.712 139.958 39.8547 140.022C61.5062 149.619 81.3983 162.529 98.9763 178.395L98.58 178.839L98.5879 178.831Z"
                    fill="#676864"
                />
                <path
                    d="M101.061 181.113C96.2896 158.78 83.0705 138.365 64.6445 124.877C46.2265 111.38 22.768 104.937 0.0386963 107.124C3.81899 113.702 11.015 117.498 18.108 120.177C25.201 122.856 32.7141 124.845 38.8798 129.267C47.867 135.702 52.8994 146.52 61.7598 153.13C67.244 157.219 73.8456 159.446 79.9876 162.458C86.1296 165.469 92.1765 169.67 94.8076 175.986"
                    fill="#7C9B6A"
                />
                <path
                    d="M90.1793 170.652C90.0842 170.652 89.9891 170.605 89.9337 170.525C73.6475 147.067 51.5046 127.999 25.8826 115.39C25.732 115.319 25.6766 115.137 25.7479 114.994C25.8192 114.851 25.9936 114.788 26.1441 114.859C51.8533 127.516 74.0834 146.647 90.425 170.185C90.5201 170.319 90.4884 170.502 90.3537 170.597C90.2982 170.636 90.2427 170.652 90.1873 170.652H90.1793Z"
                    fill="#676864"
                />
                <path
                    d="M101.631 169.17C102.733 162.798 103.834 156.331 103.272 149.888C102.709 143.445 100.284 136.907 95.3783 132.691C90.6628 128.641 84.1563 127.072 79.6468 122.8C70.2872 113.924 72.9897 97.1861 63.8996 88.0405C59.2237 83.3408 52.416 81.6845 46.2265 79.3307C40.029 76.9769 39.3079 69.0994 32.1118 66.5237C32.2941 78.6967 32.8092 91.8604 40.4253 101.355C44.4909 106.427 50.2446 109.938 54.2626 115.049C60.0004 122.34 61.5775 131.977 65.445 140.418C70.4537 151.331 79.1872 160.009 87.7463 168.433"
                    fill="#9AB557"
                />
                <path
                    d="M92.7709 173.426C92.652 173.426 92.5331 173.355 92.4935 173.228C83.5698 148.628 73.4573 120.756 54.3418 100.198C52.9787 98.7395 51.5443 97.2733 50.1574 95.8627C45.1645 90.7906 40.0052 85.5441 37.1046 78.9662C37.0412 78.8157 37.1046 78.6413 37.2552 78.5779C37.4058 78.5145 37.5801 78.5779 37.6435 78.7285C40.4966 85.2033 45.6242 90.4102 50.5774 95.4505C51.9722 96.8691 53.4067 98.3274 54.7777 99.8015C73.9804 120.446 84.1166 148.383 93.0562 173.03C93.1117 173.18 93.0324 173.355 92.8818 173.41C92.8501 173.426 92.8105 173.426 92.7788 173.426H92.7709Z"
                    fill="#676864"
                />
                <path
                    d="M97.5893 188.444C89.7434 172.855 81.7311 156.633 81.1209 139.189C80.7721 129.196 82.8723 119.02 80.5819 109.288C78.561 100.697 73.275 93.2394 70.3427 84.9101C65.9521 72.4755 67.0062 58.8601 68.1236 45.7202C76.8017 58.2737 85.5114 70.8746 92.3904 84.49C97.5814 94.7531 101.702 105.547 104.873 116.603C110.753 137.073 113.289 159.446 105.871 179.417"
                    fill="#7C9B6A"
                />
                <path
                    d="M97.6924 187.826C97.5418 187.826 97.407 187.707 97.3991 187.549C94.4034 149.579 90.5201 109.652 79.0603 75.5029C79.0049 75.3444 79.092 75.1779 79.2505 75.1304C79.409 75.0749 79.5755 75.1621 79.623 75.3206C91.0986 109.533 94.9899 149.5 97.9856 187.509C98.0014 187.675 97.8746 187.818 97.7161 187.826C97.7161 187.826 97.7003 187.826 97.6924 187.826Z"
                    fill="#676864"
                />
                <path
                    d="M95.83 183.261C93.2623 174.781 86.518 168.013 78.7117 163.805C70.9133 159.597 62.0847 157.679 53.3195 156.403C46.2265 155.373 38.7769 154.762 32.088 157.338C19.281 162.283 13.4322 176.913 3.14539 186.011C13.0597 186.613 20.1924 174.71 30.1226 174.678C36.2408 174.654 42.256 179.259 48.0334 177.246C50.8865 176.247 52.947 173.83 55.1661 171.786C57.3851 169.741 60.2778 167.894 63.2339 168.489C68.1475 169.479 69.5898 176.073 73.8615 178.704C75.7952 179.893 78.1331 180.154 80.3918 180.392C86.9776 181.082 93.5634 181.771 100.149 182.461"
                    fill="#7C9B6A"
                />
                <path
                    d="M97.3516 184.688C97.2644 184.688 97.1852 184.648 97.1218 184.585C87.0964 172.618 75.5099 164.748 63.5984 161.832C49.444 158.36 34.3308 162.545 25.9935 172.237C25.8826 172.364 25.7003 172.372 25.5735 172.269C25.4467 172.166 25.4388 171.976 25.5418 171.849C34.0217 161.99 49.3727 157.734 63.741 161.253C75.7714 164.201 87.4689 172.134 97.5735 184.196C97.6765 184.323 97.6607 184.513 97.5339 184.616C97.4784 184.664 97.4071 184.688 97.3437 184.688H97.3516Z"
                    fill="#676864"
                />
                <path
                    d="M208.058 105.016H91.2334V91.797H149.65H208.058V105.016Z"
                    fill="#B6DDE1"
                />
                <path
                    d="M202.796 190.013H96.4957L96.2897 105.016H202.59L202.796 190.013Z"
                    fill="#87CFD4"
                />
                <path
                    d="M149.444 190.315C149.277 190.315 149.142 190.18 149.142 190.013V91.7891C149.142 91.6226 149.277 91.4879 149.444 91.4879C149.61 91.4879 149.745 91.6226 149.745 91.7891V190.013C149.745 190.18 149.61 190.315 149.444 190.315Z"
                    fill="#343E4E"
                />
                <path
                    d="M149.047 92.0903C148.905 92.0903 148.778 91.9873 148.754 91.8446C148.722 91.6861 148.833 91.5276 148.992 91.4959C149.15 91.4642 149.301 91.4404 149.451 91.4087C149.325 91.3374 149.269 91.1868 149.317 91.0521C153.779 78.0865 160.024 65.8184 167.885 54.5806C170.144 51.355 172.87 47.7332 176.666 45.5538C180.09 43.5963 185.186 42.8196 188.871 45.4983C193.103 48.5733 193.547 54.7074 191.796 59.1692C189.949 63.8688 186.121 67.4985 182.737 70.7003C173.869 79.101 162.829 89.5622 149.095 92.0824C149.079 92.0824 149.063 92.0824 149.039 92.0824L149.047 92.0903ZM183.236 44.365C180.994 44.365 178.743 45.0545 176.96 46.0689C173.267 48.185 170.588 51.7433 168.369 54.9213C160.539 66.1116 154.318 78.3322 149.879 91.2502C149.872 91.2819 149.856 91.3136 149.832 91.3374C163.003 88.5874 173.702 78.4511 182.333 70.2803C185.669 67.1181 189.45 63.536 191.249 58.9632C192.913 54.7153 192.517 48.8903 188.53 45.9897C186.961 44.8485 185.107 44.373 183.244 44.373L183.236 44.365Z"
                    fill="#343E4E"
                />
                <path
                    d="M148.596 92.0902C148.596 92.0902 148.58 92.0902 148.572 92.0902C134.457 91.0996 121.365 84.9735 107.385 72.8084C103.668 69.5828 99.7291 62.2838 100.213 57.7189C100.553 54.5171 103.113 51.5452 106.592 50.3089C109.414 49.3103 112.782 49.3182 116.594 50.3485C124.567 52.4962 132.143 58.3133 137.944 66.7377C143.143 74.2983 146.487 82.9605 149.713 91.3453C149.776 91.4959 149.697 91.6702 149.547 91.7257C149.396 91.7891 149.222 91.7098 149.166 91.5513C145.949 83.2061 142.62 74.5677 137.461 67.0705C131.747 58.765 124.281 53.0272 116.451 50.9191C112.758 49.9205 109.509 49.9047 106.806 50.8636C103.541 52.0207 101.14 54.8024 100.823 57.7743C100.363 62.0936 104.262 69.2817 107.789 72.3487C121.666 84.4187 134.647 90.4973 148.627 91.4879C148.794 91.4959 148.913 91.6385 148.905 91.8049C148.897 91.9634 148.762 92.0823 148.611 92.0823L148.596 92.0902Z"
                    fill="#343E4E"
                />
                <path
                    d="M122.3 144.301C119.534 144.301 116.824 143.493 114.613 141.607C114.486 141.504 114.47 141.313 114.581 141.187C114.684 141.06 114.874 141.044 115.001 141.155C119.193 144.721 127.499 144.927 133.609 139.039C138.665 134.157 140.916 126.945 142.628 120.573C145.212 110.936 147.296 101.054 148.818 91.2026C148.841 91.0441 148.984 90.9252 149.158 90.9569C149.317 90.9807 149.428 91.1313 149.404 91.2977C147.882 101.172 145.79 111.079 143.199 120.732C141.471 127.191 139.188 134.49 134.021 139.475C131.018 142.367 126.588 144.309 122.3 144.309V144.301Z"
                    fill="#343E4E"
                />
                <path
                    d="M189.87 152.599C182.816 152.599 175.335 149.318 169.661 143.501C162.385 136.035 158.843 125.836 156.814 118.806C155.871 115.533 155.031 112.173 154.222 108.931C152.82 103.281 151.361 97.4318 149.285 91.9001C149.23 91.7495 149.309 91.5752 149.459 91.5197C149.618 91.4642 149.784 91.5435 149.84 91.694C151.924 97.2575 153.382 103.122 154.793 108.789C155.601 112.03 156.434 115.39 157.377 118.647C159.39 125.614 162.9 135.726 170.081 143.097C177.094 150.293 186.937 153.526 195.148 151.339C195.306 151.299 195.473 151.394 195.512 151.553C195.552 151.711 195.457 151.878 195.298 151.917C193.547 152.385 191.716 152.615 189.862 152.615L189.87 152.599Z"
                    fill="#343E4E"
                />
                <path
                    d="M202.035 169.075C212.385 156.347 218.266 133.753 218.963 117.364C219.66 100.974 214.937 84.3949 205.712 70.835C195.18 90.8618 184.33 112.347 186.414 134.878C187.532 147.004 193.618 160.27 202.043 169.075H202.035Z"
                    fill="#7C9B6A"
                />
                <path
                    d="M200.886 188.325C200.727 188.325 200.6 188.206 200.592 188.048C200.252 181.969 201.409 170.24 202.748 156.656C204.579 138.088 206.861 114.978 205.879 98.9375C205.871 98.7711 205.989 98.6364 206.156 98.6205C206.314 98.6047 206.465 98.7394 206.473 98.8979C207.464 114.986 205.181 138.119 203.343 156.712C202.003 170.272 200.846 181.977 201.187 188.016C201.195 188.183 201.068 188.317 200.909 188.333C200.909 188.333 200.902 188.333 200.894 188.333L200.886 188.325Z"
                    fill="#676864"
                />
                <path
                    d="M201.171 189.031C201.171 189.031 201.116 189.031 201.092 189.023C200.933 188.975 200.846 188.817 200.886 188.658C202.011 184.68 203.715 179.576 206.877 175.447C210.166 171.152 214.66 168.512 219.201 168.203C219.383 168.188 219.51 168.314 219.518 168.481C219.526 168.647 219.407 168.79 219.24 168.798C214.874 169.091 210.539 171.651 207.345 175.812C204.238 179.861 202.566 184.894 201.448 188.825C201.409 188.959 201.29 189.039 201.163 189.039L201.171 189.031Z"
                    fill="#676864"
                />
                <path
                    d="M203.406 172.887C201.52 170.256 200.394 167.149 200.212 163.916C199.768 155.951 202.859 147.788 207.63 141.242C213.606 133.047 221.943 126.945 230.209 121.065C238.467 115.184 246.915 109.272 253.223 101.339C256.504 117.26 252.97 134.466 243.681 147.812C234.393 161.15 219.478 170.438 203.414 172.887H203.406Z"
                    fill="#9AB557"
                />
                <path
                    d="M205.054 173.03C206.632 166.713 209.057 160.635 212.274 154.976C216.49 147.55 220.77 141.805 229.836 137.723C242.311 132.096 256.6 130.606 269.969 133.531C264.358 135.37 260.158 140.077 256.972 145.054C253.794 150.031 251.329 155.484 247.652 160.104C237.928 172.34 219.351 179.354 205.062 173.038L205.054 173.03Z"
                    fill="#7C9B6A"
                />
                <path
                    d="M201.02 188.048L200.941 186.431C200.941 186.328 200.989 186.217 201.068 186.146C201.892 165.136 212.647 144.468 229.448 131.827C229.575 131.732 229.765 131.756 229.868 131.882C229.963 132.017 229.939 132.199 229.812 132.302C212.655 145.212 201.853 166.563 201.623 188.032H201.028L201.02 188.048Z"
                    fill="#676864"
                />
                <path
                    d="M201.234 188.357C201.234 188.357 201.211 188.357 201.203 188.357C201.036 188.341 200.925 188.191 200.941 188.024C203.026 170.652 214.485 154.358 230.122 146.512C230.264 146.441 230.447 146.496 230.518 146.647C230.589 146.79 230.534 146.972 230.383 147.043C214.921 154.802 203.588 170.914 201.528 188.096C201.512 188.246 201.385 188.357 201.234 188.357Z"
                    fill="#676864"
                />
                <path
                    d="M170.754 191.012C171.238 186.558 174.915 181.977 179.393 182.049C183.878 182.128 187.968 185.987 188.308 190.457C188.839 183.768 194.974 177.991 201.686 177.864C208.399 177.737 214.747 183.269 215.531 189.942C216.181 187.652 218.202 185.813 220.548 185.385C222.894 184.957 226.452 187.414 226.856 191.012"
                    fill="#C7B4A7"
                />
                <path
                    d="M198.833 167.466C195.782 162.093 189.933 158.424 183.767 158.012C177.602 157.6 171.317 160.46 167.576 165.382C159.778 175.637 164.097 190.98 158.201 202.44C167.354 196.892 175.628 189.895 182.618 181.787C184.663 179.417 186.89 176.778 189.981 176.271C192.358 175.883 194.736 176.866 197.145 176.953C199.554 177.04 202.447 175.518 202.241 173.117"
                    fill="#7C9B6A"
                />
                <path
                    d="M170.62 176.739C170.572 176.739 170.524 176.731 170.477 176.707C170.334 176.628 170.279 176.446 170.358 176.303C171.975 173.291 173.647 170.177 176.262 167.561C179.171 164.645 182.983 162.814 186.731 162.553C193.444 162.061 199.245 166.444 201.488 171.017C202.13 172.332 202.605 173.767 202.883 175.281C202.915 175.439 202.804 175.598 202.645 175.629C202.479 175.661 202.328 175.55 202.296 175.392C202.019 173.925 201.567 172.538 200.949 171.278C198.793 166.888 193.254 162.688 186.771 163.147C183.173 163.401 179.496 165.168 176.682 167.989C174.13 170.549 172.482 173.624 170.881 176.596C170.826 176.699 170.723 176.755 170.62 176.755V176.739Z"
                    fill="#676864"
                />
                <path
                    d="M85.8601 191.012C86.082 185.964 90.0208 181.106 95.0771 180.273C100.981 179.307 107.195 184.014 107.852 189.958C109.612 186.946 113.598 185.449 116.903 186.55C119.011 187.255 120.707 188.967 121.563 191.012"
                    fill="#C7B4A7"
                />
                <path
                    d="M92.6758 193.809C92.549 193.809 92.4301 193.722 92.3905 193.595C91.2968 189.879 87.7067 186.97 83.8392 186.669C79.9718 186.368 75.9775 188.706 74.337 192.216L74.0755 192.771L73.7981 192.216C72.744 190.093 70.3269 188.642 67.9731 188.714C65.5956 188.777 63.2814 190.346 62.3462 192.526C62.2828 192.676 62.1085 192.74 61.9579 192.684C61.8073 192.621 61.736 192.446 61.7994 192.296C62.8455 189.871 65.3182 188.191 67.9572 188.119C70.3903 188.072 72.7837 189.371 74.0596 191.448C75.9379 188.008 79.9718 185.765 83.8868 186.067C87.9841 186.376 91.804 189.466 92.9611 193.413C93.0086 193.572 92.9135 193.738 92.763 193.778C92.7313 193.786 92.7075 193.794 92.6758 193.794V193.809Z"
                    fill="#9D8E83"
                />
            </g>
            <defs>
                <clipPath id="clip0_2220_188838">
                    <rect
                        width="269.923"
                        height="202.44"
                        fill="white"
                        transform="translate(0.0386963)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default GardenPresent

{
    /* <svg width="270" height="203" viewBox="0 0 270 203" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2220_188838)">
<path d="M199.412 140.782C201.012 135.409 199.665 129.663 199.221 124.076C198.064 109.391 203.667 94.3093 214.121 83.9432C218.052 80.044 222.632 76.7709 226.119 72.4755C228.988 68.9409 231.065 64.7881 234.1 61.3962C237.143 58.0042 241.629 55.3572 246.099 56.2448C251.107 57.2434 254.373 62.4581 254.8 67.5461C255.228 72.634 253.453 77.6189 251.67 82.3978C249.879 87.1767 248.024 92.114 248.27 97.2099C248.54 102.892 251.424 108.448 250.394 114.043C248.936 121.92 240.504 126.47 236.969 133.658C233.363 140.996 235.313 150.126 231.73 157.473C228.703 163.678 221.65 167.672 214.771 167.062" fill="#F7F7F8"/>
<path d="M212.338 162.299C212.338 162.299 212.29 162.299 212.274 162.299C212.116 162.259 212.013 162.101 212.052 161.942C218.916 132.477 227.514 103.074 237.611 74.5598C237.666 74.4013 237.841 74.322 237.991 74.3775C238.15 74.433 238.229 74.5994 238.174 74.7579C228.085 103.257 219.486 132.635 212.631 162.077C212.599 162.212 212.48 162.307 212.338 162.307V162.299Z" fill="#DBDDE0"/>
<path d="M231.128 180.123C227.808 183.887 223.14 186.431 218.178 187.192C219.296 184.862 219.613 179.877 218.939 177.381L229.131 170.129L231.128 180.123Z" fill="#7C9B6A"/>
<path d="M231.12 180.21C230.97 178.593 229.519 177.333 227.958 176.897C226.397 176.461 224.732 176.652 223.124 176.858C219.526 177.309 215.928 177.769 212.322 178.221C210.784 178.419 209.049 178.554 207.852 177.563C206.631 176.549 206.465 174.718 206.782 173.165C207.907 167.522 213.851 163.876 219.589 163.488C223.734 163.211 228.172 164.415 230.89 167.554C233.609 170.692 234.092 177.309 231.12 180.21Z" fill="#9AB557"/>
<path d="M173.948 94.7372C171.341 92.6767 168.123 91.6226 164.906 90.7113C165.199 90.6954 165.484 90.6875 165.777 90.6637C170.461 90.2833 175.184 88.5477 178.101 85.0606C181.017 81.5736 181.628 76.1369 178.735 72.5072C176.318 69.4719 172.173 68.291 168.274 67.6332C163.598 66.8407 159.16 65.6044 154.769 64.8119C160.119 62.8147 164.993 59.5575 168.757 55.2621C172.466 51.03 175.058 44.4442 171.642 39.9586C168.622 35.996 162.726 36.0039 157.789 36.7093C152.693 37.4463 147.645 38.5321 142.699 39.9507C143.357 30.4643 143.103 20.8907 141.907 11.4122C141.407 7.43378 140.361 2.91645 136.716 0.911385C133.736 -0.72912 129.853 -0.0079318 127.166 1.94165C124.479 3.89124 122.807 6.84732 121.539 9.85095C117.743 18.8618 117.038 29.0932 119.526 38.643C114.755 36.7093 109.747 35.3541 104.651 34.6487C102.051 34.2842 99.3487 34.1019 96.8919 34.9024C94.4351 35.7028 92.2716 37.7158 92.0814 40.2201C91.8278 43.4457 94.7125 46.1085 97.4388 48.0819C102.241 51.5689 107.369 54.6518 112.726 57.2671C107.004 56.9897 101.259 57.3622 95.6635 58.4004C91.7089 59.1295 87.1599 60.8493 86.4624 64.6534C85.7413 68.6001 89.6642 71.9207 93.2939 74.0209C99.3408 77.5159 105.847 80.258 112.584 82.2076C106.759 81.6528 100.799 82.4612 95.3545 84.6168C91.5583 86.1226 87.5957 88.8805 87.2708 92.9541C87.0727 95.4584 88.3724 97.8994 90.2031 99.6112C92.0338 101.331 94.34 102.433 96.6542 103.415C117.664 112.339 141.217 113.147 163.986 111.562C166.736 111.372 169.526 111.134 172.086 110.112C174.646 109.09 176.96 107.124 177.76 104.493C178.838 100.943 176.865 97.0355 173.956 94.7372H173.948Z" fill="#F7F7F8"/>
<path d="M124.567 185.044C124.4 185.044 124.273 184.902 124.273 184.743C125.755 126.525 128.727 67.5698 133.102 9.50223C133.118 9.33581 133.26 9.22485 133.419 9.22485C133.585 9.2407 133.704 9.38336 133.696 9.54186C129.322 67.6015 126.35 126.549 124.868 184.759C124.868 184.917 124.733 185.052 124.567 185.052V185.044Z" fill="#DBDDE0"/>
<path d="M188.847 140.624C195.687 138.674 202.653 136.661 208.534 132.675C214.414 128.689 219.137 122.301 219.288 115.192C219.502 104.889 210.364 95.078 213.661 85.3142C216.173 77.8725 225.2 73.6722 226.539 65.9372C227.538 60.1439 223.782 54.5805 223.465 48.7079C223.155 43.0177 226.088 37.5652 226.04 31.867C225.969 24.1717 219.668 17.0073 212.044 15.9612C204.42 14.9151 196.424 20.1061 194.276 27.5002C192.683 33.0003 194.038 39.1423 191.827 44.4204C188.776 51.7274 179.908 55.1273 176.397 62.2362C171.285 72.5864 179.631 85.7739 174.899 96.3064C172.197 102.314 165.825 105.943 162.758 111.768C158.304 120.232 163.289 132.184 172.45 134.965" fill="#F7F7F8"/>
<path d="M193.61 126.279C193.444 126.279 193.317 126.145 193.309 125.986C192.961 98.5176 197.066 71.3264 205.498 45.1813C205.546 45.0228 205.72 44.9436 205.871 44.9911C206.029 45.0387 206.116 45.2051 206.061 45.3636C197.644 71.4453 193.555 98.5731 193.896 125.978C193.896 126.145 193.769 126.279 193.603 126.279H193.61Z" fill="#DBDDE0"/>
<path d="M130.178 53.6215L130.098 53.5977C125.557 52.0603 120.834 50.4752 116.102 48.8902C111.403 47.3131 106.703 45.736 102.194 44.2144C102.035 44.1589 101.956 43.9925 102.012 43.834C102.067 43.6755 102.233 43.5962 102.392 43.6517C106.901 45.1812 111.601 46.7583 116.293 48.3275C120.992 49.9046 125.7 51.4817 130.217 53.0113C135.757 51.8463 141.193 50.6179 146.456 49.4212C151.742 48.2245 157.21 46.9882 162.782 45.8152C162.948 45.7835 163.099 45.8866 163.13 46.0451C163.162 46.2036 163.059 46.3621 162.9 46.3938C157.337 47.5588 151.869 48.803 146.591 49.9997C141.304 51.1964 135.836 52.4327 130.265 53.6057L130.185 53.6215H130.178Z" fill="#DBDDE0"/>
<path d="M132.817 79.8063C131.454 79.8063 130.178 79.7825 128.997 79.727C119.265 77.3495 109.168 73.8624 100.522 69.8998C100.371 69.8285 100.308 69.6542 100.379 69.5036C100.45 69.353 100.625 69.2896 100.775 69.3609C109.398 73.3077 119.447 76.7868 129.092 79.1485C134.489 79.3863 142.2 78.99 149.658 78.6096C157.123 78.2292 164.85 77.8329 170.326 78.0786C170.493 78.0786 170.62 78.2213 170.612 78.3877C170.612 78.5462 170.469 78.673 170.311 78.673C170.311 78.673 170.303 78.673 170.295 78.673C164.842 78.4273 157.131 78.8236 149.681 79.204C143.817 79.5051 137.794 79.8142 132.825 79.8142L132.817 79.8063Z" fill="#DBDDE0"/>
<path d="M172.347 100.99C172.347 100.99 172.331 100.99 172.324 100.99C159.873 99.8489 128.125 99.0326 127.808 99.0247C127.562 98.9771 107.971 94.9987 97.2011 93.1125C97.0426 93.0808 96.9316 92.9302 96.9633 92.7717C96.995 92.6132 97.1456 92.5023 97.3041 92.534C108.082 94.4202 127.681 98.4065 127.879 98.4461C128.149 98.4461 159.913 99.2624 172.387 100.412C172.553 100.427 172.672 100.57 172.656 100.737C172.641 100.887 172.514 101.006 172.363 101.006L172.347 100.99Z" fill="#DBDDE0"/>
<path d="M119.811 124.211C118.179 119.17 112.473 116.793 107.313 115.596C103.303 114.669 99.2219 114.09 95.1167 113.829C96.3926 113.678 97.6686 113.512 98.9366 113.29C103.723 112.458 108.613 110.738 111.807 107.077C115.001 103.415 115.881 97.4159 112.695 93.7466C109.802 90.4101 104.817 90.0059 100.403 90.2278C93.9596 90.5527 87.5561 91.7098 81.3903 93.6356C88.9351 87.1766 95.3703 79.4338 100.3 70.8191C101.94 67.9502 103.454 64.8356 103.422 61.5308C103.391 58.2261 101.338 54.7231 98.1045 54.0654C95.5763 53.5502 93.0482 54.8182 90.845 56.1655C83.752 60.5085 77.4357 66.1195 72.2764 72.6498C74.5509 62.4105 75.177 51.8067 74.0913 41.3693C73.489 35.5601 71.2065 28.586 65.4529 27.5874C61.9499 26.9772 58.3995 29.0853 56.3627 32.0017C54.3339 34.9182 53.5493 38.5162 53.05 42.0429C51.4412 53.3917 52.305 65.0892 55.5702 76.0814C50.0147 68.7982 41.9152 63.4884 33.0152 61.3169C30.1384 60.6115 27.0714 60.2311 24.2262 61.0791C21.3811 61.9271 18.8292 64.2412 18.4329 67.1815C17.9495 70.7874 20.6678 73.9813 23.3386 76.446C29.2983 81.9619 36.0188 86.6377 43.2466 90.3388C38.4598 90.2833 33.6651 90.9173 29.0685 92.2646C25.4942 93.3107 21.3652 95.8784 21.9438 99.5557C22.3955 102.393 25.4308 103.946 28.1254 104.945C37.6039 108.448 47.5579 110.667 57.6307 111.531C49.3806 112.688 41.2019 114.368 33.1658 116.571C29.8452 117.482 26.0728 118.925 25.0504 122.206C24.361 124.425 25.2248 126.905 26.7464 128.673C28.2681 130.432 30.3524 131.597 32.4525 132.604C41.9786 137.192 59.2792 137.636 98.786 138.936C103.272 139.356 107.797 139.395 111.942 137.747C117.283 135.623 121.571 129.687 119.804 124.219L119.811 124.211Z" fill="#F7F7F8"/>
<path d="M72.8233 174.908L72.7678 173.022C71.5711 131.225 68.084 89.0311 62.4017 47.6064C62.3779 47.44 62.4968 47.2973 62.6553 47.2735C62.8138 47.2498 62.9644 47.3686 62.9882 47.5271C68.5913 88.3496 72.0625 129.917 73.3067 171.12L73.6951 169.796C73.7426 169.638 73.9011 169.551 74.0596 169.59C74.2181 169.638 74.3053 169.804 74.2577 169.955L72.8154 174.9L72.8233 174.908Z" fill="#DBDDE0"/>
<path d="M68.2742 94.7848L68.0365 94.5867C59.8181 87.7711 44.0154 78.7839 32.4843 72.2219C30.2494 70.9539 28.1889 69.781 26.3819 68.7348C26.2393 68.6556 26.1917 68.4733 26.271 68.3307C26.3502 68.188 26.5325 68.1405 26.6751 68.2197C28.4821 69.2658 30.5426 70.4388 32.7775 71.7068C44.2214 78.2133 59.8577 87.1053 68.1791 93.9289C75.2405 84.5059 87.889 70.1455 96.8127 60.7463C96.9237 60.6274 97.1139 60.6195 97.2327 60.7384C97.3516 60.8493 97.3595 61.0395 97.2407 61.1584C88.2456 70.6369 75.4782 85.1399 68.4644 94.5391L68.2742 94.7927V94.7848Z" fill="#DBDDE0"/>
<path d="M69.8672 107.243H69.8117C68.4327 107.005 35.9951 101.624 29.0844 98.6443C28.9338 98.5809 28.8625 98.4066 28.9259 98.256C28.9893 98.1054 29.1636 98.0341 29.3142 98.0975C36.0268 100.99 68.2663 106.371 69.8434 106.633C71.4205 106.276 103.7 98.9059 107.527 98.2877C107.694 98.2639 107.844 98.3749 107.868 98.5334C107.892 98.6919 107.781 98.8504 107.623 98.8742C103.739 99.5003 70.2555 107.148 69.9147 107.219L69.8593 107.235L69.8672 107.243Z" fill="#DBDDE0"/>
<path d="M77.9191 129.616C75.605 129.616 73.2829 129.529 70.9529 129.323C60.7612 128.435 51.354 127.088 43.7935 126.01C38.9116 125.312 34.6954 124.71 31.24 124.361C31.0736 124.346 30.9547 124.195 30.9785 124.037C31.0023 123.878 31.137 123.759 31.3034 123.775C34.7667 124.124 38.9908 124.734 43.8806 125.431C51.4333 126.509 60.8246 127.856 71.0084 128.744C81.5489 129.671 92.1844 128.189 100.72 127.001C105.007 126.406 108.716 125.891 111.767 125.748C111.934 125.748 112.069 125.867 112.077 126.034C112.077 126.2 111.958 126.335 111.791 126.343C108.764 126.477 105.071 126.993 100.799 127.587C94.1102 128.514 86.1375 129.624 77.9191 129.624V129.616Z" fill="#DBDDE0"/>
<path d="M97.3991 180.646C97.9142 173.188 93.4128 166.182 87.7859 161.261C82.1591 156.339 75.3831 152.94 69.2728 148.636C64.6445 145.371 60.3728 141.567 55.3641 138.92C42.8345 132.287 27.745 133.769 13.6699 135.433C23.1246 143.715 27.959 136.78 32.8488 142.193C37.7386 147.606 41.8914 154.303 48.707 156.902C52.3129 158.281 56.4578 158.392 59.6199 160.603C63.1308 163.068 64.589 167.53 67.5213 170.66C71.579 174.995 77.8319 176.232 83.5618 177.793C89.2917 179.354 95.4812 182.017 97.6923 187.525" fill="#9AB557"/>
<path d="M98.5879 178.831L97.1218 177.563C97.1218 177.563 97.0584 177.492 97.0425 177.452C79.8925 162.259 60.5789 149.857 39.6169 140.568C39.4663 140.505 39.4029 140.323 39.4663 140.172C39.5297 140.022 39.712 139.958 39.8547 140.022C61.5062 149.619 81.3983 162.529 98.9763 178.395L98.58 178.839L98.5879 178.831Z" fill="#676864"/>
<path d="M101.061 181.113C96.2896 158.78 83.0705 138.365 64.6445 124.877C46.2265 111.38 22.768 104.937 0.0386963 107.124C3.81899 113.702 11.015 117.498 18.108 120.177C25.201 122.856 32.7141 124.845 38.8798 129.267C47.867 135.702 52.8994 146.52 61.7598 153.13C67.244 157.219 73.8456 159.446 79.9876 162.458C86.1296 165.469 92.1765 169.67 94.8076 175.986" fill="#7C9B6A"/>
<path d="M90.1793 170.652C90.0842 170.652 89.9891 170.605 89.9337 170.525C73.6475 147.067 51.5046 127.999 25.8826 115.39C25.732 115.319 25.6766 115.137 25.7479 114.994C25.8192 114.851 25.9936 114.788 26.1441 114.859C51.8533 127.516 74.0834 146.647 90.425 170.185C90.5201 170.319 90.4884 170.502 90.3537 170.597C90.2982 170.636 90.2427 170.652 90.1873 170.652H90.1793Z" fill="#676864"/>
<path d="M101.631 169.17C102.733 162.798 103.834 156.331 103.272 149.888C102.709 143.445 100.284 136.907 95.3783 132.691C90.6628 128.641 84.1563 127.072 79.6468 122.8C70.2872 113.924 72.9897 97.1861 63.8996 88.0405C59.2237 83.3408 52.416 81.6845 46.2265 79.3307C40.029 76.9769 39.3079 69.0994 32.1118 66.5237C32.2941 78.6967 32.8092 91.8604 40.4253 101.355C44.4909 106.427 50.2446 109.938 54.2626 115.049C60.0004 122.34 61.5775 131.977 65.445 140.418C70.4537 151.331 79.1872 160.009 87.7463 168.433" fill="#9AB557"/>
<path d="M92.7709 173.426C92.652 173.426 92.5331 173.355 92.4935 173.228C83.5698 148.628 73.4573 120.756 54.3418 100.198C52.9787 98.7395 51.5443 97.2733 50.1574 95.8627C45.1645 90.7906 40.0052 85.5441 37.1046 78.9662C37.0412 78.8157 37.1046 78.6413 37.2552 78.5779C37.4058 78.5145 37.5801 78.5779 37.6435 78.7285C40.4966 85.2033 45.6242 90.4102 50.5774 95.4505C51.9722 96.8691 53.4067 98.3274 54.7777 99.8015C73.9804 120.446 84.1166 148.383 93.0562 173.03C93.1117 173.18 93.0324 173.355 92.8818 173.41C92.8501 173.426 92.8105 173.426 92.7788 173.426H92.7709Z" fill="#676864"/>
<path d="M97.5893 188.444C89.7434 172.855 81.7311 156.633 81.1209 139.189C80.7721 129.196 82.8723 119.02 80.5819 109.288C78.561 100.697 73.275 93.2394 70.3427 84.9101C65.9521 72.4755 67.0062 58.8601 68.1236 45.7202C76.8017 58.2737 85.5114 70.8746 92.3904 84.49C97.5814 94.7531 101.702 105.547 104.873 116.603C110.753 137.073 113.289 159.446 105.871 179.417" fill="#7C9B6A"/>
<path d="M97.6924 187.826C97.5418 187.826 97.407 187.707 97.3991 187.549C94.4034 149.579 90.5201 109.652 79.0603 75.5029C79.0049 75.3444 79.092 75.1779 79.2505 75.1304C79.409 75.0749 79.5755 75.1621 79.623 75.3206C91.0986 109.533 94.9899 149.5 97.9856 187.509C98.0014 187.675 97.8746 187.818 97.7161 187.826C97.7161 187.826 97.7003 187.826 97.6924 187.826Z" fill="#676864"/>
<path d="M95.83 183.261C93.2623 174.781 86.518 168.013 78.7117 163.805C70.9133 159.597 62.0847 157.679 53.3195 156.403C46.2265 155.373 38.7769 154.762 32.088 157.338C19.281 162.283 13.4322 176.913 3.14539 186.011C13.0597 186.613 20.1924 174.71 30.1226 174.678C36.2408 174.654 42.256 179.259 48.0334 177.246C50.8865 176.247 52.947 173.83 55.1661 171.786C57.3851 169.741 60.2778 167.894 63.2339 168.489C68.1475 169.479 69.5898 176.073 73.8615 178.704C75.7952 179.893 78.1331 180.154 80.3918 180.392C86.9776 181.082 93.5634 181.771 100.149 182.461" fill="#7C9B6A"/>
<path d="M97.3516 184.688C97.2644 184.688 97.1852 184.648 97.1218 184.585C87.0964 172.618 75.5099 164.748 63.5984 161.832C49.444 158.36 34.3308 162.545 25.9935 172.237C25.8826 172.364 25.7003 172.372 25.5735 172.269C25.4467 172.166 25.4388 171.976 25.5418 171.849C34.0217 161.99 49.3727 157.734 63.741 161.253C75.7714 164.201 87.4689 172.134 97.5735 184.196C97.6765 184.323 97.6607 184.513 97.5339 184.616C97.4784 184.664 97.4071 184.688 97.3437 184.688H97.3516Z" fill="#676864"/>
<path d="M208.058 105.016H91.2334V91.797H149.65H208.058V105.016Z" fill="#B6DDE1"/>
<path d="M202.796 190.013H96.4957L96.2897 105.016H202.59L202.796 190.013Z" fill="#87CFD4"/>
<path d="M149.444 190.315C149.277 190.315 149.142 190.18 149.142 190.013V91.7891C149.142 91.6226 149.277 91.4879 149.444 91.4879C149.61 91.4879 149.745 91.6226 149.745 91.7891V190.013C149.745 190.18 149.61 190.315 149.444 190.315Z" fill="#343E4E"/>
<path d="M149.047 92.0903C148.905 92.0903 148.778 91.9873 148.754 91.8446C148.722 91.6861 148.833 91.5276 148.992 91.4959C149.15 91.4642 149.301 91.4404 149.451 91.4087C149.325 91.3374 149.269 91.1868 149.317 91.0521C153.779 78.0865 160.024 65.8184 167.885 54.5806C170.144 51.355 172.87 47.7332 176.666 45.5538C180.09 43.5963 185.186 42.8196 188.871 45.4983C193.103 48.5733 193.547 54.7074 191.796 59.1692C189.949 63.8688 186.121 67.4985 182.737 70.7003C173.869 79.101 162.829 89.5622 149.095 92.0824C149.079 92.0824 149.063 92.0824 149.039 92.0824L149.047 92.0903ZM183.236 44.365C180.994 44.365 178.743 45.0545 176.96 46.0689C173.267 48.185 170.588 51.7433 168.369 54.9213C160.539 66.1116 154.318 78.3322 149.879 91.2502C149.872 91.2819 149.856 91.3136 149.832 91.3374C163.003 88.5874 173.702 78.4511 182.333 70.2803C185.669 67.1181 189.45 63.536 191.249 58.9632C192.913 54.7153 192.517 48.8903 188.53 45.9897C186.961 44.8485 185.107 44.373 183.244 44.373L183.236 44.365Z" fill="#343E4E"/>
<path d="M148.596 92.0902C148.596 92.0902 148.58 92.0902 148.572 92.0902C134.457 91.0996 121.365 84.9735 107.385 72.8084C103.668 69.5828 99.7291 62.2838 100.213 57.7189C100.553 54.5171 103.113 51.5452 106.592 50.3089C109.414 49.3103 112.782 49.3182 116.594 50.3485C124.567 52.4962 132.143 58.3133 137.944 66.7377C143.143 74.2983 146.487 82.9605 149.713 91.3453C149.776 91.4959 149.697 91.6702 149.547 91.7257C149.396 91.7891 149.222 91.7098 149.166 91.5513C145.949 83.2061 142.62 74.5677 137.461 67.0705C131.747 58.765 124.281 53.0272 116.451 50.9191C112.758 49.9205 109.509 49.9047 106.806 50.8636C103.541 52.0207 101.14 54.8024 100.823 57.7743C100.363 62.0936 104.262 69.2817 107.789 72.3487C121.666 84.4187 134.647 90.4973 148.627 91.4879C148.794 91.4959 148.913 91.6385 148.905 91.8049C148.897 91.9634 148.762 92.0823 148.611 92.0823L148.596 92.0902Z" fill="#343E4E"/>
<path d="M122.3 144.301C119.534 144.301 116.824 143.493 114.613 141.607C114.486 141.504 114.47 141.313 114.581 141.187C114.684 141.06 114.874 141.044 115.001 141.155C119.193 144.721 127.499 144.927 133.609 139.039C138.665 134.157 140.916 126.945 142.628 120.573C145.212 110.936 147.296 101.054 148.818 91.2026C148.841 91.0441 148.984 90.9252 149.158 90.9569C149.317 90.9807 149.428 91.1313 149.404 91.2977C147.882 101.172 145.79 111.079 143.199 120.732C141.471 127.191 139.188 134.49 134.021 139.475C131.018 142.367 126.588 144.309 122.3 144.309V144.301Z" fill="#343E4E"/>
<path d="M189.87 152.599C182.816 152.599 175.335 149.318 169.661 143.501C162.385 136.035 158.843 125.836 156.814 118.806C155.871 115.533 155.031 112.173 154.222 108.931C152.82 103.281 151.361 97.4318 149.285 91.9001C149.23 91.7495 149.309 91.5752 149.459 91.5197C149.618 91.4642 149.784 91.5435 149.84 91.694C151.924 97.2575 153.382 103.122 154.793 108.789C155.601 112.03 156.434 115.39 157.377 118.647C159.39 125.614 162.9 135.726 170.081 143.097C177.094 150.293 186.937 153.526 195.148 151.339C195.306 151.299 195.473 151.394 195.512 151.553C195.552 151.711 195.457 151.878 195.298 151.917C193.547 152.385 191.716 152.615 189.862 152.615L189.87 152.599Z" fill="#343E4E"/>
<path d="M202.035 169.075C212.385 156.347 218.266 133.753 218.963 117.364C219.66 100.974 214.937 84.3949 205.712 70.835C195.18 90.8618 184.33 112.347 186.414 134.878C187.532 147.004 193.618 160.27 202.043 169.075H202.035Z" fill="#7C9B6A"/>
<path d="M200.886 188.325C200.727 188.325 200.6 188.206 200.592 188.048C200.252 181.969 201.409 170.24 202.748 156.656C204.579 138.088 206.861 114.978 205.879 98.9375C205.871 98.7711 205.989 98.6364 206.156 98.6205C206.314 98.6047 206.465 98.7394 206.473 98.8979C207.464 114.986 205.181 138.119 203.343 156.712C202.003 170.272 200.846 181.977 201.187 188.016C201.195 188.183 201.068 188.317 200.909 188.333C200.909 188.333 200.902 188.333 200.894 188.333L200.886 188.325Z" fill="#676864"/>
<path d="M201.171 189.031C201.171 189.031 201.116 189.031 201.092 189.023C200.933 188.975 200.846 188.817 200.886 188.658C202.011 184.68 203.715 179.576 206.877 175.447C210.166 171.152 214.66 168.512 219.201 168.203C219.383 168.188 219.51 168.314 219.518 168.481C219.526 168.647 219.407 168.79 219.24 168.798C214.874 169.091 210.539 171.651 207.345 175.812C204.238 179.861 202.566 184.894 201.448 188.825C201.409 188.959 201.29 189.039 201.163 189.039L201.171 189.031Z" fill="#676864"/>
<path d="M203.406 172.887C201.52 170.256 200.394 167.149 200.212 163.916C199.768 155.951 202.859 147.788 207.63 141.242C213.606 133.047 221.943 126.945 230.209 121.065C238.467 115.184 246.915 109.272 253.223 101.339C256.504 117.26 252.97 134.466 243.681 147.812C234.393 161.15 219.478 170.438 203.414 172.887H203.406Z" fill="#9AB557"/>
<path d="M205.054 173.03C206.632 166.713 209.057 160.635 212.274 154.976C216.49 147.55 220.77 141.805 229.836 137.723C242.311 132.096 256.6 130.606 269.969 133.531C264.358 135.37 260.158 140.077 256.972 145.054C253.794 150.031 251.329 155.484 247.652 160.104C237.928 172.34 219.351 179.354 205.062 173.038L205.054 173.03Z" fill="#7C9B6A"/>
<path d="M201.02 188.048L200.941 186.431C200.941 186.328 200.989 186.217 201.068 186.146C201.892 165.136 212.647 144.468 229.448 131.827C229.575 131.732 229.765 131.756 229.868 131.882C229.963 132.017 229.939 132.199 229.812 132.302C212.655 145.212 201.853 166.563 201.623 188.032H201.028L201.02 188.048Z" fill="#676864"/>
<path d="M201.234 188.357C201.234 188.357 201.211 188.357 201.203 188.357C201.036 188.341 200.925 188.191 200.941 188.024C203.026 170.652 214.485 154.358 230.122 146.512C230.264 146.441 230.447 146.496 230.518 146.647C230.589 146.79 230.534 146.972 230.383 147.043C214.921 154.802 203.588 170.914 201.528 188.096C201.512 188.246 201.385 188.357 201.234 188.357Z" fill="#676864"/>
<path d="M170.754 191.012C171.238 186.558 174.915 181.977 179.393 182.049C183.878 182.128 187.968 185.987 188.308 190.457C188.839 183.768 194.974 177.991 201.686 177.864C208.399 177.737 214.747 183.269 215.531 189.942C216.181 187.652 218.202 185.813 220.548 185.385C222.894 184.957 226.452 187.414 226.856 191.012" fill="#C7B4A7"/>
<path d="M198.833 167.466C195.782 162.093 189.933 158.424 183.767 158.012C177.602 157.6 171.317 160.46 167.576 165.382C159.778 175.637 164.097 190.98 158.201 202.44C167.354 196.892 175.628 189.895 182.618 181.787C184.663 179.417 186.89 176.778 189.981 176.271C192.358 175.883 194.736 176.866 197.145 176.953C199.554 177.04 202.447 175.518 202.241 173.117" fill="#7C9B6A"/>
<path d="M170.62 176.739C170.572 176.739 170.524 176.731 170.477 176.707C170.334 176.628 170.279 176.446 170.358 176.303C171.975 173.291 173.647 170.177 176.262 167.561C179.171 164.645 182.983 162.814 186.731 162.553C193.444 162.061 199.245 166.444 201.488 171.017C202.13 172.332 202.605 173.767 202.883 175.281C202.915 175.439 202.804 175.598 202.645 175.629C202.479 175.661 202.328 175.55 202.296 175.392C202.019 173.925 201.567 172.538 200.949 171.278C198.793 166.888 193.254 162.688 186.771 163.147C183.173 163.401 179.496 165.168 176.682 167.989C174.13 170.549 172.482 173.624 170.881 176.596C170.826 176.699 170.723 176.755 170.62 176.755V176.739Z" fill="#676864"/>
<path d="M85.8601 191.012C86.082 185.964 90.0208 181.106 95.0771 180.273C100.981 179.307 107.195 184.014 107.852 189.958C109.612 186.946 113.598 185.449 116.903 186.55C119.011 187.255 120.707 188.967 121.563 191.012" fill="#C7B4A7"/>
<path d="M92.6758 193.809C92.549 193.809 92.4301 193.722 92.3905 193.595C91.2968 189.879 87.7067 186.97 83.8392 186.669C79.9718 186.368 75.9775 188.706 74.337 192.216L74.0755 192.771L73.7981 192.216C72.744 190.093 70.3269 188.642 67.9731 188.714C65.5956 188.777 63.2814 190.346 62.3462 192.526C62.2828 192.676 62.1085 192.74 61.9579 192.684C61.8073 192.621 61.736 192.446 61.7994 192.296C62.8455 189.871 65.3182 188.191 67.9572 188.119C70.3903 188.072 72.7837 189.371 74.0596 191.448C75.9379 188.008 79.9718 185.765 83.8868 186.067C87.9841 186.376 91.804 189.466 92.9611 193.413C93.0086 193.572 92.9135 193.738 92.763 193.778C92.7313 193.786 92.7075 193.794 92.6758 193.794V193.809Z" fill="#9D8E83"/>
</g>
<defs>
<clipPath id="clip0_2220_188838">
<rect width="269.923" height="202.44" fill="white" transform="translate(0.0386963)"/>
</clipPath>
</defs>
</svg> */
}
