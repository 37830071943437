import { Alert, AlertColor, Box, Snackbar } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

export type SnackBarAlertProps = {
    open: boolean
    onClose: () => void
    severity: AlertColor
    message: string
}

const SnackBarAlert: React.FC<SnackBarAlertProps> = ({
    open,
    onClose,
    severity,
    message
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={onClose} variant="filled" severity={severity}>
                <Box data-testid="snack-bar-alert-message">
                    <CoreTypography>{message}</CoreTypography>
                </Box>
            </Alert>
        </Snackbar>
    )
}

export default SnackBarAlert
