import { memo, useMemo } from 'react'
import type {
    CommunityGardenInfo,
    PlantInfo,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import TotalPlants from './TotalPlants'

type TotalAndPlants = {
    totalPlants: number
    plants: PlantInfo[]
}

type CommunityTotalPlantsProps = {
    challenge: UnifiedChallenge
    communityGardenInfo: CommunityGardenInfo[]
}

const CommunityTotalPlants = ({
    challenge,
    communityGardenInfo
}: CommunityTotalPlantsProps) => {
    const { totalPlants, plants } = useMemo(
        () =>
            communityGardenInfo?.reduce(
                (accumulator, groupedPlant) => {
                    accumulator.totalPlants += groupedPlant.plantsNumber
                    const plants = Array.from(
                        Array(groupedPlant.plantsNumber)
                    ).map(
                        () =>
                            groupedPlant.plantsTemplate[
                                Math.floor(
                                    Math.random() *
                                        groupedPlant.plantsTemplate.length
                                )
                            ]
                    )
                    accumulator.plants = accumulator.plants.concat(plants)

                    return accumulator
                },
                { totalPlants: 0, plants: [] } as TotalAndPlants
            ),
        [communityGardenInfo]
    )

    return (
        <TotalPlants
            totalPlants={totalPlants}
            plants={plants}
            groupedPlants={communityGardenInfo}
            challengeStarted={true}
            challenge={challenge as UnifiedChallenge}
        />
    )
}

export default memo(CommunityTotalPlants)
