import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant15: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#4A4C2B',
    colorLayer2 = '#81C254',
    colorLayer3 = '#53A354',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M51.545 82.7039L51.1849 81.7739C55.3749 80.1739 58.5449 76.6139 59.6649 72.2739L78.1249 0.333862L79.0949 0.583862L60.6349 72.5239C59.4349 77.1839 56.045 80.9939 51.545 82.7139V82.7039Z"
                fill={colorLayer1}
            />
            <path
                d="M64.5838 12.2488L63.6652 12.6441L70.8109 29.2521L71.7294 28.8568L64.5838 12.2488Z"
                fill={colorLayer1}
            />
            <path
                d="M60.4749 5.72385L66.8549 20.6039C71.985 7.34385 60.4749 5.72385 60.4749 5.72385Z"
                fill={colorLayer2}
            />
            <path
                d="M86.1181 40.718L63.3181 58.4929L63.933 59.2816L86.7329 41.5067L86.1181 40.718Z"
                fill={colorLayer1}
            />
            <path
                d="M88.6549 40.6439L76.1349 50.4339C77.9849 36.6039 88.6549 40.6439 88.6549 40.6439Z"
                fill={colorLayer3}
            />
            <path
                d="M86.4077 26.2334L67.8822 40.6759L68.497 41.4646L87.0225 27.0221L86.4077 26.2334Z"
                fill={colorLayer1}
            />
            <path
                d="M88.5349 26.2439L78.3649 34.1938C79.8749 22.9538 88.5349 26.2439 88.5349 26.2439Z"
                fill={colorLayer2}
            />
            <path
                d="M86.723 11.7465L72.4641 22.8627L73.079 23.6514L87.3379 12.5351L86.723 11.7465Z"
                fill={colorLayer1}
            />
            <path
                d="M88.4249 11.8339L80.5949 17.9538C81.7549 9.30385 88.4249 11.8339 88.4249 11.8339Z"
                fill={colorLayer3}
            />
            <path
                d="M50.5349 85.2939C50.5349 85.2939 20.7649 10.2939 20.3949 9.35385L21.325 8.98386C21.695 9.91386 51.4649 84.9239 51.4649 84.9239L50.5349 85.2939Z"
                fill={colorLayer1}
            />
            <path
                d="M20.697 55.5658L20.1869 56.4259L45.0529 71.1727L45.563 70.3126L20.697 55.5658Z"
                fill={colorLayer1}
            />
            <path
                d="M18.1849 55.8739L31.8349 64.0038C28.2449 50.5239 18.1849 55.8739 18.1849 55.8739Z"
                fill={colorLayer2}
            />
            <path
                d="M18.5834 41.2921L18.0732 42.1521L38.2753 54.1377L38.7855 53.2777L18.5834 41.2921Z"
                fill={colorLayer1}
            />
            <path
                d="M16.4849 41.5739L27.5849 48.1739C24.6649 37.2139 16.4849 41.5739 16.4849 41.5739Z"
                fill={colorLayer2}
            />
            <path
                d="M16.4404 26.902L15.9303 27.7621L31.4812 36.9846L31.9913 36.1244L16.4404 26.902Z"
                fill={colorLayer1}
            />
            <path
                d="M9.96494 24.2938L25.3449 33.4438C21.3049 18.2538 9.96494 24.2938 9.96494 24.2938Z"
                fill={colorLayer3}
            />
            <path
                d="M34.0307 13.4985L29.0502 30.879L30.0115 31.1545L34.992 13.774L34.0307 13.4985Z"
                fill={colorLayer1}
            />
            <path
                d="M36.6549 8.58386L32.7749 22.1539C26.8749 11.2539 36.6549 8.58386 36.6549 8.58386Z"
                fill={colorLayer2}
            />
            <path
                d="M51.8749 3.75385H50.8749V85.4539H51.8749V3.75385Z"
                fill={colorLayer1}
            />
            <path
                d="M37.151 43.3068L36.2888 43.8134L50.9356 68.7385L51.7978 68.2318L37.151 43.3068Z"
                fill={colorLayer1}
            />
            <path
                d="M34.8149 42.3339L42.8449 56.0439C46.2149 42.5039 34.8149 42.3339 34.8149 42.3339Z"
                fill={colorLayer3}
            />
            <path
                d="M37.4027 32.7696L36.6112 33.3807L50.9662 51.974L51.7577 51.3629L37.4027 32.7696Z"
                fill={colorLayer1}
            />
            <path
                d="M35.3449 32.2439L43.2249 42.4739C44.5549 31.2139 35.3449 32.2439 35.3449 32.2439Z"
                fill={colorLayer2}
            />
            <path
                d="M40.7143 18.6637L39.9228 19.2748L50.9717 33.586L51.7632 32.9749L40.7143 18.6637Z"
                fill={colorLayer1}
            />
            <path
                d="M39.045 18.3239L45.1049 26.1939C46.1349 17.5239 39.045 18.3239 39.045 18.3239Z"
                fill={colorLayer2}
            />
            <path
                d="M68.6491 40.8709L50.9699 63.7451L51.7611 64.3567L69.4404 41.4824L68.6491 40.8709Z"
                fill={colorLayer1}
            />
            <path
                d="M71.0849 40.1939L61.3949 52.7839C59.7549 38.9239 71.0849 40.1939 71.0849 40.1939Z"
                fill={colorLayer2}
            />
            <path
                d="M65.3394 26.7712L50.9746 45.3571L51.7658 45.9686L66.1306 27.3827L65.3394 26.7712Z"
                fill={colorLayer1}
            />
            <path
                d="M67.3949 26.2639L59.5149 36.4939C58.1849 25.2339 67.3949 26.2639 67.3949 26.2639Z"
                fill={colorLayer3}
            />
            <path
                d="M62.0257 12.6637L50.9693 26.969L51.7606 27.5805L62.817 13.2752L62.0257 12.6637Z"
                fill={colorLayer1}
            />
            <path
                d="M63.6949 12.3439L57.6349 20.2139C56.6049 11.5439 63.6949 12.3439 63.6949 12.3439Z"
                fill={colorLayer2}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant15
