import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { ChallengeThemes } from '../../../enums/challengeThemes'
import {
    ActivityStats,
    ArticleStats,
    ChallengeUserDailyGoal,
    FeelingQuestionStats,
    GoalType,
    GroupChallengeType,
    MicrostepStats,
    ResetStats,
    SleepHabits,
    UserPlantInfo,
    WaterStats,
    UnifiedChallenge,
    SocialGroup,
    useGetCompanyChallengeDataQuery
} from '../../../graphql/generated/autogenerated'
import { GQLNullValue } from '../../../shared/utils/Nulls'
import { GoalChallengeDataValue } from './types'
import { ChallengeType } from '../../../shared'
import {
    isChallengeExpired,
    isChallengeStarted,
    showAvgLeaderboard
} from '../../utils/challenge'

interface UseGetCompanyChallengeDataQueryValues {
    challenge?: UnifiedChallenge
    socialGroup?: SocialGroup | null
    loading: boolean
}

export const useGoalChallengeData = ({
    challenge,
    socialGroup,
    loading: challengeLoading
}: UseGetCompanyChallengeDataQueryValues) => {
    const challengeStarted = isChallengeStarted(challenge)
    const challengeExpired = isChallengeExpired(challenge)
    const shouldShowAvgLeaderboard = showAvgLeaderboard(challenge)
    const canFirePostJoinQueries = useMemo(() => {
        return (
            !!challenge &&
            !!socialGroup &&
            challengeStarted &&
            !challengeLoading
        )
    }, [challenge, socialGroup, challengeStarted, challengeLoading])

    const {
        data,
        loading: dataLoading,
        error,
        refetch
    } = useGetCompanyChallengeDataQuery({
        variables: {
            challengeId: challenge?.id,
            date: null,
            socialGroupId: socialGroup?.id,
            isHydrationChallenge:
                challenge?.theme === ChallengeThemes.HYDRATION,
            isTeamHydrationChallenge:
                challenge?.theme === ChallengeThemes.HYDRATION &&
                socialGroup != null,
            isSleepChallenge: challenge?.theme === ChallengeThemes.SLEEP,
            isMovementChallenge: challenge?.theme === ChallengeThemes.MOVEMENT,
            isExpired: Boolean(challengeExpired),
            isGroupChallenge:
                challenge?.challenge_type === ChallengeType.company ||
                challenge?.groupChallengeType === GroupChallengeType.Group
        },
        skip: !canFirePostJoinQueries
    })

    const {
        challengeTotalWater,
        userTotalWater,
        teamTotalWater,
        waterIntake,
        feelingAnswer,
        feelingQuestionStats,
        measureUnitSettings,
        challengeTotalSteps,
        articleStats,
        microstepStats,
        resetStats,
        waterStats,
        activityStats,
        sleepHabits,
        userTodaySteps,
        userTotalSteps,
        thresholdTotal,
        thresholdValue,
        thresholdAchieved,
        activityGoal,
        goals,
        totalUserPlants,
        userPlant,
        plants,
        teamPlants,
        groupedCommunityPlants,
        groupedTeamPlants,
        // user solo activity
        userActivityRank,
        userDisplayName,
        userTotalPoints,
        // teams plant
        teamPlantsRank,
        teamPlantsTotalPoints,
        teamPlantsRankDisplayName,
        // team activity
        teamActivityRank,
        teamActivityRankDisplayName,
        teamActivityRankTotalPoints,
        // user solo plants
        userPlantsRank,
        userPlantsRankDisplayName,
        userPlantsTotalPoints
    } = useMemo(() => {
        const goalEntityDetail =
            data?.unifiedChallenges?.communityChallengeUserDailyGoals?.find(
                (goal) => goal.challengeGoal.goalType == GoalType.Steps
            )?.goalEntityDetail

        const waterIntakeResponse = data?.unifiedChallenges?.getWaterIntake
        const feelingAnswerResponse = data?.unifiedChallenges?.getFeelingAnswer
        const getChallengeStatsResponse =
            data?.unifiedChallenges?.getChallengeStats
        const getSleepHabitsResponse = data?.unifiedChallenges?.getSleepHabits

        const { teamPlantsRankData, teamActivityRankData } =
            shouldShowAvgLeaderboard
                ? {
                      teamPlantsRankData:
                          data?.retail?.getTeamPlantsRankByAverage,
                      teamActivityRankData:
                          data?.unifiedChallenges?.getTeamRankByAverage
                  }
                : {
                      teamPlantsRankData: data?.retail?.getTeamPlantsRank,
                      teamActivityRankData: data?.unifiedChallenges?.getTeamRank
                  }

        return {
            challengeTotalWater:
                data?.unifiedChallenges?.getChallengeTotalWater || 0,
            userTotalWater: data?.unifiedChallenges?.getUserTotalWater || 0,
            teamTotalWater: data?.unifiedChallenges?.getTeamTotalWater || 0,
            waterIntake:
                waterIntakeResponse == GQLNullValue ||
                !('waterIntake' in waterIntakeResponse)
                    ? 0
                    : waterIntakeResponse?.waterIntake?.amount,
            feelingAnswer:
                feelingAnswerResponse == GQLNullValue ||
                !('feelingAnswer' in feelingAnswerResponse)
                    ? undefined
                    : feelingAnswerResponse?.feelingAnswer?.answer,
            feelingQuestionStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('feelingQuestionStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.feelingQuestionStats as FeelingQuestionStats),
            articleStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('articleStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.articleStats as ArticleStats),
            microstepStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('microstepStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.microstepStats as MicrostepStats),
            resetStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('resetStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.resetStats as ResetStats),
            waterStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('waterStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.waterStats as WaterStats),
            activityStats:
                getChallengeStatsResponse == GQLNullValue ||
                !('activityStats' in getChallengeStatsResponse)
                    ? undefined
                    : (getChallengeStatsResponse?.activityStats as ActivityStats),
            sleepHabits:
                getSleepHabitsResponse == GQLNullValue ||
                !('sleepHabits' in getSleepHabitsResponse)
                    ? undefined
                    : (getSleepHabitsResponse?.sleepHabits as SleepHabits),
            measureUnitSettings:
                data?.unifiedChallenges?.getMeasureUnitSettings,
            challengeTotalSteps:
                data?.unifiedChallenges?.getChallengeTotalSteps,
            userTodaySteps:
                data?.unifiedChallenges?.communityChallengeUserSteps
                    ?.todaySteps,
            userTotalSteps:
                data?.unifiedChallenges?.communityChallengeUserSteps
                    ?.totalSteps,
            thresholdTotal:
                data?.unifiedChallenges?.getCommunityChallengeThreshold
                    ?.total || 0,
            thresholdValue:
                data?.unifiedChallenges?.getCommunityChallengeThreshold
                    ?.threshold || 0,
            thresholdAchieved:
                data?.unifiedChallenges?.getCommunityChallengeThreshold
                    ?.achieved || 0,
            activityGoal:
                goalEntityDetail == GQLNullValue ||
                !('steps' in goalEntityDetail)
                    ? 0
                    : goalEntityDetail.steps,

            // team plants
            teamPlantsRank: teamPlantsRankData?.rank,
            teamPlantsTotalPoints: teamPlantsRankData?.totalPlants,
            teamPlantsRankDisplayName: teamPlantsRankData?.displayName,

            // team activity
            teamActivityRank: teamActivityRankData?.rank,
            teamActivityRankTotalPoints: teamActivityRankData?.totalSteps,
            teamActivityRankDisplayName:
                teamActivityRankData?.requesterDisplayName,

            // user solo plants
            userPlantsRank: data?.retail?.getSoloPlantsRank?.rank,
            userPlantsRankDisplayName:
                data?.retail?.getSoloPlantsRank?.displayName,
            userPlantsTotalPoints: data?.retail?.getSoloPlantsRank?.totalPlants,

            // user solo activity
            userActivityRank: data?.unifiedChallenges?.getSoloPlayerRank?.rank,
            userDisplayName:
                data?.unifiedChallenges?.getSoloPlayerRank
                    ?.requesterDisplayName,
            userTotalPoints:
                data?.unifiedChallenges?.getSoloPlayerRank?.totalSteps,

            goals: orderBy(
                data?.unifiedChallenges?.communityChallengeUserDailyGoals,
                ['challengeGoal.position'],
                ['asc']
            ) as ChallengeUserDailyGoal[],
            totalUserPlants:
                data?.retail?.getUserCommunityChallengePlants?.length,
            userPlant: data?.retail?.getUserCommunityChallengePlants?.reduce(
                (a: any, b) => (a?.updatedAt > b?.updatedAt ? a : b),
                null
            ),
            plants: orderBy(
                data?.retail
                    ?.getUserCommunityChallengePlants as UserPlantInfo[],
                ['createdAt'],
                ['desc']
            ),
            teamPlants: orderBy(
                data?.retail
                    ?.getTeamCommunityChallengePlants as UserPlantInfo[],
                ['createdAt'],
                ['desc']
            ),
            groupedCommunityPlants: orderBy(
                data?.retail?.groupedCommunityChallengePlants,
                ['date'],
                ['asc']
            ),
            groupedTeamPlants: orderBy(
                data?.retail?.groupedTeamChallengePlants,
                ['date'],
                ['asc']
            )
        }
    }, [data])

    return useMemo<GoalChallengeDataValue>(
        () => ({
            // user solo activity
            userActivityRank,
            userDisplayName,
            userTotalPoints,
            // teams plant
            teamPlantsRank,
            teamPlantsTotalPoints,
            teamPlantsRankDisplayName,
            // team activity
            teamActivityRank,
            teamActivityRankDisplayName,
            teamActivityRankTotalPoints,
            // user solo plants
            userPlantsRank,
            userPlantsRankDisplayName,
            userPlantsTotalPoints,
            challengeTotalWater,
            userTotalWater,
            teamTotalWater,
            waterIntake,
            feelingAnswer,
            feelingQuestionStats,
            articleStats,
            microstepStats,
            resetStats,
            waterStats,
            activityStats,
            sleepHabits,
            measureUnitSettings,
            challengeTotalSteps,
            userTodaySteps,
            userTotalSteps,
            thresholdTotal,
            thresholdValue,
            thresholdAchieved,
            activityGoal,
            goals,
            totalUserPlants,
            userPlant,
            groupedCommunityPlants,
            groupedTeamPlants,
            plants,
            teamPlants,
            loading: challengeLoading || dataLoading,
            error: Boolean(error),
            refetch
        }),
        [
            userDisplayName,
            challengeTotalWater,
            userTotalWater,
            teamTotalWater,
            waterIntake,
            feelingAnswer,
            feelingQuestionStats,
            articleStats,
            microstepStats,
            resetStats,
            waterStats,
            sleepHabits,
            measureUnitSettings,
            challengeTotalSteps,
            activityStats,
            userTodaySteps,
            userTotalSteps,
            thresholdTotal,
            thresholdValue,
            thresholdAchieved,
            activityGoal,
            teamPlantsRank,
            teamPlantsRankDisplayName,
            userPlantsRank,
            teamActivityRank,
            userActivityRank,
            userTotalPoints,
            goals,
            totalUserPlants,
            userPlant,
            groupedCommunityPlants,
            groupedTeamPlants,
            plants,
            teamPlants,
            challengeLoading,
            dataLoading,
            error,
            teamActivityRankDisplayName,
            teamActivityRankTotalPoints,
            teamPlantsTotalPoints,
            userPlantsRankDisplayName,
            userPlantsTotalPoints,
            refetch
        ]
    )
}

export default useGoalChallengeData
