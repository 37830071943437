import { Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import {
    ChallengeTemplateGoal,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import { useChallengeTypeIcon } from '../../../../hooks/useChallengeTypeIcon/useChallengeTypeIcon'
import HeaderIcons from '../../Shared/HeaderIcons'
import { FormattedMessage } from 'react-intl'
import useTimeline from '../../../../../hooks/useTimeline/useTimeline'

const CARD_ELEVATION = 18
const MAX_DESCRIPTION_WIDTH = 512
const DESCRIPTION_FONT_SIZE = 18
const HEADER_HEIGHT = 36

export type IntroHeaderProps = {
    challenge: UnifiedChallenge | undefined
    isTemplate: boolean
    startDate: Date | undefined
    endDate: Date | undefined
    goals: ChallengeTemplateGoal[]
    actions?: React.ReactNode
}

const IntroHeader: React.FC<IntroHeaderProps> = ({
    challenge,
    isTemplate,
    startDate,
    endDate,
    goals,
    actions = null
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { icon, title } = useChallengeTypeIcon(challenge)
    const timeline = useTimeline(startDate, endDate)

    return (
        <Stack data-testid="intro-header">
            <Card elevation={CARD_ELEVATION}>
                <Stack gap={isMobile ? 1 : 3} p={3} pb={4.5} alignItems="start">
                    <Stack
                        gap={1}
                        height={HEADER_HEIGHT}
                        direction="row"
                        alignItems="center"
                    >
                        {icon}
                        <CoreTypography
                            variant="overline"
                            color="text.secondary"
                        >
                            {title}
                        </CoreTypography>
                    </Stack>
                    <Stack
                        gap={isMobile ? 3 : 6}
                        direction={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                    >
                        <Stack gap={isMobile ? 2 : 3}>
                            <CoreTypography
                                color="primary.main"
                                {...(isMobile && { variant: 'h1' })}
                                {...(!isMobile && {
                                    customVariant: 'display2'
                                })}
                            >
                                {challenge?.name}
                            </CoreTypography>
                            <CoreTypography
                                fontSize={DESCRIPTION_FONT_SIZE}
                                variant="body2"
                                color="text.secondary"
                            >
                                {challenge?.description}
                            </CoreTypography>
                        </Stack>
                        <HeaderIcons
                            isTemplate={isTemplate}
                            isExpired={false}
                            timeline={timeline}
                            goalCount={goals?.length ?? 0}
                            hasParticipants={
                                isTemplate
                                    ? false
                                    : Boolean(challenge?.totalParticipants)
                            }
                            canShowDailyGoalCount={isTemplate}
                            showSocialMembersList={false}
                            challenge={challenge as UnifiedChallenge}
                        />
                    </Stack>
                    {actions}
                </Stack>
            </Card>
        </Stack>
    )
}

export default IntroHeader
