import { Stack } from '@mui/material'
import React, { memo, useMemo } from 'react'
import rectangle from '../../../../assets/rectangle.png'

type ImageTopPageProps = {
    backgroundImage: string
    children: React.ReactNode
}

const Background: React.FC<ImageTopPageProps> = ({
    backgroundImage,
    children
}) => {
    const challengeBackground = useMemo(
        () => ({
            backgroundImage: `url(${backgroundImage}), url(${rectangle})`,
            backgroundPosition: '50% 0%, calc(100% + 115px) 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% min(415px, 100%), auto 435px'
        }),
        [backgroundImage]
    )

    return (
        <Stack
            sx={{
                ...challengeBackground,
                minHeight: '100vh'
            }}
        >
            {children}
        </Stack>
    )
}

export default memo(Background)
