import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { MeasureUnit } from '../../../../../../../../graphql/generated/autogenerated'
import useGlassesMeasurementConversion from '../../../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'
import Tracker from '../../../../Tracker'

const messages = defineMessages({
    removeIntake: {
        defaultMessage: 'Remove water intake from goal',
        description: 'button text for the remove water intake goal'
    },
    addIntake: {
        defaultMessage: 'Add water intake from goal',
        description: 'button text for the add water intake goal'
    },
    glasses: {
        defaultMessage: 'Glasses',
        description: 'glasses measurement'
    }
})

export type DailyGoalTrackerProps = {
    newGoal: number
    measurement: MeasureUnit
    setNewGoal: (newGoal: number) => void
}

const DailyGoalTracker: React.FC<DailyGoalTrackerProps> = ({
    newGoal,
    measurement,
    setNewGoal
}) => {
    const { formatMessage } = useIntl()
    const formatted = useGlassesMeasurementConversion(newGoal, measurement)

    const onIncrement = useCallback(
        (increment: boolean) => setNewGoal(newGoal + (increment ? 1 : -1)),
        [newGoal, setNewGoal]
    )

    return (
        <Card variant="outlined" sx={{ height: '100%' }}>
            <Stack gap={2} p={2}>
                <CoreTypography variant="h5">
                    <FormattedMessage
                        defaultMessage="Select your daily water goal"
                        description="update your daily goal hydration title"
                    />
                </CoreTypography>
                <Stack alignItems="center" justifyContent="center" gap={2}>
                    <Tracker
                        onIncrement={onIncrement}
                        removeLabel={formatMessage(messages.removeIntake)}
                        addLabel={formatMessage(messages.addIntake)}
                        removeDisabled={newGoal <= 1}
                        message={
                            <CoreTypography variant="body1">
                                {newGoal} {formatMessage(messages.glasses)}
                            </CoreTypography>
                        }
                    />
                    <CoreTypography variant="body1" alignSelf="center">
                        {formatted}
                    </CoreTypography>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(DailyGoalTracker)
