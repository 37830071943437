import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import MultiStatWaveCard from '../../Shared/WaveCard/MultiStatWaveCard'

const messages = defineMessages({
    dailyAverage: {
        defaultMessage: 'Daily Avg.',
        description: 'daily average description'
    },
    total: {
        defaultMessage: 'Total',
        description: 'total description'
    }
})

export type AverageActivityProps = {
    elevation?: number
    averageIndividualSteps?: number
    userTotalSteps?: number
}

type Stat = {
    name: string
    value: string | number
}

const AverageActivity: React.FC<AverageActivityProps> = ({
    averageIndividualSteps = 0,
    userTotalSteps = 0,
    elevation
}) => {
    const { formatMessage, formatNumber } = useIntl()

    const stats: Stat[] = useMemo(
        () => [
            {
                name: formatMessage(messages.dailyAverage),
                value: formatNumber(averageIndividualSteps, {
                    maximumFractionDigits: 0
                })
            },
            {
                name: formatMessage(messages.total),
                value: formatNumber(userTotalSteps, {
                    maximumFractionDigits: 0
                })
            }
        ],
        [averageIndividualSteps, formatMessage, formatNumber, userTotalSteps]
    )

    return (
        <MultiStatWaveCard
            stats={stats}
            elevation={elevation}
            waveColor="red"
        />
    )
}

export default memo(AverageActivity)
