import {
    Box,
    Button,
    CardMedia,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    Stack
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { QuestionType } from '../../../../../enums/questionType'
import {
    UnifiedChallenge,
    Visibility
} from '../../../../../graphql/generated/autogenerated'
import { useStorySubmissionData } from '../../../../contexts/withStorySubmission/useStorySubmissionData'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import useStorySubmissionContext from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/useStorySubmissionContext'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import TermsAndConditions from '../../../../../shared/components/elements/TermsAndConditions/TermsAndConditions'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'
import GetFeatured from '../GetFeatured'
import ReviewYourStoryQuestionWrapper from './ReviewYourStoryQuestionWrapper'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'

export type ReviewYourStoryProps = {
    challenge?: UnifiedChallenge
}

const messages = defineMessages({
    reviewYourStory: {
        defaultMessage: 'Review Your Story',
        description: 'review your story title'
    },
    submit: {
        defaultMessage: 'Submit',
        description:
            'button text that will trigger submission process for a story in a completed challenge'
    },
    uploadedImage: {
        defaultMessage: 'uploaded image preview',
        description: 'alt text for the uploaded image'
    },
    keepPrivateButtonText: {
        defaultMessage: 'Keep Private',
        description: 'Submit story button text'
    },
    shareStoryButtonText: {
        defaultMessage: 'Share Story',
        description: 'Submit story button text'
    },
    makeABiggerImpact: {
        defaultMessage: 'Make a Bigger Impact',
        description: 'Make a bigger impact dialog title'
    },
    youHaveLeftStoryPrivateText: {
        defaultMessage:
            "You've left your story private. By sharing it publicly, you'll inspire more people to start and sustain their own well-being journeys. Would you like to share it?",
        description: 'The user has left the stroy private text'
    }
})

const ReviewYourStory: React.FC<ReviewYourStoryProps> = ({ challenge }) => {
    const theme = useTheme()
    const intl = useIntl()

    const isConfirmationDialogEnabled = useIsFeatureEnabled(
        'IsConfirmationDialogEnabled',
        false,
        true
    ) as boolean

    const [isSocialChecked, setIsSocialChecked] = useState(false)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [hasSeenConfirmationDialog, setHasSeenConfirmationDialog] =
        useState(false)

    const { submitLoading, onSubmit: submitStory } = useStorySubmissionData()
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)
    const { termsAndConditionsUrl } = useStorySubmissionContext()

    const { answeredQuestions, visibility, onVisibility } =
        useStorySubmissionStateProvider()

    const { textQuestionData, imageQuestionData } = useMemo(() => {
        const textQuestion: QuestionData[] = []
        const imageQuestion: QuestionData[] = []

        answeredQuestions?.forEach((q) => {
            if (q.type === QuestionType.text) {
                textQuestion.push(q)
            } else {
                imageQuestion.push(q)
            }
        })

        return {
            textQuestionData: textQuestion,
            imageQuestionData: imageQuestion
        }
    }, [answeredQuestions])

    useEffect(() => {
        setIsSocialChecked(visibility === Visibility.Public)
    }, [visibility])

    const onStoryTypeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked
            setIsSocialChecked(isChecked)
            onVisibility(isChecked ? Visibility.Public : Visibility.Private)
        },
        [onVisibility]
    )

    const closeConfirmationDialog = (isConfirmed = false) => {
        setHasSeenConfirmationDialog(true)
        setShowConfirmationDialog(false)
        if (isConfirmed) {
            setIsSocialChecked(true)
            onVisibility(Visibility.Public)
            submitStory()
        } else {
            onVisibility(Visibility.Private)
            submitStory()
        }
    }

    const handleConfirmClose = () => closeConfirmationDialog()

    const handleConfirmSubmit = () => closeConfirmationDialog(true)

    const skipConfirmationDialog =
        isWalmartPrizeChallenge ||
        !isConfirmationDialogEnabled ||
        isSocialChecked ||
        hasSeenConfirmationDialog

    const onStorySubmit = () => {
        if (skipConfirmationDialog) {
            submitStory()
            return
        }

        setShowConfirmationDialog(true)
    }

    return (
        <Box
            data-testid="review-your-story"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                mt: theme.spacing(2),
                pt: theme.spacing(22),
                [theme.breakpoints.down('md')]: {
                    pt: theme.spacing(3)
                }
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: theme.spacing(129.5),
                    flexGrow: 1
                }}
            >
                <CoreTypography variant="h1" color="primary.main">
                    {intl.formatMessage(messages.reviewYourStory)}
                </CoreTypography>
                <Box
                    sx={{
                        pt: theme.spacing(6),
                        pb: theme.spacing(3),
                        my: theme.spacing(6),
                        borderTop: `${theme.spacing(0.125)} solid ${
                            theme.palette.grey[300]
                        }`,
                        borderBottom: `${theme.spacing(0.125)} solid ${
                            theme.palette.grey[300]
                        }`
                    }}
                >
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            {textQuestionData?.map(
                                (questionData, index: number) => (
                                    <Box key={index} mb={3}>
                                        <ReviewYourStoryQuestionWrapper
                                            questionData={questionData}
                                        >
                                            <CoreTypography
                                                variant="body1"
                                                sx={{
                                                    wordBreak: 'break-word'
                                                }}
                                            >
                                                {questionData.answer}
                                            </CoreTypography>
                                        </ReviewYourStoryQuestionWrapper>
                                    </Box>
                                )
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {imageQuestionData?.map(
                                (questionData, index: number) => (
                                    <Box key={index} mb={3}>
                                        <ReviewYourStoryQuestionWrapper
                                            questionData={questionData}
                                        >
                                            <Box>
                                                {!!questionData?.answer
                                                    ?.src && (
                                                    <Box
                                                        sx={{
                                                            maxWidth:
                                                                theme.spacing(
                                                                    62.75
                                                                ),
                                                            border: `${theme.spacing(
                                                                0.125
                                                            )} solid ${
                                                                theme.palette
                                                                    .grey[300]
                                                            }`,
                                                            p: theme.spacing(3)
                                                        }}
                                                        data-testid="review-question-image"
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            image={
                                                                questionData
                                                                    ?.answer
                                                                    ?.src
                                                            }
                                                            sx={{
                                                                minHeight:
                                                                    '100%'
                                                            }}
                                                            alt={intl.formatMessage(
                                                                messages.uploadedImage
                                                            )}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        </ReviewYourStoryQuestionWrapper>
                                    </Box>
                                )
                            )}
                        </Grid>
                    </Grid>
                </Box>
                {!isWalmartPrizeChallenge && (
                    <GetFeatured
                        checked={isSocialChecked}
                        onStoryTypeChange={onStoryTypeChange}
                    />
                )}

                {isWalmartPrizeChallenge && (
                    <CoreTypography
                        customVariant="buttonLarge"
                        sx={{
                            fontSize: theme.spacing(2),
                            mb: theme.spacing(2)
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Submit your entry to be considered for cash prizes!"
                            description="description text describing how a user can get cash prizes by submitting a story on their completed challenge"
                        />
                        <TermsAndConditions
                            termsAndConditionsUrl={termsAndConditionsUrl}
                            challenge={challenge}
                        />
                    </CoreTypography>
                )}
                <Box>
                    <LoadingButton
                        disabled={submitLoading}
                        loading={submitLoading}
                        fixWidth={true}
                        variant="contained"
                        sx={{
                            py: theme.spacing(2),
                            px: theme.spacing(5),
                            borderRadius: theme.spacing(1),
                            mb: theme.spacing(8),
                            ...(!isWalmartPrizeChallenge && {
                                mt: theme.spacing(6)
                            })
                        }}
                        onClick={onStorySubmit}
                        loader={
                            <Box textAlign="center">
                                <CircularProgress color="accent" />
                            </Box>
                        }
                        data-testid="submit-button"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {intl.formatMessage(messages.submit)}
                        </CoreTypography>
                    </LoadingButton>
                </Box>

                <LeafDialog
                    dialogTitle={intl.formatMessage(messages.makeABiggerImpact)}
                    fullWidth
                    open={showConfirmationDialog}
                    onClose={handleConfirmClose}
                >
                    <DialogContent>
                        <CoreTypography variant="body1">
                            {intl.formatMessage(
                                messages.youHaveLeftStoryPrivateText
                            )}
                        </CoreTypography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="info"
                            onClick={handleConfirmClose}
                            size="medium"
                            variant="contained"
                        >
                            <CoreTypography customVariant="buttonLarge">
                                {intl.formatMessage(
                                    messages.keepPrivateButtonText
                                )}
                            </CoreTypography>
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleConfirmSubmit}
                            size="medium"
                            variant="contained"
                        >
                            <CoreTypography customVariant="buttonLarge">
                                {intl.formatMessage(
                                    messages.shareStoryButtonText
                                )}
                            </CoreTypography>
                        </Button>
                    </DialogActions>
                </LeafDialog>
            </Stack>
        </Box>
    )
}

export default ReviewYourStory
