import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import BarElement from '../BarElement'
interface BarChartProps {
    data: BarDatum[]
}

const gradient = {
    id: 'greenGradient',
    type: 'linearGradient',
    colors: [
        // Custom Colors...?
        { offset: 0, color: '#0B5D68' },
        { offset: 100, color: '#87CFD4' }
    ]
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const maxTotal = useMemo(
        () =>
            data.reduce((acc: number, curr: any) => {
                if (curr.value > acc) {
                    return curr.value
                }
                return acc
            }, 0),
        [data]
    )
    const { palette } = useTheme()
    return (
        <ResponsiveBar
            data={data}
            keys={['value']}
            indexBy="date"
            margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
            valueScale={{ type: 'linear', min: 0, max: maxTotal }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            enableGridY={false}
            axisTop={ReactNullValue}
            axisRight={ReactNullValue}
            animate={true}
            axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={ReactNullValue}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            role="application"
            ariaLabel="total plants chart"
            barAriaLabel={(e) =>
                e.id + ': ' + e.formattedValue + ' on date: ' + e.indexValue
            }
            barComponent={BarElement}
            defs={[gradient]}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontFamily: 'GT America LCG, sans-serif',
                            fontSize: 12,
                            color: palette.text.secondary
                        }
                    },
                    legend: {
                        text: {
                            fontFamily: 'GT America LCG, sans-serif',
                            fontSize: 12,
                            color: palette.text.secondary
                        }
                    }
                }
            }}
        />
    )
}

export default BarChart
