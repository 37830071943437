import { Divider, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import ContentCard from '../../../../../shared/components/elements/ContentCard/ContentCard'
import { getDurationInMinutes } from '../../../../../utils'
import CompletedArticlesDialog from './CompletedArticlesDialog'
import type { ArticleStats } from '../../../../../graphql/generated/autogenerated'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'

type CompletedArticlesProps = {
    articleStats: ArticleStats
}

const messages = defineMessages({
    article: {
        defaultMessage: 'Article',
        description: 'content type description'
    },
    watch: {
        defaultMessage: '{durationInMinutes} minute watch',
        description: 'description of the duration in minutes the video runs for'
    }
})

const CompletedArticles = ({ articleStats }: CompletedArticlesProps) => {
    const { formatNumber, formatMessage } = useIntl()
    const [openDialog, setOpenDialog] = useState(false)

    const { totalArticles, latestArticle, durationInMinutes } = useMemo(
        () => ({
            totalArticles: articleStats?.total,
            latestArticle:
                articleStats?.articles?.[0]?.articleDetail?.articleInfo,
            durationInMinutes: getDurationInMinutes(
                articleStats?.articles?.[0]?.articleDetail?.articleInfo
                    ?.durationSeconds ?? 0
            )
        }),
        [articleStats]
    )

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Completed Articles"
                            description="completed articles title"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={2}>
                        <Stack gap={2} direction="row" alignItems="center">
                            <CoreTypography
                                variant="h3"
                                fontSize={60}
                                textAlign="left"
                                color="text.secondary"
                            >
                                {formatNumber(totalArticles ?? 0)}
                            </CoreTypography>

                            <CoreTypography
                                variant="body2"
                                color="primary.main"
                            >
                                <FormattedMessage
                                    defaultMessage="These tips help you continue to build on your progress."
                                    description="article usage description"
                                />
                            </CoreTypography>
                        </Stack>
                        <Divider />
                        <ContentCard
                            image={latestArticle?.imageUrl ?? ''}
                            title={latestArticle?.title ?? ''}
                            contentType={formatMessage(messages.article)}
                            duration={formatMessage(messages.watch, {
                                durationInMinutes
                            })}
                            alignMedia="top"
                            description={latestArticle?.summary ?? ''}
                            elevation={1}
                        />
                    </Stack>
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View Article"
                            description="view article"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <CompletedArticlesDialog
                open={openDialog}
                articleStats={articleStats}
                onClose={() => setOpenDialog(false)}
            />
        </>
    )
}

export default memo(CompletedArticles)
