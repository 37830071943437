import { useState } from 'react'
import {
    useJoinCommunityChallengeMutation,
    useSetSleepHabitsMutation,
    useSetStepsCustomizationMutation,
    ChallengeTimePoint
} from '../../../graphql/generated/autogenerated'
import { SIGNUP_STEPS, SIGNUP_VARIANT } from '../../constants'
import useChallengeIntro from '../useChallengeIntro/useChallengeIntro'
import { useMultiStepFormContext } from '../../contexts/withMultiStepForm/withMultiStepForm'
import { CompanyChallengeForm } from './useCompanyChallengeForm'
import { useParams } from 'react-router-dom'

export const useCompanyChallengeSubmission = (
    formData: CompanyChallengeForm
) => {
    const { challengeId } = useParams<{ challengeId: string }>()
    const { challenge } = useChallengeIntro(challengeId, false)
    const [joinCommunityChallenge] = useJoinCommunityChallengeMutation()
    const [setSleepHabitsMutation] = useSetSleepHabitsMutation()
    const [setStepsCustomizationMutation] = useSetStepsCustomizationMutation()
    const { steps } = useMultiStepFormContext()

    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            const isCreateTeam =
                formData.signupVariant === SIGNUP_VARIANT.CREATE_TEAM
            const isJoinTeam =
                formData.signupVariant === SIGNUP_VARIANT.JOIN_TEAM
            const isInviteTeam =
                formData.signupVariant === SIGNUP_VARIANT.INVITE_TEAM

            const challengeId = challenge?.id
            const joinCommunity = joinCommunityChallenge({
                variables: {
                    challengeId,
                    ...((isJoinTeam || isInviteTeam) && {
                        socialGroupId: formData.joinTeam?.id
                    }),
                    ...(isCreateTeam && {
                        socialGroupName: formData.createTeam?.teamName || null,
                        socialGroupPublic: formData.createTeam?.isPublic
                    })
                }
            })

            const sleepMutation =
                steps.includes(SIGNUP_STEPS.SLEEP) &&
                setSleepHabitsMutation({
                    variables: {
                        challengeId,
                        answer: formData.sleepHabit ?? 0,
                        challengeTimePoint: ChallengeTimePoint.Start
                    }
                })

            const stepsMutation =
                steps.includes(SIGNUP_STEPS.ACTIVITY) &&
                setStepsCustomizationMutation({
                    variables: {
                        challengeId,
                        stepsAmount: formData.activityGoal
                    }
                })

            await Promise.all(
                [joinCommunity, sleepMutation, stepsMutation].filter(Boolean)
            )
        } catch (e) {
            // TODO: Error handling
        } finally {
            setLoading(false)
        }
    }

    return { onSubmit, loading }
}
