import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useUpdateDisplayNameMutation } from '../../../graphql/generated/autogenerated'
import SocialGroupAction from '../SocialGroupAction/SocialGroupAction'

export type CreateDisplayNameProps = {
    onSubmit: () => void
    externalAction?: () => Promise<void>
}

const messages = defineMessages({
    createName: {
        defaultMessage: 'Create Your Display Name',
        description: 'title of the create display name popup'
    },
    betterWithFriends: {
        defaultMessage:
            'Challenges are better with friends. Reminder: Anyone from your company will be able to join and see this group was created by you.',
        description:
            'message explaining that social groups are publicly available to anyone within the same company'
    },
    recommend: {
        defaultMessage: 'We recommend using the name you use at work.',
        description:
            'description recommending users to use the same name they use at work for their displayName'
    },
    yourDisplayName: {
        defaultMessage: 'Your Display Name',
        description: 'placeholder text for the display name text field'
    }
})

const CreateDisplayName: React.FC<CreateDisplayNameProps> = ({
    onSubmit,
    externalAction = () => Promise.resolve()
}) => {
    const intl = useIntl()
    const [displayName, setDisplayName] = React.useState<string>()

    const [updateDisplayName, { loading: updateDisplayNameLoading }] =
        useUpdateDisplayNameMutation({
            variables: {
                displayName: displayName ?? ''
            }
        })

    function onHandleSubmit() {
        onSubmit()
    }

    const setActionValue = useCallback(
        (value?: string) => setDisplayName(value),
        []
    )

    return (
        <Box data-testid="create-display-name">
            <SocialGroupAction
                loading={updateDisplayNameLoading}
                title={intl.formatMessage(messages.createName)}
                description={intl.formatMessage(messages.betterWithFriends)}
                caption={intl.formatMessage(messages.recommend)}
                actionValue={displayName}
                testId="create-display-name"
                inputPlaceholder={intl.formatMessage(messages.yourDisplayName)}
                onSubmitComplete={onHandleSubmit}
                mutation={updateDisplayName}
                onMutationComplete={(response) =>
                    response?.data?.socialGroups?.updateDisplayName
                }
                setActionValue={setActionValue}
                externalAction={externalAction}
            />
        </Box>
    )
}

export default CreateDisplayName
