// External imports
import { Button, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

// Internal imports
import StepperComponent from './components/Stepper'

export interface HeaderProps {
    step: number
    maxSteps: number
    attemptExit: () => void
}

const Header = ({ step, maxSteps, attemptExit }: HeaderProps) => {
    return (
        <Stack direction="column" gap={3}>
            <Stack direction="row" justifyContent="end">
                <Button
                    variant="text"
                    color="primary"
                    endIcon={<LeafIcon icon="close" fontSize={'small'} />}
                    onClick={attemptExit}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Exit"
                            description="exit button text"
                        />
                    </CoreTypography>
                </Button>
            </Stack>

            <StepperComponent totalSteps={maxSteps} currentStep={step} />
        </Stack>
    )
}

export default Header
