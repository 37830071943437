import { Card, Stack } from '@mui/material'
import { Datum } from '@nivo/line'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import IconChart from '../../../../../components/elements/Charts/IconChart/IconChart'
import IconYAxis from '../../../../../components/elements/Charts/IconChart/IconYAxis'
import { useFeelingIcon } from '../../../../../hooks/useFeelingIcon/useFeelingIcon'
import { useActiveRefDimensions } from '../../../../../shared/hooks/useActiveRefDimensions/useActiveRefDimensions'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import FeelingIconAvatar from '../../Shared/DailyGoals/Goals/FeelingGoal/FeelingIconAvatar'
import ViewMoreDialog from '../ViewMoreDialog'
import type {
    FeelingQuestionStats,
    SleepHabits,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'

export type SleepReflectionsDialogProps = {
    challenge: UnifiedChallenge
    data: {
        sleepHabits: SleepHabits
        feelingQuestionStats: FeelingQuestionStats
    }
    open: boolean
    onClose: () => void
}

const messages = defineMessages({
    dayStat: {
        defaultMessage: 'Day {day}',
        description: 'day number'
    }
})

const SleepReflectionsDialog: React.FC<SleepReflectionsDialogProps> = ({
    challenge,
    data: { sleepHabits, feelingQuestionStats },
    open,
    onClose
}) => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const getFeelingIcon = useFeelingIcon()
    const { activeComponentWidth, getRef } = useActiveRefDimensions()
    const scrollContainerRef = useRef<HTMLDivElement>(ReactNullValue)
    const [numBarsToFit, setNumBarsToFit] = useState<number>()

    useEffect(() => {
        if (activeComponentWidth) {
            const calculatedNumBars = Math.floor(activeComponentWidth / 60)
            setNumBarsToFit(calculatedNumBars)
        }
        setTimeout(() => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft =
                    scrollContainerRef.current.scrollWidth
            }
        }, 0)
    }, [activeComponentWidth])

    const mappedSleepData = useMemo<Datum[]>(() => {
        let hasSetData = false
        const days = Array.from(Array(challenge?.duration)).map(
            (_, index) =>
                ({
                    x: formatMessage(messages.dayStat, { day: index + 1 }),
                    y: undefined
                } as Datum)
        )
        feelingQuestionStats?.dailyStats?.forEach((stat) => {
            // FeelingQuestionDailyStats.challengeDay is 1 indexed
            const day = days[stat.challengeDay - 1]

            if (day) {
                day.y = stat.answer
                hasSetData = true
            }
        })

        // Nivo chart does not play nice when no answers are supplied at all,
        // So we just force in the initially added answer during sign up
        if (!hasSetData) {
            days[0].y = sleepHabits?.startAnswer
        }

        return days
    }, [challenge, feelingQuestionStats, sleepHabits, formatMessage])

    const getIconAvatar = useCallback(
        (answer: number) => {
            const { icon, label } = getFeelingIcon(answer)
            return <FeelingIconAvatar icon={icon} label={label} />
        },
        [getFeelingIcon]
    )

    const icons = useMemo(
        () => ({
            ['1']: getIconAvatar(1),
            ['2']: getIconAvatar(2),
            ['3']: getIconAvatar(3),
            ['4']: getIconAvatar(4),
            ['5']: getIconAvatar(5)
        }),
        [getIconAvatar]
    )

    // Calculate the dynamic width based on the number of data points and bar width
    const barChartWidth =
        numBarsToFit !== ReactNullValue &&
        mappedSleepData.length > Number(numBarsToFit)
            ? mappedSleepData.length * 60
            : '100%'

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={4.75}>
                <CoreTypography variant="h3" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Here’s a look at your daily sleep reflection over the course of the Challenge."
                        description="sleep reflection title"
                    />
                </CoreTypography>
                <Card>
                    <Stack p={2} gap={3}>
                        <CoreTypography
                            variant="overline"
                            color="text.disabled"
                        >
                            <FormattedMessage
                                defaultMessage="Your Sleep Reflection"
                                description="sleep reflection title"
                            />
                        </CoreTypography>
                        <Stack
                            width="100%"
                            overflow={
                                numBarsToFit !== ReactNullValue &&
                                mappedSleepData.length > Number(numBarsToFit)
                                    ? 'auto hidden'
                                    : 'hidden'
                            }
                            direction="row"
                            position="relative"
                            ref={scrollContainerRef}
                        >
                            <Stack
                                bgcolor={palette.common.white}
                                boxShadow={`20px 1px 20px ${palette.common.white}`}
                                position="sticky"
                                minWidth={40}
                                pb={14}
                                pt={3.5}
                                zIndex={1}
                                bottom={0}
                                left={0}
                            >
                                <IconYAxis icons={icons} />
                            </Stack>
                            <Stack
                                ref={getRef}
                                width={barChartWidth}
                                height={500}
                                flexShrink={0}
                                pl={5}
                            >
                                <IconChart
                                    data={mappedSleepData}
                                    icons={icons}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(SleepReflectionsDialog)
