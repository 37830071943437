import { Box, Stack } from '@mui/material'
import { Datum, ResponsiveLine } from '@nivo/line'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { useActiveRefDimensions } from '../../../../shared/hooks/useActiveRefDimensions/useActiveRefDimensions'
import { ReactNullValue } from '../../../../shared/utils/Nulls'

interface IconChartProps {
    data: Datum[]
    icons: { [key: string]: React.ReactNode }
}

const IconChart: React.FC<IconChartProps> = ({ data, icons }) => {
    const { palette, spacing } = useTheme()
    const { activeComponentHeight: stackHeight, getRef: stackRef } =
        useActiveRefDimensions()
    const { activeComponentWidth: chartWidth, getRef: chartRef } =
        useActiveRefDimensions()

    const gradProps = {
        gradientUnits: 'userSpaceOnUse',
        x1: '0',
        y1: '0',
        x2: '0',
        y2: spacing(stackHeight)
    }

    return (
        <Box position="relative" width="100%" height="100%">
            <Box position="absolute">
                <svg>
                    <defs>
                        <linearGradient id="someGradientId" {...gradProps}>
                            <stop
                                offset="1%"
                                stopColor={palette?.secondary?.main}
                            />
                            <stop
                                offset="100%"
                                stopColor={palette?.background.paper}
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </Box>
            <Stack width="100%" height="100%" ref={stackRef}>
                <Box width="100%" height="100%" ref={chartRef}>
                    <ResponsiveLine
                        data={[
                            {
                                id: 'feeling',
                                data
                            }
                        ]}
                        xScale={{ type: 'point' }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        colors={['url(#someGradientId)']}
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 5
                        }}
                        margin={{ top: 50, right: 20, left: 20, bottom: 50 }}
                        yFormat=" >-.2f"
                        curve="monotoneX"
                        axisTop={ReactNullValue}
                        axisRight={ReactNullValue}
                        animate={true}
                        axisLeft={ReactNullValue}
                        role="application"
                        enablePoints={false}
                        enableArea={true}
                        enableGridX={false}
                        enableGridY={false}
                        isInteractive={false}
                        useMesh={true}
                        areaOpacity={1}
                        theme={{
                            axis: {
                                ticks: {
                                    text: {
                                        fontFamily:
                                            'GT America LCG, sans-serif',
                                        fontSize: 12,
                                        color: palette.text.secondary
                                    }
                                },
                                legend: {
                                    text: {
                                        fontFamily:
                                            'GT America LCG, sans-serif',
                                        fontSize: 12,
                                        color: palette.text.secondary
                                    }
                                }
                            }
                        }}
                    />
                </Box>
                <Stack
                    position="absolute"
                    direction="row"
                    width={`calc(${spacing(chartWidth)})`}
                    height="100%"
                    justifyContent="space-between"
                    alignItems="end"
                >
                    {data?.map((value, index) => {
                        const icon = icons[String(value.y)]

                        return (
                            <Box
                                key={index}
                                mb={
                                    ((stackHeight - 12.5) / 5) *
                                        Number(value.y) +
                                    4
                                }
                            >
                                {icon ? (
                                    icon
                                ) : (
                                    <Box width="40px" height="40px" />
                                )}
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </Box>
    )
}

export default IconChart
