import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    type FeelingButtonPromiseProps,
    FeelingsCard
} from '../../../../Shared/DailyGoals/Goals'

type SleepHabitsStepProps = {
    sleepHabit?: number
    setSleepHabit: (sleepHabit: number) => void
}

const SleepHabitsStep: React.FC<SleepHabitsStepProps> = ({
    sleepHabit,
    setSleepHabit
}) => {
    return (
        <Stack gap={2}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Take a moment to reflect on your current sleep routine. How do you feel about your bedtime habits?"
                    description="sleep goal question"
                />
            </CoreTypography>
            <Box width="100%" p={1}>
                <FeelingsCard
                    feelingAnswer={sleepHabit}
                    darkBackground={true}
                    promise={(props: FeelingButtonPromiseProps) => {
                        setSleepHabit(props.answer)

                        return Promise.resolve()
                    }}
                />
            </Box>
        </Stack>
    )
}

export default memo(SleepHabitsStep)
