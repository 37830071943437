import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant14: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#236807',
    colorLayer2 = '#D6D4FB',
    colorLayer3 = '#6F4D2B',
    colorLayer4 = '#81C254',
    colorLayer5 = '#4C67A1',
    colorLayer6 = '#E93323',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M25.1052 15.5537C25.1052 15.5537 40.1952 21.8537 43.7852 27.7537C47.3752 33.6537 46.0252 49.9537 46.0252 49.9537L25.1052 15.5537Z"
                fill={colorLayer1}
            />
            <path
                d="M39.3073 34.2253L38.3949 34.6347L50.2606 61.0741L51.173 60.6647L39.3073 34.2253Z"
                fill={colorLayer1}
            />
            <path
                d="M20.6152 49.1137L25.4852 51.2837C29.4752 53.0637 34.0052 50.2937 34.2452 45.9337L34.5452 40.4337L20.6252 49.1137H20.6152Z"
                fill={colorLayer1}
            />
            <path
                d="M47.2552 85.5737C43.6352 67.7337 27.2152 44.8937 27.0452 44.6637L27.8552 44.0737C28.0252 44.3037 44.5752 67.3137 48.2352 85.3637L47.2552 85.5637V85.5737Z"
                fill={colorLayer1}
            />
            <path
                d="M49.3952 32.0537C49.5352 32.4737 48.8052 33.2837 47.7552 33.8737L32.3052 42.5537L44.6952 40.7537C45.5352 40.6337 45.6952 41.1137 45.0552 41.8237C44.4152 42.5337 43.2152 43.2137 42.3652 43.3337L29.9752 45.1337L32.0552 51.2637C32.1952 51.6837 31.4652 52.4937 30.4152 53.0837C29.3652 53.6737 28.4052 53.8137 28.2552 53.3937L26.1752 47.2637L16.7252 57.7337C16.0852 58.4437 14.8852 59.1237 14.0352 59.2437C13.1852 59.3637 13.0352 58.8837 13.6752 58.1737L23.1252 47.7037L7.67524 56.3837C6.62524 56.9737 5.66523 57.1137 5.51523 56.6937C5.36523 56.2737 6.10523 55.4637 7.15523 54.8737L22.6052 46.1937L10.2152 47.9937C9.37525 48.1137 9.21525 47.6337 9.85525 46.9237C10.4952 46.2137 11.6952 45.5337 12.5452 45.4137L24.9352 43.6137L25.0252 43.8937L27.4452 44.3737L28.8252 41.7637L28.7352 41.4837L38.1852 31.0137C38.8252 30.3037 40.0252 29.6237 40.8752 29.5037C41.7152 29.3837 41.8752 29.8637 41.2352 30.5737L31.7852 41.0437L47.2352 32.3637C48.2852 31.7737 49.2452 31.6337 49.3952 32.0537ZM27.4652 44.3737L28.3152 41.9637L28.7452 41.4937L26.6652 35.3637C26.5252 34.9437 25.5552 35.0837 24.5052 35.6737C23.4552 36.2637 22.7152 37.0737 22.8652 37.4937L24.9452 43.6237L25.5052 43.5437L27.4552 44.3837L27.4652 44.3737Z"
                fill={colorLayer2}
            />
            <path
                d="M27.4552 44.3737L28.3052 41.9637L28.7352 41.4937L28.4352 40.5937C27.7652 40.8837 27.0652 41.2237 26.3652 41.6137C25.7552 41.9537 25.1752 42.3237 24.6352 42.6937L24.9452 43.6137L25.5052 43.5337L27.4552 44.3737Z"
                fill={colorLayer5}
            />
            <path
                d="M33.1352 39.5537C32.3552 39.4637 31.3052 39.6137 30.1052 39.9837L28.7452 41.4937L28.8352 41.7737L27.4552 44.3837L25.0352 43.9037L24.9452 43.6237L23.0452 43.9037C21.9952 44.7837 21.1752 45.6737 20.6652 46.4837L22.6252 46.2037L20.1452 47.5937C20.0552 47.9437 20.0352 48.2637 20.1352 48.5337C20.2252 48.7837 20.3952 48.9737 20.6252 49.1237L23.1352 47.7137L21.5952 49.4137C22.3952 49.4937 23.4452 49.3337 24.6552 48.9537L26.1752 47.2637L26.5052 48.2437C27.0952 47.9837 27.6952 47.6837 28.3052 47.3437C29.0152 46.9437 29.6752 46.5237 30.2952 46.0837L29.9752 45.1337L31.8552 44.8637C32.8752 43.9837 33.6552 43.0937 34.1252 42.2937L32.2952 42.5637L34.5352 41.3037C34.6152 40.9837 34.6152 40.6937 34.5352 40.4337C34.4452 40.1637 34.2552 39.9537 33.9952 39.8137L31.7852 41.0537L33.1352 39.5537Z"
                fill={colorLayer5}
            />
            <path
                d="M28.1817 45.841C30.3331 44.5533 31.7048 42.8872 31.2453 42.1194C30.7859 41.3517 28.6693 41.7732 26.5178 43.0609C24.3663 44.3485 22.9946 46.0147 23.4541 46.7824C23.9136 47.5501 26.0302 47.1286 28.1817 45.841Z"
                fill={colorLayer3}
            />
            <path
                d="M27.4552 44.3737L19.1752 50.4437L18.2652 51.4437L19.7452 50.6137L27.4552 44.3737L31.6052 46.1537L32.8052 45.9737L32.6052 45.3837L27.4552 44.3737L29.2752 39.2537L30.1852 38.2437L30.3852 38.8337L27.4552 44.3737L23.3052 42.6037L22.1152 42.7737L22.3152 43.3637L27.4552 44.3737L24.5352 49.9137L24.7352 50.5037L25.6452 49.5037L27.4552 44.3737L36.2552 41.3437L37.7352 40.5037L36.5452 40.6837L27.4552 44.3737L35.1652 38.1437L36.6552 37.3037L35.7452 38.3137L27.4552 44.3737L18.6652 47.4137L17.1752 48.2437L18.3652 48.0737L27.4552 44.3737Z"
                fill={colorLayer6}
            />
            <path
                d="M49.3252 85.5337C46.5952 54.3237 62.0152 24.6337 62.1752 24.3437L63.0552 24.8137C62.9052 25.1037 47.6152 54.5337 50.3252 85.4537L49.3252 85.5437V85.5337Z"
                fill={colorLayer1}
            />
            <path
                d="M86.7052 24.5737C86.7052 25.7237 85.7752 26.6637 84.6152 26.6637H67.6552L79.6452 38.6537C80.4552 39.4637 80.4552 40.7937 79.6452 41.6037C78.8352 42.4137 77.5052 42.4137 76.6952 41.6037L64.7052 29.6137V46.5737C64.7052 47.7237 63.7752 48.6637 62.6152 48.6637C61.4552 48.6637 60.5252 47.7337 60.5252 46.5737V29.6137L48.5352 41.6037C47.7252 42.4137 46.3952 42.4137 45.5852 41.6037C44.7752 40.7937 44.7752 39.4637 45.5852 38.6537L57.5752 26.6637H40.6152C39.4652 26.6637 38.5252 25.7337 38.5252 24.5737C38.5252 23.4137 39.4552 22.4837 40.6152 22.4837H57.5752L45.5852 10.4937C44.7752 9.68371 44.7752 8.3537 45.5852 7.5437C46.3952 6.7337 47.7252 6.7337 48.5352 7.5437L60.5252 19.5337V20.3037L62.6152 24.5737L64.7052 20.3037V19.5337L76.6952 7.5437C77.5052 6.7337 78.8352 6.7337 79.6452 7.5437C80.4552 8.3537 80.4552 9.68371 79.6452 10.4937L67.6552 22.4837H84.6152C85.7652 22.4837 86.7052 23.4137 86.7052 24.5737ZM62.6252 24.5737L64.1652 20.0837L64.7052 19.5437V2.58371C64.7052 1.43371 63.7752 0.493713 62.6152 0.493713C61.4552 0.493713 60.5252 1.42371 60.5252 2.58371V19.5437L61.0652 20.0837L62.6052 24.5737H62.6252Z"
                fill={colorLayer2}
            />
            <path
                d="M62.6152 24.5737L64.1552 20.0837L64.6952 19.5437V17.0637C63.9852 16.8537 63.2352 16.7337 62.4652 16.7337C61.7952 16.7337 61.1452 16.8237 60.5252 16.9837V19.5437L61.0652 20.0837L62.6052 24.5737H62.6152Z"
                fill={colorLayer5}
            />
            <path
                d="M69.3652 20.7737C68.6752 19.5437 67.6552 18.5137 66.4352 17.8037L64.7052 19.5337V20.3037L62.6152 24.5737L60.5252 20.3037V19.5337L58.6852 17.6937C57.4452 18.3737 56.4052 19.3737 55.6852 20.5937L57.5852 22.4937H54.8652C54.6752 23.1837 54.5552 23.9037 54.5552 24.6537C54.5552 25.3537 54.6552 26.0237 54.8252 26.6637H57.5852L55.6352 28.6137C56.3452 29.8337 57.3752 30.8537 58.6052 31.5437L60.5352 29.6137V32.3137C61.1552 32.4737 61.8052 32.5637 62.4752 32.5637C63.2552 32.5637 63.9952 32.4437 64.7052 32.2337V29.6137L66.5252 31.4337C67.7352 30.7037 68.7452 29.6737 69.4252 28.4337L67.6552 26.6637H70.1152C70.2852 26.0237 70.3852 25.3437 70.3852 24.6537C70.3852 23.9037 70.2752 23.1837 70.0752 22.4937H67.6552L69.3652 20.7837V20.7737Z"
                fill={colorLayer5}
            />
            <path
                d="M62.4652 28.9837C64.8622 28.9837 66.8052 27.0406 66.8052 24.6437C66.8052 22.2468 64.8622 20.3037 62.4652 20.3037C60.0683 20.3037 58.1252 22.2468 58.1252 24.6437C58.1252 27.0406 60.0683 28.9837 62.4652 28.9837Z"
                fill={colorLayer3}
            />
            <path
                d="M62.6152 24.5737L53.0752 27.8537L51.9252 29.0037H53.5552L62.6152 24.5737L65.8952 34.1137L67.0452 35.2737V33.6337L62.6152 24.5737L65.8952 15.0337L67.0452 13.8837V15.5137L62.6152 24.5737L59.3452 15.0337L58.1852 13.8837V15.5137L62.6152 24.5737L58.1852 33.6337V35.2737L59.3452 34.1137L62.6152 24.5737L71.6752 29.0037H73.3152L72.1552 27.8537L62.6152 24.5737L71.6752 20.1437H73.3152L72.1552 21.3037L62.6152 24.5737L53.5552 20.1437H51.9252L53.0752 21.3037L62.6152 24.5737Z"
                fill={colorLayer6}
            />
            <path
                d="M49.0952 77.6637C49.0352 77.1937 47.8952 65.9137 71.4752 54.5837L71.9052 55.4837C49.0552 66.4637 50.0652 77.4337 50.0752 77.5437L49.0852 77.6637H49.0952Z"
                fill={colorLayer1}
            />
            <path
                d="M51.4652 70.6637C51.4652 70.6637 53.1652 59.2837 61.8052 54.7937C70.4552 50.3037 81.5252 46.8437 81.5252 46.8437L78.4152 52.5637C76.7552 55.6137 70.0252 66.0837 57.4252 68.5237L51.4552 70.6637H51.4652Z"
                fill={colorLayer4}
            />
            <path
                d="M81.5352 46.8437C81.5352 46.8437 80.9052 47.0437 79.8252 47.4037C75.0052 50.6537 59.2852 61.5037 51.6452 69.7537C51.5252 70.3237 51.4652 70.6637 51.4652 70.6637L52.4452 70.3137C60.4552 61.7137 77.2852 50.2737 81.0352 47.7637L81.5352 46.8537V46.8437Z"
                fill={colorLayer1}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant14
