import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'
import AverageWaterIntake from '../../Shared/AverageWaterIntake'
import type {
    UnifiedChallenge,
    UnifiedChallengesQuery
} from '../../../../../graphql/generated/autogenerated'
import HydrationRecapDialog from './HydrationRecapDialog'

type HydrationRecapProps = {
    challenge: UnifiedChallenge
    data: Pick<
        UnifiedChallengesQuery,
        | 'getChallengeStats'
        | 'getChallengeTotalWater'
        | 'getMeasureUnitSettings'
        | 'getUserTotalWater'
    >
}

const HydrationRecap = ({ challenge, data }: HydrationRecapProps) => {
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Your hydration"
                            description="hydration card title"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Your hydration activity over the course of the challenge."
                            description="hydration card description"
                        />
                    }
                />
                <RecapWidgetContent>
                    <AverageWaterIntake
                        elevation={6}
                        userTotalWater={data.getUserTotalWater}
                        averageIndividualWater={
                            data.getChallengeStats.waterStats
                                ?.averageIndividualWater
                        }
                    />
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View details"
                            description="view details button"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <HydrationRecapDialog
                data={data}
                challenge={challenge}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
        </>
    )
}

export default HydrationRecap
