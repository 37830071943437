import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import TeamRecapDialog from './TeamRecapDialog'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'
import type {
    SocialGroup,
    UnifiedChallenge,
    CommunityGardenInfo
} from '../../../../../graphql/generated/autogenerated'

type TeamRecapProps = {
    challenge: UnifiedChallenge
    socialGroup: SocialGroup
    data: CommunityGardenInfo[]
}

const TeamRecap = ({ data, socialGroup, challenge }: TeamRecapProps) => {
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="your team"
                            description="your team title"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={2}>
                        <Stack gap={2} direction="row" alignItems="center">
                            <CoreTypography
                                variant="body1"
                                fontSize={24}
                                textAlign="left"
                                color="text.secondary"
                            >
                                {socialGroup?.name}
                            </CoreTypography>
                        </Stack>
                    </Stack>
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View team garden"
                            description="View team garden button"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <TeamRecapDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                groupedTeamPlants={data}
                challenge={challenge}
            />
        </>
    )
}

export default memo(TeamRecap)
