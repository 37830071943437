// React and React-related
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

// External libraries
import { Stack, Tab, Tabs } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

// Types
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'

// Utils
import { isSoloChallenge } from '../../../../utils/challenge'

interface ChallengeTabsProps {
    challenge?: UnifiedChallenge
    tabIndex: number
    setTabIndex: (tabIndex: number) => void
}

const ChallengeTabs: React.FC<ChallengeTabsProps> = ({
    challenge,
    tabIndex,
    setTabIndex
}) => {
    const soloChallenge = isSoloChallenge(challenge)
    const challengeStarted =
        challenge?.dayOfChallenge && challenge?.dayOfChallenge > 0

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue)
    }

    // If the challenge is a solo challenge or the challenge has not started, don't render the tabs
    if (soloChallenge || !challengeStarted) return null

    return (
        <Stack>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="challenge tabs"
            >
                <Tab
                    label={
                        <CoreTypography variant="h6" component="p">
                            <FormattedMessage
                                defaultMessage="Your Goals & progress"
                                description="tab text for your goals"
                            />
                        </CoreTypography>
                    }
                />
                <Tab
                    label={
                        <CoreTypography variant="h6" component="p">
                            <FormattedMessage
                                defaultMessage="Community Activity"
                                description="tab text for community activity"
                            />
                        </CoreTypography>
                    }
                />
            </Tabs>
        </Stack>
    )
}

export default memo(ChallengeTabs)
