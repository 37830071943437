import React, { useEffect, useMemo } from 'react'
import { Stack } from '@mui/system'
import { DialogContent } from '@mui/material'
import { useMultiStepFormContext } from '../../../../contexts/withMultiStepForm/withMultiStepForm'

type MultiStepFormContainerProps = {
    children: React.ReactNode
}

const MultiStepFormContainer: React.FC<MultiStepFormContainerProps> = ({
    children
}) => {
    const { currentStepName, setCurrentStepName, setSteps, setTotalSteps } =
        useMultiStepFormContext()

    const childrenArray = useMemo(
        () => React.Children.toArray(children),
        [children]
    )
    const currentChild = childrenArray.find(
        (child) => (child as React.ReactElement).props.name === currentStepName
    )

    useEffect(() => {
        const names = childrenArray.map((child: any) => child.props.name)

        setSteps((prevSteps) => {
            if (JSON.stringify(prevSteps) !== JSON.stringify(names)) {
                return names
            }
            return prevSteps
        })

        setTotalSteps((prevTotal) => {
            if (prevTotal !== names.length) {
                return names.length
            }
            return prevTotal
        })
    }, [childrenArray])

    useEffect(() => {
        if (!currentStepName && childrenArray.length > 0) {
            const firstStepName = (childrenArray[0] as React.ReactElement).props
                .name

            setCurrentStepName((prevStepName: any) => {
                if (prevStepName !== firstStepName) {
                    return firstStepName
                }
                return prevStepName
            })
        }
    }, [childrenArray, currentStepName])

    return (
        <DialogContent>
            <Stack spacing={2}>{currentChild}</Stack>
        </DialogContent>
    )
}

export default MultiStepFormContainer
