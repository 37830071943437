import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { ChallengeType } from '../../shared'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { FEATURE_FLAGS } from '../../constants'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'

export interface NavigateToChallengeProps {
    challengeId: string
    participationId?: string
    challengeName: string
    challengeType: ChallengeType
    isActive?: boolean
    isPast?: boolean
}

export const useNavigateToChallenge = (props: NavigateToChallengeProps) => {
    const history = useHistory()
    const hasChallengesV2Access = useIsFeatureEnabled(
        FEATURE_FLAGS.HAS_CHALLENGES_V2_ACCESS,
        false,
        true
    )

    const getRoute = useCallback(
        (isPast: boolean, isActive: boolean, challengeType: ChallengeType) => {
            if (
                challengeType === ChallengeType.group ||
                challengeType === ChallengeType.personal
            ) {
                if (isPast) {
                    return ROUTES.PAST_GROUP_CHALLENGE_HOME
                } else if (isActive) {
                    return ROUTES.GROUP_CHALLENGE_HOME
                } else {
                    return ROUTES.GROUP_CHALLENGE_TEMPLATE
                }
            } else if (challengeType === ChallengeType.company) {
                if (isActive) {
                    return ROUTES.COMPANY_CHALLENGE_HOME
                } else {
                    return ROUTES.COMPANY_CHALLENGE_INTRO
                }
            } else {
                if (isPast) {
                    return ROUTES.SINGLE_PAST_CHALLENGES
                } else {
                    return ROUTES.CHALLENGE
                }
            }
        },
        []
    )

    const navigate = useCallback(() => {
        Avo.challengeSelected({
            activityType: 'challenge_selected',
            challengeId: props.challengeId,
            challengeTheme: props.challengeName,
            challengeType: props.challengeType,
            dayNumber: null,
            featureType: 'challenge',
            tabName: 'Home',
            teamId: null,
            teamType: null
        })

        const route = hasChallengesV2Access
            ? ROUTES.CHALLENGE_V2
            : getRoute(
                  Boolean(props.isPast),
                  Boolean(props.isActive),
                  props.challengeType
              )

        history.push(
            generatePath(route, {
                challengeId: props.challengeId,
                userChallengeActivityId: props.participationId
            }),
            {
                isPast: props.isPast
            }
        )
    }, [props, getRoute, history])

    return { navigate }
}

export default useNavigateToChallenge
