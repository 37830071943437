import { GQLNull } from '../../../shared/utils/Nulls'
import {
    ActivityStats,
    ArticleStats,
    ChallengeUserDailyGoal,
    CommunityGardenInfo,
    FeelingQuestionStats,
    MeasureUnit,
    MicrostepStats,
    ResetStats,
    SleepHabits,
    UserPlantInfo,
    WaterStats
} from '../../../graphql/generated/autogenerated'

export interface GoalChallengeDataValue {
    // user solo activity
    userActivityRank?: number
    userDisplayName?: string
    userTotalPoints?: number
    // teams plant
    teamPlantsRank?: number
    teamPlantsTotalPoints?: number
    teamPlantsRankDisplayName?: string
    // team activity
    teamActivityRank?: number
    teamActivityRankDisplayName?: string
    teamActivityRankTotalPoints?: number
    // user solo plants
    userPlantsRank?: number
    userPlantsRankDisplayName?: string
    userPlantsTotalPoints?: number
    waterIntake: number
    feelingAnswer?: number
    feelingQuestionStats?: FeelingQuestionStats
    sleepHabits?: SleepHabits
    measureUnitSettings: MeasureUnit | GQLNull
    challengeTotalWater?: number
    userTotalWater: number
    teamTotalWater: number
    challengeTotalSteps?: number
    userTodaySteps?: number
    userTotalSteps?: number
    thresholdTotal: number
    thresholdValue: number
    thresholdAchieved: number
    activityGoal: number
    goals: ChallengeUserDailyGoal[]
    totalUserPlants?: number
    userPlant?: UserPlantInfo
    groupedCommunityPlants: CommunityGardenInfo[]
    groupedTeamPlants: CommunityGardenInfo[]
    plants: UserPlantInfo[]
    teamPlants: UserPlantInfo[]
    articleStats?: ArticleStats
    microstepStats?: MicrostepStats
    resetStats?: ResetStats
    waterStats?: WaterStats
    activityStats?: ActivityStats
    loading: boolean
    error: boolean
    refetch: () => Promise<unknown>
}

export const defaultContextValue: GoalChallengeDataValue = {
    // user solo activity
    userActivityRank: 0,
    userDisplayName: '',
    userTotalPoints: 0,
    // teams plant
    teamPlantsRank: 0,
    teamPlantsTotalPoints: 0,
    teamPlantsRankDisplayName: '',
    // team activity
    teamActivityRank: 0,
    teamActivityRankDisplayName: '',
    teamActivityRankTotalPoints: 0,
    // user solo plants
    userPlantsRank: 0,
    userPlantsRankDisplayName: '',
    userPlantsTotalPoints: 0,
    waterIntake: 0,
    feelingAnswer: undefined,
    feelingQuestionStats: undefined,
    sleepHabits: undefined,
    measureUnitSettings: undefined,
    challengeTotalWater: undefined,
    userTotalWater: 0,
    teamTotalWater: 0,
    challengeTotalSteps: undefined,
    userTodaySteps: undefined,
    userTotalSteps: undefined,
    thresholdTotal: 0,
    thresholdValue: 0,
    thresholdAchieved: 0,
    activityGoal: 0,
    goals: [],
    totalUserPlants: undefined,
    userPlant: undefined,
    groupedCommunityPlants: [],
    groupedTeamPlants: [],
    plants: [],
    teamPlants: [],
    articleStats: undefined,
    microstepStats: undefined,
    resetStats: undefined,
    waterStats: undefined,
    activityStats: undefined,
    loading: false,
    error: false,
    refetch: () => Promise.resolve(undefined)
}
