import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../../../graphql/generated/autogenerated'
import { withMultiStepForm } from '../../../../../contexts/withMultiStepForm/withMultiStepForm'
import useChallengeTemplateSignup from '../../../../../hooks/useChallengeTemplateSignup/useChallengeTemplateSignup'
import IntroTemplateChallengeDialog from './IntroTemplateChallengeDialog'

type IntroCreateChallengeDialogProps = {
    open: boolean
    groupChallengeType?: GroupChallengeType
    onClose: () => void
    challenge?: UnifiedChallenge
}

const IntroCreateChallengeDialog: React.FC<IntroCreateChallengeDialogProps> = ({
    open,
    groupChallengeType,
    onClose,
    challenge
}) => {
    const {
        formData,
        updateFormData,
        setIsDisplayNameValid,
        loading,
        onCreate
    } = useChallengeTemplateSignup({
        groupChallengeType,
        challenge
    })

    if (!challenge) return null

    return (
        <IntroTemplateChallengeDialog
            dialogTitle={
                <FormattedMessage
                    defaultMessage="Start this Challenge"
                    description="Create the Challenge dialog title"
                />
            }
            open={open}
            challenge={challenge}
            groupChallengeType={groupChallengeType}
            formData={formData}
            updateFormData={updateFormData}
            setIsDisplayNameValid={setIsDisplayNameValid}
            onClose={onClose}
            onSubmit={onCreate}
            loading={loading}
            onSubmitButtonLabel={
                <FormattedMessage
                    defaultMessage="Create Challenge"
                    description="Create Challenge button text"
                />
            }
        />
    )
}

export default withMultiStepForm(IntroCreateChallengeDialog)
