import { Box, Card, Stack } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    GroupChallengeType,
    useSetGroupChallengeTypeMutation,
    type UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../../../../contexts/withCompanyChallengeProvider'
import ConfirmParticipation from './ConfirmParticipation'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'

const messages = defineMessages({
    groupChallengeChangeTitle: {
        defaultMessage:
            'Make this a Group Challenge and invite your team to join in on the fun.',
        description: 'title for group challenge change'
    },
    individualChallengeChangeTitle: {
        defaultMessage:
            'Make this Challenge personal and take it on by yourself.',
        description: 'title for individual challenge change'
    },
    groupChallengeChangeButtonTitle: {
        defaultMessage: 'Create a Group Challenge',
        description: 'button title for group challenge change'
    },
    individualChallengeChangeButtonTitle: {
        defaultMessage: 'Create an Individual Challenge',
        description: 'button title for individual challenge change'
    }
})

export type ChangeParticipationProps = {
    challenge?: UnifiedChallenge | null
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
    onClose: () => void
}

const ChangeParticipation: React.FC<ChangeParticipationProps> = ({
    challenge,
    refetchUnifiedChallengeAvailability,
    onClose
}) => {
    const { refetch, loading: challengeLoading } =
        useCompanyChallengeProviderContext()
    const { formatMessage } = useIntl()
    const [setGroupChallengeType, { loading: setGroupChallengeLoading }] =
        useSetGroupChallengeTypeMutation()
    const [openParticipationDialog, setOpenParticipationDialog] =
        useState<boolean>(false)

    const changeParticipation = useCallback(
        async (participationType: GroupChallengeType) => {
            await setGroupChallengeType({
                variables: {
                    challengeId: challenge?.id,
                    groupChallengeType: participationType
                }
            })
            await refetchUnifiedChallengeAvailability()
            onClose()
        },
        [challenge?.id, refetch, setGroupChallengeType, onClose]
    )

    return (
        <React.Fragment>
            <Card variant="outlined">
                <Stack p={2} gap={2}>
                    <CoreTypography variant="h5">
                        {challenge?.groupChallengeType ===
                        GroupChallengeType.Group
                            ? formatMessage(
                                  messages.individualChallengeChangeTitle
                              )
                            : formatMessage(messages.groupChallengeChangeTitle)}
                    </CoreTypography>
                    <Box>
                        <LoadingButton
                            fixWidth
                            loading={
                                setGroupChallengeLoading || challengeLoading
                            }
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenParticipationDialog(true)}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {challenge?.groupChallengeType ===
                                GroupChallengeType.Group
                                    ? formatMessage(
                                          messages.individualChallengeChangeButtonTitle
                                      )
                                    : formatMessage(
                                          messages.groupChallengeChangeButtonTitle
                                      )}
                            </CoreTypography>
                        </LoadingButton>
                    </Box>
                </Stack>
            </Card>
            <ConfirmParticipation
                open={openParticipationDialog}
                changeToParticipation={
                    challenge?.groupChallengeType === GroupChallengeType.Group
                        ? GroupChallengeType.Personal
                        : GroupChallengeType.Group
                }
                onClose={() => setOpenParticipationDialog(false)}
                onConfirm={changeParticipation}
            />
        </React.Fragment>
    )
}

export default ChangeParticipation
