import { Button, Fade, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'

const messages = defineMessages({
    next: {
        defaultMessage: 'Next',
        description:
            'button text that takes a user to the next step in a sign up process'
    }
})

export interface SignUpNavigationControlsProps {
    onBack: () => void
    onNext: (responses?: unknown[]) => Promise<void>
    nextButtonText?: string
    showBackButton?: boolean
}

const SignUpNavigationControls: React.FC<SignUpNavigationControlsProps> = ({
    onBack,
    onNext,
    nextButtonText,
    showBackButton
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { loading, disabled, triggerMutations } =
        useCompanyChallengeSignUpProviderContext()

    return (
        <Stack
            width="100%"
            justifyContent="center"
            direction={isMobile ? 'column' : 'row'}
            gap={1}
        >
            <Fade in={showBackButton} mountOnEnter unmountOnExit>
                <Button
                    onClick={() => onBack()}
                    variant="contained"
                    color="secondary"
                    data-testid="back-button"
                    fullWidth={isMobile}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Back"
                            description="end join company challenge sign up button text"
                        />
                    </CoreTypography>
                </Button>
            </Fade>
            <LoadingButton
                loading={loading}
                disabled={loading || disabled}
                fixWidth={true}
                variant="contained"
                onClick={() =>
                    triggerMutations((responses) => onNext(responses))
                }
                data-testid="next-button"
                fullWidth={isMobile}
            >
                <CoreTypography customVariant="buttonNormal">
                    {nextButtonText
                        ? nextButtonText
                        : formatMessage(messages.next)}
                </CoreTypography>
            </LoadingButton>
        </Stack>
    )
}

export default memo(SignUpNavigationControls)
