import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import { useMemo } from 'react'
import {
    type UnifiedChallenge,
    type UnifiedChallengeActivity,
    useGetTemplateAvailabilityQuery,
    useGetUnifiedChallengeAvailabilityQuery
} from '../../../graphql/generated/autogenerated'
import useZonedDate from '../useZonedDateTime/useZonedDateTime'

/**
 * Enum representing the different views a challenge can display
 */
export enum ChallengeViewType {
    INTRO, // Pre-challenge or template view
    ACTIVE, // Ongoing challenge view
    RECAP // Completed or expired challenge view
}

export type RefetchUnifiedChallengeAvailability = ReturnType<
    typeof useGetUnifiedChallengeAvailabilityQuery
>['refetch']

export interface ChallengeLandingData {
    challenge?: UnifiedChallenge
    viewType: ChallengeViewType
    loading: boolean
    isTemplate: boolean
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

/**
 * Hook to determine which challenge view to display based on challenge state and user participation
 * @param id - Challenge or template ID
 * @returns Object containing view type and loading state
 */
export const useGetChallengeLanding = (id: string): ChallengeLandingData => {
    const { getZonedDate } = useZonedDate()

    const {
        data: unifiedData,
        loading: unifiedLoading,
        error: unifiedError,
        refetch: refetchUnifiedChallengeAvailability
    } = useGetUnifiedChallengeAvailabilityQuery({
        variables: { challengeId: id, challengeIds: [id] }
    })

    const isCompanyChallenge = Boolean(
        unifiedData?.unifiedChallenges?.unifiedChallenge
    )
    const {
        data: templateData,
        loading: templateLoading,
        error: templateError
    } = useGetTemplateAvailabilityQuery({
        variables: { templateId: id },
        skip: isCompanyChallenge || unifiedLoading
    })

    if (templateError && !unifiedData?.unifiedChallenges?.unifiedChallenge)
        throw templateError
    if (unifiedError) throw unifiedError

    const currentDate = useMemo(
        () => startOfDay(getZonedDate()),
        [getZonedDate]
    )

    const { challenge, participation } = useMemo(() => {
        const communityUserParticipation = (
            unifiedData?.unifiedChallenges?.communityUserChallenges || []
        ).find((challenge) => challenge.challenge.id === id)?.participation[0]

        return {
            challenge: unifiedData?.unifiedChallenges?.unifiedChallenge,
            participation: (unifiedData?.unifiedChallenges
                ?.unifiedUserChallenges[0]?.participation[0] ||
                communityUserParticipation) as UnifiedChallengeActivity
        }
    }, [unifiedData])

    return useMemo(() => {
        const hasUnifiedUserChallenges =
            !!unifiedData?.unifiedChallenges?.unifiedUserChallenges?.length

        const hasCommunityUserChallenges =
            !!unifiedData?.unifiedChallenges?.communityUserChallenges?.length

        // If ID matches a template, always show intro view
        if (templateData?.unifiedChallenges?.groupChallengeTemplate?.id) {
            return {
                viewType: ChallengeViewType.INTRO,
                loading: unifiedLoading,
                isTemplate: true,
                refetchUnifiedChallengeAvailability
            }
        }

        // Show intro if no challenge found or user hasn't participated
        if (
            !challenge ||
            (!hasUnifiedUserChallenges && !hasCommunityUserChallenges)
        ) {
            return {
                viewType: ChallengeViewType.INTRO,
                loading: unifiedLoading || templateLoading,
                isTemplate: unifiedLoading,
                refetchUnifiedChallengeAvailability
            }
        }

        // Priority order for view determination
        if (participation?.leave) {
            return {
                viewType: ChallengeViewType.INTRO,
                loading: false,
                isTemplate: false
            }
        }

        if (participation?.completedOn) {
            return {
                viewType: ChallengeViewType.RECAP,
                challenge,
                loading: unifiedLoading,
                isTemplate: false,
                refetchUnifiedChallengeAvailability
            }
        }

        if (participation?.join) {
            if (
                participation.expiredOn &&
                isAfter(currentDate, parseISO(participation.expiredOn))
            ) {
                return {
                    viewType: ChallengeViewType.RECAP,
                    challenge,
                    loading: unifiedLoading,
                    isTemplate: false,
                    refetchUnifiedChallengeAvailability
                }
            }
            return {
                viewType: ChallengeViewType.ACTIVE,
                loading: unifiedLoading,
                isTemplate: false,
                refetchUnifiedChallengeAvailability
            }
        }

        // Default case
        return {
            viewType: ChallengeViewType.INTRO,
            loading: unifiedLoading,
            isTemplate: false,
            refetchUnifiedChallengeAvailability
        }
    }, [
        templateData,
        challenge,
        unifiedData,
        participation,
        currentDate,
        unifiedLoading,
        templateLoading
    ]) as ChallengeLandingData
}

export default useGetChallengeLanding
