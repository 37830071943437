import React, { useEffect } from 'react'
import { useMultiStepFormContext } from '../../../../contexts/withMultiStepForm/withMultiStepForm'

type MultiStepFormItemProps = {
    name: string
    required?: boolean
    children: React.ReactNode
}

const MultiStepFormItem: React.FC<MultiStepFormItemProps> = ({
    name,
    children,
    required = false
}) => {
    const { markStepAsRequired } = useMultiStepFormContext()

    useEffect(() => {
        if (required) {
            markStepAsRequired(name)
        }
    }, [])

    return <>{children}</>
}

export default MultiStepFormItem
