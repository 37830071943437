import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

export type NotStartedGenericProps = {
    icon: React.ReactNode
    title: React.ReactNode
    description: React.ReactNode
}

const NotStartedGeneric: React.FC<NotStartedGenericProps> = ({
    icon,
    title,
    description
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            gap={1}
        >
            <Stack
                width={isMobile ? 150 : 200}
                height="auto"
                alignItems="center"
                justifyContent="center"
            >
                {icon}
            </Stack>
            <Stack
                alignItems="center"
                justifyContent="center"
                maxWidth={560}
                gap={2}
            >
                <CoreTypography variant="h4">{title}</CoreTypography>
                <CoreTypography variant="body1" textAlign="center">
                    {description}
                </CoreTypography>
            </Stack>
        </Stack>
    )
}

export default memo(NotStartedGeneric)
