import { Skeleton, SkeletonProps } from '@mui/material'
import React, { memo } from 'react'

export interface ConditionalSkeletonProps extends SkeletonProps {
    showSkeleton?: boolean
    children: React.ReactNode
}

const ConditionalSkeleton: React.FC<ConditionalSkeletonProps> = ({
    showSkeleton,
    children,
    ...props
}) => (
    <>
        {showSkeleton ? (
            <Skeleton {...props}>{children}</Skeleton>
        ) : (
            <>{children}</>
        )}
    </>
)

export default memo(ConditionalSkeleton)
