import { Stack, useMediaQuery } from '@mui/material'
import { addDays, diffDays, format } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafDatePickerUnstyled,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    startDate: {
        defaultMessage: 'Challenge start date',
        description: 'date input helper text'
    }
})

const MAX_DATE = 60
export interface ChallengeStartDateFieldProps {
    startDate: string | undefined
    setStartDate?: (startDate: string) => void
}

const ChallengeStartDateField: React.FC<ChallengeStartDateFieldProps> = ({
    startDate,
    setStartDate
}) => {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))

    const duration = useMemo(() => {
        if (!startDate) return 0
        return diffDays(new Date(startDate), new Date()) + 1
    }, [startDate])

    return (
        <Stack gap={1}>
            <CoreTypography variant="h5">
                <FormattedMessage
                    defaultMessage="Choose a start date for the Challenge"
                    description="start date title for the select date input"
                />
            </CoreTypography>
            <Stack
                gap={0.5}
                width="100%"
                sx={{ maxWidth: isMobile ? '100%' : '320px' }}
            >
                <CoreTypography variant="body2" color="primary.main">
                    <FormattedMessage
                        defaultMessage="Challenge start date"
                        description="challenge start date field label"
                    />
                </CoreTypography>
                <LeafDatePickerUnstyled
                    aria-label={formatMessage(messages.startDate)}
                    color="primary"
                    id="activity-date-picker-input"
                    type="date"
                    value={startDate}
                    onChange={(event) => setStartDate?.(event.target.value)}
                    inputProps={{
                        min: format(new Date()),
                        max: format(addDays(new Date(), MAX_DATE))
                    }}
                />
            </Stack>
            <CoreTypography variant="caption">
                <FormattedMessage
                    defaultMessage="This start date will give participants {duration, plural, one {# day to sign up} other {# days to sign up}}"
                    description="description for the duration this will give to the challenge on creation"
                    values={{ duration }}
                />
            </CoreTypography>
        </Stack>
    )
}

export default ChallengeStartDateField
