import { Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'

const ChallengeAboutSkeleton: React.FC = () => {
    const { breakpoints } = useTheme()
    const isTablet = useMediaQuery(breakpoints.down('md'))
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <Card elevation={0}>
            <Stack direction={isTablet ? 'column' : 'row'} gap={6} p={3}>
                <Stack flex={1} gap={2}>
                    <Stack gap={2}>
                        <Skeleton variant="rounded" height={34} width={200} />
                        <Stack gap={1}>
                            <Skeleton
                                variant="rounded"
                                height={21}
                                width="100%"
                            />
                            <Skeleton
                                variant="rounded"
                                height={21}
                                width="100%"
                            />
                            <Skeleton
                                variant="rounded"
                                height={21}
                                width="75%"
                            />
                        </Stack>
                    </Stack>
                    <Skeleton variant="rounded" height={44} width={123} />
                </Stack>
                <Stack
                    gap={2}
                    width={307}
                    {...(!isMobile && {
                        alignItems: 'center',
                        justifyContent: 'center'
                    })}
                >
                    <Stack direction="row" width="100%" gap={1.5}>
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            sx={{ minWidth: 40 }}
                        />
                        <Skeleton variant="rounded" height={40} width="100%" />
                    </Stack>
                    <Stack direction="row" width="100%" gap={1.5}>
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            sx={{ minWidth: 40 }}
                        />
                        <Skeleton variant="rounded" height={40} width="100%" />
                    </Stack>
                    <Stack direction="row" width="100%" gap={1.5}>
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            sx={{ minWidth: 40 }}
                        />
                        <Skeleton variant="rounded" height={40} width="100%" />
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeAboutSkeleton)
