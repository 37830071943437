import { Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'

const sizeProperties = {
    xs: {
        cardHeight: 389,
        mediaWidth: '100%',
        mediaHeight: 150,
        direction: 'column'
    },
    sm: {
        cardHeight: 245,
        mediaWidth: 144,
        mediaHeight: undefined,
        direction: 'row'
    },
    md: {
        cardHeight: 245,
        mediaWidth: 264,
        mediaHeight: '100%',
        direction: 'row'
    },
    lg: {
        cardHeight: 245,
        mediaWidth: 264,
        mediaHeight: '100%',
        direction: 'row'
    }
}
export interface ChallengeFeaturedCardProps {
    size: 'lg' | 'md' | 'sm' | 'xs'
}

const ChallengeFeaturedCardSkeleton: React.FC<ChallengeFeaturedCardProps> = ({
    size
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const {
        cardHeight,
        mediaWidth,
        mediaHeight,
        direction: rawDirection
    } = useMemo(() => {
        if (isMobile) {
            return {
                cardHeight: 389,
                mediaWidth: '100%',
                mediaHeight: 150,
                direction: 'column'
            }
        } else {
            return (
                sizeProperties[size] || {
                    cardHeight: 245,
                    mediaWidth: '100%',
                    mediaHeight: '100%',
                    direction: 'row'
                }
            )
        }
    }, [isMobile, size])

    const direction = rawDirection as
        | 'column'
        | 'row'
        | 'column-reverse'
        | 'row-reverse'

    return (
        <Card>
            <Stack height={cardHeight}>
                <Stack direction={direction} flex="1">
                    <Skeleton
                        sx={{
                            transform: 'initial',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius:
                                size === 'xs' || isMobile ? 0 : undefined,
                            borderBottomRightRadius: 0,
                            minWidth: mediaWidth,
                            minHeight: mediaHeight
                        }}
                    />
                    <Stack p={2} height="100%" width="100%">
                        <Stack justifyContent="space-between" height="100%">
                            <Stack gap={1} direction="row" py={1.125}>
                                <Skeleton
                                    variant="rounded"
                                    height={20}
                                    width={20}
                                />
                                <Skeleton width="50%" height={20} />
                            </Stack>

                            <Stack gap={1}>
                                <Stack>
                                    <Skeleton width={75} height={20} />
                                    <Skeleton width="60%" height={24} />
                                </Stack>
                                <Skeleton width="100%" height={20} />
                                <Skeleton width="75%" height={20} />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                gap={2}
                                width="100%"
                            >
                                <Skeleton
                                    variant="rounded"
                                    width={139}
                                    height={44}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeFeaturedCardSkeleton)
