import { CardMedia, Grid, Skeleton, Stack } from '@mui/material'
import { LeafFixedMediaCard } from '@thriveglobal/thrive-web-leafkit'

const CompletedChallengeCardSkeleton = () => {
    return (
        <LeafFixedMediaCard
            data-testid="completed-challenge-card-skeleton"
            alignMedia="top"
            mediaWidth="md"
            media={
                <Stack position="relative" height={234} sx={{ px: 2 }}>
                    <Skeleton height={234} />
                </Stack>
            }
        >
            <Stack height={224} direction={'column'}>
                <Stack justifyContent="space-between" height="100%">
                    <Stack gap={1}>
                        <Skeleton width={80} height={24} />
                        <Skeleton width="100%" height={32} />
                        <Skeleton width="60%" height={32} />
                    </Stack>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6}>
                            <Skeleton width={100} height={16} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton width={100} height={16} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton width={60} height={24} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton width={60} height={24} />
                        </Grid>
                    </Grid>

                    <Stack gap={0.5}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={'space-between'}
                            gap={2}
                            pt={4}
                        >
                            <Stack width={'100%'} alignItems="flex-end">
                                <Skeleton width={100} height={40} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </LeafFixedMediaCard>
    )
}

export default CompletedChallengeCardSkeleton
