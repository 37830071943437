import { useContext } from 'react'
import UserChallengesContext, {
    userChallengesValue
} from './userChallengesContext'

export const useUserChallengesContext = (): userChallengesValue => {
    const context = useContext(UserChallengesContext)

    return context
}
