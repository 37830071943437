import {
    format,
    useAppSelector,
    useCrossAppNavigation
} from '@thriveglobal/thrive-web-core'
import useSignupFormData from '../useSignupFormData/useSignupFormData'
import { useMultiStepFormContext } from '../../contexts/withMultiStepForm/withMultiStepForm'
import { useCallback, useEffect, useState } from 'react'
import { stripBOM } from '../../../shared/utils/bom'
import {
    GroupChallengeType,
    UnifiedChallenge,
    useCreateGroupChallengeFromTemplateMutation,
    useJoinCommunityChallengeMutation,
    useUpdateDisplayNameMutation
} from '../../../graphql/generated/autogenerated'
import { generatePath, useParams } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import { SIGNUP_STEPS, type SIGNUP_STEPS_TYPE } from '../../constants'

export type ChallengeTemplateForm = {
    details: {
        displayName?: string
        startDate?: string
    }
    terms: {
        confirmed: boolean
    }
}

type UseChallengeTemplateProps = {
    groupChallengeType?: GroupChallengeType
    challenge?: UnifiedChallenge
}

const useChallengeTemplateSignup = ({
    groupChallengeType,
    challenge
}: UseChallengeTemplateProps) => {
    const [isDisplayNameValid, setIsDisplayNameValid] = useState(false)
    const [updateDisplayName] = useUpdateDisplayNameMutation()
    const [createGroupFromChallengeMutation] =
        useCreateGroupChallengeFromTemplateMutation({})
    const [joinCommunityChallenge] = useJoinCommunityChallengeMutation()
    const [loading, setLoading] = useState(false)

    const navigate = useCrossAppNavigation()
    const user = useAppSelector((state) => state.user)
    const { currentStepName, setStepCompletion } = useMultiStepFormContext()
    const { formData, updateFormData } =
        useSignupFormData<ChallengeTemplateForm>({
            defaultValues: {
                details: {
                    displayName: user.fullName ?? undefined,
                    startDate: format(new Date())
                },
                terms: {
                    confirmed: false
                }
            }
        })

    const onCreate = useCallback(async () => {
        if (!challenge) return

        try {
            setLoading(true)
            await updateDisplayName({
                variables: { displayName: formData.details.displayName! }
            })
            const response = await createGroupFromChallengeMutation({
                variables: {
                    input: {
                        challengeTemplateId: stripBOM(challenge.id),
                        startDate: formData.details.startDate,
                        groupChallengeType
                    }
                }
            })

            const createGroupChallenge =
                response?.data?.unifiedChallenges
                    .createGroupChallengeFromTemplate

            if (
                createGroupChallenge?.__typename === 'ChallengeTemplateInstance'
            ) {
                navigate(
                    generatePath(ROUTES.CHALLENGE_V2, {
                        challengeId: createGroupChallenge.challengeInstance.id
                    })
                )
            }
        } catch (e) {
            // todo: error hanglind here
        } finally {
            setLoading(false)
        }
    }, [
        challenge,
        updateDisplayName,
        formData.details.displayName,
        formData.details.startDate,
        createGroupFromChallengeMutation,
        groupChallengeType,
        navigate
    ])

    const onJoin = useCallback(async () => {
        if (!challenge) return

        try {
            await updateDisplayName({
                variables: { displayName: formData.details.displayName! }
            })
            await joinCommunityChallenge({
                variables: {
                    challengeId: challenge.id
                }
            })
        } catch (e) {
            // todo: error handling here
        } finally {
            setLoading(false)
        }
    }, [
        challenge,
        formData.details.displayName,
        joinCommunityChallenge,
        updateDisplayName
    ])

    useEffect(() => {
        switch (currentStepName as SIGNUP_STEPS_TYPE) {
            case SIGNUP_STEPS.DETAILS:
                setStepCompletion(
                    currentStepName,
                    isDisplayNameValid &&
                        !!formData.details.displayName &&
                        !!formData.details.startDate
                )
                break
            case SIGNUP_STEPS.TERMS:
                setStepCompletion(currentStepName, formData.terms.confirmed)
                break
            default:
                setStepCompletion(currentStepName, false)
                break
        }
    }, [
        formData.details?.displayName,
        formData.details?.startDate,
        formData.terms?.confirmed,
        currentStepName,
        isDisplayNameValid
    ])

    return {
        formData,
        updateFormData,
        setIsDisplayNameValid,
        onCreate,
        onJoin,
        loading
    }
}

export default useChallengeTemplateSignup
