import { Box, Fade } from '@mui/material'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ChooseStoryType,
    ErrorScreen,
    ReviewYourStory,
    SingleQuestionStory,
    StoryQuestions,
    SubmissionSuccess
} from '../'
import { StorySubmissionSteps } from '../../../../../enums/StorySubmissionSteps'
import useStorySubmissionContext from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/useStorySubmissionContext'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import withStorySubmissionStateProvider from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/withStorySubmissionStateProvider'
import {
    useInterstitialNavigation,
    withInterstitialNavigationProvider
} from '../../../../contexts/withInterstitialNavigation'
import AreYouSureModal from '../../Shared/AreYouSureModal'
import FullScreenModal from '../../Shared/FullScreenModal'

export interface StorySubmissionProps extends JSX.IntrinsicAttributes {
    open?: boolean
    onClose: () => void
    onSuccess?: () => void
}

const messages = defineMessages({
    pageName: {
        defaultMessage: 'Story Submission',
        description: 'name of the current page'
    }
})

const StorySubmission: React.FC<StorySubmissionProps> =
    withInterstitialNavigationProvider(
        withStorySubmissionStateProvider(({ open, onClose, onSuccess }) => {
            const { formatMessage } = useIntl()
            const [openAreYouSureModal, setOpenAreYouSureModal] =
                useState(false)
            const {
                keys,
                subKeySteps,
                activeKey,
                setKeys,
                setSubKeySteps,
                queueNavigateToKey,
                navigateToKey
            } = useInterstitialNavigation()

            const {
                loading,
                questions,
                challenge,
                participation,
                interactionId
            } = useStorySubmissionContext()

            const {
                answeredQuestions,
                isSingleQuestionStory,
                setErrorDescription
            } = useStorySubmissionStateProvider()

            const closeModal = useCallback(() => {
                Avo.submissionStarted({
                    activityType: 'story_cancelled',
                    challengeId: challenge?.id,
                    challengeTheme: challenge?.theme,
                    challengeType: challenge?.challenge_type,
                    contentFormatType: null,
                    contentId: participation?.id,
                    contentSource: null,
                    contentSubtype: null,
                    contentTitle: challenge?.name,
                    contentType: null,
                    contentUrl: participation?.id,
                    dayNumber: null,
                    featureType: 'challenge',
                    teamId: null,
                    teamType: null
                })
                onClose()
            }, [challenge, participation, onClose])

            const hasNoQuestions = useMemo(() => {
                const hasNoQuestions = !loading && questions?.length <= 0

                if (hasNoQuestions) {
                    setErrorDescription(
                        formatMessage({
                            defaultMessage:
                                'Something went wrong, Please try again',
                            description:
                                'text to show in an error popup when something goes wrong'
                        })
                    )
                    queueNavigateToKey?.(StorySubmissionSteps.error)
                }

                return hasNoQuestions
            }, [
                loading,
                questions?.length,
                formatMessage,
                queueNavigateToKey,
                setErrorDescription
            ])
            const finishedLoading = useMemo(
                () => !loading && answeredQuestions?.length > 0,
                [loading, answeredQuestions?.length]
            )

            useEffect(() => {
                // If we get an openModel event we send the tracking event to show story submission has started
                if (open) {
                    Avo.submissionStarted({
                        activityType: 'story_submission_started',
                        challengeId: challenge?.id,
                        challengeTheme: challenge?.theme,
                        challengeType: challenge?.challenge_type,
                        contentFormatType: null,
                        contentId: participation?.id,
                        contentSource: null,
                        contentSubtype: null,
                        contentTitle: challenge?.name,
                        contentType: null,
                        contentUrl: participation?.id,
                        dayNumber: null,
                        featureType: 'challenge',
                        teamId: null,
                        teamType: null
                    })
                }
            }, [open, challenge, participation, interactionId])

            const submissionComponents = useMemo<{
                [key: string]: (active?: boolean) => ReactElement<any, any>
            }>(
                () => ({
                    ...(!hasNoQuestions &&
                        finishedLoading &&
                        isSingleQuestionStory && {
                            [StorySubmissionSteps.singleQuestionStory]: () => (
                                <SingleQuestionStory challenge={challenge} />
                            )
                        }),
                    ...(!hasNoQuestions &&
                        finishedLoading &&
                        !isSingleQuestionStory && {
                            [StorySubmissionSteps.storyType]: () => (
                                <ChooseStoryType
                                    challenge={challenge}
                                    participation={participation}
                                />
                            ),
                            [StorySubmissionSteps.questions]: () => (
                                <StoryQuestions />
                            ),
                            [StorySubmissionSteps.review]: () => (
                                <ReviewYourStory challenge={challenge} />
                            )
                        }),
                    ...(!hasNoQuestions &&
                        finishedLoading && {
                            [StorySubmissionSteps.success]: (
                                active?: boolean
                            ) => (
                                <SubmissionSuccess
                                    close={() => {
                                        closeModal()
                                        onSuccess?.()
                                    }}
                                    active={Boolean(active)}
                                    challenge={challenge}
                                />
                            )
                        }),
                    [StorySubmissionSteps.error]: () => (
                        <ErrorScreen
                            close={() => {
                                closeModal()
                            }}
                            {...(!hasNoQuestions && {
                                navigateBackToOnError: () =>
                                    navigateToKey(
                                        isSingleQuestionStory
                                            ? StorySubmissionSteps.singleQuestionStory
                                            : StorySubmissionSteps.review
                                    )
                            })}
                        />
                    )
                }),
                [
                    finishedLoading,
                    challenge,
                    participation,
                    hasNoQuestions,
                    isSingleQuestionStory,
                    closeModal,
                    navigateToKey
                ]
            )

            useEffect(() => {
                if (finishedLoading || hasNoQuestions) {
                    if (
                        !keys ||
                        keys?.length <= 0 ||
                        Object.keys(submissionComponents)?.length !==
                            keys?.length
                    ) {
                        setKeys(Object.keys(submissionComponents))
                    }

                    if (
                        !isSingleQuestionStory &&
                        (!subKeySteps ||
                            Object.keys(subKeySteps).length <= 0) &&
                        answeredQuestions?.length > 0
                    ) {
                        setSubKeySteps({
                            [StorySubmissionSteps.questions]:
                                answeredQuestions?.length
                        })
                    }
                }
            }, [
                finishedLoading,
                hasNoQuestions,
                keys,
                subKeySteps,
                submissionComponents,
                isSingleQuestionStory,
                answeredQuestions,
                setKeys,
                setSubKeySteps
            ])

            const confirmCloseModal = useCallback(
                (confirm: boolean) => {
                    if (confirm) {
                        closeModal()
                    }

                    setOpenAreYouSureModal(false)
                },
                [closeModal]
            )

            return (
                <Box data-testid="story-submission">
                    <AreYouSureModal
                        isOpen={openAreYouSureModal}
                        handleOnClose={confirmCloseModal}
                    />
                    <FullScreenModal
                        isOpen={open}
                        handleOnClose={() => setOpenAreYouSureModal(true)}
                        name={formatMessage(messages.pageName)}
                        body={
                            <>
                                {!finishedLoading && !hasNoQuestions ? (
                                    <ThriveFullscreenLoading />
                                ) : (
                                    <Box sx={{ position: 'relative' }}>
                                        {Object.keys(submissionComponents).map(
                                            (step, index: number) => {
                                                const isActiveStep =
                                                    step === activeKey

                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            width: '100%',
                                                            mx: 'auto',
                                                            display:
                                                                isActiveStep
                                                                    ? 'inherit'
                                                                    : 'none'
                                                        }}
                                                    >
                                                        <Fade in={isActiveStep}>
                                                            <Box>
                                                                {submissionComponents[
                                                                    step
                                                                ](isActiveStep)}
                                                            </Box>
                                                        </Fade>
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </Box>
                                )}
                            </>
                        }
                    />
                </Box>
            )
        })
    )

export default StorySubmission
