import { Link } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ConditionalSkeleton from '../../../../../elements/ConditionalSkeleton'
import DisposableDialog from '../../../../../elements/DisposableDialog'
import HydrationDailyGoalDialog from './HydrationDailyGoalDialog/HydrationDailyGoalDialog'
import {
    ChallengeUserDailyGoal,
    MeasureUnit
} from '../../../../../../../graphql/generated/autogenerated'

export interface UpdateWaterGoalProps {
    disabled?: boolean
    loading?: boolean
    goal: ChallengeUserDailyGoal
    intakeMeasurement: MeasureUnit
    saveWaterGoal: (newGoal: number, measurement: MeasureUnit) => Promise<void>
}

const UpdateWaterGoal: React.FC<UpdateWaterGoalProps> = ({
    disabled = false,
    loading = false,
    goal,
    intakeMeasurement,
    saveWaterGoal
}) => {
    const theme = useTheme()

    const disabledStyles = disabled
        ? { cursor: 'unset', color: theme.palette.text.disabled }
        : {}

    return (
        <ConditionalSkeleton showSkeleton={loading}>
            <DisposableDialog
                disabled={disabled}
                button={
                    <Link sx={{ cursor: 'pointer', ...disabledStyles }}>
                        <FormattedMessage
                            defaultMessage="Edit goal"
                            description="update goal button text"
                        />
                    </Link>
                }
                Dialog={HydrationDailyGoalDialog}
                dialogProps={{
                    goal: goal,
                    intakeMeasurement: intakeMeasurement,
                    saveWaterGoal: saveWaterGoal
                }}
            />
        </ConditionalSkeleton>
    )
}

export default UpdateWaterGoal
