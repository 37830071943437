import { Dialog, DialogContent } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactNode } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CloseModalIcon } from '../../../../../shared/components/elements'

export type FullScreenModalProps = {
    isOpen?: boolean
    handleOnClose?: () => void
    body?: ReactNode | ReactNode[]
    name: string
}

const messages = defineMessages({
    closeModal: {
        defaultMessage: `Close {modalTitle}`,
        description: 'aria name for the close popup button'
    }
})

const FullScreenModal: React.FC<FullScreenModalProps> = ({
    isOpen = false,
    handleOnClose,
    body,
    name
}) => {
    const theme = useTheme()
    const intl = useIntl()

    return (
        <Dialog
            open={isOpen}
            onClose={handleOnClose}
            fullScreen={true}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.paper
                }
            }}
            data-testid="FullScreenModal"
        >
            <DialogContent>
                <CloseModalIcon
                    onClick={() => handleOnClose?.()}
                    ariaLabel={intl.formatMessage(messages.closeModal, {
                        modalTitle: name
                    })}
                />
                {body}
            </DialogContent>
        </Dialog>
    )
}

export default FullScreenModal
