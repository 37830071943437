import { useContext } from 'react'
import CompanyChallengeSignUpProviderContext, {
    CompanyChallengeSignUpValue
} from './companyChallengeSignUpContext'

export const useCompanyChallengeSignUpProviderContext =
    (): CompanyChallengeSignUpValue => {
        const context = useContext(CompanyChallengeSignUpProviderContext)

        return context
    }
