import {
    Box,
    CircularProgress,
    DialogTitle,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { InvalidInput } from '../../../graphql/generated/autogenerated'
import InlineTitleTextField from '../../../hooks/inlineTitleTextField/inlineTitleTextField'
import ErrorModal from '../../ErrorModal/ErrorModal'

export type SocialGroupActionProps = {
    loading: boolean
    title: string
    description?: string
    caption: string
    defaultValue?: string
    actionValue?: string
    testId: string
    inputPlaceholder: string
    onSubmitComplete: () => void
    mutation: () => Promise<any>
    onMutationComplete: (response: any) => any
    externalAction?: () => Promise<void>
    setActionValue: (value?: string) => void
}
const SocialGroupAction: React.FC<SocialGroupActionProps> = ({
    loading,
    title,
    description,
    caption,
    defaultValue,
    actionValue,
    testId,
    inputPlaceholder,
    onSubmitComplete,
    mutation,
    onMutationComplete,
    externalAction = () => Promise.resolve(),
    setActionValue
}) => {
    const theme = useTheme()
    const [showError, setShowError] = React.useState(false)
    const [invalidText, setInvalidText] = React.useState('')
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    function onSubmit() {
        mutation()
            .then((response) => {
                const mutationSpecificResponse = onMutationComplete(response)

                if (
                    (mutationSpecificResponse as InvalidInput)?.invalidFields
                        ?.length > 0
                ) {
                    setInvalidText(
                        (mutationSpecificResponse as InvalidInput)
                            .invalidFields[0].message
                    )
                } else {
                    onCompleteExternalAction()
                }
            })
            .catch(() => {
                setShowError(true)
            })
    }

    function onCompleteExternalAction() {
        externalAction().then(() => {
            onSubmitComplete()
        })
    }

    useEffect(() => {
        setActionValue(defaultValue)
    }, [defaultValue, setActionValue])

    return (
        <Box data-testid={`${testId}-container`}>
            <ErrorModal
                isOpen={showError}
                handleOnClose={() => setShowError(false)}
            />
            <DialogTitle
                sx={{
                    p: 0,
                    mb: 3,
                    mt: 6,
                    [theme.breakpoints.down('sm')]: {
                        mt: 3,
                        mr: 6
                    }
                }}
            >
                <CoreTypography
                    variant="h1"
                    sx={{
                        color: theme.palette.primary.main
                    }}
                >
                    {title}
                </CoreTypography>
            </DialogTitle>
            <Box
                sx={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 0
                }}
            >
                <CoreTypography
                    variant="body1"
                    sx={{
                        color: theme.palette.primary.main,
                        mb: theme.spacing(6),
                        textAlign: 'left',
                        [theme.breakpoints.down('sm')]: {
                            mb: theme.spacing(3)
                        }
                    }}
                >
                    {description}
                </CoreTypography>
                <Stack
                    sx={{
                        maxWidth: theme.spacing(38.25),
                        [theme.breakpoints.down('sm')]: {
                            maxWidth: '100%'
                        }
                    }}
                >
                    <InlineTitleTextField
                        label={inputPlaceholder}
                        defaultValue={defaultValue}
                        variant="filled"
                        error={!!invalidText}
                        sx={{
                            mb: theme.spacing(0.5),
                            [theme.breakpoints.down('sm')]: {
                                mb: theme.spacing(1)
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            'aria-label': `${inputPlaceholder} input`
                        }}
                        disabled={loading}
                        onChange={(
                            event: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) => setActionValue(event.target.value)}
                        data-testid={`${testId}-input`}
                    />
                    {invalidText && (
                        <CoreTypography
                            variant="caption"
                            sx={{
                                color: theme.palette.error.main,
                                mb: theme.spacing(1),
                                textAlign: 'left'
                            }}
                        >
                            {invalidText}
                        </CoreTypography>
                    )}
                    <CoreTypography
                        variant="caption"
                        sx={{
                            color: theme.palette.grey[700],
                            mb: theme.spacing(8.5),
                            textAlign: 'left',
                            [theme.breakpoints.down('sm')]: {
                                mb: theme.spacing(4)
                            }
                        }}
                    >
                        {caption}
                    </CoreTypography>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: theme.spacing(3.5),
                            [theme.breakpoints.down('sm')]: {
                                width: '100%',
                                mb: theme.spacing(0.5)
                            }
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            disabled={
                                loading ||
                                actionValue === undefined ||
                                actionValue === null ||
                                actionValue === ''
                            }
                            fixWidth={true}
                            variant="contained"
                            data-testid={`submit-${testId}-button`}
                            sx={{
                                alignItems: 'center',
                                p: `${theme.spacing(2)} ${theme.spacing(10)}`,
                                borderRadius: theme.spacing(1),
                                [theme.breakpoints.down('sm')]: {
                                    p: `${theme.spacing(1)} ${theme.spacing(2)}`
                                }
                            }}
                            onClick={onSubmit}
                            loader={
                                <Box textAlign="center">
                                    <CircularProgress
                                        color="accent"
                                        {...(isMobile && {
                                            size: theme.spacing(3)
                                        })}
                                    />
                                </Box>
                            }
                        >
                            <CoreTypography
                                customVariant="buttonNormal"
                                sx={{ textAlign: 'center' }}
                            >
                                <FormattedMessage
                                    defaultMessage="Submit"
                                    description="button text for submit social group action"
                                />
                            </CoreTypography>
                        </LoadingButton>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default SocialGroupAction
