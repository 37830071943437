import { Card, Grid, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useChallengeTypeIcon } from '../../../../../hooks/useChallengeTypeIcon/useChallengeTypeIcon'
import { getChallengeType } from '../../../../../utils'
import StorySubmission from '../StorySubmission'
import useTimeline from '../../../../hooks/useTimeline/useTimeline'
import type {
    UnifiedChallenge,
    UnifiedChallengeActivity,
    Story,
    StoryType
} from '../../../../../graphql/generated/autogenerated'

import parseISO from 'date-fns/parseISO'
import addDays from 'date-fns/addDays'

export type AboutChallengeProps = {
    challenge?: UnifiedChallenge
    currentParticipation?: UnifiedChallengeActivity
    availableInteractions?: StoryType[]
    stories?: Story[]
    storyComplete?: boolean
    onStorySuccess?: () => void
}

const AboutChallenge = ({
    challenge,
    currentParticipation,
    storyComplete,
    availableInteractions,
    stories,
    onStorySuccess
}: AboutChallengeProps) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const { icon, title } = useChallengeTypeIcon(
        challenge ? getChallengeType(challenge) : undefined
    )
    const { formatNumber } = useIntl()

    const duration = challenge?.duration ?? 0
    const startDate = parseISO(challenge?.companyChallengeStartDay ?? '')
    const endDate = addDays(startDate, duration - 1)

    const timeline = useTimeline(startDate, endDate)

    return (
        <Card>
            <Stack
                {...(!isMobile && {
                    pt: 3,
                    pl: 3,
                    pb: 4.5
                })}
                {...(isMobile && {
                    pt: 2,
                    pl: 2,
                    pr: 2,
                    pb: 4.5
                })}
            >
                <Grid container>
                    <Grid item xs={12} md={12} lg={8}>
                        <Stack>
                            <Stack gap={2}>
                                <Stack gap={1}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        height={35}
                                        gap={1}
                                    >
                                        {icon}
                                        <CoreTypography
                                            variant="overline"
                                            color="text.secondary"
                                        >
                                            {title}
                                        </CoreTypography>
                                    </Stack>
                                    <Stack gap={1}>
                                        <CoreTypography
                                            variant={isMobile ? 'h4' : 'h3'}
                                            color="text.secondary"
                                        >
                                            <FormattedMessage
                                                defaultMessage="Your Challenge Recap"
                                                description="challenge recap title"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isMobile && {
                                                variant: 'h1'
                                            })}
                                            {...(!isMobile && {
                                                customVariant: 'display2'
                                            })}
                                            color="primary.main"
                                        >
                                            {challenge?.name}
                                        </CoreTypography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    gap={isMobile ? 1 : 2}
                                    direction={isMobile ? 'column' : 'row'}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1.5}
                                    >
                                        <Stack
                                            height={36}
                                            width={36}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <LeafIcon
                                                icon="calendar"
                                                sx={{ height: 19, width: 14 }}
                                            />
                                        </Stack>
                                        <CoreTypography variant="body1">
                                            {timeline}
                                        </CoreTypography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1.5}
                                    >
                                        <Stack
                                            height={36}
                                            width={36}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <LeafIcon
                                                icon="user"
                                                iconStyle="solid"
                                                sx={{ height: 16, width: 14 }}
                                            />
                                        </Stack>
                                        <CoreTypography variant="body1">
                                            <FormattedMessage
                                                id="participant.count"
                                                defaultMessage="{participantCount, plural, one {# Participant} other {# Participants}}"
                                                description="participant count"
                                                values={{
                                                    participantCount:
                                                        formatNumber(
                                                            challenge?.totalParticipants ??
                                                                0
                                                        )
                                                }}
                                            />
                                        </CoreTypography>
                                    </Stack>
                                </Stack>
                                <CoreTypography
                                    fontSize={18}
                                    lineHeight={1.5}
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    {challenge?.description}
                                </CoreTypography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={0} md={12} lg={4}>
                        {!isMobile && (
                            <Stack pr={3} pt={1}>
                                <StorySubmission
                                    challenge={challenge}
                                    currentParticipation={currentParticipation}
                                    availableInteractions={
                                        availableInteractions
                                    }
                                    stories={stories}
                                    storyComplete={storyComplete}
                                    onSuccess={() => onStorySuccess?.()}
                                />
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </Card>
    )
}

export default memo(AboutChallenge)
