import React, { memo, useMemo } from 'react'
import {
    PlantInfo,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import usePlantIcons, {
    type usePlantIconsValue
} from '../../../../hooks/usePlantIcons/usePlantIcons'

export interface PlantIconProps {
    plant?: UserPlantInfo | PlantInfo
    loading?: boolean
    useSimple?: boolean
    getPlantIcon?: (plant?: UserPlantInfo | PlantInfo) => usePlantIconsValue
}

export interface ChallengePlantCardColors {
    colorLayer1?: string
    colorLayer2?: string
    colorLayer3?: string
    colorLayer4?: string
}

export const exclusivePlants = 'plant.community.cherryblossom'

const PlantIcon: React.FC<PlantIconProps> = ({
    plant,
    loading,
    useSimple,
    getPlantIcon
}) => {
    const { getPlantIcon: getDefault } = usePlantIcons(useSimple)
    const { isExclusive, PlantComponent, base } = useMemo(() => {
        const getPlantIconFunc = getPlantIcon ? getPlantIcon : getDefault

        const { PlantIcon: PlantComponent, base } = getPlantIconFunc({
            productUpc: String(plant?.productUpc)
        } as UserPlantInfo)

        return {
            isExclusive:
                plant?.productUpc && exclusivePlants === plant.productUpc,
            PlantComponent,
            base
        }
    }, [plant, getPlantIcon, getDefault])

    const colors = useMemo(
        () =>
            loading
                ? {
                      colorLayer1: `#f0f0f2`,
                      colorLayer2: `#f5f5f6`,
                      colorLayer3: `#f5f5f6`,
                      colorLayer4: `#f0f0f2`
                  }
                : {
                      colorLayer1: `#${plant?.colorLayer1}`,
                      colorLayer2: `#${plant?.colorLayer2}`,
                      colorLayer3: `#${plant?.colorLayer3}`,
                      colorLayer4: `#${plant?.colorLayer4}`
                  },
        [loading, plant]
    )

    return (
        <>
            {PlantComponent && (
                <PlantComponent
                    {...((!isExclusive || loading) && {
                        ...colors
                    })}
                    base={base}
                />
            )}
        </>
    )
}

export default memo(PlantIcon)
