import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const DirtGroup: React.FC<SvgIconProps> = () => (
    <SvgIcon
        aria-hidden="true"
        viewBox={'0 0 218 99'}
        sx={{ width: 218, height: 99 }}
    >
        <g clipPath="url(#clip0_756_248075)">
            <path
                d="M83.375 24.8225L85.345 25.3425L87.135 23.9425L87.9051 24.9825L88.455 23.0225L88.8251 20.1325L85.265 18.5825L82.775 17.6525L82.205 15.4525L81.915 12.8925L79.095 12.4325L76.525 12.7225L73.885 14.8225L71.485 12.5125L68.7551 14.3025L67.025 11.8625L64.0751 12.8925L62.115 11.2725L62.045 7.8325L61.565 6.7725L58.745 6.7825L56.125 7.0725L54.715 4.1225L51.885 5.7325L49.845 4.5325L47.335 5.8325L45.115 3.3425L42.455 3.8125L39.805 4.7925L37.4151 1.5625L34.7351 4.3225L32.185 3.4525L29.645 4.2725L26.965 2.5025L24.435 3.4425L21.735 2.6425L19.285 3.9225L16.705 4.1925L14.725 5.4825L11.665 4.4825L11.315 8.9225L9.51505 10.5725L5.88505 11.5125L9.24505 13.5925L10.475 14.3625L9.92505 15.5325L8.66505 14.6125L4.41505 16.1925L3.29505 13.3325L3.77505 16.7225L2.97505 18.6725L0.175049 20.5325L0.975049 22.9625L0.305049 25.8225L3.93505 25.6525L7.25505 27.7125L7.18505 30.3925L6.01505 32.5525L7.73505 34.1025L9.15505 34.6725L11.415 36.3325L13.525 33.6125L16.295 35.9725L18.465 33.3425L21.065 35.7925L23.435 34.6225L26.895 37.2525L23.295 39.3225L24.805 41.3425L27.825 40.6425L29.155 42.3025L31.785 41.8825L34.445 43.1325L36.405 45.0425L39.015 45.6425L41.615 44.9325L43.835 48.2225L46.405 47.5625L48.905 47.5425L51.215 45.1925L53.615 47.3325L55.9851 46.3625L58.215 46.8525L60.265 48.1425L62.375 49.5725L64.875 49.4525L67.265 46.9425L69.845 47.9825L71.5751 45.5625L74.665 46.7025L76.035 44.1025L78.925 44.1525L81.105 42.9425L82.8651 41.2025L84.015 38.7325L87.345 38.7925L87.1451 35.6125L86.095 33.2325L87.135 30.6625L86.6551 30.5225L86.425 29.9525L84.2551 30.0425L84.555 27.5525L81.535 26.5725L83.375 24.8225Z"
                fill="#F6ECE5"
            />
            <path
                d="M69.315 39.8101C68.105 37.1201 66.035 34.6101 63.675 33.2401C60.805 31.5801 58.355 32.1201 57.385 34.2901L69.025 41.0101L69.975 41.5601C69.805 40.9701 69.585 40.3901 69.325 39.8101H69.315Z"
                fill="#B39D90"
            />
            <path
                d="M40.2651 23.7998C38.8751 20.6998 36.4751 17.7898 33.7451 16.2198C30.4351 14.3098 27.6051 14.9198 26.4751 17.4298L39.9151 25.1898L41.0051 25.8198C40.8051 25.1398 40.5551 24.4698 40.2551 23.7998H40.2651Z"
                fill="#B39D90"
            />
            <path
                d="M18.2949 2.67028C19.3949 0.210276 22.1649 -0.389724 25.4049 1.48028C28.0749 3.02028 31.5349 9.78028 31.4449 10.2603L18.2949 2.67028Z"
                fill="#B39D90"
            />
            <path
                d="M33.625 37.1994C33.475 37.1994 33.345 37.1094 33.305 36.9594C32.575 34.4794 30.725 32.0294 28.695 30.8594C28.635 30.8194 28.575 30.7994 28.515 30.7694L28.405 30.7194C28.335 30.6894 28.285 30.6294 28.255 30.5694C26.775 27.4694 24.375 24.7394 21.845 23.2794C19.665 22.0194 17.605 21.8194 16.185 22.7394C16.045 22.8294 15.855 22.7994 15.745 22.6794C14.685 21.4494 13.495 20.4394 12.305 19.7494C10.645 18.7894 9.045 18.4894 7.805 18.8894C6.915 19.1794 6.215 19.8294 5.795 20.7594C5.715 20.9294 5.515 20.9994 5.355 20.9294C5.185 20.8494 5.105 20.6594 5.185 20.4894C5.685 19.3694 6.525 18.5994 7.605 18.2494C9.035 17.7894 10.825 18.1094 12.655 19.1694C13.845 19.8594 15.025 20.8394 16.085 22.0294C17.705 21.1394 19.915 21.3794 22.195 22.6994C24.805 24.2094 27.275 26.9994 28.825 30.1694C28.895 30.2094 28.975 30.2394 29.045 30.2794C31.245 31.5494 33.175 34.0994 33.965 36.7694C34.015 36.9494 33.915 37.1294 33.735 37.1894C33.705 37.1894 33.675 37.1994 33.635 37.1994H33.625Z"
                fill="#B39D90"
            />
            <path
                d="M83.6749 36.7601C83.5149 36.7601 83.3649 36.6401 83.3449 36.4701C82.7449 32.5101 79.8549 28.2301 76.4749 26.2801C74.3149 25.0301 72.2949 24.9701 71.0649 26.1201C70.9349 26.2501 70.7249 26.2401 70.5949 26.1101C70.0049 25.5001 69.3749 25.0101 68.7349 24.6401C67.6249 24.0001 66.5649 23.8001 65.7549 24.0601C65.1749 24.2501 64.7249 24.6701 64.4549 25.2801C64.3749 25.4501 64.1749 25.5201 64.0149 25.4501C63.8449 25.3701 63.7649 25.1801 63.8449 25.0101C64.1949 24.2201 64.7949 23.6701 65.5549 23.4301C66.5649 23.1101 67.8149 23.3301 69.0849 24.0601C69.6949 24.4101 70.2949 24.8701 70.8649 25.4201C72.3249 24.2801 74.5249 24.3701 76.8249 25.7001C80.3649 27.7501 83.3949 32.2401 84.0249 36.3801C84.0549 36.5601 83.9249 36.7301 83.7449 36.7601C83.7249 36.7601 83.7149 36.7601 83.6949 36.7601H83.6749Z"
                fill="#B39D90"
            />
        </g>
        <g clipPath="url(#clip1_756_248075)">
            <path
                d="M146.375 73.1897L148.345 73.7097L150.135 72.3097L150.905 73.3497L151.455 71.3897L151.825 68.4997L148.265 66.9497L145.775 66.0197L145.205 63.8197L144.915 61.2597L142.095 60.7997L139.525 61.0897L136.885 63.1897L134.485 60.8797L131.755 62.6697L130.025 60.2297L127.075 61.2597L125.115 59.6397L125.045 56.1997L124.565 55.1397L121.745 55.1497L119.125 55.4397L117.715 52.4897L114.885 54.0997L112.845 52.8997L110.335 54.1997L108.115 51.7097L105.455 52.1797L102.805 53.1597L100.415 49.9297L97.7351 52.6897L95.185 51.8197L92.645 52.6397L89.965 50.8697L87.435 51.8097L84.735 51.0097L82.285 52.2897L79.705 52.5597L77.725 53.8497L74.665 52.8497L74.315 57.2897L72.515 58.9397L68.885 59.8797L72.245 61.9597L73.475 62.7297L72.925 63.8997L71.665 62.9797L67.415 64.5597L66.295 61.6997L66.775 65.0897L65.975 67.0397L63.175 68.8997L63.975 71.3297L63.305 74.1897L66.935 74.0197L70.255 76.0797L70.185 78.7597L69.015 80.9197L70.735 82.4697L72.155 83.0397L74.415 84.6997L76.525 81.9797L79.295 84.3397L81.465 81.7097L84.065 84.1597L86.435 82.9897L89.895 85.6197L86.295 87.6897L87.805 89.7097L90.825 89.0097L92.155 90.6697L94.785 90.2497L97.445 91.4997L99.405 93.4097L102.015 94.0097L104.615 93.2997L106.835 96.5897L109.405 95.9297L111.905 95.9097L114.215 93.5597L116.615 95.6997L118.985 94.7297L121.215 95.2197L123.265 96.5097L125.375 97.9397L127.875 97.8197L130.265 95.3097L132.845 96.3497L134.575 93.9297L137.665 95.0697L139.035 92.4697L141.925 92.5197L144.105 91.3097L145.865 89.5697L147.015 87.0997L150.345 87.1597L150.145 83.9797L149.095 81.5997L150.135 79.0297L149.655 78.8897L149.425 78.3197L147.255 78.4097L147.555 75.9197L144.535 74.9397L146.375 73.1897Z"
                fill="#F6ECE5"
            />
            <path
                d="M118.225 52.0595C118.325 52.1195 118.425 52.1895 118.525 52.2595C119.985 50.8895 122.365 50.8195 125.065 52.3795C127.465 53.7595 129.605 56.1395 131.075 58.7895C132.125 58.7895 133.325 59.1295 134.605 59.8695C137.795 61.7095 140.505 65.4395 141.595 69.1095L132.985 64.1395L128.675 61.6495L122.985 58.3595L122.265 57.9495L117.775 55.3595L117.145 54.9995L113.455 52.8695C114.195 51.2195 116.045 50.8195 118.215 52.0695L118.225 52.0595Z"
                fill="#B39D90"
            />
            <path
                d="M132.315 88.1792C131.105 85.4892 129.035 82.9792 126.675 81.6092C123.805 79.9492 121.355 80.4892 120.385 82.6592L132.025 89.3792L132.975 89.9292C132.805 89.3392 132.585 88.7592 132.325 88.1792H132.315Z"
                fill="#B39D90"
            />
            <path
                d="M122.265 69.1708C120.875 66.0708 118.475 63.1608 115.745 61.5908C112.435 59.6808 109.605 60.2908 108.475 62.8008L121.915 70.5608L123.005 71.1908C122.805 70.5108 122.555 69.8408 122.255 69.1708H122.265Z"
                fill="#B39D90"
            />
            <path
                d="M96.625 85.5686C96.475 85.5686 96.345 85.4786 96.305 85.3286C95.575 82.8486 93.725 80.3986 91.695 79.2286C91.635 79.1886 91.575 79.1686 91.515 79.1386L91.405 79.0886C91.335 79.0586 91.285 78.9986 91.255 78.9386C89.775 75.8386 87.375 73.1086 84.845 71.6486C82.665 70.3886 80.605 70.1886 79.185 71.1086C79.045 71.1986 78.855 71.1686 78.745 71.0486C77.685 69.8186 76.495 68.8086 75.305 68.1186C73.645 67.1586 72.045 66.8586 70.805 67.2586C69.915 67.5486 69.215 68.1986 68.795 69.1286C68.715 69.2986 68.515 69.3686 68.355 69.2986C68.185 69.2186 68.105 69.0286 68.185 68.8586C68.685 67.7386 69.525 66.9686 70.605 66.6186C72.035 66.1586 73.825 66.4786 75.655 67.5386C76.845 68.2286 78.025 69.2086 79.085 70.3986C80.705 69.5086 82.915 69.7486 85.195 71.0686C87.805 72.5786 90.275 75.3686 91.825 78.5386C91.895 78.5786 91.975 78.6086 92.045 78.6486C94.245 79.9186 96.175 82.4686 96.965 85.1386C97.015 85.3186 96.915 85.4986 96.735 85.5586C96.705 85.5586 96.675 85.5686 96.635 85.5686H96.625Z"
                fill="#B39D90"
            />
            <path
                d="M146.675 85.1293C146.515 85.1293 146.365 85.0093 146.345 84.8393C145.745 80.8793 142.855 76.5993 139.475 74.6493C137.315 73.3993 135.295 73.3393 134.065 74.4893C133.935 74.6193 133.725 74.6093 133.595 74.4793C133.005 73.8693 132.375 73.3793 131.735 73.0093C130.625 72.3693 129.565 72.1693 128.755 72.4293C128.175 72.6193 127.725 73.0393 127.455 73.6493C127.375 73.8193 127.175 73.8893 127.015 73.8193C126.845 73.7393 126.765 73.5493 126.845 73.3793C127.195 72.5893 127.795 72.0393 128.555 71.7993C129.565 71.4793 130.815 71.6993 132.085 72.4293C132.695 72.7793 133.295 73.2393 133.865 73.7893C135.325 72.6493 137.525 72.7393 139.825 74.0693C143.365 76.1193 146.395 80.6093 147.025 84.7493C147.055 84.9293 146.925 85.0993 146.745 85.1293C146.725 85.1293 146.715 85.1293 146.695 85.1293H146.675Z"
                fill="#B39D90"
            />
        </g>
        <g clipPath="url(#clip2_756_248075)">
            <path
                d="M212.375 39.1897L214.345 39.7097L216.135 38.3097L216.905 39.3497L217.455 37.3897L217.825 34.4997L214.265 32.9497L211.775 32.0197L211.205 29.8197L210.915 27.2597L208.095 26.7997L205.525 27.0897L202.885 29.1897L200.485 26.8797L197.755 28.6697L196.025 26.2297L193.075 27.2597L191.115 25.6397L191.045 22.1997L190.565 21.1397L187.745 21.1497L185.125 21.4397L183.715 18.4897L180.885 20.0997L178.845 18.8997L176.335 20.1997L174.115 17.7097L171.455 18.1797L168.805 19.1597L166.415 15.9297L163.735 18.6897L161.185 17.8197L158.645 18.6397L155.965 16.8697L153.435 17.8097L150.735 17.0097L148.285 18.2897L145.705 18.5597L143.725 19.8497L140.665 18.8497L140.315 23.2897L138.515 24.9397L134.885 25.8797L138.245 27.9597L139.475 28.7297L138.925 29.8997L137.665 28.9797L133.415 30.5597L132.295 27.6997L132.775 31.0897L131.975 33.0397L129.175 34.8997L129.975 37.3297L129.305 40.1897L132.935 40.0197L136.255 42.0797L136.185 44.7597L135.015 46.9197L136.735 48.4697L138.155 49.0397L140.415 50.6997L142.525 47.9797L145.295 50.3397L147.465 47.7097L150.065 50.1597L152.435 48.9897L155.895 51.6197L152.295 53.6897L153.805 55.7097L156.825 55.0097L158.155 56.6697L160.785 56.2497L163.445 57.4997L165.405 59.4097L168.015 60.0097L170.615 59.2997L172.835 62.5897L175.405 61.9297L177.905 61.9097L180.215 59.5597L182.615 61.6997L184.985 60.7297L187.215 61.2197L189.265 62.5097L191.375 63.9397L193.875 63.8197L196.265 61.3097L198.845 62.3497L200.575 59.9297L203.665 61.0697L205.035 58.4697L207.925 58.5197L210.105 57.3097L211.865 55.5697L213.015 53.0997L216.345 53.1597L216.145 49.9797L215.095 47.5997L216.135 45.0297L215.655 44.8897L215.425 44.3197L213.255 44.4097L213.555 41.9197L210.535 40.9397L212.375 39.1897Z"
                fill="#F6ECE5"
            />
            <path
                d="M198.315 54.1812C197.105 51.4912 195.035 48.9812 192.675 47.6112C189.805 45.9512 187.355 46.4912 186.385 48.6612L198.025 55.3812L198.975 55.9312C198.805 55.3412 198.585 54.7612 198.325 54.1812H198.315Z"
                fill="#B39D90"
            />
            <path
                d="M168.265 29.1708C166.875 26.0708 164.475 23.1608 161.745 21.5908C158.435 19.6808 155.605 20.2908 154.475 22.8008L167.915 30.5608L169.005 31.1908C168.805 30.5108 168.555 29.8408 168.255 29.1708H168.265Z"
                fill="#B39D90"
            />
            <path
                d="M162.625 51.5705C162.475 51.5705 162.345 51.4805 162.305 51.3305C161.575 48.8505 159.725 46.4005 157.695 45.2305C157.635 45.1905 157.575 45.1705 157.515 45.1405L157.405 45.0905C157.335 45.0605 157.285 45.0005 157.255 44.9405C155.775 41.8405 153.375 39.1105 150.845 37.6505C148.665 36.3905 146.605 36.1905 145.185 37.1105C145.045 37.2005 144.855 37.1705 144.745 37.0505C143.685 35.8205 142.495 34.8105 141.305 34.1205C139.645 33.1605 138.045 32.8605 136.805 33.2605C135.915 33.5505 135.215 34.2005 134.795 35.1305C134.715 35.3005 134.515 35.3705 134.355 35.3005C134.185 35.2205 134.105 35.0305 134.185 34.8605C134.685 33.7405 135.525 32.9705 136.605 32.6205C138.035 32.1605 139.825 32.4805 141.655 33.5405C142.845 34.2305 144.025 35.2105 145.085 36.4005C146.705 35.5105 148.915 35.7505 151.195 37.0705C153.805 38.5805 156.275 41.3705 157.825 44.5405C157.895 44.5805 157.975 44.6105 158.045 44.6505C160.245 45.9205 162.175 48.4705 162.965 51.1405C163.015 51.3205 162.915 51.5005 162.735 51.5605C162.705 51.5605 162.675 51.5705 162.635 51.5705H162.625Z"
                fill="#B39D90"
            />
            <path
                d="M212.675 51.1312C212.515 51.1312 212.365 51.0112 212.345 50.8412C211.745 46.8812 208.855 42.6012 205.475 40.6512C203.315 39.4012 201.295 39.3412 200.065 40.4912C199.935 40.6212 199.725 40.6112 199.595 40.4812C199.005 39.8712 198.375 39.3812 197.735 39.0112C196.625 38.3712 195.565 38.1712 194.755 38.4312C194.175 38.6212 193.725 39.0412 193.455 39.6512C193.375 39.8212 193.175 39.8912 193.015 39.8212C192.845 39.7412 192.765 39.5512 192.845 39.3812C193.195 38.5912 193.795 38.0412 194.555 37.8012C195.565 37.4812 196.815 37.7012 198.085 38.4312C198.695 38.7812 199.295 39.2412 199.865 39.7912C201.325 38.6512 203.525 38.7412 205.825 40.0712C209.365 42.1212 212.395 46.6112 213.025 50.7512C213.055 50.9312 212.925 51.1012 212.745 51.1312C212.725 51.1312 212.715 51.1312 212.695 51.1312H212.675Z"
                fill="#B39D90"
            />
        </g>
        <path
            d="M118.675 46.7621C118.515 46.7621 118.365 46.6421 118.345 46.4721C117.745 42.5121 114.855 38.2321 111.475 36.2821C109.315 35.0321 107.295 34.9721 106.065 36.1221C105.935 36.2521 105.725 36.2421 105.595 36.1121C105.005 35.5021 104.375 35.0121 103.735 34.6421C102.625 34.0021 101.565 33.8021 100.755 34.0621C100.175 34.2521 99.7249 34.6721 99.4549 35.2821C99.3749 35.4521 99.1749 35.5221 99.0149 35.4521C98.8449 35.3721 98.7649 35.1821 98.8449 35.0121C99.1949 34.2221 99.7949 33.6721 100.555 33.4321C101.565 33.1121 102.815 33.3321 104.085 34.0621C104.695 34.4121 105.295 34.8721 105.865 35.4221C107.325 34.2821 109.525 34.3721 111.825 35.7021C115.365 37.7521 118.395 42.2421 119.025 46.3821C119.055 46.5621 118.925 46.7321 118.745 46.7621C118.725 46.7621 118.715 46.7621 118.695 46.7621H118.675Z"
            fill="#B39D90"
        />
        <defs>
            <clipPath id="clip0_756_248075">
                <rect
                    width="88.65"
                    height="49.37"
                    fill="white"
                    transform="translate(0.175049 0.380859)"
                />
            </clipPath>
            <clipPath id="clip1_756_248075">
                <rect
                    width="88.65"
                    height="49.37"
                    fill="white"
                    transform="translate(63.175 48.75)"
                />
            </clipPath>
            <clipPath id="clip2_756_248075">
                <rect
                    width="88.65"
                    height="49.37"
                    fill="white"
                    transform="translate(129.175 14.75)"
                />
            </clipPath>
        </defs>
    </SvgIcon>
)

export default DirtGroup
