import React, {
    ForwardedRef,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useSetMobileNavigationState } from '@thriveglobal/thrive-web-core'
import { ThriveResetAudio } from '../../../graphql/generated/autogenerated'
import {
    ClosePlayerPopupButton,
    InitialPlayButton,
    listenInterval,
    PlayerContentFullscreenLoading,
    PlayerControls,
    playerControlsZIndex,
    useResetPlayerVolume,
    volumeLevelMin
} from '../CustomResetPlayer'
import useTemporaryAnimationToken from '../hooks/useTemporalAnimationToken'
import { PlayStateAnimated } from '../PlayState'
import ThriveAudioResetLogo from '../icons/ThriveAudioResetLogo'
import { postResetTimeOffsetInSeconds } from '../PostResetScreen'

export type ThriveAudioResetPlayerProps = {
    thriveAudioReset: ThriveResetAudio
    open: boolean
    autoPlay?: boolean
    onClose: () => void

    utmSource: string
    onNext?: (nextThriveAudioReset: ThriveResetAudio) => void
    // player callbacks
    onPlay?: () => void
    onPause?: () => void
    onTimeUpdate?: (newCurrentTime: number) => void
    onEnded?: () => void
    onLoadedMetadata?: () => void
    onVolumeChanged?: () => void
    onError?: () => void
}

function ThriveAudioResetPlayer(
    props: ThriveAudioResetPlayerProps,
    ref: ForwardedRef<ReactAudioPlayer>
) {
    const {
        thriveAudioReset,
        onNext,
        onEnded,
        onPlay,
        onLoadedMetadata,
        onVolumeChanged,
        onPause,
        onError,
        autoPlay,
        onClose,
        onTimeUpdate,
        open,
        utmSource
    } = props

    const theme = useTheme()

    const innerAudioPlayerRef = useRef<ReactAudioPlayer>(null)
    const audioPlayerRef =
        !!ref && typeof ref !== 'function' ? ref : innerAudioPlayerRef

    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [volume, setVolume] = useResetPlayerVolume()
    const [playbackStartedOnce, setPlaybackStartedOnce] = useState(false)
    const [isPostResetDisplayed, setIsPostResetDisplayed] = useState(false)
    const [paused, setPaused] = useState(true)

    const {
        animationToken: playStateAnimationToken,
        updateAnimationToken: updatePlayStateAnimationToken
    } = useTemporaryAnimationToken()

    const setMobileNavbarState = useSetMobileNavigationState()

    useEffect(() => {
        if (open) {
            setMobileNavbarState(false)
        }

        return function cleanup() {
            setMobileNavbarState(true)
        }
    }, [open, setMobileNavbarState])

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const audio = thriveAudioReset?.mp3Url

    const play = useCallback(
        () => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current

            audioPlayerElement?.play().then(() => {
                setPlaybackStartedOnce(true)
            })
            setPaused(audioPlayerElement?.paused ?? true)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [] // Ignore playerRef
    )

    const pause = useCallback(
        () => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current

            audioPlayerElement?.pause()
            setPaused(audioPlayerElement?.paused ?? true)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [] // Ignore playerRef
    )

    // HACK: 1. autoPlay causing audio to keep playing by OS after modal is closed in Chrome on OSX, so trigger play manually once audio is ready
    // HACK: 2. onCanPlay is not supported by Safari, use onLoadedMetadata instead
    const handleLoadedMetadata = useCallback(
        () => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current

            const audioDuration =
                audioPlayerElement?.duration ??
                thriveAudioReset?.durationInSeconds
            setDuration(audioDuration)

            const isPaused = !!audioPlayerElement?.paused
            if (open && audio && isPaused && autoPlay) {
                play()
            }

            onLoadedMetadata?.()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            thriveAudioReset?.durationInSeconds,
            open,
            audio,
            autoPlay,
            play,
            onLoadedMetadata
        ] // Ignore playerRef
    )

    const handlePlayControlClick = useCallback(() => {
        play()
    }, [play])

    const handlePauseControlClick = useCallback(() => {
        pause()
    }, [pause])

    const resetAudioPlayback = useCallback(
        () => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current
            if (audioPlayerElement) {
                audioPlayerElement.currentTime = 0
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [] // Ignore playerRef
    )

    const resetResetPlayback = useCallback(() => {
        resetAudioPlayback()
    }, [resetAudioPlayback])

    const handleVolumeChange = useCallback(
        (newVolume: number) => {
            setVolume(newVolume)
        },
        [setVolume]
    )

    const handleListenAudio = useCallback(
        (newCurrentTime: number) => {
            setCurrentTime(newCurrentTime)

            onTimeUpdate?.(newCurrentTime)

            const resetDuration =
                audioPlayerRef.current?.audioEl?.current?.duration ??
                thriveAudioReset?.durationInSeconds

            const showPostReset =
                resetDuration - newCurrentTime <= postResetTimeOffsetInSeconds

            setIsPostResetDisplayed(showPostReset)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onTimeUpdate, thriveAudioReset?.durationInSeconds] // Ignore playerRef
    )

    const handleEnded = useCallback(() => {
        pause()

        resetResetPlayback()

        setMobileNavbarState(true)

        onEnded?.()
    }, [pause, resetResetPlayback, setMobileNavbarState, onEnded])

    const muted = useMemo(() => {
        return volume <= volumeLevelMin
    }, [volume])

    const handlePlayerClose = useCallback(
        (event: SyntheticEvent) => {
            event?.stopPropagation()
            setMobileNavbarState(true)

            onClose()
        },
        [onClose, setMobileNavbarState]
    )

    const handleControlsContainerClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()
        },
        []
    )

    const handleInitialPlayClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            play()
        },
        [play]
    )

    const handleMiddlePlayerClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            updatePlayStateAnimationToken()

            if (paused) {
                play()
            } else {
                pause()
            }
        },
        [play, paused, pause, updatePlayStateAnimationToken]
    )

    const handleCurrentTimeChangeManually = useCallback(
        (newCurrentTime: number) => {
            setCurrentTime(newCurrentTime)

            pause()
        },
        [pause]
    )

    const handleCurrentTimeChangeCommittedManually = useCallback(
        (newCurrentTime: number) => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current

            if (audioPlayerElement) {
                audioPlayerElement.currentTime = newCurrentTime
            }

            play()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [play] // Ignore playerRef
    )

    const handleReplay = useCallback(
        () => {
            const audioPlayerElement = audioPlayerRef.current?.audioEl?.current
            if (!audioPlayerElement) {
                return
            }

            audioPlayerElement.currentTime = 0

            if (paused) {
                play()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [paused, play] // Ignore playerRef
    )

    return (
        <Box
            position="absolute"
            width="100%"
            height="100%"
            bgcolor={theme.palette.primary.main}
            data-testid="ThriveAudioResetPlayerContent"
        >
            {thriveAudioReset ? (
                <>
                    <Box
                        sx={[
                            {
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                px: theme.spacing(2.75),
                                pb: theme.spacing(1.375),
                                zIndex: playerControlsZIndex
                            },
                            isMobileView && {
                                px: 0,
                                pb: 0
                            }
                        ]}
                        style={{ visibility: 'visible' }}
                        onClick={handleControlsContainerClick}
                        data-testid="ThriveAudioResetPlayerControlsContainer"
                    >
                        <PlayerControls
                            onPlay={handlePlayControlClick}
                            onPause={handlePauseControlClick}
                            isPlaying={!paused}
                            onVolumeChange={handleVolumeChange}
                            volume={volume}
                            currentTime={currentTime}
                            duration={duration}
                            onCurrentTimeChange={
                                handleCurrentTimeChangeManually
                            }
                            onCurrentTimeChangeCommitted={
                                handleCurrentTimeChangeCommittedManually
                            }
                        />
                    </Box>

                    {!isPostResetDisplayed && playbackStartedOnce && (
                        <Box
                            data-testid="ThriveAudioResetAnimation"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <ThriveAudioResetLogo />
                        </Box>
                    )}

                    <Stack
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: playerControlsZIndex
                        }}
                        alignItems="center"
                        justifyContent="center"
                        role="button"
                        onClick={handleMiddlePlayerClick}
                    >
                        {!playbackStartedOnce && (
                            <InitialPlayButton
                                onClick={handleInitialPlayClick}
                            />
                        )}
                        <PlayStateAnimated
                            paused={paused}
                            animationToken={playStateAnimationToken}
                        />
                    </Stack>

                    {audio && (
                        <div data-testid="ThriveAudioResetAudio">
                            <ReactAudioPlayer
                                ref={audioPlayerRef}
                                src={audio}
                                autoPlay={autoPlay}
                                controlsList={'nodownload'}
                                volume={volume}
                                muted={muted}
                                onLoadedMetadata={handleLoadedMetadata}
                                onPlay={onPlay}
                                onPause={onPause}
                                onVolumeChanged={onVolumeChanged}
                                onEnded={handleEnded}
                                onError={onError}
                                onListen={handleListenAudio}
                                listenInterval={listenInterval}
                            />
                        </div>
                    )}
                </>
            ) : (
                <PlayerContentFullscreenLoading
                    loadingColor={theme.palette.primary.contrastText}
                />
            )}

            <ClosePlayerPopupButton
                onClick={handlePlayerClose}
                sx={{ top: 0, zIndex: playerControlsZIndex }}
            />
        </Box>
    )
}

export default React.memo(React.forwardRef(ThriveAudioResetPlayer))
