import { Stack } from '@mui/material'
import React, { memo, useCallback } from 'react'
import ConditionalSkeleton from '../ConditionalSkeleton/ConditionalSkeleton'
import IntakeButton from './IntakeButton'
import TrackerInfo from './TrackerInfo'

export type TrackerProps = {
    loading?: boolean
    onIncrement: (increment: boolean) => void
    removeLabel: string
    addLabel: string
    removeDisabled?: boolean
    addDisabled?: boolean
    completed?: boolean
    message: React.ReactNode
}

const Tracker: React.FC<TrackerProps> = ({
    loading,
    onIncrement,
    removeLabel,
    addLabel,
    completed,
    removeDisabled,
    addDisabled,
    message
}) => {
    const handleDecrement = useCallback(() => onIncrement(false), [onIncrement])
    const handleIncrement = useCallback(() => onIncrement(true), [onIncrement])

    return (
        <Stack direction="row" gap={2} alignItems="center" maxWidth="100%">
            <IntakeButton
                loading={!!loading}
                disabled={removeDisabled}
                onClick={handleDecrement}
                label={removeLabel}
                dataTestid="remove-intake"
                icon={'circle-minus'}
            />
            <ConditionalSkeleton showSkeleton={!!loading} variant="rounded">
                <TrackerInfo completed={!!completed} message={message} />
            </ConditionalSkeleton>
            <IntakeButton
                loading={!!loading}
                disabled={addDisabled}
                onClick={handleIncrement}
                label={addLabel}
                dataTestid="add-intake"
                icon={'circle-plus'}
            />
        </Stack>
    )
}

export default memo(Tracker)
