import { BarDatum, BarItemProps } from '@nivo/bar'
import { motion } from 'framer-motion'
import { memo } from 'react'

const BarElement: React.FC<BarItemProps<BarDatum>> = (props) => {
    const {
        bar: { height, width, x }
    } = props

    const newWidth = 6
    const xOffset = (width - newWidth) / 2

    return (
        <motion.rect
            initial={{ height: 0, y: props.bar.y + height }}
            animate={{ height: height, y: props.bar.y }}
            transition={{ duration: 0.5, delay: Math.random() * 0.5 }}
            x={x + xOffset}
            width={newWidth}
            fill="url(#greenGradient)"
        />
    )
}

export default memo(BarElement)
