import {
    OutlinedInputProps,
    TextField,
    TextFieldProps,
    styled
} from '@mui/material'
import { memo } from 'react'

const StyledTextField: React.FC<TextFieldProps> = styled(
    (props: TextFieldProps) => (
        <TextField
            {...props}
            InputProps={
                {
                    disableUnderline: true,
                    ...props.InputProps
                } as Partial<OutlinedInputProps>
            }
        />
    )
)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: `1px solid ${theme.palette.primary.main}`,
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: 'transparent',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow'
        ]),
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.primary.main
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            borderColor: 'transparent'
        },
        '&.Mui-error': {
            borderColor: theme.palette.error.main
        }
    }
}))

export default memo(StyledTextField)
