import { Box, Link } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { Post } from '../../../graphql/generated/autogenerated'
import { ActivityTypes } from '../../../shared/modules/social/enums/activityTypes'
import { PostTypes } from '../../../shared/modules/social/enums/postTypes'

const resetUtmSource = '?utm_source=social_group'

const messages = defineMessages({
    microstepCheckIn: {
        description:
            'An activity message indicating that a user completed a microstep',
        defaultMessage: 'Checked into a Microstep.'
    },
    microstepMultipleCheckIn: {
        description:
            'An activity message indicating that a user completed multiple microsteps',
        defaultMessage:
            'Checked into {microstepCount, plural, one {# Microstep.} other {# Microsteps.}}'
    },
    challengeJoined: {
        description:
            'An activity message indicating that a user joined a challenge',
        defaultMessage: 'Joined <link>{entityName}</link>.'
    },
    socialGroupJoined: {
        description:
            'A message indicating that a user joined the current social group',
        defaultMessage: 'Joined the team.'
    },
    socialGroupCreated: {
        description:
            'An activity message indicating that a user created the current social group',
        defaultMessage: 'Created the team.'
    },
    challengeStreak: {
        description:
            'An activity message indicating that a user achieved a streak of check-ins as part of a challenge',
        defaultMessage: 'Got a new Challenge Streak.'
    },
    challengeCompletion: {
        description:
            'An activity message indicating that a user completed a challenge',
        defaultMessage: 'Completed <link>{entityName}</link>'
    },
    resetWatched: {
        description:
            'An activity message indicating that a user watched a reset',
        defaultMessage:
            'Watched a Reset. <link>Take a moment to watch one yourself.</link>'
    },
    multipleResetWatched: {
        description:
            'An activity message indicating that a user watched multiple reset',
        defaultMessage:
            'Watched {resetCount, plural, one {# Reset.} other {# Resets.}}'
    },
    personalizedReset: {
        description:
            'An activity message indicating that a user created a personal reset',
        defaultMessage:
            'Created a Personal Reset. <link>Click here to create yours</link>.'
    },
    achievementLevelUp: {
        description:
            'An activity message indicating that a user has earned a new achievement',
        defaultMessage:
            'Leveled up an achievement. <link>Click here to see yours</link>.'
    },
    multipleSocialGroupCreated: {
        description:
            'An activity message indicating that a user created multiple social groups',
        defaultMessage:
            'Created {count, plural, one {# Group.} other {# Groups.}}'
    },
    multipleSocialGroupJoined: {
        description:
            'An activity message indicating that a user joined multiple social groups',
        defaultMessage:
            'Joined {count, plural, one {# Group.} other {# Groups.}}'
    },
    multipleChallengeCompletion: {
        description:
            'An activity message indicating that a user completed multiple challenges',
        defaultMessage:
            'Completed {count, plural, one {# Challenge. <link>Join one now</link>} other {# Challenges. <link>Join one now</link>}}'
    },
    multipleChallengeStreak: {
        description:
            'An activity message indicating that a user has multiple challenge streaks',
        defaultMessage:
            'Got {count, plural, one {# new Challenge Streak.} other {# new Challenge Streaks.}}'
    },
    multipleChallengeJoined: {
        description:
            'An activity message indicating that a user joined multiple Challenges',
        defaultMessage:
            'Joined {count, plural, one {# Challenge. <link>Join one now</link>} other {# Challenges. <link>Join one now</link>}}'
    },
    multiplePersonalizedReset: {
        description:
            'An activity message indicating that a user created multiple personal resets',
        defaultMessage:
            'Created {count, plural, one {# Personal Reset. <link>Click here to create yours</link>} other {# Personal Resets. <link>Click here to create yours</link>}}'
    },
    multipleAchievementLevelUp: {
        description:
            'An activity message indicating that a user joined multiple social groups',
        defaultMessage:
            'Leveled up {count, plural, one {# Achievement. <link>Click here to see yours</link>.} other {# Achievements. <link>Click here to see yours</link>.}}'
    },
    dailyStepActionCompleted: {
        description:
            'An activity message indicating that a user completed their daily step activity',
        defaultMessage:
            'Completed their daily activity goal in <link>{challengeName}</link>'
    },
    communityDateCompleted: {
        description:
            'An activity message indicating that a user completed their daily goals',
        defaultMessage: 'Earned a plant in <link>{challengeName}</link>'
    },
    multipleCommunityDateCompleted: {
        description:
            'An activity message indicating that a team completed their daily goals',
        defaultMessage:
            'Earned {count, plural, one {# plant in <link>{challengeName}</link>} other {# plants in <link>{challengeName}</link>}}'
    },
    dailyActionCompleted: {
        description:
            'An activity message indicating that a user completed a daily goal',
        defaultMessage: 'Completed a daily goal in <link>{challengeName}</link>'
    },
    multipleDailyActionCompleted: {
        description:
            'An activity message indicating that a team completed multiple daily goals',
        defaultMessage:
            'Completed {count, plural, one {# daily goal in <link>{challengeName}</link>} other {# daily goals in <link>{challengeName}</link>}}'
    }
})

type ActivityMessage = {
    activity?: Post
    activityLinkChunk?: any
}

export interface SocialGroupTranslations {
    hideJoinedTeamMessage: boolean
}

export const useSocialGroupTranslations = (hideJoinedTeamMessage = false) => {
    const { formatMessage } = useIntl()
    const navigate = useCrossAppNavigation()

    const createPostMessage = useCallback(
        (descriptor: MessageDescriptor, dataTestid: string, values?: any) => (
            <Box data-testid={dataTestid}>
                {formatMessage(descriptor, { ...values })}
            </Box>
        ),
        [formatMessage]
    )

    const activityMessages = useMemo<{
        [key: string]: (message: ActivityMessage) => JSX.Element
    }>(
        () => ({
            [PostTypes.MICROSTEP_CHECKIN]: () =>
                createPostMessage(
                    messages.microstepCheckIn,
                    'activity-microstep-checkin'
                ),
            [PostTypes.MICROSTEP_MULTIPLE_CHECKIN]: ({ activity }) =>
                createPostMessage(
                    messages.microstepMultipleCheckIn,
                    'activity-microstep-multiple-checkin',
                    {
                        microstepCount: activity?.postType.message
                    }
                ),
            [PostTypes.CHALLENGE_JOINED]: ({ activityLinkChunk }) =>
                createPostMessage(
                    messages.challengeJoined,
                    'activity-challenge-joined',
                    {
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.SOCIAL_GROUP_JOINED]: () =>
                createPostMessage(
                    messages.socialGroupJoined,
                    'activity-social-group-joined'
                ),
            [PostTypes.SOCIAL_GROUP_CREATED]: () =>
                createPostMessage(
                    messages.socialGroupCreated,
                    'activity-social-group-created'
                ),
            [PostTypes.CHALLENGE_STREAK]: () =>
                createPostMessage(
                    messages.challengeStreak,
                    'activity-challenge-streak'
                ),
            [PostTypes.CHALLENGE_COMPLETION]: ({ activityLinkChunk }) =>
                createPostMessage(
                    messages.challengeCompletion,
                    'activity-challenge-completion',
                    {
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.RESET_WATCHED]: ({ activityLinkChunk }) =>
                createPostMessage(
                    messages.resetWatched,
                    'activity-reset-watched',
                    {
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_RESET_WATCHED]: ({ activity }) =>
                createPostMessage(
                    messages.multipleResetWatched,
                    'activity-reset-multiple-watched',
                    {
                        resetCount: activity?.postType.message
                    }
                ),
            [PostTypes.PERSONALIZED_RESET]: ({ activityLinkChunk }) =>
                createPostMessage(
                    messages.personalizedReset,
                    'activity-personalized-reset',
                    {
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.ACHIEVEMENT_LEVEL_UP]: ({ activityLinkChunk }) =>
                createPostMessage(
                    messages.achievementLevelUp,
                    'activity-achievement-levelup',
                    {
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_SOCIAL_GROUP_CREATED]: ({ activity }) =>
                createPostMessage(
                    messages.multipleSocialGroupCreated,
                    'activity-multiple-social-group-created',
                    {
                        count: activity?.postType.message
                    }
                ),
            [PostTypes.MULTIPLE_SOCIAL_GROUP_JOINED]: ({ activity }) =>
                createPostMessage(
                    messages.multipleSocialGroupJoined,
                    'activity-multiple-social-group-joined',
                    {
                        count: activity?.postType.message
                    }
                ),
            [PostTypes.MULTIPLE_CHALLENGE_COMPLETION]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multipleChallengeCompletion,
                    'activity-multiple-challenge-completion',
                    {
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_CHALLENGE_STREAK]: ({ activity }) =>
                createPostMessage(
                    messages.multipleChallengeStreak,
                    'activity-multiple-challenge-streak',
                    {
                        count: activity?.postType.message
                    }
                ),
            [PostTypes.MULTIPLE_CHALLENGE_JOINED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multipleChallengeJoined,
                    'activity-multiple-challenge-joined',
                    {
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_PERSONALIZED_RESET]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multiplePersonalizedReset,
                    'activity-multiple-personalized-resets',
                    {
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_ACHIEVEMENT_LEVEL_UP]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multipleAchievementLevelUp,
                    'activity-multiple-social-group-joined',
                    {
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.STEPS_DAILY_ACTION_COMPLETED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.dailyStepActionCompleted,
                    'daily-step-goal-completed',
                    {
                        challengeName: activity?.entityName,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.COMMUNITY_DAY_COMPLETED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.communityDateCompleted,
                    'community-daily-goal-completed',
                    {
                        challengeName: activity?.entityName,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_COMMUNITY_DAY_COMPLETED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multipleCommunityDateCompleted,
                    'multiple-community-daily-goal-completed',
                    {
                        challengeName: activity?.entityName,
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.DAILY_ACTION_COMPLETED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.dailyActionCompleted,
                    'daily-action-completed',
                    {
                        challengeName: activity?.entityName,
                        ...activityLinkChunk
                    }
                ),
            [PostTypes.MULTIPLE_DAILY_ACTION_COMPLETED]: ({
                activity,
                activityLinkChunk
            }) =>
                createPostMessage(
                    messages.multipleDailyActionCompleted,
                    'multiple-daily-action-completed',
                    {
                        challengeName: activity?.entityName,
                        count: activity?.postType.message,
                        ...activityLinkChunk
                    }
                )
        }),
        [createPostMessage]
    )

    const buildActivityLink = useCallback<any>((activity: Post) => {
        switch (activity.entityType) {
            case ActivityTypes.CHALLENGE:
                if (
                    activity.postType.postTypeId ===
                        PostTypes.MULTIPLE_CHALLENGE_COMPLETION ||
                    activity.postType.postTypeId ===
                        PostTypes.MULTIPLE_CHALLENGE_JOINED
                ) {
                    return {
                        pathname: `/challenges`
                    }
                }

                // This Walmart challenge should use the old link
                if (
                    activity.entityId === '9b498502-bce2-47dc-a4f3-3f2eb26a4ac9'
                ) {
                    return {
                        pathname: `/challenges/${activity.entityId}`
                    }
                }

                return {
                    pathname: `/challenges/g/${activity.entityId}`
                }
            case ActivityTypes.ACHIEVEMENT:
                return {
                    pathname: `/achievements`
                }
            case ActivityTypes.RESET: {
                if (
                    activity.postType.postTypeId === PostTypes.RESET_WATCHED ||
                    activity.postType.postTypeId ===
                        PostTypes.PERSONALIZED_RESET ||
                    activity.postType.postTypeId ===
                        PostTypes.MULTIPLE_PERSONALIZED_RESET
                ) {
                    return {
                        pathname: `/reset/`,
                        search: resetUtmSource
                    }
                }

                return null
            }
            default:
                return null
        }
    }, [])

    const generateActivityLinkChunk = useCallback(
        (activity: any, activityLink: any, handleActivityLinkClick: any) => {
            return activityLink
                ? {
                      entityName: activity.entityName,
                      link: (chunks: string[]) => (
                          <Link
                              component={RouterLink}
                              to={activityLink}
                              onClick={handleActivityLinkClick}
                              data-testid="Activity-link"
                          >
                              {chunks}
                          </Link>
                      )
                  }
                : {}
        },
        []
    )

    const handleActivityLinkClick = useCallback(
        (event: any, activityLink: any) => {
            event.preventDefault()

            if (activityLink) {
                let navLink = activityLink.pathname

                if (activityLink.search) {
                    navLink += activityLink.search
                }

                navigate(navLink)
            }
        },
        [navigate]
    )

    const generateActivityMessage = useCallback(
        (activity: Post) => {
            if (
                hideJoinedTeamMessage &&
                activity.postType.postTypeId === PostTypes.SOCIAL_GROUP_JOINED
            ) {
                return
            }
            const message = activityMessages[activity.postType.postTypeId]
            const activityLink = buildActivityLink(activity)

            return message
                ? message({
                      activity,
                      activityLinkChunk: generateActivityLinkChunk(
                          activity,
                          activityLink,
                          (event: React.SyntheticEvent<Element, Event>) =>
                              handleActivityLinkClick(event, activityLink)
                      )
                  })
                : null
        },
        [
            activityMessages,
            buildActivityLink,
            hideJoinedTeamMessage,
            generateActivityLinkChunk,
            handleActivityLinkClick
        ]
    )

    return { generateActivityMessage }
}

export default useSocialGroupTranslations
