import { Stack } from '@mui/material'
import { LeafFixedMediaCard } from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'
import { memo } from 'react'

import useGetChallengeStats from '../../../../hooks/useGetChallengeStats'

import CompletedChallengeCardHeader from './CompletedChallengeCardHeader'
import CompletedChallengeCardStats from './CompletedChallengeCardStats'
import CompletedChallengeCardFooter from './CompletedChallengeCardFooter'
import CompletedChallengeCardMedia from './CompletedChallengeCardMedia'
import CompletedChallengeCardSkeleton from './CompletedChallengeCardSkeleton'

import type { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'

type CompletedChallengeCardProps = {
    challenge: UnifiedChallenge
    navigateToChallenge: () => void
}

const CompletedChallengeCard = ({
    challenge,
    navigateToChallenge
}: CompletedChallengeCardProps) => {
    const { formatMessage } = useIntl()
    const { totalEarnedPoints, totalCompletedDays, loading } =
        useGetChallengeStats(challenge.id)

    if (loading) {
        return <CompletedChallengeCardSkeleton />
    }

    return (
        <LeafFixedMediaCard
            alignMedia="top"
            mediaWidth="md"
            media={<CompletedChallengeCardMedia challenge={challenge} />}
            actionAreaProps={{
                onClick: navigateToChallenge,
                'aria-label': formatMessage({
                    defaultMessage: 'Navigate to challenge',
                    description: 'description for navigate to challenge button'
                })
            }}
        >
            <Stack height={224} direction={'column'}>
                <Stack justifyContent="space-between" height="100%">
                    <CompletedChallengeCardHeader challenge={challenge} />
                    <CompletedChallengeCardStats
                        totalEarnedPoints={totalEarnedPoints || 0}
                        totalCompletedDays={totalCompletedDays || 0}
                    />
                    <CompletedChallengeCardFooter
                        onViewClick={navigateToChallenge}
                    />
                </Stack>
            </Stack>
        </LeafFixedMediaCard>
    )
}

export default memo(CompletedChallengeCard)
