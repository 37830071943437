import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant2: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#B0DF84',
    colorLayer2 = '#178C15',
    colorLayer3 = '#73D273',
    colorLayer4 = '#236807',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M47.0681 0.0136719C47.0681 0.0136719 55.6881 18.4437 55.5381 26.0237C55.4481 30.4537 54.5681 48.8637 53.8181 55.1337C51.5681 73.9137 47.7081 83.9437 47.7081 83.9437C47.7081 83.9437 42.0081 56.2537 42.9781 46.0537C43.9481 35.8537 44.658 27.4137 43.748 21.0237C42.838 14.6437 45.4881 4.44367 47.0681 0.0136719Z"
                fill={colorLayer1}
            />
            <path
                d="M47.578 11.6437C48.828 17.9737 49.8581 24.4237 50.1581 30.9437C50.0781 40.1537 49.198 49.4237 48.448 58.6737C48.158 62.6137 47.3081 70.5937 46.7581 74.5537C47.5481 61.5237 49.1781 47.9137 49.6281 34.9337C50.0081 27.0137 48.3681 19.3837 47.5681 11.6437H47.578Z"
                fill={colorLayer2}
            />
            <path
                d="M65.1481 66.6137C55.9681 69.6937 47.718 80.2237 45.188 83.6937C45.198 83.6937 45.2181 83.6937 45.2281 83.6937C45.2781 83.6937 45.3181 83.7037 45.3681 83.7137C49.5781 79.1437 67.0581 62.5037 89.2281 73.6537C89.4881 73.7837 76.1881 62.9037 65.1481 66.6137Z"
                fill={colorLayer3}
            />
            <path
                d="M34.0981 61.0637C42.2081 66.2937 47.5581 78.5137 49.1281 82.4937C49.1181 82.4937 49.1081 82.4937 49.0881 82.4837C49.0381 82.4837 48.998 82.4837 48.948 82.4737C46.018 77.0137 33.2781 56.6137 8.98806 61.9837C8.70806 62.0437 24.3281 54.7637 34.0881 61.0637H34.0981Z"
                fill={colorLayer1}
            />
            <path
                d="M91.9381 58.3937C91.7581 58.4337 70.8781 50.5437 61.7381 60.3437C56.9481 65.4837 46.8481 83.1737 46.8481 83.1737L45.8981 83.5137C45.8981 83.5137 44.3681 83.6537 45.5981 80.3237C48.9581 71.1837 58.6581 51.3337 67.6581 48.5437C79.9281 44.7437 91.948 58.3937 91.948 58.3937H91.9381Z"
                fill={colorLayer2}
            />
            <g opacity="0.75">
                <path
                    d="M73.998 47.9937C80.098 48.6837 85.5781 52.5637 88.8381 55.3837C87.1681 55.6937 85.4281 55.9737 83.6781 56.2337C77.7481 55.1237 69.858 54.6637 64.308 58.2237C63.998 58.2237 63.818 58.2237 63.798 58.2037C63.798 58.2037 68.438 52.4637 73.998 47.9837V47.9937Z"
                    fill={colorLayer4}
                />
            </g>
            <path
                d="M83.5381 54.0537C72.6981 46.3437 61.838 53.3237 54.698 64.7937C52.478 68.2537 50.5881 71.8837 48.7281 75.5337C50.3081 71.7637 52.108 68.0537 54.388 64.6037C61.368 53.2637 73.1981 45.4037 83.5381 54.0537Z"
                fill={colorLayer4}
            />
            <path
                d="M21.138 17.0837C21.138 17.0837 31.378 20.4437 38.128 33.7437C44.888 47.0437 45.958 55.4537 46.448 65.7437C46.928 76.0337 44.698 83.3837 44.698 83.3837C44.698 83.3837 40.188 63.5537 35.958 53.2237C31.718 42.8937 26.4581 34.4637 24.3981 30.7737C20.2681 23.3337 21.138 17.0837 21.138 17.0837Z"
                fill={colorLayer4}
            />
            <path
                d="M28.2581 26.5237C31.5281 29.9537 33.838 34.0237 35.798 38.1937C40.698 48.6237 43.408 59.9637 44.808 71.4737C44.938 73.8037 45.0081 76.1437 45.1181 78.4737C44.7881 76.1837 44.4981 73.8837 44.1481 71.6037C42.2181 55.5937 37.6481 39.8137 28.2581 26.5337V26.5237Z"
                fill={colorLayer3}
            />
            <path
                d="M53.8981 50.4637C57.5381 40.9737 63.6681 26.7137 70.8981 20.9737C70.8981 20.9737 69.8081 32.6337 67.3781 39.2337C65.4181 44.5637 56.5381 62.6537 52.8181 70.3637C51.8781 72.3137 50.7381 74.2137 49.4081 76.0237L46.058 80.6137C46.058 80.6137 44.5481 74.8537 53.8981 50.4737V50.4637Z"
                fill={colorLayer4}
            />
            <path
                d="M65.9881 33.1537C63.3881 40.2237 58.5381 51.2237 55.5681 58.3137C52.5181 65.4037 48.038 76.4337 44.688 83.3737C47.288 76.3037 52.138 65.2937 55.108 58.2137C58.158 51.1237 62.6381 40.0937 65.9881 33.1537Z"
                fill={colorLayer2}
            />
            <path
                d="M46.0681 83.0637C46.0681 83.0637 48.1181 78.2037 47.5281 74.0937C46.4581 66.6337 43.1181 54.1737 34.6581 53.4537C21.5181 52.3337 11.9581 54.6037 11.9581 54.6037C11.9581 54.6037 24.1181 59.0937 30.6381 60.1337C37.1581 61.1637 46.0781 83.0637 46.0781 83.0637H46.0681Z"
                fill={colorLayer2}
            />
            <path
                d="M25.968 55.6837C30.488 55.3937 34.538 57.0937 38.078 58.9937C42.958 61.6637 44.588 66.9737 45.608 71.7837C45.768 73.0337 45.9081 74.2837 45.9881 75.5437C45.6281 74.3437 45.3181 73.1437 44.9281 71.9637C42.4681 60.9537 38.3281 58.2337 25.9781 55.6737L25.968 55.6837Z"
                fill={colorLayer4}
            />
            <path
                d="M46.0681 83.0637C46.0681 83.0637 46.728 81.4937 47.198 79.3837C42.748 59.4337 30.6281 60.1337 30.6281 60.1337C37.1481 61.1637 46.0681 83.0637 46.0681 83.0637Z"
                fill={colorLayer2}
            />
            <g opacity="0.75">
                <path
                    d="M46.0681 83.0637C46.0681 83.0637 46.728 81.4937 47.198 79.3837C42.748 59.4337 30.6281 60.1337 30.6281 60.1337C37.1481 61.1637 46.0681 83.0637 46.0681 83.0637Z"
                    fill={colorLayer4}
                />
            </g>
            <path
                d="M45.718 83.0237C45.888 83.0237 46.0381 83.0637 46.1781 83.1037C46.5781 82.6737 47.028 82.2737 47.528 81.9337C43.368 76.3837 35.348 67.4437 23.418 65.8037L43.968 83.7337C44.348 83.2937 44.9381 82.9837 45.7081 83.0337L45.718 83.0237Z"
                fill={colorLayer3}
            />
            <path
                d="M47.298 85.0137L68.698 61.6737C62.928 64.0037 47.4581 78.6437 43.0981 83.5237C44.5981 83.8537 45.988 84.3737 47.298 85.0137Z"
                fill={colorLayer2}
            />
            <g opacity="0.5">
                <path
                    d="M47.298 85.0137L68.698 61.6737C62.928 64.0037 47.4581 78.6437 43.0981 83.5237C44.5981 83.8537 45.988 84.3737 47.298 85.0137Z"
                    fill={colorLayer4}
                />
            </g>
        </g>
    </BasePlant>
)

export default CommunityPlant2
