// External libraries
import { Card, Grid, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

// Types
import {
    SocialGroup,
    UnifiedChallenge,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import { ChallengeThemes } from '../../../../enums/challengeThemes'
import { ChallengeType } from '../../../../enums/challengeType'

// Hooks
import { useChallengeTheme } from '../../../../hooks/useChallengeTheme/useChallengeTheme'
import {
    isChallengeStarted,
    isChallengeExpired,
    isSoloChallenge
} from '../../../../utils/challenge'

// Components
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import ManageDeviceButton from '../../../elements/ManageDeviceButton'
import CreateResetBanner from '../../Shared/CreateResetBanner'
import ConnectDeviceBanner from './components/ConnectDeviceBanner'
import ChallengeAbout from '../../Shared/ChallengeAbout'
import ChallengeTodaysProgress from './components/ChallengeTodaysProgress'
import DailyGoalsContainer from '../DailyGoalsContainer'
import YourProgressContainer from '../../YourProgressContainer'
import TeamProgressContainer from '../../TeamProgressContainer'
import DailyGoalsCompletedDialogs from './components/DailyGoalsCompletedDialogs'

interface GoalsProps {
    challenge: UnifiedChallenge
    userPlant?: UserPlantInfo
    loading: boolean
    socialGroup?: SocialGroup | null
}

const Goals: React.FC<GoalsProps> = ({
    challenge,
    loading,
    userPlant,
    socialGroup
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const [resetPlaying, setResetPlaying] = useState<boolean>(false)
    const challengeStarted = isChallengeStarted(challenge)
    const challengeExpired = isChallengeExpired(challenge)
    const day = challenge?.dayOfChallenge

    const soloChallenge = socialGroup?.isSoloChallenge
    const { deviceEnabled, sleepEnabled } = useChallengeTheme(challenge)

    const dailyGoals = useMemo(
        () => (
            <Card
                elevation={0}
                sx={{
                    overflow: 'visible',
                    borderRadius: isMobile ? 0 : undefined
                }}
            >
                <Stack p={2} gap={3}>
                    {challengeStarted && (
                        <CoreTypography
                            variant={isMobile ? 'h4' : 'body1'}
                            {...(!isMobile && {
                                fontSize: 24,
                                fontWeight: 400
                            })}
                        >
                            <FormattedMessage
                                defaultMessage="Welcome to day {day} of the Challenge! Here’s your plan for the day"
                                description="goal introduction welcome message"
                                values={{ day }}
                            />
                        </CoreTypography>
                    )}
                    <DailyGoalsContainer
                        setResetPlaying={setResetPlaying}
                        challenge={challenge}
                        loading={loading}
                    />
                    {challengeStarted && (
                        <ChallengeTodaysProgress challenge={challenge} />
                    )}
                    {(deviceEnabled || sleepEnabled) && challengeStarted && (
                        <Stack direction="row" justifyContent="end">
                            <ConditionalSkeleton showSkeleton={loading}>
                                <ManageDeviceButton />
                            </ConditionalSkeleton>
                        </Stack>
                    )}
                </Stack>
            </Card>
        ),
        [loading, day, deviceEnabled, sleepEnabled, isMobile, challengeStarted]
    )

    const dailyGoalsPageLayout = useMemo(
        () =>
            isMobile ? (
                dailyGoals
            ) : (
                <PageLayout maxWidth="lg">{dailyGoals}</PageLayout>
            ),
        [isMobile, dailyGoals]
    )

    const renderDailyGoals = useCallback(() => {
        const shouldShow =
            challenge?.challenge_type === ChallengeType.company
                ? challengeStarted && !challengeExpired
                : !challengeExpired

        if (!shouldShow) return null

        return (
            <Grid item sm={12}>
                {dailyGoalsPageLayout}
            </Grid>
        )
    }, [challengeStarted, challengeExpired, dailyGoalsPageLayout, challenge])

    const renderConnectDeviceBanner = useCallback(() => {
        const shouldShow = (deviceEnabled || sleepEnabled) && !challengeStarted

        if (!shouldShow) return null

        return (
            <Grid item sm={12}>
                <PageLayout maxWidth="lg">
                    <ConnectDeviceBanner />
                </PageLayout>
            </Grid>
        )
    }, [deviceEnabled, sleepEnabled, challengeStarted, challenge])

    const renderCreateResetBanner = useCallback(() => {
        const shouldShow = challenge?.theme === ChallengeThemes.RESET

        if (!shouldShow) return null

        return (
            <Grid item xs={12}>
                <PageLayout maxWidth="lg">
                    <CreateResetBanner />
                </PageLayout>
            </Grid>
        )
    }, [challenge])

    const renderYourProgressContainer = useCallback(() => {
        const component = soloChallenge ? (
            <YourProgressContainer challenge={challenge} />
        ) : (
            <TeamProgressContainer challenge={challenge} />
        )

        if (!component) return null

        return (
            <Grid item xs={12}>
                <PageLayout maxWidth="lg">{component}</PageLayout>
            </Grid>
        )
    }, [challenge, soloChallenge])

    const renderChallengeAbout = useCallback(() => {
        return (
            <Grid item xs={12}>
                <PageLayout maxWidth="lg">
                    <ChallengeAbout challenge={challenge} />
                </PageLayout>
            </Grid>
        )
    }, [challenge])

    return (
        <>
            <DailyGoalsCompletedDialogs
                resetPlaying={resetPlaying}
                challenge={challenge}
                userPlant={userPlant}
            />
            <Grid container>
                {renderConnectDeviceBanner()}
                {renderDailyGoals()}
                {renderCreateResetBanner()}
                {renderYourProgressContainer()}
                {renderChallengeAbout()}
            </Grid>
        </>
    )
}

export default memo(Goals)
