import { createContext } from 'react'

export interface CompanyChallengeRefetchProviderValue {
    dailyGoalRefetch: () => Promise<any>
    teamNameRefetch: () => Promise<any>
    displayNameRefetch: () => Promise<any>
}

export const defaultContextValue: CompanyChallengeRefetchProviderValue = {
    dailyGoalRefetch: () => Promise.resolve(),
    teamNameRefetch: () => Promise.resolve(),
    displayNameRefetch: () => Promise.resolve()
}

export default createContext(defaultContextValue)
