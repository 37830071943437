import { useMemo } from 'react'
import { ApolloError } from '@apollo/client/errors'

import {
    GetChallengeUserChallengeQuery,
    UnifiedChallengeActivity,
    useGetChallengeUserChallengeQuery
} from '../../../graphql/generated/autogenerated'

export type ChallengeUserChallenge = {
    participation?: UnifiedChallengeActivity
    data?: GetChallengeUserChallengeQuery
    loading: boolean
    error: ApolloError | undefined
    refetch: () => void
}

interface UseGetChallengeUserChallengeProps {
    challengeId?: string
}

const useGetChallengeUserChallenge = ({
    challengeId
}: UseGetChallengeUserChallengeProps): ChallengeUserChallenge => {
    const { data, loading, refetch, error } = useGetChallengeUserChallengeQuery(
        {
            variables: {
                challengeId: challengeId,
                inProgress: true
            },
            skip: !challengeId
        }
    )

    const participation = useMemo(() => {
        return data?.unifiedChallenges.unifiedUserChallenges.find(
            (userChallenge) => userChallenge.challenge.id === challengeId
        )?.participation?.[0] as UnifiedChallengeActivity
    }, [data, challengeId])

    return {
        participation,
        data,
        loading,
        error,
        refetch
    }
}

export default useGetChallengeUserChallenge
