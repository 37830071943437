import { Icon, Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { FallbackProps } from '@thriveglobal/thrive-web-errors'
import {
    CoreTypography,
    LoadingButton,
    MarkLogoMulti,
    PageLayout
} from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Page from '../Page'

const buttonStyles = {
    whiteSpace: 'nowrap',
    textAlign: 'center'
} as const

type PageFallbackProps = FallbackProps

const PageFallback: React.FC<PageFallbackProps> = ({
    error,
    resetErrorBoundary
}: PageFallbackProps): JSX.Element => {
    const navigate = useCrossAppNavigation()
    const [isRetryLoading, setRetryLoading] = useState<boolean>(false)
    const [isNavigateLoading, setNavigateLoading] = useState<boolean>(false)

    const handleRetryClick = useCallback(async (): Promise<void> => {
        setRetryLoading(true)
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            resetErrorBoundary()
        } finally {
            setRetryLoading(false)
        }
    }, [resetErrorBoundary])

    const handleNavigateClick = useCallback(async (): Promise<void> => {
        setNavigateLoading(true)
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            navigate('/')
        } finally {
            setNavigateLoading(false)
        }
    }, [navigate])

    return (
        <PageLayout maxWidth="lg">
            <Page>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                >
                    <Stack gap={2} alignItems="start">
                        <Icon component={MarkLogoMulti} sx={{ fontSize: 60 }} />

                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Full Page Failure"
                                description="title shown when a page fails to load"
                            />
                        </CoreTypography>

                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Error: {message}"
                                description="error message display format"
                                values={{ message: error.message }}
                            />
                        </CoreTypography>

                        <Stack direction="row" gap={2}>
                            <LoadingButton
                                fixWidth
                                variant="contained"
                                onClick={handleRetryClick}
                                loading={isRetryLoading}
                                disabled={isRetryLoading}
                            >
                                <CoreTypography
                                    customVariant="buttonNormal"
                                    sx={buttonStyles}
                                >
                                    <FormattedMessage
                                        defaultMessage="Retry Component"
                                        description="button text to retry loading a failed component"
                                    />
                                </CoreTypography>
                            </LoadingButton>

                            <LoadingButton
                                fixWidth
                                variant="contained"
                                onClick={handleNavigateClick}
                                endIcon={<Icon component={MarkLogoMulti} />}
                                loading={isNavigateLoading}
                                disabled={isNavigateLoading}
                            >
                                <CoreTypography
                                    variant="body1"
                                    sx={buttonStyles}
                                >
                                    <FormattedMessage
                                        defaultMessage="Go to main page"
                                        description="button text to navigate back to the main page"
                                    />
                                </CoreTypography>
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Page>
        </PageLayout>
    )
}

export default PageFallback
