import { createContext, FC, useContext, useState, useCallback } from 'react'
import { merge } from 'lodash'
import { ReactNull } from '../../utils/nulls'
import { ReactNullValue } from '../../../shared/utils/Nulls'

interface MultiStepFormContextValue {
    steps: string[]
    currentStepIndex: number
    setCurrentStepIndex: (index: number) => void

    currentStepName: string
    setCurrentStepName: React.Dispatch<React.SetStateAction<string>>

    totalSteps: number
    setTotalSteps: React.Dispatch<React.SetStateAction<number>>
    goToNextStep: () => void
    goToPreviousStep: () => void
    completedSteps: Set<string>
    setStepCompletion: (name: string, isComplete: boolean) => void
    requiredSteps: Set<string>
    markStepAsRequired: (name: string) => void
    isStepComplete: (name: string) => boolean
    isStepRequired: (name: string) => boolean
    formData: Record<string, any>
    updateFormData: (data: object) => void
    setSteps: React.Dispatch<React.SetStateAction<string[]>>
}

const MultiStepFormContext = createContext<
    MultiStepFormContextValue | ReactNull
>(ReactNullValue)

export const MultiStepFormProvider: FC<{
    children: React.ReactNode
    defaultFormData?: Record<string, any>
}> = ({ children, defaultFormData }) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [currentStepName, setCurrentStepName] = useState('')

    const [totalSteps, setTotalSteps] = useState(0)
    const [requiredSteps, setRequiredSteps] = useState<Set<string>>(new Set())
    const [completedSteps, setCompletedSteps] = useState<Set<string>>(new Set())

    const [formData, setFormData] = useState<Record<string, any>>(
        defaultFormData ?? {}
    )
    const [steps, setSteps] = useState<string[]>([])

    const setStepCompletion = useCallback(
        (name: string, isComplete: boolean) => {
            if (isComplete) {
                setCompletedSteps((prev) => new Set(prev).add(name))
            } else {
                setCompletedSteps((prev) => {
                    const updated = new Set(prev)
                    updated.delete(name)
                    return updated
                })
            }
        },
        []
    )

    const markStepAsRequired = (name: string) => {
        setRequiredSteps((prev) => new Set(prev).add(name))
    }

    const isStepComplete = useCallback(
        (name: string) => completedSteps.has(name) ?? false,
        [completedSteps]
    )
    const isStepRequired = useCallback(
        (name: string) => requiredSteps.has(name),
        [requiredSteps]
    )

    const goToNextStep = useCallback(() => {
        if (
            !isStepRequired(currentStepName) ||
            isStepComplete(currentStepName)
        ) {
            const nextStepName = steps[currentStepIndex + 1]
            setCurrentStepName(nextStepName)
            setCurrentStepIndex((prev) => prev + 1)
        }
    }, [
        currentStepIndex,
        currentStepName,
        isStepComplete,
        isStepRequired,
        totalSteps
    ])

    const goToPreviousStep = useCallback(() => {
        if (currentStepIndex > 0) {
            const previousStepName = steps[currentStepIndex - 1]
            setCurrentStepName(previousStepName)
            setCurrentStepIndex((prev) => prev - 1)
        }
    }, [currentStepIndex, steps])

    const updateFormData = useCallback((data: object) => {
        setFormData((prev) => {
            return merge({}, prev, data)
        })
    }, [])
    return (
        <MultiStepFormContext.Provider
            value={{
                steps,
                currentStepIndex,
                setCurrentStepIndex,
                currentStepName,
                setCurrentStepName,
                totalSteps,
                setTotalSteps,
                goToNextStep,
                goToPreviousStep,
                completedSteps,
                setStepCompletion,
                requiredSteps,
                markStepAsRequired,
                isStepComplete,
                isStepRequired,
                formData,
                updateFormData,
                setSteps
            }}
        >
            {children}
        </MultiStepFormContext.Provider>
    )
}

export const withMultiStepForm =
    <P extends object>(Component: FC<P>): FC<P> =>
    (props) =>
        (
            <MultiStepFormProvider>
                <Component {...props} />
            </MultiStepFormProvider>
        )

export const useMultiStepFormContext = () => {
    const context = useContext(MultiStepFormContext)
    if (!context) {
        throw new Error(
            'useMultiStepFormContext must be used within a MultiStepFormProvider'
        )
    }
    return context
}
