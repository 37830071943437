import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant18: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#8BD07D',
    colorLayer2 = '#BBDE8F',
    colorLayer3 = '#428A2D',
    colorLayer4 = '#53A354',
    colorLayer5 = '#37661D',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M75.8205 13.9184C74.4605 12.1284 75.5306 9.99833 72.8506 10.0783C71.6206 10.1183 70.9806 13.6583 69.3506 14.4583C68.2006 15.0283 65.0806 13.0283 63.7806 14.1783C62.7906 15.0583 64.2005 18.4884 63.1305 19.7884C60.9405 22.4584 56.0606 25.7484 54.4106 31.5084C53.1606 35.8884 55.3905 41.5784 54.4605 46.4784C53.5105 51.4784 49.3505 55.8983 48.7305 60.2783C47.5505 68.5783 49.3606 67.5183 50.3406 74.7383C50.4406 75.4483 49.3605 76.6783 50.0705 76.6783C50.7805 76.6783 50.7805 73.2683 51.5005 73.2683C52.2205 73.2683 52.2105 76.6783 52.9305 76.6783C53.6505 76.6783 52.5106 74.8283 52.8406 74.1883C54.9006 70.1683 56.5405 71.5284 57.5505 66.3884C58.0805 63.7084 55.4206 59.8983 56.2206 56.8083C56.9906 53.8683 61.1305 51.9283 62.1505 49.2083C63.3505 46.0283 61.7605 41.5583 63.3105 39.5483C64.8605 37.5383 69.2105 37.4583 70.7705 35.2783C72.3205 33.1083 70.9505 29.0383 72.2305 26.8483C73.6605 24.4083 77.9406 23.3783 78.6606 21.1183C79.5806 18.2483 76.7805 16.1183 75.8205 13.9383V13.9184Z"
                fill={colorLayer1}
            />
            <path
                d="M53.7405 64.5484C52.3805 66.3384 53.4505 68.4684 50.7705 68.3884C49.5405 68.3484 48.9005 64.8084 47.2705 64.0084C46.1205 63.4384 43.0005 65.4384 41.7005 64.2884C40.7105 63.4084 42.1205 59.9784 41.0505 58.6784C38.8605 56.0084 33.9805 52.7184 32.3305 46.9584C31.0805 42.5784 33.3105 36.8884 32.3805 31.9884C31.4305 26.9884 27.2705 22.5684 26.6505 18.1884C25.9005 12.9084 27.4105 9.02837 28.2305 3.93837C28.2505 3.78837 28.2805 3.63837 28.3805 3.52837C28.5205 3.37837 28.7505 3.36838 28.9505 3.38838C30.8805 3.61838 31.8905 6.12836 33.1305 7.39836C34.4605 8.76836 35.0905 10.1884 35.4605 12.0784C35.9905 14.7584 33.3305 18.5684 34.1305 21.6584C34.9005 24.5984 39.0405 26.5384 40.0605 29.2584C41.2605 32.4384 39.6705 36.9084 41.2205 38.9184C42.7505 40.9184 47.1205 41.0084 48.6805 43.1884C50.2405 45.3684 48.8605 49.4284 50.1405 51.6184C51.5705 54.0584 55.8505 55.0884 56.5705 57.3484C57.4905 60.2184 54.6905 62.3484 53.7305 64.5284L53.7405 64.5484Z"
                fill={colorLayer1}
            />
            <path
                d="M68.4005 23.8083L66.6606 25.8583C65.9906 26.4283 65.3405 27.0184 64.7105 27.6284C62.1905 30.0484 59.9205 32.7684 58.2005 35.8184C57.4205 37.4184 56.6705 38.9384 55.8105 40.5084C55.8105 36.8084 58.2405 33.6883 60.3705 30.8583C62.7205 28.1883 65.0505 25.2183 68.4005 23.7983V23.8083Z"
                fill={colorLayer5}
            />
            <path
                d="M47.0205 68.9283C49.0205 62.8483 50.0605 63.6784 48.9005 56.4784C48.2805 52.5984 44.0705 48.9784 42.8405 44.7284C41.6105 40.4784 43.1405 34.9984 41.1205 31.5684C38.7005 27.4584 33.7105 26.8883 29.8405 25.8283C27.0705 25.0683 25.2505 21.3383 23.3505 21.2783C19.9605 21.1783 19.3605 24.6283 18.4805 26.2183C17.5705 27.8783 14.9105 30.2383 16.3305 32.9683C17.2705 34.7783 21.4505 34.7883 23.2605 36.7683C25.0705 38.7483 24.2405 43.3783 26.0505 46.1583C27.6805 48.6583 32.1905 49.5984 33.6505 52.2584C35.2305 55.1084 33.6505 59.4884 34.8605 61.9484C37.2405 66.7384 38.1805 65.0584 41.4705 68.2284C42.2205 68.9484 41.3305 71.1684 42.3705 71.1684C43.4105 71.1684 43.4105 67.7584 44.4505 67.7584C45.4905 67.7584 45.1705 70.9284 46.1905 71.1384C46.6405 71.2284 46.6705 69.9183 47.0005 68.9283H47.0205Z"
                fill={colorLayer3}
            />
            <g opacity="0.75">
                <path
                    d="M26.9005 30.6483C32.6305 33.6583 35.9505 39.8084 38.5905 45.4784C39.9605 48.2784 43.5305 55.5483 44.8605 58.2683C45.4105 59.7683 45.9905 61.2384 46.5005 62.7584C45.6205 61.4284 44.8105 60.0584 43.9605 58.7184C41.6305 53.9584 37.9405 46.4184 35.5905 41.6684C33.4305 37.4684 30.1805 34.0084 26.9005 30.6584V30.6483Z"
                    fill={colorLayer5}
                />
            </g>
            <g opacity="0.75">
                <path
                    d="M49.0905 57.7284C49.0305 57.3384 48.9705 56.9284 48.9005 56.4784C48.2805 52.5984 44.0705 48.9784 42.8405 44.7284C41.6105 40.4784 43.1405 34.9984 41.1205 31.5684C40.7105 30.8784 40.2305 30.2984 39.7005 29.7884C39.5305 30.5584 39.3705 31.3484 39.2405 32.1684C38.4305 37.4784 40.9406 44.0084 40.1006 50.0384C39.4706 54.5884 36.9306 58.8784 35.4106 62.9784C37.4006 66.4684 38.4506 65.3084 41.4706 68.2184C42.2206 68.9384 41.3305 71.1584 42.3705 71.1584C43.4105 71.1584 43.4105 67.7484 44.4505 67.7484C45.4905 67.7484 45.1705 70.9184 46.1905 71.1284C46.6405 71.2184 46.6705 69.9084 47.0005 68.9184C47.0005 68.9184 47.0005 68.9184 47.0005 68.9084C46.5305 66.8384 46.0805 64.7084 46.4805 62.7484C46.8505 60.9484 47.9305 59.3084 49.0705 57.7284H49.0905Z"
                    fill={colorLayer5}
                />
            </g>
            <path
                d="M63.5105 4.50837C61.7105 2.85837 62.8205 1.09837 60.0705 0.72837C58.5805 0.52837 56.5906 3.74836 54.9106 3.68836C53.2306 3.62836 51.2405 0.298366 49.7905 0.568366C47.8105 0.938366 48.2606 3.85837 47.8506 5.25837C47.3906 6.83837 43.6606 13.2584 43.3506 22.2284C43.1606 27.5984 46.4205 33.7884 46.2905 39.8784C46.1605 45.9784 42.6305 52.0084 42.5505 57.3684C42.3905 67.4484 42.8505 66.0884 45.6405 74.4084C46.0505 75.6284 45.3106 76.6584 46.6006 76.6584C47.8906 76.6584 47.8905 73.2484 49.1805 73.2484C50.4705 73.2484 50.4705 76.6584 51.7605 76.6584C53.0505 76.6584 52.2205 75.2283 52.8605 74.1083C55.7205 69.0883 56.2605 70.1584 56.6805 63.5284C56.9005 60.0084 53.7805 55.7584 54.1205 51.7584C54.4605 47.7784 58.2405 44.1484 58.6905 40.6184C59.3705 35.2584 57.0005 29.8983 57.9205 28.2983C58.8405 26.6983 62.9505 26.0584 63.8905 23.6784C64.6805 21.6784 62.1706 18.4484 62.7206 16.2084C63.2706 13.9984 67.0105 12.2184 67.1205 10.0284C67.2305 7.65836 64.1705 6.54835 63.5105 4.51835V4.50837Z"
                fill={colorLayer2}
            />
            <path
                d="M83.7205 30.0283C81.5805 28.0883 82.3605 25.9183 78.9005 26.5483C77.3405 26.8283 76.8005 30.6384 74.8405 31.8884C73.4405 32.7784 69.7305 31.3483 68.2005 32.9583C67.0205 34.1983 68.5505 37.8483 67.3305 39.6183C65.4705 42.3283 60.7005 43.5383 59.0505 46.6283C57.4205 49.6783 59.0505 54.3383 57.6905 57.3583C56.1605 60.7483 51.5905 62.6584 50.4905 65.4784C48.2705 71.1484 48.1505 70.3484 50.2305 75.0384C50.8405 76.4184 50.0005 80.3783 51.5205 80.3783C57.9305 80.3783 58.6005 74.9684 59.9305 74.2284C63.1505 72.4284 63.4905 74.2983 66.0305 70.3783C67.1605 68.6383 65.6405 64.6483 67.3005 62.3683C68.6205 60.5583 72.9005 60.6883 74.5605 58.6183C76.0005 56.8183 74.8905 52.7083 76.6005 50.7683C78.3005 48.8283 82.4505 49.3484 83.6905 47.8184C85.4305 45.6684 83.8405 41.9283 84.7505 40.3583C86.2305 37.8183 89.9205 36.4383 89.5805 34.4983C89.2405 32.5583 85.2905 32.3683 83.7105 30.0283H83.7205Z"
                fill={colorLayer3}
            />
            <path
                d="M35.6606 76.3983C33.2706 74.9083 36.5706 71.8983 34.5306 69.3583C33.4606 68.0283 29.6306 68.7983 28.2606 67.3683C26.9406 65.9983 27.7706 62.1283 26.3306 61.0483C24.6606 59.7983 21.5806 61.8983 19.9606 61.5483C18.1406 61.1583 18.0706 57.0984 16.6506 55.3784C15.3506 53.7984 11.3706 53.4183 10.8306 51.5283C10.1906 49.2783 13.2606 47.6283 14.3406 46.1483C15.3006 44.8283 15.8706 41.3684 18.7206 41.6584C21.5906 41.9384 23.2006 46.8984 26.0906 49.8484C28.3606 52.1584 33.1906 52.8583 35.2206 55.8283C37.2606 58.8183 36.2206 63.7284 37.7706 66.5084C40.6806 71.7184 45.7506 69.7483 45.7206 74.9583C45.7206 75.8883 43.4506 79.8583 42.5106 79.8583C40.0006 79.8583 36.4306 76.8984 35.6406 76.4084L35.6606 76.3983Z"
                fill={colorLayer4}
            />
            <path
                d="M54.9305 10.7683C53.7705 15.3183 52.9905 20.0184 53.0305 24.7184C53.6205 32.5784 55.0805 40.5184 53.7105 48.4084C53.2905 51.5884 51.2406 54.2084 50.6606 57.3784C48.5706 66.5784 48.0205 76.1383 48.2305 85.5483L47.2305 85.5684C47.2705 76.0584 47.8105 66.4883 49.9905 57.2083C50.5805 54.0983 52.6205 51.4684 53.0105 48.2884C53.5205 45.2084 53.4405 42.0484 53.2805 38.9084C53.0405 32.5884 51.3505 26.2684 52.3905 19.9384C52.8505 16.7784 53.6105 13.6784 54.7305 10.6984L54.9505 10.7683H54.9305Z"
                fill={colorLayer5}
            />
            <path
                d="M20.6006 49.7983C33.0806 52.5683 36.2205 66.8983 44.1705 75.0883C45.2005 76.3783 46.3905 77.5483 47.7305 78.5783C46.1805 77.8883 44.7705 76.8884 43.4505 75.7884C37.5505 69.7684 34.8005 61.2584 28.7405 55.4484C26.2705 53.2384 23.5605 51.3183 20.5905 49.7983H20.6006Z"
                fill={colorLayer5}
            />
            <path
                d="M78.8405 36.0483C75.0305 40.8783 70.6505 45.2983 67.5005 50.6083C64.7305 56.1583 61.9005 61.7283 57.9605 66.5783C55.4305 69.7683 52.3505 73.2484 48.1405 73.9784C50.1005 73.2884 51.6705 71.9183 53.0605 70.4583C59.1105 64.8483 63.0205 57.4083 66.6405 50.1083C69.8905 44.7783 73.9406 39.9183 78.8506 36.0483H78.8405Z"
                fill={colorLayer5}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant18
