import { Card, Skeleton, Stack } from '@mui/material'
import { ConfettiExplosion, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useState } from 'react'
import DailyGoalCardStatus from '../DailyGoalCardStatus'

export type DailyGoalCardProps = {
    loading?: boolean
    completed?: boolean
    children?: React.ReactNode
}

const DailyGoalCard: React.FC<DailyGoalCardProps> = ({
    loading,
    completed,
    children
}) => {
    const { palette } = useTheme()
    const [originalCompletionState, setOriginalCompletionState] =
        useState<boolean>()
    const [showConfetti, setShowConfetti] = useState<boolean>(false)

    useEffect(() => {
        if (!loading && completed != undefined) {
            if (
                originalCompletionState != undefined &&
                !originalCompletionState &&
                completed
            ) {
                setShowConfetti(true)
            }

            setOriginalCompletionState(completed)
        }
    }, [originalCompletionState, loading, completed])

    return (
        <Stack position="relative" overflow="visible">
            <Card elevation={0}>
                <Stack p={2} gap={2} bgcolor={palette.secondary.main}>
                    <DailyGoalCardStatus
                        completed={completed}
                        loading={loading}
                    />
                    {loading ? (
                        <Skeleton variant="rounded" width="100%" height={218} />
                    ) : (
                        children
                    )}
                </Stack>
            </Card>
            <Stack position="absolute" top={0} right={8}>
                <ConfettiExplosion
                    show={showConfetti}
                    onComplete={() => setShowConfetti(false)}
                />
            </Stack>
        </Stack>
    )
}

export default memo(DailyGoalCard)
