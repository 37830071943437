import {
    Button,
    DialogActions,
    DialogContentProps,
    DialogContent as MuiDialogContent,
    Stack,
    Tabs,
    useMediaQuery
} from '@mui/material'
import {
    CoreTab,
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    ChallengeUserDailyGoal,
    StepsGoalDetail,
    useCompleteCommunityChallengeDailyGoalMutation,
    useSetStepsCustomizationMutation
} from '../../../../../../../../graphql/generated/autogenerated'
import {
    WHAT_ARE_ACTIVITY_POINTS_MESSAGE,
    WHAT_ARE_ACTIVITY_POINTS_TITLE
} from '..'
import EditGoal from './EditGoal'

const messages = defineMessages({
    editGoalTitle: {
        defaultMessage: 'Edit your activity points goal',
        description: 'Edit activity goal dialog title'
    },
    editGoalTab: {
        defaultMessage: 'Edit goal',
        description: 'Edit goal tab label'
    }
})

export type UpdateActivityGoalDialogProps = {
    open: boolean
    goal: ChallengeUserDailyGoal
    userTodaySteps: number
    dailyGoalRefetch?: () => Promise<void>
    onClose: () => void
}

/**
 * This component is a wrapper around the DialogContent component. It hoists the height of the
 * content to it's initial height. This is needed because the LeafDialog component
 * does not have a min-height property. Due to this, the height changes when the content
 * changes.
 **/

const DialogContent: React.FC<DialogContentProps> = ({
    children,
    sx,
    ...restProps
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    return (
        <MuiDialogContent
            ref={containerRef}
            sx={{
                height: containerRef.current?.clientHeight,
                ...sx
            }}
            {...restProps}
        >
            {children}
        </MuiDialogContent>
    )
}

const UpdateActivityGoalDialog: React.FC<UpdateActivityGoalDialogProps> = ({
    open,
    goal,
    userTodaySteps,
    onClose,
    dailyGoalRefetch
}) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [newGoal, setNewGoal] = useState<number>()
    const [saveLoading, setSaveLoading] = useState(false)

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { formatMessage } = useIntl()

    const goalInfo = useMemo(() => {
        return {
            currentGoal: (goal.goalEntityDetail as StepsGoalDetail).steps,
            goalId: goal.challengeGoal.id,
            challengeId: goal.challengeGoal.challengeId,
            goalCompleted: goal.completed
        }
    }, [goal])

    const [setStepsCustomizationMutation] = useSetStepsCustomizationMutation()
    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation()

    const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }
    const handleChangeGoal = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNewGoal(Number(event.target.value))
        },
        []
    )
    const handleSaveGoal = useCallback(async () => {
        if (saveLoading || !newGoal) return
        setSaveLoading(true)
        const { challengeId, goalId, goalCompleted } = goalInfo
        await setStepsCustomizationMutation({
            variables: {
                challengeId: challengeId,
                stepsAmount: newGoal
            }
        })

        if (!goalCompleted && userTodaySteps && userTodaySteps >= newGoal) {
            await completeDailyGoal({
                variables: {
                    challengeId: challengeId,
                    challengeGoalId: goalId
                }
            })
        }

        await dailyGoalRefetch?.()
        setSaveLoading(false)
        onClose()
    }, [
        newGoal,
        onClose,
        goalInfo,
        saveLoading,
        userTodaySteps,
        dailyGoalRefetch,
        completeDailyGoal,
        setStepsCustomizationMutation
    ])

    const tabs = [
        {
            label: formatMessage(messages.editGoalTab),
            value: 0
        },
        {
            label: formatMessage(WHAT_ARE_ACTIVITY_POINTS_TITLE),
            value: 1
        }
    ]

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={isSmallScreen}
            dialogTitle={
                <CoreTypography variant="h3">
                    {formatMessage(messages.editGoalTitle)}
                </CoreTypography>
            }
        >
            <DialogContent sx={{ paddingTop: 3, paddingBottom: 3 }}>
                <Tabs value={selectedTab} onChange={handleChangeTab}>
                    {tabs.map((t) => (
                        <CoreTab
                            value={t.value}
                            key={t.label}
                            aria-label={`open ${t.label} tab`}
                            label={t.label}
                        />
                    ))}
                </Tabs>

                <Stack pt={3}>
                    {selectedTab === 0 ? (
                        <EditGoal
                            currentGoal={goalInfo.currentGoal}
                            newGoal={newGoal}
                            onChangeGoal={handleChangeGoal}
                        />
                    ) : selectedTab === 1 ? (
                        <CoreTypography variant="body1">
                            {formatMessage(WHAT_ARE_ACTIVITY_POINTS_MESSAGE)}
                        </CoreTypography>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    gap={1}
                    width="100%"
                    px={isSmallScreen ? 2 : 0}
                    direction={isSmallScreen ? 'column' : 'row'}
                    justifyContent={isSmallScreen ? 'center' : 'flex-end'}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Close"
                                description="close popup button text"
                            />
                        </CoreTypography>
                    </Button>
                    <LoadingButton
                        variant="contained"
                        disabled={!newGoal || newGoal === goalInfo.currentGoal}
                        loading={saveLoading}
                        fixWidth={true}
                        onClick={handleSaveGoal}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Save new goal"
                                description="save goal button text"
                            />
                        </CoreTypography>
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}

export default UpdateActivityGoalDialog
