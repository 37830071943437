import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ChallengeThemes } from '../../../../../enums/challengeThemes'
import RadioList from '../../../../../../shared/components/elements/RadioList/RadioList'
import LeaderboardToggle from './LeaderboardToggle'
import UserPlantLeaderboard from '../UserLeaderboards/UserPlantLeaderboard'
import TeamPlantLeaderboard from '../TeamLeaderboards'
import UserActivityLeaderboard from '../UserLeaderboards/UserActivityLeaderboard'
import TeamActivityLeaderboard from '../TeamLeaderboards/TeamActivityLeaderboard'
import {
    useGetChallengeRanksQuery,
    type SocialGroup,
    type UnifiedChallenge
} from '../../../../../../graphql/generated/autogenerated'

type LeaderboardCardProps = {
    challenge: UnifiedChallenge
    socialGroup?: SocialGroup | null
    deviceEnabled: boolean
}

const messages = defineMessages({
    byUser: {
        defaultMessage: 'By user',
        description: 'by user radio item'
    },
    byTeam: {
        defaultMessage: 'By team',
        description: 'by team radio item'
    }
})

export enum LeaderboardType {
    Plants,
    Activity
}

enum LeaderboardScope {
    User,
    Team
}

const LeaderboardCard = ({
    challenge,
    socialGroup,
    deviceEnabled
}: LeaderboardCardProps) => {
    const { data: ranksData } = useGetChallengeRanksQuery({
        variables: {
            challengeId: challenge.id,
            socialGroupId: socialGroup?.id
        },
        skip: !challenge?.id || !socialGroup
    })

    const { formatMessage } = useIntl()
    const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(
        LeaderboardType.Plants
    )
    const [leaderboardScope, setLeaderboardScope] = useState<LeaderboardScope>(
        LeaderboardScope.User
    )

    const leaderboardOptions = useMemo(() => {
        return {
            [LeaderboardType.Plants]: {
                [LeaderboardScope.User]: (
                    <UserPlantLeaderboard
                        challengeId={challenge.id}
                        userPlantsTotalPoints={
                            ranksData?.retail.getSoloPlantsRank?.totalPlants
                        }
                        userPlantsRankDisplayName={
                            ranksData?.retail.getSoloPlantsRank?.displayName
                        }
                        userPlantsRank={
                            ranksData?.retail.getSoloPlantsRank?.rank
                        }
                    />
                ),
                [LeaderboardScope.Team]: (
                    <TeamPlantLeaderboard
                        challengeId={challenge.id}
                        maxTeamSize={challenge.maxTeamSize}
                        socialGroupId={socialGroup?.id}
                        teamPlantsTotalPoints={
                            ranksData?.retail.getTeamPlantsRank?.totalPlants
                        }
                        teamPlantsRankDisplayName={
                            ranksData?.retail.getTeamPlantsRank?.displayName
                        }
                        teamPlantsRank={
                            ranksData?.retail.getTeamPlantsRank?.rank
                        }
                    />
                )
            },
            [LeaderboardType.Activity]: {
                [LeaderboardScope.User]: (
                    <UserActivityLeaderboard
                        deviceEnabled={deviceEnabled}
                        challengeId={challenge.id}
                        userActivityRank={
                            ranksData?.unifiedChallenges?.getSoloPlayerRank
                                ?.rank
                        }
                        userActivityTotalPoints={
                            ranksData?.unifiedChallenges?.getSoloPlayerRank
                                ?.totalSteps
                        }
                        userActivityRankDisplayName={
                            ranksData?.unifiedChallenges?.getSoloPlayerRank
                                ?.requesterDisplayName
                        }
                    />
                ),
                [LeaderboardScope.Team]: (
                    <TeamActivityLeaderboard
                        deviceEnabled={deviceEnabled}
                        challengeId={challenge.id}
                        socialGroupId={socialGroup?.id}
                        maxTeamSize={challenge.maxTeamSize}
                        teamActivityRank={
                            ranksData?.unifiedChallenges?.getTeamRank?.rank
                        }
                        teamActivityRankTotalPoints={
                            ranksData?.unifiedChallenges?.getTeamRank
                                ?.totalSteps
                        }
                        teamActivityRankDisplayName={
                            ranksData?.unifiedChallenges?.getTeamRank
                                ?.requesterDisplayName
                        }
                    />
                )
            }
        }
    }, [
        challenge.id,
        challenge.maxTeamSize,
        deviceEnabled,
        socialGroup?.id,
        ranksData
    ])

    const laderboardContent = useMemo(
        () => leaderboardOptions[leaderboardType][leaderboardScope],
        [leaderboardType, leaderboardScope, leaderboardOptions]
    )

    const isPrivateChallenge = useMemo(
        () => !!challenge?.groupChallengeType,
        [challenge]
    )

    return (
        <Card elevation={0}>
            <Stack p={2} gap={2}>
                <CoreTypography variant="overline" color="text.disabled">
                    <FormattedMessage
                        defaultMessage="Leaderboard"
                        description="description for the leaderboard card"
                    />
                </CoreTypography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap={2}
                >
                    {challenge?.theme === ChallengeThemes.MOVEMENT && (
                        <LeaderboardToggle
                            leaderboardType={leaderboardType}
                            setLeaderboardType={setLeaderboardType}
                        />
                    )}
                    {!isPrivateChallenge && (
                        <RadioList
                            onChange={(scope) =>
                                setLeaderboardScope(scope as LeaderboardScope)
                            }
                            defaultValue={LeaderboardScope.User}
                            excludeSpacing={true}
                            row={true}
                            list={[
                                {
                                    value: LeaderboardScope.User,
                                    title: formatMessage(messages.byUser)
                                },
                                {
                                    value: LeaderboardScope.Team,
                                    title: formatMessage(messages.byTeam)
                                }
                            ]}
                        />
                    )}
                </Stack>
                {laderboardContent}
            </Stack>
        </Card>
    )
}

export default memo(LeaderboardCard)
