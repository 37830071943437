import { createContext } from 'react'
import { QuestionType } from '../../../enums/questionType'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../graphql/generated/autogenerated'
import { GQLNull, GQLNullValue } from '../../../../shared/utils/Nulls'
import { StoryData } from '../useParseStorySubmissionData'

export type QuestionData = {
    question?: string
    answer?: any
    ordering?: number
    type?: QuestionType
    image?: ImageData
}

export type ImageData = {
    description?: string
    uri?: string
}

export interface StorySubmissionContextValue {
    loading: boolean
    error: boolean
    stories: QuestionData[]
    questions: QuestionData[]
    interactionId?: string
    title?: string
    subTitle?: string
    termsAndConditionsUrl?: string | GQLNull
    challenge?: UnifiedChallenge
    participation?: UnifiedChallengeActivity
    storyComplete: boolean
    setChallenge: (challenge: UnifiedChallenge) => void
    setParticipation: (participation: UnifiedChallengeActivity) => void
    setChallengeStoryData: (storyData: StoryData) => void
}

export const defaultContextValue: StorySubmissionContextValue = {
    loading: false,
    error: false,
    stories: [],
    questions: [],
    interactionId: undefined,
    title: undefined,
    subTitle: undefined,
    termsAndConditionsUrl: GQLNullValue,
    challenge: undefined,
    participation: undefined,
    storyComplete: false,
    setChallenge: () => ({}),
    setParticipation: () => ({}),
    setChallengeStoryData: () => ({})
}

export default createContext(defaultContextValue)
