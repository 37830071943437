import { Card, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ChallengeTeamMembers from '../ChallengeTeamMembers'
import { SocialGroup } from '../../../../../graphql/generated/autogenerated'

const fullHeightCard = { height: '100%' }

interface ChallengeTeamNameProps {
    socialGroup: SocialGroup
}

const ChallengeTeamName: React.FC<ChallengeTeamNameProps> = ({
    socialGroup
}) => {
    const { palette } = useTheme()
    const [teamDialogOpen, setTeamDialogOpen] = React.useState(false)

    return (
        <React.Fragment>
            <Card elevation={0} sx={fullHeightCard}>
                <Stack p={2} gap={2}>
                    <CoreTypography variant="overline" color="text.disabled">
                        <FormattedMessage
                            defaultMessage="Team Name"
                            description="description for the challenge team name card"
                        />
                    </CoreTypography>
                    <Stack gap={1.25}>
                        <CoreTypography
                            variant="body1"
                            fontSize={24}
                            color="text.secondary"
                        >
                            {socialGroup?.name}
                        </CoreTypography>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            <Stack gap={1} direction="row" alignItems="center">
                                <LeafIcon
                                    icon="users"
                                    fontSize="small"
                                    iconStyle="solid"
                                    sx={{ color: palette.text.secondary }}
                                />
                                <CoreTypography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <FormattedMessage
                                        defaultMessage="{memberCount, plural, one {# member} other {# members}}"
                                        description="member count for team"
                                        values={{
                                            memberCount:
                                                socialGroup?.memberCount
                                        }}
                                    />
                                </CoreTypography>
                            </Stack>
                            <Link
                                href="javascript:void(0)"
                                onClick={() => setTeamDialogOpen(true)}
                            >
                                <CoreTypography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <FormattedMessage
                                        defaultMessage="View"
                                        description="button text for view"
                                    />
                                </CoreTypography>
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
            <ChallengeTeamMembers
                open={teamDialogOpen}
                onClose={() => setTeamDialogOpen(false)}
                socialGroupId={socialGroup?.id}
            />
        </React.Fragment>
    )
}

export default memo(ChallengeTeamName)
