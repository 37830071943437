import { Box, CircularProgress, Fade } from '@mui/material'
import {
    BurstNotification,
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    SocialGroup,
    useJoinSocialGroupsMutation
} from '../../../../graphql/generated/autogenerated'
import SocialGroupModal from '../../../../modals/SocialGroupModal/SocialGroupModal'

export type CustomJoinButton = {
    text: string
    loading: boolean
    disabled: boolean
    onClick: () => void
}

export type JoinGroupButtonProps = {
    socialGroups: SocialGroup[]
    displayName?: string
    disabled?: boolean
    hideSuccessMessage?: boolean
    text?: string
    openModalOnSuccess?: boolean
    button?: ReactElement<any, any>
    onJoinText?: ReactElement<any, any>
    beforeJoinGroup?: () => void
    displayNameRefetch: () => Promise<any>
    onJoinGroup?: () => Promise<any>
    onJoinGroupSuccess?: () => void
}

const messages = defineMessages({
    joinGroups: {
        defaultMessage: `Join {groups} {groups, plural, one {Group} other {Groups}}`,
        description:
            'text showing the number of groups the user will join on the join groups button'
    },
    groupJoined: {
        defaultMessage: `{groupCount, plural, one {Group Joined} other {Groups Joined}}`,
        description: 'success message on joining a group or groups'
    }
})

const JoinGroupButton: React.FC<JoinGroupButtonProps> = ({
    socialGroups,
    displayName,
    disabled,
    hideSuccessMessage,
    text,
    openModalOnSuccess,
    button,
    onJoinText,
    displayNameRefetch,
    beforeJoinGroup,
    onJoinGroup,
    onJoinGroupSuccess
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const [openSocialGroupModal, setOpenSocialGroupModal] =
        React.useState(false)
    const [showBurstNotification, setShowBurstNotification] = useState(false)
    const [joinGroupLoading, setJoinGroupLoading] = useState(false)
    const [joinSocialGroup] = useJoinSocialGroupsMutation({
        variables: {
            socialGroupIDs: socialGroups?.map((sg) => sg.id)
        }
    })

    const sendTrackingEvent = useCallback(() => {
        Avo.socialGroupJoined({
            activityType: 'social_group_joined',
            featureType: 'community'
        })
    }, [])

    const onJoinSocialGroup = useCallback(async () => {
        setJoinGroupLoading(true)
        beforeJoinGroup?.()
        joinSocialGroup().then(async () => {
            onJoinGroup?.().then(() => {
                setShowBurstNotification(true)
                setJoinGroupLoading(false)
            })
            sendTrackingEvent()
        })
    }, [joinSocialGroup, onJoinGroup, sendTrackingEvent, beforeJoinGroup])

    const onClickJoinGroup = useCallback(async () => {
        // If we already have a display name we don't need to open the SocialGroup modal since this in for adding the display name
        // Instead just fire the join command immediately
        if (!displayName || openModalOnSuccess) {
            setOpenSocialGroupModal(true)
        } else {
            onJoinSocialGroup()
        }
    }, [displayName, openModalOnSuccess, onJoinSocialGroup])

    const buttonText = useMemo(() => {
        if (text) {
            return text
        }

        return intl.formatMessage(messages.joinGroups, {
            groups: socialGroups?.length <= 0 ? 1 : socialGroups?.length
        })
    }, [text, socialGroups, intl])

    const renderJoinButton = useCallback(() => {
        if (button) {
            // Force clone in the onclick property so the component will open the tooltip on click of the custom button
            // We also must force assign the component as a button otherwise the tooltip placement seems to not take any effect (no idea why)
            return React.cloneElement(button, {
                loading: joinGroupLoading,
                disabled: joinGroupLoading || disabled,
                onClick: onClickJoinGroup,
                component: LoadingButton
            })
        } else {
            return (
                <LoadingButton
                    loading={joinGroupLoading}
                    disabled={joinGroupLoading || disabled}
                    fixWidth={true}
                    variant="contained"
                    data-testid="join-group-button"
                    sx={{
                        alignItems: 'center',
                        p: `${theme.spacing(2)} ${theme.spacing(5)}`,
                        whiteSpace: 'nowrap',
                        [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                        }
                    }}
                    onClick={onClickJoinGroup}
                    loader={
                        <Box textAlign="center">
                            <CircularProgress color={'accent'} />
                        </Box>
                    }
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        sx={{ textAlign: 'center' }}
                    >
                        {buttonText}
                    </CoreTypography>
                </LoadingButton>
            )
        }
    }, [
        joinGroupLoading,
        disabled,
        buttonText,
        button,
        onClickJoinGroup,
        theme
    ])

    const completeAction = useCallback(() => {
        setShowBurstNotification(false)
        onJoinGroupSuccess?.()
    }, [onJoinGroupSuccess])

    return (
        <Box data-testid="join-group-container">
            <SocialGroupModal
                open={openSocialGroupModal}
                handleOnClose={() => setOpenSocialGroupModal(false)}
                showPlural={socialGroups?.length > 1}
                displayName={displayName}
                displayNameRefetch={displayNameRefetch}
                externalJoinGroupAction={() => onJoinSocialGroup()}
            />
            {!hideSuccessMessage && (
                <Box
                    sx={{
                        width: 'fit-content',
                        display: 'flex',
                        maxHeight: '100%',
                        flexDirection: 'row',
                        justifyContent: 'flex-center',
                        [theme.breakpoints.down('md')]: {
                            mx: 'auto'
                        }
                    }}
                    aria-live="polite"
                    aria-atomic="true"
                    component="output"
                >
                    <BurstNotification
                        show={showBurstNotification}
                        {...(!onJoinText && {
                            text: intl.formatMessage(messages.groupJoined, {
                                groupCount: socialGroups?.length
                            })
                        })}
                        onCompleteAction={completeAction}
                    >
                        {onJoinText}
                    </BurstNotification>
                </Box>
            )}

            {(!showBurstNotification || hideSuccessMessage) && (
                <Fade in={socialGroups?.length > 0}>
                    <Box>{renderJoinButton()}</Box>
                </Fade>
            )}
        </Box>
    )
}

export default React.memo(JoinGroupButton)
