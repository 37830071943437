// React and related
import React from 'react'
import { FormattedMessage } from 'react-intl'

// External libraries
import { Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

// Components
import ManageDeviceButton from '../../../../../elements/ManageDeviceButton'

const ConnectDeviceBanner: React.FC = () => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <Card elevation={0}>
            <Stack
                p={2}
                gap={2}
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-end' : 'center'}
                justifyContent="space-between"
            >
                <CoreTypography customVariant="form">
                    <FormattedMessage
                        defaultMessage="Make it easier to see your progress by connecting a wearable or well-being app."
                        description="connect device title"
                    />
                </CoreTypography>
                <ManageDeviceButton />
            </Stack>
        </Card>
    )
}

export default ConnectDeviceBanner
