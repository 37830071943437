// External libraries
import React, { memo, useEffect, useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    ConfettiExplosion,
    CoreTypography,
    LeafDialog
} from '@thriveglobal/thrive-web-leafkit'

// Components
import ChallengePlantCard from '../../../../../../Shared/ChallengePlantCard'

// Types
import {
    UnifiedChallenge,
    UserPlantInfo
} from '../../../../../../../../../graphql/generated/autogenerated'

export type CompletedGoalDialogProps = {
    open: boolean
    onClose: () => void
    userPlant: UserPlantInfo
    challenge: UnifiedChallenge
}

const CompletedGoalDialog: React.FC<CompletedGoalDialogProps> = ({
    open,
    onClose,
    userPlant,
    challenge
}) => {
    const [showConfetti, setShowConfetti] = useState<boolean>(true)
    const [firedEvent, setFiredEvent] = useState<boolean>(false)
    const challengeV3Enabled = useIsFeatureEnabled('Challengev3', false, true, {
        challengeType: challenge?.challenge_type,
        theme: challenge?.theme
    }) as boolean

    useEffect(() => {
        if (open && userPlant && !firedEvent && !challengeV3Enabled) {
            setFiredEvent(true)
            Avo.plantAdded({
                challengeId: challenge?.id,
                challengeTheme: challenge?.theme,
                challengeType: challenge?.challenge_type,
                dayNumber: null,
                plantId: userPlant.id,
                plantType: userPlant.productUpc,
                teamId: null,
                teamName: null,
                teamType: null
            })
        }
    }, [open, challenge, userPlant, firedEvent, challengeV3Enabled])

    return (
        <LeafDialog
            open={open && !challengeV3Enabled}
            onClose={() => onClose()}
            PaperProps={{
                sx: {
                    position: 'relative',
                    overflow: 'hidden'
                }
            }}
        >
            <Stack
                px={4}
                pb={5}
                alignItems="center"
                justifyContent="center"
                data-testid="completed-goal-dialog"
            >
                <Stack gap={4} alignItems="center" justifyContent="center">
                    <Box minWidth="151px">
                        <ChallengePlantCard plant={userPlant} />
                    </Box>
                    <Stack gap={1} px={4}>
                        <CoreTypography variant="h3" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Look at you grow!"
                                description="Completed goal dialog title"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" textAlign="center">
                            <FormattedMessage
                                defaultMessage="You just completed a daily goal and added a new plant to your garden."
                                description="Completed goal dialog description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Box>
                        <Button variant="contained" onClick={() => onClose()}>
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Keep growing"
                                    description="Completed goal dialog button"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                </Stack>
            </Stack>
            {open && (
                <Stack
                    position="absolute"
                    top="25%"
                    left="50%"
                    zIndex={2}
                    sx={{ transform: 'translate(-50%, -50%)' }}
                >
                    <ConfettiExplosion
                        show={showConfetti}
                        onComplete={() => setShowConfetti(false)}
                    />
                </Stack>
            )}
        </LeafDialog>
    )
}

export default memo(CompletedGoalDialog)
