import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GroupChallengeType } from '../../../../../graphql/generated/autogenerated'
import ParticipationCard from '../../../elements/ParticipationCard'

export type IntroParticipationProps = {
    onSelect: (type: GroupChallengeType) => void
}

const IntroParticipation: React.FC<IntroParticipationProps> = ({
    onSelect
}) => {
    return (
        <Card>
            <Stack p={3} gap={3}>
                <CoreTypography variant="h3" mb={2}>
                    <FormattedMessage
                        defaultMessage="How do you want to participate?"
                        description="title for participation"
                    />
                </CoreTypography>
                <Stack direction="row" gap={4}>
                    <Stack flex={1}>
                        <ParticipationCard
                            type={GroupChallengeType.Personal}
                            onSelect={() =>
                                onSelect(GroupChallengeType.Personal)
                            }
                        />
                    </Stack>
                    <Stack flex={1}>
                        <ParticipationCard
                            type={GroupChallengeType.Group}
                            onSelect={() => onSelect(GroupChallengeType.Group)}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default IntroParticipation
