import { useAppSelector } from '@thriveglobal/thrive-web-core'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LeaderboardTable from '../../../../elements/LeaderboardTable'
import { useGetUsersPlantsLeaderboardQuery } from '../../../../../../graphql/generated/autogenerated'
import RequestorRank from '../../../../elements/RequestorRank'

type UserPlantLeaderboardProps = {
    challengeId: string
    userPlantsTotalPoints?: number
    userPlantsRankDisplayName?: string
    userPlantsRank?: number
}

const ROWS_PER_PAGE = 10

const messages = defineMessages({
    rank: {
        defaultMessage: 'Rank',
        description: 'Table header for rank'
    },
    user: {
        defaultMessage: 'User',
        description: 'Table header for team'
    },
    plants: {
        defaultMessage: 'Plants',
        description: 'Table header for plants'
    },
    soloPlants: {
        defaultMessage: 'Solo Plants',
        description: 'Leaderboard title for plants'
    }
})

const UserPlantLeaderboard: React.FC<UserPlantLeaderboardProps> = ({
    challengeId,
    userPlantsTotalPoints,
    userPlantsRankDisplayName,
    userPlantsRank
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const [offset, setOffset] = useState(0)

    const { data, loading, refetch } = useGetUsersPlantsLeaderboardQuery({
        variables: {
            challengeId,
            limit: ROWS_PER_PAGE,
            offset
        }
    })

    const { requesterRank, totalEntries, rankedList } =
        data?.retail?.getUsersPlantsLeaderboard || {}

    const maximumOffsetLoaded = useMemo(() => offset + ROWS_PER_PAGE, [offset])

    const hasMore = useMemo(
        () => (totalEntries || 0) > maximumOffsetLoaded,
        [totalEntries, maximumOffsetLoaded]
    )

    const anchorIndex = useMemo(
        () =>
            rankedList?.findIndex(
                (score) =>
                    score?.rank === requesterRank && score?.entityId === userId
            ),
        [requesterRank, rankedList, userId]
    )

    useEffect(() => {
        refetch({ offset })
    }, [offset, refetch])

    return (
        <LeaderboardTable
            loading={loading}
            loadingNext={loading}
            userRank={
                <RequestorRank
                    columnDataTitle={formatMessage(messages.user)}
                    columnDataPointsTitle={formatMessage(messages.plants)}
                    displayName={userPlantsRankDisplayName}
                    rank={userPlantsRank}
                    points={userPlantsTotalPoints}
                />
            }
            title={formatMessage(messages.soloPlants)}
            columnData={[
                {
                    title: formatMessage(messages.rank),
                    width: '20%',
                    valueName: 'rank',
                    showAnchor: (value, entityId) =>
                        value === requesterRank && entityId === userId
                },
                {
                    title: formatMessage(messages.user),
                    valueName: 'displayName'
                },
                {
                    title: formatMessage(messages.plants),
                    valueName: 'totalPlants',
                    transform: (value) => formatNumber(value)
                }
            ]}
            values={rankedList || []}
            requesterRank={requesterRank}
            totalEntries={totalEntries}
            anchorOnIndex={anchorIndex}
            rowsPerPage={ROWS_PER_PAGE}
            elevation={0}
            onNavigate={(value) => {
                setOffset(value ? offset + 1 : offset - 1)
            }}
            disableNext={!hasMore}
            disablePrev={offset === 0}
        />
    )
}

export default memo(UserPlantLeaderboard)
