import {
    useGetChallengeSocialGroupsQuery,
    type Exact,
    type GetChallengeSocialGroupsQuery,
    type GetChallengeSocialGroupsQueryVariables,
    type SocialGroup
} from '../../../graphql/generated/autogenerated'
import { ApolloError, FetchResult } from '@apollo/client'

export type SocialGroupsRefetch = () => Promise<
    FetchResult<
        GetChallengeSocialGroupsQuery,
        GetChallengeSocialGroupsQueryVariables,
        Record<string, any>
    >
>

export type SocialGroupsValues = {
    mainCommunitySocialGroup?: SocialGroup | null
    socialGroup?: SocialGroup | null
    displayName?: string | null
    loading: boolean
    error?: ApolloError
    socialGroupsRefetch: SocialGroupsRefetch
}

const formatSocialGroups = (
    challengeId: string,
    socialGroupsForUser?: SocialGroup[]
) => {
    if (!socialGroupsForUser)
        return {
            mainCommunitySocialGroup: null,
            socialGroup: null
        }

    const groups = socialGroupsForUser.filter(
        (group: SocialGroup) => group.challengeId === challengeId
    )

    return {
        mainCommunitySocialGroup: groups.find(
            (group: SocialGroup) => group.isMainCommunitySocialGroup
        ),
        socialGroup:
            groups.find(
                (group: SocialGroup) =>
                    !group.isMainCommunitySocialGroup && !group.isSoloChallenge
            ) ||
            groups.find(
                (group: SocialGroup) => !group.isMainCommunitySocialGroup
            )
    }
}

const useGetSocialGroups = (
    challengeId: string,
    options?: Omit<
        Parameters<typeof useGetChallengeSocialGroupsQuery>[0],
        'variables'
    >
): SocialGroupsValues => {
    const {
        data: socialGroupsData,
        loading: socialGroupsLoading,
        error: socialGroupsError,
        refetch: socialGroupsRefetch
    } = useGetChallengeSocialGroupsQuery({
        ...options,
        variables: {
            challengeId
        },
        fetchPolicy: 'cache-first'
    })
    const formattedGroups = formatSocialGroups(
        challengeId,
        socialGroupsData?.socialGroups?.socialGroupsForUser as SocialGroup[]
    )

    return {
        ...formattedGroups,
        displayName: socialGroupsData?.socialGroups?.displayName?.displayName,
        loading: socialGroupsLoading,
        error: socialGroupsError,
        socialGroupsRefetch
    }
}

export default useGetSocialGroups
