import React, { memo, useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ChallengeUserDailyGoal,
    MeasureUnit,
    WaterGoalDetail
} from '../../../../../../../../graphql/generated/autogenerated'
import Tracker from '../../../../Tracker'
import HydrationTrackerInfo from './HydrationTrackerInfo'

const messages = defineMessages({
    removeIntake: {
        defaultMessage: 'Remove water intake',
        description: 'button text for the remove water intake goal'
    },
    addIntake: {
        defaultMessage: 'Add water intake',
        description: 'button text for the add water intake goal'
    }
})

type HydrationTrackerProps = {
    loading?: boolean
    error?: boolean
    glasses: number
    intakeMeasurement: MeasureUnit
    addIntakeLoading?: boolean
    goal?: ChallengeUserDailyGoal
    disabled?: boolean
    addIntake: (increment: boolean) => void
    onComplete?: () => void
}

const HydrationTracker: React.FC<HydrationTrackerProps> = ({
    loading,
    error,
    glasses,
    addIntakeLoading,
    intakeMeasurement,
    goal,
    disabled,
    addIntake,
    onComplete
}) => {
    const { formatMessage } = useIntl()

    const calculateIsComplete = useMemo(
        () => (increment: boolean) =>
            increment &&
            !goal?.completed &&
            glasses + 1 >= (goal?.goalEntityDetail as WaterGoalDetail)?.water,
        [goal, glasses]
    )

    const handleOnIncrement = useCallback(
        (increment: boolean) => {
            addIntake(increment)

            if (calculateIsComplete(increment)) {
                onComplete?.()
            }
        },
        [calculateIsComplete, addIntake, onComplete]
    )

    if (error) throw error

    return (
        <Tracker
            loading={loading || addIntakeLoading}
            onIncrement={handleOnIncrement}
            removeLabel={formatMessage(messages.removeIntake)}
            addLabel={formatMessage(messages.addIntake)}
            removeDisabled={glasses <= 0 || disabled}
            addDisabled={disabled}
            completed={goal?.completed}
            message={
                <HydrationTrackerInfo
                    glasses={glasses}
                    intakeMeasurement={intakeMeasurement}
                    completed={Boolean(goal?.completed)}
                />
            }
        />
    )
}

export default memo(HydrationTracker)
