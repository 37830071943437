import {
    Box,
    Collapse,
    Fade,
    Stack,
    TextField,
    useMediaQuery
} from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { SocialGroupErrors } from '../../../../../errors/socialGroups'
import {
    type InvalidInput,
    type SocialGroup,
    useEditSocialGroupMutation
} from '../../../../../graphql/generated/autogenerated'
import useErrorHandling from '../../../../hooks/useErrorHandling/useErrorHandling'
import { useCompanyChallengeRefetchProviderContext } from '../../../../contexts/withCompanyChallengeRefetchProvider/useCompanyChallengeRefetchProviderContext'
import SuccessLoadingButton from '../../../elements/SuccessLoadingButton'

type EditTeamNameProps = {
    socialGroup: SocialGroup
    refetchSocialGroups: () => Promise<void>
}

const messages = defineMessages({
    yourTeamName: {
        defaultMessage: 'Your team name',
        description: 'placeholder text for the team name text field'
    },
    enterTeamName: {
        defaultMessage: 'Enter a team name',
        description: 'error text for the team name text field'
    },
    teamNameTooLong: {
        defaultMessage: 'Team name must be 28 characters or less',
        description: 'error text for the team name text field'
    },
    somethingWentWrong: {
        defaultMessage: 'Something went wrong',
        description: 'description for an unexpected error'
    }
})

const EditTeamName: React.FC<EditTeamNameProps> = ({
    socialGroup,
    refetchSocialGroups
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { userId } = useAppSelector((state) => state.user)
    const { formatMessage, formatNumber } = useIntl()
    const { generateErrorMessage, generateErrorNotification } =
        useErrorHandling()
    const { teamNameRefetch } = useCompanyChallengeRefetchProviderContext()

    const [error, setError] = useState(false)
    const [teamName, setTeamName] = useState(socialGroup?.name)
    const [invalidTeamNameText, setInvalidTeamNameText] = useState('')

    const [editSocialGroup] = useEditSocialGroupMutation({
        variables: {
            updatedSocialGroup: {
                socialGroupId: socialGroup?.id,
                name: String(teamName)
            }
        }
    })

    const formValid = useMemo(() => {
        if (teamName === '') {
            setInvalidTeamNameText(formatMessage(messages.enterTeamName))

            return false
        } else if (Number(teamName?.length) > 28) {
            setInvalidTeamNameText(formatMessage(messages.teamNameTooLong))

            return false
        } else {
            setInvalidTeamNameText('')

            return true
        }
    }, [teamName, formatMessage])

    const onSave = useCallback(async () => {
        setError(false)
        generateErrorNotification()

        try {
            const mutationResponse = await editSocialGroup()
            const invalidFields = (
                mutationResponse?.data?.socialGroups
                    ?.editSocialGroup as InvalidInput
            )?.invalidFields

            if (invalidFields?.length > 0) {
                setInvalidTeamNameText(
                    generateErrorMessage(
                        SocialGroupErrors,
                        invalidFields[0].message
                    )
                )
                return false
            }

            await refetchSocialGroups()
            return true
        } catch (error) {
            generateErrorNotification()
            setError(true)
        }
    }, [
        editSocialGroup,
        refetchSocialGroups,
        generateErrorMessage,
        generateErrorNotification
    ])

    const loadingButton = useMemo(
        () => (
            <Fade in={socialGroup?.createdBy === userId}>
                <Box>
                    <SuccessLoadingButton
                        promise={onSave}
                        disabled={!formValid || teamName === socialGroup?.name}
                        fixWidth={true}
                        variant="outlined"
                        data-testid="save-button"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Save"
                                description="Save Button Text"
                            />
                        </CoreTypography>
                    </SuccessLoadingButton>
                </Box>
            </Fade>
        ),
        [socialGroup, teamName, formValid, userId, onSave]
    )

    return (
        <Stack gap={1}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Your team"
                    description="Edit Team name title"
                />
            </CoreTypography>
            <Stack maxWidth="100%" gap={1}>
                <Stack direction="row" gap={2} width={isMobile ? '100%' : 443}>
                    <TextField
                        fullWidth
                        label={formatMessage(messages.yourTeamName)}
                        value={teamName}
                        error={!!invalidTeamNameText}
                        size="medium"
                        disabled={socialGroup?.createdBy !== userId}
                        onChange={(event) => setTeamName(event.target.value)}
                    />
                    {!isMobile && loadingButton}
                </Stack>
                <CoreTypography variant="caption">
                    <FormattedMessage
                        defaultMessage="{ count } characters maximum"
                        description="description for maximum characters"
                        values={{ count: formatNumber(28) }}
                    />
                </CoreTypography>
                <Collapse in={!!invalidTeamNameText}>
                    <CoreTypography variant="caption" color="error.main">
                        {invalidTeamNameText}
                    </CoreTypography>
                </Collapse>
                {error && (
                    <Collapse in={error}>
                        <CoreAlert severity="error">
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Something went wrong"
                                    description="description for an unexpected error"
                                />
                            </CoreTypography>
                        </CoreAlert>
                    </Collapse>
                )}
                {isMobile && socialGroup?.createdBy === userId && loadingButton}
            </Stack>
        </Stack>
    )
}

export default memo(EditTeamName)
