import { createContext } from 'react'
import { SocialGroup } from '../../graphql/generated/autogenerated'
import { GQLNull } from '../../shared/utils/Nulls'

export interface CompanyChallengeSocialGroupProviderContextValue {
    loading: boolean
    soloGroup?: SocialGroup
    mainGroup?: SocialGroup
    socialGroup?: SocialGroup
    companyGroups: SocialGroup[]
    displayName: string | GQLNull
    error: boolean
    refetch: () => Promise<any>
}

export const defaultContextValue: CompanyChallengeSocialGroupProviderContextValue =
    {
        loading: false,
        soloGroup: undefined,
        mainGroup: undefined,
        socialGroup: undefined,
        companyGroups: [],
        displayName: '',
        error: false,
        refetch: () => Promise.resolve()
    }

export default createContext(defaultContextValue)
