import { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import AverageSleepReflection from '../../Shared/AverageSleepReflection'
import SleepReflectionsDialog from './SleepReflectionsDialog'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'
import type {
    SleepHabits,
    UnifiedChallenge,
    ChallengeStats,
    SleepHabitsResult
} from '../../../../../graphql/generated/autogenerated'

type SleepReflectionsProps = {
    challenge: UnifiedChallenge
    data: {
        getChallengeStats: ChallengeStats
        getSleepHabits: SleepHabitsResult
    }
}

const SleepReflections = ({ challenge, data }: SleepReflectionsProps) => {
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Your sleep reflections"
                            description="sleep reflections title"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Your sleep reflections"
                            description="sleep reflections description"
                        />
                    }
                />
                <RecapWidgetContent>
                    <AverageSleepReflection
                        data={data?.getChallengeStats?.feelingQuestionStats}
                        hideTitle={true}
                        elevation={6}
                    />
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View details"
                            description="view details button"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <SleepReflectionsDialog
                challenge={challenge}
                data={{
                    feelingQuestionStats:
                        data?.getChallengeStats?.feelingQuestionStats,
                    sleepHabits: (
                        data?.getSleepHabits as {
                            sleepHabits: SleepHabits
                        }
                    )?.sleepHabits
                }}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
        </>
    )
}

export default memo(SleepReflections)
