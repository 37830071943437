import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactNullValue } from '../../../../shared/utils/Nulls'

const messages = defineMessages({
    yourRank: {
        defaultMessage: 'Your Rank',
        description:
            'description of the users current rank within the leaderboard'
    },
    currentUserRankInfo: {
        defaultMessage:
            'On the {columnDataTitle} leaderboard you are rank {rank} with {points} {columnDataPointsTitle}',
        description:
            'description of the users current rank within the leaderboard'
    }
})

export type RequestorRankProps = {
    displayName?: string
    rank?: number
    points?: number
    columnDataTitle: string
    columnDataPointsTitle: string
}

const RequestorRank: React.FC<RequestorRankProps> = ({
    displayName,
    rank,
    points,
    columnDataTitle,
    columnDataPointsTitle
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const theme = useTheme()

    // TODO: remove feature flag
    const showChallengeUserRankTable = useIsFeatureEnabled(
        'challengeUserRankTable',
        false,
        true
    )

    const showLeaderBoard =
        Boolean(displayName && rank && rank > 0 && points && points > 0) &&
        showChallengeUserRankTable

    if (!showLeaderBoard) return ReactNullValue

    return (
        <Card variant="outlined">
            <Table
                aria-label={formatMessage(messages.currentUserRankInfo, {
                    columnDataTitle,
                    rank,
                    points,
                    columnDataPointsTitle
                })}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            width={'20%'}
                            sx={{ borderBottom: 'none', pb: 0 }}
                        >
                            <CoreTypography
                                variant="h5"
                                color="primary"
                                component="p"
                            >
                                {formatMessage(messages.yourRank)}
                            </CoreTypography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', pb: 0 }}>
                            <CoreTypography
                                variant="h5"
                                color="primary"
                                component="p"
                            >
                                {columnDataTitle}
                            </CoreTypography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', pb: 0 }}>
                            <CoreTypography
                                variant="h5"
                                color="primary"
                                component="p"
                                align="right"
                            >
                                {columnDataPointsTitle}
                            </CoreTypography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            border: 0
                        }}
                    >
                        <TableCell component="th" scope="row" width={'20%'}>
                            <CoreTypography variant="body1">
                                {formatNumber(rank ?? 0)}
                            </CoreTypography>
                        </TableCell>
                        <TableCell align="left">
                            <CoreTypography variant="body1">
                                {displayName}
                            </CoreTypography>
                        </TableCell>
                        <TableCell align="right">
                            <CoreTypography variant="body1">
                                {formatNumber(points ?? 0)}
                            </CoreTypography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    )
}

export default memo(RequestorRank)
