import { ComponentType, useCallback, useMemo } from 'react'
import { PlantIcons } from '../../enums/plantIcons'
import CommunityCherryBlossomPlant from '../../shared/components/icons/plants/communityCherryBlossomPlant'
import CommunityPlant1 from '../../shared/components/icons/plants/communityPlant1'
import CommunityPlant10 from '../../shared/components/icons/plants/communityPlant10'
import CommunityPlant11 from '../../shared/components/icons/plants/communityPlant11'
import CommunityPlant12 from '../../shared/components/icons/plants/communityPlant12'
import CommunityPlant13 from '../../shared/components/icons/plants/communityPlant13'
import CommunityPlant14 from '../../shared/components/icons/plants/communityPlant14'
import CommunityPlant15 from '../../shared/components/icons/plants/communityPlant15'
import CommunityPlant16 from '../../shared/components/icons/plants/communityPlant16'
import CommunityPlant17 from '../../shared/components/icons/plants/communityPlant17'
import CommunityPlant18 from '../../shared/components/icons/plants/communityPlant18'
import CommunityPlant2 from '../../shared/components/icons/plants/communityPlant2'
import CommunityPlant3 from '../../shared/components/icons/plants/communityPlant3'
import CommunityPlant4 from '../../shared/components/icons/plants/communityPlant4'
import CommunityPlant5 from '../../shared/components/icons/plants/communityPlant5'
import CommunityPlant6 from '../../shared/components/icons/plants/communityPlant6'
import CommunityPlant7 from '../../shared/components/icons/plants/communityPlant7'
import CommunityPlant8 from '../../shared/components/icons/plants/communityPlant8'
import CommunityPlant9 from '../../shared/components/icons/plants/communityPlant9'
import Dirt from '../../shared/components/icons/plants/dirt'
import DirtSimplified from '../../shared/components/icons/plants/dirtSimplified'
import { PlantInfo, UserPlantInfo } from '../../graphql/generated/autogenerated'

export type usePlantIconsValue = {
    PlantIcon?: ComponentType<any>
    base: JSX.Element
}

export const usePlantIcons = (useSimple?: boolean) => {
    const plantIcons = useMemo<{ [key: string]: ComponentType<any> }>(
        () => ({
            [PlantIcons.CommunityPlant1]: CommunityPlant1,
            [PlantIcons.CommunityPlant2]: CommunityPlant2,
            [PlantIcons.CommunityPlant3]: CommunityPlant3,
            [PlantIcons.CommunityPlant4]: CommunityPlant4,
            [PlantIcons.CommunityPlant5]: CommunityPlant5,
            [PlantIcons.CommunityPlant6]: CommunityPlant6,
            [PlantIcons.CommunityPlant7]: CommunityPlant7,
            [PlantIcons.CommunityPlant8]: CommunityPlant8,
            [PlantIcons.CommunityPlant9]: CommunityPlant9,
            [PlantIcons.CommunityPlant10]: CommunityPlant10,
            [PlantIcons.CommunityPlant11]: CommunityPlant11,
            [PlantIcons.CommunityPlant12]: CommunityPlant12,
            [PlantIcons.CommunityPlant13]: CommunityPlant13,
            [PlantIcons.CommunityPlant14]: CommunityPlant14,
            [PlantIcons.CommunityPlant15]: CommunityPlant15,
            [PlantIcons.CommunityPlant16]: CommunityPlant16,
            [PlantIcons.CommunityPlant17]: CommunityPlant17,
            [PlantIcons.CommunityPlant18]: CommunityPlant18,
            [PlantIcons.CommunityCherryBlossomPlant]:
                CommunityCherryBlossomPlant
        }),
        []
    )

    const getPlantIcon = useCallback<
        (plant?: UserPlantInfo | PlantInfo) => usePlantIconsValue
    >(
        (plant) => {
            return {
                PlantIcon: plant ? plantIcons[plant.productUpc] : undefined,
                base: useSimple ? <DirtSimplified /> : <Dirt />
            }
        },
        [plantIcons, useSimple]
    )

    return { getPlantIcon }
}

export default usePlantIcons
