import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import cardCornerPlant from '../../../../../assets/cardPlants/cardCornerPlant.png'
import centerLeft from '../../../../../assets/cardPlants/centerLeft.png'
import centerRight from '../../../../../assets/cardPlants/centerRight.png'
import left from '../../../../../assets/cardPlants/left.png'
import right from '../../../../../assets/cardPlants/right.png'

export interface PlantsEarnedCardProps {
    title: string
    stat: number
    fullPlant?: boolean
    size?: 'small' | 'large'
}

const PlantsEarnedCard: React.FC<PlantsEarnedCardProps> = ({
    title,
    stat = 0,
    fullPlant,
    size
}) => {
    const { formatNumber } = useIntl()

    const backgroundPlant = useMemo(
        () =>
            fullPlant
                ? {
                      backgroundImage: `url(${centerLeft}), url(${left}), url(${centerRight}), url(${right})`,
                      backgroundPosition: `15% 100%, 0% 100%, 85% 100%, 100% 100%`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto 40%, auto 80%, auto 25%, auto 80%'
                  }
                : {
                      backgroundImage: `url(${cardCornerPlant})`,
                      backgroundPosition: `100% 100%`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain'
                  },
        [fullPlant]
    )

    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Stack
                p={2}
                minHeight={size === 'large' ? 160 : 167}
                height="100%"
                width="100%"
                sx={{ ...backgroundPlant }}
            >
                <Stack gap={2}>
                    <CoreTypography variant="overline" color="text.disabled">
                        {title}
                    </CoreTypography>
                    <CoreTypography
                        variant="h3"
                        fontSize={60}
                        textAlign={fullPlant ? 'center' : 'left'}
                        color="text.secondary"
                    >
                        {formatNumber(stat)}
                    </CoreTypography>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(PlantsEarnedCard)
