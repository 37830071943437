import { useMemo } from 'react'
import {
    ChallengeTemplateGoal,
    useChallengeTemplateGoalsQuery
} from '../../../graphql/generated/autogenerated'

export interface ChallengeIntroData {
    goals: ChallengeTemplateGoal[]
    loading: boolean
    error?: Error
}

export const useGetPreviewGoals = (id: string): ChallengeIntroData => {
    const { data, loading, error, refetch } = useChallengeTemplateGoalsQuery({
        variables: { challengeTemplateId: id },
        skip: !id
    })

    const goals = useMemo(
        () =>
            data?.unifiedChallenges
                ?.challengeTemplateGoals as ChallengeTemplateGoal[],
        [data]
    )

    const state = useMemo<ChallengeIntroData>(
        () => ({
            loading,
            goals,
            error,
            refetch
        }),
        [loading, goals, error, refetch]
    )

    return state
}

export default useGetPreviewGoals
