import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../routes'

export const useNavigateBack = () => {
    const history = useHistory()
    const navigation = useCrossAppNavigation()

    return useCallback(() => {
        const previousLocation = (history?.location?.state as any)
            ?.previousLocation as string

        if (!previousLocation) {
            navigation('/today')
        } else if (previousLocation.includes(ROUTES.CHALLENGE)) {
            history.push(previousLocation)
        } else {
            navigation(previousLocation)
        }
    }, [history, navigation])
}

export default useNavigateBack
