import { Box, Stack } from '@mui/material'
import {
    LeafBackgroundIcon,
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import LinkArrowRight from '../../../../../components/elements/LinkArrowRight/LinkArrowRight'
import { ROUTES } from '../../../../../routes'

const messages = defineMessages({
    joinAGroup: {
        defaultMessage: 'Join a group',
        description: 'Join a group title'
    },
    socialDescription: {
        defaultMessage:
            'Challenges are always more fun with a group. You can invite others in the company to participate in this Challenge.',
        description:
            'description to promote inviting other users to a social group'
    },
    findGroup: {
        defaultMessage: 'Find a group',
        description: 'find a group button text'
    }
})

const JoinAGroup: React.FC = () => {
    const theme = useTheme()
    const intl = useIntl()
    const location = useLocation()

    return (
        <Box
            data-testid="join-a-group"
            sx={{
                p: theme.spacing(3),
                borderRadius: theme.spacing(1),
                boxShadow: theme.shadows[9],
                minWidth: 'fit-content'
            }}
        >
            <Stack direction="row" spacing={3}>
                <LeafBackgroundIcon
                    color={'accent'}
                    icon={<LeafIcon icon={'user-group'} />}
                />
                <Stack
                    order={{ xs: 1, sm: 2 }}
                    sx={{
                        display: 'flex'
                    }}
                >
                    <CoreTypography
                        variant="h3"
                        sx={{
                            color: theme.palette.text.primary,
                            mb: theme.spacing(1.5)
                        }}
                    >
                        {intl.formatMessage(messages.joinAGroup)}
                    </CoreTypography>
                    <CoreTypography
                        variant="body2"
                        sx={{
                            color: theme.palette.text.primary,
                            mb: theme.spacing(1.5)
                        }}
                    >
                        {intl.formatMessage(messages.socialDescription)}
                    </CoreTypography>

                    <LinkArrowRight
                        linkText={intl.formatMessage(messages.findGroup)}
                        pathName={ROUTES.SOCIAL_GROUPS}
                        state={{
                            previousLocation: location.pathname
                        }}
                    />
                </Stack>
            </Stack>
        </Box>
    )
}

export default React.memo(JoinAGroup)
