import {
    Button,
    CardMedia,
    Stack,
    useMediaQuery,
    CardHeader,
    CardContent,
    CardActions
} from '@mui/material'
import {
    CoreTypography,
    useTheme,
    withTruncatedText,
    LeafFixedMediaCard
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useRef } from 'react'
import useCheckLineCount from '../../../../../hooks/useCheckLineCount/useCheckLineCount'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import ChallengeDuration from '../../../../elements/ChallengeDuration/ChallengeDuration'
import ChallengeTypeIcon from '../../../../elements/ChallengeTypeIcon/ChallengeTypeIcon'
import { defineMessages, useIntl } from 'react-intl'

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const messages = defineMessages({
    learnMore: {
        defaultMessage: 'Learn more',
        description: 'description for learn more button'
    }
})

export interface ChallengeFeaturedCardProps {
    id: string
    image?: string
    category?: string
    name: string
    description?: string
    duration: number
    size?: 'lg' | 'md' | 'sm' | 'xs'
    challengeType: ChallengeType
}

const ChallengeFeaturedCard: React.FC<ChallengeFeaturedCardProps> = ({
    id,
    image,
    name,
    description,
    duration,
    challengeType
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        challengeType: challengeType
    })
    const { formatMessage } = useIntl()

    const textRef = useRef(null)
    const singleLineTextHeight = 24
    const { checkLineCount, nameMultiLine } = useCheckLineCount(
        textRef,
        singleLineTextHeight
    )

    useEffect(() => checkLineCount, [checkLineCount])

    return (
        <Stack position="relative">
            <LeafFixedMediaCard
                alignMedia={isMobile ? 'top' : 'left'}
                mediaWidth="md"
                media={
                    <CardMedia
                        component="img"
                        image={image ? image : imagePlaceholder}
                        alt=""
                    />
                }
            >
                <CardHeader
                    title={
                        <Stack
                            gap={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <ChallengeDuration duration={duration} />
                            <Stack direction="row">
                                <ChallengeTypeIcon
                                    color="secondary"
                                    challengeType={challengeType}
                                />
                            </Stack>
                        </Stack>
                    }
                />
                <CardContent>
                    <CoreTypography variant="h3">{name}</CoreTypography>
                    <TruncatedCoreTypography
                        variant="body2"
                        textLines={nameMultiLine ? 2 : 3}
                    >
                        {description}
                    </TruncatedCoreTypography>
                </CardContent>

                <CardActions>
                    <Button
                        onClick={navigate}
                        color="primary"
                        size="large"
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonLarge">
                            {formatMessage(messages.learnMore)}
                        </CoreTypography>
                    </Button>
                </CardActions>
            </LeafFixedMediaCard>
        </Stack>
    )
}

export default memo(ChallengeFeaturedCard)
