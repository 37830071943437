import { memo, useMemo } from 'react'
import type {
    CommunityGardenInfo,
    PlantInfo,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import TotalPlants from './TotalPlants'

type TotalAndPlants = {
    totalPlants: number
    plants: PlantInfo[]
}

type TeamTotalPlantsProps = {
    groupedTeamPlants?: CommunityGardenInfo[]
    challenge: UnifiedChallenge
}

const TeamTotalPlants: React.FC<TeamTotalPlantsProps> = ({
    groupedTeamPlants,
    challenge
}) => {
    const { totalPlants = 0, plants = [] } = useMemo(
        () =>
            groupedTeamPlants?.reduce(
                (accumulator, groupedPlant) => {
                    accumulator.totalPlants += groupedPlant.plantsNumber
                    const plants = Array.from(
                        Array(groupedPlant.plantsNumber)
                    ).map(
                        () =>
                            groupedPlant.plantsTemplate[
                                Math.floor(
                                    Math.random() *
                                        groupedPlant.plantsTemplate.length
                                )
                            ]
                    )
                    accumulator.plants = accumulator.plants.concat(plants)

                    return accumulator
                },
                { totalPlants: 0, plants: [] } as TotalAndPlants
            ) || { totalPlants: 0, plants: [] },
        [groupedTeamPlants]
    )

    return (
        <TotalPlants
            totalPlants={totalPlants}
            plants={plants}
            groupedPlants={groupedTeamPlants as any}
            challengeStarted={true}
            challenge={challenge}
        />
    )
}

export default memo(TeamTotalPlants)
