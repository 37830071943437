import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { SignUpTypes } from '../../../../../enums'
import { useHydrationProviderContext } from '../../../../../hooks/hydrationProviders/withHydrationProvider'
import useGlassesMeasurementConversion from '../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import ConfirmField from '../ConfirmField/ConfirmField'

const messages = defineMessages({
    yourDisplayName: {
        defaultMessage: 'Your display name',
        description: 'description text for the display name input field'
    },
    signUpType: {
        defaultMessage: 'Participation Method',
        description: 'description text for the sign up type input field'
    },
    yourTeamName: {
        defaultMessage: 'Your team name',
        description: 'description text for the team name input field'
    },
    individualTitle: {
        defaultMessage: 'Individual',
        description: 'title for the participation method section'
    },
    teamTitle: {
        defaultMessage: 'Team',
        description: 'title for the participation method section'
    },
    dailyWaterGoal: {
        defaultMessage: 'Daily water goal',
        description: 'title for the daily water goal section'
    },
    activityGoal: {
        defaultMessage: 'Activity goal',
        description: 'title for the daily activity goal section'
    },
    dailyWaterGoalDescription: {
        defaultMessage: '{glasses} glasses / {measurement}',
        description: 'description for the daily water goal section'
    }
})

const ConfirmDetails: React.FC = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { teamName, socialGroup, activityGoal } =
        useCompanyChallengeSignUpProviderContext()
    const { signUpType, displayName } = useChallengeSignUpModalContext()
    const { challenge, hydrationEnabled, deviceEnabled } =
        useCompanyChallengeProviderContext()
    const { goalCount, intakeMeasurement } = useHydrationProviderContext()

    const formattedMeasurement = useGlassesMeasurementConversion(
        goalCount,
        intakeMeasurement
    )

    const isTeamSignUp = useMemo(
        () => signUpType !== SignUpTypes.solo && (!!teamName || !!socialGroup),
        [signUpType, teamName, socialGroup]
    )

    const signUpTypeTitle = useMemo(
        () =>
            signUpType === SignUpTypes.solo
                ? formatMessage(messages.individualTitle)
                : formatMessage(messages.teamTitle),
        [signUpType, formatMessage]
    )

    return (
        <Stack
            gap={3}
            maxWidth="672px"
            width="100%"
            sx={{ overflow: 'hidden' }}
        >
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Confirm your details"
                    description="Title for the confirm your details card inside the company sign up flow"
                />
            </CoreTypography>
            <Stack gap={2} width={isMobile ? '100%' : 343}>
                <Stack data-testid="display-name-text-field">
                    <ConfirmField
                        title={formatMessage(messages.yourDisplayName)}
                        value={displayName}
                    />
                </Stack>
                <Stack data-testid="sign-up-type-text-field">
                    <ConfirmField
                        title={formatMessage(messages.signUpType)}
                        value={signUpTypeTitle}
                    />
                </Stack>
                {isTeamSignUp && (teamName || socialGroup?.name) && (
                    <Stack data-testid="team-name-text-field">
                        <ConfirmField
                            title={formatMessage(messages.yourTeamName)}
                            value={String(
                                teamName ? teamName : socialGroup?.name
                            )}
                        />
                    </Stack>
                )}
                {hydrationEnabled && (
                    <Stack data-testid="hydration-text-field">
                        <ConfirmField
                            title={formatMessage(messages.dailyWaterGoal)}
                            value={formatMessage(
                                messages.dailyWaterGoalDescription,
                                {
                                    glasses: goalCount,
                                    measurement: formattedMeasurement
                                }
                            )}
                        />
                    </Stack>
                )}
                {deviceEnabled && challenge?.allowCustomSteps && (
                    <Stack data-testid="activity-text-field">
                        <ConfirmField
                            title={formatMessage(messages.activityGoal)}
                            value={String(activityGoal)}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default memo(ConfirmDetails)
