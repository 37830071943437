import { createContext } from 'react'
import { SignUpTypes } from '../../enums'
import {
    UnifiedChallenge,
    SocialGroup
} from '../../graphql/generated/autogenerated'

export interface ChallengeSignUpModalValue {
    signUpType: SignUpTypes
    displayName?: string
    isChangeParticipation: boolean
    excludeSocialGroups: string[]
    inviteSocialGroup?: SocialGroup
    isInvite: boolean
    setDisplayName: (displayName?: string) => void
    setChallenge: (challenge?: UnifiedChallenge) => void
    setInviteSocialGroup: (socialGroup?: SocialGroup) => void
    onOpen: (isInvite?: boolean) => void
    setSignUpType: (signUpType: SignUpTypes) => void
    setOnComplete: ({ onComplete }: { onComplete: () => Promise<any> }) => void
    setIsChangeParticipation: (isChangeParticipation: boolean) => void
    setExcludeSocialGroups: (excludeSocialGroups: string[]) => void
}

export const defaultContextValue: ChallengeSignUpModalValue = {
    signUpType: SignUpTypes.solo,
    displayName: undefined,
    isChangeParticipation: false,
    excludeSocialGroups: [],
    inviteSocialGroup: undefined,
    isInvite: false,
    setDisplayName: () => ({}),
    setChallenge: () => ({}),
    setInviteSocialGroup: () => ({}),
    onOpen: () => false,
    setSignUpType: () => false,
    setOnComplete: () => ({}),
    setIsChangeParticipation: () => ({}),
    setExcludeSocialGroups: () => ({})
}

export default createContext(defaultContextValue)
