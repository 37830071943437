import React, { useMemo } from 'react'
import { alpha, IconButton, IconButtonProps, useTheme } from '@mui/material'
import { PlayIcon } from '../../icons/CFPlayer'

export type InitialPlayButtonProps = IconButtonProps

function InitialPlayButton(props: IconButtonProps) {
    const { sx, ...restProps } = props

    const theme = useTheme()

    const buttonSx = useMemo(() => {
        return {
            color: theme.palette.primary.contrastText,
            borderRadius: '50%',
            backgroundColor: alpha('#000000', 0.5),
            position: 'absolute',
            ...(sx ?? {})
        } as IconButtonProps['sx']
    }, [sx, theme.palette.primary.contrastText])

    return (
        <IconButton
            sx={buttonSx}
            data-testid="InitialPlayButton"
            disableRipple
            tabIndex={-1}
            {...restProps}
        >
            <PlayIcon
                sx={{
                    height: 'min(25vh, 26.4rem)',
                    width: 'min(25vh, 26.4rem)'
                }}
            />
        </IconButton>
    )
}

export default React.memo(InitialPlayButton)
