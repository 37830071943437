import { Box, Fade, Stack } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import useGetSocialMembers from '../../../../hooks/useGetSocialMembers/useGetSocialMembers'
import SocialMemberSkeleton from '../../../../../shared/modules/social/components/SocialMember/Skeleton/SocialMemberSkeleton'
import SocialMember from '../../../../../shared/modules/social/components/SocialMember/SocialMember'

export interface SocialMemberListProps extends JSX.IntrinsicAttributes {
    socialGroupId: string
    ownerId?: string
    onEditUser?: () => void
}

const SocialMemberList: React.FC<SocialMemberListProps> = ({
    socialGroupId,
    ownerId,
    onEditUser
}) => {
    const {
        socialMembers,
        loading: socialMemberLoading,
        loadingNext,
        hasMore,
        fetchMore
    } = useGetSocialMembers({
        socialGroupId: socialGroupId
    })

    return (
        <Stack gap={3}>
            <Fade in>
                <Stack gap={1.5}>
                    {socialMemberLoading && !loadingNext ? (
                        <Stack gap={1.5}>
                            {[...Array(10)].map((_, index: number) => (
                                <SocialMemberSkeleton key={index} />
                            ))}
                        </Stack>
                    ) : (
                        socialMembers.map((socialMember, index: number) => (
                            <SocialMember
                                key={index}
                                ownerId={ownerId}
                                socialMember={socialMember}
                                onEditUser={onEditUser}
                            />
                        ))
                    )}
                </Stack>
            </Fade>
            {hasMore && !socialMemberLoading && (
                <Fade in={hasMore} mountOnEnter unmountOnExit>
                    <Box display="flex" justifyContent="center">
                        <LoadingButton
                            loading={loadingNext}
                            disabled={loadingNext}
                            fixWidth={true}
                            variant="text"
                            size="medium"
                            data-testid="load-more-members-button"
                            onClick={fetchMore}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Load more"
                                    description="Button text that will load more posts on a community activity feed"
                                />
                            </CoreTypography>
                        </LoadingButton>
                    </Box>
                </Fade>
            )}
        </Stack>
    )
}

export default React.memo(SocialMemberList)
