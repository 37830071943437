import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import debounce from 'lodash/debounce'
import { FC, useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useValidateDisplayNameMutation } from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    displayName: {
        defaultMessage: 'Display Name',
        description: 'label for display name input'
    },
    required: {
        defaultMessage: 'Display name is required',
        description: 'error message when display name is empty'
    },
    maxLength: {
        defaultMessage: 'Maximum {max} characters allowed',
        description: 'error message when display name is too long'
    },
    validationFailed: {
        defaultMessage: 'Validation failed',
        description: 'error message when validation fails'
    },
    characterCount: {
        defaultMessage: '{max} characters max.',
        description: 'character count display'
    }
})

interface Props {
    value: string | undefined
    onChange: (value: string) => void
    onValidate: (isValid: boolean) => void
}

const MAX_LENGTH = 28

const DisplayNameWithValidation: FC<Props> = ({
    value,
    onChange,
    onValidate
}) => {
    const { formatMessage } = useIntl()
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [validateDisplayName] = useValidateDisplayNameMutation()

    const validate = useCallback(
        async (name: string) => {
            if (!name || name.length > MAX_LENGTH) {
                setError(
                    name
                        ? formatMessage(messages.maxLength, { max: MAX_LENGTH })
                        : formatMessage(messages.required)
                )
                onValidate(false)
                return
            }

            setIsLoading(true)
            const { data }: any = await validateDisplayName({
                variables: { displayName: name }
            })
                .catch(() => {
                    setError(formatMessage(messages.validationFailed))
                    onValidate(false)
                })
                .finally(() => setIsLoading(false))

            const invalidFields =
                data?.socialGroups?.validateDisplayName?.invalidFields || []
            setError(invalidFields[0]?.message || '')
            onValidate(!invalidFields.length)
        },
        [validateDisplayName, onValidate, formatMessage]
    )

    useEffect(() => {
        if (value) {
            validate(value)
        }
    }, [])

    return (
        <TextField
            fullWidth
            label={formatMessage(messages.displayName)}
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
                debounce(validate, 300)(e.target.value)
            }}
            error={!!error}
            helperText={
                error ||
                formatMessage(messages.characterCount, {
                    max: MAX_LENGTH
                })
            }
            InputProps={{
                endAdornment: isLoading && (
                    <InputAdornment position="end">
                        <CircularProgress size={20} />
                    </InputAdornment>
                )
            }}
        />
    )
}

export default DisplayNameWithValidation
