import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

const CommunityTodayPlantsEarnedFallback = () => {
    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Stack p={2} minHeight={160} height="100%" width="100%">
                <Stack gap={2}>
                    <CoreTypography>
                        <FormattedMessage
                            defaultMessage="We can't load the plants earned today. Try again later."
                            description="Error when we can't load the plants earned"
                        />
                    </CoreTypography>
                </Stack>
            </Stack>
        </Card>
    )
}

export default CommunityTodayPlantsEarnedFallback
