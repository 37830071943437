import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    CommunitySocialGroupActivityFeedQuery,
    CommunitySocialGroupActivityFeedQueryVariables,
    Post,
    useCommunitySocialGroupActivityFeedQuery
} from '../../../graphql/generated/autogenerated'
import { stripBOM } from '../../../shared/utils/bom'
import usePage from '../../../shared/hooks/usePage/usePage'
import {
    PaginationOptions,
    usePagination
} from '../../../shared/hooks/usePagination/usePagination'

export interface SocialActivityProviderValue {
    loading: boolean
    error: boolean
    loadingNext: boolean
    hasMore: boolean
    activityFeed: Post[]
    displayName: string
    setSocialGroupId?: (socialGroupId: string) => void
    loadMore: (next: boolean) => Promise<unknown> | void
    refetch: () => Promise<unknown>
    fetchMore: (fetchMoreOptions: unknown) => Promise<unknown>
}

export function useGetSocialGroupActivity(
    params?: PaginationOptions<
        Post,
        CommunitySocialGroupActivityFeedQuery,
        CommunitySocialGroupActivityFeedQueryVariables
    >
): SocialActivityProviderValue {
    const [displayName, setDisplayName] = useState('')
    const { challengeId } = useParams<{ challengeId: string }>()

    const {
        data,
        queryData,
        loading,
        error,
        loadingNext,
        hasMore,
        handleLoadMore,
        refetch
    } = usePagination<
        Post,
        CommunitySocialGroupActivityFeedQuery,
        CommunitySocialGroupActivityFeedQueryVariables
    >({
        ...params,
        defaultOffset: 30,
        query: useCommunitySocialGroupActivityFeedQuery,
        queryProps: { challengeId: stripBOM(challengeId) },
        dataTransform: (data) =>
            data?.socialGroups?.communitySocialGroupActivityFeed as Post[]
    })

    const { pageValues, onNextPage } = usePage({
        rowsPerPage: 5,
        accumulate: true,
        onNavigate: handleLoadMore,
        values: data
    })

    useEffect(() => {
        if (queryData?.socialGroups?.displayName?.displayName) {
            setDisplayName(queryData.socialGroups.displayName.displayName)
        }
    }, [queryData])

    return useMemo<any>(
        () => ({
            displayName,
            activityFeed: pageValues as Post[],
            error: Boolean(error),
            loading,
            loadingNext,
            hasMore,
            refetch,
            loadMore: onNextPage,
            fetchMore: handleLoadMore as any
        }),
        [
            error,
            loading,
            hasMore,
            loadingNext,
            displayName,
            pageValues,
            refetch,
            onNextPage,
            handleLoadMore
        ]
    )
}
