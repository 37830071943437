import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import {
    ChallengeUserDailyGoal,
    MeasureUnit,
    WaterGoalDetail,
    useAddWaterIntakeMutation,
    useCompleteCommunityChallengeDailyGoalMutation,
    useSetWaterCustomizationMutation
} from '../../../graphql/generated/autogenerated'
import { useDebounce } from '../../../hooks/useDebounce/useDebounce'
import { useGoalChallengeDataProviderContext } from '../../../hooks/goalChallenges/useGoalChallengeData/useGoalChallengeDataProviderContext'
import { useCompanyChallengeProviderContext } from '../../withCompanyChallengeProvider/useCompanyChallengeProviderContext'
import { useCompanyChallengeRefetchProviderContext } from '../../withCompanyChallengeRefetchProvider/useCompanyChallengeRefetchProviderContext'
import HydrationContext, { HydrationValue } from './hydrationContext'

export default function withHydrationProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [glasses, setGlasses] = useState(8)
        const [waterIntake, setWaterIntake] = useState(0)
        const [goal, setGoal] = useState<ChallengeUserDailyGoal>()
        const [goalCount, setGoalCount] = useState<number>(8)
        const [refetchDebounceRequested, setRefetchDebounceRequested] =
            useState(false)
        const [intakeMeasurement, setIntakeMeasurement] = useState(
            MeasureUnit.Oz
        )
        const [addIntakeLoading, setAddIntakeLoading] = useState(false)
        const { challenge } = useCompanyChallengeProviderContext()
        const { dailyGoalRefetch } = useCompanyChallengeRefetchProviderContext()
        const { actionOnTimeout: dailyGoalRefetchDebounced } = useDebounce(
            () => {
                setAddIntakeLoading(true)

                return dailyGoalRefetch().finally(() => {
                    setAddIntakeLoading(false)
                    setRefetchDebounceRequested(false)
                })
            }
        )
        const {
            waterIntake: initialIntake,
            measureUnitSettings,
            loading,
            error
        } = useGoalChallengeDataProviderContext()

        useEffect(() => {
            if (measureUnitSettings) {
                setIntakeMeasurement(measureUnitSettings)
            }
        }, [measureUnitSettings])

        const [AddWaterIntake] = useAddWaterIntakeMutation({
            variables: {
                amount: glasses - waterIntake,
                challengeId: challenge?.id,
                date: null
            },
            onCompleted: async () => {
                setWaterIntake(glasses)
            }
        })

        const onDailyGoalRefetchDebounced = useCallback(
            (timeout = 1000) => {
                setRefetchDebounceRequested(true)
                dailyGoalRefetchDebounced(timeout)
            },
            [dailyGoalRefetchDebounced]
        )

        const [completeDailyGoal] =
            useCompleteCommunityChallengeDailyGoalMutation({
                variables: {
                    challengeId: challenge?.id,
                    challengeGoalId: goal?.challengeGoal?.id
                },
                onCompleted: async () => {
                    onDailyGoalRefetchDebounced()
                }
            })

        const [setWaterCustomizationMutation] =
            useSetWaterCustomizationMutation()

        const onAddWaterIntake = useCallback(
            (newGlasses: number) => {
                return AddWaterIntake()
                    .then(() => {
                        if (
                            !goal?.completed &&
                            newGlasses >=
                                (goal?.goalEntityDetail as WaterGoalDetail)
                                    ?.water
                        ) {
                            completeDailyGoal()
                        } else {
                            onDailyGoalRefetchDebounced()
                        }
                    })
                    .catch(() => setAddIntakeLoading(false))
            },
            [
                goal,
                AddWaterIntake,
                completeDailyGoal,
                onDailyGoalRefetchDebounced
            ]
        )
        const { actionOnTimeout: addWaterIntakeDebounced } =
            useDebounce(onAddWaterIntake)

        const addIntake = useCallback(
            (add = true) => {
                setGlasses((glasses) => {
                    const newGlasses = glasses + (add ? 1 : -1)
                    // Since we call the debounce before the next render cycle we pass the new glasses value directly as a prop
                    addWaterIntakeDebounced(1000, newGlasses)

                    return newGlasses
                })

                // if the refetch request has been made we make sure to bounce it further so it doesn't trigger in the middle of adding intake
                // Allowing this sometimes causes the count to lose track of where it was
                if (refetchDebounceRequested) {
                    onDailyGoalRefetchDebounced(3000)
                }
            },
            [
                addWaterIntakeDebounced,
                onDailyGoalRefetchDebounced,
                refetchDebounceRequested
            ]
        )

        const saveWaterGoal = useCallback(
            (waterAmount: number, measureUnit: MeasureUnit) =>
                setWaterCustomizationMutation({
                    variables: {
                        challengeId: challenge?.id,
                        waterAmount,
                        measureUnit
                    }
                }).then(async () => {
                    if (waterAmount <= glasses) {
                        completeDailyGoal()
                    } else {
                        onDailyGoalRefetchDebounced()
                    }
                }),
            [
                onDailyGoalRefetchDebounced,
                setWaterCustomizationMutation,
                completeDailyGoal,
                glasses,
                challenge?.id
            ]
        )

        useEffect(() => {
            setWaterIntake(initialIntake)
            setGlasses(initialIntake)
        }, [initialIntake])

        const state = useMemo<HydrationValue>(
            () => ({
                waterIntake,
                loading,
                error: Boolean(error),
                glasses,
                addIntakeLoading,
                goal,
                goalCount,
                intakeMeasurement,
                setGoalCount,
                setIntakeMeasurement,
                saveWaterGoal,
                addIntake,
                setGoal,
                setGlasses
            }),
            [
                waterIntake,
                loading,
                error,
                glasses,
                addIntakeLoading,
                goal,
                goalCount,
                intakeMeasurement,
                setGoalCount,
                setIntakeMeasurement,
                saveWaterGoal,
                addIntake
            ]
        )

        return (
            <HydrationContext.Provider value={state}>
                <Component {...props} />
            </HydrationContext.Provider>
        )
    }
}
