import { IconName } from '@fortawesome/fontawesome-common-types'
import { Avatar } from '@mui/material'
import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

export type FeelingIconAvatarProps = {
    icon: IconName
    label: string
}

const FeelingIconAvatar: React.FC<FeelingIconAvatarProps> = ({
    icon,
    label
}) => {
    const { palette } = useTheme()

    return (
        <Avatar
            variant="rounded"
            aria-label={label}
            sx={{
                background: palette.background.paper,
                color: palette.info.contrastText,
                border: `2px ${palette.info.contrastText} solid`
            }}
        >
            <LeafIcon icon={icon} />
        </Avatar>
    )
}

export default memo(FeelingIconAvatar)
