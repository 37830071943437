import React, { memo, useCallback } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    type SIGNUP_VARIANT_TYPE,
    SIGNUP_VARIANT
} from '../../../../../../constants'
import {
    SocialGroup,
    UnifiedChallenge
} from '../../../../../../../graphql/generated/autogenerated'
import RadioList from '../../../../../elements/RadioList'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import SocialGroupItem from '../../../../../../../components/features/SocialGroups/SocialGroupsList/SocialGroupItem/SocialGroupItem'

type ParticipationMethodStepProps = {
    socialGroup?: SocialGroup
    challenge: UnifiedChallenge
    signupVariant: SIGNUP_VARIANT_TYPE
    joinTeam?: {
        id?: string
        name?: string
    } | null
    setSignupVariant: (type: SIGNUP_VARIANT_TYPE) => void
    setInvitedTeam: (team: { id: string; name: string } | null) => void
}

const messages = defineMessages({
    individualTitle: {
        defaultMessage: 'As an individual',
        description: 'title for the participation method section'
    },
    individualDescription: {
        defaultMessage:
            'If you change your mind after signing up you can join a team.',
        description: 'description for participation method section'
    },
    joinTeamTitle: {
        defaultMessage: 'Join a team',
        description: 'title for the participation method section'
    },
    joinDifferentTeamTitle: {
        defaultMessage: 'Join a different team',
        description: 'title for the participation method section'
    },
    joinTeamDescription: {
        defaultMessage:
            "Next you'll be able to search and join an existing team.",
        description: 'description for participation method section'
    },
    createTeamTitle: {
        defaultMessage: 'Create a team',
        description: 'title for the participation method section'
    },
    createTeamDescription: {
        defaultMessage:
            "Next you'll be able to create a team, and {size, select, null {coworkers can join you} other {up to {size} coworkers can join}}.",
        description: 'description for participation method section'
    }
})

const ParticipationMethodStep: React.FC<ParticipationMethodStepProps> = ({
    socialGroup,
    challenge,
    signupVariant,
    setSignupVariant,
    joinTeam,
    setInvitedTeam
}) => {
    const { userId } = useAppSelector((state) => state.user)

    const { formatMessage } = useIntl()

    const onSetSignUpType = useCallback(
        (signUpType: SIGNUP_VARIANT_TYPE) => {
            setSignupVariant(signUpType)
        },
        [setSignupVariant]
    )

    return (
        <Stack gap={2} px={0.125} pb={0.125}>
            <Stack gap={1}>
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Participation Method"
                        description="title for the participation method section"
                    />
                </CoreTypography>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="How do you want to take part in the Challenge?"
                        description="description for participation method section"
                    />
                </CoreTypography>
            </Stack>
            <RadioList
                onChange={(type: SIGNUP_VARIANT_TYPE) => onSetSignUpType(type)}
                defaultValue={signupVariant}
                value={signupVariant}
                list={[
                    {
                        value: SIGNUP_VARIANT.SOLO,
                        title: formatMessage(messages.individualTitle),
                        description: formatMessage(
                            messages.individualDescription
                        )
                    },
                    {
                        value: SIGNUP_VARIANT.JOIN_TEAM,
                        title: formatMessage(messages.joinTeamTitle),
                        description: formatMessage(messages.joinTeamDescription)
                    },
                    {
                        value: SIGNUP_VARIANT.CREATE_TEAM,
                        title: formatMessage(messages.createTeamTitle),
                        description: formatMessage(
                            messages.createTeamDescription,
                            {
                                size: challenge?.maxTeamSize
                                    ? challenge.maxTeamSize - 1
                                    : null
                            }
                        )
                    }
                ]}
            />
            {!!socialGroup && (
                <SocialGroupItem
                    socialGroup={socialGroup}
                    userId={userId}
                    isJoinList={true}
                    hasJoinedGroup={joinTeam?.id === socialGroup.id}
                    hasAddedGroup={joinTeam?.id === socialGroup.id}
                    addToJoinList={() => {
                        setInvitedTeam({
                            id: socialGroup.id,
                            name: socialGroup.name
                        })
                    }}
                    disable={true}
                    disableNavigate={true}
                    maxCapacity={challenge?.maxTeamSize ?? undefined}
                />
            )}
        </Stack>
    )
}

export default memo(ParticipationMethodStep)
