import useChallengeTheme from '../../../../../hooks/useChallengeTheme/useChallengeTheme'
import {
    type UnifiedChallenge,
    MeasureUnit,
    SocialGroup
} from '../../../../../../graphql/generated/autogenerated'
import {
    SIGNUP_STEPS,
    SIGNUP_STEPS_TYPE,
    SIGNUP_VARIANT
} from '../../../../../constants'

import ConfirmActivityStep from '../Steps/ConfirmActivityStep'
import ConfirmHydrationStep from '../Steps/ConfirmHydrationStep'
import ConfirmStep from '../Steps/ConfirmStep'
import CreateTeamStep from '../Steps/CreateTeamStep'
import DetailsStep from '../Steps/DetailsStep'
import JoinTeamStep from '../Steps/JoinTeamStep'
import SleepHabitsStep from '../Steps/SleepHabitsStep'
import ParticipationMethodStep from '../Steps/ParticipationMethodStep'
import TermsStep from '../Steps/TermsStep'

import MultiStepFormItem from '../../../Shared/MultiStepForm/MultiStepFormItem'
import MultiStepFormContainer from '../../../Shared/MultiStepForm/MultiStepFormContainer'
import { CompanyChallengeForm } from '../../../../../hooks/useCompanyChallengeSignup/useCompanyChallengeForm'

type IntroSignupCompanyChallengeDialogStepsProps = {
    challenge: UnifiedChallenge
    formData: CompanyChallengeForm
    updateFormData: (data: Partial<CompanyChallengeForm>) => void
    setCurrentStepName: (step: SIGNUP_STEPS_TYPE) => void
    setIsDisplayNameValid: (isValid: boolean) => void
    socialGroup?: SocialGroup
}

const IntroSignupCompanyChallengeDialogSteps = ({
    challenge,
    formData,
    updateFormData,
    setCurrentStepName,
    setIsDisplayNameValid,
    socialGroup
}: IntroSignupCompanyChallengeDialogStepsProps) => {
    const { deviceEnabled, hydrationEnabled, sleepEnabled } =
        useChallengeTheme(challenge)
    const isCreateTeam = formData.signupVariant === SIGNUP_VARIANT.CREATE_TEAM
    const isJoinTeam = formData.signupVariant === SIGNUP_VARIANT.JOIN_TEAM
    const isInviteTeam = formData.signupVariant === SIGNUP_VARIANT.INVITE_TEAM

    const teamSignupVariants: {
        [SIGNUP_VARIANT.JOIN_TEAM]: React.JSX.Element
        [SIGNUP_VARIANT.CREATE_TEAM]: React.JSX.Element
    } = {
        [SIGNUP_VARIANT.JOIN_TEAM]: (
            <MultiStepFormItem name={SIGNUP_STEPS.JOIN_TEAM} required>
                <JoinTeamStep
                    challenge={challenge}
                    teamId={formData.joinTeam?.id}
                    setTeam={(id, name) =>
                        updateFormData({
                            joinTeam: { id, name }
                        })
                    }
                    switchParticipationType={() => {
                        setCurrentStepName(SIGNUP_STEPS.CREATE_TEAM)
                        updateFormData({
                            signupVariant: SIGNUP_VARIANT.CREATE_TEAM
                        })
                    }}
                />
            </MultiStepFormItem>
        ),
        [SIGNUP_VARIANT.CREATE_TEAM]: (
            <MultiStepFormItem name={SIGNUP_STEPS.CREATE_TEAM} required>
                <CreateTeamStep
                    challengeId={challenge.id}
                    teamName={formData.createTeam?.teamName}
                    isPublicGroup={formData.createTeam?.isPublic}
                    setTeamName={(value) =>
                        updateFormData({
                            createTeam: { teamName: value }
                        })
                    }
                    setIsPublicGroup={(value) =>
                        updateFormData({
                            createTeam: { isPublic: value }
                        })
                    }
                />
            </MultiStepFormItem>
        )
    } as const
    const variant = formData.signupVariant as keyof typeof teamSignupVariants

    const challengeThemeSteps = [
        deviceEnabled &&
            challenge.allowCustomSteps && {
                step: SIGNUP_STEPS.ACTIVITY,
                component: (
                    <ConfirmActivityStep
                        activityGoal={formData.activityGoal}
                        setActivityGoal={(value) =>
                            updateFormData({ activityGoal: value })
                        }
                    />
                )
            },
        hydrationEnabled && {
            step: SIGNUP_STEPS.HYDRATION,
            component: (
                <ConfirmHydrationStep
                    goalCount={formData.hydration.goalCount}
                    intakeMeasurement={
                        formData.hydration.intakeMeasurement || MeasureUnit.Oz
                    }
                    setGoalCount={(value) =>
                        updateFormData({
                            hydration: { goalCount: value }
                        })
                    }
                    setIntakeMeasurement={(value) =>
                        updateFormData({
                            hydration: { intakeMeasurement: value }
                        })
                    }
                />
            )
        },
        sleepEnabled && {
            step: SIGNUP_STEPS.SLEEP,
            component: (
                <SleepHabitsStep
                    sleepHabit={formData.sleepHabit}
                    setSleepHabit={(value) =>
                        updateFormData({ sleepHabit: value })
                    }
                />
            )
        }
    ].filter(Boolean)

    return (
        <MultiStepFormContainer>
            <MultiStepFormItem name={SIGNUP_STEPS.DETAILS} required>
                <DetailsStep
                    displayName={formData.details.displayName}
                    startDate={formData.details.startDate}
                    setDisplayName={(value) =>
                        updateFormData({
                            details: { displayName: value }
                        })
                    }
                    setStartDate={(value) =>
                        updateFormData({
                            details: { startDate: value }
                        })
                    }
                    shouldDisplayStartDate={false}
                    onValidate={setIsDisplayNameValid}
                />
            </MultiStepFormItem>

            <MultiStepFormItem
                name={SIGNUP_STEPS.PARTICIPATION_METHOD}
                required
            >
                <ParticipationMethodStep
                    socialGroup={socialGroup}
                    challenge={challenge}
                    signupVariant={formData.signupVariant}
                    joinTeam={formData.joinTeam}
                    setSignupVariant={(value) =>
                        updateFormData({
                            signupVariant: value,
                            joinTeam: null
                        })
                    }
                    setInvitedTeam={(value) =>
                        updateFormData({
                            signupVariant: SIGNUP_VARIANT.INVITE_TEAM,
                            joinTeam: value
                        })
                    }
                />
            </MultiStepFormItem>

            {teamSignupVariants[variant] ?? null}

            {challengeThemeSteps.map(({ step, component }: any) => (
                <MultiStepFormItem key={step} name={step} required>
                    {component}
                </MultiStepFormItem>
            ))}

            <MultiStepFormItem name={SIGNUP_STEPS.CONFIRM}>
                <ConfirmStep
                    challengeName={challenge.name}
                    displayName={formData.details.displayName}
                    startDate={formData.details.startDate}
                    shouldDisplayStartDate={false}
                    activityGoal={formData.activityGoal}
                    teamName={
                        isCreateTeam
                            ? formData.createTeam?.teamName
                            : isJoinTeam
                            ? formData.joinTeam?.name
                            : undefined
                    }
                    participationMethod={
                        !isCreateTeam && !isJoinTeam && !isInviteTeam
                            ? 'individual'
                            : 'team'
                    }
                    dailyWaterGoal={{
                        glasses: formData.hydration.goalCount,
                        measurement: formData.hydration.intakeMeasurement
                    }}
                />
            </MultiStepFormItem>

            <MultiStepFormItem name={SIGNUP_STEPS.TERMS} required>
                <TermsStep
                    confirmed={formData.terms.confirmed}
                    setConfirmed={(value) =>
                        updateFormData({
                            terms: { confirmed: value }
                        })
                    }
                    deviceEnabled={challenge.device_enabled}
                    isChallengeTemplate={false}
                    isTeamSignUp={isCreateTeam || isJoinTeam || isInviteTeam}
                />
            </MultiStepFormItem>
        </MultiStepFormContainer>
    )
}

export default IntroSignupCompanyChallengeDialogSteps
