import React from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    GroupChallengeType,
    MeasureUnit
} from '../../../../../../../graphql/generated/autogenerated'
import useGlassesMeasurementConversion from '../../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'
import ConfirmField from '../../../../../elements/ConfirmField'

const messages = defineMessages({
    challenge: {
        defaultMessage: 'Challenge',
        description: 'title for the challenge input field'
    },
    displayName: {
        defaultMessage: 'Display Name',
        description: 'title for the display name input field'
    },
    startDate: {
        defaultMessage: 'Start Date',
        description: 'title for the start date input field'
    },
    participationType: {
        defaultMessage: 'Challenge Type',
        description: 'title for the participation type input field'
    },
    individual: {
        defaultMessage: 'Individual',
        description: 'individual text'
    },
    group: {
        defaultMessage: 'Group',
        description: 'group text'
    },
    team: {
        defaultMessage: 'Team',
        description: 'title for the team input field'
    },
    activityGoal: {
        defaultMessage: 'Activity Goal',
        description: 'title for the activity goal input field'
    },
    yourTeamName: {
        defaultMessage: 'Your Team Name',
        description: 'title for the team name input field'
    },
    participationMethod: {
        defaultMessage: 'Participation Method',
        description: 'title for the participation method input field'
    },
    dailyWaterGoal: {
        defaultMessage: 'Daily Water Goal',
        description: 'title for the daily water goal input field'
    },
    dailyWaterGoalValue: {
        defaultMessage: '{glasses} glasses / {measurement}',
        description: 'title for the daily water goal input field'
    }
})

export interface ConfirmProps {
    startDate?: string | undefined
    displayName: string | undefined
    challengeName: string
    shouldDisplayStartDate?: boolean
    groupChallengeType?: GroupChallengeType
    participationMethod?: 'individual' | 'team'
    activityGoal?: number
    teamName?: string
    dailyWaterGoal?: {
        glasses?: number
        measurement?: string
    }
}

const ConfirmStep: React.FC<ConfirmProps> = ({
    startDate,
    shouldDisplayStartDate = true,
    displayName,
    challengeName,
    groupChallengeType,
    participationMethod,
    activityGoal,
    teamName,
    dailyWaterGoal
}) => {
    const { formatMessage } = useIntl()

    const hydrationMeasurement = useGlassesMeasurementConversion(
        dailyWaterGoal?.glasses || 0,
        dailyWaterGoal?.measurement as MeasureUnit
    )

    return (
        <Stack gap={2}>
            <Stack gap={2}>
                <CoreTypography variant="h5">
                    <FormattedMessage
                        defaultMessage="Confirm details"
                        description="title for the display name input field"
                    />
                </CoreTypography>
                <Stack gap={2} maxWidth={327}>
                    <Stack data-testid="template-text-field">
                        <ConfirmField
                            title={formatMessage(messages.challenge)}
                            value={challengeName}
                        />
                    </Stack>
                    <Stack data-testid="display-name-text-field">
                        <ConfirmField
                            title={formatMessage(messages.displayName)}
                            value={displayName}
                        />
                    </Stack>
                    {shouldDisplayStartDate && (
                        <Stack data-testid="start-date-text-field">
                            <ConfirmField
                                title={formatMessage(messages.startDate)}
                                value={startDate}
                            />
                        </Stack>
                    )}
                    {groupChallengeType && (
                        <Stack data-testid="participation-type-text-field">
                            <ConfirmField
                                title={formatMessage(
                                    messages.participationType
                                )}
                                value={
                                    groupChallengeType ===
                                    GroupChallengeType.Personal
                                        ? formatMessage(messages.individual)
                                        : formatMessage(messages.group)
                                }
                            />
                        </Stack>
                    )}
                    {!!activityGoal && (
                        <Stack data-testid="start-date-text-field">
                            <ConfirmField
                                title={formatMessage(messages.activityGoal)}
                                value={activityGoal.toString()}
                            />
                        </Stack>
                    )}
                    {!!teamName && (
                        <Stack data-testid="team-name-text-field">
                            <ConfirmField
                                title={formatMessage(messages.yourTeamName)}
                                value={teamName}
                            />
                        </Stack>
                    )}
                    {!!participationMethod && (
                        <Stack data-testid="participation-method-text-field">
                            <ConfirmField
                                title={formatMessage(
                                    messages.participationMethod
                                )}
                                value={
                                    participationMethod === 'individual'
                                        ? formatMessage(messages.individual)
                                        : formatMessage(messages.team)
                                }
                            />
                        </Stack>
                    )}
                    {!!dailyWaterGoal?.glasses &&
                        !!dailyWaterGoal.measurement && (
                            <Stack data-testid="daily-water-goal-text-field">
                                <ConfirmField
                                    title={formatMessage(
                                        messages.dailyWaterGoal
                                    )}
                                    value={formatMessage(
                                        messages.dailyWaterGoalValue,
                                        {
                                            glasses: dailyWaterGoal.glasses,
                                            measurement: hydrationMeasurement
                                        }
                                    )}
                                />
                            </Stack>
                        )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ConfirmStep
