import { format } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import { useCompanyChallengeProviderContext } from '../withCompanyChallengeProvider/useCompanyChallengeProviderContext'
import { useGoalChallengeDataProviderContext } from '../withGoalChallenges/useGoalChallengeData'
import useGoalCompletedLocalState from './useGoalsCompletedLocalState'

export const useThresholdReachedDialogState = () => {
    const { thresholdValue, thresholdAchieved } =
        useGoalChallengeDataProviderContext()
    const { goals } = useGoalChallengeDataProviderContext()
    const { challengeStarted, challenge, challengeExpired } =
        useCompanyChallengeProviderContext()
    const { loadThresholdReached, saveThresholdReached } =
        useGoalCompletedLocalState()

    const { thresholdReached, achievedGoalsGreaterThanThreshold } =
        useMemo(() => {
            const thresholdReached = !!(
                thresholdAchieved &&
                thresholdValue &&
                +thresholdAchieved >= +thresholdValue
            )
            const achievedGoalsGreaterThanThreshold =
                thresholdReached &&
                thresholdAchieved - thresholdValue > goals?.length

            return { thresholdReached, achievedGoalsGreaterThanThreshold }
        }, [thresholdAchieved, thresholdValue, goals])

    const saveThresholdReachedState = useCallback(
        () => saveThresholdReached(format(new Date()), challenge?.id),
        [challenge, saveThresholdReached]
    )

    const canShowDialog = useMemo(() => {
        const { date } = loadThresholdReached(challenge?.id)

        if (
            thresholdReached &&
            !date &&
            !challengeExpired &&
            challengeStarted
        ) {
            // if the thresholdAchieved is greater than the thresholdValue and by a margin greater than the number of goals the user would receive in a day
            // we can assume they have already seen the dialog at some point
            // We don't show anything and save the state
            if (achievedGoalsGreaterThanThreshold) {
                saveThresholdReachedState()

                return false
            }

            return true
        }

        return false
    }, [
        challenge,
        thresholdReached,
        challengeExpired,
        challengeStarted,
        achievedGoalsGreaterThanThreshold,
        loadThresholdReached,
        saveThresholdReachedState
    ])

    return { canShowDialog, saveThresholdReachedState }
}

export default useThresholdReachedDialogState
