import { Card, Skeleton, Stack } from '@mui/material'
import React from 'react'

const YourPlantGoalSkeleton: React.FC = () => {
    return (
        <Card elevation={0} sx={{ flex: 1 }}>
            <Stack p={2} flex={1} height="100%" gap={3}>
                <Skeleton variant="rounded" height={20} width={150} />
                <Stack flex={1} gap={1}>
                    <Skeleton variant="rounded" height={15} width="100%" />
                    <Skeleton variant="rounded" height={15} width="100%" />
                    <Skeleton variant="rounded" height={15} width="100%" />
                    <Skeleton variant="rounded" height={15} width="75%" />
                </Stack>
            </Stack>
        </Card>
    )
}

export default YourPlantGoalSkeleton
