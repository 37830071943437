import { AccordionDetails, Stack } from '@mui/material'
import {
    LeafAccordion,
    LeafAccordionSummary
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

export type AccordionChildProps = {
    summary: React.ReactNode
    details: React.ReactNode
}

export type AccordionGroupProps = {
    children: AccordionChildProps[]
}

const AccordionGroup: React.FC<AccordionGroupProps> = ({ children }) => {
    const [expanded, setExpanded] = React.useState<string | false>(`panel-${0}`)

    const handleChange =
        (panel: string) =>
        (_: React.SyntheticEvent<Element, Event>, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false)
        }
    return (
        <Stack>
            {children.map((child, index: number) => (
                <LeafAccordion
                    key={index}
                    TransitionProps={{ unmountOnExit: true }}
                    onChange={handleChange(`panel-${index}`)}
                    expanded={expanded === `panel-${index}`}
                >
                    <LeafAccordionSummary
                        aria-controls={`panel-${index}`}
                        data-testid={`panel-${index}`}
                    >
                        {child.summary}
                    </LeafAccordionSummary>
                    <AccordionDetails>{child.details}</AccordionDetails>
                </LeafAccordion>
            ))}
        </Stack>
    )
}

export default memo(AccordionGroup)
