import { Card, Grid, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import type {
    ChallengeUserDailyGoal,
    MicrostepGoalDetail,
    MicrostepStats,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import ViewMoreDialog from '../ViewMoreDialog'
import { FormattedMessage } from 'react-intl'
import MicrostepGoal from '../../Shared/DailyGoals/Goals/MicrostepGoal'

export type CompletedMicrostepsDialogProps = {
    challenge: UnifiedChallenge
    microstepStats: MicrostepStats
    open: boolean
    onClose: () => void
}

const CompletedMicrostepsDialog = ({
    challenge,
    microstepStats,
    open,
    onClose
}: CompletedMicrostepsDialogProps) => {
    const { palette } = useTheme()

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={5} pb={6}>
                <Stack gap={2}>
                    <CoreTypography variant="h1" color="primary.main">
                        <FormattedMessage
                            defaultMessage="Microsteps"
                            description="microsteps usage description"
                        />
                    </CoreTypography>
                    <CoreTypography variant="h3" color="text.primary">
                        <FormattedMessage
                            defaultMessage="Each Microstep taken builds to lasting behavior change."
                            description="tips title"
                        />
                    </CoreTypography>
                </Stack>
                <Grid container>
                    {microstepStats?.microsteps
                        ?.filter((microstepStat) => microstepStat?.completed)
                        ?.map((microstepStat, index) => {
                            const microstep =
                                microstepStat?.microstepDetail?.microstep

                            return (
                                <Grid item key={index} xs={12} md={4}>
                                    <Card sx={{ overflow: 'visible' }}>
                                        <Stack
                                            p={2}
                                            gap={2}
                                            sx={{
                                                background:
                                                    palette.secondary.main
                                            }}
                                        >
                                            <MicrostepGoal
                                                challenge={challenge}
                                                goal={
                                                    {
                                                        goalEntityDetail: {
                                                            microstep: microstep
                                                        } as MicrostepGoalDetail,
                                                        completed: false
                                                    } as ChallengeUserDailyGoal
                                                }
                                                disabled={true}
                                                hideButton={true}
                                            />
                                        </Stack>
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(CompletedMicrostepsDialog)
