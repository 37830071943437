import { Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ChallengeIconInfo from '../../../../../components/features/Challenge/ChallengeIconInfo/ChallengeIconInfo'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import useTimeline from '../../../../../hooks/useTimeline/useTimeline'

export interface HeaderIconsProps {
    isTemplate: boolean
    challenge?: UnifiedChallenge
    isExpired: boolean
    canShowDailyGoalCount: boolean
    goalCount: number
    hasParticipants: boolean
    showSocialMembersList: boolean
    timeline: string | null
}

const HeaderIcons: React.FC<HeaderIconsProps> = ({
    isTemplate,
    challenge,
    isExpired,
    canShowDailyGoalCount,
    goalCount,
    hasParticipants,
    showSocialMembersList,
    timeline
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Stack gap={isMobile ? 1 : 2} whiteSpace="nowrap">
            {timeline && !isTemplate && (
                <ChallengeIconInfo icon="calendar" info={<>{timeline}</>} />
            )}
            {!isExpired && (
                <React.Fragment>
                    <ChallengeIconInfo
                        icon="clock"
                        info={
                            <FormattedMessage
                                defaultMessage="{duration} day Challenge"
                                description="total number of days in the challenge"
                                values={{
                                    duration: challenge?.duration ?? 0
                                }}
                            />
                        }
                    />
                    {canShowDailyGoalCount && (
                        <ChallengeIconInfo
                            icon="circle-check"
                            info={
                                <FormattedMessage
                                    defaultMessage="{goalCount, plural, one {# daily goal} other {# daily goals}}"
                                    description="daily goals count"
                                    values={{
                                        goalCount
                                    }}
                                />
                            }
                        />
                    )}
                </React.Fragment>
            )}
            {hasParticipants && (
                <React.Fragment>
                    <ChallengeIconInfo
                        clickable={showSocialMembersList}
                        icon="user"
                        onClick={() => {}}
                        info={
                            <FormattedMessage
                                defaultMessage="{totalParticipants, plural, one {# participant} other {# participants}}"
                                description="count of participants in the challenge"
                                values={{
                                    totalParticipants:
                                        challenge?.totalParticipants ?? 0
                                }}
                            />
                        }
                    />
                    {/* <ParticipantsDialog
                        open={participantsDialogOpen}
                        onClose={() => setParticipantsDialogOpen(false)}
                    /> */}
                </React.Fragment>
            )}
        </Stack>
    )
}

export default HeaderIcons
