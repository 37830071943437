import React, { useMemo } from 'react'
import {
    AudioResetGoalDetail,
    ChallengeUserDailyGoal,
    GoalType,
    UnifiedChallenge,
    useGetAudioResetsByIdsQuery
} from '../../../../../../../graphql/generated/autogenerated'
import AudioResetGoal from '.'

export type AudioResetGoalByQueryProps = {
    id: string
    type: GoalType
    challenge: UnifiedChallenge
    onStart: () => void
    onComplete: () => void
}

const AudioResetGoalByQuery: React.FC<AudioResetGoalByQueryProps> = ({
    id,
    type,
    challenge,
    onStart,
    onComplete
}) => {
    const { data, loading, error } = useGetAudioResetsByIdsQuery({
        variables: {
            ids: [id]
        }
    })

    const goal = useMemo(() => {
        if (data && !loading) {
            const reset = data?.reset?.thriveAudio.getByIds[0]

            return {
                challengeGoal: {
                    goalType: type as string
                },
                goalEntityDetail: {
                    audioReset: {
                        ...reset
                    } as AudioResetGoalDetail | unknown
                },
                completed: false
            }
        }
    }, [data, loading, type])

    if (error) throw error

    return (
        <AudioResetGoal
            goal={goal as ChallengeUserDailyGoal}
            challenge={challenge}
            loading={loading}
            onStart={onStart}
            onComplete={onComplete}
            disabled={true}
        />
    )
}

export default AudioResetGoalByQuery
