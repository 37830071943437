import startOfDay from 'date-fns/startOfDay'
import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useZonedDate from '../../../../../hooks/useZonedDateTime/useZonedDateTime'
import { ReactNullValue } from '../../../../../../shared/utils/Nulls'
import PlantsEarnedCard from '../PlantsEarnedCard'
import { CommunityGardenInfo } from '../../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    todayCount: {
        defaultMessage: "today's plants earned",
        description: "description for today's plant count"
    }
})

export type CommunityTodayPlantsEarnedProps = {
    size?: 'small' | 'large'
    groupedCommunityPlants: CommunityGardenInfo[]
}

const CommunityTodayPlantsEarned: React.FC<CommunityTodayPlantsEarnedProps> = ({
    size,
    groupedCommunityPlants
}) => {
    const { formatMessage } = useIntl()
    const { getZonedDate, getUTCDate } = useZonedDate()

    // Calculate today's date
    const todayDate = getUTCDate(startOfDay(getZonedDate()))
    const formattedTodayDate = todayDate.toISOString().split('T')[0]

    const totalPlants = useMemo(() => {
        return groupedCommunityPlants?.reduce((accumulator, groupedPlant) => {
            if (groupedPlant.date.slice(0, 10) === formattedTodayDate) {
                return accumulator + groupedPlant.plantsNumber
            }
            return accumulator
        }, 0)
    }, [formattedTodayDate, groupedCommunityPlants])

    return (
        <PlantsEarnedCard
            title={formatMessage(messages.todayCount)}
            stat={totalPlants}
            size={size}
        />
    )
}

export default memo(CommunityTodayPlantsEarned)
