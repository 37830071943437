import { StreamPlayerApi } from '@cloudflare/stream-react'
import { CircularProgress, Stack } from '@mui/material'
import {
    ResetIframePlayerWithFallback,
    ResetPlayerPopup
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ChallengeUserDailyGoal,
    ResetGoalDetail,
    UnifiedChallenge,
    useCompleteCommunityChallengeDailyGoalMutation,
    useFireThriveResetWatchedEventMutation
} from '../../../../../../../graphql/generated/autogenerated'
import {
    defaultContentCompletedProps,
    defaultContentStartedProps
} from '../../../../../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../../../../../utils/nulls'
import ContentCard from '../../../../../elements/ContentCard'
import GoalTitle from '../../GoalTitle'

const messages = defineMessages({
    watchAReset: {
        defaultMessage: 'Watch a reset',
        description: 'title for the reset daily goal card'
    },
    youWatched: {
        defaultMessage: 'You watched',
        description:
            'title of the reset video a user has watched, there is no text following this, there is a video below it'
    },
    reset: {
        defaultMessage: 'Reset',
        description: 'content type description'
    },
    watch: {
        defaultMessage: '{durationInMinutes} minute watch',
        description: 'description of the duration in minutes the video runs for'
    }
})

export type ResetGoalProps = {
    challenge: UnifiedChallenge | undefined
    goal: ChallengeUserDailyGoal
    loading?: boolean
    disabled?: boolean
    onStart: () => void
    onComplete: () => void
}

const RESET_THRESHOLD = 80

const ResetGoal: React.FC<ResetGoalProps> = ({
    challenge,
    goal,
    loading,
    disabled,
    onStart,
    onComplete
}) => {
    const { formatMessage } = useIntl()
    const playerRef = useRef<StreamPlayerApi>(ReactNullValue)
    const [isPlayerOpen, setIsPlayerOpen] = useState(false)
    const [resetThresholdHit, setResetThresholdHit] = useState(false)
    const [fireThriveResetWatchedEvent] =
        useFireThriveResetWatchedEventMutation()

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    const { reset, durationInMinutes, completed } = useMemo(() => {
        const resetEntityDetail = goal?.goalEntityDetail as ResetGoalDetail
        return {
            reset: resetEntityDetail?.resetInfo,
            durationInMinutes: 5,
            completed: goal?.completed
        }
    }, [goal])

    const clickHandler = useCallback(() => {
        Avo.contentStarted({
            ...defaultContentStartedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: 'video',
            contentId: reset?.id,
            contentType: 'reset',
            contentTitle: reset?.name,
            featureType: 'challenge',
            resetType: 'challenge_reset',
            tabName: 'Home'
        })
        setIsPlayerOpen(true)
        onStart()
    }, [onStart, challenge, reset])

    const closeHandler = useCallback(() => {
        setIsPlayerOpen(false)
        if (resetThresholdHit) {
            onComplete()
        }
    }, [resetThresholdHit, onComplete])

    const onEndedHandler = useCallback(async () => {
        await Promise.all([
            fireThriveResetWatchedEvent({
                variables: {
                    resetId: reset.id
                }
            }),
            completeDailyGoal()
        ])
        Avo.contentCompleted({
            ...defaultContentCompletedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: 'audio',
            contentType: 'reset',
            featureType: 'challenge',
            resetType: 'challenge_reset'
        })
    }, [reset, challenge, completeDailyGoal, fireThriveResetWatchedEvent])

    const onHandleTimeUpdate = useCallback(() => {
        const percentageComplete =
            ((playerRef?.current?.currentTime ?? 0) /
                (playerRef?.current?.duration ?? 0)) *
            100

        if (percentageComplete >= RESET_THRESHOLD && !resetThresholdHit) {
            setResetThresholdHit(true)
            onEndedHandler()
        }
    }, [playerRef, resetThresholdHit, onEndedHandler])

    return (
        <Stack gap={2} height="100%">
            <GoalTitle
                title={formatMessage(
                    completed ? messages.youWatched : messages.watchAReset
                )}
            />
            <ContentCard
                elevation={1}
                loading={loading}
                disabled={disabled}
                onClick={clickHandler}
                title={reset?.name as string}
                image={reset?.landscape?.thumbnail as string}
                contentType={formatMessage(messages.reset)}
                description={reset?.description as string}
                duration={formatMessage(messages.watch, {
                    durationInMinutes
                })}
            />
            {isPlayerOpen && (
                <ResetPlayerPopup isOpen={isPlayerOpen} onClose={closeHandler}>
                    <Stack height="100%" width="100%">
                        <Suspense fallback={<CircularProgress />}>
                            {reset?.landscape?.iframe && (
                                <ResetIframePlayerWithFallback
                                    src={reset.landscape.iframe}
                                    ref={playerRef}
                                    muted={false}
                                    autoplay={true}
                                    controls={true}
                                    onTimeUpdate={onHandleTimeUpdate}
                                />
                            )}
                        </Suspense>
                    </Stack>
                </ResetPlayerPopup>
            )}
        </Stack>
    )
}

export default ResetGoal
