import { ButtonBase, Card, Icon } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'

export enum DualToggleIndex {
    First,
    Second
}

export interface DualToggleProps {
    activeIndex?: DualToggleIndex
    firstElement: React.ReactNode
    secondElement: React.ReactNode
    onSelect: (index: number) => void
}

const buttonBaseStyle = { flex: 1, height: '100%', px: 2 }

const DualToggle: React.FC<DualToggleProps> = ({
    activeIndex = DualToggleIndex.First,
    firstElement,
    secondElement,
    onSelect
}) => {
    const { palette } = useTheme()
    const cardStyle = useMemo(() => {
        return {
            height: 39,
            minWidth: 95.25,
            display: 'flex',
            direction: 'row',
            overflow: 'hidden',
            bgcolor: palette.grey[100],
            zIndex: 11
        }
    }, [palette])
    return (
        <Card variant="outlined" sx={cardStyle}>
            <ButtonBase
                onClick={() => onSelect?.(DualToggleIndex.First)}
                sx={{
                    ...buttonBaseStyle,
                    bgcolor:
                        activeIndex === DualToggleIndex.First
                            ? palette.common.white
                            : undefined
                }}
            >
                {firstElement}
            </ButtonBase>
            <ButtonBase
                onClick={() => onSelect?.(DualToggleIndex.Second)}
                sx={{
                    ...buttonBaseStyle,
                    borderLeft: `1px solid ${palette.grey[200]}`,
                    bgcolor:
                        activeIndex === DualToggleIndex.Second
                            ? palette.common.white
                            : undefined
                }}
            >
                {secondElement}
            </ButtonBase>
        </Card>
    )
}

export default memo(DualToggle)
