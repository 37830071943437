import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import { PageLayout } from '@thriveglobal/thrive-web-leafkit'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useCallback, FC, useEffect, useState } from 'react'
import { ROUTES } from '../../../../routes'

import ChallengeSkeleton from '../../../../components/features/Challenge/ChallengeSkeleton/ChallengeSkeleton'
import Page from '../../../components/layout/Page'
import BackActionHeader from '../../../components/layout/BackActionHeader'
import Background from '../../../components/elements/Background'

import useChallengeRecap from '../../../hooks/useChallengeRecap/useChallengeRecap'

import withStorySubmissionProvider from '../../../contexts/withStorySubmission/withStorySubmissionProvider/withStorySubmissionProvider'

import { ChallengeThemes } from '../../../enums/challengeThemes'
import { RecapWidgetContainer } from '../../../components/features/Recap/RecapWidget'
import type {
    ArticleStats,
    ChallengeStats,
    CommunityGardenInfo,
    GetChallengeRanksQuery,
    MicrostepStats,
    ResetStats,
    ScoreRanked,
    SleepHabitsResult,
    SocialGroup,
    StoryType,
    UnifiedChallenge,
    UnifiedChallengeActivity,
    UserPlantInfo
} from '../../../../graphql/generated/autogenerated'

import AboutChallenge from '../../../components/features/Recap/AboutChallenge'
import TotalPlantsEarned from '../../../components/features/Recap/TotalPlantsEarned'
import TeamRecap from '../../../components/features/Recap/TeamRecap'
import CompletedResets from '../../../components/features/Recap/CompletedResets'
import CompletedMicrosteps from '../../../components/features/Recap/CompletedMicrosteps'
import HydrationRecap from '../../../components/features/Recap/HydrationRecap'
import ActivityRecap from '../../../components/features/Shared/ActivityRecap'
import SleepReflections from '../../../components/features/Recap/SleepReflections'
import ChallengePrize from '../../../components/features/Recap/ChallengePrize'
import CompletedArticles from '../../../components/features/Recap/CompletedArticles'
import CommunityActivity from '../../../components/features/Recap/CommunityActivity'
import StorySubmission from '../../../components/features/Recap/StorySubmission'
import ConnectedSleepDevices from '../../../components/features/Recap/ConnectedDevices'

type ChallengeRecapProps = {
    challengeId?: string
    theme?: string
} & JSX.IntrinsicAttributes

const ChallengeRecap: FC<ChallengeRecapProps> = ({
    challengeId = '',
    theme = ''
}) => {
    const { challenge, socialGroups, plants, loading } = useChallengeRecap(
        challengeId,
        theme
    )

    const { breakpoints, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const navigate = useCrossAppNavigation()

    const [hasSubmittedStory, setHasSubmittedStory] = useState<boolean>(false)

    const unifiedChallenge = challenge?.unifiedChallenges?.unifiedChallenge
    const unifiedChallengeParticipation =
        challenge?.unifiedChallenges?.unifiedUserChallenges

    const isSoloChallenge = socialGroups?.socialGroup?.isSoloChallenge
    const stories = challenge?.unifiedChallenges?.getStories || []
    const backgroundImage = unifiedChallenge?.backgroundImage || ''

    const isMovementChallenge = theme === ChallengeThemes.MOVEMENT
    const isHydrationChallenge = theme === ChallengeThemes.HYDRATION
    const isSleepChallenge = theme === ChallengeThemes.SLEEP

    const currentParticipation = unifiedChallengeParticipation?.find(
        (uc) => uc.challenge.id === challengeId
    )?.participation[0]
    const availableInteractions =
        challenge?.unifiedChallenges.availableInteractions

    const totalPlantsEarned =
        plants?.retail.getUserCommunityChallengePlants || []
    const teamPlantsEarned = plants?.retail.teamChallengePlants || []
    const communityPlantsEarned =
        plants?.retail.groupedCommunityChallengePlants || []

    const stats = challenge?.unifiedChallenges.getChallengeStats

    const handleOnBack = useCallback(() => navigate(ROUTES.HOME), [navigate])

    useEffect(() => {
        if (stories.length) {
            setHasSubmittedStory(true)
        }
    }, [stories])

    if (loading || !unifiedChallenge) {
        return <ChallengeSkeleton />
    }

    return (
        <Background backgroundImage={backgroundImage}>
            <PageLayout maxWidth="lg">
                <Page>
                    <Stack pb={isMobile ? 6 : 0} gap={2}>
                        <BackActionHeader onBack={handleOnBack} />
                        <Grid container pb={6}>
                            <Grid item xs={12}>
                                <AboutChallenge
                                    challenge={
                                        unifiedChallenge as UnifiedChallenge
                                    }
                                    currentParticipation={
                                        currentParticipation as UnifiedChallengeActivity
                                    }
                                    availableInteractions={
                                        availableInteractions as StoryType[]
                                    }
                                    stories={stories}
                                    storyComplete={hasSubmittedStory}
                                    onStorySuccess={() =>
                                        setHasSubmittedStory(true)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack gap={2}>
                                    <RecapWidgetContainer
                                        hide={!totalPlantsEarned.length}
                                    >
                                        <TotalPlantsEarned
                                            data={
                                                totalPlantsEarned as UserPlantInfo[]
                                            }
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={isSoloChallenge}
                                    >
                                        <TeamRecap
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            socialGroup={
                                                socialGroups?.socialGroup as SocialGroup
                                            }
                                            data={
                                                teamPlantsEarned as CommunityGardenInfo[]
                                            }
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={!stats?.resetStats.total}
                                    >
                                        <CompletedResets
                                            resetStats={
                                                stats?.resetStats as ResetStats
                                            }
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={!stats?.resetStats.total}
                                    >
                                        <ConnectedSleepDevices
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                        />
                                        ,
                                    </RecapWidgetContainer>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack gap={2}>
                                    <RecapWidgetContainer
                                        hide={!stats?.microstepStats.total}
                                    >
                                        <CompletedMicrosteps
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            microstepStats={
                                                stats?.microstepStats as MicrostepStats
                                            }
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={!isHydrationChallenge}
                                    >
                                        <HydrationRecap
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            data={{
                                                getChallengeStats:
                                                    stats as ChallengeStats,
                                                getChallengeTotalWater:
                                                    challenge?.unifiedChallenges
                                                        ?.getChallengeTotalWater ||
                                                    0,
                                                getMeasureUnitSettings:
                                                    challenge?.unifiedChallenges
                                                        ?.getMeasureUnitSettings,
                                                getUserTotalWater:
                                                    challenge?.unifiedChallenges
                                                        ?.getUserTotalWater || 0
                                            }}
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={!isMovementChallenge}
                                    >
                                        <ActivityRecap
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            data={{
                                                getChallengeStats:
                                                    stats as ChallengeStats,
                                                getSoloPlayerRank: challenge
                                                    ?.unifiedChallenges
                                                    ?.getSoloPlayerRank as ScoreRanked
                                            }}
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer
                                        hide={!isSleepChallenge}
                                    >
                                        <SleepReflections
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            data={{
                                                getChallengeStats:
                                                    stats as ChallengeStats,
                                                getSleepHabits: challenge
                                                    ?.unifiedChallenges
                                                    ?.getSleepHabits as SleepHabitsResult
                                            }}
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer>
                                        <ChallengePrize
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                        />
                                    </RecapWidgetContainer>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack gap={2}>
                                    <RecapWidgetContainer
                                        hide={!stats?.articleStats.total}
                                    >
                                        <CompletedArticles
                                            articleStats={
                                                stats?.articleStats as ArticleStats
                                            }
                                        />
                                    </RecapWidgetContainer>
                                    <RecapWidgetContainer>
                                        <CommunityActivity
                                            challenge={
                                                unifiedChallenge as UnifiedChallenge
                                            }
                                            socialGroup={
                                                socialGroups?.socialGroup as SocialGroup
                                            }
                                            deviceEnabled={isMovementChallenge}
                                            data={communityPlantsEarned}
                                        />
                                    </RecapWidgetContainer>
                                </Stack>
                            </Grid>
                        </Grid>
                        {isMobile && !hasSubmittedStory && (
                            <Stack
                                p={2}
                                left={0}
                                bottom={0}
                                zIndex={1}
                                width="100%"
                                position="fixed"
                                alignItems="center"
                                justifyContent="center"
                                bgcolor={palette.common.white}
                            >
                                <StorySubmission
                                    challenge={
                                        unifiedChallenge as UnifiedChallenge
                                    }
                                    currentParticipation={
                                        currentParticipation as UnifiedChallengeActivity
                                    }
                                    availableInteractions={
                                        availableInteractions as StoryType[]
                                    }
                                    stories={stories}
                                    storyComplete={hasSubmittedStory}
                                    onSuccess={() => setHasSubmittedStory(true)}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Page>
            </PageLayout>
        </Background>
    )
}

export default withStorySubmissionProvider(ChallengeRecap)
