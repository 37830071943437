import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useHydrationProviderContext } from '../../../../../hooks/hydrationProviders/withHydrationProvider'
import { MeasureUnit } from '../../../../../graphql/generated/autogenerated'
import DailyGoalMeasurement from '../../../../../components/features/Challenge/DailyGoals/Goals/HydrationGoal/HydrationDailyGoalDialog/DailyGoalMeasurement'
import DailyGoalTracker from '../../../../../components/features/Challenge/DailyGoals/Goals/HydrationGoal/HydrationDailyGoalDialog/DailyGoalTracker'

const CONFIRM_HYDRATION = 'confirmHydration'

const ConfirmHydration: React.FC = () => {
    const { goalCount, intakeMeasurement, setIntakeMeasurement, setGoalCount } =
        useHydrationProviderContext()

    const { addDisable } = useCompanyChallengeSignUpProviderContext()

    useEffect(() => {
        addDisable(CONFIRM_HYDRATION, goalCount === 0)
    }, [goalCount, addDisable])

    return (
        <Stack gap={2}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Set your daily water goal for the challenge"
                    description="title for the confirm hydration page"
                />
            </CoreTypography>
            <Box width="100%">
                <DailyGoalMeasurement
                    measurement={intakeMeasurement as MeasureUnit}
                    setMeasurement={setIntakeMeasurement}
                />
            </Box>
            <Box width="100%">
                <DailyGoalTracker
                    newGoal={goalCount}
                    setNewGoal={setGoalCount}
                    measurement={intakeMeasurement as MeasureUnit}
                />
            </Box>
            <CoreTypography variant="body2">
                <FormattedMessage
                    defaultMessage="Thrive recommends starting with 8 glasses per day. Don’t worry, you’ll be able to update your daily goal at any time."
                    description="subtitle for the confirm hydration page"
                />
            </CoreTypography>
        </Stack>
    )
}

export default memo(ConfirmHydration)
