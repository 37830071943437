import { useCallback, useEffect, useMemo } from 'react'
import { useMultiStepFormContext } from '../../contexts/withMultiStepForm/withMultiStepForm'
import { SIGNUP_STEPS, SIGNUP_STEPS_TYPE } from '../../constants'
import { useChangeCommunityChallengeTeamMutation } from '../../../graphql/generated/autogenerated'

export interface ChangeChallengeParticipationFormData {
    terms: {
        confirmed: boolean
    }
    socialGroup: {
        id: string | null
        name: string | null
    }
}

const INITIAL_FORM_DATA: ChangeChallengeParticipationFormData = {
    terms: {
        confirmed: false
    },
    socialGroup: {
        id: null,
        name: null
    }
}

const useChangeChallengeParticipation = () => {
    const {
        formData,
        currentStepName,
        setStepCompletion,
        updateFormData,
        ...rest
    } = useMultiStepFormContext()
    const [changeCommunityChallengeTeam, { loading }] =
        useChangeCommunityChallengeTeamMutation()

    const stepCompletionStatus = useMemo(
        () => ({
            [SIGNUP_STEPS.TERMS]: formData?.terms?.confirmed,
            [SIGNUP_STEPS.JOIN_TEAM]:
                !!formData?.socialGroup?.id && !!formData?.socialGroup?.name
        }),
        [
            formData?.terms?.confirmed,
            formData?.socialGroup?.id,
            formData?.socialGroup?.name
        ]
    ) as Record<SIGNUP_STEPS_TYPE, boolean>

    useEffect(() => {
        updateFormData(INITIAL_FORM_DATA)
    }, [])

    useEffect(() => {
        const newCompletionStatus =
            stepCompletionStatus[currentStepName as SIGNUP_STEPS_TYPE] ?? false
        setStepCompletion(currentStepName, newCompletionStatus)
    }, [currentStepName, stepCompletionStatus, setStepCompletion])

    const onSubmit = useCallback(
        async (challengeId: string) => {
            changeCommunityChallengeTeam({
                variables: {
                    challengeId,
                    socialGroupId: formData?.socialGroup?.id
                }
            })
        },
        [changeCommunityChallengeTeam, formData?.socialGroup?.id]
    )

    return {
        ...rest,
        formData,
        updateFormData,
        stepCompletionStatus,
        onSubmit,
        loading
    }
}

export default useChangeChallengeParticipation
