// External libraries
import { Grid } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import { memo, useMemo } from 'react'

// Internal components
import ChallengePersonalGarden from './Shared/ChallengePersonalGarden'
import UserWaterTotal from './Shared/WaterTotal/UserWaterTotal'
import YourPlantGoal from './Shared/PlantGoal/YourPlantGoal'
import YourTotalPlantsEarned from './Shared/PlantsEarned/YourTotalPlantsEarned'
import CardActivityPoints from './Shared/ActivityPoints/CardActivityPoints'

// Types and hooks
import { type UnifiedChallenge } from '../../../graphql/generated/autogenerated'
import useChallengeTheme from '../../../hooks/useChallengeTheme/useChallengeTheme'
import useGoalChallengeData from '../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'
import useGetSocialGroups from '../../hooks/useGetSocialGroups/useGetSocialGroups'

export type YourProgressContainerProps = {
    hideTitle?: boolean
    hideTotals?: boolean
    challenge: UnifiedChallenge
}

const YourProgressContainer: React.FC<YourProgressContainerProps> = ({
    hideTitle,
    hideTotals,
    challenge
}) => {
    const { deviceEnabled, hydrationEnabled } = useChallengeTheme(challenge)
    const { socialGroup } = useGetSocialGroups(challenge?.id)

    const {
        plants,
        error,
        thresholdValue,
        thresholdAchieved,
        userTotalSteps,
        userTotalWater,
        loading
    } = useGoalChallengeData({
        challenge,
        socialGroup,
        loading: false
    })

    const { gardenOrder, gardenSize, statsOrder, statsSize, statsItemSize } =
        useMemo(() => {
            if (deviceEnabled || hydrationEnabled) {
                return {
                    gardenOrder: 2,
                    gardenSize: 12,
                    statsOrder: 1,
                    statsSize: 12,
                    statsItemSize: 4
                }
            }

            return {
                gardenOrder: 1,
                gardenSize: hideTotals ? 12 : 9,
                statsOrder: 2,
                statsSize: 3,
                statsItemSize: 12
            }
        }, [deviceEnabled, hydrationEnabled, hideTotals])

    return (
        <Grid container>
            {!hideTitle && (
                <Grid item xs={12}>
                    <CoreTypography variant="body2" component="p" fontSize={24}>
                        <FormattedMessage
                            defaultMessage="Your progress"
                            description="title for the daily goals card"
                        />
                    </CoreTypography>
                </Grid>
            )}
            <Grid item xs={12} md={gardenSize} order={gardenOrder}>
                <ChallengePersonalGarden challenge={challenge} />
            </Grid>
            {!hideTotals && (
                <Grid item xs={12} md={statsSize} order={statsOrder}>
                    <Grid container>
                        <Grid item xs={12} md={statsItemSize}>
                            <YourTotalPlantsEarned
                                plants={plants}
                                error={error}
                            />
                        </Grid>
                        <Grid item xs={12} md={statsItemSize}>
                            <YourPlantGoal
                                thresholdValue={thresholdValue}
                                thresholdAchieved={thresholdAchieved}
                                error={error}
                                size={'small'}
                            />
                        </Grid>
                        {deviceEnabled && (
                            <Grid item xs={12} md={statsItemSize}>
                                <CardActivityPoints
                                    totalSteps={userTotalSteps}
                                    loading={loading}
                                    error={error}
                                />
                            </Grid>
                        )}
                        {hydrationEnabled && (
                            <Grid item xs={12} md={statsItemSize}>
                                <UserWaterTotal
                                    userTotalWater={userTotalWater}
                                    loading={loading}
                                    error={error}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default memo(YourProgressContainer)
