import { Button, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { ReactNode, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import DoubleActionHeader from '../DoubleActionHeader'

const messages = defineMessages({
    ariaNavigate: {
        defaultMessage: 'Navigate back',
        description: 'aria button text describing navigating back one page'
    }
})

export type BackActionHeaderProps = {
    isContrastText?: boolean
    rightAction?: ReactNode | ReactNode[]
    hideBackButton?: boolean
    onBack: () => void
}

const BackActionHeader: React.FC<BackActionHeaderProps> = ({
    isContrastText,
    rightAction,
    hideBackButton,
    onBack
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage } = useIntl()

    const textButton = useMemo(() => {
        return (
            <Button
                variant="text"
                onClick={onBack}
                data-testid="back-action"
                aria-label={formatMessage(messages.ariaNavigate)}
                sx={{
                    width: 'fit-content'
                }}
                startIcon={
                    <LeafIcon
                        icon="arrow-left"
                        fontSize="small"
                        sx={{
                            color: isContrastText
                                ? 'primary.contrastText'
                                : 'primary.main'
                        }}
                    />
                }
            >
                <CoreTypography
                    customVariant="buttonNormal"
                    color={isContrastText ? 'primary.contrastText' : 'primary'}
                >
                    <FormattedMessage
                        defaultMessage="Back"
                        description="button text that will navigate the user back to the previous page"
                    />
                </CoreTypography>
            </Button>
        )
    }, [formatMessage, isContrastText, onBack])

    const iconButton = useMemo(() => {
        return (
            <IconButton
                variant="contained"
                color="secondary"
                onClick={onBack}
                data-testid="back-action"
                aria-label={formatMessage(messages.ariaNavigate)}
            >
                <LeafIcon icon="chevron-left" fontSize="small" />
            </IconButton>
        )
    }, [formatMessage, onBack])

    return (
        <DoubleActionHeader
            leftAction={!hideBackButton && isMobile ? iconButton : textButton}
            rightAction={rightAction}
        />
    )
}

export default React.memo(BackActionHeader)
