import React, { useCallback, useState } from 'react'
import {
    Fade,
    IconButton,
    Slider,
    Stack,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import clamp from 'lodash/clamp'
import { VolumeFullIcon, VolumeMutedIcon } from '../../icons/CFPlayer'
import { volumeChangeStep, volumeLevelMax, volumeLevelMin } from '..'

const messages = defineMessages({
    unmuteAriaLabel: {
        defaultMessage: 'Unmute',
        description:
            'Unmute volume button in personalized reset player aria label'
    },
    muteAriaLabel: {
        defaultMessage: 'Mute',
        description:
            'Mute volume button in personalized reset player aria label'
    },
    sliderAriaLabel: {
        defaultMessage: 'Volume level',
        description: 'Volume level slider aria label'
    }
})

export type VolumeControlProps = {
    onVolumeChange: (volume: number) => void
    volume: number
}

function VolumeControl(props: VolumeControlProps) {
    const { onVolumeChange, volume } = props

    const theme = useTheme()
    const intl = useIntl()

    const canHoverElements = useMediaQuery('(hover: none)')

    const [hasMouseOverVolumeControl, setHasMouseOverVolumeControl] =
        useState(false)
    const handleVolumeControlMouseEnter = useCallback(() => {
        setHasMouseOverVolumeControl(true)
    }, [])
    const handleVolumeControlMouseLeave = useCallback(() => {
        setHasMouseOverVolumeControl(false)
    }, [])

    const volumeValue = clamp(volume, volumeLevelMin, volumeLevelMax)
    const muted = volumeValue === volumeLevelMin

    const handleVolumeChange = useCallback(
        (_event: Event, newVolume: number | number[], _activeThumb: number) => {
            onVolumeChange(newVolume as number)
        },
        [onVolumeChange]
    )

    const handleVolumeIconClick = useCallback(() => {
        const volumeLevelToSet = muted ? volumeLevelMax : volumeLevelMin

        onVolumeChange(volumeLevelToSet)
    }, [onVolumeChange, muted])

    const [isSliderFocussed, setSliderFocussed] = useState(false)
    const handleSliderFocus = useCallback(() => {
        setSliderFocussed(true)
    }, [])
    const handleSliderBlur = useCallback(() => {
        setSliderFocussed(false)
    }, [])

    const isSliderVisible =
        (hasMouseOverVolumeControl || isSliderFocussed) && !canHoverElements

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            onMouseEnter={handleVolumeControlMouseEnter}
            onMouseLeave={handleVolumeControlMouseLeave}
            data-testid="VolumeControl"
        >
            <IconButton
                onClick={handleVolumeIconClick}
                sx={{
                    p: theme.spacing(1.5),
                    color: theme.palette.primary.contrastText,
                    height: '2.4rem',
                    width: '2.4rem'
                }}
                aria-label={
                    muted
                        ? intl.formatMessage(messages.unmuteAriaLabel)
                        : intl.formatMessage(messages.muteAriaLabel)
                }
                aria-pressed={muted}
            >
                {muted ? (
                    <VolumeMutedIcon
                        sx={{ height: '2.4rem', width: '2.4rem' }}
                    />
                ) : (
                    <VolumeFullIcon
                        sx={{ height: '2.4rem', width: '2.4rem' }}
                    />
                )}
            </IconButton>
            <Fade in={isSliderVisible}>
                <Slider
                    value={volumeValue}
                    onChange={handleVolumeChange}
                    min={volumeLevelMin}
                    max={volumeLevelMax}
                    step={volumeChangeStep}
                    onFocus={handleSliderFocus}
                    onBlur={handleSliderBlur}
                    sx={{
                        color: theme.palette.primary.contrastText,
                        minWidth: '8rem'
                    }}
                    style={{ visibility: 'visible' }}
                    data-testid="VolumeSlider"
                    aria-label={intl.formatMessage(messages.sliderAriaLabel)}
                />
            </Fade>
        </Stack>
    )
}

export default React.memo(VolumeControl)
