import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useEditDisplayName } from '../../../../../hooks/useEditDisplayName/useEditDisplayName'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import DisplayNameWithValidation from '../../../../elements/DisplayNameWithValidation/DisplayNameWithValidation'

const DISPLAY_NAME = 'displayName'

const CreateDisplayName: React.FC = () => {
    const { displayName: savedDisplayName } =
        useCompanyChallengeProviderContext()
    const { displayName, setDisplayName } = useChallengeSignUpModalContext()
    const { addMutation, addDisable } =
        useCompanyChallengeSignUpProviderContext()
    const { editDisplayName, invalidText } = useEditDisplayName({
        savedDisplayName,
        displayName
    })

    // Pass the mutation off to the provider so we can trigger it from elsewhere
    useEffect(() => {
        addMutation({ key: DISPLAY_NAME, mutation: editDisplayName })
    }, [addMutation, editDisplayName, displayName])

    return (
        <Stack gap={3} maxWidth={544} width="100%">
            <Stack gap={2}>
                <Stack gap={1}>
                    <CoreTypography variant="h4">
                        <FormattedMessage
                            defaultMessage="Create a display name"
                            description="Title for the create a display name card inside the company sign up flow"
                        />
                    </CoreTypography>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            defaultMessage="This name will appear in the community activity feed, and leaderboards."
                            description="description of what the display name you enter is for"
                        />
                    </CoreTypography>
                </Stack>
                <DisplayNameWithValidation
                    displayName={displayName}
                    altInvalidDisplayNameText={invalidText}
                    setDisplayName={setDisplayName}
                    onValidate={(valid) => {
                        addDisable(DISPLAY_NAME, !valid)
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default memo(CreateDisplayName)
