import { Box, Button, DialogActions } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import React, { useMemo } from 'react'
import { useMultiStepFormContext } from '../../../../contexts/withMultiStepForm/withMultiStepForm'

type MultiStepFormNavigationProps = {
    goToPreviousStep?: (currentStepIndex: number) => void
    goToNextStep?: (currentStepIndex: number) => void
    onSubmit?: () => void
    onSubmitButtonLabel?: string | React.ReactNode
    loading: boolean
    startActions?: React.ReactNode
}

const MultiStepFormNavigation: React.FC<MultiStepFormNavigationProps> = ({
    startActions,
    goToPreviousStep: customGoToPreviousStep,
    goToNextStep: customGoToNextStep,
    onSubmit,
    onSubmitButtonLabel,
    loading
}) => {
    const {
        currentStepIndex,
        currentStepName,
        totalSteps,
        goToPreviousStep,
        goToNextStep,
        isStepComplete,
        isStepRequired
    } = useMultiStepFormContext()

    const isLastStep = useMemo(
        () => currentStepIndex === totalSteps - 1,
        [currentStepIndex, totalSteps]
    )
    const isFirstStep = useMemo(
        () => currentStepIndex === 0,
        [currentStepIndex]
    )

    const canGoNext =
        !isStepRequired(currentStepName) || isStepComplete(currentStepName)

    return (
        <DialogActions>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Box>{startActions}</Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={
                            customGoToPreviousStep
                                ? () => customGoToPreviousStep(currentStepIndex)
                                : goToPreviousStep
                        }
                        disabled={isFirstStep}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Back"
                                description="Back button text"
                            />
                        </CoreTypography>
                    </Button>
                    {!isLastStep && (
                        <Button
                            variant="contained"
                            onClick={
                                customGoToNextStep
                                    ? () => customGoToNextStep(currentStepIndex)
                                    : goToNextStep
                            }
                            disabled={!canGoNext || loading}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Next"
                                    description="Next button text"
                                />
                            </CoreTypography>
                        </Button>
                    )}
                    {isLastStep && (
                        <LoadingButton
                            disabled={!canGoNext}
                            loading={loading}
                            fixWidth
                            variant="contained"
                            onClick={onSubmit}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {onSubmitButtonLabel ? (
                                    onSubmitButtonLabel
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Submit"
                                        description="Submit button text"
                                    />
                                )}
                            </CoreTypography>
                        </LoadingButton>
                    )}
                </Box>
            </Box>
        </DialogActions>
    )
}

export default MultiStepFormNavigation
