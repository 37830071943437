// External libraries
import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import { memo } from 'react'

// Internal components
import DirtGroup from '../../../../../shared/components/icons/plants/dirtGroup'

export type GardenEmptyStateProps = {
    hideEmptyStateText?: boolean
}

const GardenEmptyState: React.FC<GardenEmptyStateProps> = ({
    hideEmptyStateText
}) => (
    <Stack
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={2}
        pb={2}
    >
        <DirtGroup />
        {!hideEmptyStateText && (
            <CoreTypography variant="body2" fontSize={18}>
                <FormattedMessage
                    defaultMessage="Your garden is empty! Complete daily goals to grow plants."
                    description="garden empty description"
                />
            </CoreTypography>
        )}
    </Stack>
)

export default memo(GardenEmptyState)
