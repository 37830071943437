import { Box, Button, DialogTitle, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import { useInterstitialNavigation } from '../../../../contexts/withInterstitialNavigation'

export type ErrorScreenProps = {
    navigateBackToOnError?: () => void
    close: () => void
}

const messages = defineMessages({
    whoops: {
        defaultMessage: 'Whoops!',
        description: 'error screen title'
    },
    emptyStoryDescription: {
        defaultMessage:
            'You didn’t add anything in your story. We know writing is not easy but it is a huge accomplishment for your journey. Please go back and fill in your story.',
        description:
            'error screen description if the specific error was that a user tried to submit nothing'
    },
    close: {
        defaultMessage: 'Close',
        description: 'button text that will close the current popup'
    },
    goBack: {
        defaultMessage: 'Go back',
        description:
            'button text that will navigate the user back a step in the current process'
    },
    backToPreviousPage: {
        defaultMessage: 'Go back to the previous page',
        description: 'aria label describing that the link will go back a page'
    },
    closeModal: {
        defaultMessage: 'Close story submission',
        description: 'aria label describing that the close story submission'
    }
})

const ErrorScreen: React.FC<ErrorScreenProps> = ({
    navigateBackToOnError,
    close
}) => {
    const theme = useTheme()
    const intl = useIntl()

    const { navigateToKey } = useInterstitialNavigation()
    const { errorDescription } = useStorySubmissionStateProvider()

    return (
        <Box
            data-testid="error-screen"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: `calc(100vh - ${theme.spacing(3)})`
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    maxWidth: theme.spacing(62.75),
                    m: theme.spacing(2),
                    my: 'auto'
                }}
            >
                <DialogTitle
                    sx={{
                        p: 0
                    }}
                >
                    <CoreTypography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(2)
                        }}
                    >
                        {intl.formatMessage(messages.whoops)}
                    </CoreTypography>
                </DialogTitle>
                <CoreTypography
                    id="empty-description"
                    variant="body1"
                    sx={{
                        color: theme.palette.primary.main,
                        mb: theme.spacing(6)
                    }}
                    component="output"
                    aria-live="polite"
                    aria-atomic="true"
                >
                    {errorDescription
                        ? errorDescription
                        : intl.formatMessage(messages.emptyStoryDescription)}
                </CoreTypography>
                <Stack direction="row" justifyContent="flex-end" gap={1}>
                    <Button
                        variant="text"
                        sx={{
                            alignItems: 'center',
                            p: `${theme.spacing(2)} ${theme.spacing(4)}`,
                            borderRadius: theme.spacing(1)
                        }}
                        data-testid="close-button"
                        onClick={close}
                        aria-label={intl.formatMessage(messages.closeModal)}
                        aria-describedby="empty-description"
                    >
                        <CoreTypography
                            customVariant="buttonNormal"
                            sx={{ textAlign: 'center' }}
                        >
                            {intl.formatMessage(messages.close)}
                        </CoreTypography>
                    </Button>
                    {navigateBackToOnError && (
                        <Button
                            variant="contained"
                            sx={{
                                alignItems: 'center',
                                p: `${theme.spacing(2)} ${theme.spacing(6)}`,
                                borderRadius: theme.spacing(1)
                            }}
                            data-testid="go-back-button"
                            onClick={navigateBackToOnError}
                            aria-label={intl.formatMessage(
                                messages.backToPreviousPage
                            )}
                            aria-describedby="empty-description"
                        >
                            <CoreTypography
                                customVariant="buttonNormal"
                                sx={{ textAlign: 'center' }}
                            >
                                {intl.formatMessage(messages.goBack)}
                            </CoreTypography>
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}

export default ErrorScreen
