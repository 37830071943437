import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ChallengePlantCard from '../../ChallengePlantCard'
import { UserPlantInfo } from '../../../../../../graphql/generated/autogenerated'

interface CompletedDailyGoalsProps {
    userPlant?: UserPlantInfo
}

const CompletedDailyGoals: React.FC<CompletedDailyGoalsProps> = ({
    userPlant
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <Stack
            data-testid="completed-daily-goals"
            direction={isMobile ? 'column' : 'row'}
            gap={2}
        >
            <Stack direction="row" gap={2} alignItems="center">
                <Box minWidth="48px" height="fit-content">
                    <ChallengePlantCard plant={userPlant} />
                </Box>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="Great job! You completed your daily goals and added new plants to your garden."
                        description="completed daily goals descriptions"
                    />
                </CoreTypography>
            </Stack>
        </Stack>
    )
}

export default memo(CompletedDailyGoals)
