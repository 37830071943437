import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
    isMicrosoftTeamsTest,
    useSetPageTitle
} from '@thriveglobal/thrive-web-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useSyncAppleHealthData } from '@thriveglobal/thrive-web-wearables-core'
import React, { Suspense } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    BrowserRouter,
    Redirect,
    RouteComponentProps,
    Switch
} from 'react-router-dom'
import ScrollToTop from '../components/elements/ScrollToTop/ScrollToTop'
import { LazyHome } from '../pages/Home/index'
import { LazyPastChallenges } from '../pages/PastChallenges'

const CompanyChallengeWithProvider = React.lazy(() =>
    import('../pages/CompanyChallenge').then((module) => ({
        default: module.CompanyChallengeWithProvider
    }))
)

const InviteCompanyChallengeWithProvider = React.lazy(() =>
    import('../pages/CompanyChallenge').then((module) => ({
        default: module.InviteCompanyChallengeWithProvider
    }))
)

const TemplateGroupChallengeWithProvider = React.lazy(() =>
    import('../pages/Challenge').then((module) => ({
        default: module.TemplateGroupChallengeWithProvider
    }))
)

const GroupChallengeWithProvider = React.lazy(() =>
    import('../pages/Challenge').then((module) => ({
        default: module.CurrentGroupChallengeWithProvider
    }))
)

const PastGroupChallengeWithProvider = React.lazy(() =>
    import('../pages/Challenge').then((module) => ({
        default: module.PastGroupChallengeWithProvider
    }))
)

const PreviousSingleChallenge = React.lazy(() =>
    import('../pages/SingleChallenge').then((module) => ({
        default: module.PreviousSingleChallenge
    }))
)

const SingleChallengeWithChallengeProvider = React.lazy(() =>
    import('../pages/SingleChallenge').then((module) => ({
        default: module.SingleChallengeWithChallengeProvider
    }))
)

const SocialGroupsWithProvider = React.lazy(() =>
    import('../shared/modules/social/components/SocialGroups').then(
        (module) => ({ default: module.SocialGroupsWithProvider })
    )
)

const SocialGroupSettingsWithContext = React.lazy(() =>
    import('../shared/modules/social/components/SocialGroupSettings').then(
        (module) => ({ default: module.SocialGroupSettingsWithContext })
    )
)

const SingleSocialGroupWithProvider = React.lazy(() =>
    import('../shared/modules/social/components/SingleSocialGroup').then(
        (module) => ({
            default: module.SingleSocialGroupWithProvider
        })
    )
)

const ChallengeV2 = React.lazy(() => import('../v2/pages/Challenge/Challenge'))

export enum ROUTES {
    // Internal
    HOME = '/challenges',
    PAST_CHALLENGES = '/challenges/past',
    SOCIAL_GROUPS = '/challenges/social',
    SOCIAL_GROUPS_SETTINGS = '/challenges/social/settings',
    SINGLE_SOCIAL_GROUP = '/challenges/social/:socialGroupId',
    SINGLE_SOCIAL_GROUP_SETTINGS = '/challenges/social/:socialGroupId/settings',
    // New V2 all-encompassing challenge route
    CHALLENGE_V2 = '/challenges/v2/:challengeId/:socialGroupId?',
    // External
    ARTICLE_PAGE = '/learn/articles/:articleId',
    BOOK_PAGE = '/learn/books/:bookId',
    // TODO: Deprecate all below routes on V2 release
    CHALLENGE = '/challenges/:challengeId',
    SINGLE_PAST_CHALLENGES = '/challenges/past/:challengeId/:userChallengeActivityId',
    SINGLE_PAST_CHALLENGES_STORY_SUBMISSION = '/challenges/past/:challengeId/:userChallengeActivityId/storySubmission',
    COMPANY_CHALLENGE_INTRO = '/challenges/company/:challengeId/intro',
    COMPANY_CHALLENGE_HOME = '/challenges/company/:challengeId/:socialGroupId?',
    COMPANY_CHALLENGE_INVITE = '/challenges/company/invite/:challengeId',
    GROUP_COMPANY_CHALLENGE_INVITE = '/challenges/company/invite/:challengeId/:socialGroupId',
    PAST_GROUP_CHALLENGE_HOME = '/challenges/g/past/:challengeId',
    GROUP_CHALLENGE_HOME = '/challenges/g/:challengeId',
    GROUP_CHALLENGE_TEMPLATE = '/challenges/t/:challengeId'
}

const messages = defineMessages({
    pageTitle: {
        defaultMessage: 'Thrive - Challenges',
        description: 'Thrive Challenges Page Title'
    }
})

const Router: React.FC = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const PAGE_TITLE = formatMessage(messages.pageTitle)
    useSetPageTitle(PAGE_TITLE)
    useSyncAppleHealthData()

    type RouteType = React.ComponentType<{}> &
        (
            | React.ComponentType<any>
            | React.ComponentType<RouteComponentProps<any, any, unknown>>
            | undefined
        )

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <ScrollToTop>
                        <Switch>
                            <ProtectedRoute
                                path={ROUTES.CHALLENGE_V2}
                                component={ChallengeV2}
                            />
                            <ProtectedRoute
                                path={ROUTES.GROUP_COMPANY_CHALLENGE_INVITE}
                                component={InviteCompanyChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.COMPANY_CHALLENGE_INVITE}
                                component={InviteCompanyChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.PAST_GROUP_CHALLENGE_HOME}
                                component={PastGroupChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.GROUP_CHALLENGE_HOME}
                                component={GroupChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.GROUP_CHALLENGE_TEMPLATE}
                                component={TemplateGroupChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.COMPANY_CHALLENGE_INTRO}
                                component={CompanyChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.COMPANY_CHALLENGE_HOME}
                                component={CompanyChallengeWithProvider}
                            />
                            <ProtectedRoute
                                path={ROUTES.SINGLE_SOCIAL_GROUP_SETTINGS}
                                component={
                                    SocialGroupSettingsWithContext as RouteType
                                }
                            />
                            <ProtectedRoute
                                path={ROUTES.SOCIAL_GROUPS_SETTINGS}
                                component={
                                    SocialGroupSettingsWithContext as RouteType
                                }
                            />
                            <ProtectedRoute
                                path={ROUTES.SINGLE_SOCIAL_GROUP}
                                component={
                                    SingleSocialGroupWithProvider as RouteType
                                }
                            />
                            <ProtectedRoute
                                path={ROUTES.SOCIAL_GROUPS}
                                component={
                                    SocialGroupsWithProvider as RouteType
                                }
                            />
                            <ProtectedRoute
                                path={
                                    ROUTES.SINGLE_PAST_CHALLENGES_STORY_SUBMISSION
                                }
                                component={PreviousSingleChallenge as RouteType}
                            />
                            <ProtectedRoute
                                path={ROUTES.SINGLE_PAST_CHALLENGES}
                                component={PreviousSingleChallenge as RouteType}
                            />
                            <ProtectedRoute
                                path={ROUTES.PAST_CHALLENGES}
                                component={LazyPastChallenges}
                            />
                            <ProtectedRoute
                                path={ROUTES.CHALLENGE}
                                component={
                                    SingleChallengeWithChallengeProvider as RouteType
                                }
                            />
                            <ProtectedRoute
                                path={ROUTES.HOME}
                                component={LazyHome}
                            />
                            {isMicrosoftTeamsTest() && (
                                <Redirect to={ROUTES.HOME} />
                            )}
                        </Switch>
                    </ScrollToTop>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
