import { Box, DialogTitle, Stack } from '@mui/material'
import {
    BurstNotification,
    CoreTypography,
    LeafIcon,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    UnifiedChallenge,
    Visibility
} from '../../../../../graphql/generated/autogenerated'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'

export type SubmissionSuccessProps = {
    active: boolean
    challenge?: UnifiedChallenge
    close: () => void
}

const messages = defineMessages({
    storySubmitted: {
        defaultMessage: 'Story submitted',
        description: 'story submitted successfully title'
    },
    nextChallenge: {
        defaultMessage: 'Now, onto your next Challenge!',
        description: 'story submitted successfully description'
    },
    backToChallenge: {
        defaultMessage: 'Back to Challenges',
        description: 'link text that takes the user back to the challenges list'
    },
    shareChallenge: {
        defaultMessage:
            'Thank you for sharing your Challenge story! Thrive may reach out to you about being a Thrive Role Model for your peers. Please note that your story will not be published automatically.',
        description:
            'Description on submitting a story publicly that thrive may reach out to the user to present their story to other members'
    },
    privateChallenge: {
        defaultMessage:
            'Thank you for reflecting on your Challenge! Your story has been saved for your eyes only. If you ever decide to share your story with Thrive, know we’re always ready to hear it.',
        description:
            'Description on submitting a story privately that at any point they can re-submit this publicly'
    }
})

const SubmissionSuccess: React.FC<SubmissionSuccessProps> = ({
    active,
    challenge,
    close
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const showWalmartPrizeText = useShowWalmartBetterChoicesChallenge(challenge)
    const { visibility } = useStorySubmissionStateProvider()

    const onBackToChallenge = useCallback(() => {
        setLoading(true)
        close()
    }, [close])

    return (
        <Box
            data-testid="submission-success"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: `calc(100vh - ${theme.spacing(3)})`
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    maxWidth: theme.spacing(62.75),
                    m: theme.spacing(2),
                    my: 'auto'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        mb: theme.spacing(2)
                    }}
                    aria-live="polite"
                    aria-atomic="true"
                    component="output"
                >
                    <BurstNotification show={active} alwaysVisible={true}>
                        <LeafIcon icon={'check'} fontSize={'large'} />
                    </BurstNotification>
                </Box>
                <DialogTitle sx={{ p: 0 }}>
                    <CoreTypography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(2)
                        }}
                        component="output"
                        aria-live="polite"
                        aria-atomic="true"
                    >
                        {intl.formatMessage(messages.storySubmitted)}
                    </CoreTypography>
                </DialogTitle>
                {!showWalmartPrizeText && (
                    <CoreTypography
                        variant="body1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(3)
                        }}
                    >
                        {visibility === Visibility.Public
                            ? intl.formatMessage(messages.shareChallenge)
                            : intl.formatMessage(messages.privateChallenge)}
                    </CoreTypography>
                )}
                <CoreTypography
                    variant="body1"
                    sx={{
                        color: theme.palette.primary.main,
                        mb: theme.spacing(6)
                    }}
                >
                    {intl.formatMessage(messages.nextChallenge)}
                </CoreTypography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        fixWidth={true}
                        sx={{
                            alignItems: 'center',
                            p: `${theme.spacing(2)} ${theme.spacing(6)}`,
                            borderRadius: theme.spacing(1)
                        }}
                        data-testid="back-to-challenge-button"
                        onClick={onBackToChallenge}
                    >
                        <CoreTypography
                            customVariant="buttonNormal"
                            sx={{ textAlign: 'center' }}
                        >
                            {intl.formatMessage(messages.backToChallenge)}
                        </CoreTypography>
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}

export default SubmissionSuccess
