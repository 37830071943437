import { Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'

const ChallengeHeaderSkeleton: React.FC = () => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    return (
        <Card elevation={0}>
            <Stack
                p={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={isMobile ? 1 : 3}
            >
                <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    flex={1}
                    gap={3}
                    width="100%"
                >
                    <Skeleton variant="rounded" height={38} width="100%" />
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Skeleton variant="circular" height={20} width={20} />
                        <Skeleton variant="rounded" height={20} width={93} />
                    </Stack>
                </Stack>
                <Stack
                    gap={1}
                    direction="row"
                    alignItems={isMobile ? 'start' : 'center'}
                    justifyContent="end"
                    flex={1}
                >
                    <Skeleton variant="rounded" height={44} width={73} />
                    <Skeleton variant="circular" height={44} width={44} />
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeHeaderSkeleton)
