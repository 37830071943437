import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../../../graphql/generated/autogenerated'

import { SIGNUP_STEPS } from '../../../../../constants'
import MultiStepFormContainer from '../../../Shared/MultiStepForm/MultiStepFormContainer'
import MultiStepFormItem from '../../../Shared/MultiStepForm/MultiStepFormItem'
import MultiStepFormNavigation from '../../../Shared/MultiStepForm/MultiStepFormNavigation'
import { ChallengeTemplateForm } from '../../../../../hooks/useChallengeTemplateSignup/useChallengeTemplateSignup'
import ConfirmStep from '../Steps/ConfirmStep'
import DetailsStep from '../Steps/DetailsStep'
import TermsStep from '../Steps/TermsStep'

type IntroTemplateChallengeDialogProps = {
    open: boolean
    challenge: UnifiedChallenge
    groupChallengeType?: GroupChallengeType
    formData: ChallengeTemplateForm
    updateFormData: (data: Partial<ChallengeTemplateForm>) => void
    setIsDisplayNameValid: (value: boolean) => void
    shouldDisplayStartDate?: boolean
    onClose: () => void
    onSubmit: () => void
    dialogTitle: React.ReactElement
    loading: boolean
    onSubmitButtonLabel: React.ReactElement
}

const IntroTemplateChallengeDialog: React.FC<
    IntroTemplateChallengeDialogProps
> = ({
    open,
    dialogTitle,
    challenge,
    groupChallengeType,
    formData,
    updateFormData,
    setIsDisplayNameValid,
    shouldDisplayStartDate = true,
    onClose,
    onSubmit,
    onSubmitButtonLabel,
    loading
}) => {
    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            dialogTitle={dialogTitle}
        >
            <MultiStepFormContainer>
                <MultiStepFormItem name={SIGNUP_STEPS.DETAILS} required>
                    <CoreTypography variant="h4">
                        {challenge.name}
                    </CoreTypography>
                    <DetailsStep
                        displayName={formData.details.displayName}
                        startDate={formData.details.startDate}
                        setDisplayName={(value) => {
                            updateFormData({
                                details: {
                                    displayName: value
                                }
                            })
                        }}
                        setStartDate={(value) => {
                            updateFormData({
                                details: {
                                    startDate: value
                                }
                            })
                        }}
                        shouldDisplayStartDate={shouldDisplayStartDate}
                        onValidate={setIsDisplayNameValid}
                    />
                </MultiStepFormItem>
                <MultiStepFormItem name={SIGNUP_STEPS.CONFIRM}>
                    <ConfirmStep
                        challengeName={challenge.name}
                        displayName={formData.details.displayName}
                        startDate={formData.details.startDate}
                        shouldDisplayStartDate={shouldDisplayStartDate}
                        groupChallengeType={groupChallengeType}
                    />
                </MultiStepFormItem>
                <MultiStepFormItem name={SIGNUP_STEPS.TERMS} required>
                    <TermsStep
                        confirmed={formData.terms.confirmed}
                        setConfirmed={(value) => {
                            updateFormData({
                                terms: {
                                    confirmed: value
                                }
                            })
                        }}
                        deviceEnabled={challenge.device_enabled}
                        isChallengeTemplate={true}
                        isTeamSignUp={false}
                    />
                </MultiStepFormItem>
            </MultiStepFormContainer>
            <MultiStepFormNavigation
                loading={loading}
                onSubmit={onSubmit}
                onSubmitButtonLabel={onSubmitButtonLabel}
            />
        </LeafDialog>
    )
}

export default IntroTemplateChallengeDialog
