import { Box } from '@mui/material'
import React from 'react'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import JoinAGroup from './JoinAGroup/JoinAGroup'
import YourGroups from './YourGroups/YourGroups'

export type SocialGroupsCardProps = {
    socialGroups: SocialGroup[]
}

const SocialGroupsCard: React.FC<SocialGroupsCardProps> = ({
    socialGroups
}) => (
    <Box data-testid="social-groups-card">
        {(!socialGroups || socialGroups?.length <= 0) && <JoinAGroup />}
        {socialGroups?.length > 0 && <YourGroups socialGroups={socialGroups} />}
    </Box>
)

export default SocialGroupsCard
