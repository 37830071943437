import { Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import type { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'

import Image from '../../../elements/Image'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetHeader
} from '../RecapWidget'

type ChallengePrizeProps = {
    challenge: UnifiedChallenge
}

const ChallengePrize = ({ challenge }: ChallengePrizeProps) => {
    const { plantedPlants } = useAppSelector((state) => state.garden)

    const gardenPrize = useMemo(
        () => plantedPlants.find((plant) => plant.challengeId === challenge.id),
        [plantedPlants, challenge]
    )

    if (!gardenPrize) return ReactNullValue

    return (
        <RecapWidget>
            <RecapWidgetHeader
                title={
                    <FormattedMessage
                        defaultMessage="your challenge prize"
                        description="total plants earned title"
                    />
                }
            />
            <RecapWidgetContent>
                <Stack>
                    <CoreTypography variant="body2">
                        <FormattedMessage
                            defaultMessage="You met your plant goal for the Challenge, To commemorate this, we've added a special prize to your personal garden!"
                            description="plant goal met description"
                        />
                    </CoreTypography>
                    <Image src={gardenPrize?.plantImage ?? ''} height={150} />
                </Stack>
            </RecapWidgetContent>
        </RecapWidget>
    )
}

export default memo(ChallengePrize)
