import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'
import { v4 as uuid } from 'uuid'

const CommunityCherryBlossomPlant: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#350B18',
    colorLayer2 = '#FF8BBB',
    colorLayer3 = '#E3005C',
    colorLayer4 = 'white',
    useSimple,
    base
}) => {
    const id = uuid()

    return (
        <BasePlant useSimple={useSimple} base={base}>
            <g>
                <g clipPath="url(#clip0_713_2)">
                    <path
                        d="M81.5701 29.0194C79.9577 29.0918 78.4228 29.2977 76.8658 29.5926C75.3198 29.8875 73.7849 30.2881 72.2833 30.8112C69.2911 31.8574 66.3544 33.421 64.0272 35.8249C62.8691 37.0213 61.8772 38.4236 61.1791 39.9706C61.107 40.1264 61.0405 40.2877 60.9796 40.4491L62.1155 43.4985C62.6253 42.2743 64.9082 38.4737 65.7947 37.4609C67.6621 35.3241 70.2276 33.7493 72.9704 32.6364C74.3501 32.08 75.7797 31.6292 77.237 31.2731C78.6888 30.9225 80.1959 30.6387 81.6532 30.5052L81.5812 29.0139L81.5701 29.0194Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M71.9453 23.0486C70.9257 25.9923 62.5865 24.7736 59.6552 23.7497C56.724 22.7258 55.1725 19.5039 56.1921 16.5603C57.2116 13.6166 60.4199 12.0585 63.3511 13.0824C66.2823 14.1063 72.9648 20.0993 71.9453 23.043V23.0486Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M72.3221 22.9429C69.3743 23.9167 65.2739 16.5269 64.2987 13.5665C63.329 10.6061 64.9304 7.41205 67.8782 6.43825C70.826 5.46444 74.0066 7.07261 74.9763 10.033C75.9459 12.9934 75.2699 21.9635 72.3166 22.9429H72.3221Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M72.3221 22.9429C72.3221 22.9429 74.7103 3.93974 81.2044 8.16884C87.6985 12.3979 72.3221 22.9429 72.3221 22.9429Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M72.915 22.8538C72.915 22.8538 58.6302 35.5523 56.5301 28.0679C54.4301 20.5835 72.915 22.8538 72.915 22.8538Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M79.6307 10.9567L72.5991 23.4715L71.2859 22.6312L79.6307 10.9567Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M69.208 10.7786L72.7044 22.8816L71.1862 23.2211L69.208 10.7786Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M60.636 17.7678C63.9718 18.9753 68.9254 21.0287 72.2778 22.3419L71.6129 23.7553C68.4378 22.0247 63.8111 19.3927 60.636 17.7678Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M60.4476 27.3278L71.6738 22.3196L72.2113 23.7831L60.4476 27.3278Z"
                        fill={colorLayer3}
                    />
                    <path
                        opacity="0.5"
                        d="M59.1843 23.5494C59.4835 23.3713 59.8049 23.21 60.1595 23.0764C57.7104 22.0136 56.4581 19.2257 57.3502 16.6549C58.2811 13.9672 61.2068 12.5482 63.8831 13.483C64.138 13.5721 64.4316 13.7112 64.7364 13.8781C63.8831 11.1849 65.3404 8.3024 68.0167 7.41763C70.7097 6.52729 73.6076 7.99635 74.4942 10.7007C74.6161 11.068 74.7047 11.5354 74.7712 12.0696C74.7712 12.0696 75.1259 11.8192 75.281 11.5299C75.2145 11.107 75.0815 10.3502 74.9818 10.0386C74.0121 7.07819 70.8316 5.46445 67.8837 6.44382C64.9525 7.41206 63.3511 10.5728 64.2876 13.522C63.9496 13.3439 63.6282 13.1881 63.3511 13.088C60.4199 12.0641 57.2117 13.6166 56.1921 16.5658C55.228 19.3481 56.5634 22.3642 59.1843 23.555V23.5494Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M54.2417 45.7744C54.02 44.9843 53.7651 44.4 53.5213 43.7489L52.7899 41.8291L51.3271 37.984L48.3959 30.3048L45.9301 31.2063L48.5843 38.9467L49.9086 42.8141L50.5735 44.7506C50.7896 45.3905 51.05 46.0694 51.1553 46.6091C51.7205 49.0297 51.7205 51.5728 51.183 53.9767C50.9115 55.1786 50.5015 56.3416 49.9806 57.4434L48.213 61.1383L46.0797 65.5956C46.0354 65.3285 46.0021 65.0558 45.9467 64.7943C45.2596 61.2329 43.8744 58.2336 42.273 55.7295C41.4696 54.4719 40.5941 53.3312 39.6798 52.285C39.4249 51.9957 39.17 51.7174 38.9151 51.4448C38.5384 51.9456 38.1837 52.4631 37.8402 52.9862C42.4115 59.1963 43.42 64.5661 43.8522 67.3317C43.8522 67.3373 43.8522 67.3484 43.8522 67.354C44.0739 68.8119 43.8134 70.3032 43.1762 71.6387L38.2114 82H43.9797L51.9255 62.8133L53.4992 59.0182C54.0699 57.6159 54.4966 56.158 54.7459 54.6778C55.2446 51.7063 55.0673 48.6513 54.2306 45.78L54.2417 45.7744Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M84.5512 14.9298C80.4231 17.1557 75.8407 19.5485 72.034 22.3252L70.6265 23.3992C70.1611 23.7664 69.7123 24.1504 69.2524 24.5288C68.8035 24.9183 68.3713 25.3356 67.928 25.7363C67.5125 26.1703 67.0858 26.5988 66.6868 27.0662C66.3156 27.5559 65.9 28.0234 65.6063 28.5742C65.4512 28.8413 65.2794 29.1029 65.1409 29.37L64.7419 30.1824C64.2211 31.2731 63.7944 32.3804 63.3788 33.4822C61.7609 37.9061 60.359 42.3355 58.3143 46.4644C56.3362 50.6101 53.7097 54.4051 50.435 57.5102L52.4519 59.0627C55.8929 55.6627 58.5692 51.5839 60.5252 47.2546C62.5421 42.9421 63.8775 38.3958 65.3792 34.0109C66.1549 31.8295 66.9362 29.6593 68.3104 27.9566C69.7178 26.2316 71.5186 24.7402 73.3306 23.3324C77.0043 20.5334 80.9661 18.0571 85 15.7812L84.5512 14.9354V14.9298Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M73.2475 37.2661C74.698 37.2661 75.8739 36.0852 75.8739 34.6285C75.8739 33.1718 74.698 31.9909 73.2475 31.9909C71.7969 31.9909 70.621 33.1718 70.621 34.6285C70.621 36.0852 71.7969 37.2661 73.2475 37.2661Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M78.5059 28.2237C79.9565 28.2237 81.1324 27.0428 81.1324 25.586C81.1324 24.1293 79.9565 22.9484 78.5059 22.9484C77.0554 22.9484 75.8795 24.1293 75.8795 25.586C75.8795 27.0428 77.0554 28.2237 78.5059 28.2237Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M52.4298 61.4833C51.7316 58.445 59.4336 55.0172 62.459 54.3161C65.4845 53.6149 68.5043 55.5069 69.2025 58.5452C69.9007 61.5835 68.0167 64.6162 64.9913 65.3173C61.9659 66.0184 53.1335 64.5216 52.4353 61.4833H52.4298Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M52.1638 61.7727C54.1419 59.3688 61.5337 63.4253 63.9274 65.4119C66.3211 67.3985 66.6591 70.9598 64.681 73.3582C62.7028 75.7621 59.1566 76.1015 56.7684 74.1149C54.3746 72.1284 50.1912 64.171 52.1693 61.7727H52.1638Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M52.1638 61.7726C52.1638 61.7726 60.226 79.1342 52.4852 79.0285C44.7443 78.9228 52.1638 61.7726 52.1638 61.7726Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M51.7094 62.1733C51.7094 62.1733 57.0565 43.7879 62.8081 48.9963C68.5542 54.2048 51.7094 62.1733 51.7094 62.1733Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M53.9092 61.9952C57.3336 61.3553 61.7609 60.5707 64.7973 59.9029C61.7498 60.1812 57.3945 60.4038 53.948 60.6096L59.888 51.7174L51.8147 61.0103L51.654 61.4777L52.3411 75.8288L53.1113 63.1304L61.2566 70.3978L53.9147 61.9952H53.9092Z"
                        fill={colorLayer3}
                    />
                    <path
                        opacity="0.5"
                        d="M62.9577 54.2326C62.7971 54.5387 62.6142 54.8503 62.387 55.1563C65.019 54.7446 67.5623 56.4362 68.1719 59.0849C68.8091 61.8561 67.0858 64.6273 64.3264 65.2672C64.0659 65.3284 63.739 65.3674 63.3955 65.3896C65.5454 67.2093 65.8446 70.4312 64.0493 72.6125C62.2429 74.8049 59.007 75.1166 56.8238 73.3025C56.5301 73.0577 56.2032 72.7127 55.8652 72.2953C55.9095 72.6403 55.9428 72.9742 55.9594 73.3025C56.242 73.6252 56.5135 73.9202 56.7629 74.1261C59.1566 76.1126 62.6973 75.7732 64.6755 73.3693C66.6425 70.9821 66.3212 67.4485 63.9607 65.4564C64.343 65.4286 64.6921 65.3896 64.9802 65.3229C68.0056 64.6217 69.8896 61.589 69.1914 58.5507C68.532 55.6794 65.8003 53.8431 62.9522 54.2382L62.9577 54.2326Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M35.8565 6.85559C36.527 2.43173 40.7437 -0.589848 45.2707 0.100163C49.8033 0.790174 52.934 4.9358 52.2635 9.35966C52.1195 10.3112 51.8036 11.1904 51.3659 11.9862C51.3659 11.9862 46.4233 22.6201 42.1844 26.8715L41.2479 26.7268L40.3115 26.5821C37.5188 21.2624 35.9285 9.63233 35.9285 9.63233C35.9285 9.63233 35.7124 7.80714 35.8565 6.85559Z"
                        fill="#FFDAE9"
                    />
                    <path
                        opacity="0.5"
                        d="M44.8551 2.86021C40.8545 2.2481 37.0976 4.54628 35.8177 8.13545C35.801 7.67915 35.8066 7.20616 35.8564 6.85559C36.5269 2.43173 40.7436 -0.589848 45.2707 0.100163C49.8033 0.790174 52.9339 4.9358 52.2635 9.35966C52.1914 9.83822 52.0751 10.2945 51.9255 10.7341C51.8257 6.86672 48.9056 3.48344 44.8551 2.86577V2.86021Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M37.6906 18.8585C39.1645 17.9626 40.8601 17.5563 42.5889 17.8179C44.3177 18.0794 45.8138 18.9753 46.9607 20.2663C45.4702 22.7648 43.7746 25.2633 42.1844 26.8604L41.2479 26.7157L40.3115 26.571C39.2642 24.5733 38.3832 21.6797 37.6961 18.8529L37.6906 18.8585Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M44.6557 4.17346L40.7825 26.6545L41.7189 26.7936L44.6557 4.17346Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M58.9851 14.3622C63.1353 13.5999 67.2357 17.0611 68.15 22.0859C69.0642 27.1107 66.4433 31.8017 62.2931 32.5585C61.401 32.7199 60.5144 32.6809 59.6611 32.4806C59.6611 32.4806 48.5624 30.7778 43.3704 27.7006L43.182 26.66L42.9936 25.6194C46.7726 20.9006 56.5526 15.3583 56.5526 15.3583C56.5526 15.3583 58.0985 14.5236 58.9906 14.3622H58.9851Z"
                        fill="#FFDAE9"
                    />
                    <path
                        d="M48.9555 20.2885C50.2466 21.5517 51.1775 23.2155 51.5266 25.1297C51.8757 27.044 51.5875 28.9304 50.8229 30.5719C48.1022 29.8207 45.3151 28.858 43.3646 27.7006L43.1762 26.66L42.9878 25.6194C44.4063 23.8443 46.6782 21.9524 48.9555 20.2885Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M64.3264 22.787L43.082 26.1425L43.2704 27.1775L64.3264 22.787Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M59.55 39.0969C61.8218 43.0644 60.3701 49.0186 56.303 52.4018C52.2358 55.7796 47.0993 55.3066 44.8275 51.339C44.3398 50.4876 44.0351 49.5416 43.8799 48.5511C43.8799 48.5511 41.0873 36.1309 41.7854 29.2141L42.6277 28.513L43.4699 27.8119C49.083 29.4812 57.7381 37.0268 57.7381 37.0268C57.7381 37.0268 59.0679 38.2399 59.5555 39.0913L59.55 39.0969Z"
                        fill="#FFDAE9"
                    />
                    <path
                        d="M50.4572 31.4734C49.8421 33.4321 48.745 35.2072 47.1935 36.4982C45.642 37.7892 43.8744 38.3957 42.1401 38.3902C41.7466 35.185 41.5195 31.8073 41.7799 29.2086L42.6221 28.5074L43.4644 27.8063C45.5755 28.4351 48.1133 29.893 50.4627 31.4678L50.4572 31.4734Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M54.2084 48.7403L43.0432 28.1624L42.201 28.858L54.2084 48.7403Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M26.6695 12.103C22.5358 10.5727 17.6486 13.1937 15.7536 17.957C13.8586 22.7203 15.6705 27.823 19.7986 29.3533C20.6851 29.6816 21.6105 29.8096 22.5303 29.7706C22.5303 29.7706 34.2939 30.1935 40.2339 28.1513L40.6273 27.1664L41.0208 26.1814C38.0231 20.8283 28.9967 13.5442 28.9967 13.5442C28.9967 13.5442 27.5616 12.4313 26.675 12.103H26.6695Z"
                        fill="#FFDAE9"
                    />
                    <path
                        d="M35.8842 19.8156C34.3106 20.8116 33.0361 22.2695 32.3102 24.0892C31.5844 25.9088 31.5234 27.8119 31.9999 29.5647C34.9422 29.3422 38.0009 28.9192 40.2339 28.1513L40.6274 27.1664L41.0208 26.1815C39.8959 24.1726 37.9178 21.8856 35.8842 19.8156Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M19.5603 19.3704L40.8213 26.6767L40.4279 27.6616L19.5603 19.3704Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M21.7989 34.0999C18.6571 36.9211 18.8566 42.2966 22.2477 46.1027C25.6388 49.9089 30.9305 50.7102 34.0778 47.889C34.7538 47.2824 35.2636 46.5535 35.6348 45.7522C35.6348 45.7522 41.1094 35.9084 41.8741 29.8986L41.1704 29.1084L40.4666 28.3183C34.438 28.4184 24.0762 32.77 24.0762 32.77C24.0762 32.77 22.4693 33.4822 21.7989 34.0888V34.0999Z"
                        fill="#FFDAE9"
                    />
                    <path
                        d="M32.615 29.7817C32.8145 31.5847 33.5514 33.3375 34.8425 34.7955C36.1336 36.2478 37.7903 37.1771 39.5524 37.5778C40.6329 34.9624 41.5859 32.1634 41.8741 29.9042L41.1704 29.114L40.4666 28.3238C38.2004 28.3628 35.3246 28.9971 32.615 29.7817Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M25.1401 43.5041L40.8213 28.7189L41.5194 29.5035L25.1401 43.5041Z"
                        fill={colorLayer3}
                    />
                    <path
                        opacity="0.5"
                        d="M66.5816 22.6146C65.6729 17.6287 61.6334 14.1953 57.5164 14.8798C57.9985 14.6627 58.5637 14.4346 58.9848 14.3567C63.1351 13.5943 67.2354 17.0555 68.1497 22.0804C69.0584 27.0662 66.4763 31.7182 62.387 32.5307C65.5454 30.9837 67.3684 26.9271 66.5871 22.6146H66.5816Z"
                        fill={colorLayer4}
                    />
                    <path
                        opacity="0.5"
                        d="M44.2013 49.5973C46.4731 53.5649 51.6097 54.0434 55.6768 50.6601C59.0679 47.8445 60.6305 43.237 59.7439 39.4864C61.7165 43.4484 60.2371 49.1243 56.3029 52.3963C52.2358 55.774 47.0993 55.301 44.8274 51.3335C44.4507 50.6768 44.1847 49.9646 44.0129 49.2245C44.0739 49.3525 44.1348 49.4804 44.2013 49.5973Z"
                        fill={colorLayer4}
                    />
                    <path
                        opacity="0.5"
                        d="M15.7536 17.9625C17.6486 13.1992 22.5358 10.5783 26.6694 12.1086C27.0906 12.2644 27.628 12.5927 28.0824 12.8988C23.971 11.4464 19.1503 14.0618 17.2663 18.7861C15.6372 22.8761 16.751 27.2109 19.7099 29.3199C15.6428 27.7507 13.8696 22.6925 15.7536 17.9625Z"
                        fill={colorLayer4}
                    />
                    <path
                        opacity="0.5"
                        d="M21.5218 34.3837C19.8263 37.3496 20.4247 41.6065 23.2506 44.7839C26.6418 48.5901 31.939 49.3914 35.0808 46.5702C35.175 46.4811 35.2636 46.3865 35.3523 46.2975C35.0198 46.8873 34.5987 47.4271 34.0778 47.889C30.9361 50.7102 25.6388 49.9145 22.2477 46.1027C18.9674 42.419 18.6848 37.2717 21.5218 34.3837Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M41.4751 27.9454C35.2414 24.7959 33.1469 14.7851 33.2134 14.3344L33.762 14.3956C33.762 15.0968 35.9119 24.5176 41.7189 27.4502L41.4696 27.9454H41.4751Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M42.9047 28.9193L42.3506 28.8636C42.9656 22.7314 39.6632 12.3534 39.6244 12.2477L40.1508 12.0752C40.2893 12.5037 43.5253 22.6702 42.8991 28.9193H42.9047Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M41.8796 27.1386C36.5934 26.5154 30.5814 20.9229 30.3321 20.6837L30.7089 20.2774C30.7698 20.3331 36.8428 25.9812 41.9461 26.5877L41.8796 27.1386Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M41.3033 29.2643C38.3777 29.0973 32.8643 27.2554 32.6316 27.1775L32.8089 26.6489C32.8643 26.6656 38.4885 28.5464 41.3366 28.7078L41.3033 29.2643Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M43.0543 27.8286L42.7052 27.4001C44.8164 25.6584 45.9578 15.0189 45.9689 14.9076L46.5175 14.9688C46.4731 15.414 45.3372 25.9478 43.0543 27.8342V27.8286Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M42.2453 29.9765L42.0791 29.4423C43.9575 28.858 46.7391 23.1543 47.6534 21.0064L48.1631 21.2234C48.0246 21.5461 44.7499 29.1919 42.2453 29.971V29.9765Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M41.8685 28.5242L41.7854 27.9733C45.2042 27.4613 51.7981 19.3537 51.8646 19.2702L52.2968 19.6208C52.0197 19.9602 45.5034 27.9788 41.8741 28.5242H41.8685Z"
                        fill={colorLayer4}
                    />
                    <path
                        d="M39.8903 13.6945C40.735 13.6945 41.4197 13.0069 41.4197 12.1587C41.4197 11.3104 40.735 10.6228 39.8903 10.6228C39.0457 10.6228 38.361 11.3104 38.361 12.1587C38.361 13.0069 39.0457 13.6945 39.8903 13.6945Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M38.1616 23.1821C39.0062 23.1821 39.6909 22.4945 39.6909 21.6463C39.6909 20.7981 39.0062 20.1105 38.1616 20.1105C37.3169 20.1105 36.6322 20.7981 36.6322 21.6463C36.6322 22.4945 37.3169 23.1821 38.1616 23.1821Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M48.1798 23.1098C49.0244 23.1098 49.7091 22.4222 49.7091 21.574C49.7091 20.7258 49.0244 20.0381 48.1798 20.0381C47.3352 20.0381 46.6505 20.7258 46.6505 21.574C46.6505 22.4222 47.3352 23.1098 48.1798 23.1098Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M50.8228 27.5504C51.6675 27.5504 52.3522 26.8627 52.3522 26.0145C52.3522 25.1663 51.6675 24.4787 50.8228 24.4787C49.9782 24.4787 49.2935 25.1663 49.2935 26.0145C49.2935 26.8627 49.9782 27.5504 50.8228 27.5504Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M30.5149 22.0191C31.3595 22.0191 32.0443 21.3315 32.0443 20.4833C32.0443 19.6351 31.3595 18.9475 30.5149 18.9475C29.6703 18.9475 28.9856 19.6351 28.9856 20.4833C28.9856 21.3315 29.6703 22.0191 30.5149 22.0191Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M45.9744 16.6771C46.8191 16.6771 47.5038 15.9895 47.5038 15.1413C47.5038 14.2931 46.8191 13.6055 45.9744 13.6055C45.1298 13.6055 44.4451 14.2931 44.4451 15.1413C44.4451 15.9895 45.1298 16.6771 45.9744 16.6771Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M32.7202 28.4518C33.5649 28.4518 34.2496 27.7642 34.2496 26.916C34.2496 26.0678 33.5649 25.3802 32.7202 25.3802C31.8756 25.3802 31.1909 26.0678 31.1909 26.916C31.1909 27.7642 31.8756 28.4518 32.7202 28.4518Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M52.6071 21.012C53.4517 21.012 54.1364 20.3244 54.1364 19.4761C54.1364 18.6279 53.4517 17.9403 52.6071 17.9403C51.7624 17.9403 51.0777 18.6279 51.0777 19.4761C51.0777 20.3244 51.7624 21.012 52.6071 21.012Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M33.4849 15.9092C34.3295 15.9092 35.0143 15.2216 35.0143 14.3734C35.0143 13.5252 34.3295 12.8376 33.4849 12.8376C32.6403 12.8376 31.9556 13.5252 31.9556 14.3734C31.9556 15.2216 32.6403 15.9092 33.4849 15.9092Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M41.8408 30.4495C43.4352 30.4495 44.7277 29.1515 44.7277 27.5503C44.7277 25.9492 43.4352 24.6512 41.8408 24.6512C40.2465 24.6512 38.9539 25.9492 38.9539 27.5503C38.9539 29.1515 40.2465 30.4495 41.8408 30.4495Z"
                        fill={colorLayer3}
                    />
                </g>
                <defs>
                    <clipPath id={id}>
                        <rect
                            width="70"
                            height="82"
                            fill={colorLayer4}
                            transform="translate(15)"
                        />
                    </clipPath>
                </defs>
            </g>
        </BasePlant>
    )
}

export default CommunityCherryBlossomPlant
