import { useContext } from 'react'
import InterstitialNavigationProviderContext, {
    InterstitialNavigationProviderValue
} from './interstitialNavigationContext'

export const useInterstitialNavigation =
    (): InterstitialNavigationProviderValue => {
        const context = useContext(InterstitialNavigationProviderContext)

        return context
    }
