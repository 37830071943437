import { FetchResult } from '@apollo/client'
import { useCallback, useMemo, useState } from 'react'
import {
    EditDisplayNameMutation,
    InvalidInput,
    UpdateDisplayNameMutation,
    useEditDisplayNameMutation,
    useUpdateDisplayNameMutation
} from '../../graphql/generated/autogenerated'
import { GQLNull } from '../../shared/utils/Nulls'

export interface useEditDisplayNameProps {
    savedDisplayName?: string | GQLNull
    displayName?: string
}

export const useEditDisplayName = ({
    savedDisplayName,
    displayName
}: useEditDisplayNameProps) => {
    const [invalidText, setInvalidText] = useState('')

    const [updateDisplayName] = useUpdateDisplayNameMutation({
        variables: {
            displayName: displayName ?? ''
        }
    })

    const [editDisplayNameMutation] = useEditDisplayNameMutation({
        variables: {
            displayName: displayName ?? ''
        }
    })

    const { mutation, onMutationComplete } = useMemo(() => {
        const { activeMutation, omMutationSpecificResponse } = savedDisplayName
            ? {
                  activeMutation: editDisplayNameMutation,
                  omMutationSpecificResponse: (
                      response: FetchResult<EditDisplayNameMutation>
                  ) => response?.data?.socialGroups?.editDisplayName
              }
            : {
                  activeMutation: updateDisplayName,
                  omMutationSpecificResponse: (
                      response: FetchResult<UpdateDisplayNameMutation>
                  ) => response?.data?.socialGroups?.updateDisplayName
              }

        return {
            mutation: activeMutation,
            onMutationComplete: (response: any) => {
                setInvalidText('')
                const mutationSpecificResponse =
                    omMutationSpecificResponse(response)

                if (
                    (mutationSpecificResponse as InvalidInput)?.invalidFields
                        ?.length > 0
                ) {
                    setInvalidText(
                        (mutationSpecificResponse as InvalidInput)
                            .invalidFields[0].message
                    )
                    return false
                }

                return true
            }
        }
    }, [savedDisplayName, editDisplayNameMutation, updateDisplayName])

    const editDisplayName = useCallback(
        () => mutation().then(onMutationComplete),
        [mutation, onMutationComplete]
    )

    return { editDisplayName, invalidText }
}

export default useEditDisplayName
