import { Card, Skeleton, Stack } from '@mui/material'
import React from 'react'

const DailyGoalCardSkeleton: React.FC = () => {
    return (
        <Card elevation={16}>
            <Stack p={2} gap={2}>
                <Skeleton variant="rounded" height={23} width="50%" />
                <Card variant="outlined">
                    <Stack direction="row" height={132}>
                        <Skeleton
                            variant="rounded"
                            height="100%"
                            width={112}
                            sx={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0
                            }}
                        />
                        <Stack p={1} flex={1} gap={1}>
                            <Stack>
                                <Skeleton
                                    variant="text"
                                    height={20}
                                    width={45}
                                />
                                <Skeleton
                                    variant="text"
                                    height={24}
                                    width="75%"
                                />
                            </Stack>
                            <Stack gap={1}>
                                <Stack>
                                    <Skeleton
                                        variant="text"
                                        height={20}
                                        width="100%"
                                    />
                                    <Skeleton
                                        variant="text"
                                        height={20}
                                        width="80%"
                                    />
                                </Stack>
                                <Skeleton
                                    variant="text"
                                    height={20}
                                    width="40%"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </Card>
    )
}

export default DailyGoalCardSkeleton
