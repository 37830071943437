import { useAppSelector } from '@thriveglobal/thrive-web-core'
import React, { memo, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import useGoalCompletedLocalState from '../../../../contexts/withGoalsCompleted/useGoalsCompletedLocalState'
import { useCompanyChallengeProviderContext } from '../../../../contexts/withCompanyChallengeProvider'
import { ROUTES } from '../../../../../routes'
import GoalChallengeSettings from '../GoalChallengeSettings'
import LeaveChallengeButton from '../LeaveChallengeButton'
import RemoveChallengeButton from '../RemoveChallengeButton'
import ParticipantsList from './Participant'
import type {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../graphql/generated/autogenerated'
import type { SocialGroupsValues } from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'

export interface GroupChallengeSettingsProps {
    open: boolean
    onClose: () => void
    challenge?: UnifiedChallenge
    socialGroups: SocialGroupsValues
    participation?: UnifiedChallengeActivity
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const messages = defineMessages({
    yourProgress: {
        defaultMessage:
            'Your progress will be lost if you leave the challenge.',
        description: 'description on what leaving this challenge will cause'
    }
})

const GroupChallengeSettings: React.FC<GroupChallengeSettingsProps> = ({
    open,
    onClose,
    challenge,
    socialGroups,
    participation,
    refetchUnifiedChallengeAvailability
}) => {
    const { formatMessage } = useIntl()
    const history = useHistory()
    const { userId } = useAppSelector((state) => state.user)
    const [activeSettingChange, setActiveSettingChange] = useState(false)
    const { deleteAllCompletedState } = useGoalCompletedLocalState()

    const { mainCommunitySocialGroup } = socialGroups

    const { isAdmin, hasSoloParticipant } = useMemo(() => {
        return {
            isAdmin: userId === challenge?.createdBy,
            hasSoloParticipant:
                (mainCommunitySocialGroup?.memberCount ?? 0) <= 1
        }
    }, [userId, challenge, mainCommunitySocialGroup?.memberCount])

    return (
        <GoalChallengeSettings
            challenge={challenge as UnifiedChallenge}
            socialGroups={socialGroups}
            open={open}
            onClose={onClose}
            refetchUnifiedChallengeAvailability={
                refetchUnifiedChallengeAvailability
            }
            settings={
                !hasSoloParticipant && (
                    <ParticipantsList
                        challenge={challenge as UnifiedChallenge}
                        socialGroups={socialGroups}
                    />
                )
            }
            action={
                isAdmin ? (
                    <>
                        {hasSoloParticipant && (
                            <RemoveChallengeButton
                                challengeId={challenge?.id}
                                disabled={activeSettingChange}
                                onRemoving={setActiveSettingChange}
                                onRemove={() => {
                                    deleteAllCompletedState(challenge?.id)
                                    history?.push(ROUTES.HOME)
                                    onClose()
                                }}
                            />
                        )}
                    </>
                ) : (
                    <LeaveChallengeButton
                        disabled={activeSettingChange}
                        onLeaving={setActiveSettingChange}
                        description={formatMessage(messages.yourProgress)}
                        userChallengeActivityId={participation?.id}
                        refetchChallenge={() => {
                            return socialGroups?.socialGroupsRefetch()
                        }}
                        onLeave={() => {
                            deleteAllCompletedState(challenge?.id)
                            onClose()
                        }}
                    />
                )
            }
        />
    )
}

export default memo(GroupChallengeSettings)
