import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SignUpSteps, SignUpTypes } from '../../../../../enums'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useInterstitialNavigation } from '../../../../../hooks/withInterstitialNavigation'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'

const messages = defineMessages({
    createATeam: {
        defaultMessage: 'Create a team',
        description: 'create a team button on company challenge sign up process'
    },
    joinATeam: {
        defaultMessage: 'Join this team',
        description: 'join a team button on company challenge sign up process'
    }
})

export interface SwitchSignUpTypeButtonProps {
    onSwitch?: () => void
}

const SwitchSignUpTypeButton: React.FC<SwitchSignUpTypeButtonProps> = ({
    onSwitch
}) => {
    const { formatMessage } = useIntl()
    const { clearDisables, clearMutations } =
        useCompanyChallengeSignUpProviderContext()

    const { setSignUpType, signUpType } = useChallengeSignUpModalContext()
    const { queueNavigateToKey } = useInterstitialNavigation()

    const otherSignUpType = useMemo(
        () =>
            signUpType === SignUpTypes.joinTeam
                ? SignUpTypes.createTeam
                : SignUpTypes.joinTeam,
        [signUpType]
    )

    return (
        <Button
            data-testid="switch-sign-up-type-button"
            onClick={() => {
                clearMutations()
                clearDisables?.()
                onSwitch?.()
                setSignUpType(otherSignUpType)
                // We use the queue navigate since we need to wait for the navigation keys to update from the setSignUpType hook
                queueNavigateToKey?.(
                    otherSignUpType === SignUpTypes.joinTeam
                        ? SignUpSteps.joinTeam
                        : SignUpSteps.createTeam
                )
            }}
        >
            <CoreTypography customVariant="buttonNormal">
                {formatMessage(
                    signUpType === SignUpTypes.joinTeam
                        ? messages.createATeam
                        : messages.joinATeam
                )}
            </CoreTypography>
        </Button>
    )
}

export default memo(SwitchSignUpTypeButton)
