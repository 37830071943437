import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant1: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#365D67',
    colorLayer2 = '#1B3237',
    colorLayer3 = '#52A185',
    colorLayer4 = '#94CAD0',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M75.3597 34.1L58.3097 47.13L49.7797 53.65C47.0097 55.73 45.0397 58.71 44.0997 61.79L42.9997 61.6C44.1197 58.31 46.2797 55.21 49.3097 53.04L57.9897 46.73L75.3597 34.1Z"
                fill={colorLayer1}
            />
            <path
                d="M26.6297 29.2C27.0597 37.74 31.9797 39.53 37.8997 38.77C43.8197 38.01 48.4997 34.99 48.0797 26.45C47.6497 17.91 42.2797 7.09 36.3597 7.85C30.4397 8.61 26.2097 20.66 26.6297 29.19V29.2Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M37.0497 20.59L37.4097 20.78L43.2997 12.13C43.2197 12.01 43.1397 11.9 43.0497 11.78L37.0497 20.59Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.75"
                d="M47.2197 21C47.1797 20.83 47.1297 20.66 47.0797 20.49L37.7397 34.22L38.0997 34.41L47.2197 21Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.75"
                d="M40.3897 9.07C40.2797 8.99 40.1697 8.91 40.0597 8.84L36.6997 13.78L37.0597 13.97L40.3897 9.07Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.75"
                d="M37.3897 27.41L37.7497 27.6L45.5397 16.16C45.4797 16.02 45.4097 15.87 45.3497 15.73L37.3997 27.42L37.3897 27.41Z"
                fill={colorLayer2}
            />
            <path
                d="M36.2297 10.09L36.5597 9.81L35.0197 8.23C34.8797 8.29 34.7397 8.35 34.6097 8.43L36.2397 10.09H36.2297Z"
                fill={colorLayer3}
            />
            <path
                d="M26.9297 31.72C26.9797 32 27.0397 32.26 27.1097 32.52L33.1297 38.68C33.3697 38.73 33.6097 38.78 33.8597 38.81L26.9297 31.72Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M48.0697 26.45C48.0697 26.45 48.0697 26.39 48.0697 26.36L39.8697 38.41C40.0697 38.36 40.2697 38.31 40.4597 38.25L48.0797 27.05C48.0797 26.85 48.0797 26.66 48.0797 26.45H48.0697Z"
                fill={colorLayer2}
            />
            <path
                d="M38.3097 37.01L26.7497 25.17C26.7297 25.36 26.7097 25.55 26.6997 25.74L37.9797 37.29L38.3097 37.01Z"
                fill={colorLayer3}
            />
            <path
                d="M36.9897 16.61L31.6197 11.11C31.5297 11.23 31.4397 11.34 31.3597 11.46L36.6597 16.89L36.9897 16.61Z"
                fill={colorLayer3}
            />
            <path
                d="M37.8697 30.21L27.7097 19.81C27.6697 19.97 27.6297 20.14 27.5897 20.3L37.5397 30.49L37.8697 30.21Z"
                fill={colorLayer3}
            />
            <path
                d="M37.4297 23.41L29.3297 15.11C29.2697 15.25 29.1997 15.39 29.1397 15.54L37.0997 23.69L37.4297 23.41Z"
                fill={colorLayer3}
            />
            <path
                d="M36.5697 7.83C37.2397 17.11 38.3997 33.19 39.0497 42.3C39.1797 43.35 39.6897 45.31 39.9297 46.36C42.9797 58.98 44.4897 72.01 44.1097 85L42.2297 84.93C42.8297 72.07 41.6597 59.17 38.8497 46.6C38.6197 45.47 38.1297 43.49 38.0197 42.36C37.8297 38.9 37.3897 30.77 37.1897 27.23L36.1297 7.88C36.4097 7.83 36.5597 7.83 36.5597 7.83H36.5697Z"
                fill={colorLayer1}
            />
            <path
                d="M50.4297 18.62C45.0597 28.35 48.2497 32.75 53.5497 35.73C58.8397 38.71 64.2297 39.14 69.5997 29.41C74.9697 19.68 78.2897 3.46999 72.9997 0.489991C67.7097 -2.49001 55.8097 8.87999 50.4397 18.62H50.4297Z"
                fill={colorLayer4}
            />
            <path
                opacity="0.5"
                d="M51.4697 19.52C56.8397 9.79 68.7397 -1.59 74.0297 1.39C74.0297 1.39 74.0297 1.39 74.0397 1.39C73.7297 1.02 73.3897 0.709996 72.9797 0.479996C67.6897 -2.5 55.7897 8.87 50.4197 18.61C45.8597 26.87 47.4797 31.29 51.3097 34.26C48.2697 31.3 47.3397 26.98 51.4597 19.51L51.4697 19.52Z"
                fill="white"
            />
            <path
                d="M72.9897 0.48999C67.5797 9.70999 58.6397 24.78 53.1897 33.93C52.5197 35.04 51.6497 36.67 50.7597 37.59C44.3597 43.91 43.0997 52.56 43.5797 61.19L42.4997 61.25C41.7697 52.6 43.4097 43.09 50.0997 36.99C50.9997 36.12 51.8497 34.58 52.5097 33.52C58.0897 24.47 67.3297 9.52999 72.9897 0.48999Z"
                fill={colorLayer1}
            />
            <path
                d="M50.0197 36.44C43.8297 40.42 44.2997 46.33 47.8997 51.68C51.4897 57.03 56.8497 59.79 63.0397 55.81C69.2297 51.83 78.9597 39.46 75.3697 34.11C71.7797 28.76 56.2097 32.46 50.0297 36.44H50.0197Z"
                fill={colorLayer3}
            />
            <path
                d="M48.8497 52.96L51.8697 35.43C51.6697 35.55 51.4697 35.66 51.2797 35.78L48.4197 52.39C48.5597 52.58 48.7097 52.77 48.8497 52.96Z"
                fill={colorLayer1}
            />
            <path
                d="M54.9197 49.41L57.7297 33.11C57.5397 33.17 57.3497 33.22 57.1697 33.28L54.3497 49.63C54.4997 49.53 54.6597 49.45 54.8497 49.41C54.8797 49.41 54.8997 49.41 54.9297 49.41H54.9197Z"
                fill={colorLayer1}
            />
            <path
                d="M67.3997 52.18L50.6997 52.62C50.6097 52.81 50.4997 52.98 50.3397 53.12L66.8797 52.68C67.0497 52.52 67.2197 52.35 67.3997 52.18Z"
                fill={colorLayer1}
            />
            <path
                d="M74.1897 32.93C74.0397 32.82 73.8797 32.72 73.7097 32.63L73.2397 35.8L73.8197 35.48L74.1997 32.93H74.1897Z"
                fill={colorLayer1}
            />
            <path
                d="M61.1597 45L63.4197 31.81C63.2397 31.84 63.0597 31.87 62.8697 31.9L60.5997 45.14C60.6997 45.1 60.7897 45.05 60.8997 45.03C60.9797 45.03 61.0697 45.01 61.1497 45.01L61.1597 45Z"
                fill={colorLayer1}
            />
            <path
                d="M76.1297 36.95L71.4697 37.09C71.3697 37.28 71.2397 37.45 71.0597 37.59L76.0997 37.44C76.1097 37.28 76.1197 37.11 76.1197 36.95H76.1297Z"
                fill={colorLayer1}
            />
            <path
                d="M68.3997 31.44L66.7897 40.44C66.9497 40.36 67.1397 40.32 67.3497 40.31L68.9297 31.46C68.7597 31.46 68.5797 31.44 68.3997 31.44Z"
                fill={colorLayer1}
            />
            <path
                d="M59.3897 57.42L55.5897 57.53C56.7997 57.77 58.0697 57.74 59.3897 57.42Z"
                fill={colorLayer1}
            />
            <path
                d="M64.4897 42.28C64.3697 42.48 64.2197 42.65 64.0197 42.78L74.5697 42.47C74.6497 42.3 74.7297 42.14 74.7997 41.98L64.4897 42.28Z"
                fill={colorLayer1}
            />
            <path
                d="M57.2497 47.49L57.3197 47.98L71.3997 47.57C71.5197 47.4 71.6497 47.24 71.7697 47.07L57.2497 47.49Z"
                fill={colorLayer1}
            />
            <path
                d="M75.3397 34.16L57.9897 46.73L49.3097 53.04C48.8797 53.35 48.4697 53.68 48.0697 54.03C48.2897 54.2 48.4997 54.38 48.6897 54.57C49.0397 54.25 49.4097 53.94 49.7897 53.65L58.3197 47.13L75.5097 34.32C75.4597 34.26 75.3997 34.21 75.3497 34.15L75.3397 34.16Z"
                fill={colorLayer1}
            />
            <path
                d="M38.1497 55.9C38.1497 55.9 12.7197 53.61 12.2197 48.72C11.7197 43.83 22.3297 38.16 30.1297 43.15C38.1097 48.26 38.1497 55.91 38.1497 55.91V55.9Z"
                fill={colorLayer1}
            />
            <path
                d="M13.3497 50.33C16.0197 48.67 18.9597 47.34 21.9697 46.85C32.6997 45.09 38.1497 55.9 38.1497 55.9C38.1497 55.9 18.3497 54.12 13.3497 50.33Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M13.3497 50.33C16.0197 48.67 18.9597 47.34 21.9697 46.85C32.6997 45.09 38.1497 55.9 38.1497 55.9C38.1497 55.9 18.3497 54.12 13.3497 50.33Z"
                fill={colorLayer2}
            />
            <path
                d="M47.1797 62.44C47.1797 62.44 67.9397 71.83 71.5797 68.3C75.2197 64.77 68.1297 56.24 61.7397 56.18C51.2497 56.08 47.1797 62.44 47.1797 62.44Z"
                fill={colorLayer1}
            />
            <path
                d="M61.2897 57.26C66.8297 57.31 72.8797 63.71 72.0397 67.72C74.4797 63.92 67.7997 56.24 61.7297 56.18C51.2397 56.08 47.1697 62.44 47.1697 62.44C47.1697 62.44 47.2897 62.49 47.4797 62.58C49.0697 60.82 53.3297 57.18 61.2797 57.25L61.2897 57.26Z"
                fill={colorLayer3}
            />
            <path
                d="M69.6397 69.04C64.2897 56.21 47.1697 62.44 47.1697 62.44C47.1697 62.44 63.3397 69.75 69.6397 69.04Z"
                fill={colorLayer1}
            />
            <path
                d="M42.6897 63.55L42.0597 62.92C42.1797 62.8 45.1197 59.97 52.8697 63.01L52.5497 63.84C45.4097 61.04 42.7197 63.53 42.6897 63.55Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M69.6397 69.04C64.2897 56.21 47.1697 62.44 47.1697 62.44C47.1697 62.44 63.3397 69.75 69.6397 69.04Z"
                fill={colorLayer2}
            />
            <path
                d="M32.2697 57.77C31.6297 57.66 30.9897 57.74 30.4697 58.26C29.4697 59.27 28.4297 66.64 36.1297 69.15C41.5897 70.93 47.8297 65.08 46.6397 61.68C45.9897 59.82 43.8697 59.74 41.9197 59.47C40.6597 59.29 39.3297 59.51 38.0397 59.38C36.6397 59.23 35.2797 58.89 34.0097 58.37C33.5097 58.16 32.8897 57.88 32.2597 57.77H32.2697Z"
                fill={colorLayer1}
            />
            <path
                d="M32.5997 56.21C31.9597 56.1 31.3197 56.18 30.7997 56.7C29.7997 57.71 28.7597 65.08 36.4597 67.59C41.9197 69.37 47.8697 64.53 46.6797 61.13C46.0297 59.27 44.1997 58.18 42.2597 57.91C40.9997 57.73 39.6697 57.95 38.3797 57.82C36.9797 57.67 35.6197 57.33 34.3497 56.81C33.8497 56.6 33.2297 56.32 32.5997 56.21Z"
                fill={colorLayer3}
            />
            <path
                d="M41.2797 54.79C41.6997 54.9 41.7297 55.46 41.3597 55.64C41.1197 55.78 41.1997 55.91 40.9897 56.09C39.8697 57.03 38.8697 58.45 38.4397 59.86C37.9197 61.62 37.7697 63.49 37.8097 65.35C37.4697 61.76 37.6897 57.27 40.8597 54.91C40.6597 55.05 41.2697 54.79 41.2697 54.79H41.2797Z"
                fill={colorLayer1}
            />
            <path
                d="M35.1797 41.01L14.0097 33.58C14.5397 29.35 23.5697 25.59 29.5197 30.12C35.6197 34.76 35.1797 41 35.1797 41V41.01Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M35.1797 41.01L14.0097 33.58C14.5397 29.35 23.5697 25.59 29.5197 30.12C35.6197 34.76 35.1797 41 35.1797 41V41.01Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.75"
                d="M36.5697 7.84C36.5697 7.84 36.5697 7.84 36.5597 7.84C36.4897 7.84 36.4297 7.84 36.3597 7.85C36.3597 7.85 36.3597 7.85 36.3497 7.85C36.2797 7.85 36.2097 7.88 36.1397 7.89L37.1997 27.23C37.3397 29.83 37.6197 34.89 37.8297 38.77C37.8497 38.77 37.8797 38.77 37.8997 38.77C38.1997 38.73 38.4997 38.68 38.7897 38.63C38.1197 29.32 37.1597 15.98 36.5697 7.84Z"
                fill={colorLayer2}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant1
