import { DialogActions, DialogContent } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { useInterstitialNavigation } from '../../../../hooks/withInterstitialNavigation'
import Actions from './Actions'
import Steps from './Steps'
import { StepDetails } from './Steps/config'

export interface TutorialProps extends JSX.IntrinsicAttributes {
    open: boolean
    onClose: () => void
    title: string
    stepDetails: { [key: string]: StepDetails }
}

const Tutorial: FC<TutorialProps> = ({ open, onClose, title, stepDetails }) => {
    const { userId } = useAppSelector((state) => state.user)
    const { keys, activeKey, activeIndex, next, prev, setKeys } =
        useInterstitialNavigation()

    useEffect(() => {
        setKeys(Object.keys(stepDetails))
    }, [setKeys, stepDetails])

    const handleNext = useCallback(() => {
        next()
    }, [next])

    const handleBack = useCallback(() => {
        prev()
    }, [prev])

    const { startOfSteps, endOfSteps } = useMemo(
        () => ({
            startOfSteps: activeIndex === 0,
            endOfSteps: activeIndex === keys.length - 1
        }),
        [activeIndex, keys]
    )

    return (
        <LeafDialog
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth="md"
            dialogTitle={
                <CoreTypography variant="h3" color="text.primary">
                    {title}
                </CoreTypography>
            }
            PaperProps={{
                sx: {
                    maxWidth: 672,
                    minHeight: 336
                }
            }}
        >
            <DialogContent>
                <Steps stepDetails={stepDetails} />
            </DialogContent>
            <DialogActions>
                <Actions
                    startOfSteps={startOfSteps}
                    endOfSteps={endOfSteps}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    onClose={onClose}
                />
            </DialogActions>
        </LeafDialog>
    )
}
export default memo(Tutorial)
