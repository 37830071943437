import { Card, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { MeasureUnit } from '../../../../../../../../graphql/generated/autogenerated'
import useGlassesMeasurementConversion from '../../../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'

type CurrentDailyGoalProps = {
    measurement: MeasureUnit
    goal: number
}

const CurrentDailyGoal: React.FC<CurrentDailyGoalProps> = ({
    measurement,
    goal
}) => {
    const { palette } = useTheme()
    const formatted = useGlassesMeasurementConversion(goal, measurement)

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: palette.grey[100]
            }}
        >
            <Stack gap={1} p={2}>
                <CoreTypography variant="overline" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Your current daily water goal"
                        description="current value set for the daily goal"
                    />
                </CoreTypography>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="{waterGoal} Glasses / {intakeMeasurement}."
                        description="current count set for the hydration daily goal"
                        values={{
                            waterGoal: goal,
                            intakeMeasurement: formatted
                        }}
                    />
                </CoreTypography>
            </Stack>
        </Card>
    )
}

export default memo(CurrentDailyGoal)
