import { useMemo } from 'react'
import { ApolloError } from '@apollo/client/errors'

import {
    ChallengeGoal,
    ChallengeTemplateGoal,
    useChallengeGoalsQuery
} from '../../../graphql/generated/autogenerated'

export type ChallengeGoalsPreview = {
    goals?: ChallengeGoal[] | ChallengeTemplateGoal[]
    loading: boolean
    error: ApolloError | undefined
    refetch: () => void
}

interface UseChallengeGoalPreviewProps {
    challengeId?: string
}

const useChallengeGoalPreview = ({
    challengeId
}: UseChallengeGoalPreviewProps): ChallengeGoalsPreview => {
    const { data, loading, error, refetch } = useChallengeGoalsQuery({
        variables: { challengeId: challengeId },
        skip: !challengeId
    })

    const goals = useMemo(
        () =>
            data?.unifiedChallenges?.communityChallengeGoals as ChallengeGoal[],
        [data]
    )
    return {
        goals,
        loading,
        error,
        refetch
    }
}

export default useChallengeGoalPreview
