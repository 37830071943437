import { useContext } from 'react'
import CompanyTabControllerContext, {
    CompanyTabControllerValue
} from './companyTabControllerContext'

export const useCompanyTabControllerContext = (): CompanyTabControllerValue => {
    const context = useContext(CompanyTabControllerContext)

    return context
}
