import { Typography, Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { diffDays, parseDate } from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { type GraphQlMicrostep } from '../../../graphql/generated/autogenerated'

export interface MicrostepNewLabelProps {
    microstep: GraphQlMicrostep
}

const MicrostepNewLabel: React.FC<MicrostepNewLabelProps> = ({ microstep }) => {
    const theme = useTheme()
    const [isNew, setIsNew] = useState<boolean>(false)

    useEffect(() => {
        if (!microstep.created_at) {
            setIsNew(false)
        } else {
            // compare the created_at date from CMS to today, use "day" as the unit of measure
            const diffInDays = diffDays(
                new Date(),
                parseDate(microstep.created_at)
            )
            // diff could be negative, we only care about the last 30 days
            setIsNew(Math.abs(diffInDays) <= 30)
        }
    }, [microstep.created_at])

    return (
        <>
            {isNew && (
                <Chip
                    data-testid="microstep-new-label"
                    color="secondary"
                    size="small"
                    sx={{
                        marginLeft: theme.spacing(0.8)
                    }}
                    label={
                        <Typography variant="overline">
                            <FormattedMessage
                                defaultMessage="NEW"
                                description="a label indicating that the microstep is new"
                            ></FormattedMessage>
                        </Typography>
                    }
                ></Chip>
            )}
        </>
    )
}

export default MicrostepNewLabel
