import { Box, Card, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafTextField,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    currentGoalTitle: {
        defaultMessage: 'Current daily activity points goal',
        description: 'Current goal title'
    },
    newGoalLabel: {
        defaultMessage: 'New Daily Activity Points Goal',
        description: 'Points input label and card title'
    },
    pointsValue: {
        defaultMessage: '{points, plural, one {# point} other {# points}}',
        description: 'Current goal value'
    }
})

type EditGoalProps = {
    currentGoal: number
    newGoal?: number
    onChangeGoal: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const EditGoal: React.FC<EditGoalProps> = ({
    currentGoal,
    newGoal,
    onChangeGoal
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    return (
        <Stack gap={2} maxWidth={500}>
            <Box mb={1}>
                <CoreTypography variant="overline" color="text.primary">
                    {formatMessage(messages.currentGoalTitle)}
                </CoreTypography>
                <CoreTypography variant="h3" color="text.disabled">
                    {formatMessage(messages.pointsValue, {
                        points: currentGoal
                    })}
                </CoreTypography>
            </Box>
            <LeafTextField
                aria-label={formatMessage(messages.newGoalLabel)}
                id="points-goal-input"
                variant="outlined"
                type="number"
                label={formatMessage(messages.newGoalLabel)}
                value={newGoal?.toString()}
                onChange={onChangeGoal}
                fullWidth={false}
                inputProps={{ min: 0 }}
            />

            <Stack gap={1}>
                <Card
                    variant="elevation"
                    elevation={0}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.palette.grey[100],
                        gap: 1,
                        padding: 2
                    }}
                >
                    <Stack gap={1}>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            <CoreTypography
                                variant="overline"
                                color={theme.palette.text.primary}
                            >
                                {formatMessage(messages.newGoalLabel)}
                            </CoreTypography>
                        </Stack>
                        <Stack gap={1}>
                            <CoreTypography variant="h3">
                                {formatMessage(messages.pointsValue, {
                                    points: newGoal ?? 0
                                })}
                            </CoreTypography>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </Stack>
    )
}

export default EditGoal
