import { useState, useEffect, useMemo } from 'react'
import { useAppSelector, format } from '@thriveglobal/thrive-web-core'
import { useMultiStepFormContext } from '../../contexts/withMultiStepForm/withMultiStepForm'
import {
    SIGNUP_STEPS,
    SIGNUP_STEPS_TYPE,
    SIGNUP_VARIANT,
    type SIGNUP_VARIANT_TYPE
} from '../../constants'
import { MeasureUnit } from '../../../graphql/generated/autogenerated'

import useSignupFormData from '../useSignupFormData/useSignupFormData'

export type CompanyChallengeForm = {
    details: {
        displayName?: string
        startDate?: string
    }
    signupVariant: SIGNUP_VARIANT_TYPE
    terms: {
        confirmed: boolean
    }
    activityGoal: number
    hydration: {
        goalCount?: number
        intakeMeasurement?: MeasureUnit
    }
    sleepHabit?: number
    createTeam?: {
        teamName?: string
        isPublic?: boolean
    }
    joinTeam?: {
        id?: string
        name?: string
    } | null
}

export const useCompanyChallengeForm = () => {
    const user = useAppSelector((state) => state.user)
    const [isDisplayNameValid, setIsDisplayNameValid] = useState(false)
    const { currentStepName, setStepCompletion } = useMultiStepFormContext()

    const { formData, updateFormData } =
        useSignupFormData<CompanyChallengeForm>({
            defaultValues: {
                details: {
                    displayName: user.fullName ?? undefined,
                    startDate: format(new Date())
                },
                signupVariant: SIGNUP_VARIANT.SOLO,
                terms: {
                    confirmed: false
                },
                activityGoal: 0,
                hydration: {
                    goalCount: 0,
                    intakeMeasurement: MeasureUnit.Oz
                },
                createTeam: {
                    teamName: '',
                    isPublic: true
                }
            }
        })

    const stepCompletionStatus = useMemo(
        () => ({
            [SIGNUP_STEPS.DETAILS]:
                isDisplayNameValid &&
                !!formData.details.displayName &&
                !!formData.details.startDate,
            [SIGNUP_STEPS.PARTICIPATION_METHOD]: !!formData.signupVariant,
            [SIGNUP_STEPS.ACTIVITY]: !!formData.activityGoal,
            [SIGNUP_STEPS.HYDRATION]:
                !!formData.hydration.goalCount &&
                !!formData.hydration.intakeMeasurement,
            [SIGNUP_STEPS.SLEEP]: !!formData.sleepHabit,
            [SIGNUP_STEPS.TERMS]: formData.terms.confirmed,
            [SIGNUP_STEPS.CREATE_TEAM]:
                !!formData.createTeam?.teamName &&
                formData.createTeam?.isPublic !== undefined,
            [SIGNUP_STEPS.JOIN_TEAM]:
                !!formData.joinTeam?.id && !!formData.joinTeam?.name
        }),
        [
            isDisplayNameValid,
            formData.details.displayName,
            formData.details.startDate,
            formData.signupVariant,
            formData.activityGoal,
            formData.hydration.goalCount,
            formData.hydration.intakeMeasurement,
            formData.sleepHabit,
            formData.terms.confirmed,
            formData.createTeam?.teamName,
            formData.createTeam?.isPublic,
            formData.joinTeam?.id,
            formData.joinTeam?.name
        ]
    ) as Record<SIGNUP_STEPS_TYPE, boolean>

    useEffect(() => {
        const newCompletionStatus =
            stepCompletionStatus[currentStepName as SIGNUP_STEPS_TYPE] ?? false
        setStepCompletion(currentStepName, newCompletionStatus)
    }, [currentStepName, stepCompletionStatus, setStepCompletion])

    return {
        formData,
        updateFormData,
        setIsDisplayNameValid,
        stepCompletionStatus
    }
}
