import { Box, Icon, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { FC, useMemo } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { useInterstitialNavigation } from '../../../../../hooks/withInterstitialNavigation'
import { toUserReadableSteps } from '../config'
import { StepDetails } from './config'
import { visuallyHidden } from '@mui/utils'

type StepsProps = {
    stepDetails: { [key: string]: StepDetails }
}

const CURRENT_STEP_TEXT = defineMessage({
    defaultMessage: '{currentStep, number} of {totalSteps, number}',
    description: 'Text to indicate what is the current step of the total steps'
})

const Steps: FC<StepsProps> = ({ stepDetails }) => {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const { keys, activeKey, activeIndex } = useInterstitialNavigation()

    const { tutorialImage, currentStepDetails } = useMemo(() => {
        if (!stepDetails[String(activeKey)]) {
            return { tutorialImage: null, currentStepDetails: null }
        }

        const { StepIcon, imgSrc, alt } = stepDetails[String(activeKey)]
        const tutorialImage = StepIcon ? (
            <StepIcon
                titleAccess={formatMessage(alt)}
                sx={{
                    fill: 'transparent',
                    width: '100%',
                    height: '100%'
                }}
            />
        ) : (
            <img
                src={imgSrc}
                alt={formatMessage(alt)}
                loading="lazy"
                width="100%"
                height="100%"
            />
        )

        return {
            tutorialImage,
            currentStepDetails: stepDetails[String(activeKey)]
        }
    }, [activeKey, stepDetails, formatMessage])

    const currentStep = useMemo(
        () => toUserReadableSteps(activeIndex),
        [activeIndex]
    )

    if (!currentStepDetails) {
        return null
    }

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'start',
                gap: 4,
                [breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 0
                }
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                maxWidth={140}
                maxHeight={140}
                minWidth={140}
            >
                {tutorialImage}
            </Stack>
            <Stack gap={1}>
                <Box>
                    <CoreTypography variant="overline">
                        {formatMessage(CURRENT_STEP_TEXT, {
                            currentStep,
                            totalSteps: keys.length
                        })}
                    </CoreTypography>
                </Box>
                <CoreTypography variant="h4">
                    {formatMessage(currentStepDetails.title)}
                </CoreTypography>
                {currentStepDetails.description ? (
                    <CoreTypography variant="body1" color="text.secondary">
                        {formatMessage(currentStepDetails.description)}
                    </CoreTypography>
                ) : null}
            </Stack>
        </Stack>
    )
}
export default Steps
