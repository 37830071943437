import { useMemo } from 'react'
import { QuestionType } from '../../enums/questionType'
import { Story, StoryType } from '../../../graphql/generated/autogenerated'
import { GQLNull } from '../../../shared/utils/Nulls'
import { QuestionData } from './withStorySubmissionProvider/storySubmissionContext'

export type StoryData = {
    stories: QuestionData[]
    questions: QuestionData[]
    interactionId: string
    title?: string
    subTitle?: string
    termsAndConditionsUrl?: string | GQLNull
}

export type PaseStoryDataProps = {
    availableInteraction: StoryType | undefined
    participationId?: string
    stories?: Story[]
}

export const useParseStorySubmissionData = ({
    availableInteraction,
    participationId,
    stories
}: PaseStoryDataProps) => {
    const parsedStories = useMemo(() => {
        const story = stories?.find(
            (story) => story.userChallengeActivityId === participationId
        )?.story

        return story ? JSON.parse(story)?.questions : []
    }, [stories, participationId])

    const { questions, interactionId, title, subTitle, termsAndConditionsUrl } =
        useMemo(() => {
            if (availableInteraction && availableInteraction.questions) {
                const parsedData = JSON.parse(availableInteraction.questions)
                // We can tell it's an image question if the json returned as an image property included
                parsedData?.questions?.forEach((q: any) => {
                    q.type =
                        !!q.image &&
                        Object.prototype.hasOwnProperty.call(q.image, 'uri')
                            ? QuestionType.image
                            : QuestionType.text
                })

                return {
                    questions: parsedData?.questions,
                    interactionId: availableInteraction?.id,
                    title: availableInteraction?.title as string,
                    subTitle: availableInteraction?.subTitle as string,
                    termsAndConditionsUrl:
                        availableInteraction?.termsAndConditionsUrl
                }
            } else {
                return {
                    questions: []
                }
            }
        }, [availableInteraction])

    return useMemo<StoryData>(
        () => ({
            stories: parsedStories,
            questions,
            interactionId,
            title,
            subTitle,
            termsAndConditionsUrl
        }),
        [
            parsedStories,
            questions,
            interactionId,
            title,
            subTitle,
            termsAndConditionsUrl
        ]
    )
}
