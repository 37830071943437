import { useContext } from 'react'
import ChallengeSignUpProviderContext, {
    ChallengeSignUpModalValue
} from './challengeSignUpModalProviderContext'

export const useChallengeSignUpModalContext = (): ChallengeSignUpModalValue => {
    const context = useContext(ChallengeSignUpProviderContext)

    if (context === undefined) {
        throw new Error(
            'useChallengeSignUpModalContext must be used within a ChallengeSignUpModalContext'
        )
    }

    return context
}
