import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    ChallengeUserDailyGoal,
    MeasureUnit,
    WaterGoalDetail
} from '../../../../../../../../graphql/generated/autogenerated'
import CurrentDailyGoal from './CurrentDailyGoal'
import DailyGoalMeasurement from './DailyGoalMeasurement'
import DailyGoalTracker from './DailyGoalTracker'

export type HydrationDailyGoalDialogProps = {
    open: boolean
    goal: ChallengeUserDailyGoal
    intakeMeasurement: MeasureUnit
    onClose: () => void
    saveWaterGoal: (newGoal: number, measurement: MeasureUnit) => Promise<void>
}

const HydrationDailyGoalDialog: React.FC<HydrationDailyGoalDialogProps> = ({
    open,
    goal,
    intakeMeasurement,
    onClose,
    saveWaterGoal
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const waterGoal = (goal?.goalEntityDetail as WaterGoalDetail)?.water
    const [newGoal, setNewGoal] = useState(0)
    const [measurement, setMeasurement] = useState(MeasureUnit.Ml)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        setNewGoal(waterGoal)
    }, [waterGoal])

    useEffect(() => {
        if (intakeMeasurement) {
            setMeasurement(intakeMeasurement)
        }
    }, [intakeMeasurement])

    const onSave = useCallback(() => {
        setSaveLoading(true)

        saveWaterGoal(newGoal, measurement)
            .then(() => {
                onClose()
            })
            .finally(() => {
                setSaveLoading(false)
            })
    }, [newGoal, measurement, saveWaterGoal, onClose])
    return (
        <LeafDialog
            open={open}
            maxWidth="md"
            fullScreen={isMobile}
            onClose={onClose}
            PaperProps={{
                sx: {
                    position: 'relative',
                    overflow: 'hidden'
                }
            }}
            dialogTitle={
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Edit your water goal"
                        description="hydration goal title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <Grid container py={2} spacing={2}>
                    <Grid item xs={12} marginBottom={isMobile ? 2 : 0}>
                        <CurrentDailyGoal
                            goal={newGoal}
                            measurement={measurement}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DailyGoalMeasurement
                            measurement={measurement}
                            setMeasurement={setMeasurement}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DailyGoalTracker
                            newGoal={newGoal}
                            setNewGoal={setNewGoal}
                            measurement={measurement}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack
                    gap={1}
                    width="100%"
                    px={isMobile ? 2 : 0}
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Cancel"
                                description="cancel button text"
                            />
                        </CoreTypography>
                    </Button>
                    <LoadingButton
                        variant="contained"
                        disabled={
                            saveLoading ||
                            (newGoal === waterGoal &&
                                measurement === intakeMeasurement)
                        }
                        loading={saveLoading}
                        fixWidth={true}
                        onClick={onSave}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Save new goal"
                                description="save goal button text"
                            />
                        </CoreTypography>
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}

export default HydrationDailyGoalDialog
