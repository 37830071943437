import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCard from '../WaveCard'

const messages = defineMessages({
    totalActivity: {
        defaultMessage: 'Activity points total',
        description: 'total community points title'
    }
})

interface CardActivityPointsProps {
    totalSteps?: number
    loading: boolean
    error: boolean
}

const CardActivityPoints: React.FC<CardActivityPointsProps> = ({
    totalSteps,
    loading,
    error
}) => {
    const { formatMessage } = useIntl()

    return (
        <>
            {!error && (
                <WaveCard
                    title={formatMessage(messages.totalActivity)}
                    loading={loading}
                >
                    <ConditionalSkeleton
                        showSkeleton={loading}
                        variant="rounded"
                    >
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            color="text.secondary"
                        >
                            {totalSteps}
                        </CoreTypography>
                    </ConditionalSkeleton>
                </WaveCard>
            )}
        </>
    )
}

export default memo(CardActivityPoints)
