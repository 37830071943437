import { ChallengeType } from '../../shared/enums'
import {
    GroupChallengeType,
    SocialGroup,
    UnifiedChallenge
} from '../../graphql/generated/autogenerated'
import {
    type CHALLENGE_VARIANT_TYPE,
    CHALLENGE_THEME,
    CHALLENGE_VARIANT
} from '../constants'
import { addDays } from '@thriveglobal/thrive-web-core'
import parseISO from 'date-fns/parseISO'
import { GQLNullValue } from '../../shared/utils/Nulls'

/**
 * Retrieves the challenge type from a UnifiedChallenge object.
 */
export const getChallengeType = (challenge: UnifiedChallenge) => {
    // Get the challenge type from the active challenge
    let challengeType = challenge.challenge_type as CHALLENGE_VARIANT_TYPE

    // If the challenge is a group challenge and the group challenge type is personal,
    // change the challenge type to personal
    if (challengeType === CHALLENGE_VARIANT.GROUP) {
        if (challenge.groupChallengeType === GroupChallengeType.Personal) {
            challengeType = CHALLENGE_VARIANT.PERSONAL
        }
    }

    return challengeType
}

export const isSoloChallenge = (challenge?: UnifiedChallenge) => {
    return (
        challenge?.challenge_type === ChallengeType.group &&
        challenge?.totalParticipants === 1
    )
}

export const isChallengeStarted = (challenge?: UnifiedChallenge) => {
    return !!(challenge?.dayOfChallenge && challenge?.dayOfChallenge > 0)
}

export const isChallengeExpired = (challenge?: UnifiedChallenge) => {
    const day = challenge?.dayOfChallenge ?? 0
    const duration = Number(challenge?.duration)
    return day > duration
}

export const isChallengeCompleted = (challenge?: UnifiedChallenge) => {
    return challenge?.dayOfChallenge && challenge?.dayOfChallenge > 0
}

export const getChallengeDates = (challenge?: UnifiedChallenge) => {
    const duration = Number(challenge?.duration)
    const startDate = parseISO(challenge?.companyChallengeStartDay)
    const endDate = addDays(startDate, duration - 1) // -1 since inclusive of startDate

    return { startDate, endDate }
}

export const getElapsedTime = (challenge?: UnifiedChallenge) => {
    const day = challenge?.dayOfChallenge ?? 0
    return 1 - day
}

export const getRemainingDays = (challenge?: UnifiedChallenge) => {
    const day = challenge?.dayOfChallenge ?? 0
    const duration = Number(challenge?.duration)
    return duration + 1 - day
}

export const showAvgLeaderboard = (challenge?: UnifiedChallenge) => {
    return (
        challenge?.maxTeamSize === GQLNullValue ||
        challenge?.maxTeamSize === undefined
    )
}

export const isMovementChallenge = (challenge?: UnifiedChallenge) => {
    return challenge?.theme === CHALLENGE_THEME.MOVEMENT
}

export const getChallengeStartDate = (challenge?: UnifiedChallenge) => {
    return challenge?.companyChallengeStartDay
        ? parseISO(challenge.companyChallengeStartDay)
        : undefined
}

export const getChallengeEndDate = (challenge?: UnifiedChallenge) => {
    const startDate = getChallengeStartDate(challenge)
    return startDate && challenge?.duration
        ? addDays(startDate, challenge.duration)
        : undefined
}

interface GetCanFirePostJoinQueriesProps {
    challenge?: UnifiedChallenge
    challengeStarted: boolean
    challengeLoading: boolean
    socialGroup?: SocialGroup | null
}

export const getCanFirePostJoinQueries = ({
    challenge,
    challengeStarted,
    challengeLoading,
    socialGroup
}: GetCanFirePostJoinQueriesProps) => {
    return !!challenge && !!socialGroup && challengeStarted && !challengeLoading
}
