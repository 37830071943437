import { useCallback, useEffect, useState } from 'react'
import {
    type ChallengeUserDailyGoal,
    type WaterGoalDetail,
    type UnifiedChallenge,
    MeasureUnit,
    useAddWaterIntakeMutation,
    useCompleteCommunityChallengeDailyGoalMutation,
    useSetWaterCustomizationMutation
} from '../../../graphql/generated/autogenerated'
import useDebounce from '../../hooks/useDebounce/useDebounce'

type UseHydrationTrackerProps = {
    challenge: UnifiedChallenge
    dailyGoalRefetch?: () => Promise<void>
    initialIntake: number
    measureUnitSettings: MeasureUnit | null
}

export function useHydrationTracker({
    challenge,
    dailyGoalRefetch,
    initialIntake,
    measureUnitSettings
}: UseHydrationTrackerProps) {
    const [glasses, setGlasses] = useState(8)
    const [waterIntake, setWaterIntake] = useState(0)
    const [goal, setGoal] = useState<ChallengeUserDailyGoal>()
    const [goalCount, setGoalCount] = useState<number>(8)
    const [refetchDebounceRequested, setRefetchDebounceRequested] =
        useState(false)
    const [intakeMeasurement, setIntakeMeasurement] = useState(MeasureUnit.Oz)
    const [addIntakeLoading, setAddIntakeLoading] = useState(false)

    const { actionOnTimeout: dailyGoalRefetchDebounced } = useDebounce(
        async () => {
            setAddIntakeLoading(true)
            if (dailyGoalRefetch) {
                await dailyGoalRefetch().finally(() => {
                    setAddIntakeLoading(false)
                    setRefetchDebounceRequested(false)
                })
            } else {
                setAddIntakeLoading(false)
                setRefetchDebounceRequested(false)
            }
        }
    )

    const [AddWaterIntake] = useAddWaterIntakeMutation({
        variables: {
            amount: glasses - waterIntake,
            challengeId: challenge?.id,
            date: null
        },
        onCompleted: async () => {
            setWaterIntake(glasses)
        }
    })

    const onDailyGoalRefetchDebounced = useCallback(
        (timeout = 1000) => {
            setRefetchDebounceRequested(true)
            dailyGoalRefetchDebounced(timeout)
        },
        [dailyGoalRefetchDebounced]
    )

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        },
        onCompleted: async () => {
            onDailyGoalRefetchDebounced()
        }
    })

    const [setWaterCustomizationMutation] = useSetWaterCustomizationMutation()

    const onAddWaterIntake = useCallback(
        (newGlasses: number) => {
            return AddWaterIntake()
                .then(() => {
                    if (
                        !goal?.completed &&
                        newGlasses >=
                            (goal?.goalEntityDetail as WaterGoalDetail)?.water
                    ) {
                        completeDailyGoal()
                    } else {
                        onDailyGoalRefetchDebounced()
                    }
                })
                .catch(() => setAddIntakeLoading(false))
        },
        [goal, AddWaterIntake, completeDailyGoal, onDailyGoalRefetchDebounced]
    )

    const { actionOnTimeout: addWaterIntakeDebounced } =
        useDebounce(onAddWaterIntake)

    const addIntake = useCallback(
        (add = true) => {
            setGlasses((glasses) => {
                const newGlasses = glasses + (add ? 1 : -1)
                addWaterIntakeDebounced(1000, newGlasses)
                return newGlasses
            })

            if (refetchDebounceRequested) {
                onDailyGoalRefetchDebounced(3000)
            }
        },
        [
            addWaterIntakeDebounced,
            onDailyGoalRefetchDebounced,
            refetchDebounceRequested
        ]
    )

    const saveWaterGoal = useCallback(
        async (waterAmount: number, measureUnit: MeasureUnit) => {
            await setWaterCustomizationMutation({
                variables: {
                    challengeId: challenge?.id,
                    waterAmount,
                    measureUnit
                }
            })

            if (waterAmount <= glasses) {
                completeDailyGoal()
            } else {
                onDailyGoalRefetchDebounced()
            }
        },
        [
            onDailyGoalRefetchDebounced,
            setWaterCustomizationMutation,
            completeDailyGoal,
            glasses,
            challenge?.id
        ]
    )

    useEffect(() => {
        if (measureUnitSettings) {
            setIntakeMeasurement(measureUnitSettings)
        }
    }, [measureUnitSettings])

    useEffect(() => {
        setWaterIntake(initialIntake)
        setGlasses(initialIntake)
    }, [initialIntake])

    return {
        waterIntake,
        glasses,
        addIntakeLoading,
        goal,
        goalCount,
        intakeMeasurement,
        setGoalCount,
        setIntakeMeasurement,
        saveWaterGoal,
        addIntake,
        setGoal,
        setGlasses
    }
}
