import { Grid } from '@mui/material'
import CompletedChallengeCardSkeleton from '../../CompletedChallengeCard/CompletedChallengeCardSkeleton'

type ChallengeGridSkeletonProps = {
    count: number
}

const CompletedChallengeGridSkeleton = ({
    count
}: ChallengeGridSkeletonProps) => {
    return (
        <Grid container spacing={4}>
            {Array.from({ length: count }).map((_, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                    <CompletedChallengeCardSkeleton />
                </Grid>
            ))}
        </Grid>
    )
}

export default CompletedChallengeGridSkeleton
