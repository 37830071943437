import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { memo } from 'react'

const CompleteDailyGoals: React.FC<SvgIconProps> = ({ ...props }) => {
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 160 161'}
            sx={{ width: 160, height: 161, fill: 'transparent' }}
            {...props}
        >
            <g filter="url(#filter0_d_951_164212)">
                <circle cx="69.6115" cy="65.7219" r="49.3889" fill="white" />
                <circle
                    cx="69.6115"
                    cy="65.7219"
                    r="48.8889"
                    stroke="#253858"
                    stroke-opacity="0.16"
                />
            </g>
            <circle cx="110.445" cy="24.1104" r="21" fill="#F2947F" />
            <g clipPath="url(#clip0_951_164212)">
                <path
                    d="M105.403 20.533L105.044 18.8317C104.979 18.4957 105.049 18.1478 105.239 17.8629C105.428 17.5779 105.722 17.3788 106.056 17.3083C106.391 17.2378 106.74 17.3016 107.028 17.486C107.317 17.6703 107.521 17.9605 107.597 18.294L107.956 19.9964"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M109.029 25.1019L107.956 19.9958C107.916 19.8266 107.911 19.6513 107.941 19.4801C107.97 19.3089 108.033 19.1453 108.126 18.9989C108.22 18.8524 108.341 18.7261 108.484 18.6273C108.627 18.5284 108.788 18.4591 108.958 18.4234C109.128 18.3877 109.304 18.3863 109.474 18.4192C109.645 18.4521 109.807 18.5188 109.951 18.6153C110.096 18.7117 110.219 18.8361 110.315 18.981C110.411 19.1259 110.477 19.2884 110.509 19.4591L111.046 22.0122"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M111.852 25.8426L111.046 22.013C111.006 21.8438 111.001 21.6685 111.03 21.4973C111.06 21.3261 111.123 21.1625 111.216 21.016C111.31 20.8696 111.431 20.7433 111.574 20.6444C111.717 20.5456 111.878 20.4763 112.048 20.4406C112.218 20.4049 112.393 20.4034 112.564 20.4364C112.734 20.4693 112.897 20.536 113.041 20.6325C113.186 20.7289 113.309 20.8533 113.405 20.9982C113.501 21.1431 113.567 21.3056 113.599 21.4763L115.389 29.9862C115.507 30.5451 115.513 31.1219 115.408 31.6834C115.303 32.245 115.088 32.7803 114.776 33.2589C114.464 33.7374 114.061 34.1498 113.59 34.4724C113.118 34.795 112.588 35.0215 112.029 35.139L109.191 35.7359C108.473 35.8862 107.73 35.853 107.029 35.6394C106.328 35.4258 105.693 35.0386 105.181 34.5138C103.23 32.514 100.612 29.0956 100.612 29.0956C100.425 28.9225 100.308 28.687 100.282 28.4335C100.257 28.18 100.326 27.926 100.475 27.7195C100.624 27.513 100.843 27.3681 101.092 27.3123C101.341 27.2565 101.601 27.2936 101.824 27.4166L104.644 29.5788L102.849 21.0709C102.778 20.7322 102.844 20.3791 103.033 20.0892C103.222 19.7993 103.518 19.5964 103.857 19.5251C104.196 19.4538 104.549 19.5199 104.839 19.709C105.129 19.8981 105.332 20.1946 105.403 20.5333L106.472 25.6403"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M114.228 16.8739L114.598 15.1141C114.678 14.7704 114.889 14.4716 115.187 14.2819C115.485 14.0922 115.845 14.0266 116.19 14.0992C116.535 14.1718 116.839 14.3768 117.035 14.6703C117.231 14.9638 117.304 15.3224 117.239 15.6693L116.868 17.43"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M111.588 16.3191C111.624 16.1458 111.695 15.9814 111.795 15.8352C111.895 15.689 112.022 15.564 112.171 15.4672C112.319 15.3704 112.485 15.3038 112.659 15.2712C112.833 15.2385 113.012 15.2405 113.185 15.2769C113.359 15.3134 113.523 15.3836 113.669 15.4836C113.815 15.5836 113.94 15.7114 114.037 15.8597C114.134 16.0081 114.201 16.174 114.233 16.3481C114.266 16.5222 114.264 16.701 114.227 16.8743"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M116.306 20.1036L116.868 17.43C116.901 17.2535 116.969 17.0854 117.068 16.9355C117.167 16.7857 117.295 16.6571 117.444 16.5573C117.594 16.4576 117.762 16.3886 117.938 16.3546C118.114 16.3205 118.296 16.322 118.471 16.3589C118.647 16.3959 118.814 16.4676 118.962 16.5698C119.109 16.672 119.235 16.8026 119.332 16.9541C119.428 17.1056 119.494 17.2748 119.524 17.4518C119.554 17.6289 119.549 17.8102 119.508 17.9852L118.014 26.007L120.568 24.5486C120.798 24.4236 121.066 24.3868 121.322 24.445C121.578 24.5032 121.803 24.6525 121.957 24.8649C122.111 25.0773 122.182 25.3384 122.157 25.5994C122.132 25.8604 122.013 26.1034 121.822 26.2831C121.822 26.2831 120.678 27.7774 119.322 29.3942"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M103.479 13.9512L104.042 15.4348"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M107.446 14.1348L107.152 15.1303"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M100.285 16.2227L101.192 17.0889"
                    stroke="white"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M61.4452 76.2217H49.0008C48.1756 76.2217 47.3843 76.5495 46.8009 77.1329C46.2174 77.7164 45.8896 78.5077 45.8896 79.3328V88.6661H61.4452V76.2217Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M89.4454 76.2217H77.001V88.6661H92.5565V79.3328C92.5565 78.5077 92.2288 77.7164 91.6453 77.1329C91.0619 76.5495 90.2705 76.2217 89.4454 76.2217Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M73.8898 63.7764H64.5564C63.7313 63.7764 62.94 64.1041 62.3565 64.6876C61.7731 65.271 61.4453 66.0624 61.4453 66.8875V88.6653H77.0009V66.8875C77.0009 66.0624 76.6731 65.271 76.0896 64.6876C75.5062 64.1041 74.7149 63.7764 73.8898 63.7764Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M63.7783 47.4435C63.7783 48.8874 64.3519 50.2722 65.373 51.2933C66.394 52.3143 67.7788 52.8879 69.2228 52.8879C70.6667 52.8879 72.0515 52.3143 73.0726 51.2933C74.0936 50.2722 74.6672 48.8874 74.6672 47.4435C74.6672 45.9995 74.0936 44.6147 73.0726 43.5937C72.0515 42.5726 70.6667 41.999 69.2228 41.999C67.7788 41.999 66.394 42.5726 65.373 43.5937C64.3519 44.6147 63.7783 45.9995 63.7783 47.4435Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M75.9619 57.5546C75.1307 56.5786 74.0975 55.7948 72.9337 55.2573C71.7698 54.7198 70.5032 54.4414 69.2212 54.4414C67.9392 54.4414 66.6726 54.7198 65.5087 55.2573C64.3449 55.7948 63.3117 56.5786 62.4805 57.5546"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M80.373 61.4435C80.373 62.8874 80.9467 64.2722 81.9677 65.2933C82.9887 66.3143 84.3735 66.8879 85.8175 66.8879C87.2614 66.8879 88.6463 66.3143 89.6673 65.2933C90.6883 64.2722 91.2619 62.8874 91.2619 61.4435C91.2619 59.9995 90.6883 58.6147 89.6673 57.5937C88.6463 56.5726 87.2614 55.999 85.8175 55.999C84.3735 55.999 82.9887 56.5726 81.9677 57.5937C80.9467 58.6147 80.373 59.9995 80.373 61.4435Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.556 71.5546C91.4366 70.2384 89.9558 69.2793 88.2969 68.796C86.638 68.3127 84.8738 68.3264 83.2227 68.8355"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M47.1836 61.4435C47.1836 62.1584 47.3244 62.8664 47.598 63.527C47.8716 64.1875 48.2727 64.7877 48.7782 65.2933C49.2838 65.7988 49.884 66.1999 50.5445 66.4735C51.2051 66.7471 51.9131 66.8879 52.628 66.8879C53.343 66.8879 54.051 66.7471 54.7115 66.4735C55.3721 66.1999 55.9723 65.7988 56.4778 65.2933C56.9834 64.7877 57.3844 64.1875 57.658 63.527C57.9317 62.8664 58.0725 62.1584 58.0725 61.4435C58.0725 60.7285 57.9317 60.0205 57.658 59.36C57.3844 58.6994 56.9834 58.0992 56.4778 57.5937C55.9723 57.0881 55.3721 56.6871 54.7115 56.4135C54.051 56.1398 53.343 55.999 52.628 55.999C51.9131 55.999 51.2051 56.1398 50.5445 56.4135C49.884 56.6871 49.2838 57.0881 48.7782 57.5937C48.2727 58.0992 47.8716 58.6994 47.598 59.36C47.3244 60.0205 47.1836 60.7285 47.1836 61.4435Z"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.8896 71.5545C46.7226 70.5807 47.7562 69.7985 48.9197 69.2615C50.0832 68.7246 51.349 68.4455 52.6304 68.4434C53.5057 68.444 54.3761 68.5754 55.2126 68.8333"
                stroke="#F2947F"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_951_164212"
                    x="1.22266"
                    y="1.33301"
                    width="158.777"
                    height="158.777"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="11" dy="15" />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.580392 0 0 0 0 0.498039 0 0 0 0.24 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_951_164212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_951_164212"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_951_164212">
                    <rect
                        width="23.3333"
                        height="23.3333"
                        fill="white"
                        transform="translate(99.5557 13.2217)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default memo(CompleteDailyGoals)
