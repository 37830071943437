import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    YesterdaysCommunityChallengeUserDailyGoalsQuery,
    useYesterdaysCommunityChallengeUserDailyGoalsQuery
} from '../../graphql/generated/autogenerated'
import { GQLNull } from '../../shared/utils/Nulls'

export interface YesterdaysRecapData {
    recap?: YesterdaysCommunityChallengeUserDailyGoalsQuery['unifiedChallenges']['communityChallengeUserDailyGoals']
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<
        Apollo.ApolloQueryResult<YesterdaysCommunityChallengeUserDailyGoalsQuery>
    >
}

export interface YesterdaysRecapProps {
    challengeId: string | GQLNull
    challengeDay: number | GQLNull
}

export const useGetYesterdaysRecap = ({
    challengeId,
    challengeDay
}: YesterdaysRecapProps) => {
    const { data, loading, error, refetch } =
        useYesterdaysCommunityChallengeUserDailyGoalsQuery({
            variables: {
                challengeId: challengeId,
                challengeDay: Number(challengeDay) - 1
            },
            skip: !challengeId || !challengeDay
        })

    return useMemo<YesterdaysRecapData>(() => {
        return {
            recap: data?.unifiedChallenges?.communityChallengeUserDailyGoals,
            loading,
            error,
            refetch
        }
    }, [data, loading, error, refetch])
}

export default useGetYesterdaysRecap
