import { useParams } from 'react-router-dom'
import {
    ChallengeTeamDailyGoal,
    CommunityChallengeTeamDailyGoalsQuery,
    CommunityChallengeTeamDailyGoalsQueryVariables,
    useCommunityChallengeTeamDailyGoalsQuery
} from '../../../graphql/generated/autogenerated'
import usePagination, {
    PaginationOptions
} from '../../../shared/hooks/usePagination/usePagination'

export type UseGetSocialGroupGoalsActivityValue = {
    teamDailyGoalProgress: ChallengeTeamDailyGoal[]
    error: boolean
    loading: boolean
    loadingNext: boolean
    hasMore: boolean
    refetch: () => void
    fetchMore: () => Promise<unknown>
}

export function useGetSocialGroupGoalsActivity(
    params?: PaginationOptions<
        ChallengeTeamDailyGoal,
        CommunityChallengeTeamDailyGoalsQuery,
        CommunityChallengeTeamDailyGoalsQueryVariables
    >
): UseGetSocialGroupGoalsActivityValue {
    const { challengeId } = useParams<{ challengeId: string }>()

    const {
        data,
        loading,
        error,
        loadingNext,
        hasMore,
        handleLoadMore,
        refetch
    } = usePagination<
        ChallengeTeamDailyGoal,
        CommunityChallengeTeamDailyGoalsQuery,
        CommunityChallengeTeamDailyGoalsQueryVariables
    >({
        ...params,
        defaultOffset: 10,
        query: useCommunityChallengeTeamDailyGoalsQuery,
        queryProps: {
            challengeId: challengeId
        },
        dataTransform: (data) => {
            return data?.unifiedChallenges
                ?.communityChallengeTeamDailyGoals as ChallengeTeamDailyGoal[]
        }
    })

    return {
        teamDailyGoalProgress: data,
        error: Boolean(error),
        loading,
        loadingNext,
        hasMore,
        refetch,
        fetchMore: handleLoadMore
    }
}
