import { Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import CommunityPlant1 from '../../../../shared/components/icons/plants/communityPlant1'
import CommunityPlant10 from '../../../../shared/components/icons/plants/communityPlant10'
import CommunityPlant11 from '../../../../shared/components/icons/plants/communityPlant11'
import CommunityPlant12 from '../../../../shared/components/icons/plants/communityPlant12'
import CommunityPlant13 from '../../../../shared/components/icons/plants/communityPlant13'
import CommunityPlant14 from '../../../../shared/components/icons/plants/communityPlant14'
import CommunityPlant15 from '../../../../shared/components/icons/plants/communityPlant15'
import CommunityPlant16 from '../../../../shared/components/icons/plants/communityPlant16'
import CommunityPlant17 from '../../../../shared/components/icons/plants/communityPlant17'
import CommunityPlant18 from '../../../../shared/components/icons/plants/communityPlant18'
import CommunityPlant2 from '../../../../shared/components/icons/plants/communityPlant2'
import CommunityPlant3 from '../../../../shared/components/icons/plants/communityPlant3'
import CommunityPlant4 from '../../../../shared/components/icons/plants/communityPlant4'
import CommunityPlant5 from '../../../../shared/components/icons/plants/communityPlant5'
import CommunityPlant6 from '../../../../shared/components/icons/plants/communityPlant6'
import CommunityPlant7 from '../../../../shared/components/icons/plants/communityPlant7'
import CommunityPlant8 from '../../../../shared/components/icons/plants/communityPlant8'
import CommunityPlant9 from '../../../../shared/components/icons/plants/communityPlant9'
import PlantIconSkeleton from '../../CompanyChallenge/ChallengePlantCard/PlantIconSkeleton'

const plantComponents = [
    CommunityPlant1,
    CommunityPlant2,
    CommunityPlant3,
    CommunityPlant4,
    CommunityPlant5,
    CommunityPlant6,
    CommunityPlant7,
    CommunityPlant8,
    CommunityPlant9,
    CommunityPlant10,
    CommunityPlant11,
    CommunityPlant12,
    CommunityPlant13,
    CommunityPlant14,
    CommunityPlant15,
    CommunityPlant16,
    CommunityPlant17,
    CommunityPlant18
]

const ChallengeGardenSkeleton: React.FC = () => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const randomPlantComponents = useMemo(() => {
        return Array.from({ length: isMobile ? 9 : 24 }).map(
            () =>
                plantComponents[
                    Math.floor(Math.random() * plantComponents.length)
                ]
        )
    }, [isMobile])

    return (
        <Card elevation={0}>
            <Stack p={2} height={400} gap={4}>
                <Skeleton
                    variant="rounded"
                    height={20}
                    width={150}
                    sx={{ maxWidth: '100%' }}
                />
                <Stack direction="row" flexWrap="wrap" gap={2}>
                    {randomPlantComponents.map((PlantComponent, index) => (
                        <PlantIconSkeleton
                            key={index}
                            PlantComponent={PlantComponent}
                        />
                    ))}
                </Stack>
            </Stack>
        </Card>
    )
}

export default ChallengeGardenSkeleton
