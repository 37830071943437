import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import MultiStatWaveCard from '../WaveCard/MultiStatWaveCard'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import { MeasureUnit } from '../../../../../graphql/generated/autogenerated'

import useGlassesMeasurementConversion from '../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'

const messages = defineMessages({
    dailyAverage: {
        defaultMessage: 'Daily Avg.',
        description: 'daily average description'
    },
    total: {
        defaultMessage: 'Total',
        description: 'total description'
    }
})

export type AverageWaterIntakeProps = {
    elevation?: number
    userTotalWater?: number
    averageIndividualWater?: number
}

const AverageWaterIntake: React.FC<AverageWaterIntakeProps> = ({
    elevation,
    userTotalWater,
    averageIndividualWater
}) => {
    const { formatMessage } = useIntl()

    const totalWater = useGlassesMeasurementConversion(
        userTotalWater ?? 0,
        MeasureUnit.Oz
    )

    const averageWater = useGlassesMeasurementConversion(
        averageIndividualWater ?? 0,
        MeasureUnit.Oz
    )

    const stats = useMemo(
        () => [
            {
                name: formatMessage(messages.dailyAverage) || '',
                value: averageWater
            },
            {
                name: formatMessage(messages.total),
                value: totalWater
            }
        ],
        [averageWater, formatMessage, totalWater]
    )

    return (
        <MultiStatWaveCard
            stats={stats}
            elevation={elevation}
            waveColor="blue"
        />
    )
}

export default memo(AverageWaterIntake)
