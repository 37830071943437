import {
    DEFAULT_VOLUME,
    useIsFeatureEnabled,
    useVolume
} from '@thriveglobal/thrive-web-core'
import { useState, useCallback, useMemo } from 'react'

export type UseResetPlayerVolumeResult = [number, (value: number) => void]

// NOTE: Temporary wrapper, remove and replace with useVolume and when IsVolumeStorageEnabled is enabled for all users
function useResetPlayerVolume(): UseResetPlayerVolumeResult {
    const isVolumeStorageEnabled = useIsFeatureEnabled(
        'isResetVolumeStorageEnabled',
        false,
        true
    )

    const [stateVolume, setStateVolume] = useState(DEFAULT_VOLUME)
    const [storageVolume, setStorageVolume] = useVolume()

    const volume = isVolumeStorageEnabled ? storageVolume : stateVolume

    const handleSetVolume = useCallback(
        (newVolume: number) => {
            if (isVolumeStorageEnabled) {
                setStorageVolume(newVolume)
            } else {
                setStateVolume(newVolume)
            }
        },
        [isVolumeStorageEnabled, setStorageVolume]
    )

    return useMemo(() => [volume, handleSetVolume], [volume, handleSetVolume])
}

export default useResetPlayerVolume
