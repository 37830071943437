import { Box, Button, DialogTitle, Fade, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { MutableRefObject, useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { QuestionType } from '../../../../../enums/questionType'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import useStorySubmissionContext from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/useStorySubmissionContext'
import { useInterstitialNavigation } from '../../../../contexts/withInterstitialNavigation'
import SlideUp from '../../../elements/SlideUp'

export type StoryQuestionProps = {
    questionData: QuestionData
    isActive: boolean
    containerRef?: MutableRefObject<any>
    children: React.ReactNode
}

const messages = defineMessages({
    skip: {
        defaultMessage: 'Skip',
        description:
            'button text that will skip the current question in a list of questions and move onto the next one'
    },
    next: {
        defaultMessage: 'Next',
        description:
            'button text that will go to the next question in a list of questions'
    }
})

const StoryQuestion: React.FC<StoryQuestionProps> = ({
    questionData,
    isActive,
    containerRef,
    children
}) => {
    const theme = useTheme()
    const intl = useIntl()

    const { next } = useInterstitialNavigation()

    const { challenge, participation, interactionId } =
        useStorySubmissionContext()

    const onNextQuestion = useCallback(() => {
        if (!!questionData.answer && questionData.type === QuestionType.text) {
            Avo.submissionStarted({
                activityType: 'story_answer_added',
                challengeId: challenge?.id,
                challengeTheme: challenge?.theme,
                challengeType: challenge?.challenge_type,
                contentFormatType: null,
                contentId: participation?.id,
                contentSource: null,
                contentSubtype: null,
                contentTitle: questionData.question,
                contentType: null,
                contentUrl: participation?.id,
                dayNumber: null,
                featureType: 'challenge',
                teamId: null,
                teamType: null
            })
        }

        next()
    }, [challenge, participation, questionData, next])

    return (
        <Fade in={isActive} timeout={400}>
            <Box>
                <SlideUp
                    in={isActive}
                    {...(!!containerRef && {
                        container: containerRef.current
                    })}
                    mountOnEnter
                    unmountOnExit
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center'
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexGrow: 1,
                                maxWidth: theme.spacing(62.75)
                            }}
                            data-testid="story-question"
                        >
                            <DialogTitle
                                sx={{
                                    p: 0
                                }}
                            >
                                <CoreTypography
                                    id="story-question"
                                    variant="h5"
                                    sx={{
                                        mb: theme.spacing(2),
                                        color: theme.palette.primary.main,
                                        fontSize: theme.spacing(3)
                                    }}
                                >
                                    {questionData.question}
                                </CoreTypography>
                            </DialogTitle>
                            {children}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    variant="text"
                                    sx={{
                                        alignItems: 'center',
                                        p: `${theme.spacing(2)} ${theme.spacing(
                                            4
                                        )}`,
                                        borderRadius: theme.spacing(1),
                                        mr: theme.spacing(1)
                                    }}
                                    data-testid="skip-button"
                                    onClick={next}
                                    aria-label={intl.formatMessage(
                                        messages.skip
                                    )}
                                >
                                    <CoreTypography
                                        customVariant="buttonNormal"
                                        sx={{ textAlign: 'center' }}
                                    >
                                        {intl.formatMessage(messages.skip)}
                                    </CoreTypography>
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignItems: 'center',
                                        p: `${theme.spacing(2)} ${theme.spacing(
                                            6
                                        )}`,
                                        borderRadius: theme.spacing(1)
                                    }}
                                    data-testid="next-button"
                                    onClick={onNextQuestion}
                                    aria-label={intl.formatMessage(
                                        messages.next
                                    )}
                                >
                                    <CoreTypography
                                        customVariant="buttonNormal"
                                        sx={{ textAlign: 'center' }}
                                    >
                                        {intl.formatMessage(messages.next)}
                                    </CoreTypography>
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </SlideUp>
            </Box>
        </Fade>
    )
}

export default StoryQuestion
