import { Button, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'

const CompletedChallengeCardFooter = ({
    onViewClick
}: {
    onViewClick?: () => void
}) => {
    const { formatMessage } = useIntl()

    const viewButtonText = formatMessage({
        defaultMessage: 'View',
        description: 'View button text'
    })

    const viewButtonAriaLabel = formatMessage({
        defaultMessage: 'View',
        description: 'View button aria label'
    })

    return (
        <Stack gap={0.5}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                gap={2}
                pt={4}
            >
                <Button
                    onClick={onViewClick || (() => {})}
                    color="primary"
                    size="large"
                    aria-label={viewButtonAriaLabel}
                    sx={{ gap: 1, ml: 'auto' }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        {viewButtonText}
                    </CoreTypography>
                    <LeafIcon fontSize="small" icon="arrow-right" />
                </Button>
            </Stack>
        </Stack>
    )
}

export default CompletedChallengeCardFooter
