import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../../../graphql/generated/autogenerated'
import { withMultiStepForm } from '../../../../../contexts/withMultiStepForm/withMultiStepForm'
import useChallengeTemplateSignup from '../../../../../hooks/useChallengeTemplateSignup/useChallengeTemplateSignup'
import IntroTemplateChallengeDialog from './IntroTemplateChallengeDialog'

type IntroJoinChallengeDialogProps = {
    open: boolean
    groupChallengeType?: GroupChallengeType
    onClose: () => void
    challenge?: UnifiedChallenge
}

const IntroJoinChallengeDialog: React.FC<IntroJoinChallengeDialogProps> = ({
    open,
    groupChallengeType,
    onClose,
    challenge
}) => {
    const { formData, updateFormData, setIsDisplayNameValid, loading, onJoin } =
        useChallengeTemplateSignup({
            groupChallengeType
        })

    if (!challenge) return null

    return (
        <IntroTemplateChallengeDialog
            dialogTitle={
                <FormattedMessage
                    defaultMessage="Join Challenge"
                    description="Join the Challenge dialog title"
                />
            }
            open={open}
            challenge={challenge}
            groupChallengeType={groupChallengeType}
            formData={formData}
            updateFormData={updateFormData}
            setIsDisplayNameValid={setIsDisplayNameValid}
            shouldDisplayStartDate={false}
            onClose={onClose}
            onSubmit={onJoin}
            loading={loading}
            onSubmitButtonLabel={
                <FormattedMessage
                    defaultMessage="Join Challenge"
                    description="Join the Challenge button text"
                />
            }
        />
    )
}

export default withMultiStepForm(IntroJoinChallengeDialog)
