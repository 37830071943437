import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CHALLENGE_TYPE } from '../../constants'
import type { UnifiedChallenge } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    prizeChallenge: {
        defaultMessage: 'prize challenge',
        description: 'prize challenge title'
    },
    multiJourney: {
        defaultMessage: 'multi-journey',
        description: 'multiple journey title'
    }
})

/**
 * Custom hook to get the category of a challenge.
 * @param {UnifiedChallenge} [challenge] - The challenge object to extract the category from.
 * @returns {string | undefined} The category of the challenge in uppercase, or undefined if not applicable.
 */
export const useGetCategory = (challenge?: UnifiedChallenge) => {
    const { locale, formatMessage } = useIntl()

    return useMemo(() => {
        if (challenge?.journeys && challenge.journeys.length > 1) {
            return formatMessage(messages.multiJourney)?.toLocaleUpperCase(
                locale
            )
        } else if (challenge?.journeys && challenge.journeys.length > 0) {
            return challenge.journeys[0].shortName?.toLocaleUpperCase(locale)
        }

        switch (challenge?.type?.toUpperCase()) {
            case CHALLENGE_TYPE.COMPANY:
            case CHALLENGE_TYPE.GROUP:
            case CHALLENGE_TYPE.JOURNEY: {
                return challenge?.category?.shortName
                    ? challenge?.category?.shortName?.toLocaleUpperCase(locale)
                    : challenge?.category?.name?.toLocaleUpperCase(locale)
            }
            case CHALLENGE_TYPE.BIOTYPE: {
                return challenge?.biotype?.name?.toLocaleUpperCase(locale)
            }
            case CHALLENGE_TYPE.PRIZE: {
                return formatMessage(
                    messages.prizeChallenge
                )?.toLocaleUpperCase(locale)
            }
        }
    }, [challenge, locale, formatMessage])
}

export default useGetCategory
