import { useErrorBoundary } from '@thriveglobal/thrive-web-errors'
import { Card } from '@mui/material'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    BoundaryName,
    BoundaryType,
    withErrorBoundary
} from '../../../decorators/withErrorBoundary/withErrorBoundary'

/**
 * A component that wraps content in an error boundary and displays a fallback error card if an error occurs
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The content to wrap in the error boundary
 * @param {Error} [props.error] - Optional error to trigger the error boundary
 * @returns {JSX.Element} The wrapped content or error card
 */
const ErrorCard = withErrorBoundary(
    BoundaryName.CHALLENGE_RECAP,
    BoundaryType.COMPONENT,
    ({ children, error }: { children: React.ReactNode; error?: Error }) => {
        const { showBoundary } = useErrorBoundary()

        useEffect(() => {
            if (error) {
                showBoundary(error)
            }
        }, [error, showBoundary])

        return <>{children}</>
    },
    () => (
        <Card sx={{ p: 6 }}>
            <FormattedMessage
                defaultMessage="Error loading data"
                description="Error loading data widget text for error card"
            />
        </Card>
    )
)

export default ErrorCard
