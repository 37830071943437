import { ReactNode, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

type CompletedChallengeCardStatsProps = {
    totalEarnedPoints: number
    totalCompletedDays: number
}

const StatLabel = memo(({ label }: { label: string | ReactNode }) => (
    <Grid item xs={6}>
        <Stack data-testid="stat-description" width="100%">
            <CoreTypography variant="overline">{label}</CoreTypography>
        </Stack>
    </Grid>
))

const StatValue = memo(({ value }: { value: number }) => (
    <Grid item xs={6}>
        <Stack data-testid="stat-description" width="100%">
            <CoreTypography customVariant="display3">{value}</CoreTypography>
        </Stack>
    </Grid>
))

const CompletedChallengeCardStats = memo(
    ({
        totalEarnedPoints,
        totalCompletedDays
    }: CompletedChallengeCardStatsProps) => {
        return (
            <Grid container rowSpacing={1}>
                <StatLabel
                    label={
                        <FormattedMessage
                            defaultMessage="GOALS COMPLETED"
                            description="Goals completed label"
                        />
                    }
                />
                <StatLabel
                    label={
                        <FormattedMessage
                            defaultMessage="TOTAL PLANTS"
                            description="Total plants label"
                        />
                    }
                />
                <StatValue value={totalCompletedDays} />
                <StatValue value={totalEarnedPoints} />
            </Grid>
        )
    }
)

export default CompletedChallengeCardStats
