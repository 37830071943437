import { Box, Collapse, Fade } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LabelledCheckbox from '../../../elements/LabelledCheckbox'

export type GetFeaturedProps = {
    checked: boolean
    onStoryTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const messages = defineMessages({
    getFeatured: {
        defaultMessage: 'Get featured on Thrive (optional)',
        description: 'title on how to get your story featured on thrive'
    },
    getFeaturedAria: {
        defaultMessage: 'Feature Your Story With Thrive',
        description: 'feature your story radio button aria label'
    },
    wantToShare: {
        defaultMessage:
            'I want to share my submission with Thrive and my employer, who can use my name and submission to feature me in the platform as a Thrive Role Model for my coworkers. If needed, Thrive may reach out to me to learn more about my submission.',
        description: 'thrive feature your story description'
    },
    submissionNotificationResponseShared: {
        defaultMessage: `By clicking “Submit” below, I understand my responses will be shared with Thrive, for the purpose of potentially sharing my Challenge experience with my company. I understand Thrive may contact me with more questions about my Challenge experience to gather more details and possibly be featured as a Thrive Role Model. I understand submitting my story does not guarantee my story will be featured and that my responses will not be shared as is. I understand my name and email address will be associated with this story, and that my employer will have access to my story. Your story will be shared with Thrive.`,
        description:
            'description when a user selects a story as public on how it will be shared with thrive'
    },
    checkBelow: {
        defaultMessage:
            'Please check the below if you want to share your story:',
        description:
            'description when a user selects a story as public on how it will be shared with thrive'
    }
})

const GetFeatured: React.FC<GetFeaturedProps> = ({
    checked,
    onStoryTypeChange
}) => {
    const { formatMessage } = useIntl()
    const showTakedaCompanyChallengeCopy = useIsFeatureEnabled(
        'takedaStorySubmission',
        false,
        true
    ) as boolean

    return (
        <Box data-testid="get-featured">
            <CoreTypography
                variant="h5"
                sx={{
                    mb: 2
                }}
                color="primary.main"
            >
                {formatMessage(
                    showTakedaCompanyChallengeCopy
                        ? messages.checkBelow
                        : messages.getFeatured
                )}
            </CoreTypography>
            <LabelledCheckbox
                checked={checked}
                onChange={onStoryTypeChange}
                label={formatMessage(messages.wantToShare)}
                inputProps={{
                    'aria-label': formatMessage(messages.getFeaturedAria)
                }}
                data-testid="get-featured-checkbox"
            />
            <Fade in={checked}>
                <Box component="output" aria-live="polite" aria-atomic="true">
                    <Collapse in={checked}>
                        <CoreTypography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                ml: 5.5,
                                mt: 3
                            }}
                        >
                            {formatMessage(
                                messages.submissionNotificationResponseShared
                            )}
                        </CoreTypography>
                    </Collapse>
                </Box>
            </Fade>
        </Box>
    )
}

export default GetFeatured
