import { Card, Icon, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useMemo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModifiedFormattedMessageType } from 'src/types'
import {
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal,
    UnifiedChallenge,
    MeasureUnit
} from '../../../../../../../graphql/generated/autogenerated'
import { useHydration } from '../../../../../../hooks/useHydration/useHydration'
import useGlassesMeasurementConversion from '../../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'
import WaterGlass from '../../../../../icons/WaterGlass'
import GoalTitle from '../../GoalTitle'
import HydrationTracker from './HydrationTracker/HydrationTracker'
import UpdateWaterGoal from './UpdateWaterGoal'
import { useHydrationTracker } from '../../../../../../hooks/useHydrationTracker/useHydrationTracker'

const ModifiedFormattedMessage =
    FormattedMessage as ModifiedFormattedMessageType

export interface HydrationGoalProps extends JSX.IntrinsicAttributes {
    goal:
        | ChallengeUserDailyGoal
        | {
              __typename: undefined
              challengeGoal: ChallengeTemplateGoal
          }
    challenge: UnifiedChallenge
    disabled?: boolean
    onComplete?: () => void
    waterIntake?: number
    measureUnitSettings?: MeasureUnit | null
    dailyGoalRefetch?: () => Promise<void>
}

const HydrationGoal: React.FC<HydrationGoalProps> = ({
    goal,
    challenge,
    waterIntake,
    measureUnitSettings,
    disabled = false,
    onComplete,
    dailyGoalRefetch
}) => {
    const { glasses, addIntake, setGoal, intakeMeasurement, saveWaterGoal } =
        useHydrationTracker({
            challenge,
            dailyGoalRefetch,
            initialIntake: waterIntake ?? 0,
            measureUnitSettings: measureUnitSettings ?? MeasureUnit.Oz
        })

    const waterGoal = useMemo(() => {
        if (
            goal.__typename === 'ChallengeUserDailyGoal' &&
            goal.goalEntityDetail?.__typename === 'WaterGoalDetail'
        ) {
            return goal.goalEntityDetail.water
        } else {
            return goal.challengeGoal.goalWater ?? 0
        }
    }, [goal])

    const formatted = useGlassesMeasurementConversion(
        waterGoal,
        intakeMeasurement
    )

    useEffect(() => {
        if (goal?.__typename === 'ChallengeUserDailyGoal') {
            setGoal(goal)
        }
    }, [setGoal, goal])

    return (
        <Stack gap={2} width="100%" height="100%" data-testid="hydration-goal">
            <Stack gap={2}>
                <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <GoalTitle
                        title={
                            <FormattedMessage
                                defaultMessage="Reach your daily water goal"
                                description="title for the activity daily goal card"
                            />
                        }
                    />
                    <UpdateWaterGoal
                        disabled={disabled || !goal}
                        goal={goal as ChallengeUserDailyGoal}
                        intakeMeasurement={intakeMeasurement}
                        saveWaterGoal={saveWaterGoal}
                    />
                </Stack>
            </Stack>
            <Card elevation={1}>
                <Stack alignItems="center" padding={2} gap={2}>
                    <HydrationTracker
                        goal={goal as ChallengeUserDailyGoal}
                        glasses={glasses}
                        intakeMeasurement={intakeMeasurement}
                        addIntake={addIntake}
                        disabled={disabled}
                        onComplete={onComplete}
                    />
                    <CoreTypography
                        variant="body1"
                        color="text.secondary"
                        as={Stack}
                        flexDirection="row"
                        alignItems="center"
                        gap={0.4}
                    >
                        <ModifiedFormattedMessage
                            id="message.dailyWaterGoal"
                            defaultMessage="Daily water goal <goal>{waterGoal} glasses or {glassesAsMeasurement}</goal>"
                            description="daily water goal E.G Daily water goal 8 glasses or 64oz"
                            values={{
                                waterGoal,
                                glassesAsMeasurement: formatted,
                                goal: (chunk: React.ReactNode[]) => (
                                    <React.Fragment>
                                        <Icon
                                            component={WaterGlass}
                                            sx={{ height: 18.25 }}
                                        />
                                        {chunk}
                                    </React.Fragment>
                                )
                            }}
                        />
                    </CoreTypography>
                </Stack>
            </Card>
        </Stack>
    )
}

export default memo(HydrationGoal)
