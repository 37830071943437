import { Box, Button, DialogTitle, Stack } from '@mui/material'
import {
    BurstNotification,
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import HiddenStatusUpdate from '../../elements/HiddenStatusUpdate/HiddenStatusUpdate'

export type SuccessScreenProps = {
    title: string
    description?: string
    subDescription?: string
    buttonText: string
    onClose: () => void
    active: boolean
}

const SuccessScreen: React.FC<SuccessScreenProps> = ({
    title,
    description,
    subDescription,
    buttonText,
    onClose,
    active
}) => {
    const theme = useTheme()

    return (
        <Box
            data-testid="success-screen"
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    maxWidth: theme.spacing(62.75),
                    m: theme.spacing(2),
                    my: 'auto'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        mb: theme.spacing(2)
                    }}
                    aria-live="polite"
                    aria-atomic="true"
                    component="output"
                >
                    <BurstNotification show={active} alwaysVisible={true}>
                        <LeafIcon icon={'check'} fontSize={'large'} />
                    </BurstNotification>
                </Box>
                <HiddenStatusUpdate status={title} />
                <DialogTitle
                    sx={{
                        p: 0
                    }}
                >
                    <CoreTypography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(2)
                        }}
                    >
                        {title}
                    </CoreTypography>
                </DialogTitle>
                <CoreTypography
                    variant="body1"
                    sx={{
                        color: theme.palette.primary.main,
                        mb: theme.spacing(3)
                    }}
                >
                    {description}
                </CoreTypography>
                <CoreTypography
                    variant="body1"
                    sx={{
                        color: theme.palette.primary.main,
                        mb: theme.spacing(6)
                    }}
                >
                    {subDescription}
                </CoreTypography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            alignItems: 'center',
                            p: `${theme.spacing(2)} ${theme.spacing(6)}`,
                            borderRadius: theme.spacing(1)
                        }}
                        data-testid="close-success-button"
                        onClick={onClose}
                        aria-label={buttonText}
                    >
                        <CoreTypography
                            customVariant="buttonNormal"
                            sx={{ textAlign: 'center' }}
                        >
                            {buttonText}
                        </CoreTypography>
                    </Button>
                </Box>
            </Stack>
        </Box>
    )
}

export default SuccessScreen
