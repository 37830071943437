// External libraries
import { Box, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

// Types
import {
    PlantInfo,
    UnifiedChallenge,
    UserPlantInfo
} from '../../../../../../../graphql/generated/autogenerated'

// Hooks
import { usePlantIcons } from '../../../../../../../hooks/usePlantIcons/usePlantIcons'
import useGoalChallengeData from '../../../../../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'
import useGetSocialGroups from '../../../../../../hooks/useGetSocialGroups/useGetSocialGroups'

// Components
import BasePlant from '../../../../../../../shared/components/icons/plants/basePlant'
import ChallengeGarden from '../../../../Shared/ChallengeGarden'
import { isChallengeStarted } from '../../../../../../utils/challenge'

interface ChallengeTodaysProgressProps {
    challenge?: UnifiedChallenge
}

const ChallengeTodaysProgress: React.FC<ChallengeTodaysProgressProps> = ({
    challenge
}) => {
    const { breakpoints, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { getPlantIcon } = usePlantIcons()
    const { socialGroup } = useGetSocialGroups(challenge?.id)

    const challengeStarted = isChallengeStarted(challenge)

    const { plants, goals } = useGoalChallengeData({
        challenge: challenge as UnifiedChallenge,
        socialGroup,
        loading: false
    })

    const todaysPlants = useMemo(() => {
        let selectedPlantIndex = plants?.length

        return goals.map((goal) => {
            if (goal.completed) {
                --selectedPlantIndex
                return plants[selectedPlantIndex]
            } else {
                return {} as UserPlantInfo
            }
        })
    }, [goals, plants])

    const defaultToDirt = useCallback(
        (plant?: UserPlantInfo | PlantInfo) => {
            const plantData = getPlantIcon(plant)

            if (!plantData?.PlantIcon) {
                plantData.PlantIcon = BasePlant
            }

            return plantData
        },
        [getPlantIcon]
    )

    return (
        <Card elevation={0}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={2}
                p={2}
                sx={{ background: palette.grey[100] }}
            >
                <Stack gap={4}>
                    <CoreTypography variant="overline" color="primary.main">
                        <FormattedMessage
                            defaultMessage="Today’s goal progress"
                            description="description for the todays progress card"
                        />
                    </CoreTypography>
                    <CoreTypography
                        customVariant="form"
                        color="text.secondary"
                        fontSize={24}
                        minWidth={isMobile ? undefined : 300}
                    >
                        <FormattedMessage
                            defaultMessage="Complete daily goals to earn a plant"
                            description="description for earned plants"
                        />
                    </CoreTypography>
                </Stack>
                <Box width="100%">
                    <ChallengeGarden
                        rowSize={1}
                        columnSize={todaysPlants?.length}
                        challengeStarted={challengeStarted}
                        canLoadMore={false}
                        hideEmptyState={true}
                        plants={todaysPlants}
                        getPlantIcon={defaultToDirt}
                    />
                </Box>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeTodaysProgress)
