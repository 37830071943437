import { format, getPlatform } from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    Channel,
    FetchPulseCheckInMutation,
    useFetchPulseCheckInMutation
} from '../../../graphql/generated/autogenerated'

const PLATFORM_TO_PULSE_CHANNEL_MAP: Record<string, Channel> = {
    'hybrid-android': Channel.MobileAndroid,
    'hybrid-ios': Channel.MobileIos,
    msteams: Channel.MsTeams,
    web: Channel.Web,
    webex: Channel.Webex
}

export interface DailyCheckInData {
    data: FetchPulseCheckInMutation | undefined
    loading: boolean
    error: boolean
    responded: boolean
    refetch: () => Promise<boolean>
}

export const useGetDailyCheckIn = (): DailyCheckInData => {
    const platform = getPlatform()
    const [pulseResponse, setPulseResponse] =
        useState<FetchPulseCheckInMutation>()
    const [loading, setLoading] = useState(true)
    const requestDate = useRef(format(new Date(), 'YYYY-MM-DD'))

    const [fetchPulseCheckIn, { error }] = useFetchPulseCheckInMutation({
        variables: {
            channel: PLATFORM_TO_PULSE_CHANNEL_MAP[platform] || Channel.Web,
            requestDate: requestDate.current.substring(0, 10)
        }
    })

    const fetchData = useCallback(async () => {
        const result = await fetchPulseCheckIn()
        if (result?.data) {
            setPulseResponse(result.data)
        }
        setLoading(false)
        return !!result?.data?.pulse?.surveys?.requestCheckIn?.response
    }, [fetchPulseCheckIn])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const responded = useMemo(() => {
        if (
            pulseResponse?.pulse?.surveys?.requestCheckIn?.status ===
            'PREVIOUSLY_SAVED'
        ) {
            return !!pulseResponse.pulse.surveys.requestCheckIn.response
        }
        return false
    }, [pulseResponse])

    return useMemo(
        () => ({
            data: pulseResponse,
            loading,
            error: Boolean(error),
            responded,
            refetch: fetchData
        }),
        [pulseResponse, loading, error, responded, fetchData]
    )
}

export default useGetDailyCheckIn
