import { Box, Card, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import WaterGlass from '../../../shared/components/icons/WaterGlass'

export interface TrackerInfoProps {
    completed: boolean
    message: React.ReactNode
}

const TrackerInfo: React.FC<TrackerInfoProps> = ({ completed, message }) => {
    const { palette, spacing } = useTheme()
    const glassRef = React.useRef<HTMLDivElement>(null)

    return (
        <Card
            elevation={1}
            sx={{
                border: `${spacing(0.125)} solid ${palette.primary.main}`,
                ...(completed && {
                    border: `${spacing(0.125)} solid ${palette.success.main}`
                }),
                py: 1.1,
                px: 2
            }}
        >
            <Stack
                direction="row"
                maxWidth={150}
                alignItems="center"
                justifyContent="center"
                gap={1}
                sx={{
                    whiteSpace: 'nowrap'
                }}
            >
                <WaterGlass />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    gap={1}
                >
                    {message}
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(TrackerInfo)
