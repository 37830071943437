import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import ContentCard from '../../../../../shared/components/elements/ContentCard/ContentCard'
import { getDurationInMinutes } from '../../../../../utils'
import type {
    ResetGoalDetail,
    ResetStatsDetail
} from '../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    reset: {
        defaultMessage: 'Reset',
        description: 'content type description'
    },
    watch: {
        defaultMessage: '{durationInMinutes} minute watch',
        description: 'description of the duration in minutes the video runs for'
    }
})

type ResetContentCardProps = {
    reset: ResetStatsDetail
    onClick: () => void
}

const ResetContentCard = ({ reset, onClick }: ResetContentCardProps) => {
    const { formatMessage } = useIntl()

    return (
        <ContentCard
            image={reset.resetDetail.resetInfo.landscape?.thumbnail as string}
            title={reset.resetDetail.resetInfo.name as string}
            contentType={formatMessage(messages.reset)}
            duration={formatMessage(messages.watch, {
                durationInMinutes: getDurationInMinutes(
                    reset?.resetDetail.reset.durationInSeconds ?? 0
                )
            })}
            alignMedia="top"
            description={reset.resetDetail.resetInfo.description as string}
            onClick={onClick}
            elevation={1}
        />
    )
}

export default React.memo(ResetContentCard)
