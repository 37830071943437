import { useContext } from 'react'
import CompanyChallengeRefetchProviderContext, {
    CompanyChallengeRefetchProviderValue
} from './companyChallengeRefetchContext'

export const useCompanyChallengeRefetchProviderContext =
    (): CompanyChallengeRefetchProviderValue => {
        const context = useContext(CompanyChallengeRefetchProviderContext)

        return context
    }
