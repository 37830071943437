import { useContext } from 'react'
import GoalChallengeContextProvider, {
    GoalChallengeValue
} from './goalChallengeContext'

export const useGoalChallengeContextProvider = (): GoalChallengeValue => {
    const context = useContext(GoalChallengeContextProvider)

    if (context === undefined) {
        throw new Error(
            'useGoalChallengeContextProvider must be used within a GoalChallengeContextProvider'
        )
    }

    return context
}
