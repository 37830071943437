// External imports
import { Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { PageLayout, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { generatePath, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

// Internal imports
import Body from '../../components/features/ChallengeSignup/Body'
import ExitDialog from '../../components/features/ChallengeSignup/ExitDialog'
import Header from '../../components/features/ChallengeSignup/Header'
import Page from '../../components/layout/Page'
import { ROUTES } from '../../../routes'
import useGetChallengeLanding, {
    ChallengeViewType
} from '../../hooks/useGetChallengeLanding/useGetChallengeLanding'

export const CHALLENGE_SIGNUP_STEPS = {
    PARTICIPATION_SELECTION: 1,
    TEAM_SELECTION: 2,
    CONSENT_AND_CONFIRMATION: 3
}

const ChallengeSignup = () => {
    const [step, setStep] = useState<number>(
        CHALLENGE_SIGNUP_STEPS.PARTICIPATION_SELECTION
    )
    const [exitDialogOpen, setExitDialogOpen] = useState(false)
    const { gradient } = useTheme()
    const { challengeId } = useParams<{ challengeId: string }>()

    const navigate = useCrossAppNavigation()

    const {
        viewType,
        loading,
        isTemplate,
        challenge,
        refetchUnifiedChallengeAvailability
    } = useGetChallengeLanding(challengeId)

    const navigateToExistingChallenge = () => {
        navigate(
            generatePath(ROUTES.CHALLENGE_V2, {
                challengeId: challengeId
            })
        )
    }

    useEffect(() => {
        if (
            [ChallengeViewType.ACTIVE, ChallengeViewType.RECAP].includes(
                viewType
            )
        ) {
            navigateToExistingChallenge()
        }
    }, [viewType])

    return (
        <>
            <Stack
                height="100%"
                minHeight="inherit"
                sx={{ background: gradient.main }}
            >
                <Stack>
                    <PageLayout maxWidth="lg">
                        <Page>
                            <Stack gap={3}>
                                <Header
                                    step={step}
                                    maxSteps={3}
                                    attemptExit={() => setExitDialogOpen(true)}
                                />
                                <Body
                                    loading={loading}
                                    challenge={challenge}
                                    step={step}
                                    setStep={setStep}
                                    attemptExit={() => setExitDialogOpen(true)}
                                />
                            </Stack>
                        </Page>
                    </PageLayout>
                </Stack>
            </Stack>

            <ExitDialog
                open={exitDialogOpen}
                onClose={() => setExitDialogOpen(false)}
            />
        </>
    )
}

export default ChallengeSignup
