import { memo } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
const ChallengeSignupSkeleton = () => {
    const { palette } = useTheme()
    return (
        <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={2}>
                <Skeleton variant="rounded" height="38px" width="80%" />
                <Skeleton variant="rounded" height="24px" width="70%" />
            </Stack>
            <Skeleton variant="rounded" height="108px" width="100%" />
            <Skeleton variant="rounded" height="172px" width="50%" />

            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                borderTop={`1px solid ${palette.divider}`}
                paddingTop={3}
            >
                <Skeleton variant="rounded" height="44px" width="100px" />
                <Skeleton variant="rounded" height="44px" width="100px" />
            </Box>
        </Stack>
    )
}

export default memo(ChallengeSignupSkeleton)
