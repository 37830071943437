import { Card, Fade } from '@mui/material'
import { Stack } from '@mui/system'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { memo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import type {
    CommunityGardenInfo,
    PlantInfo,
    UnifiedChallenge,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import DualToggle, { DualToggleIndex } from '../../../elements/DualToggle'
import ChallengeGarden from '../ChallengeGarden'
import TotalPlantsBarChart from './TotalPlantsBarChart'

export type TotalPlantsProps = {
    totalPlants: number
    plants?: UserPlantInfo[] | PlantInfo[]
    groupedPlants: CommunityGardenInfo[]
    challengeStarted?: boolean
    challenge: UnifiedChallenge
}

const TotalPlants: React.FC<TotalPlantsProps> = ({
    totalPlants,
    plants,
    groupedPlants,
    challengeStarted,
    challenge
}) => {
    const { formatNumber } = useIntl()
    const [activeIndex, setActiveIndex] = useState<DualToggleIndex>(
        DualToggleIndex.First
    )
    return (
        <>
            <Card elevation={0}>
                <Stack p={2}>
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        <CoreTypography
                            variant="overline"
                            color="text.disabled"
                        >
                            <FormattedMessage
                                defaultMessage="Total Plants"
                                description="Total Plants title"
                            />
                        </CoreTypography>
                        {challengeStarted && (
                            <DualToggle
                                activeIndex={activeIndex}
                                firstElement={
                                    <LeafIcon
                                        icon={'seedling'}
                                        color={'primary'}
                                    />
                                }
                                secondElement={
                                    <LeafIcon
                                        icon={'chart-simple'}
                                        color={'primary'}
                                    />
                                }
                                onSelect={setActiveIndex}
                            />
                        )}
                    </Stack>
                    <Stack direction="row" gap={1}>
                        <CoreTypography
                            variant="h3"
                            fontSize={41}
                            color="text.secondary"
                        >
                            {formatNumber(totalPlants)}
                        </CoreTypography>
                    </Stack>
                </Stack>
                {activeIndex === DualToggleIndex.First && (
                    <Stack width="100%" p={2}>
                        <ChallengeGarden
                            plants={plants}
                            challengeStarted={challengeStarted}
                        />
                    </Stack>
                )}
                {activeIndex === DualToggleIndex.Second && (
                    <Stack>
                        <TotalPlantsBarChart
                            groupedPlants={groupedPlants}
                            challenge={challenge}
                        />
                    </Stack>
                )}
            </Card>
        </>
    )
}

export default memo(TotalPlants)
