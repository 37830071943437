import { Box, Card, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useStorySubmissionContext } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import StorySubmissionButton from '../../StorySubmission/StorySubmissionButton'
import type {
    Story,
    StoryType,
    UnifiedChallenge,
    UnifiedChallengeActivity,
    UnifiedChallengeParticipation
} from '../../../../../graphql/generated/autogenerated'
import { useParseStorySubmissionData } from '../../../../contexts/withStorySubmission/useParseStorySubmissionData'

export interface StorySubmissionProps extends JSX.IntrinsicAttributes {
    challenge?: UnifiedChallenge
    currentParticipation?: UnifiedChallengeActivity
    availableInteractions?: StoryType[]
    stories?: Story[]
    isHorizontal?: boolean
    buttonOnly?: boolean
    storyComplete?: boolean
    loading?: boolean
    onSuccess?: () => void
}

const messages = defineMessages({
    writeStory: {
        defaultMessage: 'Write your story!',
        description: 'Write your story title'
    },
    viewGarden: {
        defaultMessage: 'View my garden',
        description: 'View my garden button'
    },
    completedChallenge: {
        defaultMessage:
            'Now that you’ve completed the Challenge, take a moment to reflect on what you’ve accomplished. This will take less than 5 minutes. You can keep your story private or share it for a chance to be featured as a Thrive Role Model.',
        description: 'Write your story description'
    },
    thankYou: {
        defaultMessage: 'Thank you for writing your story!!',
        description: 'thank you description'
    },
    sharingChallenge: {
        defaultMessage:
            'Sharing your Challenge story is a powerful way to reinforce your new habits and inspire others.',
        description: 'Sharing challenge description'
    }
})

const StorySubmission: React.FC<StorySubmissionProps> = ({
    challenge,
    currentParticipation,
    availableInteractions,
    stories,
    isHorizontal,
    buttonOnly,
    storyComplete,
    loading,
    onSuccess
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage } = useIntl()

    const { setChallenge, setParticipation, setChallengeStoryData } =
        useStorySubmissionContext()

    const storyData = useParseStorySubmissionData({
        availableInteraction: availableInteractions?.[0] as StoryType,
        participationId: currentParticipation?.id,
        stories
    })

    useEffect(() => {
        if (currentParticipation) {
            setParticipation(currentParticipation)
        }
    }, [currentParticipation, setParticipation])

    useEffect(() => {
        if (challenge) {
            setChallenge(challenge)
        }
    }, [challenge, setChallenge])

    useEffect(() => {
        if (storyData) {
            setChallengeStoryData(storyData)
        }
    }, [storyData, setChallengeStoryData])

    if (buttonOnly) {
        if (storyComplete) return ReactNullValue
        return (
            <ConditionalSkeleton showSkeleton={loading} variant="rounded">
                <StorySubmissionButton
                    variant="contained"
                    onSuccess={onSuccess}
                />
            </ConditionalSkeleton>
        )
    }

    return (
        <Card>
            <Stack
                p={2}
                gap={2}
                flex="1"
                direction={isHorizontal && !isMobile ? 'row' : 'column'}
                alignItems={isHorizontal && !isMobile ? 'center' : 'flex-start'}
            >
                <Stack flex="1" gap={1}>
                    <CoreTypography variant="h3" color="text.primary">
                        {formatMessage(
                            storyComplete
                                ? messages.thankYou
                                : messages.writeStory
                        )}
                    </CoreTypography>
                    <CoreTypography variant="body1" color="text.primary">
                        {formatMessage(
                            storyComplete
                                ? messages.sharingChallenge
                                : messages.completedChallenge
                        )}
                    </CoreTypography>
                </Stack>
                <Stack>
                    {!storyComplete && (
                        <Box>
                            <ConditionalSkeleton
                                showSkeleton={loading}
                                variant="rounded"
                            >
                                <StorySubmissionButton
                                    variant="contained"
                                    endIcon={
                                        <LeafIcon
                                            icon="arrow-right"
                                            fontSize="small"
                                        />
                                    }
                                    onSuccess={onSuccess}
                                />
                            </ConditionalSkeleton>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(StorySubmission)
