import { ApolloError } from '@apollo/client'
import { Button, Card, Stack, type SxProps } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { ReactElement, ReactNode } from 'react'
import ErrorCard from '../../../elements/ErrorCard'

/**
 * Props for the RecapWidgetHeader component
 * @typedef {Object} RecapWidgetHeaderProps
 * @property {string | ReactNode} title - Title text or node to display
 * @property {string | ReactNode} [description] - Optional description text or node
 */
type RecapWidgetHeaderProps = {
    title: string | ReactNode
    description?: string | ReactNode
}

/**
 * Header component for the RecapWidget displaying a title and optional description
 * @component
 * @param {RecapWidgetHeaderProps} props - Component props
 * @param {string | ReactNode} props.title - Title text or node to display
 * @param {string | ReactNode} [props.description] - Optional description text or node
 * @returns {ReactElement} Header section with title and description
 */
const RecapWidgetHeader = ({ title, description }: RecapWidgetHeaderProps) => (
    <Stack gap={2}>
        <CoreTypography variant="overline" color="text.disabled">
            {title}
        </CoreTypography>
        <CoreTypography variant="body2" color="text.secondary">
            {description}
        </CoreTypography>
    </Stack>
)

/**
 * Props for the RecapWidgetContent component
 * @typedef {Object} RecapWidgetContentProps
 * @property {ReactNode} children - Content to render inside the widget
 * @property {SxProps} [sx] - Optional MUI sx props for styling
 */
type RecapWidgetContentProps = {
    children: ReactNode
    sx?: SxProps
}

/**
 * Content component for the RecapWidget that wraps children in a Stack
 * @component
 * @param {RecapWidgetContentProps} props - Component props
 * @param {ReactNode} props.children - Content to render inside the widget
 * @param {SxProps} [props.sx] - Optional MUI sx props for styling
 * @returns {ReactElement} Content section wrapped in Stack
 */
const RecapWidgetContent = ({ children, sx }: RecapWidgetContentProps) => (
    <Stack gap={2} sx={sx}>
        {children}
    </Stack>
)

/**
 * Props for the RecapWidgetFooter component
 * @typedef {Object} RecapWidgetFooterProps
 * @property {string | ReactNode} label - Label text or node for the action button
 * @property {() => void} onClick - Click handler for the action button
 */
type RecapWidgetFooterProps = {
    label: string | ReactNode
    onClick: () => void
}

/**
 * Footer component for the RecapWidget with an action button
 * @component
 * @param {RecapWidgetFooterProps} props - Component props
 * @param {string | ReactNode} props.label - Label text or node for the action button
 * @param {() => void} props.onClick - Click handler for the action button
 * @returns {ReactElement} Footer section with action button
 */
const RecapWidgetFooter = ({ label, onClick }: RecapWidgetFooterProps) => (
    <Stack gap={2}>
        <Button
            variant="text"
            endIcon={<LeafIcon icon="arrow-right" fontSize="small" />}
            onClick={onClick}
        >
            <CoreTypography customVariant="buttonNormal">
                {label}
            </CoreTypography>
        </Button>
    </Stack>
)

/**
 * Type for allowed child components of RecapWidget
 * @typedef {ReactElement<typeof RecapWidgetHeader | typeof RecapWidgetContent | typeof RecapWidgetFooter>} AllowedRecapWidgetComponents
 */
type AllowedRecapWidgetComponents = ReactElement<
    | typeof RecapWidgetHeader
    | typeof RecapWidgetContent
    | typeof RecapWidgetFooter
>

/**
 * Props for the RecapWidget component
 * @typedef {Object} RecapWidgetProps
 * @property {AllowedRecapWidgetComponents | AllowedRecapWidgetComponents[]} children - Child components that must be RecapWidget subcomponents
 */
type RecapWidgetProps = {
    children: AllowedRecapWidgetComponents | AllowedRecapWidgetComponents[]
}

/**
 * Container component that provides a card layout for recap information
 * @component
 * @param {RecapWidgetProps} props - Component props
 * @param {AllowedRecapWidgetComponents | AllowedRecapWidgetComponents[]} props.children - Child components that must be RecapWidget subcomponents
 * @returns {ReactElement} Card container with header, content and footer sections
 */
const RecapWidget = ({ children }: RecapWidgetProps) => {
    return (
        <Card>
            <Stack p={2} gap={2}>
                {children}
            </Stack>
        </Card>
    )
}

/**
 * Props for the RecapWidgetContainer component
 * @typedef {Object} RecapWidgetContainerProps
 * @property {ReactNode} children - Child components to render inside the container
 * @property {Error | ApolloError} [error] - Optional error to display
 */
type RecapWidgetContainerProps = {
    children: ReactNode
    hide?: boolean | null
    error?: Error | ApolloError
}

/**
 * Container component that wraps recap widgets with error handling
 * @component
 * @param {RecapWidgetContainerProps} props - Component props
 * @param {ReactNode} props.children - Child components to render inside the container
 * @param {Error | ApolloError} [props.error] - Optional error to display
 * @returns {ReactElement} Error card container with children
 */
export const RecapWidgetContainer = ({
    children,
    error,
    hide
}: RecapWidgetContainerProps) => {
    if (hide) return null
    return <ErrorCard error={error}>{children}</ErrorCard>
}

export { RecapWidget, RecapWidgetHeader, RecapWidgetContent, RecapWidgetFooter }
