import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import BulletList from '../../../../../elements/BulletList'

const messages = defineMessages({
    competingTeam: {
        defaultMessage:
            'I am competing on a team against other teams in the Challenge’s Team Leaderboard, which will feature my team’s name and the team’s {deviceEnabled, select, true {total activity points and plants for the duration of the Challenge} other {total plants for the duration of the Challenge}}',
        description: 'company challenge participation information'
    },
    competingIndividualAlso: {
        defaultMessage:
            'I am also competing against other individual participants in the Challenge’s Individual Leaderboards, which will feature my display name and {deviceEnabled, select, true {total activity points and plants for the duration of the Challenge} other {total plants for the duration of the Challenge}}',
        description: 'company challenge participation information'
    },
    activityPoints: {
        defaultMessage:
            'My activity points will appear with my display name in the Individual Leaderboard',
        description: 'company challenge participation information'
    },
    activityCompanyFeed: {
        defaultMessage:
            'My Thrive activity will appear with my display name in the Company’s Challenge activity feed',
        description: 'company challenge participation information'
    },
    activityTeamFeed: {
        defaultMessage:
            'My Thrive activity will appear with my display name in my team’s Challenge activity feed',
        description: 'company challenge participation information'
    },
    activityTeamCompanyFeed: {
        defaultMessage:
            'My team’s activity will appear with my team’s name in the Company’s Challenge activity feed',
        description: 'company challenge participation information'
    },
    competingIndividual: {
        defaultMessage:
            'I am competing against other individual participants in the Challenge’s Individual Leaderboards, which will feature my display name and {deviceEnabled, select, true {total activity points and plants for the duration of the Challenge} other {total plants for the duration of the Challenge}}',
        description: 'company challenge participation information'
    },
    inviteCompetingIndividual: {
        defaultMessage:
            'I am competing against other individual participants I invite in the Challenge’s Individual Leaderboards, which will feature my display name and {deviceEnabled, select, true {total activity points and plants for the duration of the Challenge} other {total plants for the duration of the Challenge}}',
        description: 'invite challenge participation information'
    },
    challengeActivity: {
        defaultMessage:
            'My Thrive activity will appear with my display name in the Challenge activity feed after inviting others',
        description: 'invite challenge participation information'
    },
    emailParticipation: {
        defaultMessage:
            'By consenting to participation, I give Thrive permission to share my display name and email address with the Company to celebrate the end of the Challenge and determine winners, if applicable. I acknowledge that any applicable awards may be based on both the data represented in Challenge Leaderboards and the suitability of the team size and performance to the type of award being granted. If I am the sole participant in a team, I may not be eligible for certain team-based awards, as these are typically calculated based on the average or total performance of all team members.',
        description: 'email usage permission disclaimer'
    }
})

export interface ConfirmDetailsBulletListProps {
    isTemplate?: boolean
    isTeamSignUp: boolean
    deviceEnabled?: boolean
}

const TermsStepDetails: React.FC<ConfirmDetailsBulletListProps> = ({
    isTemplate,
    isTeamSignUp,
    deviceEnabled
}) => {
    const { formatMessage } = useIntl()

    const bulletList = useMemo(() => {
        if (isTemplate) {
            return [
                formatMessage(messages.inviteCompetingIndividual, {
                    deviceEnabled
                }),
                formatMessage(messages.challengeActivity),
                formatMessage(messages.emailParticipation)
            ]
        } else {
            return isTeamSignUp
                ? [
                      formatMessage(messages.competingTeam, { deviceEnabled }),
                      formatMessage(messages.competingIndividualAlso, {
                          deviceEnabled
                      }),
                      ...(deviceEnabled
                          ? [formatMessage(messages.activityPoints)]
                          : []),
                      formatMessage(messages.activityCompanyFeed),
                      formatMessage(messages.activityTeamFeed),
                      formatMessage(messages.activityTeamCompanyFeed),
                      formatMessage(messages.emailParticipation)
                  ]
                : [
                      formatMessage(messages.competingIndividual, {
                          deviceEnabled
                      }),
                      ...(deviceEnabled
                          ? [formatMessage(messages.activityPoints)]
                          : []),
                      formatMessage(messages.activityCompanyFeed),
                      formatMessage(messages.emailParticipation)
                  ]
        }
    }, [isTeamSignUp, isTemplate, formatMessage, deviceEnabled])

    return (
        <Card variant="outlined" sx={{ overflow: 'auto' }}>
            <Stack gap={1.5} p={2}>
                <CoreTypography variant="h5" color="primary.main">
                    {isTeamSignUp ? (
                        <FormattedMessage
                            defaultMessage="By participating on a team I understand that:"
                            description="participation confirmation title"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="By participating individually, I understand that:"
                            description="participation confirmation title"
                        />
                    )}
                </CoreTypography>
                <BulletList
                    gap={1}
                    list={bulletList.map((bullet) => (
                        <CoreTypography variant="body2">
                            {bullet}
                        </CoreTypography>
                    ))}
                />
            </Stack>
        </Card>
    )
}

export default memo(TermsStepDetails)
