import { Box, Button, Card, Collapse, Divider, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../graphql/generated/autogenerated'
import {
    useStorySubmissionContext,
    withStorySubmissionProvider
} from '../../../../contexts/withStorySubmission/withStorySubmissionProvider'
import StorySubmissionButton from '../StorySubmissionButton'
import YourStoryAnswers from './YourStoryAnswers'

const messages = defineMessages({
    yourStory: {
        defaultMessage: 'Your Story',
        description: 'title for your story card'
    },
    congratulations: {
        defaultMessage: 'Congratulations!',
        description:
            'title text for successfully submitting a story for a completed challenge'
    },
    youDidIt: {
        defaultMessage:
            'You did it! Take a moment to celebrate & reflect — you earned it.',
        description: 'description on completing a challenge'
    },
    writeYourStory: {
        defaultMessage: 'Write your story',
        description: 'button to navigate to write a story pages'
    },
    uploadedImage: {
        defaultMessage: 'uploaded image',
        description: 'alt text for the uploaded image'
    }
})

export interface YourStoryProps extends JSX.IntrinsicAttributes {
    challenge?: UnifiedChallenge
    participation?: UnifiedChallengeActivity
}

const YourStory: React.FC<YourStoryProps> = withStorySubmissionProvider(
    ({ challenge, participation }) => {
        const { palette } = useTheme()
        const { formatMessage } = useIntl()
        const [showStories, setShowStories] = useState(true)
        const {
            loading,
            error,
            stories,
            questions,
            storyComplete,
            setChallenge,
            setParticipation
        } = useStorySubmissionContext()

        useEffect(() => {
            if (challenge) {
                setChallenge(challenge)
            }
        }, [setChallenge, challenge])

        useEffect(() => {
            if (participation) {
                setParticipation(participation)
            }
        }, [setParticipation, participation])

        return (
            <Collapse in={!loading && !error}>
                <Stack data-testid="your-story" gap={1}>
                    {storyComplete ? (
                        <Button
                            onClick={() => setShowStories(!showStories)}
                            disabled={!storyComplete}
                            endIcon={
                                <LeafIcon
                                    icon={'chevron-down'}
                                    sx={{
                                        transition: 'all 0.3s ease',
                                        ...(showStories && {
                                            transform: 'rotate(180deg)'
                                        })
                                    }}
                                />
                            }
                            sx={{
                                width: '100%',
                                justifyContent: 'space-between',
                                mx: -1
                            }}
                        >
                            <CoreTypography variant="h5">
                                {formatMessage(messages.yourStory)}
                            </CoreTypography>
                        </Button>
                    ) : (
                        <CoreTypography variant="h5">
                            {formatMessage(messages.yourStory)}
                        </CoreTypography>
                    )}
                    <Stack gap={2}>
                        <Collapse
                            in={storyComplete && showStories}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Stack>
                                <YourStoryAnswers questionsData={stories} />
                            </Stack>
                        </Collapse>
                        {!storyComplete && !!questions && (
                            <Card>
                                <Stack direction="column" p={2}>
                                    <Stack>
                                        <LeafIcon
                                            icon={'hands-clapping'}
                                            fontSize={'large'}
                                            color={'primary'}
                                        />
                                        <CoreTypography
                                            customVariant="stat3"
                                            color="primary.main"
                                            sx={{
                                                wordBreak: 'break-word'
                                            }}
                                        >
                                            {formatMessage(
                                                messages.congratulations
                                            )}
                                        </CoreTypography>
                                        <CoreTypography
                                            variant="body1"
                                            sx={{
                                                my: 1,
                                                mb: 2,
                                                color: palette.primary.main
                                            }}
                                        >
                                            {formatMessage(messages.youDidIt)}
                                        </CoreTypography>
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <StorySubmissionButton />
                                    </Box>
                                </Stack>
                            </Card>
                        )}
                        <Divider />
                    </Stack>
                </Stack>
            </Collapse>
        )
    }
)

export default memo(YourStory)
