import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { Visibility } from '../../../../graphql/generated/autogenerated'
import { useShowWalmartBetterChoicesChallenge } from '../../../../shared/hooks/useShowWalmartBetterChoicesChallenge/useShowWalmartBetterChoicesChallenge'
import { useStorySubmissionContext } from '../withStorySubmissionProvider'
import { QuestionData } from '../withStorySubmissionProvider/storySubmissionContext'
import StorySubmissionStateContext, {
    StorySubmissionStateProviderValue
} from './storySubmissionStateContext'

export default function withStorySubmissionStateProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [errorDescription, setErrorDescription] = useState<string>()
        const [answeredQuestions, setAnsweredQuestions] = useState<
            QuestionData[]
        >([])

        const { challenge, questions } = useStorySubmissionContext()

        const isWalmartPrizeChallenge =
            useShowWalmartBetterChoicesChallenge(challenge)
        const [visibility, setVisibility] = useState(
            isWalmartPrizeChallenge ? Visibility.Public : Visibility.Private
        )

        //initially populate the questions we will be mutating during story submission
        useEffect(() => setAnsweredQuestions(questions), [questions])

        useEffect(() => {
            if (isWalmartPrizeChallenge) {
                setVisibility(Visibility.Public)
            }
        }, [isWalmartPrizeChallenge])

        const onVisibility = useCallback((type: any) => {
            setVisibility(type)
        }, [])

        const onAnswer = useCallback(
            (index: any, answer: any) => {
                const answeredQuestionsCopy = [...answeredQuestions]
                answeredQuestionsCopy[index].answer = answer
                setAnsweredQuestions(answeredQuestionsCopy)
            },
            [answeredQuestions]
        )

        // Add query when available
        const state = useMemo<StorySubmissionStateProviderValue>(
            () => ({
                answeredQuestions,
                isSingleQuestionStory: questions?.length === 1,
                visibility,
                errorDescription,
                onAnswer,
                onVisibility,
                setErrorDescription
            }),
            [
                answeredQuestions,
                questions,
                visibility,
                errorDescription,
                onAnswer,
                onVisibility
            ]
        )

        return (
            <StorySubmissionStateContext.Provider value={state}>
                <Component {...props} />
            </StorySubmissionStateContext.Provider>
        )
    }
}
