import React, { useMemo } from 'react'
import {
    ChallengeUserDailyGoal,
    GoalType,
    MicrostepGoalDetail,
    useGetMicrostepsV2Query
} from '../../../../../../../graphql/generated/autogenerated'
import MicrostepGoal from '.'

export type MicrostepGoalByQueryProps = {
    id: string
    type: GoalType
}

const MicrostepGoalByQuery: React.FC<MicrostepGoalByQueryProps> = ({
    id,
    type
}) => {
    const { data, loading, error } = useGetMicrostepsV2Query({
        variables: {
            ids: [id]
        }
    })

    const goal = useMemo(() => {
        if (!data || loading) return undefined
        const microstep = data?.today?.getMicrostepsV2?.[0]

        return {
            challengeGoal: {
                goalType: type as string
            },
            goalEntityDetail: {
                microstep: microstep
            } as MicrostepGoalDetail | unknown,
            completed: false
        }
    }, [data, loading, type])

    if (error) throw error

    return (
        <MicrostepGoal
            challenge={undefined}
            goal={goal as ChallengeUserDailyGoal}
            loading={loading}
            disabled={true}
        />
    )
}

export default MicrostepGoalByQuery
