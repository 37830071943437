import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant17: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#F5D93F',
    colorLayer2 = '#F5BA64',
    colorLayer3 = '#37661D',
    colorLayer4 = '#53A354',
    colorLayer5 = '#EB9C8E',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M50.9518 48.9793C53.5718 51.3593 54.2319 54.8893 52.4319 56.8693C50.6319 58.8493 47.0519 58.5293 44.4319 56.1593C41.8119 53.7793 38.2319 43.9793 38.2319 43.9793C38.2319 43.9793 48.3318 46.5993 50.9518 48.9793Z"
                fill={colorLayer3}
            />
            <path
                d="M52.8827 21.4393L50.0414 84.4953L51.0404 84.5404L53.8817 21.4843L52.8827 21.4393Z"
                fill={colorLayer4}
            />
            <path
                d="M39.3019 11.2393L46.9418 17.0593L37.3918 15.5093C36.6718 15.3893 36.0018 15.8693 35.8318 16.5793C35.4718 18.1393 35.2719 19.7693 35.2719 21.4393C35.2719 23.2193 35.4918 24.9493 35.8918 26.5993C36.0618 27.2893 36.7619 27.7093 37.4619 27.5793L46.9119 25.7893L39.3818 31.8493C38.8118 32.3093 38.7118 33.1193 39.1318 33.7193C42.2818 38.2193 47.1119 41.4493 52.7119 42.5093C53.4419 42.6493 54.1518 42.2193 54.3218 41.4993L56.6019 32.1693L58.9919 41.5293C59.1719 42.2393 59.8719 42.6693 60.5919 42.5293C62.0119 42.2693 63.3918 41.8593 64.7018 41.3393C68.6718 39.7493 72.0618 37.0093 74.4418 33.5193C74.8618 32.9093 74.7419 32.0893 74.1519 31.6393L66.5118 25.8193L76.0618 27.3693C76.7818 27.4893 77.4518 27.0093 77.6218 26.2993C77.9818 24.7393 78.1819 23.1093 78.1819 21.4393C78.1819 19.6593 77.9618 17.9293 77.5618 16.2793C77.3918 15.5893 76.6919 15.1693 75.9919 15.2993L66.5419 17.0893L74.0718 11.0293C74.6418 10.5693 74.7418 9.75932 74.3218 9.15932C71.1718 4.65932 66.3419 1.42931 60.7419 0.369311C60.0119 0.229311 59.3018 0.659321 59.1318 1.37932L56.8519 10.7093L54.4619 1.33931C54.2919 0.659313 53.6219 0.199333 52.9319 0.329333C49.9319 0.869333 47.1619 2.02933 44.7319 3.66933C42.4919 5.18933 40.5419 7.11932 39.0219 9.34932C38.6019 9.95932 38.7218 10.7893 39.3118 11.2293L39.3019 11.2393Z"
                fill={colorLayer1}
            />
            <path
                d="M56.7319 25.0293C58.7146 25.0293 60.3218 23.422 60.3218 21.4393C60.3218 19.4566 58.7146 17.8493 56.7319 17.8493C54.7492 17.8493 53.1418 19.4566 53.1418 21.4393C53.1418 23.422 54.7492 25.0293 56.7319 25.0293Z"
                fill={colorLayer5}
            />
            <path
                d="M56.7119 20.4093L53.5219 15.6693L52.5319 16.3393L55.5818 20.8793H50.1218V22.0693H55.6019L52.5319 26.6393L53.5219 27.2993L56.7119 22.5593L59.9119 27.2993L60.9019 26.6393L57.8318 22.0693H63.3419V20.8793H57.8519L60.9019 16.3393L59.9119 15.6693L56.7119 20.4093Z"
                fill="white"
            />
            <path
                d="M24.2819 38.7613L23.3883 39.2102L45.9161 84.0603L46.8098 83.6115L24.2819 38.7613Z"
                fill={colorLayer4}
            />
            <path
                d="M69.2093 59.466L54.6537 85.0675L55.5231 85.5618L70.0786 59.9602L69.2093 59.466Z"
                fill={colorLayer4}
            />
            <path
                d="M12.4219 39.5493L17.0219 41.3593L10.6219 42.1593C10.1419 42.2193 9.6219 42.5893 9.4219 42.9993C8.9919 43.8993 8.6719 44.7993 8.4819 45.6993C8.2819 46.6493 8.23187 47.5393 8.32187 48.3593C8.36187 48.6993 8.7919 48.7993 9.2919 48.6093L16.0019 46.0393L10.1119 50.5693C9.67191 50.9093 9.50189 51.3693 9.72189 51.6093C11.3719 53.4793 14.3319 54.3893 18.0619 54.0093C18.5419 53.9593 19.0819 53.6093 19.2919 53.1893L21.9319 47.8093L22.5019 52.4193C22.5419 52.7693 22.9819 52.8793 23.4819 52.6893C24.4919 52.3093 25.4819 51.8593 26.4519 51.3493C29.3719 49.8193 32.0119 47.7793 34.0619 45.5093C34.4219 45.1093 34.4319 44.6893 34.0819 44.5493L29.4819 42.7393L35.8819 41.9393C36.3619 41.8793 36.8819 41.5093 37.0819 41.0993C37.5119 40.1993 37.8319 39.2993 38.0219 38.3993C38.2219 37.4493 38.2719 36.5593 38.1819 35.7393C38.1419 35.3993 37.7119 35.2993 37.2119 35.4893L30.5019 38.0593L36.3919 33.5293C36.8319 33.1893 37.0019 32.7293 36.7819 32.4893C35.1319 30.6193 32.1719 29.7093 28.4419 30.0893C27.9619 30.1393 27.4219 30.4893 27.2119 30.9093L24.5719 36.2893L24.0019 31.6793C23.9619 31.3493 23.5519 31.2093 23.0619 31.3993C20.9419 32.1993 18.8919 33.2893 17.0319 34.5793C15.3119 35.7693 13.7519 37.1393 12.4419 38.5893C12.0819 38.9893 12.0719 39.4093 12.4219 39.5493Z"
                fill={colorLayer2}
            />
            <path
                d="M24.1129 43.3554C25.4128 42.5384 26.0807 41.2619 25.6046 40.5044C25.1285 39.7469 23.6887 39.7952 22.3888 40.6122C21.0889 41.4292 20.4211 42.7056 20.8972 43.4631C21.3733 44.2206 22.813 44.1724 24.1129 43.3554Z"
                fill={colorLayer5}
            />
            <path
                d="M23.3619 41.4893L21.7119 39.4993L20.9418 40.0293L22.5219 41.9293L18.7719 42.8593L18.6318 43.4993L22.4019 42.5693L19.7618 45.5393L20.3718 45.7293L23.1119 42.6393L24.7618 44.6393L25.5319 44.1093L23.9418 42.1893L27.7319 41.2593L27.8718 40.6093L24.0919 41.5493L26.7119 38.5993L26.1019 38.4093L23.3619 41.4893Z"
                fill="white"
            />
            <path
                d="M80.3319 56.6193L75.7319 58.4293L82.1319 59.2293C82.6119 59.2893 83.1319 59.6593 83.3319 60.0693C83.7619 60.9693 84.0819 61.8693 84.2719 62.7693C84.4719 63.7193 84.5219 64.6093 84.4319 65.4293C84.3919 65.7693 83.9619 65.8693 83.4619 65.6793L76.7519 63.1093L82.6419 67.6393C83.0819 67.9793 83.2519 68.4393 83.0319 68.6793C81.3819 70.5493 78.4219 71.4593 74.6919 71.0793C74.2119 71.0293 73.6719 70.6793 73.4619 70.2593L70.8219 64.8793L70.2519 69.4893C70.2119 69.8393 69.7719 69.9493 69.2719 69.7593C68.2619 69.3793 67.2719 68.9293 66.3019 68.4193C63.3819 66.8893 60.7419 64.8493 58.6919 62.5793C58.3319 62.1793 58.3219 61.7593 58.6719 61.6193L63.2719 59.8093L56.8719 59.0093C56.3919 58.9493 55.8719 58.5793 55.6719 58.1693C55.2419 57.2693 54.9219 56.3693 54.7319 55.4693C54.5319 54.5193 54.4819 53.6293 54.5719 52.8093C54.6119 52.4693 55.0419 52.3693 55.5419 52.5593L62.2519 55.1293L56.3619 50.5993C55.9219 50.2593 55.7519 49.7993 55.9719 49.5593C57.6219 47.6893 60.5819 46.7793 64.3119 47.1593C64.7919 47.2093 65.3319 47.5593 65.5419 47.9793L68.1819 53.3593L68.7519 48.7493C68.7919 48.4193 69.2019 48.2793 69.6919 48.4693C71.8119 49.2693 73.8619 50.3593 75.7219 51.6493C77.4419 52.8393 79.0019 54.2093 80.3119 55.6593C80.6719 56.0593 80.6819 56.4793 80.3319 56.6193Z"
                fill={colorLayer1}
            />
            <path
                d="M71.8738 60.6435C72.3499 59.886 71.682 58.6096 70.3821 57.7926C69.0822 56.9756 67.6425 56.9273 67.1664 57.6848C66.6902 58.4423 67.3581 59.7188 68.658 60.5358C69.9579 61.3528 71.3977 61.401 71.8738 60.6435Z"
                fill={colorLayer5}
            />
            <path
                d="M69.3918 58.5593L71.0419 56.5693L71.8019 57.0893L70.2219 58.9993L73.9819 59.9293L74.1218 60.5693L70.3519 59.6393L72.9819 62.6093L72.3818 62.7893L69.6318 59.7093L67.9819 61.7093L67.2219 61.1793L68.8118 59.2593L65.0118 58.3193L64.8718 57.6793L68.6619 58.6193L66.0419 55.6693L66.6418 55.4793L69.3918 58.5593Z"
                fill="white"
            />
            <path
                d="M32.1318 59.4293C35.4818 60.5493 37.5119 63.5193 36.6619 66.0593C35.8119 68.5993 32.4019 69.7393 29.0519 68.6193C25.7019 67.4993 13.8619 57.5293 13.8619 57.5293C13.8619 57.5293 28.7918 58.2993 32.1418 59.4293H32.1318Z"
                fill={colorLayer3}
            />
            <path
                d="M70.4319 74.0093L60.9418 77.3593C59.5618 77.8493 58.4418 78.8693 57.8218 80.1993L56.3918 83.2993L60.9518 79.7393L70.4418 74.0093H70.4319Z"
                fill={colorLayer3}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant17
