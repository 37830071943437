import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCardContainer, { WaveCardContainerProps } from './WaveCardContainer'

export type WaveCardProps = WaveCardContainerProps & {
    title?: string
}

const WaveCard: React.FC<WaveCardProps> = ({
    title,
    elevation,
    loading,
    children,
    waveColor
}) => (
    <WaveCardContainer
        elevation={elevation}
        waveColor={waveColor}
        loading={loading}
    >
        <Stack gap={1.5} zIndex={1}>
            <ConditionalSkeleton showSkeleton={loading}>
                <CoreTypography variant="overline" color="text.disabled">
                    {title}
                </CoreTypography>
            </ConditionalSkeleton>
            <Stack width="100%" alignItems="center" justifyContent="center">
                {children}
            </Stack>
        </Stack>
    </WaveCardContainer>
)

export default memo(WaveCard)
