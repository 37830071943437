import { Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import SocialMemberList from '../SocialMemberList'

export type ChallengeTeamMembersProps = {
    open: boolean
    onClose: () => void
    socialGroupId: string
}

const ChallengeTeamMembers: React.FC<ChallengeTeamMembersProps> = ({
    open,
    onClose,
    socialGroupId
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullScreen={isMobile}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Team members"
                        description="title for team member dialog"
                    />
                </CoreTypography>
            }
        >
            <Stack gap={2} pt={2} pb={4} width={400} maxWidth="100%">
                <SocialMemberList socialGroupId={socialGroupId} />
            </Stack>
        </LeafDialog>
    )
}

export default ChallengeTeamMembers
