import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    GetChallengeMaxTeamSizeQuery,
    useGetChallengeMaxTeamSizeQuery
} from '../../../graphql/generated/autogenerated'
import { GQLNull } from '../../../shared/utils/Nulls'

export interface MaxTeamSizeData {
    maxTeamSize: number
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<
        Apollo.ApolloQueryResult<GetChallengeMaxTeamSizeQuery>
    >
}

export interface MaxTeamSizeProps {
    challengeId: string | GQLNull
}

export const useGetMaxTeamSize = ({ challengeId }: MaxTeamSizeProps) => {
    const { data, loading, error, refetch } = useGetChallengeMaxTeamSizeQuery({
        variables: {
            challengeId: challengeId
        },
        skip: !challengeId
    })

    return useMemo<MaxTeamSizeData>(() => {
        return {
            maxTeamSize:
                data?.unifiedChallenges?.unifiedChallenge?.maxTeamSize ?? 0,
            loading,
            error,
            refetch
        }
    }, [data, loading, error, refetch])
}

export default useGetMaxTeamSize
