import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant6: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#EB9C8E',
    colorLayer2 = '#81C254',
    colorLayer3 = '#21513A',
    colorLayer4 = '#DD6756',
    colorLayer5 = '#CC473B',
    colorLayer6 = '#1F2720',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M29.2791 26.63C28.9991 26.48 28.8091 26.39 28.7291 26.33L28.6491 26.42C29.4591 26.65 47.3191 47.06 48.4991 55.59L49.4891 55.45C48.2491 46.46 43.7391 34.16 29.2791 26.63Z"
                fill={colorLayer3}
            />
            <path
                d="M50.0491 83.12C44.3591 46.33 55.0191 21.53 55.1291 21.28L56.0491 21.68C55.9391 21.92 45.3991 46.48 51.0391 82.96L50.0491 83.11V83.12Z"
                fill={colorLayer3}
            />
            <path
                d="M56.2891 45.44C61.8791 37.81 70.6091 35.25 70.6091 35.25C70.6091 35.25 70.2891 45.06 64.6991 52.69C59.1091 60.32 50.3791 62.88 50.3791 62.88C50.3791 62.88 50.6991 53.07 56.2891 45.44Z"
                fill={colorLayer2}
            />
            <path
                d="M70.6091 35.25C70.6091 35.25 70.2891 45.06 64.6991 52.69C59.1091 60.32 50.3791 62.88 50.3791 62.88L70.6091 35.25Z"
                fill={colorLayer3}
            />
            <path
                d="M54.0591 85.01C53.7891 74.4 57.0691 66.67 59.8691 62.05C62.9091 57.03 65.9691 54.56 66.0991 54.46L66.7191 55.24C66.5991 55.34 54.5591 65.19 55.0591 84.99L54.0591 85.02V85.01Z"
                fill={colorLayer3}
            />
            <path
                d="M44.3691 76.64C39.9991 51.69 26.4891 47.84 26.3491 47.81L26.5991 46.84C27.1791 46.99 40.8591 50.83 45.3491 76.47L44.3691 76.64Z"
                fill={colorLayer3}
            />
            <path
                d="M26.4691 47.33C26.4691 47.33 26.4691 47.33 26.4691 47.32C26.4691 47.32 26.4491 47.3 26.4291 47.28C26.4391 47.31 26.4491 47.32 26.4491 47.33H26.4591H26.4691Z"
                fill={colorLayer5}
            />
            <path
                d="M21.3891 45.54C24.4391 45.34 26.1491 46.97 26.4391 47.28C26.2691 46.91 25.4491 44.79 27.0791 42.47C27.7691 41.48 28.1391 40.68 28.3191 40.08C28.9991 37.86 25.5591 37.55 21.6391 39.57C21.5391 39.62 21.4491 39.67 21.3491 39.72C21.2491 39.77 21.1591 39.82 21.0691 39.88C17.2491 42.05 15.9391 44.92 18.4491 45.42C19.1291 45.55 20.0891 45.64 21.3891 45.55V45.54Z"
                fill={colorLayer1}
            />
            <path
                d="M29.7191 52.16L25.7191 47.97C25.4591 47.7 24.0291 46.36 21.4591 46.54C20.2391 46.62 19.1691 46.57 18.2591 46.39C16.6091 46.07 16.2591 44.97 16.1891 44.52C15.9191 42.84 17.6391 40.67 20.5791 39.01L21.1691 38.69C24.2891 37.08 27.2891 36.77 28.6491 37.91C29.3491 38.5 29.5791 39.4 29.2791 40.38C29.0291 41.21 28.5591 42.1 27.8991 43.05C26.5891 44.92 27.2191 46.57 27.3591 46.88L29.7291 52.17L29.7191 52.16ZM21.9091 44.52C23.1991 44.52 24.2591 44.81 25.0891 45.18C25.1291 44.22 25.4291 43.07 26.2591 41.89C26.7991 41.12 27.1691 40.41 27.3691 39.78C27.4491 39.51 27.3891 39.46 27.3691 39.44C26.9291 39.08 24.9391 39 22.0991 40.46L21.5691 40.75C19.0091 42.2 18.0891 43.72 18.1691 44.2C18.1891 44.3 18.4291 44.39 18.6491 44.43C19.3791 44.57 20.2891 44.61 21.3291 44.54C21.5291 44.53 21.7291 44.52 21.9191 44.52H21.9091Z"
                fill={colorLayer1}
            />
            <path
                d="M26.4391 47.28C26.4391 47.28 26.4391 47.28 26.4391 47.29C26.4391 47.29 26.4591 47.31 26.4791 47.33C26.4691 47.3 26.4591 47.29 26.4591 47.28H26.4491H26.4391Z"
                fill={colorLayer5}
            />
            <path
                d="M31.5191 49.07C28.4691 49.27 26.7591 47.64 26.4691 47.33C26.6391 47.7 27.4591 49.82 25.8291 52.14C25.1391 53.13 24.7691 53.93 24.5891 54.53C23.9091 56.75 27.3491 57.06 31.2691 55.04C31.3691 54.99 31.4591 54.94 31.5591 54.89C31.6591 54.84 31.7491 54.79 31.8391 54.73C35.6591 52.56 36.9691 49.69 34.4591 49.19C33.7791 49.06 32.819 48.97 31.5191 49.06V49.07Z"
                fill={colorLayer1}
            />
            <path
                d="M26.5991 57.39C25.6191 57.39 24.8091 57.17 24.2691 56.71C23.5691 56.12 23.3391 55.22 23.6391 54.24C23.8891 53.42 24.3591 52.52 25.0191 51.57C26.3291 49.7 25.6991 48.05 25.5591 47.74L23.1891 42.44L27.1991 46.65C27.4591 46.92 28.8591 48.25 31.4591 48.08C32.6691 48 33.7491 48.05 34.6591 48.23C36.2991 48.55 36.6491 49.65 36.7291 50.1C36.9991 51.78 35.2791 53.95 32.3391 55.61L31.7391 55.93C29.8791 56.89 28.0591 57.39 26.5991 57.39ZM27.8291 49.43C27.7791 50.39 27.4891 51.54 26.6591 52.72C26.1191 53.5 25.7491 54.21 25.5491 54.83C25.4691 55.1 25.5291 55.15 25.5491 55.17C25.9891 55.54 27.9791 55.61 30.8191 54.15L31.3191 53.88C33.9191 52.41 34.8291 50.89 34.7491 50.41C34.7291 50.31 34.4891 50.22 34.2691 50.18C33.5291 50.04 32.6291 49.99 31.5891 50.07C30.0291 50.18 28.7691 49.85 27.8191 49.43H27.8291Z"
                fill={colorLayer1}
            />
            <path
                d="M26.3791 47.33C26.3791 47.33 26.3791 47.33 26.3891 47.33C26.4091 47.33 26.4491 47.31 26.5191 47.29C26.4591 47.31 26.4191 47.32 26.3991 47.32C26.3891 47.32 26.3791 47.32 26.3791 47.32V47.33Z"
                fill={colorLayer5}
            />
            <path
                d="M35.7791 43.08C32.4391 45.28 27.4291 46.99 26.5191 47.28C27.3891 47.06 32.1891 45.88 34.3791 46.45C35.3191 46.7 36.2991 46.75 37.1591 46.73C40.3091 46.64 45.0491 44.01 46.1891 41.65C46.2191 41.59 46.2391 41.54 46.2691 41.48C46.2991 41.42 46.3091 41.37 46.3291 41.31C47.0891 39.06 43.5191 38.87 39.5791 40.86C38.5091 41.4 37.1991 42.13 35.7791 43.07V43.08Z"
                fill={colorLayer1}
            />
            <path
                d="M26.7691 48.25L26.2091 46.33C27.1491 46.02 32.0091 44.36 35.2291 42.24C36.4891 41.41 37.8091 40.64 39.1291 39.97C41.7991 38.62 45.1891 37.85 46.7291 39.18C47.4091 39.77 47.6091 40.66 47.2791 41.63C47.2591 41.7 47.2291 41.78 47.1891 41.86C47.1691 41.92 47.1291 42 47.0891 42.08C45.7991 44.76 40.7991 47.61 37.1991 47.72C36.0691 47.74 35.0391 47.65 34.1391 47.41C32.7291 47.04 29.4291 47.56 26.7791 48.24L26.7691 48.25ZM33.8291 45.35C34.1191 45.38 34.3891 45.43 34.6391 45.49C35.3591 45.68 36.1991 45.77 37.1391 45.73C39.9191 45.65 44.3391 43.2 45.2891 41.21L45.3791 40.99C45.4391 40.82 45.4291 40.72 45.4291 40.7C45.2191 40.4 43.1991 40.15 40.0291 41.75C38.7691 42.39 37.5291 43.11 36.3291 43.9C35.5591 44.41 34.7091 44.89 33.8291 45.33V45.35Z"
                fill={colorLayer1}
            />
            <path
                d="M26.5191 47.28C26.5191 47.28 26.5191 47.28 26.5091 47.28C26.4891 47.28 26.4491 47.3 26.3791 47.32C26.4391 47.3 26.4791 47.29 26.4991 47.29C26.5091 47.29 26.5191 47.29 26.5191 47.29V47.28Z"
                fill={colorLayer5}
            />
            <path
                d="M17.1291 51.53C20.4691 49.33 25.4791 47.62 26.3891 47.33C25.5191 47.55 20.7191 48.73 18.5291 48.16C17.5891 47.91 16.6091 47.86 15.7491 47.88C12.5991 47.97 7.85908 50.6 6.71908 52.96C6.68908 53.02 6.66912 53.07 6.63912 53.13C6.60912 53.19 6.59912 53.24 6.57912 53.3C5.81912 55.55 9.38912 55.74 13.3291 53.75C14.3991 53.21 15.7091 52.48 17.1291 51.54V51.53Z"
                fill={colorLayer1}
            />
            <path
                d="M8.47911 56.11C7.54911 56.11 6.73912 55.9 6.17912 55.42C5.49912 54.83 5.29913 53.94 5.62913 52.97C5.64913 52.9 5.6791 52.82 5.7191 52.74C5.7491 52.67 5.78914 52.59 5.82914 52.51C7.11914 49.83 12.1191 46.98 15.7191 46.88C16.8491 46.85 17.8691 46.95 18.7791 47.19C20.1891 47.56 23.4891 47.04 26.1391 46.36L26.6991 48.28C25.7591 48.59 20.8991 50.25 17.6791 52.37C16.4091 53.21 15.0991 53.97 13.7791 54.64C12.0791 55.5 10.0991 56.12 8.47911 56.12V56.11ZM16.1191 48.87C16.0091 48.87 15.8891 48.87 15.7691 48.87C12.9891 48.95 8.56911 51.4 7.60911 53.39L7.51908 53.61C7.45908 53.78 7.4691 53.88 7.4691 53.9C7.6791 54.2 9.69912 54.45 12.8691 52.85C14.1191 52.22 15.3691 51.49 16.5691 50.7C17.3391 50.19 18.1891 49.71 19.0691 49.27C18.7791 49.24 18.5091 49.19 18.2591 49.13C17.6291 48.96 16.9091 48.88 16.1091 48.88L16.1191 48.87Z"
                fill={colorLayer1}
            />
            <path
                d="M26.5091 47.26C26.5091 47.26 26.5091 47.26 26.4891 47.26C26.4791 47.27 26.4491 47.3 26.4091 47.34C26.4591 47.3 26.4891 47.27 26.5091 47.26Z"
                fill={colorLayer5}
            />
            <path
                d="M22.3491 53.13C22.6091 51.19 25.8091 47.94 26.3991 47.35C25.7191 47.93 21.8591 51.15 18.1391 52.86C16.5491 53.59 15.2391 54.31 14.2291 54.93C10.4991 57.19 9.2691 60.04 11.9991 60.36C12.0691 60.36 12.1291 60.37 12.1991 60.38C12.2691 60.39 12.3391 60.38 12.4091 60.38C15.4091 60.44 20.1491 57.82 21.5191 55.41C21.8891 54.76 22.2291 53.97 22.3391 53.14L22.3491 53.13Z"
                fill={colorLayer1}
            />
            <path
                d="M12.5291 61.38C12.5291 61.38 12.4491 61.38 12.4091 61.38C12.3191 61.38 12.2291 61.38 12.1491 61.37C12.0591 61.37 11.9791 61.36 11.8991 61.35C10.1191 61.15 9.68908 60.06 9.58908 59.6C9.22908 57.96 10.8191 55.84 13.7291 54.08C14.9791 53.32 16.3291 52.61 17.7391 51.96C21.4191 50.27 25.3291 46.97 25.7591 46.6L27.1091 48.07C25.4091 49.77 23.4991 52.12 23.3391 53.28C23.2291 54.12 22.9091 55.01 22.3991 55.91C20.8691 58.61 15.8391 61.39 12.5191 61.39L12.5291 61.38ZM21.6091 52.11C20.6391 52.72 19.5991 53.3 18.5791 53.77C17.2391 54.39 15.9591 55.06 14.7691 55.78C12.0291 57.44 11.4491 58.94 11.5491 59.17C11.5691 59.2 11.7191 59.31 12.1291 59.36L12.4591 59.38C12.4591 59.38 12.5191 59.38 12.5391 59.38C15.1191 59.38 19.4891 56.99 20.6691 54.91C21.0491 54.23 21.2891 53.59 21.3691 53C21.4091 52.71 21.4891 52.41 21.6091 52.1V52.11Z"
                fill={colorLayer1}
            />
            <path
                d="M26.3991 47.35C26.3991 47.35 26.3991 47.35 26.4191 47.35C26.4291 47.34 26.4591 47.31 26.4991 47.27C26.4491 47.31 26.4191 47.34 26.3991 47.35Z"
                fill={colorLayer5}
            />
            <path
                d="M30.5591 41.48C30.2991 43.42 27.0991 46.67 26.5091 47.26C27.1891 46.68 31.0491 43.46 34.7691 41.75C36.3591 41.02 37.6691 40.3 38.6791 39.68C42.4091 37.42 43.6391 34.57 40.9091 34.25C40.8391 34.25 40.7791 34.24 40.7091 34.23C40.6391 34.22 40.5691 34.23 40.4991 34.23C37.4991 34.17 32.7591 36.79 31.3891 39.2C31.0191 39.85 30.6791 40.64 30.5691 41.47L30.5591 41.48Z"
                fill={colorLayer1}
            />
            <path
                d="M27.1491 48.02L25.7991 46.55C27.4991 44.85 29.4091 42.5 29.5691 41.34C29.6791 40.5 29.9991 39.61 30.5091 38.71C32.0391 36.01 37.0691 33.23 40.3891 33.23C40.4291 33.23 40.4691 33.23 40.5091 33.23C40.5991 33.23 40.6891 33.23 40.7691 33.24C40.8391 33.24 40.9291 33.25 41.0091 33.26C42.8091 33.47 43.2291 34.55 43.3291 35.01C43.6891 36.65 42.0991 38.77 39.1891 40.53C37.9391 41.28 36.5891 42 35.1791 42.65C31.5091 44.34 27.5891 47.64 27.1591 48.01L27.1491 48.02ZM40.3691 35.23C37.7891 35.23 33.4191 37.62 32.2491 39.7C31.8691 40.38 31.6291 41.02 31.5491 41.61C31.5091 41.9 31.4291 42.2 31.3091 42.5C32.2791 41.89 33.3191 41.31 34.3391 40.84C35.6891 40.22 36.9691 39.54 38.1491 38.83C40.8891 37.17 41.4691 35.67 41.3691 35.44C41.3491 35.41 41.1991 35.3 40.7891 35.25L40.4591 35.23C40.4591 35.23 40.3991 35.23 40.3691 35.23Z"
                fill={colorLayer1}
            />
            <path
                d="M22.5991 41.58C23.1491 41.91 23.6291 42.28 24.1091 42.64L25.0691 44.07L26.9991 46.93L28.9291 49.79L29.8891 51.22C30.0491 51.81 30.2091 52.39 30.3091 53.01C29.7691 52.68 29.2891 52.31 28.8091 51.94L27.8491 50.51L25.9191 47.65L23.9891 44.79L23.0191 43.36C22.8591 42.77 22.6991 42.19 22.5991 41.56V41.58Z"
                fill={colorLayer6}
            />
            <path
                d="M37.2291 37.45C36.5091 38.47 35.7491 39.43 34.9791 40.4L32.2791 42.86L26.8891 47.79L21.4991 52.72L18.8091 55.18C17.7791 55.86 16.7491 56.54 15.6791 57.16C16.3991 56.15 17.1691 55.18 17.9291 54.22L20.6191 51.76L26.0091 46.83L31.3991 41.9L34.0891 39.43C35.1191 38.75 36.1491 38.07 37.2291 37.45Z"
                fill={colorLayer6}
            />
            <path
                d="M41.3791 42.97C40.2091 43.59 39.0191 44.14 37.8291 44.68L34.0991 45.76L26.6391 47.93L19.1791 50.1L15.4491 51.18C14.1491 51.35 12.8491 51.53 11.5391 51.64C12.7091 51.03 13.8991 50.48 15.0891 49.93L18.8191 48.85L26.2791 46.68L33.7391 44.51L37.4691 43.42C38.7691 43.24 40.0591 43.07 41.3791 42.97Z"
                fill={colorLayer6}
            />
            <path
                d="M20.8691 47.99C19.8491 48.54 19.2291 49.31 19.4991 49.7C19.7691 50.09 20.8092 49.96 21.8292 49.41C22.8492 48.86 23.4692 48.09 23.1992 47.7C22.9292 47.31 21.8891 47.44 20.8691 47.99Z"
                fill={colorLayer4}
            />
            <path
                d="M30.2356 44.7606C31.2661 44.2285 31.9269 43.4589 31.7115 43.0418C31.4962 42.6247 30.4861 42.7179 29.4556 43.2501C28.4251 43.7822 27.7643 44.5517 27.9797 44.9688C28.1951 45.3859 29.205 45.2927 30.2356 44.7606Z"
                fill={colorLayer4}
            />
            <path
                d="M25.3936 46.1285C26.4242 45.5964 27.0849 44.8269 26.8696 44.4097C26.6542 43.9926 25.6442 44.0858 24.6137 44.618C23.5831 45.1501 22.9223 45.9196 23.1377 46.3367C23.3531 46.7538 24.3631 46.6606 25.3936 46.1285Z"
                fill={colorLayer4}
            />
            <path
                d="M22.8858 51.1683C23.9164 50.6362 24.5771 49.8667 24.3617 49.4496C24.1464 49.0325 23.1364 49.1257 22.1059 49.6578C21.0753 50.1899 20.4145 50.9594 20.6299 51.3766C20.8453 51.7937 21.8553 51.7004 22.8858 51.1683Z"
                fill={colorLayer4}
            />
            <path
                d="M30.6191 45.16C29.5991 45.71 28.9791 46.48 29.2491 46.87C29.5191 47.26 30.5592 47.13 31.5792 46.58C32.5992 46.03 33.2192 45.26 32.9492 44.87C32.6792 44.48 31.6391 44.61 30.6191 45.16Z"
                fill={colorLayer4}
            />
            <path
                d="M27.8802 49.8194C28.9107 49.2872 29.5715 48.5177 29.3561 48.1006C29.1408 47.6835 28.1308 47.7767 27.1003 48.3088C26.0697 48.841 25.4089 49.6105 25.6243 50.0276C25.8397 50.4447 26.8497 50.3515 27.8802 49.8194Z"
                fill={colorLayer4}
            />
            <path
                d="M68.5891 53.89C68.5891 53.89 68.5991 53.87 68.6191 53.85C68.6191 53.87 68.6091 53.89 68.6091 53.89H68.5891Z"
                fill={colorLayer5}
            />
            <path
                d="M72.3991 51.8C69.9491 52.04 68.7991 53.56 68.6091 53.85C68.6891 53.53 69.0691 51.74 67.4691 50.1C66.7891 49.4 66.3891 48.81 66.1691 48.36C65.3291 46.68 68.0391 45.99 71.4191 47.08C71.4991 47.11 71.5791 47.13 71.6691 47.16C71.7591 47.19 71.8291 47.22 71.9091 47.25C75.2391 48.47 76.6491 50.59 74.7191 51.31C74.1991 51.51 73.4491 51.7 72.3991 51.8Z"
                fill={colorLayer1}
            />
            <path
                d="M67.3891 56.51L68.1291 53.72C68.2091 53.43 68.5191 51.88 67.1191 50.45C66.4891 49.81 66.0191 49.18 65.7291 48.58C65.2991 47.72 65.6291 47.14 65.8591 46.87C66.7091 45.88 69.0091 45.77 71.5791 46.6L72.0891 46.77C74.5291 47.67 76.0991 49.05 76.0991 50.3C76.0991 50.68 75.9491 51.38 74.8991 51.77C74.2291 52.02 73.3991 52.19 72.4491 52.29C70.2391 52.5 69.2091 53.85 69.0191 54.12L67.3891 56.5V56.51ZM68.3991 47.05C67.4591 47.05 66.8391 47.26 66.6091 47.53C66.5491 47.6 66.4191 47.75 66.6091 48.14C66.8591 48.64 67.2691 49.19 67.8191 49.75C68.7591 50.72 69.0891 51.74 69.1691 52.54C69.8491 52 70.8891 51.44 72.3391 51.3C73.2091 51.22 73.9391 51.06 74.5391 50.84C74.9091 50.7 75.0891 50.52 75.0891 50.31C75.0891 49.75 74.0491 48.57 71.7291 47.72L71.2591 47.56C70.1291 47.19 69.1491 47.05 68.3891 47.05H68.3991Z"
                fill={colorLayer1}
            />
            <path
                d="M68.6091 53.84C68.6091 53.84 68.5991 53.86 68.5791 53.88C68.5791 53.86 68.5891 53.84 68.5891 53.84H68.6091Z"
                fill={colorLayer5}
            />
            <path
                d="M64.7991 55.93C67.2491 55.69 68.3991 54.17 68.5891 53.88C68.5091 54.2 68.1291 55.99 69.7291 57.63C70.4091 58.33 70.8091 58.92 71.0291 59.37C71.8691 61.05 69.1591 61.74 65.7791 60.65C65.6991 60.62 65.6191 60.6 65.5291 60.57C65.4391 60.54 65.3691 60.51 65.2891 60.48C61.9591 59.26 60.5491 57.14 62.4791 56.42C62.9991 56.22 63.7491 56.03 64.7991 55.93Z"
                fill={colorLayer1}
            />
            <path
                d="M68.8091 61.69C67.8691 61.69 66.7691 61.51 65.6191 61.13L65.1091 60.96C62.6691 60.06 61.0991 58.68 61.0991 57.43C61.0991 57.05 61.2491 56.35 62.2991 55.96C62.9691 55.71 63.7991 55.54 64.7491 55.44C66.9691 55.23 67.9891 53.88 68.1791 53.61L69.8191 51.2L69.0791 54.01C68.9991 54.3 68.6891 55.84 70.0891 57.28C70.7191 57.92 71.1891 58.55 71.4791 59.15C71.9091 60.01 71.5791 60.59 71.3491 60.86C70.8791 61.41 69.9691 61.68 68.8191 61.68L68.8091 61.69ZM64.8491 56.43C63.9791 56.51 63.2391 56.67 62.6491 56.89C62.2791 57.03 62.0991 57.21 62.0991 57.42C62.0991 57.98 63.1391 59.16 65.4591 60.01L65.9391 60.17C68.4591 60.99 70.1791 60.68 70.5891 60.2C70.6491 60.13 70.7791 59.98 70.5891 59.59C70.3391 59.09 69.9291 58.55 69.3791 57.98C68.4391 57.01 68.1091 55.99 68.0291 55.19C67.3491 55.73 66.3091 56.29 64.8591 56.43H64.8491Z"
                fill={colorLayer1}
            />
            <path
                d="M68.6591 53.87C68.6591 53.87 68.6591 53.87 68.6491 53.87C68.6291 53.87 68.5991 53.87 68.5391 53.85C68.5891 53.85 68.6291 53.85 68.6391 53.85H68.6491L68.6591 53.87Z"
                fill={colorLayer5}
            />
            <path
                d="M60.6291 51.72C63.5691 53.04 67.7891 53.74 68.5491 53.86C67.8291 53.8 63.8491 53.49 62.1791 54.23C61.4691 54.55 60.6891 54.72 60.0091 54.81C57.4891 55.15 53.3691 53.67 52.1591 51.95C52.1291 51.91 52.0991 51.87 52.0791 51.82C52.0591 51.77 52.0291 51.74 52.0091 51.7C51.1091 50.01 53.9291 49.39 57.3191 50.46C58.2391 50.75 59.3791 51.16 60.6291 51.72Z"
                fill={colorLayer1}
            />
            <path
                d="M59.2191 55.36C56.5191 55.36 52.9291 53.91 51.7491 52.23L51.6491 52.08C51.6091 52.02 51.5891 51.97 51.5591 51.92C51.1191 51.08 51.4191 50.51 51.6391 50.24C52.4591 49.26 54.8491 49.15 57.4591 49.97C58.5891 50.32 59.7191 50.76 60.8291 51.25C63.6991 52.53 67.8191 53.22 68.6191 53.35L68.4991 54.34C66.4291 54.16 63.5791 54.13 62.3791 54.67C61.7191 54.96 60.9391 55.17 60.0691 55.29C59.7991 55.33 59.5091 55.34 59.2191 55.34V55.36ZM54.2091 50.43C53.2491 50.43 52.6191 50.64 52.4091 50.89C52.3591 50.95 52.2391 51.09 52.4391 51.46L52.5591 51.66C53.6491 53.22 57.5791 54.63 59.9291 54.32C60.7091 54.22 61.3891 54.03 61.9691 53.78C62.4491 53.57 63.0891 53.43 63.7791 53.35C62.6391 53.04 61.4591 52.65 60.4191 52.19C59.3491 51.71 58.2491 51.29 57.1591 50.95C55.9891 50.58 54.9891 50.44 54.2091 50.44V50.43Z"
                fill={colorLayer1}
            />
            <path
                d="M68.5391 53.86C68.5391 53.86 68.5391 53.86 68.5491 53.86C68.5691 53.86 68.5991 53.86 68.6591 53.88C68.6091 53.88 68.5691 53.88 68.5591 53.88H68.5491L68.5391 53.86Z"
                fill={colorLayer5}
            />
            <path
                d="M76.5791 56.01C73.6391 54.69 69.4191 53.99 68.6591 53.87C69.3791 53.93 73.3591 54.24 75.0291 53.5C75.7391 53.18 76.5191 53.01 77.1991 52.92C79.7191 52.58 83.8391 54.06 85.0491 55.78C85.0791 55.82 85.1091 55.86 85.1291 55.91C85.1491 55.96 85.1791 55.99 85.1991 56.03C86.0991 57.72 83.2791 58.34 79.8891 57.27C78.9691 56.98 77.8291 56.57 76.5791 56.01Z"
                fill={colorLayer1}
            />
            <path
                d="M82.9891 58.3C82.0291 58.3 80.9091 58.12 79.7391 57.75C78.6091 57.4 77.4791 56.96 76.3691 56.47C73.4991 55.19 69.3791 54.5 68.5791 54.37L68.6991 53.38C70.7691 53.56 73.6191 53.59 74.8191 53.05C75.4791 52.75 76.2591 52.55 77.1291 52.43C79.8691 52.06 84.1491 53.64 85.4591 55.5C85.4991 55.55 85.5291 55.6 85.5591 55.65C85.5891 55.7 85.6191 55.75 85.6491 55.8C86.0891 56.64 85.7891 57.21 85.5691 57.48C85.1191 58.02 84.1791 58.3 82.9991 58.3H82.9891ZM76.7791 55.56C77.8491 56.04 78.9491 56.46 80.0391 56.8C82.6291 57.61 84.4091 57.31 84.7891 56.84C84.8391 56.78 84.9591 56.64 84.7591 56.27L84.6391 56.07C83.5491 54.51 79.6291 53.1 77.2691 53.41C76.4891 53.51 75.8091 53.7 75.2291 53.95C74.7491 54.16 74.1091 54.3 73.4191 54.38C74.5591 54.69 75.7491 55.08 76.7791 55.55V55.56Z"
                fill={colorLayer1}
            />
            <path
                d="M68.5591 53.84C68.5591 53.84 68.5591 53.84 68.5691 53.84C68.5791 53.84 68.6091 53.87 68.6491 53.89C68.6091 53.86 68.5791 53.85 68.5591 53.84Z"
                fill={colorLayer5}
            />
            <path
                d="M72.6291 57.97C72.1691 56.46 69.1991 54.29 68.6491 53.9C69.2691 54.27 72.7591 56.33 75.9391 57.2C77.2991 57.57 78.4391 57.98 79.3191 58.33C82.5791 59.64 83.9391 61.75 81.8091 62.36C81.7591 62.37 81.6991 62.39 81.6491 62.4C81.5991 62.41 81.5391 62.42 81.4791 62.44C79.0991 62.88 74.9791 61.41 73.5791 59.67C73.1991 59.2 72.8291 58.61 72.6291 57.97Z"
                fill={colorLayer1}
            />
            <path
                d="M80.5391 63.02C77.9891 63.02 74.5291 61.65 73.1891 59.98C72.6891 59.36 72.3391 58.73 72.1491 58.11C71.8491 57.13 70.0291 55.49 68.3591 54.3L68.9091 53.46C69.5591 53.85 72.9791 55.86 76.0791 56.71C77.2691 57.04 78.4291 57.43 79.5191 57.86C81.9091 58.82 83.4091 60.21 83.3491 61.41C83.3291 61.8 83.1191 62.5 81.9591 62.83L81.5791 62.92C81.2591 62.98 80.9191 63.01 80.5491 63.01L80.5391 63.02ZM72.1691 56.32C72.6091 56.83 72.9591 57.35 73.1091 57.83C73.2591 58.33 73.5491 58.84 73.9691 59.36C75.2691 60.97 79.2191 62.35 81.3891 61.95L81.6791 61.88C81.9691 61.8 82.3291 61.64 82.3391 61.37C82.3691 60.85 81.3891 59.7 79.1391 58.8C78.0891 58.38 76.9691 58 75.8091 57.68C74.5791 57.34 73.3091 56.84 72.1791 56.31L72.1691 56.32Z"
                fill={colorLayer1}
            />
            <path
                d="M68.6491 53.9C68.6491 53.9 68.6491 53.9 68.6391 53.9C68.6291 53.9 68.5991 53.87 68.5591 53.85C68.5991 53.88 68.6291 53.89 68.6491 53.9Z"
                fill={colorLayer5}
            />
            <path
                d="M64.5691 49.76C65.0291 51.27 67.9991 53.44 68.5491 53.83C67.9291 53.46 64.4391 51.4 61.2591 50.53C59.8991 50.16 58.7591 49.75 57.8791 49.4C54.6191 48.09 53.2592 45.98 55.3892 45.37C55.4392 45.36 55.4991 45.34 55.5491 45.33C55.5991 45.32 55.6591 45.31 55.7191 45.29C58.0991 44.85 62.2191 46.32 63.6191 48.06C63.9991 48.53 64.3691 49.12 64.5691 49.76Z"
                fill={colorLayer1}
            />
            <path
                d="M68.2991 54.26C67.6491 53.87 64.2491 51.87 61.1291 51.01C59.9391 50.68 58.7791 50.29 57.6891 49.86C55.2991 48.9 53.7991 47.51 53.8591 46.31C53.8791 45.92 54.0891 45.22 55.2491 44.89L55.6291 44.8C58.1691 44.33 62.4792 45.84 64.0092 47.75C64.5092 48.37 64.8591 49 65.0491 49.62C65.3491 50.6 67.1691 52.24 68.8391 53.43L68.2891 54.26H68.2991ZM56.6591 45.71C56.3591 45.71 56.0691 45.73 55.8191 45.78L55.5291 45.85C55.2391 45.93 54.8791 46.09 54.8691 46.36C54.8391 46.88 55.8191 48.03 58.0691 48.93C59.1191 49.35 60.2391 49.73 61.3991 50.05C62.6291 50.39 63.8991 50.89 65.0291 51.42C64.5891 50.91 64.2391 50.39 64.0991 49.91C63.9491 49.41 63.6591 48.9 63.2391 48.38C62.0991 46.96 58.8891 45.72 56.6591 45.72V45.71Z"
                fill={colorLayer1}
            />
            <path
                d="M70.9191 48.8C70.9691 49.33 70.9491 49.84 70.9391 50.34L70.3491 51.6L69.1891 54.13L68.0291 56.66L67.4491 57.93C67.0791 58.27 66.7091 58.61 66.2791 58.92C66.2391 58.39 66.2591 57.89 66.2691 57.38L66.8491 56.11L68.0091 53.58L69.1691 51.05L69.7391 49.78C70.1091 49.44 70.4791 49.1 70.9191 48.79V48.8Z"
                fill={colorLayer6}
            />
            <path
                d="M58.7391 47.43C59.7091 47.74 60.6391 48.12 61.5591 48.49L64.0191 50.1L68.9491 53.32L73.8791 56.54L76.3491 58.15C77.0591 58.85 77.7791 59.55 78.4591 60.3C77.4991 59.98 76.5691 59.61 75.6391 59.24L73.1691 57.63L68.2391 54.41L63.3091 51.19L60.8391 49.59C60.1191 48.89 59.4091 48.19 58.7291 47.43H58.7391Z"
                fill={colorLayer6}
            />
            <path
                d="M56.1591 52.37C57.2291 52.23 58.2891 52.16 59.3491 52.09L62.4591 52.47L68.6791 53.22L74.8991 53.97L78.0091 54.34C79.0191 54.66 80.0391 54.98 81.0391 55.36C79.9691 55.49 78.9091 55.56 77.8491 55.63L74.7391 55.26L68.5191 54.51L62.2991 53.76L59.1891 53.39C58.1791 53.08 57.1591 52.76 56.1591 52.37Z"
                fill={colorLayer6}
            />
            <path
                d="M73.1391 53.68C74.0291 53.99 74.6091 54.52 74.4491 54.86C74.2891 55.2 73.4391 55.24 72.5591 54.93C71.6791 54.62 71.0891 54.09 71.2491 53.75C71.4091 53.41 72.2591 53.37 73.1391 53.68Z"
                fill={colorLayer4}
            />
            <path
                d="M67.0199 52.1854C67.1375 51.8229 66.5089 51.2941 65.6159 51.0043C64.7228 50.7145 63.9035 50.7734 63.7859 51.1359C63.6683 51.4983 64.2969 52.0271 65.1899 52.3169C66.0829 52.6067 66.9022 52.5479 67.0199 52.1854Z"
                fill={colorLayer4}
            />
            <path
                d="M71.0514 52.6442C71.169 52.2817 70.5405 51.7529 69.6474 51.4631C68.7544 51.1733 67.9351 51.2322 67.8175 51.5946C67.6998 51.9571 68.3284 52.4859 69.2214 52.7757C70.1145 53.0655 70.9338 53.0066 71.0514 52.6442Z"
                fill={colorLayer4}
            />
            <path
                d="M73.7092 56.3327C73.8268 55.9702 73.1982 55.4414 72.3052 55.1516C71.4122 54.8618 70.5929 54.9207 70.4752 55.2831C70.3576 55.6456 70.9862 56.1744 71.8792 56.4642C72.7723 56.754 73.5916 56.6951 73.7092 56.3327Z"
                fill={colorLayer4}
            />
            <path
                d="M65.0091 52.7C65.8991 53.01 66.4791 53.54 66.3191 53.88C66.1591 54.22 65.3091 54.26 64.4291 53.95C63.5491 53.64 62.9591 53.11 63.1191 52.77C63.2791 52.43 64.1291 52.39 65.0091 52.7Z"
                fill={colorLayer4}
            />
            <path
                d="M69.5523 55.8977C69.6699 55.5352 69.0413 55.0065 68.1483 54.7166C67.2552 54.4268 66.4359 54.4857 66.3183 54.8482C66.2007 55.2106 66.8293 55.7394 67.7223 56.0293C68.6153 56.3191 69.4346 56.2602 69.5523 55.8977Z"
                fill={colorLayer4}
            />
            <path
                d="M55.5691 21.48C55.5691 21.48 55.5691 21.48 55.5791 21.47C55.5791 21.45 55.5891 21.4 55.5991 21.33C55.5791 21.4 55.5691 21.44 55.5591 21.46C55.5591 21.47 55.5591 21.48 55.5591 21.48H55.5691Z"
                fill={colorLayer5}
            />
            <path
                d="M54.7291 11.43C56.5391 14.99 55.7691 20.36 55.6091 21.33C55.8991 20.39 57.6291 15.25 60.8391 12.88C62.2091 11.87 63.2191 10.81 63.9291 9.89002C66.5491 6.50002 65.0491 1.44002 60.9291 0.25002C60.8291 0.22002 60.7291 0.19002 60.6291 0.17002C60.5291 0.15002 60.4291 0.12002 60.3191 0.11002C56.1091 -0.67998 52.4891 3.17002 53.3091 7.38002C53.5291 8.52002 53.9491 9.92002 54.7191 11.44L54.7291 11.43Z"
                fill={colorLayer1}
            />
            <path
                d="M55.6091 21.33C55.6091 21.33 55.6091 21.33 55.5991 21.34C55.5991 21.36 55.5891 21.41 55.5791 21.48C55.5991 21.41 55.6091 21.37 55.6191 21.35C55.6191 21.34 55.6191 21.33 55.6191 21.33H55.6091Z"
                fill={colorLayer5}
            />
            <path
                d="M56.4491 31.39C54.6391 27.83 55.4091 22.46 55.5691 21.49C55.2791 22.43 53.5491 27.57 50.3391 29.94C48.9691 30.95 47.9591 32.01 47.2491 32.93C44.6291 36.32 46.1291 41.38 50.2491 42.57C50.3491 42.6 50.4491 42.63 50.5491 42.65C50.6491 42.67 50.7491 42.7 50.8591 42.71C55.0691 43.5 58.6891 39.65 57.8691 35.44C57.6491 34.3 57.2291 32.9 56.4591 31.38L56.4491 31.39Z"
                fill={colorLayer1}
            />
            <path
                d="M55.5291 21.36C55.5291 21.36 55.5291 21.36 55.5291 21.38C55.5491 21.39 55.5791 21.42 55.6391 21.46C55.5891 21.41 55.5591 21.38 55.5391 21.36C55.5291 21.36 55.5191 21.36 55.5191 21.36H55.5291Z"
                fill={colorLayer5}
            />
            <path
                d="M64.7891 25.37C60.7991 25.28 56.4291 22.06 55.6491 21.46C56.3391 22.16 60.0591 26.12 60.6191 30.07C60.8591 31.76 61.3191 33.14 61.7991 34.2C63.5491 38.11 68.7191 39.18 71.7091 36.12C71.7791 36.05 71.8491 35.97 71.9191 35.89C71.9891 35.81 72.0591 35.73 72.1191 35.65C74.8091 32.31 73.1191 27.31 69.0291 26.04C67.9191 25.7 66.4891 25.4 64.7791 25.37H64.7891Z"
                fill={colorLayer1}
            />
            <path
                d="M55.6491 21.46C55.6491 21.46 55.6491 21.46 55.6491 21.44C55.6291 21.43 55.5991 21.4 55.5391 21.36C55.5891 21.41 55.6191 21.44 55.6391 21.46C55.6491 21.46 55.6591 21.46 55.6591 21.46H55.6491Z"
                fill={colorLayer5}
            />
            <path
                d="M46.3891 17.45C50.3791 17.54 54.7491 20.76 55.5291 21.36C54.8391 20.66 51.1191 16.7 50.5591 12.75C50.3191 11.06 49.8591 9.68002 49.3791 8.62002C47.6291 4.71002 42.4591 3.64002 39.4691 6.70002C39.3991 6.77002 39.3291 6.85002 39.2591 6.93002C39.1891 7.01002 39.1191 7.09002 39.0591 7.17002C36.3691 10.51 38.0591 15.51 42.1491 16.78C43.2591 17.12 44.6891 17.42 46.3991 17.45H46.3891Z"
                fill={colorLayer1}
            />
            <path
                d="M55.6591 21.39C55.6591 21.39 55.6591 21.39 55.6391 21.39C55.6191 21.39 55.5791 21.41 55.5091 21.44C55.5791 21.42 55.6191 21.41 55.6391 21.41L55.6491 21.4L55.6591 21.39Z"
                fill={colorLayer5}
            />
            <path
                d="M47.4991 27.32C49.6091 23.93 54.5992 21.8 55.5092 21.44C54.5592 21.67 49.2592 22.87 45.5692 21.34C43.9892 20.69 42.5691 20.38 41.4091 20.25C37.1491 19.77 33.5991 23.67 34.7091 27.81C34.7391 27.91 34.7691 28.01 34.7991 28.11C34.8291 28.21 34.8691 28.31 34.8991 28.41C36.4091 32.42 41.5691 33.52 44.7491 30.64C45.6091 29.86 46.5891 28.78 47.4891 27.33L47.4991 27.32Z"
                fill={colorLayer1}
            />
            <path
                d="M55.5191 21.43C55.5191 21.43 55.5191 21.43 55.5391 21.43C55.5591 21.43 55.5991 21.41 55.6691 21.38C55.5991 21.4 55.5591 21.41 55.5391 21.41L55.5291 21.42L55.5191 21.43Z"
                fill={colorLayer5}
            />
            <path
                d="M63.6791 15.5C61.5691 18.89 56.5791 21.02 55.6691 21.38C56.6191 21.15 61.9191 19.95 65.6091 21.48C67.1891 22.13 68.6091 22.44 69.7691 22.57C74.0291 23.05 77.5791 19.15 76.4691 15.01C76.4391 14.91 76.4091 14.81 76.3791 14.71C76.3491 14.61 76.3091 14.51 76.2791 14.41C74.7691 10.4 69.6091 9.30002 66.4291 12.18C65.5691 12.96 64.5891 14.04 63.6891 15.49L63.6791 15.5Z"
                fill={colorLayer1}
            />
            <path
                d="M59.3891 5.38002C59.4691 6.81002 59.4391 8.21002 59.4091 9.62002L58.4491 13.62L56.5491 21.63L54.6491 29.64L53.6991 33.65C53.0891 34.92 52.4891 36.18 51.7891 37.43C51.7191 36 51.7591 34.6 51.7891 33.2L52.7391 29.19L54.6391 21.18L56.5391 13.17L57.4791 9.16002C58.0791 7.89002 58.6791 6.62002 59.3991 5.38002H59.3891Z"
                fill={colorLayer6}
            />
            <path
                d="M71.2791 16.39C70.0991 17.2 68.8791 17.89 67.6591 18.59L63.7291 19.83L55.8891 22.34L48.0491 24.85L44.1291 26.1C42.7291 26.23 41.3391 26.37 39.9091 26.41C41.0991 25.62 42.3191 24.92 43.5291 24.22L47.4491 22.97L55.2891 20.46L63.1291 17.95L67.0491 16.69C68.4491 16.55 69.8391 16.41 71.2791 16.39Z"
                fill={colorLayer6}
            />
            <path
                d="M67.8391 32.42C66.5391 31.81 65.3291 31.11 64.1091 30.41L61.0491 27.65L54.9191 22.14L48.7891 16.63L45.7291 13.88C44.9091 12.74 44.0791 11.61 43.3291 10.39C44.6191 11.01 45.8291 11.71 47.0491 12.41L50.1091 15.16L56.2391 20.67L62.3691 26.18L65.4391 28.93C66.2591 30.07 67.0891 31.2 67.8391 32.42Z"
                fill={colorLayer6}
            />
            <path
                d="M51.9591 15.48C50.8591 15.22 49.7591 15.9 49.4991 17C49.2391 18.1 49.9191 19.2 51.0191 19.46C52.1191 19.72 53.2191 19.04 53.4791 17.94C53.7391 16.84 53.0591 15.74 51.9591 15.48Z"
                fill={colorLayer4}
            />
            <path
                d="M60.7291 21.49C61.8558 21.49 62.7691 20.5767 62.7691 19.45C62.7691 18.3234 61.8558 17.41 60.7291 17.41C59.6024 17.41 58.6891 18.3234 58.6891 19.45C58.6891 20.5767 59.6024 21.49 60.7291 21.49Z"
                fill={colorLayer4}
            />
            <path
                d="M56.7891 17.85C57.9158 17.85 58.8291 16.9367 58.8291 15.81C58.8291 14.6834 57.9158 13.77 56.7891 13.77C55.6624 13.77 54.7491 14.6834 54.7491 15.81C54.7491 16.9367 55.6624 17.85 56.7891 17.85Z"
                fill={colorLayer4}
            />
            <path
                d="M50.3391 24.32C51.4657 24.32 52.3791 23.4067 52.3791 22.28C52.3791 21.1534 51.4657 20.24 50.3391 20.24C49.2124 20.24 48.2991 21.1534 48.2991 22.28C48.2991 23.4067 49.2124 24.32 50.3391 24.32Z"
                fill={colorLayer4}
            />
            <path
                d="M59.9591 22.67C58.8591 22.41 57.7591 23.09 57.4991 24.19C57.2391 25.29 57.9191 26.39 59.0191 26.65C60.1191 26.91 61.2191 26.23 61.4791 25.13C61.7391 24.03 61.0591 22.93 59.9591 22.67Z"
                fill={colorLayer4}
            />
            <path
                d="M54.3391 28.19C55.4657 28.19 56.3791 27.2767 56.3791 26.15C56.3791 25.0234 55.4657 24.11 54.3391 24.11C53.2124 24.11 52.2991 25.0234 52.2991 26.15C52.2991 27.2767 53.2124 28.19 54.3391 28.19Z"
                fill={colorLayer4}
            />
            <path
                d="M33.7091 51.05C29.1691 46.62 29.3491 39.08 29.3491 39.08C29.3491 39.08 36.8791 38.71 41.4191 43.14C45.9591 47.57 45.7791 55.11 45.7791 55.11C45.7791 55.11 38.2491 55.48 33.7091 51.05Z"
                fill={colorLayer2}
            />
            <path
                d="M45.7791 55.11C44.9491 54.59 44.1591 54.03 43.3691 53.47L41.3191 51.46L37.2091 47.45L33.0991 43.44L31.0491 41.44C30.4791 40.65 29.8891 39.89 29.3491 39.08C30.1791 39.6 30.9491 40.18 31.7491 40.73L33.7991 42.73L37.9091 46.74L42.0191 50.75L44.0791 52.75C44.6591 53.52 45.2391 54.3 45.7791 55.11Z"
                fill={colorLayer3}
            />
            <path
                d="M34.3291 70.86C30.2591 68.84 28.7191 63.67 28.7191 63.67C28.7191 63.67 33.7691 61.76 37.8391 63.77C41.9091 65.78 43.4491 70.96 43.4491 70.96C43.4491 70.96 38.3991 72.87 34.3291 70.86Z"
                fill={colorLayer2}
            />
            <path
                d="M34.3291 70.86C30.2591 68.84 28.7191 63.67 28.7191 63.67L43.4491 70.97C43.4491 70.97 38.3991 72.88 34.3291 70.87V70.86Z"
                fill={colorLayer3}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant6
