import { Card, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

const ChallengeYesterdaysRecapSkeleton: React.FC = () => {
    const { palette } = useTheme()
    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Stack bgcolor={palette.grey[100]} p={2} gap={2} height="100%">
                <Skeleton height={17} width={135} />
                <Stack gap={1.4375}>
                    <Skeleton
                        sx={{ height: 24, maxWidth: 325, width: '100%' }}
                    />
                    <Skeleton
                        sx={{ height: 21, maxWidth: 425, width: '100%' }}
                    />
                    <Skeleton
                        sx={{ height: 21, maxWidth: 425, width: '100%' }}
                    />
                    <Skeleton
                        sx={{ height: 21, maxWidth: 425, width: '100%' }}
                    />
                </Stack>
            </Stack>
        </Card>
    )
}

export default ChallengeYesterdaysRecapSkeleton
