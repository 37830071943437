import { Box, Button, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { StorySubmissionSteps } from '../../../../../enums/StorySubmissionSteps'
import { useInterstitialNavigation } from '../../../../contexts/withInterstitialNavigation'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'

export type ReviewYourStoryQuestionWrapperProps = {
    questionData: QuestionData
    children: React.ReactNode
    disableNavigation?: boolean
}

const messages = defineMessages({
    editQuestionAria: {
        defaultMessage: `Edit {question}`,
        description: 'aria name for edit question'
    }
})

const ReviewYourStoryQuestionWrapper: React.FC<
    ReviewYourStoryQuestionWrapperProps
> = ({ questionData, children, disableNavigation }) => {
    const intl = useIntl()
    const theme = useTheme()

    const { navigateToKey } = useInterstitialNavigation()

    return (
        <Stack spacing={1} data-testid="review-your-story-wrapper">
            <Stack direction="row">
                <CoreTypography
                    variant="h5"
                    sx={{
                        flexGrow: 1,
                        color: theme.palette.primary.main
                    }}
                >
                    {questionData.question}
                </CoreTypography>

                {!disableNavigation && (
                    <Box mx={2}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                py: theme.spacing(0.3),
                                px: theme.spacing(0.7),
                                minWidth: 'fit-content',
                                borderColor: `${theme.palette.text.primary}17`
                            }}
                            onClick={() =>
                                navigateToKey(
                                    StorySubmissionSteps.questions,
                                    Number(questionData.ordering) - 1
                                )
                            }
                            data-testid={`edit-question-${questionData.ordering}`}
                        >
                            <CoreTypography
                                variant="h6"
                                sx={{
                                    textTransform: 'uppercase',
                                    color: theme.palette.grey[900]
                                }}
                                aria-label={intl.formatMessage(
                                    messages.editQuestionAria,
                                    { question: questionData.question }
                                )}
                            >
                                <FormattedMessage
                                    defaultMessage="edit"
                                    description="button to edit a previously added answer"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                )}
            </Stack>
            {children}
        </Stack>
    )
}

export default ReviewYourStoryQuestionWrapper
