import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ModifiedFormattedMessageType } from 'src/types'
import { MeasureUnit } from '../../../../../../../../graphql/generated/autogenerated'
import { useGlassesMeasurementConversion } from '../../../../../../../hooks/useMeasurementConversion/useGlassesMeasurementConversion'

const ModifiedFormattedMessage =
    FormattedMessage as ModifiedFormattedMessageType

export interface HydrationTrackerInfoProps {
    glasses: number
    intakeMeasurement: MeasureUnit
    completed: boolean
}

const HydrationTrackerInfo: React.FC<HydrationTrackerInfoProps> = ({
    glasses,
    intakeMeasurement,
    completed
}) => {
    const { palette } = useTheme()
    const formatted = useGlassesMeasurementConversion(
        glasses,
        intakeMeasurement
    )

    return (
        <ModifiedFormattedMessage
            id="message.hydrationTrackerInfo"
            defaultMessage="<main>{glasses}</main> <secondary>/ {glassesAsMeasurement}</secondary>"
            description="current water intake tracker, the intake is measured in glasses and then converted to the users selected measurement E.G 1 / 7oz"
            values={{
                glasses,
                glassesAsMeasurement: formatted,
                main: (chunk: React.ReactNode[]) => (
                    <CoreTypography
                        customVariant="form"
                        color={
                            completed
                                ? palette.success.main
                                : palette.text.primary
                        }
                    >
                        {chunk}
                    </CoreTypography>
                ),
                secondary: (chunk: React.ReactNode[]) => (
                    <CoreTypography
                        variant="body2"
                        color={
                            completed
                                ? palette.success.main
                                : palette.text.disabled
                        }
                    >
                        {chunk}
                    </CoreTypography>
                )
            }}
        />
    )
}

export default HydrationTrackerInfo
