import { isToday, loadState, saveState } from '@thriveglobal/thrive-web-core'
import { useCallback } from 'react'
import { LocalStorageEvents } from '../../../shared/enums/localStorageEvents'

export const useGoalsCompletedLocalState = () => {
    const loadCurrentState = useCallback(
        (event: LocalStorageEvents, challengeId: string) => {
            const currentState = loadState(event)
            const currentChallengeState = currentState?.[challengeId]

            return currentChallengeState
        },
        []
    )

    const loadGoalsCompleted = useCallback(
        (challengeId: string) => {
            const currentState = loadCurrentState(
                LocalStorageEvents.COMPANY_CHALLENGE_DAILY_GOALS_COMPLETED_AND_SEEN,
                challengeId
            )

            return currentState && isToday(currentState?.date)
                ? currentState
                : { goalsCompletedCount: 0 }
        },
        [loadCurrentState]
    )

    const loadThresholdReached = useCallback(
        (challengeId: string) => {
            const currentState = loadCurrentState(
                LocalStorageEvents.COMPANY_CHALLENGE_THRESHOLD_COMPLETED_AND_SEEN,
                challengeId
            )

            return currentState && isToday(currentState?.date)
                ? currentState
                : { date: null }
        },
        [loadCurrentState]
    )

    const saveCurrentState = useCallback(
        (event: LocalStorageEvents, challengeId: string, props: any) => {
            const currentState = loadState(LocalStorageEvents)

            const newState = {
                [challengeId]: {
                    ...props
                },
                ...currentState
            }

            saveState(event, newState)
        },
        []
    )

    const saveGoalsCompleted = useCallback(
        (goalsCompletedCount: number, date: string, challengeId: string) =>
            saveCurrentState(
                LocalStorageEvents.COMPANY_CHALLENGE_DAILY_GOALS_COMPLETED_AND_SEEN,
                challengeId,
                {
                    goalsCompletedCount,
                    date
                }
            ),
        [saveCurrentState]
    )

    const saveThresholdReached = useCallback(
        (date: string, challengeId: string) =>
            saveCurrentState(
                LocalStorageEvents.COMPANY_CHALLENGE_THRESHOLD_COMPLETED_AND_SEEN,
                challengeId,
                {
                    date
                }
            ),
        [saveCurrentState]
    )

    const deleteState = useCallback(
        (challengeId: string, event: LocalStorageEvents) => {
            const currentState = loadState(event)

            if (currentState?.[challengeId]) {
                delete currentState[challengeId]
            }

            saveState(event, currentState)
        },
        []
    )

    const deleteAllCompletedState = useCallback(
        (challengeId: string) => {
            deleteState(
                challengeId,
                LocalStorageEvents.COMPANY_CHALLENGE_DAILY_GOALS_COMPLETED_AND_SEEN
            )
            deleteState(
                challengeId,
                LocalStorageEvents.COMPANY_CHALLENGE_THRESHOLD_COMPLETED_AND_SEEN
            )
        },
        [deleteState]
    )

    return {
        loadGoalsCompleted,
        loadThresholdReached,
        saveGoalsCompleted,
        saveThresholdReached,
        deleteAllCompletedState
    }
}

export default useGoalsCompletedLocalState
