import {
    Button,
    DialogActions,
    DialogContent,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ParticipantsList from '../GroupChallengeSettings/Participant/ParticipantsList'
import type {
    SocialGroup,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import useGetSocialGroups, {
    type SocialGroupsValues
} from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'

export interface ParticipantsDialogProps {
    open: boolean
    onClose: () => void
    challenge: UnifiedChallenge
    socialGroup?: SocialGroup | null
}

const ParticipantsDialog: React.FC<ParticipantsDialogProps> = ({
    open,
    onClose,
    challenge,
    socialGroup
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))

    const {
        mainCommunitySocialGroup,
        displayName,
        socialGroupsRefetch,
        loading: socialGroupsLoading
    } = useGetSocialGroups(challenge.id, {
        skip: !challenge.id
    })

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            fullScreen={isMobile}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Participants"
                        description="Challenge Settings Dialog Title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <ParticipantsList
                    challenge={challenge}
                    socialGroups={
                        {
                            displayName,
                            mainCommunitySocialGroup:
                                mainCommunitySocialGroup as SocialGroup,
                            socialGroup: socialGroup as SocialGroup,
                            socialGroupsRefetch
                        } as SocialGroupsValues
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Close"
                            description="close button text"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(ParticipantsDialog)
