import { Link } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import DisposableDialog from '../../../../elements/DisposableDialog'
import DailyGoalPreviewDialog from './DailyGoalPreviewDialog'
import {
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal
} from '../../../../../../graphql/generated/autogenerated'

type DailyGoalPreviewButtonProps = {
    goals: ChallengeTemplateGoal[] | ChallengeUserDailyGoal[]
}

const DailyGoalPreviewButton: React.FC<DailyGoalPreviewButtonProps> = ({
    goals
}) => (
    <DisposableDialog
        button={
            <Link href="javascript:void(0)">
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="Preview more goals"
                        description="view all challenge goals button"
                    />
                </CoreTypography>
            </Link>
        }
        Dialog={DailyGoalPreviewDialog}
        dialogProps={{
            goals: goals
        }}
    />
)

export default memo(DailyGoalPreviewButton)
