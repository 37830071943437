import { Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { SignUpTypes } from '../../../../../enums'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import { useCompanyChallengeSocialGroupContext } from '../../../../../hooks/withCompanyChallengeSocialGroupProvider'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import JoinCompanyChallengeButton from '../../JoinCompanyChallengeButton/JoinCompanyChallengeButton'

export interface HowToTakePartProps {
    challenge?: UnifiedChallenge
}

const HowToTakePart: React.FC<HowToTakePartProps> = ({ challenge }) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { refetch } = useCompanyChallengeProviderContext()
    // this provider is only active on invite related routes
    const { socialGroup } = useCompanyChallengeSocialGroupContext()
    const {
        setChallenge,
        setInviteSocialGroup,
        onOpen,
        setOnComplete,
        setIsChangeParticipation,
        setSignUpType
    } = useChallengeSignUpModalContext()

    const onSignUp = useCallback(
        (signUpType: SignUpTypes) => {
            setOnComplete({ onComplete: refetch })
            setChallenge(challenge)
            setSignUpType(signUpType)
            setIsChangeParticipation(false)
            onOpen(true)
        },
        [
            challenge,
            setChallenge,
            onOpen,
            refetch,
            setIsChangeParticipation,
            setOnComplete,
            setSignUpType
        ]
    )

    // If we are given a social Group from the social groups provider we assume this is an invite link and start the Sign Up process
    useEffect(() => {
        if (socialGroup) {
            setInviteSocialGroup(socialGroup)
            onSignUp(SignUpTypes.inviteTeam)
        }
    }, [setInviteSocialGroup, socialGroup, onSignUp])

    return (
        <Card elevation={16}>
            <Stack
                p={4}
                gap={2}
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent="center"
            >
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Ready to grow?"
                        description="text for header asking if ready to grow"
                    />
                </CoreTypography>
                <JoinCompanyChallengeButton />
            </Stack>
        </Card>
    )
}

export default memo(HowToTakePart)
