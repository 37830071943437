import { Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ViewMoreDialog from '../ViewMoreDialog'
import LeaderboardCard from '../../Shared/Leaderboards/LeaderboardCard'
import CommunityTotalPlants from '../../Shared/TotalPlantsGraph/CommunityTotalPlants'
import type {
    CommunityGardenInfo,
    UnifiedChallenge,
    SocialGroup
} from '../../../../../graphql/generated/autogenerated'

export type CommunityActivityDialogProps = {
    challenge: UnifiedChallenge
    communityGardenInfo: CommunityGardenInfo[]
    socialGroup: SocialGroup
    deviceEnabled: boolean
    open: boolean
    onClose: () => void
}

const messages = defineMessages({
    communityActivity: {
        defaultMessage:
            'Check out the collective efforts of you and your coworkers!',
        description: 'Community activity title'
    }
})

const CommunityActivityDialog: React.FC<CommunityActivityDialogProps> = ({
    challenge,
    communityGardenInfo,
    socialGroup,
    deviceEnabled,
    open,
    onClose
}) => {
    const { formatMessage } = useIntl()

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={3} pb={6}>
                <CoreTypography variant="h3" color="text.primary">
                    {formatMessage(messages.communityActivity)}
                </CoreTypography>
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <CommunityTotalPlants
                                challenge={challenge}
                                communityGardenInfo={communityGardenInfo}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LeaderboardCard
                                challenge={challenge}
                                socialGroup={socialGroup}
                                deviceEnabled={deviceEnabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(CommunityActivityDialog)
