import {
    Card,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import ConditionalSkeleton from '../ConditionalSkeleton'
import { ColumnData } from '.'

const tableRowStyle = {
    '&:last-child td, &:last-child th': {
        border: 0
    }
}

export interface LeaderboardTableSkeleton {
    columnData: ColumnData[]
    rowsPerPage?: number
    loadingNext?: boolean
}

const LeaderboardTableSkeleton: React.FC<LeaderboardTableSkeleton> = ({
    columnData,
    rowsPerPage = 10,
    loadingNext
}) => (
    <Stack>
        <TableContainer component={Card}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columnData.map((column, index: number) => (
                            <TableCell
                                key={index}
                                width={column.width}
                                {...(index + 1 === columnData.length && {
                                    align: 'right'
                                })}
                            >
                                <ConditionalSkeleton
                                    showSkeleton={!loadingNext}
                                >
                                    <CoreTypography
                                        variant="h5"
                                        color="primary"
                                    >
                                        {column.title}
                                    </CoreTypography>
                                </ConditionalSkeleton>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Array.from(Array(rowsPerPage))?.map((_, index: number) => (
                        <TableRow key={index} sx={tableRowStyle}>
                            {columnData.map((_, index: number) => (
                                <TableCell
                                    key={index}
                                    {...(index + 1 === columnData.length
                                        ? {
                                              align: 'right'
                                          }
                                        : {
                                              component: 'th',
                                              scope: 'row'
                                          })}
                                >
                                    <Skeleton
                                        variant="text"
                                        height={21.91}
                                        width="40%"
                                        sx={{
                                            ...(index + 1 ===
                                                columnData.length && {
                                                float: 'right'
                                            })
                                        }}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={3} align="right">
                            <Stack
                                gap={2}
                                direction="row"
                                justifyContent="end"
                                minWidth="100%"
                            >
                                <Skeleton
                                    variant="circular"
                                    width={44}
                                    height={44}
                                    sx={{ minWidth: 44, minHeight: 44 }}
                                />
                                <Skeleton
                                    variant="circular"
                                    width={44}
                                    height={44}
                                    sx={{ minWidth: 44, minHeight: 44 }}
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </Stack>
)

export default memo(LeaderboardTableSkeleton)
