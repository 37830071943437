import { Card, Grid, Skeleton, Stack } from '@mui/material'
import { PageLayout } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../routes'
import { BackActionHeader } from '../../../elements/Headers'
import ChallengeAboutSkeleton from '../ChallengeAbout/ChallengeAboutSkeleton'
import ChallengeGardenSkeleton from '../ChallengeGarden/ChallengeGardenSkeleton'
import ChallengeHeaderSkeleton from '../ChallengeHeader/ChallengeHeaderSkeleton'
import ChallengeTodaysProgressSkeleton from '../ChallengeTodaysProgress/ChallengeTodaysProgressSkeleton'
import DailyGoalCardSkeleton from '../DailyGoals/DailyGoalCard/DailyGoalCardSkeleton'
import YourPlantGoalSkeleton from '../PlantGoal/YourPlantGoal/YourPlantGoalSkeleton'
import YourTotalPlantsEarnedSkeleton from '../PlantsEarned/YourTotalPlantsEarned/YourTotalPlantsEarnedSkeleton'

const ChallengeSkeleton: React.FC = () => {
    const history = useHistory()
    const onBack = useCallback(() => {
        history.push(ROUTES.HOME)
    }, [history])

    return (
        <Stack minHeight="100vh" height="100%" width="100%">
            <PageLayout maxWidth="lg">
                <Stack pb={3} gap={6}>
                    <Stack>
                        <Stack pt={3} pb={1}>
                            <BackActionHeader onBack={onBack} />
                        </Stack>
                        <ChallengeHeaderSkeleton />
                    </Stack>
                    <Card elevation={0}>
                        <Stack p={2} gap={3}>
                            <Skeleton
                                variant="rounded"
                                height={32}
                                width="60%"
                            />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <DailyGoalCardSkeleton />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DailyGoalCardSkeleton />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DailyGoalCardSkeleton />
                                </Grid>
                            </Grid>
                            <ChallengeTodaysProgressSkeleton />
                        </Stack>
                    </Card>
                    <Stack gap={4}>
                        <Skeleton variant="rounded" height={34} width={150} />
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <ChallengeGardenSkeleton />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack height="100%" gap={4}>
                                    <YourTotalPlantsEarnedSkeleton />
                                    <YourPlantGoalSkeleton />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                    <ChallengeAboutSkeleton />
                </Stack>
            </PageLayout>
        </Stack>
    )
}

export default ChallengeSkeleton
