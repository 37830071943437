import { Box, Fade, Stack } from '@mui/material'
import React, { memo, useMemo } from 'react'

export type OverlappingComponentsListProps = {
    components: { [key: string]: JSX.Element }
    activeKey: string
}

const OverlappingComponentsList: React.FC<OverlappingComponentsListProps> = ({
    components,
    activeKey
}) => {
    const componentKeys = Object.keys(components)
    const componentListByKey = useMemo(
        () =>
            componentKeys.map((key, index) => (
                <Stack
                    key={key}
                    position="absolute"
                    maxWidth="100%"
                    width="100%"
                    height="100%"
                >
                    <Fade
                        in={key === activeKey}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                    >
                        <Stack
                            data-testid={`overlapping-${key}`}
                            width="100%"
                            height="100%"
                            zIndex={componentKeys.length - index}
                        >
                            {components[key]}
                        </Stack>
                    </Fade>
                </Stack>
            )),
        [componentKeys, components, activeKey]
    )

    return (
        <Box
            data-testid="overlapping-components"
            position="relative"
            width="100%"
            height="100%"
        >
            {componentListByKey}
        </Box>
    )
}

export default memo(OverlappingComponentsList)
