import { Box, Button, Fade } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath, Link as RouterLink, useLocation } from 'react-router-dom'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../routes'
import { JoinGroupButton } from '..'

export type SocialGroupControlsProps = {
    hasJoined: boolean
    socialGroup: SocialGroup
    displayName: string
    socialGroupRefetch: () => Promise<any>
    displayNameRefetch: () => Promise<any>
}

const messages = defineMessages({
    joinGroup: {
        defaultMessage: 'Join Group',
        description: 'join group button text'
    },
    settings: {
        defaultMessage: 'Settings',
        description:
            'tab button text that opens up the available settings a user has in the selected social group'
    }
})

const SocialGroupControls: React.FC<SocialGroupControlsProps> = ({
    hasJoined,
    socialGroup,
    displayName,
    socialGroupRefetch,
    displayNameRefetch
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const location = useLocation()

    return (
        <Box
            data-testid="social-group-controls"
            position="relative"
            height="100%"
        >
            <Box sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                <Fade in={hasJoined} mountOnEnter unmountOnExit>
                    <Box>
                        <Button
                            variant="text"
                            data-testid="social-group-settings-button"
                            aria-label={intl.formatMessage(messages.settings)}
                            component={RouterLink}
                            to={{
                                pathname: generatePath(
                                    ROUTES.SINGLE_SOCIAL_GROUP_SETTINGS,
                                    {
                                        socialGroupId: socialGroup.id
                                    }
                                ),
                                state: {
                                    previousLocation: location.pathname
                                }
                            }}
                            startIcon={
                                <LeafIcon icon={'gear'} fontSize={'small'} />
                            }
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {intl.formatMessage(messages.settings)}
                            </CoreTypography>
                        </Button>
                    </Box>
                </Fade>
            </Box>
            <Box sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                <Fade in={!hasJoined} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            height: theme.spacing(7),
                            alignItems: 'center'
                        }}
                    >
                        <JoinGroupButton
                            socialGroups={[socialGroup]}
                            displayName={displayName}
                            displayNameRefetch={displayNameRefetch}
                            onJoinGroup={socialGroupRefetch}
                            text={intl.formatMessage(messages.joinGroup)}
                        />
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default React.memo(SocialGroupControls)
