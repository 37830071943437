import { CardContent, CardMedia, Skeleton, Stack } from '@mui/material'
import { LeafFixedMediaCard } from '@thriveglobal/thrive-web-leafkit'

const ContentCardSkeleton: React.FC = () => {
    return (
        <LeafFixedMediaCard
            alignMedia="left"
            mediaWidth="md"
            media={
                <CardMedia>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                    />
                </CardMedia>
            }
        >
            <CardContent>
                <Stack gap={1}>
                    <Stack textAlign="left">
                        <Skeleton variant="text" width="50%" height={24} />
                    </Stack>
                    <Stack textAlign="left">
                        <Skeleton variant="text" width="100%" height={18} />
                        <Skeleton variant="text" width="80%" height={18} />
                    </Stack>
                </Stack>
            </CardContent>
        </LeafFixedMediaCard>
    )
}

export default ContentCardSkeleton
