import { createContext, MutableRefObject } from 'react'
import { CompanyChallengeTabOptionKeys } from '../../enums/companyChallengeTabOptionKeys'

export interface CompanyTabControllerValue {
    ref: MutableRefObject<any>
    tabIndex: number
    companyChallengeTabOptions: any[]
    activeTabKey: CompanyChallengeTabOptionKeys
    isTabActive: (key: CompanyChallengeTabOptionKeys) => boolean
    handleTabChange: (event: any, newTabindex: number) => void
}

export const defaultContextValue: CompanyTabControllerValue = {
    ref: {} as MutableRefObject<any>,
    tabIndex: 0,
    companyChallengeTabOptions: [],
    activeTabKey: CompanyChallengeTabOptionKeys.Leaderboards,
    isTabActive: () => false,
    handleTabChange: () => ({})
}

export default createContext(defaultContextValue)
