import { fetchAndStoreGarden } from '@thriveglobal/thrive-web-core'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity,
    useCompleteChallengeMutation,
    SleepHabits
} from '../../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../../routes'
import { ChallengeType } from '../../../../../../shared/enums/challengeType'
import SleepHabitsChallengeEndDialog from '../SleepHabitsChallengeEndDialog'
import {
    isChallengeExpired,
    isChallengeCompleted
} from '../../../../../utils/challenge'
import { useChallengeTheme } from '../../../../../hooks/useChallengeTheme/useChallengeTheme'

interface ChallengeCompletionComponentsProps {
    challenge: UnifiedChallenge
    participation?: UnifiedChallengeActivity
    refetch: () => void
    sleepHabits?: SleepHabits
    loading: boolean
}

const ChallengeCompletionComponents: React.FC<
    ChallengeCompletionComponentsProps
> = ({ challenge, participation, refetch, sleepHabits, loading }) => {
    const history = useHistory()
    const [sleepHabitOpened, setSleepHabitOpened] = useState(true)
    const [completeChallengeMutation, { loading: completeChallengeLoading }] =
        useCompleteChallengeMutation()

    const challengeExpired = isChallengeExpired(challenge)
    const challengeCompleted = !!participation?.completedOn
    const { sleepEnabled } = useChallengeTheme(challenge)

    // If they challenge is ready to be completed we automatically complete
    // Company challenges will remain on the challenge home page for a few days following this
    useEffect(() => {
        if (
            challengeExpired &&
            !challengeCompleted &&
            !completeChallengeLoading &&
            participation?.id
        ) {
            completeChallengeMutation({
                variables: {
                    userChallengeActivityId: participation?.id
                }
            }).finally(() => {
                if (challenge?.challenge_type === ChallengeType.group) {
                    history.push(
                        generatePath(ROUTES.PAST_GROUP_CHALLENGE_HOME, {
                            challengeId: challenge?.id
                        })
                    )
                } else {
                    refetch()
                    fetchAndStoreGarden()
                }
            })
        }
    }, [
        refetch,
        history,
        challenge,
        participation,
        challengeExpired,
        challengeCompleted,
        completeChallengeLoading,
        completeChallengeMutation
    ])

    const canShowSleepHabit = useMemo(
        () =>
            sleepEnabled &&
            !sleepHabits?.endAnswer &&
            challengeCompleted &&
            !loading,
        [sleepEnabled, sleepHabits, challengeCompleted, loading]
    )

    return (
        <>
            {canShowSleepHabit && (
                <SleepHabitsChallengeEndDialog
                    open={sleepHabitOpened}
                    onClose={() => setSleepHabitOpened(false)}
                    challenge={challenge}
                />
            )}
        </>
    )
}

export default memo(ChallengeCompletionComponents)
