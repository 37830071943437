import { IconName } from '@fortawesome/fontawesome-common-types'
import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    veryBad: {
        defaultMessage: 'Feeling very bad',
        description: 'feeling very bad or poorly'
    },
    bad: {
        defaultMessage: 'Feeling bad',
        description: 'feeling bad or poorly'
    },
    neutral: {
        defaultMessage: 'Feeling neutral',
        description: 'feeling neutral'
    },
    good: {
        defaultMessage: 'Feeling Good',
        description: 'feeling good'
    },
    great: {
        defaultMessage: 'Feeling great',
        description: 'feeling great'
    }
})

export interface FeelingIcon {
    icon: IconName
    label: string
}

export const useFeelingIcon = () => {
    const { formatMessage } = useIntl()

    return useCallback<(value?: number) => FeelingIcon>(
        (value) => {
            switch (value) {
                case 1:
                    return {
                        icon: 'face-weary',
                        label: formatMessage(messages.veryBad)
                    }
                case 2:
                    return {
                        icon: 'face-frown',
                        label: formatMessage(messages.bad)
                    }
                case 3:
                    return {
                        icon: 'face-meh',
                        label: formatMessage(messages.neutral)
                    }
                case 4:
                    return {
                        icon: 'face-smile',
                        label: formatMessage(messages.good)
                    }
                case 5:
                    return {
                        icon: 'face-laugh-beam',
                        label: formatMessage(messages.great)
                    }
                default:
                    return {
                        icon: 'face-meh',
                        label: formatMessage(messages.neutral)
                    }
            }
        },
        [formatMessage]
    )
}

export default useFeelingIcon
