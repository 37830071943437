// External imports
import { Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'

// Internal imports
import { ROUTES } from '../../../../../routes'

interface ExitDialogProps {
    open: boolean
    onClose: () => void
}

const ExitDialog = ({ open, onClose }: ExitDialogProps) => {
    return (
        <>
            <LeafDialog
                open={open}
                onClose={onClose}
                maxWidth="xs"
                dialogTitle={
                    <FormattedMessage
                        defaultMessage="Are you sure you want to exit?"
                        description="exit dialog title"
                    />
                }
            >
                <DialogTitle>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            defaultMessage="Your current Challenge sign-up progress will be lost, and you’ll be redirected to the Challenges home page."
                            description="exit dialog description"
                        />
                    </CoreTypography>
                </DialogTitle>
                <DialogActions>
                    <Box display="flex" gap={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={onClose}
                        >
                            <CoreTypography customVariant="buttonLarge">
                                <FormattedMessage
                                    defaultMessage="Cancel"
                                    description="exit dialog cancel button"
                                />
                            </CoreTypography>
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            href={generatePath(ROUTES.HOME)}
                        >
                            <CoreTypography customVariant="buttonLarge">
                                <FormattedMessage
                                    defaultMessage="Exit"
                                    description="exit dialog exit button"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                </DialogActions>
            </LeafDialog>
        </>
    )
}

export default ExitDialog
