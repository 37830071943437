import { Box, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useUpdateDisplayNameMutation } from '../../../../../graphql/generated/autogenerated'
import SuccessLoadingButton from '../../../elements/SuccessLoadingButton'
import DisplayNameInput from '../../../elements/DisplayNameInput'

type DisplayNameInputProps = {
    displayName?: string | null
    refetchSocialGroups: () => Promise<void>
}

const EditDisplayName = ({
    displayName,
    refetchSocialGroups
}: DisplayNameInputProps) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))

    const [userDisplayName, setUserDisplayName] = useState(displayName ?? '')
    const [isValid, setIsValid] = useState(false)

    const [updateDisplayName] = useUpdateDisplayNameMutation({
        variables: {
            displayName: String(userDisplayName)
        }
    })

    const onSave = useCallback(async () => {
        if (!isValid) {
            return false
        }

        await updateDisplayName()
        await refetchSocialGroups()

        return true
    }, [updateDisplayName, refetchSocialGroups, isValid])

    const loadingButton = useMemo(
        () => (
            <Box>
                <SuccessLoadingButton
                    promise={onSave}
                    disabled={displayName === userDisplayName && isValid}
                    fixWidth={true}
                    variant="outlined"
                    data-testid="save-button"
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Save"
                            description="Save Button Text"
                        />
                    </CoreTypography>
                </SuccessLoadingButton>
            </Box>
        ),
        [onSave, userDisplayName, displayName]
    )

    return (
        <Stack gap={1}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Display name"
                    description="Edit Display name title"
                />
            </CoreTypography>
            <CoreTypography variant="body1">
                <FormattedMessage
                    defaultMessage="This display name will be visible to everyone taking part in the Challenge."
                    description="Edit Display name information"
                />
            </CoreTypography>
            <Stack maxWidth="100%" gap={1}>
                <Stack
                    direction="row"
                    gap={2}
                    width={isMobile ? '100%' : 443}
                    sx={{ mt: 2 }}
                >
                    <DisplayNameInput
                        value={userDisplayName}
                        onChange={setUserDisplayName}
                        onValidate={setIsValid}
                    />
                    {!isMobile && loadingButton}
                </Stack>
                {isMobile && loadingButton}
            </Stack>
        </Stack>
    )
}

export default memo(EditDisplayName)
