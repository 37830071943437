import withHydrationProvider from '../../../../../hooks/hydrationProviders/withHydrationProvider/withHydrationProvider'
import withCompanyChallengeSignUpProvider from '../../../../../hooks/withChallengeCompanySignUp/withCompanyChallengeSignUpProvider'
import withInterstitialNavigationProvider from '../../../../../hooks/withInterstitialNavigation/withInterstitialNavigationProvider'
import SignUpInterstitial from './SignUpInterstitial'

export const SignUpInterstitialWithProvider =
    withInterstitialNavigationProvider(
        withCompanyChallengeSignUpProvider(
            withHydrationProvider(SignUpInterstitial)
        )
    )

export default SignUpInterstitial
