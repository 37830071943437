import { Card, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { RecapGoalMetadata } from '../../../../hooks/useGetRecapGoalMetadata/useGetRecapGoalMetadata'
import RecapGoal from './RecapGoal'

type ChallengeYesterdayRecapProps = {
    recapGoalMetadata: RecapGoalMetadata[]
}

const ChallengeYesterdaysRecap: React.FC<ChallengeYesterdayRecapProps> = ({
    recapGoalMetadata
}) => {
    const { palette } = useTheme()

    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Stack bgcolor={palette.grey[100]} p={2} gap={2} height="100%">
                <CoreTypography
                    variant="overline"
                    color="primary"
                    component="p"
                    fontSize={12}
                >
                    <FormattedMessage
                        defaultMessage="Yesterday's Recap"
                        description="title for yesterday's recap section"
                    />
                </CoreTypography>
                <Stack gap={1.4375}>
                    <CoreTypography variant="body1" component="p" fontSize={18}>
                        <FormattedMessage
                            defaultMessage="Here's what you committed to yesterday:"
                            description="text for yesterday's recap section"
                        />
                    </CoreTypography>
                    {recapGoalMetadata?.map(
                        (metadata: RecapGoalMetadata, index: number) => {
                            const { text, ctaText, ctaClick } = metadata
                            return (
                                <RecapGoal
                                    key={index}
                                    text={text}
                                    ctaText={ctaText}
                                    ctaClick={ctaClick}
                                />
                            )
                        }
                    )}
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeYesterdaysRecap)
