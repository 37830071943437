import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

export type GoalTitleProps = {
    title: string | JSX.Element
}

const GoalTitle: React.FC<GoalTitleProps> = ({ title }) => {
    return <CoreTypography customVariant="form">{title}</CoreTypography>
}

export default GoalTitle
