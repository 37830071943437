import { Box, Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
export interface HowItWorksProps {
    challenge?: UnifiedChallenge
    isVertical?: boolean
}

type PromoInfo = {
    description: string
    header: string
    image: string
}

const HowItWorks: React.FC<HowItWorksProps> = ({ challenge, isVertical }) => {
    const promoInfo = useMemo<PromoInfo[]>(() => {
        const promoInfo = []

        if (
            challenge?.promoDescription1 ||
            challenge?.promoHeader1 ||
            challenge?.promoImage1
        ) {
            promoInfo.push({
                description: challenge?.promoDescription1,
                header: challenge?.promoHeader1,
                image: challenge?.promoImage1
            })
        }

        if (
            challenge?.promoDescription2 ||
            challenge?.promoHeader2 ||
            challenge?.promoImage2
        ) {
            promoInfo.push({
                description: challenge?.promoDescription2,
                header: challenge?.promoHeader2,
                image: challenge?.promoImage2
            })
        }

        if (
            challenge?.promoDescription3 ||
            challenge?.promoHeader3 ||
            challenge?.promoImage3
        ) {
            promoInfo.push({
                description: challenge?.promoDescription3,
                header: challenge?.promoHeader3,
                image: challenge?.promoImage3
            })
        }

        return promoInfo as PromoInfo[]
    }, [challenge])

    return (
        <Grid container spacing={isVertical ? 1 : 4} pt={2}>
            {promoInfo?.map((promoInfo, index: number) => (
                <Grid item key={index} xs={12} sm={isVertical ? 12 : 4}>
                    <Stack gap={2} direction={isVertical ? 'row' : 'column'}>
                        {promoInfo.image && (
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                height={112}
                                width={'100%'}
                            >
                                <Box
                                    maxHeight="100%"
                                    component="img"
                                    alt=""
                                    src={promoInfo.image}
                                    sx={{ objectFit: 'contain' }}
                                />
                            </Stack>
                        )}
                        <Stack
                            gap={1}
                            justifyContent={
                                isVertical ? 'center' : 'flex-start'
                            }
                        >
                            {promoInfo.header && (
                                <CoreTypography
                                    variant={isVertical ? 'h3' : 'h5'}
                                >
                                    {promoInfo.header}
                                </CoreTypography>
                            )}
                            {promoInfo.description && (
                                <CoreTypography variant="body1">
                                    {promoInfo.description}
                                </CoreTypography>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    )
}

export default memo(HowItWorks)
