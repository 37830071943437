import { Stack } from '@mui/material'
import { BarDatum } from '@nivo/bar'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import addMinutes from 'date-fns/addMinutes'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    CommunityGardenInfo,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import { useGoalChallengeDataProviderContext } from '../../../../contexts/withGoalChallenges/useGoalChallengeData'
import { useCompanyChallengeProviderContext } from '../../../../contexts/withCompanyChallengeProvider'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import BarChart from '../../../elements/Charts/BarChart'
import BarYAxis from '../../../elements/Charts/BarYAxis'
import useZonedDate from '../../../../hooks/useZonedDateTime/useZonedDateTime'

const messages = defineMessages({
    today: {
        defaultMessage: 'Today',
        description: 'today label for the bar chart'
    }
})

export type TotalPlantsBarChartProps = {
    challenge: UnifiedChallenge
    groupedPlants: CommunityGardenInfo[]
}

const TotalPlantsBarChart: React.FC<TotalPlantsBarChartProps> = ({
    groupedPlants,
    challenge
}) => {
    const { palette } = useTheme()
    const { formatDate, formatMessage, formatNumber } = useIntl()
    const scrollContainerRef = useRef<HTMLDivElement>(ReactNullValue)
    const barChartContainerRef = useRef<HTMLDivElement>(ReactNullValue)
    const [numBarsToFit, setNumBarsToFit] = useState<number>()
    const { getZonedDate, getUTCDate } = useZonedDate()

    useEffect(() => {
        if (barChartContainerRef.current) {
            const containerWidth = barChartContainerRef.current.clientWidth
            const calculatedNumBars = Math.floor(containerWidth / 60)
            setNumBarsToFit(calculatedNumBars)
        }
        setTimeout(() => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft =
                    scrollContainerRef.current.scrollWidth
            }
        }, 0)
    }, [])

    const data = useMemo<BarDatum[]>(() => {
        const startOfToday = getUTCDate(startOfDay(getZonedDate()))
        const challengeStartDate = getZonedDate(
            parseISO(challenge?.companyChallengeStartDay)
        )

        let currentDate = getUTCDate(startOfDay(challengeStartDate))
        // Assure the start date is the same and not the previous
        currentDate.setUTCDate(challengeStartDate.getUTCDate())

        const endDate = addMinutes(
            currentDate,
            (challenge?.duration ?? 1) * 24 * 60
        )

        const dateMap = new Map<string, BarDatum>()
        while (currentDate <= endDate) {
            dateMap.set(currentDate.toISOString(), {
                date:
                    currentDate.getTime() === startOfToday.getTime()
                        ? formatMessage(messages.today)
                        : formatDate(currentDate, {
                              month: 'short',
                              day: '2-digit'
                          }).replace(/([^\s\d]+)\s/, '$1.'),
                value: formatNumber(0) // Initialize all values to 0
            })
            currentDate = addMinutes(currentDate, 24 * 60) // Move to the next day

            if (currentDate > startOfToday) {
                break // Stop generating dates after today
            }
        }

        // Update values from groupedCommunityPlants
        groupedPlants?.forEach((groupedPlant) => {
            const groupedPlantDate = new Date(groupedPlant.date)

            // Only update values for dates up to today
            const isoString = groupedPlantDate.toISOString()
            const existingEntry = dateMap.get(isoString)
            if (existingEntry) {
                existingEntry.value = formatNumber(groupedPlant.plantsNumber)
            }
        })

        // Convert the map to an array of values
        return Array.from(dateMap.values())
    }, [
        getUTCDate,
        getZonedDate,
        challenge?.companyChallengeStartDay,
        challenge?.duration,
        groupedPlants,
        formatMessage,
        formatDate,
        formatNumber
    ])
    // Calculate the dynamic width based on the number of data points and bar width
    const barChartWidth =
        numBarsToFit !== ReactNullValue && data.length > Number(numBarsToFit)
            ? data.length * 60
            : '100%'

    return (
        <Stack
            height={250}
            width="100%"
            overflow={
                numBarsToFit !== ReactNullValue &&
                data.length > Number(numBarsToFit)
                    ? 'auto hidden'
                    : 'hidden'
            }
            direction="row"
            position="relative"
            ref={scrollContainerRef}
        >
            <Stack
                bgcolor={palette.common.white}
                boxShadow={`20px 1px 20px ${palette.common.white}`}
                position="sticky"
                minWidth={40}
                pb={5.375}
                zIndex={1}
                bottom={0}
                left={0}
            >
                <BarYAxis data={data} />
            </Stack>
            <Stack
                ref={barChartContainerRef}
                width={barChartWidth}
                height={250}
                flexShrink={0}
            >
                <BarChart data={data} />
            </Stack>
        </Stack>
    )
}

export default memo(TotalPlantsBarChart)
