import { Card, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

const SocialMemberSkeleton: React.FC = () => {
    const theme = useTheme()
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
        >
            <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ minWidth: 40, minHeight: 40 }}
            />
            <Skeleton variant="text" width="66%" height={40} />
        </Stack>
    )
}

export default memo(SocialMemberSkeleton)
