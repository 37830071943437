import { TextField } from '@mui/material'
import React, { memo } from 'react'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export type SearchFieldProps = {
    setSearchTerm: (searchTerm: string) => void
    placeholder: string
    ariaLabel: string
}

const SearchField: React.FC<SearchFieldProps> = ({
    setSearchTerm,
    placeholder,
    ariaLabel
}) => {
    return (
        <TextField
            placeholder={placeholder}
            size="small"
            fullWidth
            InputProps={{
                startAdornment: (
                    <LeafIcon
                        icon={'magnifying-glass'}
                        fontSize="small"
                        sx={{ mr: 1 }}
                    />
                )
            }}
            onChange={(event) => setSearchTerm(event.target.value)}
            aria-label={ariaLabel}
            role="search"
            sx={{
                '.MuiInputBase-root': {
                    height: '100%'
                }
            }}
        />
    )
}

export default memo(SearchField)
