import { defineMessages } from 'react-intl'

export const errorMessages = defineMessages({
    uploadSize: {
        defaultMessage: 'Max file size: 5mb',
        description: 'User image max size error message'
    },
    general: {
        defaultMessage: 'Something went wrong',
        description: 'general error message'
    }
})
