import { format } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import { useCompanyChallengeProviderContext } from '../withCompanyChallengeProvider/useCompanyChallengeProviderContext'
import { useGoalChallengeDataProviderContext } from '../withGoalChallenges/useGoalChallengeData'
import useGoalCompletedLocalState from './useGoalsCompletedLocalState'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export const useGoalsCompletedDialogState = () => {
    const { goals } = useGoalChallengeDataProviderContext()
    const { challengeStarted, challenge, challengeExpired } =
        useCompanyChallengeProviderContext()
    const { loadGoalsCompleted, saveGoalsCompleted } =
        useGoalCompletedLocalState()

    const { hasCompletedGoals, allGoalsCompleted, goalsCompletedCount } =
        useMemo(() => {
            const goalsCompletedCount = goals?.filter(
                (goal) => goal.completed
            )?.length
            return {
                hasCompletedGoals: goalsCompletedCount > 0,
                allGoalsCompleted: goalsCompletedCount === goals?.length,
                goalsCompletedCount
            }
        }, [goals])

    const saveGoalsCompletedState = useCallback(
        () =>
            saveGoalsCompleted(
                goalsCompletedCount,
                format(new Date()),
                challenge?.id
            ),
        [goalsCompletedCount, challenge, saveGoalsCompleted]
    )

    const canShowDialog = useMemo(() => {
        const { goalsCompletedCount: savedCount } = loadGoalsCompleted(
            challenge?.id
        )

        if (
            hasCompletedGoals &&
            goalsCompletedCount > savedCount &&
            !challengeExpired &&
            challengeStarted
        ) {
            // if the current goal count is greater than 1 and there is nothing saved we just assume this is a different device or the user re-logged
            // We don't show anything and save the state
            if (savedCount === 0 && goalsCompletedCount > 1) {
                saveGoalsCompletedState()

                return false
            }

            if (allGoalsCompleted) {
                Avo.challengeGoalAllCompleted({
                    activityType: 'daily_goals_completed',
                    challengeId: challenge?.id,
                    challengeTheme: challenge?.theme,
                    challengeType: challenge?.challenge_type,
                    dayNumber: null,
                    featureType: 'challenge',
                    teamId: null,
                    teamType: null
                })
            }

            return true
        }

        return false
    }, [
        challenge,
        hasCompletedGoals,
        goalsCompletedCount,
        allGoalsCompleted,
        challengeExpired,
        challengeStarted,
        loadGoalsCompleted,
        saveGoalsCompletedState
    ])

    return { canShowDialog, saveGoalsCompletedState }
}

export default useGoalsCompletedDialogState
