import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import DualToggle from '../../../../elements/DualToggle'
import { LeaderboardType } from '.'

export type LeaderboardToggleProps = {
    leaderboardType: LeaderboardType
    setLeaderboardType: (leaderboardType: LeaderboardType) => void
}

const LeaderboardToggle: React.FC<LeaderboardToggleProps> = ({
    leaderboardType,
    setLeaderboardType
}) => (
    <DualToggle
        activeIndex={Number(leaderboardType)}
        firstElement={
            <CoreTypography variant="body1" color="text.secondary">
                <FormattedMessage
                    defaultMessage="Plants"
                    description="description for plants"
                />
            </CoreTypography>
        }
        secondElement={
            <CoreTypography variant="body1" color="text.secondary">
                <FormattedMessage
                    defaultMessage="Activity"
                    description="description for activity"
                />
            </CoreTypography>
        }
        onSelect={(index) => setLeaderboardType(index as LeaderboardType)}
    />
)

export default memo(LeaderboardToggle)
