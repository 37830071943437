import { Link } from '@mui/material'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal
} from '../../../../../../../../graphql/generated/autogenerated'
import ConditionalSkeleton from '../../../../../../elements/ConditionalSkeleton'
import DisposableDialog from '../../../../../../elements/DisposableDialog'
import UpdateActivityGoalDialog from './UpdateActivityGoalDialog'

export interface UpdateActivityGoalProps {
    goal:
        | ChallengeUserDailyGoal
        | {
              __typename: undefined
              challengeGoal: ChallengeTemplateGoal
          }
    disabled?: boolean
    dailyGoalRefetch?: () => Promise<void>
}

const UpdateActivityGoal: React.FC<UpdateActivityGoalProps> = ({
    goal,
    disabled,
    dailyGoalRefetch
}) => {
    return (
        <ConditionalSkeleton showSkeleton={!goal}>
            <DisposableDialog
                button={
                    <Link
                        href={disabled ? undefined : 'javascript:void(0)'}
                        flexShrink={0}
                        sx={{
                            color: disabled ? 'text.disabled' : undefined
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Edit goal"
                            description="update goal button text"
                        />
                    </Link>
                }
                // TODO: Add edit goal dialog
                Dialog={({ open, onClose }) =>
                    goal.__typename === 'ChallengeUserDailyGoal' ? (
                        <UpdateActivityGoalDialog
                            userTodaySteps={0}
                            open={open}
                            onClose={onClose}
                            goal={goal}
                            dailyGoalRefetch={dailyGoalRefetch}
                        />
                    ) : null
                }
                disabled={disabled}
            />
        </ConditionalSkeleton>
    )
}

export default memo(UpdateActivityGoal)
