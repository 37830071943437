import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant10: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#21513A',
    colorLayer2 = '#8BD07D',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M63.32 66.8438L69.53 66.0838C69.64 66.0038 69.76 65.9238 69.87 65.8338C73.79 62.9238 77.32 58.0538 80.26 52.9238L77.41 52.1938L81.19 51.2538C83.04 47.8538 84.6299 44.4238 85.8999 41.4438L83.55 40.7638L86.73 39.4438C88.41 35.3038 89.35 32.4238 89.35 32.4238L49.1 71.0038C49.1 71.0038 56.82 73.7038 66.74 67.9138L63.31 66.8638L63.32 66.8438Z"
                fill={colorLayer1}
            />
            <path
                d="M72.49 59.6938L70.67 59.8238C70.08 59.7538 69.4899 59.7238 68.8999 59.7038C65.2699 59.4538 61.88 60.5738 58.59 61.9138C61.05 58.9138 69.1 58.1238 72.5 59.6838L72.49 59.6938Z"
                fill={colorLayer2}
            />
            <path
                d="M80.66 48.2438C79.21 48.3238 77.8599 47.7238 76.3999 47.8738C74.9199 47.8538 73.69 48.6438 72.26 48.8038C73.58 46.7438 79.04 46.4838 80.66 48.2438Z"
                fill={colorLayer2}
            />
            <path
                d="M37.69 85.5938L36.98 85.3538C39.89 76.7838 48.82 70.7438 48.91 70.6838L89.1 32.1438L89.62 32.6838L49.37 71.2638C49.23 71.3638 40.52 77.2738 37.69 85.5938Z"
                fill={colorLayer1}
            />
            <path
                d="M26.38 64.6338L20.17 63.8738C20.06 63.7938 19.94 63.7138 19.83 63.6238C15.91 60.7138 12.38 55.8438 9.43996 50.7138L12.29 49.9838L8.50996 49.0438C6.65996 45.6438 5.06997 42.2138 3.79997 39.2338L6.14998 38.5538L2.96996 37.2338C1.28996 33.0938 0.34996 30.2138 0.34996 30.2138L40.6 68.7938C40.6 68.7938 32.88 71.4938 22.96 65.7038L26.39 64.6538L26.38 64.6338Z"
                fill={colorLayer2}
            />
            <path
                d="M17.21 57.4738C20.6 55.9138 28.65 56.6938 31.12 59.7038C27.83 58.3638 24.43 57.2438 20.81 57.4938C20.22 57.5138 19.62 57.5438 19.04 57.6138L17.22 57.4838L17.21 57.4738Z"
                fill={colorLayer1}
            />
            <path
                d="M9.03996 46.0238C10.66 44.2638 16.12 44.5238 17.44 46.5838C16.01 46.4238 14.77 45.6338 13.3 45.6538C11.84 45.5038 10.49 46.1038 9.03996 46.0238Z"
                fill={colorLayer1}
            />
            <path
                d="M33.21 11.2238V16.2538L35.04 13.5238C37.58 16.8238 40.06 20.4838 41.91 24.1838L37.6 32.1438L43.53 27.9138C43.94 29.0338 44.28 30.1538 44.53 31.2638C47.67 45.3538 41.66 53.4238 41.66 53.4238L23.24 0.543823C23.24 0.543823 28.09 4.98383 33.21 11.2238Z"
                fill={colorLayer1}
            />
            <path
                d="M39.25 47.6138C37.84 40.7438 42.07 33.2438 42.25 32.9238L42.9 33.2938C42.86 33.3638 38.63 40.8738 39.99 47.4538L39.26 47.6038L39.25 47.6138Z"
                fill={colorLayer1}
            />
            <path
                d="M32.99 29.6238C32.18 24.6438 35.39 21.0138 35.52 20.8638L36.08 21.3638C36.08 21.3638 32.98 24.8738 33.73 29.4938L32.99 29.6138V29.6238Z"
                fill={colorLayer1}
            />
            <path
                d="M31.2 42.8938L26.18 39.1938C26.12 39.0638 26.05 38.9438 25.99 38.8138C23.84 34.3938 22.85 28.4738 22.47 22.6238L25.23 23.3938L22.37 20.7338C22.2 16.9138 22.28 13.1838 22.44 9.99382L24.75 10.5638L22.57 7.86383C22.86 3.48383 23.26 0.543823 23.26 0.543823L41.68 53.4238C41.68 53.4238 33.91 51.9638 27.82 42.1238L31.21 42.9038L31.2 42.8938Z"
                fill={colorLayer2}
            />
            <path
                d="M26.31 32.2538C28.78 32.4338 30.86 33.9938 32.84 35.3438C34.72 36.8338 36.79 38.5438 37.33 40.9838C35.03 38.2738 32.68 35.6438 29.41 34.0538C28.89 33.7738 28.36 33.5138 27.82 33.2738L26.31 32.2538Z"
                fill={colorLayer1}
            />
            <path
                d="M24.09 18.4038C26.31 17.6838 30.9 20.6538 31.08 23.0038C29.89 22.2038 29.2 20.9138 27.93 20.2038C26.76 19.3438 25.3 19.2038 24.1 18.4038H24.09Z"
                fill={colorLayer1}
            />
            <path
                d="M48.81 76.5838C49.04 67.6738 42.3 53.8738 42.02 53.2938L23.6 0.413818L22.89 0.663818L41.33 53.5838C41.4 53.7238 47.2 65.5938 47.98 74.3138C48.05 75.1138 47.03 75.5038 46.54 74.8738C43.62 71.1338 40.89 68.5538 40.85 68.5038L0.59996 29.9238L0.0799713 30.4638L40.33 69.0438C40.42 69.1238 49.09 77.2938 51.93 85.6638L52.64 85.4238C51.89 83.2138 50.74 81.0238 49.45 78.9838C49 78.2638 48.79 77.4338 48.81 76.5838Z"
                fill={colorLayer1}
            />
            <path
                d="M78.69 33.3438C74.19 41.3038 66.49 45.9138 66.49 45.9138C66.49 45.9138 66.09 37.6138 70.59 29.6438C75.09 21.6838 82.79 17.0738 82.79 17.0738C82.79 17.0738 83.19 25.3738 78.69 33.3438Z"
                fill={colorLayer2}
            />
            <path
                d="M78.69 33.3438C83.19 25.3838 82.79 17.0738 82.79 17.0738L66.49 45.9038C66.49 45.9038 74.19 41.3038 78.69 33.3338V33.3438Z"
                fill={colorLayer1}
            />
            <path
                d="M50.4 60.9838L50.28 60.2438C60.17 58.6738 66.36 45.8838 66.42 45.7538L82.4599 16.8838L83.12 17.2438L67.09 46.0938C66.84 46.6138 60.68 59.3538 50.4 60.9738V60.9838Z"
                fill={colorLayer1}
            />
            <path
                d="M51.42 18.2138V24.2038L49.24 20.9538C46.21 24.8738 43.27 29.2338 41.07 33.6438L46.1899 43.1138L39.1299 38.0838C38.6399 39.4238 38.2399 40.7538 37.9399 42.0638C34.1999 58.8338 41.36 68.4438 41.36 68.4438L63.2799 5.51382C63.2799 5.51382 57.51 10.7938 51.42 18.2238V18.2138Z"
                fill={colorLayer2}
            />
            <path
                d="M40.28 44.2738C42.28 46.5638 43.02 49.6338 43.81 52.5038C44.41 55.4138 45.01 58.6138 43.79 61.4438C43.7 58.5038 43.84 55.5638 43.07 52.6838C42.46 49.7738 41.06 47.1338 40.27 44.2838L40.28 44.2738Z"
                fill={colorLayer1}
            />
            <path
                d="M48.34 29.9938C50.82 31.4338 52.66 37.5038 51.24 40.0438C50.86 38.3038 51.11 36.5238 50.49 34.8238C50.05 33.0738 48.9 31.6838 48.33 29.9938H48.34Z"
                fill={colorLayer1}
            />
            <path
                d="M53.82 55.9138L59.79 51.5038C59.87 51.3538 59.9399 51.2038 60.0199 51.0538C62.5799 45.7938 63.7599 38.7538 64.2099 31.7838L60.93 32.7038L64.3299 29.5338C64.5299 24.9838 64.44 20.5438 64.25 16.7538L61.51 17.4338L64.11 14.2238C63.77 9.01383 63.29 5.51382 63.29 5.51382L41.37 68.4438C41.37 68.4438 50.61 66.7038 57.87 54.9938L53.8299 55.9138H53.82Z"
                fill={colorLayer1}
            />
            <path
                d="M59.63 43.2438C57.26 44.7938 54.59 45.8138 52.35 47.5738C50 49.2138 48.36 51.5038 46.52 53.6338C47.67 49.1438 55.1699 43.8538 59.6399 43.2438H59.63Z"
                fill={colorLayer2}
            />
            <path
                d="M62.28 26.7638C60.79 27.6038 59.08 27.8438 57.68 28.8438C56.17 29.7038 55.3 31.1938 53.97 32.2438C54.39 29.4338 59.55 26.0838 62.28 26.7638Z"
                fill={colorLayer2}
            />
            <path
                d="M40.58 85.5938C39.29 75.2038 40.97 68.4238 40.99 68.3538L62.92 5.39383L63.63 5.64383L41.71 68.5738C41.71 68.5738 40.06 75.2638 41.33 85.5038L40.59 85.5938H40.58Z"
                fill={colorLayer1}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant10
