import { Box, Dialog, DialogContent, DialogTitle, Grow } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactNode } from 'react'
import { CloseModalIcon } from '../../shared/components/elements'

export type ActionModalProps = {
    isOpen: boolean
    handleOnClose: () => void
    title?: string
    body?: string
    size?: 'small' | 'medium'
    icon?: ReactNode | ReactNode[]
    actions?: ReactNode | ReactNode[]
}

const TransitionComponent = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Grow in ref={ref} {...props} />
})

const ActionModal: React.FC<ActionModalProps> = ({
    isOpen,
    handleOnClose,
    title,
    body,
    size,
    icon,
    actions
}) => {
    const theme = useTheme()

    return (
        <Dialog
            open={isOpen}
            onClose={handleOnClose}
            TransitionComponent={TransitionComponent}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.default,
                    maxWidth:
                        size === 'small'
                            ? theme.spacing(67.5)
                            : theme.spacing(79.5),
                    width: '100%',
                    padding: theme.spacing(4),
                    [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(1),
                        margin: theme.spacing(2)
                    },
                    overflow: 'hidden'
                }
            }}
            data-testid="ActionModalTestId"
        >
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    overflow: 'hidden'
                }}
            >
                <CloseModalIcon onClick={handleOnClose} />
                <Box>{icon}</Box>
                {title && (
                    <DialogTitle
                        sx={{
                            p: 0
                        }}
                    >
                        <CoreTypography
                            variant="h1"
                            sx={{
                                color: theme.palette.primary.main,
                                mb: theme.spacing(2),
                                ...(!icon && { mt: theme.spacing(4) }),
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '8.5vw',
                                    letterSpacing: '0.015rem',
                                    ...(!icon && { mt: theme.spacing(6) })
                                }
                            }}
                        >
                            {title}
                        </CoreTypography>
                    </DialogTitle>
                )}
            </Box>
            <DialogContent
                sx={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    overflow: 'hidden'
                }}
            >
                {body && (
                    <CoreTypography
                        variant="body1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(4),
                            whiteSpace: 'pre-line'
                        }}
                    >
                        {body}
                    </CoreTypography>
                )}
                {actions}
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(ActionModal)
