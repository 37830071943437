import React, { memo, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LeaderboardTable from '../../../../elements/LeaderboardTable'
import {
    useGetTeamsLeaderboardQuery,
    useGetTeamsLeaderboardByAverageQuery
} from '../../../../../../graphql/generated/autogenerated'
import { GQLNullValue } from '../../../../../utils/nulls'
import RequestorRank from '../../../../elements/RequestorRank'

type TeamActivityLeaderboardProps = {
    challengeId: string
    socialGroupId: string
    maxTeamSize?: number | null
    deviceEnabled: boolean
    teamActivityRank?: number
    teamActivityRankTotalPoints?: number
    teamActivityRankDisplayName?: string
}

const messages = defineMessages({
    rank: {
        defaultMessage: 'Rank',
        description: 'Table header for rank'
    },
    team: {
        defaultMessage: 'Team Name',
        description: 'Table header for team'
    },
    totalActivityPoints: {
        defaultMessage: 'Total',
        description: 'Table header for total activity points'
    },
    avgActivityPoints: {
        defaultMessage: 'Average per Person',
        description: 'Table header for average activity points'
    },
    teamActivity: {
        defaultMessage: 'Team Activity',
        description: 'Leaderboard title for Activity'
    }
})

const ROWS_PER_PAGE = 10

const TeamActivityLeaderboard: React.FC<TeamActivityLeaderboardProps> = ({
    challengeId,
    socialGroupId,
    maxTeamSize,
    deviceEnabled,
    teamActivityRank,
    teamActivityRankTotalPoints,
    teamActivityRankDisplayName
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const [offset, setOffset] = useState(0)

    const showAvgLeaderboard = useMemo(
        () => maxTeamSize === GQLNullValue || maxTeamSize === undefined,
        [maxTeamSize]
    )

    const {
        data: teamLeaderboardByAvgData,
        loading: teamLeaderboardByAvgDataLoading,
        refetch: refetchAvg
    } = useGetTeamsLeaderboardByAverageQuery({
        variables: {
            challengeId,
            offset,
            limit: ROWS_PER_PAGE
        },
        skip: !showAvgLeaderboard || !deviceEnabled
    })
    const {
        data: teamLeaderboardData,
        loading: teamLeaderboardDataLoading,
        refetch: refetchData
    } = useGetTeamsLeaderboardQuery({
        variables: {
            challengeId,
            offset,
            limit: ROWS_PER_PAGE
        },
        skip: showAvgLeaderboard || !deviceEnabled
    })
    const scores = useMemo(() => {
        if (showAvgLeaderboard) {
            return teamLeaderboardByAvgData?.unifiedChallenges
                ?.getTeamsLeaderboardByAverage?.rankedList
        }
        return teamLeaderboardData?.unifiedChallenges?.getTeamsLeaderboard
            ?.rankedList
    }, [showAvgLeaderboard, teamLeaderboardByAvgData, teamLeaderboardData])

    const totalEntries = useMemo(() => {
        if (showAvgLeaderboard) {
            return teamLeaderboardByAvgData?.unifiedChallenges
                ?.getTeamsLeaderboardByAverage?.totalEntries
        }
        return teamLeaderboardData?.unifiedChallenges?.getTeamsLeaderboard
            ?.totalEntries
    }, [showAvgLeaderboard, teamLeaderboardByAvgData, teamLeaderboardData])

    const requesterRank = 2

    const anchorIndex = useMemo(
        () =>
            scores?.findIndex(
                (score) =>
                    score?.rank === requesterRank &&
                    score?.entityId === socialGroupId
            ),
        [requesterRank, scores, socialGroupId]
    )

    const maximumOffsetLoaded = useMemo(() => {
        return offset + ROWS_PER_PAGE
    }, [offset])

    const hasMore = useMemo(() => {
        return (totalEntries || 0) > maximumOffsetLoaded
    }, [totalEntries, maximumOffsetLoaded])

    useEffect(() => {
        if (showAvgLeaderboard) {
            refetchAvg({ offset })
        } else {
            refetchData({ offset })
        }
    }, [offset, refetchAvg, refetchData, showAvgLeaderboard])

    const showLoading = showAvgLeaderboard
        ? teamLeaderboardByAvgDataLoading
        : teamLeaderboardDataLoading

    return (
        <LeaderboardTable
            loading={showLoading}
            loadingNext={showLoading}
            userRank={
                <RequestorRank
                    columnDataTitle={formatMessage(messages.team)}
                    columnDataPointsTitle={
                        showAvgLeaderboard
                            ? formatMessage(messages.avgActivityPoints)
                            : formatMessage(messages.totalActivityPoints)
                    }
                    displayName={teamActivityRankDisplayName}
                    rank={teamActivityRank}
                    points={teamActivityRankTotalPoints}
                />
            }
            title={formatMessage(messages.teamActivity)}
            columnData={[
                {
                    title: formatMessage(messages.rank),
                    width: '20%',
                    valueName: 'rank',
                    showAnchor: (value, entityId) =>
                        value === requesterRank && entityId === socialGroupId
                },
                {
                    title: formatMessage(messages.team),
                    valueName: 'requesterDisplayName'
                },
                {
                    title: showAvgLeaderboard
                        ? formatMessage(messages.avgActivityPoints)
                        : formatMessage(messages.totalActivityPoints),
                    valueName: 'totalSteps',
                    transform: (value) => formatNumber(value)
                }
            ]}
            values={scores ?? []}
            requesterRank={requesterRank}
            totalEntries={totalEntries}
            anchorOnIndex={anchorIndex}
            rowsPerPage={ROWS_PER_PAGE}
            elevation={0}
            onNavigate={(value) => {
                setOffset(value ? offset + 1 : offset - 1)
            }}
            disableNext={!hasMore}
            disablePrev={offset === 0}
        />
    )
}

export default memo(TeamActivityLeaderboard)
