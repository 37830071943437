import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CommunityActivityDialog from './CommunityActivityDialog'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'
import type { ModifiedFormattedMessageType } from 'src/types'
import type {
    CommunityGardenInfo,
    SocialGroup,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'

const ModifiedFormattedMessage =
    FormattedMessage as ModifiedFormattedMessageType

type CommunityActivityProps = {
    challenge: UnifiedChallenge
    socialGroup: SocialGroup
    deviceEnabled: boolean
    data: CommunityGardenInfo[]
}

const CommunityActivity = ({
    challenge,
    socialGroup,
    deviceEnabled,
    data
}: CommunityActivityProps) => {
    const { formatNumber } = useIntl()
    const [openDialog, setOpenDialog] = useState(false)

    const totalPlants = useMemo(
        () =>
            data?.reduce((accumulator, groupedPlant) => {
                accumulator += groupedPlant.plantsNumber

                return accumulator
            }, 0),
        [data]
    )

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Community Activity"
                            description="Community activity title"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={2}>
                        <CoreTypography variant="body2" color="primary.main">
                            <FormattedMessage
                                defaultMessage="Check out the collective efforts of you and your coworkers!"
                                description="community activity description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={2}>
                        <ModifiedFormattedMessage
                            id="message.totalParticipants"
                            defaultMessage="<stat>{totalParticipants}</stat> <part>Participants</part>"
                            description="participants total"
                            values={{
                                totalParticipants: formatNumber(
                                    challenge?.totalParticipants ?? 0
                                ),
                                stat: (chunks: React.ReactNode[]) => (
                                    <CoreTypography
                                        variant="h2"
                                        textAlign="left"
                                        color="text.secondary"
                                    >
                                        {chunks}
                                    </CoreTypography>
                                ),
                                part: (chunks: React.ReactNode[]) => (
                                    <CoreTypography
                                        variant="body2"
                                        textAlign="left"
                                        color="primary.main"
                                    >
                                        {chunks}
                                    </CoreTypography>
                                )
                            }}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={2}>
                        <ModifiedFormattedMessage
                            id="message.totalPlants"
                            defaultMessage="<stat>{totalPlants}</stat> <part>Plants earned</part>"
                            description="community Plants earned"
                            values={{
                                totalPlants: formatNumber(totalPlants ?? 0),
                                stat: (chunks: React.ReactNode[]) => (
                                    <CoreTypography
                                        variant="h2"
                                        textAlign="left"
                                        color="text.secondary"
                                    >
                                        {chunks}
                                    </CoreTypography>
                                ),
                                part: (chunks: React.ReactNode[]) => (
                                    <CoreTypography
                                        variant="body2"
                                        textAlign="left"
                                        color="primary.main"
                                    >
                                        {chunks}
                                    </CoreTypography>
                                )
                            }}
                        />
                    </Stack>
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View Activity"
                            description="view activity"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <CommunityActivityDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                challenge={challenge}
                communityGardenInfo={data}
                socialGroup={socialGroup}
                deviceEnabled={deviceEnabled}
            />
        </>
    )
}

export default memo(CommunityActivity)
