import { Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ConditionalSkeleton from '../../../../elements/ConditionalSkeleton'

export type DailyGoalCardStatus = {
    loading?: boolean
    completed?: boolean
}

const DailyGoalCardStatus: React.FC<DailyGoalCardStatus> = ({
    loading,
    completed
}) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            gap={2}
            alignItems="center"
        >
            <Stack direction="row" gap={1}>
                <ConditionalSkeleton showSkeleton={!!loading}>
                    <CoreTypography
                        variant="overline"
                        color="secondary.contrastText"
                    >
                        <FormattedMessage
                            defaultMessage="Daily goal"
                            description="daily goal card title"
                        />
                    </CoreTypography>
                </ConditionalSkeleton>
            </Stack>
            {completed && <LeafIcon icon="circle-check" color="accent" />}
        </Stack>
    )
}

export default memo(DailyGoalCardStatus)
