import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FormControl, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import ChallengeStartDateField from '../../../../../elements/ChallengeStartDateField'
import DisplayNameInput from '../../../../../elements/DisplayNameInput'

export interface DetailsProps {
    displayName: string | undefined
    setDisplayName: (value: string) => void
    startDate?: string | undefined
    setStartDate?: (value: string) => void
    onValidate: (valid: boolean) => void
    shouldDisplayStartDate?: boolean
}

const DetailsStep: React.FC<DetailsProps> = ({
    displayName,
    setDisplayName,
    startDate,
    setStartDate,
    onValidate,
    shouldDisplayStartDate
}) => {
    return (
        <Stack gap={2}>
            <CoreTypography variant="h5">
                <FormattedMessage
                    defaultMessage="Your display name for the Challenge"
                    description="title for the display name input field"
                />
            </CoreTypography>
            <CoreTypography variant="body1">
                <FormattedMessage
                    defaultMessage="This display name will be visible to anyone invited to join the Challenge."
                    description="description for the display name input field"
                />
            </CoreTypography>
            <Stack
                sx={{
                    width: '100%',
                    gap: 2
                }}
            >
                <FormControl
                    sx={{
                        width: '100%',
                        maxWidth: '320px'
                    }}
                >
                    <DisplayNameInput
                        value={displayName}
                        onChange={setDisplayName}
                        onValidate={onValidate}
                    />
                </FormControl>
                {shouldDisplayStartDate && (
                    <FormControl
                        sx={{
                            width: '100%',
                            maxWidth: '320px'
                        }}
                    >
                        <ChallengeStartDateField
                            startDate={startDate}
                            setStartDate={setStartDate}
                        />
                    </FormControl>
                )}
            </Stack>
        </Stack>
    )
}

export default DetailsStep
