import {
    WearablesNativeConnectButton,
    useGetUser
} from '@thriveglobal/thrive-web-wearables-core'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    connectADevice: {
        defaultMessage: 'Connect an app',
        description:
            'button text that will open up a new tab to allow a user to connect a step counting device'
    },
    manageYourDevice: {
        defaultMessage: 'Manage connected apps',
        description:
            'button text that will open a new tab to allow users to manage the devices they currently have connected'
    }
})

type ManageDeviceButtonProps = React.ComponentProps<
    typeof WearablesNativeConnectButton
> & {}

const ManageDeviceButton: React.FC<ManageDeviceButtonProps> = ({
    ...props
}) => {
    const { formatMessage } = useIntl()
    const { isReturningUser } = useGetUser()

    const { activityText } = useMemo(
        () => ({
            activityText: formatMessage(
                isReturningUser
                    ? messages.manageYourDevice
                    : messages.connectADevice
            )
        }),
        [formatMessage, isReturningUser]
    )

    return <WearablesNativeConnectButton buttonText={activityText} {...props} />
}

export default memo(ManageDeviceButton)
