import { useCompanyChallengeForm } from './useCompanyChallengeForm'
import { useCompanyChallengeSubmission } from './useCompanyChallengeSubmission'

const useCompanyChallengeSignup = () => {
    const { formData, updateFormData, setIsDisplayNameValid } =
        useCompanyChallengeForm()
    const { onSubmit, loading } = useCompanyChallengeSubmission(formData)

    return {
        formData,
        updateFormData,
        setIsDisplayNameValid,
        onSubmit,
        loading
    }
}

export default useCompanyChallengeSignup
