import { LeafConfirmationDialog } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

export type AreYouSureModalProps = {
    isOpen: boolean
    handleOnClose: (close: boolean) => void
}

const messages = defineMessages({
    areYouSure: {
        defaultMessage: 'Are You Sure?',
        description: 'title text for the are you sure modal'
    },
    areYouSureConfirm: {
        defaultMessage:
            'Are you sure you want to leave, all information will be lost',
        description:
            'question that is asked when a user tries to close the modal to make sure they meant their action'
    },
    confirm: {
        defaultMessage: 'Confirm',
        description: 'button text for confirming an an Are you sure modal'
    },
    close: {
        defaultMessage: 'Close',
        description: 'button text for declining an an Are you sure modal'
    }
})

const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
    isOpen,
    handleOnClose
}) => {
    const { formatMessage } = useIntl()

    return (
        <LeafConfirmationDialog
            open={isOpen}
            fullWidth
            dialogTitle={formatMessage(messages.areYouSure)}
            dialogContent={formatMessage(messages.areYouSureConfirm)}
            onClose={() => handleOnClose(false)}
            primaryButtonOnClick={() => handleOnClose(true)}
            primaryButtonText={formatMessage(messages.confirm)}
            secondaryButtonOnClick={() => handleOnClose(false)}
            secondaryButtonText={formatMessage(messages.close)}
        />
    )
}

export default AreYouSureModal
