/**
 * Custom hook for text transformations.
 * @returns {Object} An object containing text transformation functions.
 */
export const useTextTransform = () => {
    /**
     * Converts a given text to title case.
     * @param {string} text - The text to be transformed.
     * @returns {string} The transformed text in title case.
     */
    const toTitleCase = (text: string) => {
        return text
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    return { toTitleCase }
}

export default useTextTransform
