import { Box, Button, Stack } from '@mui/material'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { GroupChallengeType } from '../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    individualChallenge: {
        defaultMessage:
            "Confirm you'd like to make this challenge an individual Challenge.",
        description: 'description for individual challenge'
    },
    groupChallenge: {
        defaultMessage:
            "Confirm you'd like to make this challenge a group Challenge.",
        description: 'description for group challenge'
    }
})

export type ConfirmParticipationProps = {
    open: boolean
    changeToParticipation: GroupChallengeType
    onClose: () => void
    onConfirm: (participationType: GroupChallengeType) => void
}

const ConfirmParticipation: React.FC<ConfirmParticipationProps> = ({
    open,
    changeToParticipation,
    onClose,
    onConfirm
}) => {
    const { formatMessage } = useIntl()
    const onHandleConfirm = useCallback(() => {
        onConfirm(changeToParticipation)
        onClose()
    }, [onConfirm, onClose, changeToParticipation])

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Confirm Challenge Type"
                        description="title for confirmation challenge type"
                    />
                </CoreTypography>
            }
        >
            <Stack gap={2} pb={2}>
                <CoreTypography variant="body1">
                    {changeToParticipation === GroupChallengeType.Personal
                        ? formatMessage(messages.individualChallenge)
                        : formatMessage(messages.groupChallenge)}
                </CoreTypography>
                <Box>
                    <Button
                        onClick={onHandleConfirm}
                        variant="contained"
                        color="secondary"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Confirm"
                                description="button title for confirming challenge type"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </Stack>
        </LeafDialog>
    )
}

export default ConfirmParticipation
