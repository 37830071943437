import { Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { UserPlantInfo } from '../../../../graphql/generated/autogenerated'
import usePlantIcons from '../../../../hooks/usePlantIcons/usePlantIcons'

export type PlantIconSkeletonProps = {
    PlantComponent?: any
}

const PlantIconSkeleton: React.FC<PlantIconSkeletonProps> = ({
    PlantComponent
}) => {
    const { palette } = useTheme()
    const { getPlantIcon: getDefault } = usePlantIcons(true)
    const { base } = useMemo(() => {
        const getPlantIconFunc = getDefault
        const { base } = getPlantIconFunc({
            productUpc: 'plant.community.cherryblossom'
        } as UserPlantInfo)

        return {
            base
        }
    }, [getDefault])

    return (
        <Stack height={75} width={75}>
            {PlantComponent && (
                <PlantComponent
                    base={base}
                    colorLayer1={palette.grey[200]}
                    colorLayer2={palette.grey[300]}
                    colorLayer3={palette.grey[200]}
                    colorLayer4={palette.grey[300]}
                    colorLayer5={palette.grey[200]}
                    colorLayer6={palette.grey[300]}
                />
            )}
        </Stack>
    )
}

export default PlantIconSkeleton
