import { Slide } from '@mui/material'
import React from 'react'

const SlideUp = (props: any) => {
    const [direction, setDirection] = React.useState('up')

    return (
        <Slide
            {...props}
            direction={direction}
            onEntered={() => setDirection('down')}
            onExited={() => setDirection('up')}
            easing={'linear'}
            timeout={400}
            data-testid="slide-up"
        />
    )
}

export default SlideUp
