import { useCallback, useEffect, useRef, useState } from 'react'

export const defaultAnimationDelay = 200

function useTemporaryAnimationToken(animationDelay = defaultAnimationDelay) {
    const [animationToken, setAnimationToken] = useState<number | null>(null)
    const animationTimeoutRef = useRef<NodeJS.Timeout | number | null>(null)

    const updateAnimationToken = useCallback(() => {
        if (animationTimeoutRef?.current) {
            clearTimeout(animationTimeoutRef.current as NodeJS.Timeout)
            animationTimeoutRef.current = null
        }

        setAnimationToken(Date.now())
        animationTimeoutRef.current = setTimeout(() => {
            setAnimationToken(null)
        }, animationDelay)
    }, [animationDelay])

    useEffect(() => {
        return () => {
            setAnimationToken(null)
            if (animationTimeoutRef?.current) {
                clearTimeout(animationTimeoutRef.current as NodeJS.Timeout)
            }
        }
    }, [])

    return {
        animationToken,
        updateAnimationToken
    }
}

export default useTemporaryAnimationToken
