import { createContext } from 'react'

export interface InterstitialNavigationProviderValue {
    keys: string[]
    subKeySteps?: { [key: string]: { current: number; max: number } }
    activeKey?: string
    activeIndex: number
    activeKeySubIndex?: number
    next: () => void
    prev: () => void
    close: () => void
    navigateToKey: (key: string, subStep?: number) => void
    navigateToStep: (step: number) => void
    queueNavigateToKey?: (key: string) => void
    setClose: (close: () => void) => void
    setKeys: (keys: string[]) => void
    setSubKeySteps: (subKeyPages: { [key: string]: number }) => void
}

export const defaultContextValue: InterstitialNavigationProviderValue = {
    keys: [],
    subKeySteps: undefined,
    activeKey: undefined,
    activeIndex: 0,
    activeKeySubIndex: undefined,
    next: () => ({}),
    prev: () => ({}),
    close: () => ({}),
    navigateToKey: () => ({}),
    navigateToStep: () => ({}),
    queueNavigateToKey: () => ({}),
    setClose: () => ({}),
    setKeys: () => ({}),
    setSubKeySteps: () => ({})
}

export default createContext(defaultContextValue)
