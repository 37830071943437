import React, { memo, useMemo } from 'react'
import {
    ArticleV2Detail,
    ChallengeUserDailyGoal,
    GoalType,
    SocialGroup,
    UnifiedChallenge,
    useGetArticleV2Query
} from '../../../../../../../graphql/generated/autogenerated'
import ArticleGoal from '.'

export type ArticleGoalByQueryProps = {
    id: string
    type: GoalType
    challenge: UnifiedChallenge
    socialGroup: SocialGroup
}

const ArticleGoalByQuery: React.FC<ArticleGoalByQueryProps> = ({
    id,
    type,
    challenge,
    socialGroup
}) => {
    const { data, error, loading } = useGetArticleV2Query({
        variables: {
            id
        }
    })

    const goal = useMemo(() => {
        if (data && !loading) {
            const article = data?.learnV2?.getArticleV2

            return {
                challengeGoal: {
                    goalType: type as string
                },
                goalEntityDetail: {
                    articleInfo: {
                        ...article
                    } as ArticleV2Detail | unknown
                },
                completed: false
            }
        }
    }, [data, loading, type])

    return (
        <>
            {!error && (
                <ArticleGoal
                    goal={goal as ChallengeUserDailyGoal}
                    loading={loading}
                    disabled={true}
                    challenge={challenge}
                    socialGroup={socialGroup}
                />
            )}
        </>
    )
}

export default memo(ArticleGoalByQuery)
