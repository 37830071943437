// React and React-related
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

// Components
import CompletedDailyGoals from '../../Shared/DailyGoals/CompletedDailyGoals'
import DailyGoalsList from './components/DailyGoalsList'
import DailyGoalPreview from '../../Shared/DailyGoals/DailyGoalPreview'
import HiddenStatusUpdate from '../../../elements/HiddenStatusUpdate'

// External libraries
import { Stack } from '@mui/material'

// Types and GraphQL
import {
    type ChallengeUserDailyGoal,
    type UnifiedChallenge,
    useCommunityChallengeUserDailyGoalsQuery
} from '../../../../../graphql/generated/autogenerated'

// Utils
import { isChallengeStarted } from '../../../../utils/challenge'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'

// Hooks
import useGetSocialGroups from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import useGoalChallengeData from '../../../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'

const messages = defineMessages({
    yourDailyGoals: {
        defaultMessage: 'Your daily goals',
        description: 'title for the daily goals card'
    },
    dailyGoalsCompleted: {
        defaultMessage:
            'You have completed {dailyGoalsCompletedCount, plural, one {#  daily goal out of {dailyGoalsTotal} daily goals} other {# daily goals out of {dailyGoalsTotal} daily goals}}.',
        description: 'daily goals completed description'
    },
    dailyGoalCount: {
        defaultMessage:
            'Complete all {dailyGoalsTotal} to earn a plant for the day',
        description: 'description on what goals to complete'
    }
})

export type DailyGoalsContainerProps = {
    setResetPlaying: (resetPlaying: boolean) => void
    challenge: UnifiedChallenge
    loading: boolean
}

const DailyGoalsContainer: React.FC<DailyGoalsContainerProps> = ({
    setResetPlaying,
    challenge,
    loading: challengeLoading
}) => {
    const { formatMessage } = useIntl()
    const { socialGroup } = useGetSocialGroups(challenge?.id)

    const {
        userPlant,
        userTodaySteps,
        feelingAnswer,
        waterIntake,
        measureUnitSettings
    } = useGoalChallengeData({
        challenge,
        socialGroup,
        loading: challengeLoading
    })

    const { loading, data, error, refetch } =
        useCommunityChallengeUserDailyGoalsQuery({
            variables: { challengeId: challenge?.id },
            skip: !challenge?.id
        })

    const goals = data?.unifiedChallenges?.communityChallengeUserDailyGoals as
        | ChallengeUserDailyGoal[]
        | undefined
    const challengeStarted = isChallengeStarted(challenge)
    const isLoading = loading || challengeLoading

    const { dailyGoalsCompleted, dailyGoalsCompletedCount, dailyGoalsTotal } =
        useMemo(() => {
            const completedCount =
                goals?.filter((goal) => goal.completed)?.length ?? 0
            return {
                dailyGoalsCompleted:
                    goals &&
                    goals?.length > 0 &&
                    completedCount >= goals?.length,
                dailyGoalsCompletedCount: completedCount,
                dailyGoalsTotal: goals?.length
            }
        }, [goals])

    if (error || !challenge) return ReactNullValue

    return (
        <React.Fragment>
            <Stack gap={2}>
                {dailyGoalsCompleted && (
                    <CompletedDailyGoals userPlant={userPlant} />
                )}
                {challengeStarted ? (
                    <DailyGoalsList
                        setResetPlaying={setResetPlaying}
                        goals={goals ?? []}
                        challenge={challenge}
                        dailyGoalRefetch={async () => {
                            await refetch()
                        }}
                        userTodaySteps={userTodaySteps}
                        feelingAnswer={feelingAnswer}
                        waterIntake={waterIntake}
                        measureUnitSettings={measureUnitSettings}
                    />
                ) : (
                    <DailyGoalPreview goals={goals ?? []} />
                )}
            </Stack>
            {!isLoading && challengeStarted && (
                <HiddenStatusUpdate
                    status={
                        <React.Fragment>
                            {formatMessage(messages.dailyGoalsCompleted, {
                                dailyGoalsCompletedCount,
                                dailyGoalsTotal
                            })}
                            {formatMessage(messages.dailyGoalCount, {
                                dailyGoalsTotal
                            })}
                        </React.Fragment>
                    }
                />
            )}
        </React.Fragment>
    )
}

export default memo(DailyGoalsContainer)
