import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { StorySubmissionSteps } from '../../enums/StorySubmissionSteps'
import { QuestionType } from '../../enums/questionType'
import {
    Visibility,
    useSubmitStoryMutation
} from '../../../graphql/generated/autogenerated'
import { useInterstitialNavigation } from '../withInterstitialNavigation'
import { QuestionData } from './withStorySubmissionProvider/storySubmissionContext'
import useStorySubmissionContext from './withStorySubmissionProvider/useStorySubmissionContext'
import { useStorySubmissionStateProvider } from './withStorySubmissionStateProvider'
import uploadStorySubmissionImage from '../../hooks/uploadStorySubmissionImage/uploadStorySubmissionImage'

// before submitting a story we must upload any image we have
// and instead save the url to the image in a properly formatted question object
async function uploadAndMapImageData(
    imageQuestions: QuestionData[]
): Promise<QuestionData[]> {
    const mappedImageData: QuestionData[] = []

    await Promise.all(
        imageQuestions.map(async (image) => {
            mappedImageData.push({
                question: image.question,
                ordering: image.ordering,
                image: {
                    description: image.answer.name,
                    uri: image.answer?.src
                        ? (
                              await uploadStorySubmissionImage(
                                  getImageData(image)
                              )
                          ).url
                        : ''
                }
            })
        })
    )

    return mappedImageData
}

// Before uploading an image we need to convert the locally stored base 64 string back to a blob
function getImageData(questionData: QuestionData) {
    const byteCharacters = atob(
        questionData.answer.src
            .toString()
            .replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    )
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new File(
        [new Blob([byteArray], { type: 'contentType' })],
        questionData.answer.name,
        { type: 'image/png' }
    )
}

export const useStorySubmissionData = () => {
    const intl = useIntl()
    const [submitLoading, setSubmitLoading] = useState(false)
    const isRenderedRef = useRef(true)
    const [submitQuestionsData, setSubmitQuestionsData] = useState<any>(null)

    const { challenge, participation, interactionId } =
        useStorySubmissionContext()
    const { answeredQuestions, visibility, setErrorDescription } =
        useStorySubmissionStateProvider()
    const { navigateToKey } = useInterstitialNavigation()
    const [submitStoryMutation] = useSubmitStoryMutation({
        variables: {
            newStory: {
                userChallengeActivityId: participation?.id,
                storyTypeId: interactionId,
                message: submitQuestionsData,
                visibility: visibility as Visibility
            }
        }
    })
    const navigateToErrorScreen = useCallback(
        (description?: string) => {
            setErrorDescription(description)
            navigateToKey(StorySubmissionSteps.error)
        },
        [navigateToKey, setErrorDescription]
    )

    const onNoDataSubmission = useCallback(() => {
        Avo.submissionStarted({
            activityType: 'story_no_data',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: null,
            contentId: participation?.id,
            contentSource: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: participation?.id,
            dayNumber: null,
            featureType: 'challenge',
            teamId: null,
            teamType: null
        })
        navigateToErrorScreen()
        setSubmitLoading(false)
    }, [challenge, participation, navigateToErrorScreen])

    const onError = useCallback(() => {
        setSubmitLoading(false)
        navigateToErrorScreen(
            intl.formatMessage({
                defaultMessage: 'Something went wrong, Please try again',
                description:
                    'text to show in an error popup when something goes wrong'
            })
        )
    }, [navigateToErrorScreen, intl])

    const onSubmitStoryMutation = useCallback(
        async (newSubmitQuestionsData: any) => {
            await submitStoryMutation({
                variables: {
                    newStory: {
                        userChallengeActivityId: participation?.id,
                        storyTypeId: interactionId,
                        message: newSubmitQuestionsData,
                        visibility: visibility as Visibility
                    }
                }
            })
                .then(() => {
                    if (isRenderedRef.current) {
                        navigateToKey(StorySubmissionSteps.success)
                        setSubmitLoading(false)

                        Avo.submissionCompleted({
                            activityType: 'story_submission_completed',
                            challengeId: challenge?.id,
                            challengeTheme: challenge?.theme,
                            challengeType: challenge?.challenge_type,
                            contentFormatType: null,
                            contentId: participation?.id,
                            contentSource: null,
                            contentSubtype: null,
                            contentTitle: null,
                            contentType: null,
                            contentUrl: participation?.id,
                            dayNumber: null,
                            featureType: 'challenge',
                            teamId: null,
                            teamType: null
                        })
                    }
                })
                .catch(() => {
                    onError()
                })
        },
        [
            challenge,
            participation,
            interactionId,
            visibility,
            onError,
            submitStoryMutation,
            navigateToKey
        ]
    )

    const onSubmit = useCallback(async () => {
        setSubmitLoading(true)

        try {
            // If we have any test answers or image answers we allow submission
            if (
                answeredQuestions.some(
                    (q) =>
                        (!!q.answer && q.type === QuestionType.text) ||
                        (!!q.answer?.src && q.type === QuestionType.image)
                )
            ) {
                // to make sure the story submission save successfully we need to strip out any unexpected data we added to the object
                let mappedQuestionData: QuestionData[] = answeredQuestions
                    .filter((q) => q.type === QuestionType.text)
                    .map((q) => {
                        return {
                            question: q.question,
                            ordering: q.ordering,
                            answer: q.answer
                        }
                    })
                mappedQuestionData = mappedQuestionData.concat(
                    await uploadAndMapImageData(
                        answeredQuestions.filter(
                            (q) => q.type === QuestionType.image
                        )
                    )
                )
                const wrappedData = JSON.stringify({
                    type: 'text_submission',
                    questions: mappedQuestionData
                })
                setSubmitQuestionsData(wrappedData)
                onSubmitStoryMutation(wrappedData)
            } else {
                onNoDataSubmission()
            }
        } catch (error) {
            onError()
        }
    }, [answeredQuestions, onNoDataSubmission, onError, onSubmitStoryMutation])

    useEffect(() => {
        isRenderedRef.current = true
        return () => {
            isRenderedRef.current = false
        }
    }, [])

    return { submitLoading, submitQuestionsData, onSubmit }
}

export default useStorySubmissionData
