import { ApolloError } from '@apollo/client'
import {
    SocialGroup,
    useCompanyChallengeSocialGroupsQuery
} from '../../../graphql/generated/autogenerated'

const useGetCompanySocialGroups = (
    challengeId: string
): {
    companyGroups: SocialGroup[]
    loading: boolean
    error: ApolloError | undefined
} => {
    const { data, loading, error } = useCompanyChallengeSocialGroupsQuery({
        variables: {
            challengeId: challengeId
        }
    })

    return {
        companyGroups:
            (data?.socialGroups?.companyChallengeSocialGroups?.filter(
                (socialGroup) =>
                    !socialGroup.isSoloChallenge &&
                    !socialGroup.isMainCommunitySocialGroup
            ) as SocialGroup[]) || [],
        loading,
        error
    }
}

export default useGetCompanySocialGroups
