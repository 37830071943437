import { Button, Divider, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'

import type {
    UnifiedChallenge,
    MicrostepStats
} from '../../../../../graphql/generated/autogenerated'
import CompletedMicrostepsDialog from './CompletedMicrostepsDialog'

type CompletedMicrostepsProps = {
    challenge: UnifiedChallenge
    microstepStats: MicrostepStats
}

const CompletedMicrosteps = ({
    challenge,
    microstepStats
}: CompletedMicrostepsProps) => {
    const { formatNumber } = useIntl()
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const { totalMicrosteps, latestMicrostep } = useMemo(
        () => ({
            totalMicrosteps: microstepStats?.total,
            latestMicrostep:
                microstepStats?.microsteps?.[0]?.microstepDetail?.microstep
        }),
        [microstepStats]
    )

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Completed Microsteps"
                            description="Completed microsteps section title"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Each Microstep taken builds to lasting behavior change"
                            description="Completed microsteps section description"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={2} direction="row" alignItems="center">
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            textAlign="left"
                            color="text.secondary"
                        >
                            {formatNumber(totalMicrosteps ?? 0)}
                        </CoreTypography>

                        <CoreTypography variant="body2" color="primary.main">
                            <FormattedMessage
                                defaultMessage="Each Microstep taken builds to lasting behavior change"
                                description="microstep usage description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Divider />

                    <Stack gap={1}>
                        <CoreTypography variant="body1">
                            {latestMicrostep?.title}
                        </CoreTypography>
                        <CoreTypography variant="body2">
                            {latestMicrostep?.body}
                        </CoreTypography>
                    </Stack>
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View more Microsteps"
                            description="View more Microsteps button"
                        />
                    }
                    onClick={() => setIsDialogOpen(true)}
                />
            </RecapWidget>
            <CompletedMicrostepsDialog
                challenge={challenge}
                microstepStats={microstepStats}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    )
}

export default memo(CompletedMicrosteps)
