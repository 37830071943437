import { useMemo } from 'react'
import {
    GetTeamsLeaderboardByAverageQuery,
    GetTeamsLeaderboardByAverageQueryVariables,
    GetTeamsLeaderboardQuery,
    GetTeamsLeaderboardQueryVariables,
    type ScoreRanked,
    type SocialGroup,
    type UnifiedChallenge,
    useGetTeamsLeaderboardByAverageQuery,
    useGetTeamsLeaderboardQuery
} from '../../../graphql/generated/autogenerated'
import useLeaderboardData from '../../hooks/useLeaderboardData/useLeaderboardData'

const ROWS_PER_PAGE = 10

interface UseChallengeTeamLeaderboardProps {
    teamActivityRank?: number
    showAvgLeaderboard?: boolean
    canFirePostJoinQueries?: boolean
    deviceEnabled?: boolean
    challenge?: UnifiedChallenge
    socialGroup?: SocialGroup | null
}

export const useChallengeTeamLeaderboard = ({
    teamActivityRank,
    showAvgLeaderboard,
    canFirePostJoinQueries,
    deviceEnabled,
    challenge,
    socialGroup
}: UseChallengeTeamLeaderboardProps) => {
    const skipQuery = useMemo(
        () => !canFirePostJoinQueries || !deviceEnabled,
        [canFirePostJoinQueries, deviceEnabled]
    )

    const leaderboardByAvg = useLeaderboardData<
        ScoreRanked,
        GetTeamsLeaderboardByAverageQuery,
        GetTeamsLeaderboardByAverageQueryVariables
    >({
        skipQuery: skipQuery || !showAvgLeaderboard,
        rank: Number(teamActivityRank),
        rowsPerPage: ROWS_PER_PAGE,
        query: useGetTeamsLeaderboardByAverageQuery,
        dataTransform: (data) => ({
            totalEntries:
                data?.unifiedChallenges?.getTeamsLeaderboardByAverage
                    ?.totalEntries,
            rankedList:
                data?.unifiedChallenges?.getTeamsLeaderboardByAverage
                    ?.rankedList
        }),
        challenge,
        socialGroup
    })

    const leaderboard = useLeaderboardData<
        ScoreRanked,
        GetTeamsLeaderboardQuery,
        GetTeamsLeaderboardQueryVariables
    >({
        skipQuery: skipQuery || showAvgLeaderboard,
        rank: Number(teamActivityRank),
        rowsPerPage: ROWS_PER_PAGE,
        query: useGetTeamsLeaderboardQuery,
        dataTransform: (data) => ({
            totalEntries:
                data?.unifiedChallenges?.getTeamsLeaderboard?.totalEntries,
            rankedList: data?.unifiedChallenges?.getTeamsLeaderboard?.rankedList
        }),
        challenge,
        socialGroup
    })

    return showAvgLeaderboard ? leaderboardByAvg : leaderboard
}
