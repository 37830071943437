import { Card, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
interface NotStartedChallengeActivityFeedProps {
    loading: boolean
    hideTitle?: boolean
}

const NotStartedChallengeActivityFeed: React.FC<
    NotStartedChallengeActivityFeedProps
> = ({ loading, hideTitle }) => (
    <Stack gap={3}>
        {!hideTitle && (
            <ConditionalSkeleton showSkeleton={loading}>
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Challenge feed"
                        description="daily goals start title"
                    />
                </CoreTypography>
            </ConditionalSkeleton>
        )}
        <Card variant="outlined">
            <Stack
                data-testid="not-started-daily-goals"
                alignItems="center"
                justifyContent="center"
                p={3}
            >
                <ConditionalSkeleton showSkeleton={loading} variant="circular">
                    <LeafIcon icon={'hands-clapping'} fontSize={'large'} />
                </ConditionalSkeleton>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    maxWidth={240}
                    gap={1}
                >
                    <ConditionalSkeleton showSkeleton={loading}>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Celebrate progress"
                                description="daily goals start title"
                            />
                        </CoreTypography>
                    </ConditionalSkeleton>
                    <ConditionalSkeleton showSkeleton={loading}>
                        <CoreTypography variant="body2" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Cheer on your coworkers as they complete daily goals."
                                description="daily goals start title"
                            />
                        </CoreTypography>
                    </ConditionalSkeleton>
                </Stack>
            </Stack>
        </Card>
    </Stack>
)

export default memo(NotStartedChallengeActivityFeed)
