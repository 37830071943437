import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    CommunitySocialGroupActivityFeedQuery,
    CommunitySocialGroupActivityFeedQueryVariables,
    Post,
    useCommunitySocialGroupActivityFeedQuery
} from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import usePage from '../../../../hooks/usePage/usePage'
import usePagination from '../../../../hooks/usePagination/usePagination'
import SocialActivityProviderContext, {
    SocialActivityProviderValue
} from './socialActivityContext'
import { stripBOM } from '../../../../../shared/utils/bom'

export default function withCommunitySocialGroupActivityProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [displayName, setDisplayName] = useState('')
        const { challengeId } = useParams<{ challengeId: string }>()
        const { canFirePostJoinQueries, soloChallenge } =
            useCompanyChallengeProviderContext()

        const {
            data,
            queryData,
            loading,
            error,
            loadingNext,
            hasMore,
            handleLoadMore,
            refetch
        } = usePagination<
            Post,
            CommunitySocialGroupActivityFeedQuery,
            CommunitySocialGroupActivityFeedQueryVariables
        >({
            defaultOffset: 30,
            query: useCommunitySocialGroupActivityFeedQuery,
            queryProps: { challengeId: stripBOM(challengeId) },
            skip: !canFirePostJoinQueries || soloChallenge,
            dataTransform: (data) =>
                data?.socialGroups?.communitySocialGroupActivityFeed as Post[]
        })

        const { pageValues, onNextPage } = usePage({
            rowsPerPage: 5,
            accumulate: true,
            onNavigate: handleLoadMore,
            values: data
        })

        useEffect(() => {
            if (queryData?.socialGroups?.displayName?.displayName) {
                setDisplayName(queryData.socialGroups.displayName.displayName)
            }
        }, [queryData])

        const state = useMemo<SocialActivityProviderValue>(
            () => ({
                displayName,
                activityFeed: pageValues as Post[],
                error: Boolean(error),
                loading,
                loadingNext,
                hasMore,
                refetch,
                loadMore: onNextPage,
                fetchMore: handleLoadMore as any
            }),
            [
                error,
                loading,
                hasMore,
                loadingNext,
                displayName,
                pageValues,
                refetch,
                onNextPage,
                handleLoadMore
            ]
        )

        return (
            <SocialActivityProviderContext.Provider value={state}>
                <Component {...props} />
            </SocialActivityProviderContext.Provider>
        )
    }
}
