import { IconName } from '@fortawesome/fontawesome-common-types'
import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import CenterAlign from '../../../../../../components/elements/CenterAlign/CenterAlign'
import SuccessLoadingButton from '../../../../../../components/elements/SuccessLoadingButton/SuccessLoadingButton'
import { GQLNull } from '../../../../../../shared/utils/Nulls'

export type FeelingButtonPromiseProps = {
    answer: number
    date: string | GQLNull
}

export type FeelingIconButtonProps = {
    answer?: number
    icon: IconName
    label: string
    completed: boolean
    disabled?: boolean
    onLoading?: (loading: boolean) => void
    promise?: (options: FeelingButtonPromiseProps) => Promise<unknown>
}

const FeelingIconButton: React.FC<FeelingIconButtonProps> = ({
    answer,
    icon,
    label,
    completed,
    disabled,
    onLoading,
    promise = () => Promise.resolve()
}) => {
    const { palette } = useTheme()
    return (
        <SuccessLoadingButton
            promise={() => {
                onLoading?.(true)

                return completed
                    ? Promise.resolve()
                    : promise({
                          answer: answer ?? 0,
                          date: null
                      }).finally(() => onLoading?.(false))
            }}
            fixWidth={true}
            disabled={disabled}
            variant={completed ? 'contained' : 'outlined'}
            aria-label={label}
            color={'primary'}
            sx={{
                borderWidth: '1px!important',
                borderColor: palette.grey[200],
                width: 45,
                height: 45
            }}
        >
            <CenterAlign>
                <LeafIcon icon={icon} iconStyle="light" />
            </CenterAlign>
        </SuccessLoadingButton>
    )
}

export default memo(FeelingIconButton)
