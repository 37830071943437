import { Box, Fade, Slide } from '@mui/material'
import {
    LeafIcon,
    LoadingButton,
    LoadingButtonProps
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useRef, useState } from 'react'

export interface SuccessLoadingButtonProps
    extends Omit<LoadingButtonProps, 'loading'> {
    promise: (success?: boolean) => Promise<unknown>
    children?: React.ReactNode
}

const SuccessLoadingButton: React.FC<SuccessLoadingButtonProps> = ({
    promise,
    children,
    ...props
}) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const ref = useRef(null)

    const onSuccess = useCallback(() => {
        setSuccess(true)

        setTimeout(() => setSuccess(false), 1500)
    }, [])

    const onSave = useCallback(async () => {
        setLoading(true)
        try {
            if (await promise()) {
                onSuccess()
            }
        } finally {
            setLoading(false)
        }
    }, [promise, onSuccess])

    return (
        <Box position="relative" overflow="hidden" ref={ref}>
            <LoadingButton
                {...props}
                loading={loading}
                {...(loading && { disabled: loading })}
                onClick={onSave}
            >
                <Fade in={!success}>
                    <Box
                        sx={{
                            ...(success && { color: 'transparent' })
                        }}
                    >
                        {children}
                    </Box>
                </Fade>
            </LoadingButton>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -45%)'
                }}
            >
                <Fade in={success} mountOnEnter unmountOnExit>
                    <Slide
                        in={success}
                        mountOnEnter
                        unmountOnExit
                        container={ref.current}
                        timeout={400}
                    >
                        <Box>
                            <LeafIcon
                                icon={'check'}
                                fontSize={'large'}
                                color={
                                    props.variant === 'contained'
                                        ? 'secondary'
                                        : 'accent'
                                }
                            />
                        </Box>
                    </Slide>
                </Fade>
            </Box>
        </Box>
    )
}

export default memo(SuccessLoadingButton)
