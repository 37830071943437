import { Box, Collapse, Dialog, Fade, Grow, Stack } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CloseModalIcon } from '../../shared/components/elements'
import SuccessScreen from '../../components/elements/SuccessScreen/SuccessScreen'
import { AddSocialGroupStates } from '../../enums/addSocialGroupStates'
import CreateDisplayName from './CreateDisplayName/CreateDisplayName'
import CreateSocialGroup from './CreateSocialGroup/CreateSocialGroup'

export type SocialGroupModalProps = {
    open: boolean
    displayName?: string
    showPlural?: boolean
    socialGroupRefetch?: () => void
    displayNameRefetch?: () => void
    handleOnClose: () => void
    externalJoinGroupAction?: () => Promise<void>
}

const TransitionComponent = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Grow in ref={ref} {...props} />
})

const messages = defineMessages({
    createdSuccess: {
        defaultMessage: `Successfully created {groups, plural, one {group} other {groups}}`,
        description: 'message shown on successfully creating a group name'
    },
    joinedSuccess: {
        defaultMessage: `Successfully joined {groups, plural, one {group} other {groups}}`,
        description: 'message shown on successfully joining a group name'
    },
    backToGroup: {
        defaultMessage: `Back to {groups, plural, one {Group} other {Groups}}`,
        description: 'text to show on link'
    }
})

const SocialGroupModal: React.FC<SocialGroupModalProps> = ({
    open,
    displayName,
    showPlural,
    socialGroupRefetch = () => ({}),
    displayNameRefetch = () => ({}),
    handleOnClose,
    externalJoinGroupAction
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const [socialGroupCurrentState, setSocialGroupCurrentState] =
        React.useState<AddSocialGroupStates>(
            AddSocialGroupStates.createSocialGroup
        )

    useEffect(() => {
        if (open) {
            if (!displayName) {
                setSocialGroupCurrentState(AddSocialGroupStates.displayName)
            } else if (!externalJoinGroupAction) {
                setSocialGroupCurrentState(
                    AddSocialGroupStates.createSocialGroup
                )
            } else {
                setSocialGroupCurrentState(AddSocialGroupStates.success)
            }
        }
    }, [displayName, externalJoinGroupAction, open])

    const isActive = useCallback(
        (state: AddSocialGroupStates) => socialGroupCurrentState === state,
        [socialGroupCurrentState]
    )

    const onClose = useCallback(() => {
        if (externalJoinGroupAction && isActive(AddSocialGroupStates.success)) {
            externalJoinGroupAction()
        }

        displayNameRefetch()
        socialGroupRefetch()
        handleOnClose()
    }, [
        externalJoinGroupAction,
        isActive,
        displayNameRefetch,
        socialGroupRefetch,
        handleOnClose
    ])

    const onSocialGroupActionSubmit = useCallback(() => {
        socialGroupRefetch()
        setSocialGroupCurrentState(AddSocialGroupStates.success)
    }, [socialGroupRefetch])

    const successTitle = useMemo(() => {
        if (externalJoinGroupAction) {
            return intl.formatMessage(messages.joinedSuccess, {
                groups: showPlural ? 2 : 1
            })
        }

        return intl.formatMessage(messages.createdSuccess, {
            groups: showPlural ? 2 : 1
        })
    }, [externalJoinGroupAction, intl, showPlural])

    const activeSteps = useMemo<{ [key: string]: () => JSX.Element }>(
        () => ({
            ...(!displayName && {
                [AddSocialGroupStates.displayName]: () => (
                    <CreateDisplayName
                        onSubmit={() => {
                            setSocialGroupCurrentState(
                                !externalJoinGroupAction
                                    ? AddSocialGroupStates.createSocialGroup
                                    : AddSocialGroupStates.success
                            )
                        }}
                    />
                )
            }),
            ...(!externalJoinGroupAction && {
                [AddSocialGroupStates.createSocialGroup]: () => (
                    <CreateSocialGroup
                        displayNameExists={!!displayName}
                        onSubmit={() => {
                            onSocialGroupActionSubmit()
                        }}
                    />
                )
            }),
            [AddSocialGroupStates.success]: () => {
                return (
                    <SuccessScreen
                        title={successTitle}
                        buttonText={intl.formatMessage(messages.backToGroup, {
                            groups: !externalJoinGroupAction ? 2 : 1
                        })}
                        onClose={() => {
                            onClose()
                        }}
                        active={isActive(AddSocialGroupStates.success)}
                    />
                )
            }
        }),
        [
            externalJoinGroupAction,
            onSocialGroupActionSubmit,
            displayName,
            intl,
            isActive,
            onClose,
            successTitle
        ]
    )

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={TransitionComponent}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.paper,
                    maxWidth: theme.spacing(79.5),
                    width: '100%',
                    minHeight: theme.spacing(51),
                    p: `${theme.spacing(5)} ${theme.spacing(6)}`,
                    [theme.breakpoints.down('sm')]: {
                        p: theme.spacing(2.5),
                        margin: theme.spacing(2)
                    }
                }
            }}
            data-testid="create-social-group-modal"
            aria-describedby="dialog1Desc"
        >
            <CloseModalIcon
                onClick={
                    isActive(AddSocialGroupStates.success)
                        ? onClose
                        : handleOnClose
                }
            />
            {!displayName &&
                !isActive(AddSocialGroupStates.success) &&
                !externalJoinGroupAction && (
                    <>
                        <Stack
                            direction="row"
                            sx={{
                                pr: theme.spacing(2.5),
                                [theme.breakpoints.down('sm')]: {
                                    mt: theme.spacing(3),
                                    pr: theme.spacing(7)
                                }
                            }}
                            data-testid="step-indicator"
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    height: theme.spacing(0.25),
                                    borderRadius: theme.spacing(0.125),
                                    mr: theme.spacing(0.5),
                                    backgroundColor: isActive(
                                        AddSocialGroupStates.displayName
                                    )
                                        ? theme.palette.primary.main
                                        : `${theme.palette.primary.main}30`
                                }}
                            />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    height: theme.spacing(0.25),
                                    borderRadius: theme.spacing(0.125),
                                    ml: theme.spacing(0.5),
                                    backgroundColor: isActive(
                                        AddSocialGroupStates.displayName
                                    )
                                        ? `${theme.palette.primary.main}30`
                                        : theme.palette.primary.main
                                }}
                            />
                        </Stack>
                    </>
                )}

            <Box sx={{ position: 'relative' }}>
                {Object.keys(activeSteps).map((step: string, index: number) => {
                    const isActiveStep = isActive(step as AddSocialGroupStates)

                    return (
                        <Collapse in={isActiveStep} key={index}>
                            <Fade in={isActiveStep}>
                                <Box>{activeSteps[step]()}</Box>
                            </Fade>
                        </Collapse>
                    )
                })}
            </Box>
        </Dialog>
    )
}

export default SocialGroupModal
