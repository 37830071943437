import { Box, Dialog, DialogTitle } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'

export type ConfirmActionModalProps = {
    isOpen: boolean
    title: string
    confirmActionText?: string
    cancelActionText?: string
    confirmAction?: () => Promise<any>
    cancelAction?: () => Promise<any>
    handleOnClose: (close: boolean) => void
}

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
    isOpen,
    title,
    confirmActionText,
    cancelActionText,
    confirmAction,
    cancelAction,
    handleOnClose
}) => {
    const [primaryActionLoading, setPrimaryActionLoading] =
        useState<boolean>(false)
    const [secondaryActionLoading, setSecondaryActionLoading] =
        useState<boolean>(false)

    const onAction = useCallback(
        (action: () => Promise<any>, setAction: (loading: boolean) => void) => {
            setAction(true)

            action().finally(() => setAction(false))
        },
        []
    )

    return (
        <Dialog open={isOpen} onClose={handleOnClose}>
            <Box p={3} data-testid="confirm-action-modal">
                <DialogTitle sx={{ p: 0 }}>{title}</DialogTitle>
                <Box display="flex" mt={3.5}>
                    {confirmActionText && confirmAction && (
                        <LoadingButton
                            variant="contained"
                            fixWidth={true}
                            loading={primaryActionLoading}
                            onClick={() =>
                                onAction(confirmAction, setPrimaryActionLoading)
                            }
                            data-testid="primary-action-button"
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {confirmActionText}
                            </CoreTypography>
                        </LoadingButton>
                    )}

                    {cancelActionText && cancelAction && (
                        <LoadingButton
                            sx={{ ml: 1 }}
                            variant="text"
                            fixWidth={true}
                            loading={secondaryActionLoading}
                            onClick={() =>
                                onAction(
                                    cancelAction,
                                    setSecondaryActionLoading
                                )
                            }
                            data-testid="secondary-action-button"
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {cancelActionText}
                            </CoreTypography>
                        </LoadingButton>
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}

export default ConfirmActionModal
