import { SvgIconProps } from '@mui/material'
import { NamedExoticComponent } from 'react'
import { defineMessages, MessageDescriptor } from 'react-intl'
import Step4 from '../../../../../assets/tutorial/step4.png'
import CelebrateProgress from '../../../../../shared/components/icons/tutorial/CelebrateProgress'
import CompleteDailyGoals from '../../../../../shared/components/icons/tutorial/CompleteDailyGoals'
import EarnPrizes from '../../../../../shared/components/icons/tutorial/EarnPrizes'
import GrowPlants from '../../../../../shared/components/icons/tutorial/GrowPlants'

const IMG_ALT_TEXT = defineMessages({
    SleepHabits: {
        defaultMessage:
            'A picture representing sleep habits, actionable steps to help you create sustainable habits that unlock better sleep.',
        description: 'Alt text for the svg image of the sleep habits step'
    },
    SleepReflect: {
        defaultMessage:
            'A picture representing sleep reflection on how your sleep has improved and earn plants for your garden by completing your daily goals.',
        description: 'Alt text for the svg image of the sleep reflect step'
    },
    CelebrateAchievement: {
        defaultMessage:
            'A picture representing celebrating your achievements in your challenge recap',
        description:
            'Alt text for the svg image of the celebrate achievement step'
    },
    CompleteDailyGoals: {
        defaultMessage:
            'A picture representing daily goals completion, relating to microsteps, activity, learn and other associated content',
        description: 'Alt text for the svg image of the first step'
    },
    GrowPlants: {
        defaultMessage:
            'A picture of the 4 of the community challenge plant cards',
        description: 'Alt text for the svg image of the second step'
    },
    EarnPrizes: {
        defaultMessage:
            'A picture of the 3 community challenge plant cards and a prize image indicating there are prizes after completing the challenge',
        description: 'Alt text for the svg image of the third step'
    },
    LogYouActivity: {
        defaultMessage:
            'Two icons indicating how you can log your activity via wearable devices or manually',
        description: 'Alt text for the svg image of the fourth step'
    },
    CelebrateProgress: {
        defaultMessage:
            'A bar graph card representing how you can share your success with members of your team and company',
        description: 'Alt text for the svg image of the fifth step'
    }
})

const STEP_TITLE = defineMessages({
    SleepHabits: {
        defaultMessage: 'Learn new sleep habits',
        description: 'Title of the sleep step'
    },
    SleepReflect: {
        defaultMessage: 'Reflect on your progress',
        description: 'Title of the sleep reflect step'
    },
    CelebrateAchievement: {
        defaultMessage: 'Celebrate your achievements',
        description: 'Title of the celebrate achievement step'
    },
    CompleteDailyGoals: {
        defaultMessage: 'Complete daily goals',
        description: 'Title of the first step'
    },
    GrowPlants: {
        defaultMessage: 'Grow plants',
        description: 'Title of the second step'
    },
    EarnPrizes: {
        defaultMessage: 'Earn prizes',
        description: 'Title of the third step'
    },
    LogYouActivity: {
        defaultMessage: 'Log your activity',
        description: 'Title of the fourth step'
    },
    CelebrateProgress: {
        defaultMessage: 'Celebrate your success',
        description: 'Title of the fifth step'
    }
})

const STEP_DESCRIPTION = defineMessages({
    SleepHabits: {
        defaultMessage:
            'This Challenge will give you small, actionable steps to help you create sustainable habits that unlock better sleep.',
        description: 'Description text of the sleep step'
    },
    SleepReflect: {
        defaultMessage:
            "You'll reflect each day on how your sleep has improved and earn plants for your garden by completing your daily goals.",
        description: 'Description text of the sleep reflect step'
    },
    CelebrateAchievement: {
        defaultMessage:
            "Get a summary of your accomplishments in our Challenge Recap, available after the 21 days are up. Celebrate everything you've achieved and get the chance to win an exclusive trophy for your Thrive garden.",
        description: 'Description of the celebrate achievements step'
    },
    CompleteDailyGoals: {
        defaultMessage:
            'Every day you’ll get a set of daily goals to complete. These are short, simple tasks, like taking a Microstep, reading an article, or doing a 60-second Reset.',
        description: 'Description text of the first step'
    },
    GrowPlants: {
        defaultMessage:
            'Each time you complete a daily goal, you’ll receive a new plant in the Challenge garden. Check in every day and watch your garden grow!',
        description: 'Description text of the second step'
    },
    EarnPrizes: {
        defaultMessage:
            'When you reach the challenge plant goal, you’ll earn a special prize for your personal garden. You will see your prize in your personal garden once the Challenge is over.',
        description: 'Description text of the third step'
    },
    LogYouActivity: {
        defaultMessage:
            'One of your daily goals might be an activity goal. To log your steps, you can either connect a wearable device or add activity manually, tracking each activity type and duration. The more active you are, the higher you’ll be on the leaderboard!',
        description: 'Description text of the third step'
    },
    CelebrateProgress: {
        defaultMessage:
            'Your results will appear on the leaderboard throughout the course of the Challenge. Check in often to cheer on your friends and watch each other grow.',
        description: 'Description of the fourth step'
    }
})

export type StepDetails = {
    StepIcon?: NamedExoticComponent<SvgIconProps>
    imgSrc?: string
    alt: MessageDescriptor
    title: MessageDescriptor
    description: MessageDescriptor | null
}

export enum TutorialSteps {
    SleepHabits = 'SleepHabits',
    SleepReflect = 'SleepReflect',
    CelebrateAchievement = 'CelebrateAchievement',
    CompleteDailyGoals = 'CompleteDailyGoals',
    GrowPlants = 'GrowPlants',
    EarnPrizes = 'EarnPrizes',
    LogYourActivity = 'LogYourActivity',
    CelebrateYourSuccess = 'CelebrateYourSuccess'
}

export const getStepDetails: (
    deviceEnabled: boolean,
    sleepEnabled: boolean,
    allowCelebrate: boolean
) => {
    [key: string]: StepDetails
} = (
    allowActivityGoal = true,
    sleepEnabled = false,
    allowCelebrate = true
) => ({
    ...(sleepEnabled
        ? {
              [TutorialSteps.SleepHabits]: {
                  StepIcon: CompleteDailyGoals,
                  alt: IMG_ALT_TEXT.SleepHabits,
                  title: STEP_TITLE.SleepHabits,
                  description: STEP_DESCRIPTION.SleepHabits
              },
              [TutorialSteps.SleepReflect]: {
                  StepIcon: EarnPrizes,
                  alt: IMG_ALT_TEXT.SleepReflect,
                  title: STEP_TITLE.SleepReflect,
                  description: STEP_DESCRIPTION.SleepReflect
              },
              [TutorialSteps.CelebrateAchievement]: {
                  StepIcon: CelebrateProgress,
                  alt: IMG_ALT_TEXT.CelebrateAchievement,
                  title: STEP_TITLE.CelebrateAchievement,
                  description: STEP_DESCRIPTION.CelebrateAchievement
              }
          }
        : {
              [TutorialSteps.CompleteDailyGoals]: {
                  StepIcon: CompleteDailyGoals,
                  alt: IMG_ALT_TEXT.CompleteDailyGoals,
                  title: STEP_TITLE.CompleteDailyGoals,
                  description: STEP_DESCRIPTION.CompleteDailyGoals
              },
              [TutorialSteps.GrowPlants]: {
                  StepIcon: GrowPlants,
                  alt: IMG_ALT_TEXT.GrowPlants,
                  title: STEP_TITLE.GrowPlants,
                  description: STEP_DESCRIPTION.GrowPlants
              },
              [TutorialSteps.EarnPrizes]: {
                  StepIcon: EarnPrizes,
                  alt: IMG_ALT_TEXT.EarnPrizes,
                  title: STEP_TITLE.EarnPrizes,
                  description: STEP_DESCRIPTION.EarnPrizes
              },
              ...(allowActivityGoal && {
                  [TutorialSteps.LogYourActivity]: {
                      imgSrc: Step4,
                      alt: IMG_ALT_TEXT.LogYouActivity,
                      title: STEP_TITLE.LogYouActivity,
                      description: STEP_DESCRIPTION.LogYouActivity
                  }
              }),
              ...(allowCelebrate && {
                  [TutorialSteps.CelebrateYourSuccess]: {
                      StepIcon: CelebrateProgress,
                      alt: IMG_ALT_TEXT.CelebrateProgress,
                      title: STEP_TITLE.CelebrateProgress,
                      description: STEP_DESCRIPTION.CelebrateProgress
                  }
              })
          })
})
