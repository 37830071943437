import { Box, Link } from '@mui/material'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import WhereIsMyActivity from './WhereIsMyActivityDialog'

const WhereIsMyActivityButton: React.FC<{ disabled: boolean }> = ({
    disabled
}) => {
    const [showWhereActivity, setShowWhereActivity] = useState<boolean>(false)

    const handleShow = () => setShowWhereActivity(true && !disabled)
    const handleClose = () => setShowWhereActivity(false)

    return (
        <Box>
            <Link
                href={disabled ? undefined : 'javascript:void(0)'}
                flexShrink={0}
                sx={{
                    color: disabled ? 'text.disabled' : undefined
                }}
                onClick={handleShow}
            >
                <FormattedMessage
                    defaultMessage="Where is my activity?"
                    description="where my activity button text"
                />
            </Link>
            <WhereIsMyActivity open={showWhereActivity} onClose={handleClose} />
        </Box>
    )
}

export default WhereIsMyActivityButton
