import React, { PropsWithChildren } from 'react'
import { Backdrop, Fade, Modal, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

export type ThriveAudioResetPopupProps = PropsWithChildren<{
    open: boolean

    onClose: () => void
}>

function ThriveAudioResetPopup(props: ThriveAudioResetPopupProps) {
    const { children, onClose, open } = props

    const theme = useTheme()

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition={true}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            color="primary"
            data-testid="ThriveAudioResetPopup"
        >
            <Fade in={open} onExited={onClose}>
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: theme.palette.primary.main
                    }}
                    data-testid="ThriveAudioResetPopupContent"
                >
                    {children}
                </Stack>
            </Fade>
        </Modal>
    )
}

export default React.memo(ThriveAudioResetPopup)
