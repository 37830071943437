import { useCallback, useRef } from 'react'

export const useDebounce = (action: (props: any) => Promise<any>) => {
    const timer = useRef(null)

    const actionOnTimeout = useCallback(
        (timeout = 1000, props: any = {}) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            ;(timer as any).current = setTimeout(() => action(props), timeout)

            return timer.current
        },
        [action]
    )

    return { actionOnTimeout }
}

export default useDebounce
