import { Box, Checkbox, CheckboxProps, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { v4 as uuid } from 'uuid'
import React, { memo } from 'react'

export interface LabelledCheckboxProps extends CheckboxProps {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
}

const LabelledCheckbox: React.FC<LabelledCheckboxProps> = ({
    checked,
    onChange,
    label,
    ...props
}) => {
    const theme = useTheme()
    const ariaId = uuid()

    return (
        <Stack direction="row">
            <Box
                sx={{
                    mt: theme.spacing(-1.2)
                }}
            >
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: 24
                        },
                        color: theme.palette.primary.main
                    }}
                    color="primary"
                    inputProps={{
                        'aria-describedby': ariaId
                    }}
                    {...props}
                />
            </Box>
            <CoreTypography
                id={ariaId}
                customVariant="form"
                color="primary"
                sx={{ ml: 0.5 }}
            >
                {label}
            </CoreTypography>
        </Stack>
    )
}

export default memo(LabelledCheckbox)
