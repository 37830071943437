import { Box, CardMedia, Fade, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { MutableRefObject, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { defineMessages, useIntl } from 'react-intl'
import SnackBarAlert from '../../../elements/SnackBarAlert'
import StoryQuestion from '.'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import useStorySubmissionContext from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/useStorySubmissionContext'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export type StoryImageQuestionProps = {
    questionData: QuestionData
    isActive: boolean
    containerRef?: MutableRefObject<any>
    index: number
}

const messages = defineMessages({
    uploadedImage: {
        defaultMessage: 'uploaded image preview',
        description: 'alt text for the uploaded image'
    },
    chooseImage: {
        defaultMessage: 'Choose an image here or Drag it here',
        description: 'description on how to upload an image'
    },
    uploadSize: {
        defaultMessage: 'Max file size: 5mb',
        description: 'User image max size error message'
    }
})

const fiveMegabytes = 5000000

const StoryImageQuestion: React.FC<StoryImageQuestionProps> = ({
    questionData,
    isActive,
    containerRef,
    index
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const [answer, setAnswer] = useState(questionData.answer)
    const [imageSizeErrorOpen, setImageSizeErrorOpen] = useState(false)

    const { challenge, participation, interactionId } =
        useStorySubmissionContext()
    const { onAnswer } = useStorySubmissionStateProvider()

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        onDrop: (acceptedFiles) => onDrop(acceptedFiles)
    })

    const onAnswerImageQuestion = useCallback(
        (newAnswer: any) => {
            setAnswer(newAnswer)
            onAnswer(index, newAnswer)
        },
        [onAnswer, index]
    )

    // on drop or selection of a file we set the answer as a base 64 string pulled from the blob
    // This way we can immediatly show the uploaded image below and store the image in local storage (cannot save a blob locally)
    const onDrop = useCallback(
        (acceptedFiles: any) => {
            acceptedFiles.forEach((file: any) => {
                const reader = new FileReader()

                // don't accept any files greater than 5Mb
                if (file.size <= fiveMegabytes) {
                    reader.onload = () => {
                        onAnswerImageQuestion({
                            src: reader.result,
                            name: file.name
                        })
                        Avo.submissionStarted({
                            activityType: 'story_photo_uploaded',
                            challengeId: challenge?.id,
                            challengeTheme: challenge?.theme,
                            challengeType: challenge?.challenge_type,
                            contentFormatType: null,
                            contentId: participation?.id,
                            contentSource: null,
                            contentSubtype: null,
                            contentTitle: questionData.question,
                            contentType: null,
                            contentUrl: participation?.id,
                            dayNumber: null,
                            featureType: 'challenge',
                            teamId: null,
                            teamType: null
                        })
                    }
                    reader.readAsDataURL(file)
                } else {
                    setImageSizeErrorOpen(true)
                }
            })
        },
        [onAnswerImageQuestion, questionData.question, challenge, participation]
    )

    return (
        <>
            <SnackBarAlert
                open={imageSizeErrorOpen}
                onClose={() => setImageSizeErrorOpen(false)}
                severity={'error'}
                message={intl.formatMessage(messages.uploadSize)}
            />
            <StoryQuestion
                questionData={questionData}
                isActive={isActive}
                containerRef={containerRef}
            >
                <Box sx={{ mb: theme.spacing(3) }}>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            minHeight: theme.spacing(26),
                            maxWidth: theme.spacing(62.75),
                            background: theme.palette.background.paper,
                            border: `${theme.spacing(0.125)} dashed ${
                                theme.palette.grey[300]
                            }`,
                            borderRadius: theme.spacing(1),
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        data-testid="dropzone"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <LeafIcon
                            icon={'plus'}
                            fontSize={'large'}
                            sx={{
                                mb: theme.spacing(1),
                                color: theme.palette.grey[700]
                            }}
                        />
                        <CoreTypography
                            variant="body1"
                            sx={{ color: theme.palette.grey[700] }}
                        >
                            {intl.formatMessage(messages.chooseImage)}
                        </CoreTypography>
                    </Stack>
                    {!!answer?.src && (
                        <Fade in>
                            <Box
                                sx={{
                                    maxWidth: theme.spacing(62.75),
                                    border: `${theme.spacing(0.125)} solid ${
                                        theme.palette.grey[300]
                                    }`,
                                    p: theme.spacing(3),
                                    mt: theme.spacing(3)
                                }}
                                data-testid="question-image"
                            >
                                <CardMedia
                                    component="img"
                                    image={answer?.src}
                                    sx={{
                                        minHeight: '100%'
                                    }}
                                    alt={intl.formatMessage(
                                        messages.uploadedImage
                                    )}
                                />
                            </Box>
                        </Fade>
                    )}
                </Box>
            </StoryQuestion>
        </>
    )
}

export default StoryImageQuestion
