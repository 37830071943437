// External libraries
import { Button, Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import cloneDeep from 'lodash/cloneDeep'
import { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

// Types
import {
    PlantInfo,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import { type usePlantIconsValue } from '../../../../hooks/usePlantIcons/usePlantIcons'
import GardenEmptyStateIcon from '../../../../../shared/components/icons/GardenEmptyStateIcon'
import PlantIcon from '../ChallengePlantCard/PlantIcon'
import NotStartedGeneric from '../NotStartedGeneric'
import GardenEmptyState from './GardenEmptyState'

const wrapSx = { maxWidth: '75px!important' }

export interface ChallengeGardenProps {
    plants?: UserPlantInfo[] | PlantInfo[]
    challengeStarted?: boolean
    columnSize?: number
    rowSize?: number
    canLoadMore?: boolean
    hideEmptyState?: boolean
    hideEmptyStateText?: boolean
    getPlantIcon?: (plant?: UserPlantInfo | PlantInfo) => usePlantIconsValue
    defaultPlants?: UserPlantInfo[] | PlantInfo[]
}

const ChallengeGarden: React.FC<ChallengeGardenProps> = ({
    plants = [],
    challengeStarted = false,
    columnSize = 12,
    rowSize = 5,
    canLoadMore = true,
    hideEmptyState,
    hideEmptyStateText,
    getPlantIcon,
    defaultPlants
}) => {
    const [rowsShown, setRowsShown] = useState(rowSize)

    const viewMore = useCallback(() => {
        setRowsShown(rowsShown + rowSize)
    }, [rowsShown, rowSize])

    return !challengeStarted ? (
        <Stack alignItems="center" justifyContent="center" height="100%">
            <NotStartedGeneric
                icon={<GardenEmptyStateIcon />}
                title={
                    <FormattedMessage
                        defaultMessage="Cultivate community progress"
                        description="daily goals start title"
                    />
                }
                description={
                    <FormattedMessage
                        defaultMessage="Once the Challenge starts, watch the community garden flourish as you and coworkers complete daily goals and earn plants."
                        description="daily goals start title"
                    />
                }
            />
        </Stack>
    ) : plants.length > 0 || hideEmptyState ? (
        <Stack alignItems="center" justifyContent="center" width="100%" gap={1}>
            <Grid container spacing={0}>
                {cloneDeep(plants)
                    .splice(0, rowsShown * columnSize)
                    .map((plant, index: number) => (
                        <Grid
                            item
                            key={index}
                            sx={wrapSx}
                            xs={12 / Math.floor(12 / columnSize)}
                        >
                            <PlantIcon
                                plant={plant as UserPlantInfo}
                                getPlantIcon={getPlantIcon}
                            />
                        </Grid>
                    ))}
            </Grid>
            {canLoadMore && plants?.length > rowsShown * columnSize && (
                <Button onClick={viewMore}>
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Load More"
                            description="button title for loading more plants"
                        />
                    </CoreTypography>
                </Button>
            )}
        </Stack>
    ) : (
        <GardenEmptyState hideEmptyStateText={hideEmptyStateText} />
    )
}

export default memo(ChallengeGarden)
