// React and related
import React, { memo, useMemo } from 'react'

// External libraries
import { Grid } from '@mui/material'

// Components
import ChallengeYesterdaysRecap from '../../../../Shared/ChallengeYesterdaysRecap'
import ChallengeYesterdaysRecapSkeleton from '../../../../Shared/ChallengeYesterdaysRecap/ChallengeYesterdaysRecapSkeleton'
import DailyGoalCard from '../../../../Shared/DailyGoals/DailyGoalCard'

// Hooks
import { useDailyGoals } from '../../../../../../hooks/useDailyGoals/useDailyGoals'
import useGetRecapGoalMetadata from '../../../../../../../hooks/useGetRecapGoalMetadata/useGetRecapGoalMetadata'
import useGetYesterdaysRecap from '../../../../../../../hooks/useGetYesterdaysRecap/useGetYesterdaysRecap'

// Types
import {
    MeasureUnit,
    type UnifiedChallenge
} from '../../../../../../../graphql/generated/autogenerated'

export type DailyGoalsListProps = {
    setResetPlaying: (resetPlaying: boolean) => void
    goals: any[]
    challenge: UnifiedChallenge
    dailyGoalRefetch?: () => Promise<void>
    userTodaySteps?: number
    feelingAnswer?: number
    waterIntake?: number
    measureUnitSettings?: MeasureUnit | null
}

const DailyGoalsList: React.FC<DailyGoalsListProps> = ({
    setResetPlaying,
    goals,
    challenge,
    dailyGoalRefetch,
    userTodaySteps,
    feelingAnswer,
    waterIntake,
    measureUnitSettings
}) => {
    const dailyGoals = useDailyGoals({
        goals,
        setResetPlaying,
        challenge,
        dailyGoalRefetch,
        userTodaySteps,
        feelingAnswer,
        waterIntake,
        measureUnitSettings
    })

    const { recap, loading: recapLoading } = useGetYesterdaysRecap({
        challengeId: challenge?.id,
        challengeDay: challenge?.dayOfChallenge
    })
    const recapGoalMetadata = useGetRecapGoalMetadata(recap)

    const totalItems = useMemo(
        () => (dailyGoals?.length || 1) + (recapGoalMetadata.length ? 1 : 0),
        [dailyGoals, recapGoalMetadata]
    )

    const gridItemSize = useMemo(
        () => (totalItems > 3 ? 6 : Math.max(12 / totalItems, 4)),
        [totalItems]
    )

    return (
        <Grid container spacing={2}>
            {(recapLoading || !!recapGoalMetadata.length) && (
                <Grid item xs={12} md={gridItemSize}>
                    {recapLoading ? (
                        <ChallengeYesterdaysRecapSkeleton />
                    ) : (
                        <ChallengeYesterdaysRecap
                            recapGoalMetadata={recapGoalMetadata}
                        />
                    )}
                </Grid>
            )}
            {dailyGoals?.map((dailyGoal, index) => (
                <Grid item key={index} xs={12} md={gridItemSize}>
                    <DailyGoalCard completed={dailyGoal.goal.completed}>
                        {dailyGoal?.component}
                    </DailyGoalCard>
                </Grid>
            ))}
        </Grid>
    )
}

export default memo(DailyGoalsList)
