import { Box } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

export type HiddenStatusUpdateProps = {
    id?: string
    status: React.ReactNode
}

const HiddenStatusUpdate: React.FC<HiddenStatusUpdateProps> = ({
    id,
    status
}) => {
    const theme = useTheme()

    return (
        <Box
            id={id}
            component="output"
            aria-live="polite"
            aria-atomic="true"
            sx={{
                top: 0,
                left: theme.spacing(-0.25),
                width: theme.spacing(0.125),
                height: theme.spacing(0.125),
                position: 'absolute',
                overflow: 'hidden'
            }}
            data-testid="hidden-status-update"
        >
            {status}
        </Box>
    )
}

export default React.memo(HiddenStatusUpdate)
