import { Skeleton } from '@mui/material'
import React, { memo, useMemo } from 'react'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import { useCompanyChallengeSocialGroupContext } from '../../../../hooks/withCompanyChallengeSocialGroupProvider'
import SocialGroupsList from './SocialGroupsList'

export interface SocialGroupsListContextProps extends JSX.IntrinsicAttributes {
    userSocialGroups?: SocialGroup[]
    joinedSocialGroups?: string[]
    excludeSocialGroups?: string[]
    joinGroupQueryActive: boolean
    searchTerm: string
    activeById: string
    disableNavigate?: boolean
    maxCapacity?: number
    isJoinList?: boolean
    addToJoinList: (socialGroup: SocialGroup, join: boolean) => void
    setEmpty?: (empty: boolean) => void
}

const SocialGroupsListContext: React.FC<SocialGroupsListContextProps> = ({
    userSocialGroups,
    joinedSocialGroups,
    excludeSocialGroups,
    joinGroupQueryActive,
    searchTerm,
    activeById,
    disableNavigate,
    maxCapacity,
    isJoinList = false,
    addToJoinList,
    setEmpty
}) => {
    const { loading, companyGroups } = useCompanyChallengeSocialGroupContext()

    const publicCompanyGroups = useMemo(
        () => companyGroups?.filter((group) => group?.isPublic),
        [companyGroups]
    )

    return loading ? (
        <Skeleton variant="rounded" height="100%" width="100%" />
    ) : (
        <SocialGroupsList
            userSocialGroups={userSocialGroups}
            joinedSocialGroups={joinedSocialGroups}
            allSocialGroups={publicCompanyGroups}
            isJoinList={isJoinList}
            addToJoinList={addToJoinList}
            excludeSocialGroups={excludeSocialGroups}
            joinGroupQueryActive={joinGroupQueryActive}
            searchTerm={searchTerm}
            activeById={activeById}
            disableNavigate={disableNavigate}
            maxCapacity={maxCapacity}
            setEmpty={setEmpty}
        />
    )
}

export default memo(SocialGroupsListContext)
