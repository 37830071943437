import { Box, Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import CommunityPlant1 from '../../../../shared/components/icons/plants/communityPlant1'
import CommunityPlant2 from '../../../../shared/components/icons/plants/communityPlant2'
import CommunityPlant3 from '../../../../shared/components/icons/plants/communityPlant3'
import PlantIconSkeleton from '../../CompanyChallenge/ChallengePlantCard/PlantIconSkeleton'

const ChallengeTodaysProgressSkeleton: React.FC = () => {
    const { breakpoints, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <Card elevation={0}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={3}
                p={2}
                sx={{ background: palette.grey[100] }}
            >
                <Stack gap={3}>
                    <Skeleton
                        variant="rounded"
                        height={14}
                        width={200}
                        sx={{ maxWidth: '100%' }}
                    />
                    <Skeleton
                        variant="rounded"
                        height={18}
                        width={291}
                        sx={{ maxWidth: '100%' }}
                    />
                </Stack>
                <Box maxWidth={275} width="100%">
                    <Stack
                        height="100%"
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        <PlantIconSkeleton PlantComponent={CommunityPlant1} />
                        <PlantIconSkeleton PlantComponent={CommunityPlant2} />
                        <PlantIconSkeleton PlantComponent={CommunityPlant3} />
                    </Stack>
                </Box>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeTodaysProgressSkeleton)
