import { store } from '@thriveglobal/thrive-web-core'
import { Image } from '../../../graphql/generated/autogenerated'

const resetApi = process.env.RESET_API
const endpoint = `${resetApi}image`

// GraphQL does not support uploading files
// Uploading needs to be done through REST API
export default async function uploadStorySubmissionImage(
    file: File
): Promise<Image> {
    const accessToken = store.getState()?.auth?.accessToken
    const contentType = file?.type
    const fetchOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': contentType,
            Authorization: `Bearer ${accessToken}`
        }),
        body: file
    }
    const result = await fetch(endpoint, fetchOptions)

    return result.json() as Promise<Image>
}
