import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { matchPath, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../../../routes'
import StorySubmission from '../StorySubmission'

export type StorySubmissionButtonProps = {
    variant?: 'text' | 'contained'
    endIcon?: React.ReactNode
    maxWidth?: number
    onSuccess?: () => void
}

const StorySubmissionButton: React.FC<StorySubmissionButtonProps> = ({
    variant,
    endIcon,
    maxWidth,
    onSuccess
}) => {
    const location = useLocation()
    const [open, setOpen] = useState(
        matchPath(
            location.pathname,
            ROUTES.SINGLE_PAST_CHALLENGES_STORY_SUBMISSION
        )?.isExact || false
    )

    return (
        <>
            <Button
                variant={variant ? variant : 'text'}
                endIcon={endIcon}
                fullWidth
                sx={{ maxWidth: maxWidth ? maxWidth : 300 }}
                onClick={() => {
                    setOpen(true)
                }}
            >
                <CoreTypography customVariant="buttonNormal">
                    <FormattedMessage
                        defaultMessage="Write your story"
                        description="write your story button"
                    />
                </CoreTypography>
            </Button>

            <StorySubmission
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                onSuccess={() => {
                    setOpen(false)
                    onSuccess?.()
                }}
            />
        </>
    )
}

export default memo(StorySubmissionButton)
