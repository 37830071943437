import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCard from '../WaveCard'

const messages = defineMessages({
    totalWater: {
        defaultMessage: 'Total glasses of water',
        description: 'total water title'
    }
})

type CommunityWaterTotalProps = {
    challengeTotalWater?: number
    loading: boolean
}

const CommunityWaterTotal: React.FC<CommunityWaterTotalProps> = ({
    challengeTotalWater,
    loading
}) => {
    const { formatMessage } = useIntl()

    return (
        <WaveCard title={formatMessage(messages.totalWater)} loading={loading}>
            <ConditionalSkeleton showSkeleton={loading} variant="rounded">
                <CoreTypography
                    variant="h3"
                    fontSize={60}
                    color="text.secondary"
                >
                    {challengeTotalWater}
                </CoreTypography>
            </ConditionalSkeleton>
        </WaveCard>
    )
}

export default memo(CommunityWaterTotal)
