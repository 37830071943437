import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant12: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#52A185',
    colorLayer2 = '#1B3237',
    colorLayer3 = '#365D67',
    colorLayer4 = '#94CAD0',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M91.3699 16.0238C91.3699 16.0238 63.1599 36.3338 56.1499 44.8338C56.1499 44.8338 52.8499 30.8538 64.2199 22.7538C73.6699 16.0238 78.6499 15.7938 91.3599 16.0238H91.3699Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.75"
                d="M56.39 34.3738L63.59 37.6338C65.46 36.0138 67.5099 34.3038 69.6299 32.5938L64.89 22.3038C64.67 22.4538 64.4599 22.5938 64.2399 22.7538C59.5499 26.0938 57.36 30.4338 56.4 34.3738H56.39Z"
                fill={colorLayer2}
            />
            <path
                d="M46.4099 50.6238L9.81995 20.0138C9.81995 20.0138 31.44 18.9138 39.28 27.4538C48.71 37.7238 46.4199 50.6238 46.4199 50.6238H46.4099Z"
                fill={colorLayer3}
            />
            <path
                d="M48.33 31.4938C48.84 36.7538 49.0199 42.0438 49.0699 47.3338C47.1299 57.9138 46.5 68.6938 45.83 79.3938C45.72 81.1238 45.62 82.8738 45.52 84.6138H46.9C47.51 71.3138 48.8299 57.9338 50.8599 44.7938C51.4199 41.7238 52.0299 38.5438 52.9399 35.5638L53.4 34.0638L53.9699 32.6038C54.3299 31.7438 54.8499 30.6938 55.4799 30.0638L55.2 29.5338C53.81 30.6938 53.3299 32.2738 52.5999 33.7538C51.4899 36.5738 50.7 39.5638 50.02 42.5338C49.88 38.8138 49.6499 35.0938 49.2599 31.3938C48.5399 26.0138 47.91 20.1738 45.33 15.4138L44.53 15.4538C44.56 15.6638 44.7099 15.9038 44.7999 16.0838C47.0999 20.8738 47.6699 26.2738 48.2999 31.4938H48.33Z"
                fill={colorLayer3}
            />
            <path
                opacity="0.75"
                d="M52.5999 36.8138C52.7199 36.3938 52.82 35.9738 52.95 35.5638L53.4099 34.0638L53.9799 32.6038C54.3399 31.7438 54.8599 30.6938 55.4899 30.0638L55.21 29.5338C53.82 30.6938 53.3399 32.2738 52.6099 33.7538C52.2599 34.6338 51.9599 35.5338 51.6699 36.4338C51.9799 36.5538 52.28 36.6838 52.59 36.8038L52.5999 36.8138Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.75"
                d="M48.33 31.4938C48.45 32.7238 48.5499 33.9538 48.6299 35.1938C48.9699 35.3338 49.3099 35.4738 49.6599 35.6138C49.5499 34.2038 49.43 32.7938 49.28 31.3938C48.56 26.0138 47.9299 20.1738 45.3499 15.4138L44.5499 15.4538C44.5799 15.6638 44.7299 15.9038 44.8199 16.0838C47.1199 20.8738 47.6899 26.2738 48.3199 31.4938H48.33Z"
                fill={colorLayer2}
            />
            <path
                d="M43.09 6.58378C43.09 6.58378 36.1599 -1.30623 29.4099 3.81377C22.6599 8.92377 26.3899 14.0038 35.9699 18.6338C45.5599 23.2738 61 38.3538 61 38.3538C61 38.3538 59.75 25.5538 59.39 18.9538C59.03 12.3538 59.0299 -0.0962285 49.9199 0.673771C43.5299 1.21377 43.0999 6.58378 43.0999 6.58378H43.09Z"
                fill={colorLayer1}
            />
            <path
                d="M29.4099 3.81376C22.6599 8.92377 25.76 14.5538 34.3 20.7938C38.92 24.1738 61 38.3538 61 38.3538L43.09 6.59376C43.09 6.59376 36.1599 -1.29624 29.4099 3.82376V3.81376Z"
                fill={colorLayer4}
            />
            <path
                d="M51.45 21.4238L43.2199 5.86377C43.1099 6.30377 43.08 6.58377 43.08 6.58377C43.08 6.58377 42.88 6.35376 42.52 6.01376L51.45 21.4238Z"
                fill={colorLayer3}
            />
            <path
                d="M84.21 58.3938C83.84 58.7038 44.21 54.8438 54.78 48.3638C60.31 44.9738 63.9199 45.8538 63.9199 45.8538C63.9199 45.8538 67.1399 42.1238 71.8599 42.9538C79.0199 44.2138 84.21 58.3838 84.21 58.3838V58.3938Z"
                fill={colorLayer3}
            />
            <path
                opacity="0.75"
                d="M71.8599 42.9638C67.1399 42.1338 63.9199 45.8638 63.9199 45.8638L84.21 58.3938C84.21 58.3938 79.0099 44.2238 71.8599 42.9638Z"
                fill={colorLayer2}
            />
            <path
                d="M71.2599 58.9938C77.9299 63.3138 73.7499 73.1138 72.0099 80.6638L62.21 60.2638C62.21 60.2638 67.8099 56.7538 71.2599 58.9838V58.9938Z"
                fill={colorLayer3}
            />
            <path
                d="M62.21 60.2738C62.21 60.2738 59.8499 58.1438 54.0399 60.1338C42.9399 63.9438 71.59 80.8838 72.01 80.6638L62.21 60.2738Z"
                fill={colorLayer1}
            />
            <path
                d="M25.59 63.0638L24.9899 62.7938C25.2199 62.2938 30.65 50.4438 38.34 49.8338C42.09 49.5438 45.6999 51.9938 49.0599 57.1238L48.6099 58.5738C45.3799 53.6538 41.89 50.2038 38.39 50.4838C31.09 51.0538 25.64 62.9438 25.59 63.0638Z"
                fill={colorLayer3}
            />
            <path
                d="M31.53 53.4538C31.53 53.4538 25.89 47.1538 18.7 51.6338C11.51 56.1138 11.2399 85.6438 11.2399 85.6438C11.2399 85.6438 16.4299 78.5138 25.0599 73.7338C33.6899 68.9538 44.6999 59.2138 40.3499 54.9038C36.5699 51.1538 31.5399 53.4638 31.5399 53.4638L31.53 53.4538Z"
                fill={colorLayer1}
            />
            <path
                d="M11.2399 85.6338C11.2399 85.6338 20.3399 79.8138 27.4299 73.8638C36.2699 66.4538 44.69 59.2038 40.34 54.8938C36.56 51.1438 31.53 53.4538 31.53 53.4538L11.2399 85.6338Z"
                fill={colorLayer3}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant12
