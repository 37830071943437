import { Box, Button, DialogTitle, Grid, Stack } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import {
    FormattedDateTimeRange,
    FormattedMessage,
    defineMessages,
    useIntl
} from 'react-intl'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity,
    UnifiedChallengeParticipation,
    Visibility
} from '../../../../../graphql/generated/autogenerated'
import { useStorySubmissionContext } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider'
import { useInterstitialNavigation } from '../../../../contexts/withInterstitialNavigation'
import { ChallengeResults } from '../../../../../shared/'
import TermsAndConditions from '../../../../../shared/components/elements/TermsAndConditions/TermsAndConditions'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'
import GetFeatured from '../GetFeatured'

export type ChooseStoryTypeProps = {
    participation?: UnifiedChallengeActivity
    challenge?: UnifiedChallenge
}

const messages = defineMessages({
    writeYourStory: {
        defaultMessage: 'Write your Story',
        description: 'choose story page title'
    },
    reflecting: {
        defaultMessage:
            'Reflecting on your experience is an important step as you close out your Challenge. How do you feel? What new habits have you formed?',
        description: 'description on the importance of submitting a story'
    },
    specifics: {
        defaultMessage:
            "Congratulations on completing your Thrive Challenge! Please answer as many of the following questions as you'd like to share your story in your own words. The more specifics and details you can share, the better.",
        description:
            'description on the detail you should go into when submitting a story'
    },
    start: {
        defaultMessage: 'Start',
        description:
            'Button text that will start the completed challenge story process'
    },
    yourResult: {
        defaultMessage: 'Your Result',
        description:
            'title text that is shown above the finished results of a completed challenge'
    },
    walmartSubmitEntry: {
        defaultMessage: 'Submit your entry for a chance to win cash prizes',
        description:
            'description text shown that tells the user they can win a cash prize for submitting a story with their completed challenge'
    }
})

const ChooseStoryType: React.FC<ChooseStoryTypeProps> = ({
    participation,
    challenge
}) => {
    const { spacing, palette, breakpoints } = useTheme()
    const { formatMessage } = useIntl()
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)
    const showTakedaCompanyChallengeCopy = useIsFeatureEnabled(
        'takedaStorySubmission',
        false,
        true
    ) as boolean

    const { next } = useInterstitialNavigation()
    const { title, subTitle, termsAndConditionsUrl } =
        useStorySubmissionContext()
    const { visibility, onVisibility } = useStorySubmissionStateProvider()

    const onStoryTypeChange = useCallback(
        (event: any) =>
            onVisibility(
                event.target.checked ? Visibility.Public : Visibility.Private
            ),
        [onVisibility]
    )

    const formattedDateRange = useMemo(
        () => (
            <FormattedDateTimeRange
                from={new Date(participation?.join)}
                to={new Date(participation?.completedOn)}
                month="2-digit"
                day="2-digit"
                year="numeric"
            />
        ),
        [participation]
    )

    return (
        <Stack
            data-testid="choose-story-type"
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                color: palette.primary.main,
                mt: 2,
                pt: 22,
                [breakpoints.down('md')]: {
                    pt: 3
                }
            }}
        >
            <Stack maxWidth={spacing(133.5)} gap={2}>
                <DialogTitle
                    sx={{
                        p: 0
                    }}
                >
                    <CoreTypography variant="h1">
                        {title ? title : formatMessage(messages.writeYourStory)}
                    </CoreTypography>
                </DialogTitle>
                {isWalmartPrizeChallenge && (
                    <CoreTypography variant="h5">
                        {formatMessage(messages.walmartSubmitEntry)}
                        <TermsAndConditions
                            challenge={challenge}
                            termsAndConditionsUrl={termsAndConditionsUrl}
                        />
                    </CoreTypography>
                )}
                <CoreTypography id="story-reflecting" variant="h5">
                    {subTitle
                        ? subTitle
                        : formatMessage(
                              isWalmartPrizeChallenge ||
                                  showTakedaCompanyChallengeCopy
                                  ? messages.specifics
                                  : messages.reflecting
                          )}
                </CoreTypography>
                {showTakedaCompanyChallengeCopy &&
                    challenge?.challenge_type === ChallengeType.company && (
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                defaultMessage="In addition to those who grow the most plants, we'll also be celebrating one Challenge participant who submits their inspiring well-being story. Share your story for a chance to win!"
                                description="share your story description"
                            />
                        </CoreTypography>
                    )}
                <Grid container>
                    <Grid
                        item
                        md={6}
                        sx={{
                            [breakpoints.down('md')]: {
                                width: '100%'
                            }
                        }}
                    >
                        {!isWalmartPrizeChallenge && (
                            <GetFeatured
                                checked={visibility === Visibility.Public}
                                onStoryTypeChange={onStoryTypeChange}
                            />
                        )}
                        <Box
                            sx={{
                                [breakpoints.down('md')]: {
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    p: spacing(2, 5),
                                    borderRadius: spacing(1),
                                    mb: 3,
                                    mt: 6,
                                    mx: 'auto'
                                }}
                                data-testid="start-story-submission-button"
                                onClick={next}
                                aria-label={formatMessage(messages.start)}
                                aria-describedby="story-reflecting"
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    {formatMessage(messages.start)}
                                </CoreTypography>
                            </Button>
                        </Box>
                    </Grid>

                    {challenge?.challenge_type !== ChallengeType.company &&
                        challenge?.challenge_type !== ChallengeType.group && (
                            <Grid
                                item
                                md={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    [breakpoints.down('md')]: {
                                        justifyContent: 'center',
                                        width: '100%',
                                        mb: 3
                                    }
                                }}
                            >
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        maxWidth: spacing(51.625),
                                        p: 3,
                                        borderRadius: spacing(1),
                                        backgroundColor: `${palette.accent.main}10`,
                                        height: 'fit-content'
                                    }}
                                >
                                    <CoreTypography
                                        customVariant="buttonNormal"
                                        sx={{
                                            mb: 2,
                                            color: palette.primary.main
                                        }}
                                    >
                                        {challenge?.name}
                                    </CoreTypography>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            color: palette.primary.main,
                                            backgroundColor:
                                                palette.background.paper,
                                            outline: 'none',
                                            border: 'none',
                                            padding: spacing(0.5, 1.25),
                                            borderRadius: spacing(0.5),
                                            width: 'fit-content',
                                            mb: 4
                                        }}
                                    >
                                        <CoreTypography customVariant="buttonNormal">
                                            {formattedDateRange}
                                        </CoreTypography>
                                    </Box>
                                    <CoreTypography
                                        customVariant="stat3"
                                        sx={{
                                            mb: 2,
                                            color: palette.primary.main
                                        }}
                                    >
                                        {formatMessage(messages.yourResult)}
                                    </CoreTypography>
                                    <ChallengeResults
                                        challengeParticipation={
                                            {
                                                challenge,
                                                participation: [participation]
                                            } as UnifiedChallengeParticipation
                                        }
                                        hideTitle={true}
                                    />
                                </Stack>
                            </Grid>
                        )}
                </Grid>
            </Stack>
        </Stack>
    )
}

export default ChooseStoryType
