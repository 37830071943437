import { Stack, Typography, Paper, Button, Box } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import gridEmptyState from '../../../../assets/cardPlants/gridEmptyState.png'

type ChallengesEmptyStateProps = {
    title: string | React.ReactNode
    description: string | React.ReactNode
    ctaLabel: string | React.ReactNode
    ctaOnClick: () => void
}

const ChallengesEmptyState = ({
    title,
    description,
    ctaLabel,
    ctaOnClick
}: ChallengesEmptyStateProps) => {
    return (
        <Paper
            sx={{
                boxShadow: 'none',
                border: 'none',
                padding: 6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    minWidth: '220px',
                    minHeight: '160px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img
                    src={gridEmptyState}
                    alt="Empty State"
                    style={{
                        objectFit: 'contain',
                        display: 'block'
                    }}
                />
            </Box>
            <Stack gap={1} alignItems="center">
                <Typography variant="h3">{title}</Typography>
                <Typography variant="h5">{description}</Typography>
            </Stack>
            <Button size="large" variant="contained" onClick={ctaOnClick}>
                <CoreTypography customVariant="buttonLarge">
                    {ctaLabel}
                </CoreTypography>
            </Button>
        </Paper>
    )
}

export default ChallengesEmptyState
