import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

const EcoIcon: React.FC<SvgIconProps> = (props) => {
    const theme = useTheme()
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 21 22'}
            sx={{ height: 21, width: 22 }}
            fill={props.fill}
        >
            <path
                d="M5.29376 7.54375C2.90501 9.9325 2.90501 13.8 5.27626 16.1888C6.56251 13.2137 8.85501 10.7288 11.7163 9.25C9.29251 11.2975 7.59501 14.1587 7.00001 17.405C9.27501 18.4812 12.075 18.0875 13.9563 16.2063C17.0013 13.1613 17.5 4 17.5 4C17.5 4 8.33876 4.49875 5.29376 7.54375Z"
                fill={props.fill || theme.palette.grey[400]}
            />
        </SvgIcon>
    )
}

export default EcoIcon
