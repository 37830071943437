import { IconName } from '@fortawesome/fontawesome-common-types'
import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UnifiedChallenge } from '../../../graphql/generated/autogenerated'
import { CHALLENGE_VARIANT } from '../../constants'

const messages = defineMessages({
    companyChallenge: {
        defaultMessage: 'Company Challenge',
        description: 'description for company challenge'
    },
    groupChallenge: {
        defaultMessage: 'Group Challenge',
        description: 'description for group challenge'
    },
    personalChallenge: {
        defaultMessage: 'Personal Challenge',
        description: 'description for personal challenge'
    },
    privateChallenge: {
        defaultMessage: 'Private Challenge',
        description: 'description for private challenge'
    }
})

interface IconConfig {
    icon: string
    title: keyof typeof messages
}

const CHALLENGE_TYPE_CONFIG: Record<string, IconConfig> = {
    [CHALLENGE_VARIANT.COMPANY]: {
        icon: 'buildings',
        title: 'companyChallenge'
    },
    [CHALLENGE_VARIANT.GROUP]: { icon: 'users', title: 'privateChallenge' },
    [CHALLENGE_VARIANT.JOURNEY]: { icon: 'user', title: 'personalChallenge' },
    [CHALLENGE_VARIANT.BIOTYPE]: { icon: 'user', title: 'personalChallenge' },
    [CHALLENGE_VARIANT.PRIZE]: { icon: 'user', title: 'personalChallenge' },
    [CHALLENGE_VARIANT.PERSONAL]: { icon: 'user', title: 'personalChallenge' }
}

const DEFAULT_CONFIG: IconConfig = {
    icon: 'users',
    title: 'privateChallenge'
}

export const useChallengeTypeIcon = (challenge?: UnifiedChallenge) => {
    const { formatMessage } = useIntl()
    const { palette } = useTheme()

    return useMemo(() => {
        const config =
            CHALLENGE_TYPE_CONFIG[challenge?.challenge_type ?? ''] ??
            DEFAULT_CONFIG

        return {
            icon: (
                <LeafIcon
                    fontSize="small"
                    iconStyle="solid"
                    icon={config.icon as IconName}
                    sx={{ color: palette.text.secondary }}
                />
            ),
            title: formatMessage(messages[config.title])
        }
    }, [challenge?.challenge_type, formatMessage, palette.text.secondary])
}
