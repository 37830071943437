import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useGoalChallengeDataProviderContext } from '../../../../contexts/withGoalChallenges/useGoalChallengeData/useGoalChallengeDataProviderContext'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCard from '../WaveCard'

const messages = defineMessages({
    totalWater: {
        defaultMessage: 'Your total glasses of water',
        description: 'total water title'
    }
})

interface UserWaterTotalProps {
    userTotalWater?: number
    loading: boolean
    error: boolean
}

const UserWaterTotal: React.FC<UserWaterTotalProps> = ({
    userTotalWater,
    loading,
    error
}) => {
    const { formatMessage } = useIntl()

    return (
        <>
            {!error && (
                <WaveCard
                    title={formatMessage(messages.totalWater)}
                    loading={loading}
                >
                    <ConditionalSkeleton
                        showSkeleton={loading}
                        variant="rounded"
                    >
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            color="text.secondary"
                        >
                            {userTotalWater}
                        </CoreTypography>
                    </ConditionalSkeleton>
                </WaveCard>
            )}
        </>
    )
}

export default memo(UserWaterTotal)
