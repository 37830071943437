import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack
} from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'

export interface RadioListItem {
    title: string
    description?: any
    value: any
}

export interface RadioListProps {
    list: RadioListItem[]
    defaultValue?: any
    value?: any
    excludeSpacing?: boolean
    row?: boolean
    onChange: (value: any) => void
}

const radioStyles = {
    '& svg': {
        width: '1.5em',
        height: '1.5em'
    }
}

const RadioList: React.FC<RadioListProps> = ({
    list,
    defaultValue,
    value,
    excludeSpacing,
    row,
    onChange
}) => {
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(_, value) => onChange(value)}
                {...{ defaultValue }}
                {...{ value }}
                row={row}
            >
                {list.map((listItem, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={listItem.value}
                        control={<Radio sx={radioStyles} />}
                        sx={{
                            alignItems: 'center',
                            ml: 0,
                            ...(!excludeSpacing && {
                                mb: 3
                            })
                        }}
                        label={
                            <Stack ml={1} alignSelf="center">
                                <CoreTypography customVariant="form">
                                    {listItem.title}
                                </CoreTypography>
                                {listItem.description && (
                                    <CoreTypography variant="body2">
                                        {listItem.description}
                                    </CoreTypography>
                                )}
                            </Stack>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export default memo(RadioList)
