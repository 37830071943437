import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { SignUpNavigationControls, LearnMore } from '..'
import { SignUpSteps } from '../../../../../enums'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useInterstitialNavigation } from '../../../../../hooks/withInterstitialNavigation'

export interface SignUpNavigationProps {
    onBack: () => void
    onNext: (responses?: unknown[]) => Promise<void>
    activeStep: number
    totalSteps: number
    nextButtonText?: string
    showBackButton?: boolean
    children: React.ReactNode
}

const SignUpNavigation: React.FC<SignUpNavigationProps> = ({
    onBack,
    onNext,
    activeStep,
    totalSteps,
    nextButtonText,
    showBackButton,
    children
}) => {
    const theme = useTheme()

    const { clearDisables, clearMutations } =
        useCompanyChallengeSignUpProviderContext()
    const { activeKey, navigateToStep } = useInterstitialNavigation()

    useEffect(() => {
        clearDisables?.()
        clearMutations()
        navigateToStep(0)
    }, []) // eslint-disable-line

    const excludedSignUpTypes: SignUpSteps[] = [
        SignUpSteps.confirm,
        SignUpSteps.sign
    ]

    return (
        <>
            <DialogContent sx={{ height: '100%', overflow: 'hidden' }}>
                <Stack flex={1} gap={5} height="100%" mt={2} mb={1}>
                    <Stack height="100%">
                        {!excludedSignUpTypes.includes(
                            activeKey as SignUpSteps
                        ) && (
                            <Stack>
                                <CoreTypography
                                    variant="overline"
                                    textAlign="start"
                                    color="accent.main"
                                    component="output"
                                    aria-live="polite"
                                    aria-atomic="true"
                                >
                                    <FormattedMessage
                                        defaultMessage={
                                            'Step {activeStep} of {totalSteps}'
                                        }
                                        description="Description of the what the current step the user is in for the company challenge sign up process E.g Step 2 of 4"
                                        values={{ activeStep, totalSteps }}
                                    />
                                </CoreTypography>
                            </Stack>
                        )}
                        <Stack
                            gap={2.5}
                            width="100%"
                            height="100%"
                            alignItems="center"
                        >
                            <Stack width="100%" height="100%">
                                {children}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    sx={{
                        pt: theme.spacing(2),
                        borderTop: `2px solid ${theme.palette.divider}`
                    }}
                >
                    <LearnMore />
                    <Box>
                        <SignUpNavigationControls
                            onBack={onBack}
                            onNext={onNext}
                            nextButtonText={nextButtonText}
                            showBackButton={showBackButton}
                        />
                    </Box>
                </Box>
            </DialogActions>
        </>
    )
}

export default memo(SignUpNavigation)
