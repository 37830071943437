import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    SocialGroup,
    useCompanyChallengeSocialGroupsQuery
} from '../../graphql/generated/autogenerated'
import CompanyChallengeSocialGroupProviderContext, {
    CompanyChallengeSocialGroupProviderContextValue
} from './CompanyChallengeSocialGroupProviderContext'
import { stripBOM } from '../../shared/utils/bom'

export default function withCompanyChallengeSocialGroupProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const { challengeId, socialGroupId } = useParams<{
            challengeId: string
            socialGroupId: string
        }>()

        const { loading, data, error, refetch } =
            useCompanyChallengeSocialGroupsQuery({
                variables: {
                    challengeId: stripBOM(challengeId)
                }
            })

        const {
            soloGroup,
            mainGroup,
            socialGroup,
            companyGroups,
            displayName
        } = useMemo(
            () => ({
                soloGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.isSoloChallenge
                    ) as SocialGroup,
                mainGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.isMainCommunitySocialGroup
                    ) as SocialGroup,
                socialGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.id === socialGroupId
                    ) as SocialGroup,
                companyGroups:
                    data?.socialGroups?.companyChallengeSocialGroups?.filter(
                        (socialGroup) =>
                            !socialGroup.isSoloChallenge &&
                            !socialGroup.isMainCommunitySocialGroup
                    ) as SocialGroup[],
                displayName: data?.socialGroups?.displayName?.displayName
            }),
            [data, socialGroupId]
        )

        const state = useMemo<CompanyChallengeSocialGroupProviderContextValue>(
            () => ({
                loading,
                soloGroup,
                mainGroup,
                socialGroup,
                companyGroups,
                displayName: displayName,
                error: Boolean(error),
                refetch
            }),
            [
                loading,
                soloGroup,
                mainGroup,
                socialGroup,
                companyGroups,
                displayName,
                error,
                refetch
            ]
        )

        return (
            <CompanyChallengeSocialGroupProviderContext.Provider value={state}>
                <Component {...props} />
            </CompanyChallengeSocialGroupProviderContext.Provider>
        )
    }
}
