import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

const SocialGroupsHomeContentFallback = () => {
    return (
        <Stack>
            <CoreTypography>
                <FormattedMessage
                    defaultMessage="We can't load your social groups. Try again later."
                    description="Error when we can't load the social groups section"
                />
            </CoreTypography>
        </Stack>
    )
}

export default SocialGroupsHomeContentFallback
