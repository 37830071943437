import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { MeasureUnit } from '../../../../../../../graphql/generated/autogenerated'
import {
    DailyGoalMeasurement,
    DailyGoalTracker
} from '../../../../Shared/DailyGoals/Goals'

type ConfirmHydrationProps = {
    goalCount?: number
    intakeMeasurement: MeasureUnit
    setIntakeMeasurement: (value: MeasureUnit) => void
    setGoalCount: (value: number) => void
}

const ConfirmHydrationStep: React.FC<ConfirmHydrationProps> = ({
    goalCount,
    setGoalCount,
    intakeMeasurement,
    setIntakeMeasurement
}) => {
    return (
        <Stack gap={2}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Set your daily water goal for the challenge"
                    description="title for the confirm hydration page"
                />
            </CoreTypography>
            <Box width="100%">
                <DailyGoalMeasurement
                    measurement={intakeMeasurement}
                    setMeasurement={setIntakeMeasurement}
                />
            </Box>
            <Box width="100%">
                <DailyGoalTracker
                    newGoal={goalCount ?? 0}
                    setNewGoal={setGoalCount}
                    measurement={intakeMeasurement}
                />
            </Box>
            <CoreTypography variant="body2">
                <FormattedMessage
                    defaultMessage="Thrive recommends starting with 8 glasses per day. Don't worry, you'll be able to update your daily goal at any time."
                    description="subtitle for the confirm hydration page"
                />
            </CoreTypography>
        </Stack>
    )
}

export default memo(ConfirmHydrationStep)
