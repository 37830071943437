import { Button, Card, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

const CreateResetBanner: React.FC = () => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const history = useHistory()

    const handleNavigate = useCallback(() => {
        history.push('/reset/new')
    }, [history])

    return (
        <Card elevation={0}>
            <Stack
                p={2}
                gap={2}
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-end' : 'center'}
                justifyContent="space-between"
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <LeafIcon icon="medal" fontSize="small" />
                    <CoreTypography customVariant="form">
                        <FormattedMessage
                            defaultMessage="Earn a special reward for your garden when you create a Personal Reset."
                            description="create reset title"
                        />
                    </CoreTypography>
                </Stack>
                <Button variant="outlined" onClick={handleNavigate}>
                    <CoreTypography customVariant="buttonLarge">
                        <FormattedMessage
                            defaultMessage="Create a Personal Reset"
                            description="button for routing to the personal reset page to create"
                        />
                    </CoreTypography>
                </Button>
            </Stack>
        </Card>
    )
}

export default CreateResetBanner
