import { useEffect, useMemo, useState } from 'react'
import {
    type GetMembersForSocialGroupQuery,
    type GetMembersForSocialGroupQueryVariables,
    type SocialGroup,
    type SocialGroupMember,
    useGetMembersForSocialGroupQuery
} from '../../../graphql/generated/autogenerated'
import usePagination from '../../../shared/hooks/usePagination/usePagination'

type UseSocialMemberProps = {
    socialGroupId: string
}

type UseSocialMemberValues = {
    displayName: string
    socialMembers: SocialGroupMember[]
    error: boolean
    loading: boolean
    loadingNext: boolean
    hasMore: boolean
    refetch: () => void
    fetchMore: () => void
}

export default function useGetSocialMembers({
    socialGroupId
}: UseSocialMemberProps): UseSocialMemberValues {
    const [displayName, setDisplayName] = useState('')

    const {
        data,
        queryData,
        loading,
        error,
        loadingNext,
        hasMore,
        handleLoadMore,
        refetch
    } = usePagination<
        SocialGroupMember,
        GetMembersForSocialGroupQuery,
        GetMembersForSocialGroupQueryVariables
    >({
        defaultOffset: 10,
        query: useGetMembersForSocialGroupQuery,
        skip: !socialGroupId,
        queryProps: {
            socialGroupId
        },
        dataTransform: (data) =>
            (data?.socialGroups?.socialGroup as SocialGroup)
                ?.paginatedMembers as SocialGroupMember[]
    })

    useEffect(() => {
        if (queryData?.socialGroups?.displayName?.displayName) {
            setDisplayName(queryData.socialGroups.displayName.displayName)
        }
    }, [queryData])

    return useMemo(
        () => ({
            displayName,
            socialMembers: data,
            error: Boolean(error),
            loading,
            loadingNext,
            hasMore,
            refetch,
            fetchMore: handleLoadMore
        }),
        [
            displayName,
            data,
            error,
            loading,
            loadingNext,
            hasMore,
            refetch,
            handleLoadMore
        ]
    )
}
