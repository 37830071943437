import React, { useMemo } from 'react'
import {
    ChallengeUserDailyGoal,
    GoalType,
    ResetGoalDetail,
    UnifiedChallenge,
    useGetResetsByIdsQuery
} from '../../../../../../../graphql/generated/autogenerated'
import ResetGoal from '.'

export type ResetGoalByQueryProps = {
    id: string
    type: GoalType
    challenge: UnifiedChallenge | undefined
}

const ResetGoalByQuery: React.FC<ResetGoalByQueryProps> = ({
    id,
    challenge,
    type
}) => {
    const { data, loading, error } = useGetResetsByIdsQuery({
        variables: {
            ids: [id]
        }
    })

    const goal = useMemo(() => {
        if (!data || loading) return undefined
        const reset = data?.reset?.thrive?.getByIds?.[0]
        return {
            challengeGoal: {
                goalType: type as string
            },
            goalEntityDetail: {
                resetInfo: {
                    ...reset
                } as ResetGoalDetail | unknown
            },
            completed: false
        }
    }, [data, loading, type])

    if (error) throw error

    return (
        <ResetGoal
            challenge={challenge}
            goal={goal as ChallengeUserDailyGoal}
            loading={loading}
            onStart={() => {}}
            onComplete={() => {}}
            disabled={true}
        />
    )
}

export default ResetGoalByQuery
