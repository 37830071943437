import { Button, InputAdornment, OutlinedInput, Stack } from '@mui/material'
import { useBranchReferralLink } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafCopyButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import { ShareContentOnTeamsBranchLink } from '@thriveglobal/thrive-web-ms-teams-app'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { GQLNull } from '../../../../shared/utils/Nulls'
import ConditionalSkeleton from '../ConditionalSkeleton'

export interface InviteInputProps {
    disabled?: boolean
    companyName?: string | GQLNull
    referrerName?: string | GQLNull
    redirectUrl: string
    ariaLabel: string
    onCopied?: () => void
    onShared?: () => void
}

const InviteInput: React.FC<InviteInputProps> = ({
    disabled,
    companyName,
    referrerName,
    redirectUrl,
    ariaLabel,
    onCopied,
    onShared
}) => {
    const { error, loading, coworkerReferral } = useBranchReferralLink(
        companyName as string,
        referrerName as string,
        redirectUrl
    )

    return (
        <Stack direction="row" gap={1}>
            <ConditionalSkeleton
                showSkeleton={loading}
                variant="rounded"
                sx={{
                    width: '100%',
                    maxWidth: '684px'
                }}
            >
                <OutlinedInput
                    disabled={disabled}
                    value={error ? redirectUrl : coworkerReferral.link}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    sx={{
                        width: '100%',
                        maxWidth: '684px'
                    }}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            data-testid="copy-button"
                        >
                            <LeafCopyButton
                                disabled={disabled}
                                ariaLabel={ariaLabel}
                                icon="copy"
                                linkBody={coworkerReferral.link}
                                toolTipPosition="right"
                                onCopied={onCopied}
                            />
                        </InputAdornment>
                    }
                />
            </ConditionalSkeleton>
            <ConditionalSkeleton showSkeleton={loading} variant="rounded">
                <ShareContentOnTeamsBranchLink
                    url={redirectUrl}
                    onShared={onShared}
                    companyName={companyName as string}
                    referrerName={referrerName as string}
                    button={
                        <Button
                            variant="outlined"
                            startIcon={
                                <LeafIcon icon="microsoft" iconStyle="brands" />
                            }
                            sx={{
                                minWidth: 'fit-content !important',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Share on Teams"
                                    description="share on teams button text"
                                />
                            </CoreTypography>
                        </Button>
                    }
                />
            </ConditionalSkeleton>
        </Stack>
    )
}

export default memo(InviteInput)
