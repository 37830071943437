import { Box, Button, Fade, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { JoinGroupButton } from '..'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import { useNavigateBack } from '../../../../hooks/useNavigateBack/useNavigateBack'

export type SocialGroupsHeaderProps = {
    socialGroups: SocialGroup[]
    displayName?: string
    displayNameRefetch: () => Promise<any>
    onJoinGroup?: () => Promise<any>
    onJoinGroupSuccess?: () => void
    beforeJoinGroup?: () => void
}

const SocialGroupsHeader: React.FC<SocialGroupsHeaderProps> = ({
    socialGroups,
    displayName,
    displayNameRefetch,
    onJoinGroup,
    onJoinGroupSuccess,
    beforeJoinGroup
}) => {
    const theme = useTheme()
    const onNavigateBack = useNavigateBack()

    return (
        <Stack
            sx={{
                borderBottom: `${theme.spacing(0.125)} solid ${
                    theme.palette.grey[300]
                }`,
                pt: theme.spacing(6)
            }}
            data-testid="social-groups-header"
        >
            <Button
                variant="text"
                onClick={onNavigateBack}
                data-testid="social-groups-challenges-link"
                sx={{
                    width: 'fit-content'
                }}
                startIcon={<LeafIcon icon={'arrow-left'} fontSize={'small'} />}
            >
                <CoreTypography customVariant="buttonNormal">
                    <FormattedMessage
                        defaultMessage="Back"
                        description="button text that will navigate the user back to the previous page"
                    />
                </CoreTypography>
            </Button>
            <Stack
                direction="row"
                sx={{
                    pt: theme.spacing(3)
                }}
            >
                <Stack
                    sx={{
                        flexGrow: 1
                    }}
                >
                    <CoreTypography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(2)
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Add or Create Groups to Join"
                            description="title text on the social groups list page"
                        />
                    </CoreTypography>
                    <CoreTypography
                        variant="body1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(4)
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Select a group from the list below or search for a group by name."
                            description="Describes that the user can select a social group item from a list below or use a search bar to search for a specific item"
                        />
                    </CoreTypography>
                </Stack>

                <Fade in={socialGroups?.length > 0}>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                my: 'auto'
                            },
                            [theme.breakpoints.down('md')]: {
                                position: 'fixed',
                                bottom: 0,
                                padding: theme.spacing(2.25),
                                width: '100%',
                                background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 45.89%)`,
                                mx: theme.spacing(-3),
                                paddingBottom: theme.spacing(2.25),
                                textAlign: 'center',
                                zIndex: 2
                            }
                        }}
                    >
                        <JoinGroupButton
                            socialGroups={socialGroups}
                            displayName={displayName}
                            displayNameRefetch={displayNameRefetch}
                            onJoinGroup={onJoinGroup}
                            beforeJoinGroup={beforeJoinGroup}
                            onJoinGroupSuccess={onJoinGroupSuccess}
                        />
                    </Box>
                </Fade>
            </Stack>
        </Stack>
    )
}

export default SocialGroupsHeader
