// React and related
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

// External libraries
import { Box, Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

// Components
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import GoalChallengeSettingsButton from '../../Shared/GoalChallenges/GoalChallengeSettings/GoalChallengeSettingsButton'
import GoalChallengeShowTutorial from '../../Shared/GoalChallenges/GoalChallengeNavBar/GoalChallengeShowTutorial'
import IconStat from '../../../elements/Stats/IconStat'
import InviteToChallengeModal from '../../Shared/InviteToChallenge/InviteToChallengeModal'

// Types and enums
import { ChallengeType } from '../../../../../shared/enums'
import {
    GroupChallengeType,
    type SocialGroup,
    type UnifiedChallenge,
    type UnifiedChallengeActivity,
    type Company
} from '../../../../../graphql/generated/autogenerated'

// Hooks and utils
import { isChallengeExpired } from '../../../../utils/challenge'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'
import useTutorialWatched from '../../../../hooks/useTutorialWatched/useTutorialWatched'
import type { SocialGroupsValues } from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import DisposableDialog from '../../../elements/DisposableDialog'

const messages = defineMessages({
    dayOf: {
        defaultMessage: `day {day} of {duration}`,
        description: 'total number of days in the challenge'
    }
})

interface RouteState {
    participationType: string
}

interface ChallengeHeaderProps {
    challenge?: UnifiedChallenge
    loading: boolean
    participation?: UnifiedChallengeActivity
    company: Company
    socialGroups: SocialGroupsValues
    refetchUnifiedChallengeAvailability: () => Promise<void>
}

const ChallengeHeader: React.FC<ChallengeHeaderProps> = ({
    challenge,
    loading,
    participation,
    company,
    socialGroups,
    refetchUnifiedChallengeAvailability
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { formatMessage, formatNumber } = useIntl()

    const {
        tutorialWatched,
        setTutorialWatched,
        loading: tutorialWatchedLoading
    } = useTutorialWatched()

    const dayOfChallenge = challenge?.dayOfChallenge
    const challengeExpired = isChallengeExpired(challenge)
    const location = useLocation<RouteState>()
    const participationType = location.state?.participationType
    const [openInviteModal, setOpenInviteModal] = useState(false)
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)
    const [showTutorialDialog, setShowTutorialDialog] = useState<boolean>(false)

    useEffect(() => {
        if (participationType === GroupChallengeType.Group) {
            setOpenInviteModal(true)
        }
    }, [participationType])

    const maxDay = useMemo(() => {
        if (dayOfChallenge && challenge) {
            return dayOfChallenge > challenge?.duration
                ? challenge?.duration
                : dayOfChallenge
        }
        return dayOfChallenge ?? 0
    }, [challenge, dayOfChallenge])

    const canShowInviteButton = useMemo(
        () =>
            challenge?.groupChallengeType === GroupChallengeType.Group ||
            challenge?.challenge_type === ChallengeType.company,
        [challenge?.groupChallengeType, challenge?.challenge_type]
    )

    useEffect(() => {
        if (
            !tutorialWatched &&
            !!participation &&
            !isWalmartPrizeChallenge &&
            !tutorialWatchedLoading
        ) {
            setShowTutorialDialog(true)
            setTutorialWatched?.()
        }
    }, [
        tutorialWatched,
        participation,
        setTutorialWatched,
        isWalmartPrizeChallenge,
        tutorialWatchedLoading
    ])

    return (
        <>
            <Card elevation={0}>
                <Stack
                    p={2}
                    direction={isMobile ? 'column' : 'row'}
                    gap={isMobile ? 1 : 3}
                >
                    <Box order={isMobile ? 2 : 1}>
                        <ConditionalSkeleton
                            showSkeleton={loading}
                            sx={{ width: 400, height: 45 }}
                        >
                            <CoreTypography
                                variant="h2"
                                component="h1"
                                fontSize={32}
                                sx={{
                                    whiteSpace: isMobile ? 'auto' : 'nowrap'
                                }}
                            >
                                {challenge?.name}
                            </CoreTypography>
                        </ConditionalSkeleton>
                    </Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={maxDay > 0 ? 'space-between' : 'end'}
                        width="100%"
                        order={isMobile ? 1 : 2}
                        gap={2}
                    >
                        {maxDay > 0 && (
                            <ConditionalSkeleton
                                showSkeleton={loading}
                                sx={{ height: 45 }}
                            >
                                <IconStat
                                    component="output"
                                    aria-live="polite"
                                    aria-atomic="true"
                                    variant="overline"
                                    color="text.primary"
                                    iconColor="text.primary"
                                    spacing={1}
                                    stat={formatMessage(messages.dayOf, {
                                        day: formatNumber(maxDay),
                                        duration: formatNumber(
                                            challenge?.duration ?? 0
                                        )
                                    })}
                                    icon={'calendar'}
                                />
                            </ConditionalSkeleton>
                        )}
                        {!challengeExpired && (
                            <Stack gap={1} direction="row">
                                {canShowInviteButton && (
                                    <Button
                                        variant="contained"
                                        onClick={() => setOpenInviteModal(true)}
                                    >
                                        <CoreTypography customVariant="buttonNormal">
                                            <FormattedMessage
                                                defaultMessage="Invite"
                                                description="invite a coworker to the company challenge button text"
                                            />
                                        </CoreTypography>
                                    </Button>
                                )}
                                <GoalChallengeSettingsButton
                                    challenge={challenge}
                                    socialGroups={socialGroups}
                                    participation={participation}
                                    refetchUnifiedChallengeAvailability={
                                        refetchUnifiedChallengeAvailability
                                    }
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Card>
            <DisposableDialog
                open={openInviteModal}
                Dialog={InviteToChallengeModal}
                onClose={() => setOpenInviteModal(false)}
                dialogProps={{
                    challenge,
                    company,
                    socialGroup: socialGroups.socialGroup
                }}
            />

            <GoalChallengeShowTutorial
                open={showTutorialDialog}
                onClose={() => setShowTutorialDialog(false)}
                challenge={challenge}
            />
        </>
    )
}

export default memo(ChallengeHeader)
