import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant9: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#81C254',
    colorLayer2 = '#37661D',
    colorLayer3 = '#53A354',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M16.546 18.1537C16.416 24.6537 16.5761 31.0937 17.7461 37.1937C18.3161 40.1637 18.946 43.1637 19.916 45.9637C22.496 53.4137 27.036 61.6837 33.826 66.3737C37.756 69.0837 42.916 70.1337 48.666 69.4337C51.256 69.1137 55.946 67.9737 58.536 66.7937C60.296 65.9937 59.856 63.6237 59.626 63.2337L22.066 0.783691C22.296 1.14369 16.666 12.1437 16.546 18.1537Z"
                fill={colorLayer1}
            />
            <path
                d="M39.986 4.0537C45.786 6.9837 51.406 10.1537 56.246 14.0437C58.606 15.9337 60.956 17.8937 62.976 20.0637C68.346 25.8337 73.526 33.7237 74.476 41.9237C75.026 46.6637 73.536 51.7137 70.226 56.4737C68.736 58.6137 65.526 62.2237 63.266 63.9537C61.736 65.1337 59.846 63.6337 59.616 63.2437L22.056 0.793686C22.266 1.17369 34.626 1.35369 39.986 4.06369V4.0537Z"
                fill={colorLayer1}
            />
            <path
                d="M60.986 64.1837L58.296 59.7237L70.846 30.9137C70.736 30.7037 70.626 30.4937 70.516 30.2937L70.106 30.1137L57.666 58.6737L46.646 40.4437L51.1561 30.1037C51.9061 29.4037 58.576 23.1037 60.866 17.9537C60.726 17.8237 60.596 17.7037 60.456 17.5737L60.026 17.3937C58.506 20.9437 54.566 25.2737 52.226 27.6537L57.666 15.1637C57.526 15.0537 57.386 14.9437 57.256 14.8237L56.816 14.6337L46.026 39.3937L36.986 24.4537L44.786 6.5537C44.496 6.3937 44.1961 6.2337 43.9061 6.0737L36.356 23.4037L22.756 0.913696C22.356 0.863696 22.116 0.813697 22.086 0.773697C22.086 0.773697 23.136 2.52369 24.896 5.4437C23.146 2.53369 22.086 0.773697 22.086 0.773697C22.116 0.823697 22.046 1.05369 21.896 1.43369L33.546 20.7037L16.566 19.3937L16.536 19.8437C16.536 20.0237 16.536 20.2137 16.536 20.3937L34.1861 21.7537L45.246 40.0437L18.046 37.9437L18.006 38.4237C18.036 38.6037 18.076 38.7737 18.106 38.9537L45.876 41.0937L56.9361 59.3837L48.226 58.7137C47.396 58.1337 42.336 54.6337 39.746 53.3637C36.956 51.9937 22.066 49.2737 21.426 49.1637L21.336 49.6437C21.416 49.8337 21.486 50.0137 21.576 50.2037C23.796 50.6137 36.846 53.0537 39.306 54.2637C41.136 55.1637 44.336 57.2737 46.236 58.5637L25.506 56.9637L25.466 57.4537C25.586 57.6337 25.706 57.8137 25.826 57.9937L57.576 60.4437L59.846 64.1937C59.816 63.7337 59.716 63.3637 59.636 63.2237C59.756 63.4237 60.296 63.9037 61.006 64.1737L60.986 64.1837Z"
                fill={colorLayer2}
            />
            <g opacity="0.75">
                <path
                    d="M65.856 23.3937C61.316 26.0737 56.956 28.9537 53.196 32.3337C51.176 34.1537 49.156 36.0337 47.446 38.0837C42.906 43.5337 38.6561 50.8837 38.2061 58.2937C37.9861 61.9537 39.1261 65.7437 41.4661 69.3237C43.7161 69.6837 46.1161 69.7337 48.6461 69.4337C50.9861 69.1537 55.0061 68.1937 57.6761 67.1437C58.2961 65.9637 58.966 64.7037 59.666 63.3737C59.646 63.3137 59.626 63.2637 59.606 63.2337C59.626 63.2637 59.6561 63.2937 59.6861 63.3337C63.7761 55.5837 68.986 45.7037 73.476 37.2137C71.956 32.2037 69.086 27.4437 65.846 23.3937H65.856Z"
                    fill={colorLayer2}
                />
            </g>
            <path
                d="M49.816 85.7837H53.286C55.806 74.5137 55.706 62.7637 53.116 51.5137L52.126 51.7137C53.956 62.9937 53.196 74.8637 49.816 85.7837Z"
                fill={colorLayer2}
            />
            <path
                d="M75.596 19.4837C70.526 22.4137 65.646 25.5337 61.486 29.2737C59.466 31.0937 57.446 32.9737 55.736 35.0237C51.196 40.4737 46.946 47.8237 46.496 55.2337C46.236 59.5237 47.836 63.9837 51.046 68.0837C52.496 69.9337 55.556 73.0137 57.676 74.4537C59.106 75.4337 60.736 73.9937 60.926 73.6337C60.926 73.6337 91.526 15.6437 91.526 15.6337C91.356 15.9837 80.266 16.7637 75.586 19.4737L75.596 19.4837Z"
                fill={colorLayer3}
            />
            <path
                d="M60.936 73.6437C60.936 73.6437 91.536 15.6537 91.536 15.6437C91.346 15.9837 60.746 74.0037 60.936 73.6437Z"
                fill={colorLayer1}
            />
            <path
                d="M52.596 83.0537L52.076 82.2037C52.136 82.1637 58.246 78.4137 59.586 75.4937L91.096 15.4237L91.976 15.8837L60.476 75.9337C59.016 79.1037 52.856 82.8937 52.596 83.0537Z"
                fill={colorLayer2}
            />
            <path
                d="M59.146 62.7037C59.216 61.6437 59.546 56.2837 59.316 53.7437C59.116 51.4537 55.426 41.1837 53.996 37.2537C53.726 37.6137 53.466 37.9737 53.206 38.3437C53.956 40.3337 58.106 51.4137 58.326 53.8337C58.486 55.5837 58.356 58.8337 58.256 60.9137L49.796 44.0137C49.606 44.3937 49.426 44.7737 49.246 45.1537L62.096 70.8437L62.546 70.6237C62.636 70.4537 62.736 70.2637 62.836 70.0837L59.1561 62.7237L59.146 62.7037Z"
                fill={colorLayer2}
            />
            <path
                d="M74.6861 20.0537L74.276 20.2637C74.116 20.3537 73.966 20.4437 73.806 20.5337L81.046 34.9037L81.496 34.6837C81.596 34.5037 81.686 34.3237 81.786 34.1437L74.6861 20.0537Z"
                fill={colorLayer2}
            />
            <path
                d="M61.486 29.2737C61.076 29.6437 60.656 30.0237 60.246 30.4037L71.566 52.8637L72.016 52.6437C72.116 52.4637 72.206 52.2737 72.306 52.0937L61.486 29.2737Z"
                fill={colorLayer2}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant9
