import { Card, Grid, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Image from '../../../../../components/elements/Image/Image'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import introPlant1 from '../../../../assets/cardPlants/introPlant1.png'
import introPlant2 from '../../../../assets/cardPlants/introPlant2.png'
import introPlant3 from '../../../../assets/cardPlants/introPlant3.png'
import introPlant4 from '../../../../assets/cardPlants/introPlant4.png'
import introPlant5 from '../../../../assets/cardPlants/introPlant5.png'
import introPlant6 from '../../../../assets/cardPlants/introPlant6.png'
import introPlant7 from '../../../../assets/cardPlants/introPlant7.png'
import { ReactNullValue } from '../../../../utils/nulls'

type PromoInfo = {
    description: string
    header: string
    image: string
}

export interface IntroHowItWorksProps {
    challenge: UnifiedChallenge
}

const IntroHowItWorks: React.FC<IntroHowItWorksProps> = ({ challenge }) => {
    const { breakpoints, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const promoInfo = useMemo<PromoInfo[]>(() => {
        const promoInfo = []

        if (
            challenge?.promoDescription1 ||
            challenge?.promoHeader1 ||
            challenge?.promoImage1
        ) {
            promoInfo.push({
                description: challenge?.promoDescription1,
                header: challenge?.promoHeader1,
                image: challenge?.promoImage1
            })
        }

        if (
            challenge?.promoDescription2 ||
            challenge?.promoHeader2 ||
            challenge?.promoImage2
        ) {
            promoInfo.push({
                description: challenge?.promoDescription2,
                header: challenge?.promoHeader2,
                image: challenge?.promoImage2
            })
        }

        if (
            challenge?.promoDescription3 ||
            challenge?.promoHeader3 ||
            challenge?.promoImage3
        ) {
            promoInfo.push({
                description: challenge?.promoDescription3,
                header: challenge?.promoHeader3,
                image: challenge?.promoImage3
            })
        }

        return promoInfo as PromoInfo[]
    }, [challenge])

    const backgroundPlant = useMemo(
        () => ({
            backgroundImage: isMobile
                ? `url(${introPlant2}), url(${introPlant3}), url(${introPlant1})`
                : `url(${introPlant2}), url(${introPlant3}), url(${introPlant1}), url(${introPlant5}), url(${introPlant4}), url(${introPlant6}), url(${introPlant7})`,
            backgroundPosition: isMobile
                ? '61px 100%, 145px 100%, 8px 100%'
                : '85px 100%, 169px 100%, 32px 100%, calc(100% - 133px) 100%, calc(100% - 196px) 100%, calc(100% - 32px) 100%, 100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: isMobile
                ? 'auto 86px, auto 73px, auto 100px'
                : 'auto 86px, auto 73px, auto 100px, auto 107px, auto 80px, auto 125px, auto 120px'
        }),
        [isMobile]
    )

    if (promoInfo.some((item) => !item.description || !item.image))
        return ReactNullValue

    return (
        <Stack gap={3}>
            <CoreTypography variant="h2">
                <FormattedMessage
                    defaultMessage="How it works"
                    description="how the challenge works header"
                />
            </CoreTypography>
            <Card>
                <Grid
                    container
                    spacing={4.5}
                    pb={14.25}
                    px={3}
                    pt={4}
                    sx={{
                        ...backgroundPlant,
                        bgcolor: palette.secondary.light
                    }}
                >
                    {promoInfo?.map((promoInfo, index: number) => (
                        <Grid item key={index} xs={12} sm={isMobile ? 12 : 4}>
                            <Stack
                                gap={3}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {promoInfo.image && (
                                    <Image
                                        src={promoInfo.image}
                                        height={60}
                                        width={60}
                                    />
                                )}
                                <Stack
                                    gap={1}
                                    justifyContent={
                                        isMobile ? 'center' : 'flex-start'
                                    }
                                    width="100%"
                                >
                                    {promoInfo.header && (
                                        <CoreTypography
                                            variant="h4"
                                            color="secondary.contrastText"
                                        >
                                            {promoInfo.header}
                                        </CoreTypography>
                                    )}
                                    {promoInfo.description && (
                                        <CoreTypography
                                            variant="body2"
                                            color="secondary.contrastText"
                                        >
                                            {promoInfo.description}
                                        </CoreTypography>
                                    )}
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </Stack>
    )
}

export default IntroHowItWorks
