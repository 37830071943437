import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { MicrostepDialogView } from '../index'
import { MicrostepState } from '../../MicrostepCard'

import {
    GraphQlMicrostep,
    MicrostepUserProgress
} from '../../../../graphql/generated/autogenerated'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import MicrostepNewLabel from '../../MicrostepNewLabel'

interface MicrostepDialogDetailsProps {
    microstep: GraphQlMicrostep
    microstepProgress?: MicrostepUserProgress
    state: MicrostepState
    isChecked: boolean
    linkCopied: boolean
    shareUrl?: string
    onChecked: () => void
    onUnchecked: () => void
    onClose: () => void
    onAdded: () => void
    onViewChange: (index: number) => void
    onLinkCopied: () => void
    disableRemove?: boolean
}

const messages = defineMessages({
    checkInProgress: {
        defaultMessage: `{count, plural,
            =0 {No times in the last week}
            =1 {One time in the last week}
            other {# times in the last week}
          }`,
        description:
            'count of how often this microstep has been checked into in the past week'
    }
})

const MicrostepDialogDetails: React.FC<MicrostepDialogDetailsProps> = ({
    microstep,
    microstepProgress,
    state,
    isChecked,
    linkCopied,
    shareUrl,
    onChecked,
    onUnchecked,
    onClose,
    onAdded,
    onViewChange,
    onLinkCopied,
    disableRemove
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [checked, setChecked] = useState<boolean>(isChecked)
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(linkCopied)
    const canRenderJourney = microstep?.journeys?.length > 0

    useEffect(() => {
        setIsLinkCopied(linkCopied)
    }, [linkCopied])

    const linkCopiedHandler = useCallback(() => {
        setIsLinkCopied(true)
        onLinkCopied()
    }, [onLinkCopied])

    const handleOnChecked = useCallback(
        (checkUpdate: boolean) => {
            setChecked(checkUpdate)

            if (state === MicrostepState.CheckIn) {
                checkUpdate ? onChecked() : onUnchecked()
            } else if (
                state === MicrostepState.Toggle ||
                state === MicrostepState.Preview
            ) {
                onAdded()
            }
            onClose()
        },
        [state, onAdded, onClose, onChecked, onUnchecked]
    )

    const onViewChangeHandler = useCallback(
        (view: MicrostepDialogView) => {
            onViewChange(view)
        },
        [onViewChange]
    )

    return (
        <Box data-testid="microstep-dialog-2-details">
            <Box>
                {canRenderJourney && (
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="overline" color="accent.main">
                            {microstep?.journeys?.[0]?.shortName}
                        </Typography>
                        <MicrostepNewLabel
                            microstep={microstep}
                        ></MicrostepNewLabel>
                    </Box>
                )}
                <Typography variant="h3" sx={{ pt: theme.spacing(1) }}>
                    {microstep.title}
                </Typography>
            </Box>
            <Paper
                elevation={0}
                sx={{
                    borderRadius: theme.spacing(0.75),
                    backgroundColor: theme.palette.grey[100],
                    padding: theme.spacing(3),
                    mt: theme.spacing(3),
                    mb: theme.spacing(3),
                    [theme.breakpoints.down('sm')]: {
                        backgroundColor: theme.palette.primary.contrastText,
                        padding: theme.spacing(2),
                        mb: theme.spacing(6)
                    }
                }}
            >
                <Typography variant="h6">
                    <FormattedMessage
                        defaultMessage="WHY"
                        description="H6 describing purpose for microstep"
                    />
                </Typography>
                <Typography variant="body2" sx={{ pt: theme.spacing(1) }}>
                    {microstep.body}
                </Typography>
            </Paper>
            <Box
                display={'flex'}
                alignItems={'center'}
                sx={{
                    flexDirection: 'row',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                <Button
                    variant="contained"
                    color={checked ? 'accent' : 'primary'}
                    size="large"
                    sx={{
                        py: theme.spacing(2),
                        px: theme.spacing(2),
                        borderRadius: theme.spacing(1),
                        width: theme.spacing(28),
                        marginRight: theme.spacing(1.375),
                        [theme.breakpoints.down('sm')]: {
                            marginRight: 0
                        }
                    }}
                    onClick={() => handleOnChecked(!checked)}
                >
                    {!checked && (
                        <CoreTypography customVariant="buttonLarge">
                            {state === MicrostepState.CheckIn && (
                                <FormattedMessage
                                    defaultMessage="Check In"
                                    description="Mark complete to check into a microstep"
                                />
                            )}
                            {(state === MicrostepState.Toggle ||
                                state === MicrostepState.Preview) && (
                                <FormattedMessage
                                    defaultMessage="Add Microstep"
                                    description="Add microstep to committed microsteps"
                                />
                            )}
                        </CoreTypography>
                    )}
                    {checked && (
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <LeafIcon
                                fontSize="small"
                                icon={'check'}
                                color={'secondary'}
                                sx={{ mr: 1 }}
                            />
                            <CoreTypography customVariant="buttonLarge">
                                {state === MicrostepState.CheckIn && (
                                    <FormattedMessage
                                        defaultMessage="Completed"
                                        description="Microstep check in completed"
                                    />
                                )}
                                {(state === MicrostepState.Toggle ||
                                    state === MicrostepState.Preview) && (
                                    <FormattedMessage
                                        defaultMessage="Microstep Added"
                                        description="Microstep added to committed microsteps"
                                    />
                                )}
                            </CoreTypography>
                        </Box>
                    )}
                </Button>
                {state === MicrostepState.CheckIn && !disableRemove && (
                    <Button
                        disableRipple
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() =>
                            onViewChangeHandler(MicrostepDialogView.Confirm)
                        }
                        sx={{
                            mt: 0,
                            '&.MuiButtonBase-root:hover': {
                                bgcolor: 'transparent'
                            },
                            [theme.breakpoints.down('sm')]: {
                                mt: theme.spacing(2.75)
                            }
                        }}
                    >
                        <CoreTypography
                            customVariant="buttonNormal"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            <FormattedMessage
                                defaultMessage="Remove Microstep"
                                description="Remove microstep from committed microsteps"
                            />
                        </CoreTypography>
                    </Button>
                )}
            </Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingTop={theme.spacing(3)}
                sx={{
                    height: 'auto',
                    pb: theme.spacing(1),
                    flexDirection: 'row',
                    [theme.breakpoints.down('sm')]: {
                        pt: theme.spacing(6),
                        flexDirection: 'column-reverse'
                    }
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            pt: theme.spacing(3)
                        }
                    }}
                >
                    {intl.formatMessage(messages.checkInProgress, {
                        count: microstepProgress?.checkedInCount
                    })}
                </Typography>
                {shareUrl && !isMobile && !isLinkCopied && (
                    <Button
                        color={'primary'}
                        sx={{ borderRadius: theme.spacing(1) }}
                        onClick={linkCopiedHandler}
                        endIcon={
                            <LeafIcon icon={'share-nodes'} fontSize={'small'} />
                        }
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Share"
                                description="Share button icon to share the microstep"
                            />
                        </CoreTypography>
                    </Button>
                )}
                {isLinkCopied && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            delay: 0,
                            duration: 3,
                            bounce: 0,
                            type: 'spring',
                            stiffness: 33
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            minHeight={'3rem'}
                        >
                            <LeafIcon
                                fontSize={'small'}
                                icon={'check'}
                                color={'accent'}
                                sx={{ mr: 1 }}
                            />
                            <Typography
                                variant={'overline'}
                                color="accent.main"
                                sx={{
                                    [theme.breakpoints.up('sm')]: {
                                        fontSize: 12
                                    }
                                }}
                            >
                                <FormattedMessage
                                    defaultMessage="Link Copied to Clipboard"
                                    description="Success message when link has been copied to the clipboard"
                                />
                            </Typography>
                        </Box>
                    </motion.div>
                )}
            </Box>
        </Box>
    )
}

export default MicrostepDialogDetails
