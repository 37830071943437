import { Box, Button, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ChallengeThemes } from '../../../../enums/challengeThemes'
import GoalChallengeShowTutorial, {
    tutorialMessages
} from '../GoalChallenges/GoalChallengeNavBar/GoalChallengeShowTutorial'
import ChallengeAboutIcons from './ChallengeAboutIcons'
import TermsAndConditions from '../../../../../shared/components/elements/TermsAndConditions/TermsAndConditions'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'

interface ChallengeAboutProps {
    challenge: UnifiedChallenge
}

const ChallengeAbout: React.FC<ChallengeAboutProps> = ({ challenge }) => {
    const { breakpoints } = useTheme()
    const isTablet = useMediaQuery(breakpoints.down('md'))
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)
    const [showTutorialDialog, setShowTutorialDialog] = useState<boolean>(false)
    const { formatMessage } = useIntl()

    return (
        <Card elevation={0}>
            <Stack direction={isTablet ? 'column' : 'row'} gap={2} p={3}>
                <Stack flex={1} gap={2}>
                    <CoreTypography
                        variant={isMobile ? 'h4' : 'body2'}
                        fontSize={24}
                    >
                        <FormattedMessage
                            defaultMessage="About this Challenge"
                            description="about this challenge title"
                        />
                    </CoreTypography>
                    {isWalmartPrizeChallenge && <TermsAndConditions />}
                    <CoreTypography
                        variant="body1"
                        maxWidth={512}
                        {...(!isMobile && { fontSize: 18, lineHeight: 1.675 })}
                    >
                        {challenge?.description}
                    </CoreTypography>
                    {!isWalmartPrizeChallenge && (
                        <Box>
                            <Button
                                variant="text"
                                aria-label={formatMessage(
                                    tutorialMessages.tutorialButtonAriaLabel
                                )}
                                onClick={() => setShowTutorialDialog(true)}
                            >
                                <CoreTypography variant="h5" component="p">
                                    {formatMessage(
                                        tutorialMessages.dialogTitle
                                    )}
                                </CoreTypography>
                            </Button>
                            <GoalChallengeShowTutorial
                                open={showTutorialDialog}
                                onClose={() => setShowTutorialDialog(false)}
                            />
                        </Box>
                    )}
                    {isWalmartPrizeChallenge &&
                        challenge?.theme === ChallengeThemes.MOVEMENT && (
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    defaultMessage="At the end of the Challenge, you still have the
                        opportunity to share your individual well-being story
                        for a chance to win cash prizes!"
                                    description="challenge about description"
                                />
                            </CoreTypography>
                        )}
                </Stack>
                <Stack
                    gap={2}
                    width={307}
                    {...(!isMobile && {
                        alignItems: 'center',
                        justifyContent: 'center'
                    })}
                >
                    <ChallengeAboutIcons challenge={challenge} />
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeAbout)
