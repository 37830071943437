import { Card, LinearProgress, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export type PlantGoalCard = {
    title: string
    stat: number
    threshold: number
    size?: 'small' | 'large'
}

const PlantGoalCard: React.FC<PlantGoalCard> = ({
    title,
    stat = 0,
    threshold = 0,
    size = 'small'
}) => {
    const { palette } = useTheme()
    const { formatNumber } = useIntl()
    const percentageDone = useMemo(
        () => (stat ? stat / (threshold / 100) : 0),
        [stat, threshold]
    )

    return (
        <Card elevation={0}>
            <Stack p={2} gap={2} minHeight={117}>
                <CoreTypography variant="overline" color="text.disabled">
                    {title}
                </CoreTypography>
                <Stack
                    gap={2}
                    direction={size === 'large' ? 'row-reverse' : 'column'}
                >
                    <CoreTypography
                        variant="h2"
                        textAlign="center"
                        color="text.secondary"
                    >
                        {formatNumber(threshold)}
                    </CoreTypography>
                    <Stack width="100%" justifyContent="center">
                        <LinearProgress
                            variant="determinate"
                            color="accent"
                            value={percentageDone}
                        />
                    </Stack>
                </Stack>
                {size === 'small' && (
                    <Stack direction="row" gap={1}>
                        <LeafIcon
                            icon={'gift-card'}
                            sx={{ color: palette.text.secondary }}
                        />
                        <CoreTypography variant="body2" color="text.secondary">
                            <FormattedMessage
                                defaultMessage="Reach your Challenge plant goal, and earn a special
                        reward for your personal garden."
                                description="challenge plant goal description"
                            />
                        </CoreTypography>
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}

export default memo(PlantGoalCard)
