import { createContext } from 'react'

export interface GoalChallengeValue {
    contextValues: { [key: string]: unknown }
}

export const defaultContextValue: GoalChallengeValue = {
    contextValues: {}
}

const GoalChallengeContext = createContext(defaultContextValue)

export default GoalChallengeContext
