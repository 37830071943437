import {
    useGetChallengesQuery,
    type GetChallengesQueryHookResult
} from '../../../graphql/generated/autogenerated'

export type UseGetChallengesValue = GetChallengesQueryHookResult

type UseGetChallengesProps = {
    inProgress: boolean
}

const useGetChallenges = ({
    inProgress
}: UseGetChallengesProps): UseGetChallengesValue => {
    return useGetChallengesQuery({
        variables: {
            inProgress
        }
    })
}

export default useGetChallenges
