import { Button } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    SocialGroup,
    useLeaveSocialGroupMutation
} from '../../../../../graphql/generated/autogenerated'
import ConfirmAction from '../../../../elements/ConfirmAction/ConfirmAction'

const messages = defineMessages({
    areYouSure: {
        defaultMessage: 'Are you sure you want to leave “{socialGroup}”?',
        description:
            'description text for the leave social group confirmation pop up'
    },
    leaveGroup: {
        defaultMessage: 'Leave group',
        description: 'button text for confirming leaving the social group'
    },
    cancel: {
        defaultMessage: 'Cancel',
        description: 'button text for cancelling leaving the social group'
    }
})

export type LeaveGroupProps = {
    socialGroup?: SocialGroup
    onLeaveGroup: () => void
    children?: ReactElement<any, any>
}

const LeaveGroup: React.FC<LeaveGroupProps> = ({
    socialGroup,
    onLeaveGroup,
    children
}) => {
    const intl = useIntl()

    const [leaveSocialGroup] = useLeaveSocialGroupMutation({
        variables: {
            socialGroupId: socialGroup?.id
        }
    })

    const renderButton = useMemo(() => {
        if (children) {
            return children
        } else {
            return (
                <Button
                    variant="text"
                    data-testid="leave-group-button"
                    color="error"
                    startIcon={
                        <LeafIcon
                            icon={'arrow-right-from-bracket'}
                            fontSize={'small'}
                        />
                    }
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        sx={{ textAlign: 'center' }}
                    >
                        <FormattedMessage
                            defaultMessage="Leave group"
                            description="button text for opening the are you sure you want to leave the group popup"
                        />
                    </CoreTypography>
                </Button>
            )
        }
    }, [children])

    return (
        <ConfirmAction
            confirmTitle={intl.formatMessage(messages.areYouSure, {
                socialGroup: socialGroup?.name
            })}
            confirmActionText={intl.formatMessage(messages.leaveGroup)}
            cancelActionText={intl.formatMessage(messages.cancel)}
            confirmAction={() =>
                leaveSocialGroup().then(() => {
                    onLeaveGroup()
                })
            }
        >
            {renderButton}
        </ConfirmAction>
    )
}

export default React.memo(LeaveGroup)
