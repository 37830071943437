import { LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import { withMultiStepForm } from '../../../../contexts/withMultiStepForm/withMultiStepForm'
import MultiStepFormContainer from '../../Shared/MultiStepForm/MultiStepFormContainer'
import {
    SIGNUP_STEPS,
    SIGNUP_VARIANT,
    SIGNUP_VARIANT_TYPE
} from '../../../../constants'
import MultiStepFormItem from '../../Shared/MultiStepForm/MultiStepFormItem'
import MultiStepFormNavigation from '../../Shared/MultiStepForm/MultiStepFormNavigation'
import ConfirmStep from '../../Intro/IntroSignup/Steps/ConfirmStep'
import type {
    SocialGroup,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import { isMovementChallenge } from '../../../../utils/challenge'
import TermsStep from '../../Intro/IntroSignup/Steps/TermsStep'
import useChangeChallengeParticipation from '../../../../hooks/useChangeChallengeParticipation/useChangeChallengeParticipation'
import JoinTeamStep from '../../Intro/IntroSignup/Steps/JoinTeamStep'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import type {
    SocialGroupsRefetch,
    SocialGroupsValues
} from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'

type ChangeParticipationModalProps = {
    participationVariant: SIGNUP_VARIANT_TYPE
    socialGroups: SocialGroupsValues
    challenge: UnifiedChallenge
    open: boolean
    onClose: () => void
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const ChangeParticipationModal: React.FC<ChangeParticipationModalProps> = ({
    challenge,
    open,
    socialGroups,
    participationVariant,
    onClose,
    refetchUnifiedChallengeAvailability
}) => {
    const deviceEnabled = isMovementChallenge(challenge)
    const { formData, updateFormData, onSubmit, loading } =
        useChangeChallengeParticipation()

    const isTeamSignUp = participationVariant === SIGNUP_VARIANT.JOIN_TEAM

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            dialogTitle={challenge.name}
        >
            <MultiStepFormContainer>
                {isTeamSignUp && (
                    <MultiStepFormItem name={SIGNUP_STEPS.JOIN_TEAM} required>
                        <JoinTeamStep
                            challenge={challenge}
                            teamId={formData?.socialGroup?.id}
                            setTeam={(id, name) => {
                                updateFormData({ socialGroup: { id, name } })
                            }}
                        />
                    </MultiStepFormItem>
                )}
                <MultiStepFormItem name={SIGNUP_STEPS.DETAILS}>
                    <ConfirmStep
                        shouldDisplayStartDate={false}
                        displayName={socialGroups.displayName || ''}
                        challengeName={challenge.name}
                        participationMethod={
                            isTeamSignUp ? 'team' : 'individual'
                        }
                        teamName={formData?.socialGroup?.name}
                    />
                </MultiStepFormItem>
                <MultiStepFormItem name={SIGNUP_STEPS.TERMS} required>
                    <TermsStep
                        deviceEnabled={deviceEnabled}
                        confirmed={formData?.terms?.confirmed}
                        setConfirmed={(confirmed) =>
                            updateFormData({ terms: { confirmed } })
                        }
                        isChallengeTemplate={false}
                        isTeamSignUp={isTeamSignUp}
                    />
                </MultiStepFormItem>
            </MultiStepFormContainer>
            <MultiStepFormNavigation
                loading={loading}
                onSubmit={async () => {
                    await onSubmit(challenge.id)
                    await refetchUnifiedChallengeAvailability()
                    await socialGroups.socialGroupsRefetch()
                    onClose()
                }}
                onSubmitButtonLabel={
                    <FormattedMessage
                        defaultMessage="Change Participation"
                        description="Change Participation dialog title"
                    />
                }
            />
        </LeafDialog>
    )
}

export default withMultiStepForm(ChangeParticipationModal)
