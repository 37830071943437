// External libraries
import { Grid, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import { memo, useMemo } from 'react'

// Components
import AverageSleepReflection from './Shared/AverageSleepReflection'
import CardActivityPoints from './Shared/ActivityPoints/CardActivityPoints'
import ChallengeTeamActivity from './Shared/ChallengeTeamActivity'
import ChallengeTeamName from './Shared/ChallengeTeamName'
import TeamTodayPlantsEarned from './Shared/PlantsEarned/TeamTodayPlantsEarned'
import TeamTotalPlants from './Shared/TotalPlantsGraph/TeamTotalPlants'
import TeamWaterTotal from './Shared/WaterTotal/TeamWaterTotal'
import YourPlantGoal from './Shared/PlantGoal/YourPlantGoal'

// Hooks
import { useChallengeTeamLeaderboard } from '../../hooks/useChallengeTeamLeaderboard/useChallengeTeamLeaderboard'
import useChallengeTheme from '../../hooks/useChallengeTheme/useChallengeTheme'
import useGetSocialGroups from '../../hooks/useGetSocialGroups/useGetSocialGroups'
import useGoalChallengeData from '../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'

// Internal types & utilities
import { ChallengeThemes } from '../../../enums/challengeThemes'
import {
    type FeelingQuestionStats,
    type UnifiedChallenge
} from '../../../graphql/generated/autogenerated'
import {
    getCanFirePostJoinQueries,
    isChallengeStarted,
    showAvgLeaderboard
} from '../../utils/challenge'

export type TeamProgressContainerProps = {
    hideTitle?: boolean
    hideActivity?: boolean
    hideTotals?: boolean
    challenge?: UnifiedChallenge
}
const TeamProgressContainer: React.FC<TeamProgressContainerProps> = ({
    hideTitle,
    hideActivity,
    hideTotals,
    challenge
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const size = useMemo(() => (isMobile ? 'small' : 'large'), [isMobile])
    const { socialGroup } = useGetSocialGroups(challenge?.id)
    const challengeStarted = isChallengeStarted(challenge)
    const shouldShowAvgLeaderboard = showAvgLeaderboard(challenge)
    const canFirePostJoinQueries = getCanFirePostJoinQueries({
        challenge,
        challengeStarted,
        challengeLoading: false,
        socialGroup
    })
    const { deviceEnabled } = useChallengeTheme(challenge)

    const {
        error,
        thresholdValue,
        thresholdAchieved,
        teamPlants,
        loading,
        teamTotalWater,
        feelingQuestionStats,
        groupedTeamPlants,
        teamActivityRank
    } = useGoalChallengeData({
        challenge: challenge as UnifiedChallenge,
        socialGroup,
        loading: false
    })

    const {
        scores,
        error: teamLeaderboardError,
        loading: teamLeaderboardLoading,
        requesterRank
    } = useChallengeTeamLeaderboard({
        showAvgLeaderboard: shouldShowAvgLeaderboard,
        canFirePostJoinQueries,
        deviceEnabled,
        challenge,
        socialGroup,
        teamActivityRank
    })

    const total = useMemo(
        () =>
            scores?.find((score) => score?.rank === requesterRank)?.totalSteps,
        [scores, requesterRank]
    )

    const themeCommunitySpecific = useMemo(() => {
        switch (challenge?.theme) {
            case ChallengeThemes.SLEEP:
                return (
                    <AverageSleepReflection
                        data={feelingQuestionStats as FeelingQuestionStats}
                    />
                )
            case ChallengeThemes.MOVEMENT:
                return (
                    <CardActivityPoints
                        totalSteps={total}
                        loading={teamLeaderboardLoading}
                        error={teamLeaderboardError}
                    />
                )
            case ChallengeThemes.HYDRATION:
                return (
                    <TeamWaterTotal
                        teamTotalWater={teamTotalWater}
                        loading={loading}
                        error={error}
                    />
                )
            default:
                return <></>
        }
    }, [challenge, feelingQuestionStats, total])

    return (
        <Grid container>
            {!hideTitle && (
                <Grid item xs={12}>
                    <CoreTypography variant="body1" fontSize={24} component="p">
                        <FormattedMessage
                            defaultMessage="Team Progress"
                            description="title for the daily goals card"
                        />
                    </CoreTypography>
                </Grid>
            )}
            <Grid item xs={12} md={hideActivity ? 12 : 8}>
                <Grid container>
                    {!hideTotals && (
                        <>
                            {socialGroup && !socialGroup.isSoloChallenge && (
                                <Grid item xs={12} md={6}>
                                    <ChallengeTeamName
                                        socialGroup={socialGroup}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <YourPlantGoal
                                    size={size}
                                    thresholdValue={thresholdValue}
                                    thresholdAchieved={thresholdAchieved}
                                    error={error}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TeamTodayPlantsEarned
                                    error={error}
                                    teamPlants={teamPlants}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {themeCommunitySpecific}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TeamTotalPlants
                            challenge={challenge as UnifiedChallenge}
                            groupedTeamPlants={groupedTeamPlants}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!hideActivity && socialGroup && !socialGroup.isSoloChallenge && (
                <Grid item xs={12} md={4}>
                    <ChallengeTeamActivity
                        challengeStarted={challengeStarted}
                        loading={false}
                        socialGroup={socialGroup}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default memo(TeamProgressContainer)
