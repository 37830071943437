import { Box, Card, Divider, Snackbar, Stack } from '@mui/material'
import {
    CoreAlert,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import {
    AddManualActivityModal,
    ViewManualActivityModal
} from '@thriveglobal/thrive-web-wearables-core'
import React, { memo, useCallback, useMemo, useState } from 'react'
import {
    FormattedMessage,
    defineMessage,
    defineMessages,
    useIntl
} from 'react-intl'
import {
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal,
    StepsGoalDetail
} from '../../../../../../../graphql/generated/autogenerated'
import ConditionalSkeleton from '../../../../../elements/ConditionalSkeleton'
import DisposableDialog from '../../../../../elements/DisposableDialog'
import HiddenStatusUpdate from '../../../../../elements/HiddenStatusUpdate'
import ManageDeviceButton from '../../../../../elements/ManageDeviceButton'
import StatFraction from '../../../../../elements/Stats/StatFraction'
import GoalTitle from '../../GoalTitle'
import UpdateActivityGoal from './UpdateActivityGoal'
import WhatAreActivityPointsButton from './WhatAreActivityPoints/WhatAreActivityPointsButton'
import WhereIsMyActivity from './WhereIsMyActivity'

export const WHAT_ARE_ACTIVITY_POINTS_TITLE = defineMessage({
    defaultMessage: 'What are activity points?',
    description: 'What are activity points title'
})

export const WHAT_ARE_ACTIVITY_POINTS_MESSAGE = defineMessage({
    defaultMessage:
        'Activity points are calculated by combining your reported daily activities. For step and step-based activities, we calculate that 1 step = 1 activity point. For non-step activities (i.e. swimming), Thrive calculates points based on Metabolic Equivalent of Task (METS).',
    description: 'explanation of activity points'
})

const messages = defineMessages({
    activityGoal: {
        defaultMessage:
            'Current activity today is: {todaySteps}, to reach your goal you must achieve {activityGoal}',
        description: 'activity goal on what the user needs to achieve'
    },
    editSuccess: {
        defaultMessage: 'Activity successfully edited!',
        description: 'success alert message when activity is edited'
    },
    addSuccess: {
        defaultMessage: 'Activity successfully added!',
        description: 'success alert message when activity is added'
    }
})

export interface ActivityGoalProps extends JSX.IntrinsicAttributes {
    goal:
        | ChallengeUserDailyGoal
        | {
              __typename: undefined
              challengeGoal: ChallengeTemplateGoal
          }
    disabled?: boolean
    loading?: boolean
    userTodaySteps?: number
    startDate: Date
    dailyGoalRefetch?: () => Promise<void>
    onComplete: () => void
}

const ActivityGoal: React.FC<ActivityGoalProps> = ({
    goal,
    disabled = false,
    loading = false,
    userTodaySteps = 0,
    startDate,
    dailyGoalRefetch = Promise.resolve,
    onComplete
}) => {
    const [showViewActivityModal, setShowViewActivityModal] = useState(false)
    const [showEditSuccess, setShowEditSuccess] = useState(false)
    const [showAddSuccess, setShowAddSuccess] = useState(false)
    const { formatMessage } = useIntl()

    const handleOpenViewActivityModal = useCallback(() => {
        if (disabled) return
        setShowViewActivityModal(true)
    }, [disabled])

    const handleCloseViewActivityModal = useCallback(() => {
        setShowViewActivityModal(false)
    }, [])

    const handleHideEditSuccess = useCallback(() => {
        setShowEditSuccess(false)
    }, [])

    const handleOnEditActivity = useCallback(
        (isToday: boolean) => {
            setShowEditSuccess(true)

            if (!isToday) return
            dailyGoalRefetch()
        },
        [dailyGoalRefetch]
    )

    const handleOnAddActivity = (_steps: number, isToday?: boolean) => {
        setShowAddSuccess(true)
        const activityGoal = goal as ChallengeUserDailyGoal
        const goalEntity = (goal as ChallengeUserDailyGoal)?.goalEntityDetail
        const stepThreshold = goalEntity as StepsGoalDetail
        if (
            !activityGoal?.completed &&
            _steps + userTodaySteps > stepThreshold?.steps
        ) {
            onComplete?.()
        }
        if (isToday) dailyGoalRefetch()
    }

    const handleHideAddSuccess = useCallback(() => {
        setShowAddSuccess(false)
    }, [])

    const activityGoal = useMemo(() => {
        if (
            goal.__typename === 'ChallengeUserDailyGoal' &&
            goal.goalEntityDetail?.__typename === 'StepsGoalDetail'
        ) {
            return goal.goalEntityDetail.steps ?? 0
        } else {
            return goal.challengeGoal.goalSteps ?? 0
        }
    }, [goal])

    return (
        <Stack gap={2} width="100%" height="100%" data-testid="activity-goal">
            <Stack
                gap={2}
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="space-between"
            >
                <GoalTitle
                    title={
                        <FormattedMessage
                            defaultMessage="Reach your daily activity points goal"
                            description="title for the activity daily goal card"
                        />
                    }
                />
                <UpdateActivityGoal
                    goal={goal}
                    disabled={disabled}
                    dailyGoalRefetch={dailyGoalRefetch}
                />
            </Stack>
            <HiddenStatusUpdate
                status={formatMessage(messages.activityGoal, {
                    userTodaySteps,
                    activityGoal
                })}
            />
            <Card elevation={0} variant="outlined">
                <Stack divider={<Divider />} gap={2} p={2}>
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <ConditionalSkeleton
                            showSkeleton={loading}
                            variant="rounded"
                        >
                            <StatFraction
                                stat={Number(userTodaySteps)}
                                activityGoal={activityGoal}
                                onStatClicked={
                                    !disabled
                                        ? handleOpenViewActivityModal
                                        : undefined
                                }
                                variant="h3"
                                disabled={disabled}
                            />
                        </ConditionalSkeleton>
                        <DisposableDialog
                            button={
                                <IconButton
                                    sx={{ borderRadius: 1 }}
                                    variant="contained"
                                    color="secondary"
                                    disabled={disabled}
                                >
                                    <LeafIcon icon="plus" />
                                </IconButton>
                            }
                            Dialog={({ open, onClose }) => (
                                <AddManualActivityModal
                                    open={open}
                                    onClose={onClose}
                                    startDate={startDate}
                                    onAddActivity={handleOnAddActivity}
                                />
                            )}
                            disabled={disabled}
                        />
                    </Stack>
                    <Box>
                        <ManageDeviceButton
                            color="primary"
                            variant="text"
                            disabled={disabled}
                        />
                    </Box>
                </Stack>
            </Card>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
            >
                <WhereIsMyActivity disabled={disabled} />
                <WhatAreActivityPointsButton disabled={disabled} />
            </Stack>

            {/* Dialogs and snackbars */}
            <ViewManualActivityModal
                open={showViewActivityModal}
                startDate={startDate}
                onClose={handleCloseViewActivityModal}
                onEditActivity={(_, isToday) =>
                    handleOnEditActivity(Boolean(isToday))
                }
            />
            <Snackbar
                open={showEditSuccess}
                onClose={handleHideEditSuccess}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <CoreAlert onClose={handleHideEditSuccess} severity="success">
                    {formatMessage(messages.editSuccess)}
                </CoreAlert>
            </Snackbar>
            <Snackbar
                open={showAddSuccess}
                onClose={handleHideAddSuccess}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <CoreAlert onClose={handleHideAddSuccess} severity="success">
                    {formatMessage(messages.addSuccess)}
                </CoreAlert>
            </Snackbar>
        </Stack>
    )
}

export default memo(ActivityGoal)
