import { CardActions, CardContent, Stack, useTheme } from '@mui/material'
import {
    CoreTypography,
    LeafExternalLink,
    LeafFlexMediaCard
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import {
    ChallengeUserDailyGoal,
    UnifiedChallenge,
    useCompleteCommunityChallengeDailyGoalMutation
} from '../../../../../../../graphql/generated/autogenerated'
import CompletedGoal from '../../CompletedGoal'
import GoalTitle from '../../GoalTitle'

const messages = defineMessages({
    readExternal: {
        defaultMessage: 'Read External Content',
        description: 'title for the external link goal card'
    },
    accessContent: {
        defaultMessage: 'Access Content',
        description: 'card title for viewing external content'
    },
    pleaseAccess: {
        defaultMessage:
            'Please access this link to complete one of your daily goals.',
        description: 'card title for viewing external content'
    },
    backToChallengeLabelText: {
        defaultMessage: 'Back to Company Challenge',
        description: 'label for the back to challenge link'
    }
})

export type ExternalLinkGoalProps = {
    challenge: UnifiedChallenge | undefined
    goal: ChallengeUserDailyGoal
    disabled?: boolean
    onComplete?: () => void
}

const ExternalLinkGoal: React.FC<ExternalLinkGoalProps> = ({
    challenge,
    goal,
    disabled,
    onComplete
}) => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const ariaId = uuid()

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    const { externalLink, completed } = useMemo(() => {
        return {
            externalLink: goal?.challengeGoal?.url,
            completed: goal?.completed
        }
    }, [goal])

    const onContinue = useCallback(async () => {
        await completeDailyGoal()
        onComplete?.()
    }, [completeDailyGoal, onComplete])

    return (
        <Stack spacing={1} height="100%">
            <GoalTitle title={formatMessage(messages.readExternal)} />
            {completed ? (
                <CompletedGoal
                    content={
                        <CoreTypography
                            variant="body1"
                            color="text.disabled"
                            component="output"
                            aria-live="polite"
                        >
                            <LeafExternalLink
                                externalLink={String(externalLink)}
                            >
                                {formatMessage(messages.readExternal)}
                            </LeafExternalLink>
                        </CoreTypography>
                    }
                />
            ) : (
                <LeafFlexMediaCard>
                    <CardContent>
                        <Stack gap={1}>
                            <CoreTypography variant="h4" component="p">
                                {formatMessage(messages.accessContent)}
                            </CoreTypography>
                            <CoreTypography
                                variant="caption"
                                id={`description-${ariaId}`}
                            >
                                {formatMessage(messages.pleaseAccess)}
                            </CoreTypography>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <LeafExternalLink
                            externalLink={String(externalLink)}
                            onContinue={onContinue}
                            sx={{
                                ...(disabled && {
                                    pointerEvents: 'none',
                                    color: palette.text.disabled
                                })
                            }}
                        >
                            {formatMessage(messages.accessContent)}
                        </LeafExternalLink>
                    </CardActions>
                </LeafFlexMediaCard>
            )}
        </Stack>
    )
}

export default ExternalLinkGoal
