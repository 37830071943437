import { Fade, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SocialGroupMember } from '../../../../../graphql/generated/autogenerated'
import { getInitials } from '../../util/getInitials'
import SocialAvatar from '../SocialAvatar/SocialAvatar'

export interface SocialMemberProps {
    ownerId?: string
    socialMember: SocialGroupMember
    onEditUser?: () => void
}

const SocialMember: React.FC<SocialMemberProps> = ({
    ownerId,
    socialMember,
    onEditUser
}) => {
    const { userId } = useAppSelector((state) => state.user)
    return (
        <Fade in>
            <Stack
                direction="row"
                data-testid="social-member"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <SocialAvatar
                        initials={getInitials(socialMember?.displayName)}
                    />
                    <Stack gap={0.5}>
                        <CoreTypography variant="body1">
                            {socialMember?.displayName}
                        </CoreTypography>
                        {ownerId && ownerId === socialMember?.user?.id && (
                            <CoreTypography
                                variant="caption"
                                color="text.secondary"
                            >
                                <FormattedMessage
                                    defaultMessage="Admin"
                                    description="text for admins of the social group"
                                />
                            </CoreTypography>
                        )}
                    </Stack>
                </Stack>
                {!!onEditUser && (
                    <Stack>
                        {socialMember?.user?.id === userId && (
                            <IconButton
                                variant="text"
                                color="primary"
                                onClick={onEditUser}
                            >
                                <LeafIcon icon={'pen'} />
                            </IconButton>
                        )}
                    </Stack>
                )}
            </Stack>
        </Fade>
    )
}

export default React.memo(SocialMember)
