import { alpha, TextField, TextFieldProps, styled } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

const InlineTitleTextField: React.FC<any> = styled(TextField)(() => {
    const theme = useTheme()

    return {
        '& .MuiFilledInput-root': {
            border: `${theme.spacing(0.125)} solid ${
                theme.palette.primary.main
            }`,
            overflow: 'hidden',
            borderRadius: theme.spacing(1),
            backgroundColor: 'transparent',
            minHeight: theme.spacing(8),
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow'
            ]),
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                boxShadow: `${alpha(
                    theme.palette.primary.main,
                    0.25
                )} 0 0 0 ${theme.spacing(0.25)}`,
                borderColor: theme.palette.primary.main
            },
            '&.Mui-error': {
                borderColor: theme.palette.error.main
            }
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.grey[700],
            transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(
                2.5
            )}) scale(1)`,
            '&.Mui-focused': {
                color: theme.palette.grey[700],
                transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(
                    1.5
                )}) scale(0.75)`
            },
            '&.MuiFormLabel-filled': {
                transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(
                    1.5
                )}) scale(0.75)`
            }
        }
    }
})

export default InlineTitleTextField
