// External libraries
import React, { memo, useEffect, useState } from 'react'

// Components
import DisposableDialog from '../../../../../elements/DisposableDialog'
import CompletedGoalDialog from './components/CompletedGoalDialog'
import CompletedPlantThresholdDialog from './components/CompletedPlantThresholdDialog/CompletedPlantThresholdDialog'

// Hooks
import { useGoalsCompletedDialogState } from '../../../../../../contexts/withGoalsCompleted/useGoalsCompletedDialogState'
import { useThresholdReachedDialogState } from '../../../../../../contexts/withGoalsCompleted/useThresholdReachedDialogState'

// Types
import {
    UnifiedChallenge,
    UserPlantInfo
} from '../../../../../../../graphql/generated/autogenerated'

export type DailyGoalsCompletedDialogsProps = {
    resetPlaying: boolean
    challenge: UnifiedChallenge
    userPlant?: UserPlantInfo
}

const DailyGoalsCompletedDialogs: React.FC<DailyGoalsCompletedDialogsProps> = ({
    resetPlaying,
    challenge,
    userPlant
}) => {
    const [showCompletedGoalDialog, setShowCompletedGoalDialog] =
        useState(false)
    const [
        showCompletedPlantThresholdDialog,
        setShowCompletedPlantThresholdDialog
    ] = useState<boolean>(false)
    const { canShowDialog: canShowCompletedDialog, saveGoalsCompletedState } =
        useGoalsCompletedDialogState()
    const { canShowDialog: canShowThresholdDialog, saveThresholdReachedState } =
        useThresholdReachedDialogState()

    useEffect(() => {
        if (canShowCompletedDialog && !resetPlaying) {
            setShowCompletedGoalDialog(true)
            saveGoalsCompletedState()
        }
    }, [canShowCompletedDialog, resetPlaying, saveGoalsCompletedState])

    useEffect(() => {
        if (
            canShowThresholdDialog &&
            !resetPlaying &&
            !canShowCompletedDialog
        ) {
            setShowCompletedPlantThresholdDialog(true)
            saveThresholdReachedState()
        }
    }, [
        canShowThresholdDialog,
        canShowCompletedDialog,
        resetPlaying,
        saveThresholdReachedState
    ])

    return (
        <>
            <DisposableDialog
                open={showCompletedGoalDialog}
                onClose={() => setShowCompletedGoalDialog(false)}
                Dialog={CompletedGoalDialog}
                dialogProps={{
                    userPlant: userPlant,
                    challenge: challenge
                }}
            />
            <DisposableDialog
                open={showCompletedPlantThresholdDialog}
                onClose={() => setShowCompletedPlantThresholdDialog(false)}
                Dialog={CompletedPlantThresholdDialog}
            />
        </>
    )
}

export default memo(DailyGoalsCompletedDialogs)
