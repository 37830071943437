import { FetchResult } from '@apollo/client'
import { Stack } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import TextFieldNewInput from '../../../../components/elements/TextFieldNewInput/TextFieldNewInput'
import {
    EditDisplayNameMutation,
    EditSocialGroupMutation,
    InvalidInput,
    SocialGroup,
    useEditDisplayNameMutation,
    useEditSocialGroupMutation
} from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    yourDisplayName: {
        defaultMessage: 'Your display name',
        description: 'description text for the display name input field'
    },
    socialGroupName: {
        defaultMessage: 'Social group name',
        description: 'description text for the social group input field'
    }
})

enum TextFieldSettings {
    displayName = 'displayName',
    socialGroup = 'socialGroup'
}

export type SocialGroupTextFieldSettingsProps = {
    socialGroup?: SocialGroup
    displayName?: string
    isAdmin: boolean
    isSocialGroupSetting: boolean
    refetchSocialGroup: () => Promise<any>
    setLoading: (value: boolean) => void
    setHasUnsavedChallenge: (value: boolean) => void
    setOnDone: (doneAction: () => Promise<any>) => void
}

const SocialGroupTextFieldSettings: React.FC<
    SocialGroupTextFieldSettingsProps
> = ({
    socialGroup,
    displayName,
    isAdmin,
    isSocialGroupSetting,
    refetchSocialGroup,
    setLoading,
    setHasUnsavedChallenge,
    setOnDone
}) => {
    const intl = useIntl()
    const [newDisplayName, setNewDisplayName] = useState('')
    const [newSocialGroupName, setNewSocialGroupName] = useState('')
    const [invalidText, setInvalidText] = useState<{ [key: string]: string }>(
        {}
    )

    const [editDisplayName, { loading: editDisplayNameLoading }] =
        useEditDisplayNameMutation({
            variables: {
                displayName: newDisplayName
            }
        })
    const [editSocialGroup, { loading: editSocialGroupLoading }] =
        useEditSocialGroupMutation({
            variables: {
                updatedSocialGroup: {
                    socialGroupId: socialGroup?.id,
                    name: newSocialGroupName
                }
            }
        })

    const handleInvalidStateUpdate = useCallback(
        (key: any, newError: any) => {
            const invalidTextCopy = { ...invalidText }
            invalidTextCopy[key] = newError
            setInvalidText(invalidTextCopy)
        },
        [invalidText]
    )

    const handleMutation = useCallback(
        <T extends unknown, K extends unknown>(
            key: string,
            mutation: () => Promise<FetchResult<T>>,
            onMutationComplete: (response: FetchResult<T>) => K
        ) => {
            return mutation().then((response) => {
                const mutationSpecificResponse = onMutationComplete(response)

                if (
                    (mutationSpecificResponse as InvalidInput)?.invalidFields
                        ?.length > 0
                ) {
                    handleInvalidStateUpdate(
                        key,
                        (mutationSpecificResponse as InvalidInput)
                            .invalidFields[0].message
                    )
                }
            })
        },
        [handleInvalidStateUpdate]
    )

    const onDone = useCallback(() => {
        const actions = []

        if (newDisplayName) {
            actions.push(() =>
                handleMutation<EditDisplayNameMutation, SocialGroup>(
                    TextFieldSettings.displayName,
                    () => editDisplayName(),
                    (response) =>
                        response?.data?.socialGroups
                            ?.editDisplayName as SocialGroup
                )
            )
        }

        if (newSocialGroupName) {
            actions.push(() =>
                handleMutation<EditSocialGroupMutation, SocialGroup>(
                    TextFieldSettings.socialGroup,
                    () => editSocialGroup(),
                    (response) =>
                        response?.data?.socialGroups
                            ?.editSocialGroup as SocialGroup
                )
            )
        }

        return Promise.all(Array.from(actions.map((action) => action()))).then(
            refetchSocialGroup
        )
    }, [
        newDisplayName,
        newSocialGroupName,
        handleMutation,
        editDisplayName,
        editSocialGroup,
        refetchSocialGroup
    ])

    const isLoading = useMemo(
        () => editDisplayNameLoading || editSocialGroupLoading,
        [editDisplayNameLoading, editSocialGroupLoading]
    )

    useEffect(() => {
        setOnDone(onDone)
    }, [setOnDone, onDone])
    useEffect(() => {
        setLoading(isLoading)
    }, [setLoading, isLoading])
    useEffect(() => {
        const hasDisplayNameChanged = newDisplayName !== displayName
        const hasSocialGroupNameChanged =
            newSocialGroupName !== socialGroup?.name

        setHasUnsavedChallenge(
            hasDisplayNameChanged || hasSocialGroupNameChanged
        )
    }, [
        setHasUnsavedChallenge,
        newDisplayName,
        displayName,
        newSocialGroupName,
        socialGroup?.name
    ])
    useEffect(() => {
        if (socialGroup?.name) setNewSocialGroupName(socialGroup.name)
    }, [socialGroup?.name])

    useEffect(() => {
        if (displayName) setNewDisplayName(displayName)
    }, [displayName])

    const getTextFieldInput = useCallback(
        (
            textFieldSettings: TextFieldSettings,
            label: string,
            onNewInput: (value: string) => void,
            dataTestId: string,
            value?: string
        ) => (
            <TextFieldNewInput
                fullWidth
                label={label}
                value={value}
                onNewInput={onNewInput}
                disabled={isLoading}
                data-testid={dataTestId}
                error={!!invalidText[textFieldSettings]}
                helperText={invalidText[textFieldSettings]}
            />
        ),
        [invalidText, isLoading]
    )

    return (
        <Stack spacing={2} maxWidth={320}>
            {getTextFieldInput(
                TextFieldSettings.displayName,
                intl.formatMessage(messages.yourDisplayName),
                setNewDisplayName,
                'display-name-textfield',
                newDisplayName
            )}
            {isAdmin &&
                isSocialGroupSetting &&
                getTextFieldInput(
                    TextFieldSettings.socialGroup,
                    intl.formatMessage(messages.socialGroupName),
                    setNewSocialGroupName,
                    'social-group-name-textfield',
                    newSocialGroupName
                )}
        </Stack>
    )
}

export default React.memo(SocialGroupTextFieldSettings)
