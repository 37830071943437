import { Card, Stack } from '@mui/material'
import React from 'react'
import Image from '../../../elements/Image'

const WAVE_BACKGROUND_SVGS = {
    purple: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/31c0e6dc-1081-40ea-a314-96d4d0da0700/cms',
    blue: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/94ed9c58-a885-4dbe-0d5c-457443c67a00/cms',
    red: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9e0868de-7dd7-4319-fc95-d0ea0f462a00/cms'
}

export type WaveCardContainerProps = React.PropsWithChildren<{
    waveColor?: keyof typeof WAVE_BACKGROUND_SVGS
    elevation?: number
    loading?: boolean
}>

const WaveCardContainer: React.FC<WaveCardContainerProps> = ({
    waveColor = 'purple',
    elevation,
    children
}) => {
    return (
        <Card
            elevation={elevation ? elevation : 0}
            sx={{
                height: '100%'
            }}
        >
            <Stack
                p={2}
                minHeight={123}
                height="100%"
                width="100%"
                position="relative"
            >
                {children}
                <Stack
                    position="absolute"
                    bottom={0}
                    left={0}
                    height="100%"
                    width="100%"
                >
                    <Image
                        src={WAVE_BACKGROUND_SVGS[waveColor]}
                        height="100%"
                        width="100%"
                        backgroundPositionY="bottom"
                    />
                </Stack>
            </Stack>
        </Card>
    )
}

export default WaveCardContainer
