import {
    type SocialGroup,
    useCompanyChallengeSocialGroupsQuery
} from '../../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { stripBOM } from '../../../shared/utils/bom'
type UseGetSocialGroupById = {
    challengeId: string
    socialGroupId: string
}

type UseGetSocialGroupByIdReturn = {
    loading: boolean
    data?: SocialGroup
    error?: ApolloError | null
}

const useGetSocialGroupById = ({
    challengeId,
    socialGroupId
}: UseGetSocialGroupById): UseGetSocialGroupByIdReturn => {
    const { loading, data, error, refetch } =
        useCompanyChallengeSocialGroupsQuery({
            variables: {
                challengeId: stripBOM(challengeId)
            },
            skip: !socialGroupId
        })

    return {
        loading,
        data: data?.socialGroups.companyChallengeSocialGroups.find(
            (group) => group.id === socialGroupId
        ) as SocialGroup | undefined,
        error
    }
}

export default useGetSocialGroupById
