import { IconButton } from '@mui/material'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import DisposableDialog from '../../../../../../elements/DisposableDialog'
import WhatAreActivityPoints from './WhatAreActivityPointsDialog'

const WhatAreActivityPointsButton: React.FC<{ disabled: boolean }> = ({
    disabled
}) => {
    return (
        <DisposableDialog
            button={
                <IconButton
                    sx={{ minWidth: 0 }}
                    size="small"
                    color="primary"
                    disabled={disabled}
                >
                    <LeafIcon icon="circle-info" />
                </IconButton>
            }
            // TODO: Add activity explainer dialog
            Dialog={({ open, onClose }) => (
                <WhatAreActivityPoints open={open} onClose={onClose} />
            )}
            disabled={disabled}
        />
    )
}

export default memo(WhatAreActivityPointsButton)
