import { DialogContent, Stack } from '@mui/material'
import {
    isMicrosoftTeamsTest,
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../../../../../routes'
import { ChallengeType } from '../../../../../../shared/enums/challengeType'
import EmailInviteInput from '../../../../elements/EmailInviteInput'
import InviteInput from '../../../../elements/InviteInput'
import { InviteTypes } from '../../../../../enums/inviteTypes'
import {
    useGetMeQuery,
    type Company,
    type SocialGroup,
    type UnifiedChallenge
} from '../../../../../../graphql/generated/autogenerated'

import useGetSocialGroups from '../../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import { FEATURE_FLAGS } from '../../../../../../constants'

const messages = defineMessages({
    copyTeamInvite: {
        defaultMessage: `Copy link to Invite to your team`,
        description: 'invite link success message'
    },
    copyChallengeInvite: {
        defaultMessage: `Copy link to Invite to the challenge`,
        description: 'invite link success message'
    },
    inviteEmailsToChallenge: {
        defaultMessage: `Invite emails to challenge button`,
        description: 'invite link success message'
    },
    inviteLink: {
        defaultMessage: `Invite link`,
        description: 'invite link'
    },
    inviteChallengeLink: {
        defaultMessage: `Invite to challenge link`,
        description: 'Invite to challenge link'
    },
    inviteTeamLink: {
        defaultMessage: `Invite to team link`,
        description: 'Invite to team link'
    }
})

export interface InviteToChallengeModalProps {
    open: boolean
    onClose: () => void
    challenge: UnifiedChallenge
    socialGroup: SocialGroup
    company: Company
}

const InviteToChallengeModal: React.FC<InviteToChallengeModalProps> = ({
    open,
    onClose,
    challenge,
    socialGroup,
    company
}) => {
    const hasChallengesV2Access = useIsFeatureEnabled(
        FEATURE_FLAGS.HAS_CHALLENGES_V2_ACCESS,
        false,
        true
    )
    const { fullName } = useAppSelector((state) => state.user)
    const { formatMessage } = useIntl()

    const displayName = fullName
    const companyName = company?.name

    const [emailError, setEmailError] = useState(false)

    const { companyPath, teamPath } = useMemo(() => {
        const groupChallenge = challenge?.challenge_type === ChallengeType.group
        const company = generatePath(
            hasChallengesV2Access
                ? ROUTES.CHALLENGE_V2
                : groupChallenge
                ? ROUTES.GROUP_CHALLENGE_HOME
                : ROUTES.COMPANY_CHALLENGE_INVITE,
            {
                challengeId: challenge?.id
            }
        )
        const team = generatePath(ROUTES.GROUP_COMPANY_CHALLENGE_INVITE, {
            challengeId: challenge?.id,
            socialGroupId: socialGroup?.id || '0'
        })

        return {
            companyPath: isMicrosoftTeamsTest()
                ? company
                : `${window.location.origin}${company}`,
            teamPath: isMicrosoftTeamsTest()
                ? team
                : `${window.location.origin}${team}`
        }
    }, [challenge, socialGroup])

    // Same event is used for sharing content on msteams
    const handleOnCopied = (activityType: InviteTypes) => {
        Avo.inviteSent({
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme as string,
            challengeType: challenge?.challenge_type,
            dayNumber: challenge?.dayOfChallenge as number,
            teamId: socialGroup?.id,
            teamType: null,
            featureType: 'challenge',
            activityType
        })
    }

    const canShowSoloInviteLink = useMemo(
        () => !!companyName && !!displayName && !!challenge?.id && companyPath,
        [companyName, displayName, challenge?.id, companyPath]
    )

    const canShowTeamInviteLink = useMemo(
        () =>
            !!companyName &&
            !!displayName &&
            !!challenge?.id &&
            socialGroup &&
            !socialGroup?.isSoloChallenge &&
            teamPath,
        [companyName, displayName, challenge?.id, socialGroup, teamPath]
    )

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                sx: {
                    maxWidth: '672px',
                    width: '100%'
                }
            }}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Invite a coworker"
                        description="invite a coworker card title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <Stack gap={3} p={0.125} pb={10}>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            defaultMessage="Invite your friends to join in the fun! Copy the link and send it along."
                            description="invite a coworker description"
                        />
                    </CoreTypography>
                    <Stack gap={3}>
                        <Stack gap={1}>
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Invite by link"
                                    description="Invite by link challenge description"
                                />
                            </CoreTypography>
                            <Stack gap={0.5}>
                                <CoreTypography variant="caption">
                                    {formatMessage(
                                        socialGroup &&
                                            socialGroup?.isSoloChallenge
                                            ? messages.inviteLink
                                            : messages.inviteChallengeLink
                                    )}
                                </CoreTypography>
                                <InviteInput
                                    disabled={!canShowSoloInviteLink}
                                    companyName={companyName}
                                    referrerName={displayName}
                                    redirectUrl={companyPath}
                                    ariaLabel={formatMessage(
                                        messages.copyChallengeInvite
                                    )}
                                    onCopied={() =>
                                        handleOnCopied(
                                            InviteTypes.ChallengeInvite
                                        )
                                    }
                                    onShared={() =>
                                        handleOnCopied(
                                            InviteTypes.ChallengeInvite
                                        )
                                    }
                                />
                            </Stack>
                        </Stack>
                        <Stack gap={1}>
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Invite by email"
                                    description="invite a friend by email to join the challenge"
                                />
                            </CoreTypography>
                            <Stack gap={0.5}>
                                <CoreTypography variant="caption">
                                    <FormattedMessage
                                        defaultMessage="Email"
                                        description="email input label"
                                    />
                                </CoreTypography>
                                <EmailInviteInput
                                    disabled={emailError}
                                    companyName={companyName}
                                    referrerName={displayName}
                                    redirectUrl={companyPath}
                                    onEmailError={setEmailError}
                                    onEmailsSent={() =>
                                        handleOnCopied(InviteTypes.EmailSent)
                                    }
                                    ariaLabel={formatMessage(
                                        messages.copyChallengeInvite
                                    )}
                                />
                                <CoreTypography variant="caption">
                                    <FormattedMessage
                                        defaultMessage="Use a comma to separate multiple email addresses"
                                        description="invite a friend by email to join the challenge description"
                                    />
                                </CoreTypography>
                            </Stack>
                        </Stack>
                        {canShowTeamInviteLink && (
                            <Stack gap={0.5}>
                                <CoreTypography variant="caption">
                                    {formatMessage(messages.inviteTeamLink)}
                                </CoreTypography>
                                <InviteInput
                                    companyName={companyName}
                                    referrerName={displayName}
                                    redirectUrl={teamPath}
                                    ariaLabel={formatMessage(
                                        messages.copyTeamInvite
                                    )}
                                    onCopied={() =>
                                        handleOnCopied(InviteTypes.TeamInvite)
                                    }
                                    onShared={() =>
                                        handleOnCopied(InviteTypes.TeamInvite)
                                    }
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
        </LeafDialog>
    )
}

export default memo(InviteToChallengeModal)
