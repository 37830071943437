import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { MeasureUnit } from '../../../graphql/generated/autogenerated'
import { MEASUREMENTS, type MEASUREMENTS_TYPE } from '../../constants'

const OUNCE_AS_CUP = 8
const MILLILITER_AS_CUP = 250

export const useGlassesMeasurementConversion = (
    glasses: number,
    intakeMeasurement?: MeasureUnit
) => {
    const { formatNumber } = useIntl()

    return useMemo(() => {
        const format = (measurement: number, unit: MEASUREMENTS_TYPE) =>
            formatNumber(measurement ?? 0, {
                style: 'unit',
                unit,
                unitDisplay: 'short'
            })

        switch (intakeMeasurement) {
            case MeasureUnit.Oz:
                return format(glasses * OUNCE_AS_CUP, MEASUREMENTS.OUNCE)
            case MeasureUnit.Ml:
                return format(
                    glasses * MILLILITER_AS_CUP,
                    MEASUREMENTS.MILLILITER
                )
            default:
                return format(
                    glasses * MILLILITER_AS_CUP,
                    MEASUREMENTS.MILLILITER
                )
        }
    }, [intakeMeasurement, glasses, formatNumber])
}

export default useGlassesMeasurementConversion
