// React and React-related
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// External libraries
import { Box, Stack } from '@mui/material'
import { PageLayout } from '@thriveglobal/thrive-web-leafkit'

// Components
import BackActionHeader from '../../../components/layout/BackActionHeader'
import ChallengeCompletionComponents from '../../../components/features/Active/ChallengeCompletion/ChallengeCompletionComponents'
import ChallengeHeader from '../../../components/features/Active/ChallengeHeader'
import ChallengeQuote from '../../../components/features/Shared/ChallengeQuote'
import ChallengeTabs from '../../../components/features/Active/ChallengeTabs'
import CommunityContainer from '../../../components/features/CommunityContainer'
import YourGoalsContainer from '../../../components/features/Active/Goals'
import type { RefetchUnifiedChallengeAvailability } from '../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import ChallengeSkeleton from '../../../../components/features/Challenge/ChallengeSkeleton/ChallengeSkeleton'
import Page from '../../../components/layout/Page'
// Decorators
import {
    BoundaryName,
    BoundaryType,
    withErrorBoundary
} from '../../../decorators/withErrorBoundary/withErrorBoundary'

// GraphQL
import {
    useGetMeQuery,
    type Company,
    type SocialGroup,
    type UnifiedChallenge
} from '../../../../graphql/generated/autogenerated'

// Routes
import { ROUTES } from '../../../../routes'

// Utils
import { stripBOM } from '../../../../shared/utils/bom'
import useGoalChallengeData from '../../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'
import useGetSocialGroups, {
    type SocialGroupsValues
} from '../../../hooks/useGetSocialGroups/useGetSocialGroups'
import useGetChallengeUserChallenge from '../../../hooks/useGetChallengeUserChallenge/useGetChallengeUserChallenge'

type ChallengeActiveProps = {
    fetchAvailabilityLoading: boolean
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const ChallengeActive = ({
    fetchAvailabilityLoading,
    refetchUnifiedChallengeAvailability
}: ChallengeActiveProps) => {
    const [tabIndex, setTabIndex] = useState(0)
    const history = useHistory()
    const onBack = useCallback(() => {
        history.push(ROUTES.HOME)
    }, [history])

    const { challengeId } = useParams<{
        challengeId: string
    }>()

    const {
        participation,
        data,
        loading,
        refetch: refetchParticipation
    } = useGetChallengeUserChallenge({
        challengeId: stripBOM(challengeId)
    })

    const {
        socialGroup,
        mainCommunitySocialGroup,
        displayName,
        socialGroupsRefetch,
        loading: socialGroupsLoading
    } = useGetSocialGroups(challengeId, {
        skip: !challengeId
    })
    const { data: identity } = useGetMeQuery()

    const challenge = useMemo(() => {
        return data?.unifiedChallenges.unifiedChallenge as UnifiedChallenge
    }, [data])

    const {
        sleepHabits,
        userPlant,
        refetch: refetchChallengeData,
        loading: challengeLoading
    } = useGoalChallengeData({
        challenge,
        socialGroup,
        loading
    })

    const renderTabContent = () => {
        switch (tabIndex) {
            case 0:
                return (
                    <YourGoalsContainer
                        challenge={challenge}
                        loading={loading}
                        userPlant={userPlant}
                        socialGroup={socialGroup}
                    />
                )
            case 1:
                return (
                    <PageLayout maxWidth="lg">
                        <CommunityContainer
                            challenge={challenge}
                            loading={loading}
                            refetch={refetchParticipation}
                        />
                    </PageLayout>
                )
            default:
                return null
        }
    }

    if (
        fetchAvailabilityLoading ||
        loading ||
        challengeLoading ||
        socialGroupsLoading
    ) {
        return <ChallengeSkeleton />
    }

    return (
        <PageLayout maxWidth="lg">
            <Page>
                <BackActionHeader onBack={onBack} />
                <Stack gap={6}>
                    <ChallengeHeader
                        challenge={challenge}
                        loading={loading}
                        participation={participation}
                        company={identity?.identity.me.company as Company}
                        socialGroups={
                            {
                                displayName,
                                mainCommunitySocialGroup:
                                    mainCommunitySocialGroup as SocialGroup,
                                socialGroup: socialGroup as SocialGroup,
                                socialGroupsRefetch
                            } as SocialGroupsValues
                        }
                        refetchUnifiedChallengeAvailability={async () => {
                            await refetchUnifiedChallengeAvailability()
                            await socialGroupsRefetch()
                            await refetchParticipation()
                            await refetchChallengeData()
                        }}
                    />
                    <ChallengeQuote challenge={challenge} loading={loading} />
                </Stack>
                <Box
                    sx={{
                        backgroundImage: `url(${challenge?.backgroundImage})`,
                        backgroundPosition: '50% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% min(415px, 100%)'
                    }}
                    pb={26}
                >
                    <Stack gap={3}>
                        <PageLayout maxWidth="lg">
                            <ChallengeTabs
                                challenge={challenge}
                                tabIndex={tabIndex}
                                setTabIndex={setTabIndex}
                            />
                        </PageLayout>
                        {renderTabContent()}
                    </Stack>
                    <ChallengeCompletionComponents
                        challenge={challenge}
                        refetch={refetchParticipation}
                        participation={participation}
                        sleepHabits={sleepHabits}
                        loading={loading}
                    />
                </Box>
            </Page>
        </PageLayout>
    )
}

export default withErrorBoundary(
    BoundaryName.CHALLENGE_ACTIVE,
    BoundaryType.PAGE,
    ChallengeActive
)
