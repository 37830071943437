import { useMemo } from 'react'
import {
    WearablesReportInterval,
    useGetSleepSummaryStatsQuery
} from '../../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import useCurrentDateTime from '../useCurrentDateTime/useCurrentDateTime'

const calculateAverage = (records: number[]): number | undefined => {
    const validRecords = records.filter(Number.isFinite)
    return validRecords.length
        ? validRecords.reduce((a, b) => a + b, 0) / validRecords.length
        : undefined
}

type useGetChallengeWearablesSleepSummaryResults =
    | {
          /**
           * The average bedtime start in seconds since midnight UTC.
           */
          averageBedTimeStart?: number
          /**
           * The average minutes asleep.
           */
          averageMinutesAsleep?: number
          loading: false
          error: undefined
      }
    | {
          averageBedTimeStart: undefined
          averageMinutesAsleep: undefined
          loading: true
          error: undefined
      }
    | {
          averageBedTimeStart: undefined
          averageMinutesAsleep: undefined
          loading: false
          error: ApolloError
      }

const useGetWearablesSleepSummary = (
    startDate?: Date,
    endDate?: Date
): useGetChallengeWearablesSleepSummaryResults => {
    const noopTime = useCurrentDateTime()
    const { data, loading, error } = useGetSleepSummaryStatsQuery({
        skip: !startDate || !endDate,
        variables: {
            intervalType: WearablesReportInterval.Monthly,
            from: startOfDay(startDate ?? noopTime).toISOString(),
            to: endOfDay(endDate ?? noopTime).toISOString()
        }
    })

    return useMemo(() => {
        if (loading) {
            return {
                averageBedTimeStart: undefined,
                averageMinutesAsleep: undefined,
                loading: true,
                error: undefined
            }
        }

        if (error) {
            return {
                averageBedTimeStart: undefined,
                averageMinutesAsleep: undefined,
                loading: false,
                error
            }
        }

        // Could be more than 1 summary if challenge spans between 2 months
        const sleepSummaries = data?.wearables.insights.sleepSummaryStats ?? []
        const averageBedTimeStart = calculateAverage(
            sleepSummaries.map((summary) => summary.averageBedTimeStart)
        )
        const averageMinutesAsleep = calculateAverage(
            sleepSummaries.map((summary) => summary.averageMinutesAsleep)
        )

        return {
            averageBedTimeStart,
            averageMinutesAsleep,
            loading: false,
            error: undefined
        }
    }, [loading, error, data?.wearables.insights.sleepSummaryStats])
}

export default useGetWearablesSleepSummary
