import { createContext } from 'react'
import { Visibility } from '../../../../graphql/generated/autogenerated'
import { QuestionData } from '../withStorySubmissionProvider/storySubmissionContext'

export type StorySubmissionData = {
    id: string
    challengeId: string
    userChallengeActivityId: string
    storyType?: Visibility
    questionData?: QuestionData[]
}

export interface StorySubmissionStateProviderValue {
    answeredQuestions: QuestionData[]
    isSingleQuestionStory: boolean
    visibility?: Visibility
    errorDescription?: string
    onAnswer: (index: number, answer: any) => void
    onVisibility: (visibility: Visibility) => void
    setErrorDescription: (errorDescription?: string) => void
}

export const defaultContextValue: StorySubmissionStateProviderValue = {
    answeredQuestions: [],
    isSingleQuestionStory: false,
    visibility: undefined,
    errorDescription: undefined,
    onAnswer: () => ({}),
    onVisibility: () => ({}),
    setErrorDescription: () => ({})
}

export default createContext(defaultContextValue)
