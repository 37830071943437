// External dependencies
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Button, Stack, useMediaQuery } from '@mui/material'

// Local components
import BackActionHeader from '../../../components/layout/BackActionHeader'
import ChallengeTemplateSkeleton from '../../../components/features/Intro/IntroSkeleton'
import DailyGoalPreview from '../../../components/features/Shared/DailyGoals/DailyGoalPreview'
import IntroDialogManager, {
    type DialogType
} from '../../../components/features/Intro/IntroDialogManager'
import IntroHeader from '../../../components/features/Intro/IntroHeader'
import IntroHowItWorks from '../../../components/features/Intro/IntroHowItWorks'
import IntroParticipation from '../../../components/features/Intro/IntroParticipation'
import DailyGoalPreviewMoreButton from '../../../components/features/Shared/DailyGoals/DailyGoalPreviewDialog/DailyGoalPreviewButton'

// Local utilities and types
import { CHALLENGE_TYPE } from '../../../constants'
import {
    BoundaryName,
    BoundaryType,
    withErrorBoundary
} from '../../../decorators/withErrorBoundary/withErrorBoundary'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../routes'
import type { RefetchUnifiedChallengeAvailability } from '../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import useChallengeIntro from '../../../hooks/useChallengeIntro/useChallengeIntro'

// Thrive dependencies
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'

export interface ChallengeIntroProps {
    isTemplate: boolean
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const ChallengeIntro: React.FC<ChallengeIntroProps> = ({
    isTemplate,
    refetchUnifiedChallengeAvailability
}) => {
    const { challengeId } = useParams<{ challengeId: string }>()

    const { breakpoints } = useTheme()
    const { socialGroupId } = useParams<{
        socialGroupId: string
    }>()
    const isMobileScreen = useMediaQuery(breakpoints.down('sm'))
    const navigate = useCrossAppNavigation()
    const [activeDialog, setActiveDialog] = useState<DialogType>(null)
    const [groupChallengeType, setGroupChallengeType] =
        useState<GroupChallengeType>()
    const { loading, error, challenge, goals, startDate, endDate } =
        useChallengeIntro(challengeId, isTemplate)

    const onSelectParticipationType = useCallback(
        (type: GroupChallengeType) => {
            setGroupChallengeType(type)
            setActiveDialog('create')
        },
        []
    )

    useEffect(() => {
        if (socialGroupId) {
            setActiveDialog('joinCompany')
        }
    }, [socialGroupId])

    if (error) throw error

    const handleOnBack = useCallback(() => {
        navigate(ROUTES.HOME)
    }, [navigate])

    if (loading) return <ChallengeTemplateSkeleton />

    return (
        <Stack
            py={3}
            gap={isMobileScreen ? 4 : 6}
            data-testid="challenge-intro"
        >
            <Stack gap={2}>
                <BackActionHeader onBack={handleOnBack} />
                <IntroHeader
                    isTemplate={isTemplate}
                    challenge={challenge}
                    startDate={startDate}
                    endDate={endDate}
                    goals={goals}
                    actions={
                        !isTemplate && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    switch (challenge?.challenge_type) {
                                        case CHALLENGE_TYPE.GROUP:
                                            setActiveDialog('join')
                                            break
                                        case CHALLENGE_TYPE.COMPANY:
                                            setActiveDialog('joinCompany')
                                            break
                                    }
                                }}
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        defaultMessage="Join this Challenge"
                                        description="text for button to join the challenge"
                                    />
                                </CoreTypography>
                            </Button>
                        )
                    }
                />
            </Stack>
            {isTemplate && (
                <IntroParticipation onSelect={onSelectParticipationType} />
            )}
            {!!goals?.length && (
                <>
                    <DailyGoalPreview goals={goals} />
                    <DailyGoalPreviewMoreButton goals={goals} />
                </>
            )}
            <IntroHowItWorks challenge={challenge as UnifiedChallenge} />
            {challenge && (
                <IntroDialogManager
                    challenge={challenge}
                    isTemplate={isTemplate}
                    groupChallengeType={groupChallengeType}
                    activeDialog={activeDialog}
                    onClose={() => setActiveDialog(null)}
                    refetchUnifiedChallengeAvailability={
                        refetchUnifiedChallengeAvailability
                    }
                />
            )}
        </Stack>
    )
}

export default withErrorBoundary(
    BoundaryName.CHALLENGE_INTRO,
    BoundaryType.PAGE,
    (props: ChallengeIntroProps) => {
        return (
            <PageLayout maxWidth="lg">
                <ChallengeIntro {...props} />
            </PageLayout>
        )
    }
)
