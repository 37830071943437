import { LeafExternalLink } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { DEFAULT_TERMS } from '../../../../constants'
import { UnifiedChallenge } from '../../../../graphql/generated/autogenerated'
import { useShowWalmartBetterChoicesChallenge } from '../../../../shared/hooks'
import { GQLNull } from '../../../utils/Nulls'

export type TermsAndConditionsProps = {
    termsAndConditionsUrl?: string | GQLNull
    challenge?: UnifiedChallenge
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
    termsAndConditionsUrl,
    challenge
}) => {
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)

    if (!termsAndConditionsUrl && !isWalmartPrizeChallenge) {
        return <></>
    }

    return (
        <LeafExternalLink
            externalLink={
                termsAndConditionsUrl ? termsAndConditionsUrl : DEFAULT_TERMS
            }
        >
            <FormattedMessage
                defaultMessage="Terms and conditions"
                description="link text that will navigate a user to a page that contains description of the current terms and conditions of the challenge"
            />
        </LeafExternalLink>
    )
}

export default memo(TermsAndConditions)
