import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import AccordionGroup from '../../../../../elements/AccordionGroup'

type PrivacyInfoProps = {
    deviceEnabled: boolean
}

const PrivacyInfo: React.FC<PrivacyInfoProps> = ({ deviceEnabled }) => {
    return (
        <Stack gap={2} data-testid="privacy-info">
            <AccordionGroup
                children={[
                    {
                        summary: (
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Who can see my display name?"
                                    description="title for display name privacy information"
                                />
                            </CoreTypography>
                        ),
                        details: (
                            <Stack gap={2}>
                                <CoreTypography variant="body1">
                                    <FormattedMessage
                                        defaultMessage="Your display name will appear on the Challenge activity feed, and leaderboard."
                                        description="description for display name privacy information"
                                    />
                                </CoreTypography>
                                <CoreTypography variant="body2">
                                    <FormattedMessage
                                        defaultMessage="Note: Challenge display names may be provided to your Company for Challenge celebrations."
                                        description="note for display name privacy information"
                                    />
                                </CoreTypography>
                            </Stack>
                        )
                    },
                    {
                        summary: (
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Who can see my activity?"
                                    description="title for activity privacy information"
                                />
                            </CoreTypography>
                        ),
                        details: (
                            <Stack gap={2}>
                                <CoreTypography variant="body1">
                                    <FormattedMessage
                                        defaultMessage="Your daily goal activity will appear on the activity feed and Challenge leaderboard. Activity will be visible to everyone at your company."
                                        description="description for activity privacy information"
                                    />
                                </CoreTypography>
                                <CoreTypography variant="body2">
                                    <FormattedMessage
                                        defaultMessage="Note: Challenge display names may be provided to your Company for Challenge celebrations."
                                        description="note for display name privacy information"
                                    />
                                </CoreTypography>
                            </Stack>
                        )
                    },
                    {
                        summary: (
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    defaultMessage="Should I participate as an individual or on a team?"
                                    description="title for activity privacy information"
                                />
                            </CoreTypography>
                        ),
                        details: (
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    defaultMessage="It’s up to you! Participation method only changes whether you show up in the team leaderboard on a team and you can bundle your earned plants together with your teammates. All participants experience the same functionality and get the same daily goals throughout the Challenge."
                                    description="description for activity privacy information"
                                />
                            </CoreTypography>
                        )
                    },
                    ...(deviceEnabled
                        ? [
                              {
                                  summary: (
                                      <CoreTypography variant="h5">
                                          <FormattedMessage
                                              defaultMessage="Who can see my activity points?"
                                              description="title for activity points privacy information"
                                          />
                                      </CoreTypography>
                                  ),
                                  details: (
                                      <Stack gap={2}>
                                          <CoreTypography variant="body1">
                                              <FormattedMessage
                                                  defaultMessage="If you’re participating in a Challenge with activity goals, your activity points will appear on the Challenge leaderboard, along with your display name. Everyone in the Challenge will see your cumulative activity points for the Challenge."
                                                  description="description for activity privacy information"
                                              />
                                          </CoreTypography>
                                      </Stack>
                                  )
                              },
                              {
                                  summary: (
                                      <CoreTypography variant="h5">
                                          <FormattedMessage
                                              defaultMessage="What if I don't have a wearable?"
                                              description="title for wearable privacy information"
                                          />
                                      </CoreTypography>
                                  ),
                                  details: (
                                      <Stack gap={2}>
                                          <CoreTypography variant="body1">
                                              <FormattedMessage
                                                  defaultMessage="No worries! For Challenges that have activity goals, you can enter activity manually. We’ll convert your activity to activity points so it counts toward your activity goal."
                                                  description="description for activity privacy information"
                                              />
                                          </CoreTypography>
                                      </Stack>
                                  )
                              }
                          ]
                        : [])
                ]}
            />
        </Stack>
    )
}

export default memo(PrivacyInfo)
