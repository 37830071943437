import { Card, Grid, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../../routes'
// import DailyGoalCardSkeleton from '../DailyGoals/DailyGoalCard/DailyGoalCardSkeleton'
// import IntroductionHowItWorks from '../Introduction/IntroductionHowItWorks'
// import IntroductionHowItWorksSkeleton from '../Introduction/IntroductionHowItWorksSkeleton'
import BackActionHeader from '../../../layout/BackActionHeader'
import DailyGoalCardSkeleton from '../../Shared/DailyGoals/DailyGoalCard/DailyGoalCardSkeleton'

const ChallengeTemplateSkeleton: React.FC = () => {
    const { gradient, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const history = useHistory()
    const onBack = useCallback(() => {
        history.push(ROUTES.HOME)
    }, [history])
    return (
        <Stack pb={3} gap={isMobile ? 6 : 9}>
            <Stack>
                <Stack py={3}>
                    <BackActionHeader onBack={onBack} />
                </Stack>
                <Card>
                    <Stack p={3} pb={4.5} gap={4}>
                        <Stack direction="row" gap={1}>
                            <Skeleton
                                variant="circular"
                                height={16}
                                width={16}
                            />
                            <Skeleton
                                variant="rounded"
                                height={16}
                                width={183}
                            />
                        </Stack>
                        <Stack direction={isMobile ? 'column' : 'row'} gap={3}>
                            <Stack flex={1} gap={3}>
                                <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width="80%"
                                />
                                <Stack>
                                    <Skeleton height={25} width="90%" />
                                    <Skeleton height={25} width="90%" />
                                    <Skeleton height={25} width="90%" />
                                    <Skeleton height={25} width="50%" />
                                </Stack>
                            </Stack>
                            <Stack gap={2}>
                                <Stack direction="row" gap={1.5}>
                                    <Skeleton
                                        variant="circular"
                                        height={40}
                                        width={40}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        height={40}
                                        width={186}
                                    />
                                </Stack>
                                <Stack direction="row" gap={1.5}>
                                    <Skeleton
                                        variant="circular"
                                        height={40}
                                        width={40}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        height={40}
                                        width={186}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
            <Stack gap={2}>
                <Skeleton variant="rounded" height={23} width={151} />
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <DailyGoalCardSkeleton />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DailyGoalCardSkeleton />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DailyGoalCardSkeleton />
                    </Grid>
                </Grid>
                <Skeleton variant="rounded" height={44} width={133} />
            </Stack>
            {/* <IntroductionHowItWorksSkeleton /> */}
        </Stack>
    )
}

export default ChallengeTemplateSkeleton
