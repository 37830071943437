import { Box, Stack } from '@mui/material'
import {
    LeafBackgroundIcon,
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import LinkArrowRight from '../../../../../components/elements/LinkArrowRight/LinkArrowRight'
import { SocialGroup } from '../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../routes'
import { ChevronCard } from '../../../../../shared/components/elements'

export type YourGroupsProps = {
    socialGroups: SocialGroup[]
}

const messages = defineMessages({
    yourGroups: {
        defaultMessage: 'Your Groups',
        description: 'Your groups title'
    },
    joinCreateGroup: {
        defaultMessage: 'Join or create a group',
        description: 'button to navigate to the social groups page'
    },
    socialGroupAria: {
        defaultMessage: 'Navigate to {socialGroup} page',
        description: 'accessibility label describing a social group link'
    }
})

const YourGroups: React.FC<YourGroupsProps> = ({ socialGroups }) => {
    const theme = useTheme()
    const location = useLocation()
    const intl = useIntl()
    const history = useHistory()

    const onNavigate = useCallback(
        (socialGroup: SocialGroup) =>
            history.push(
                generatePath(ROUTES.SINGLE_SOCIAL_GROUP, {
                    socialGroupId: socialGroup.id
                }),
                {
                    previousLocation: ROUTES.SOCIAL_GROUPS
                }
            ),
        [history]
    )

    return (
        <Box data-testid="your-groups">
            <Stack
                direction="row"
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    mb: theme.spacing(3)
                }}
            >
                <CoreTypography
                    customVariant="buttonNormal"
                    sx={{
                        color: theme.palette.primary.main
                    }}
                >
                    {intl.formatMessage(messages.yourGroups)}
                </CoreTypography>

                <LinkArrowRight
                    linkText={intl.formatMessage(messages.joinCreateGroup)}
                    pathName={ROUTES.SOCIAL_GROUPS}
                    state={{
                        previousLocation: location.pathname
                    }}
                />
            </Stack>

            <Stack spacing={1}>
                {socialGroups?.map((socialGroup, index: number) => (
                    <ChevronCard
                        key={index}
                        onNavigate={() => onNavigate(socialGroup)}
                        aria-label={intl.formatMessage(
                            messages.socialGroupAria,
                            { socialGroup: socialGroup.name }
                        )}
                    >
                        <Stack
                            direction="row"
                            p={theme.spacing(2.5, 2)}
                            spacing={3}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <LeafBackgroundIcon
                                color={'accent'}
                                icon={<LeafIcon icon={'user-group'} />}
                            />
                            <CoreTypography
                                customVariant="buttonNormal"
                                sx={{
                                    color: theme.palette.primary.main,
                                    display: 'flex',
                                    flexGrow: 1,
                                    my: 'auto'
                                }}
                            >
                                {socialGroup.name}
                            </CoreTypography>
                        </Stack>
                    </ChevronCard>
                ))}
            </Stack>
        </Box>
    )
}

export default React.memo(YourGroups)
