import {
    Box,
    Collapse,
    Fade,
    InputAdornment,
    Stack,
    TextField,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafCircularProgress,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import debounce from 'lodash/debounce'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    InvalidInput,
    SocialGroup,
    useValidateDisplayNameMutation
} from '../../../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../../../hooks/withChallengeCompanySignUp'
import { SwitchSignUpTypeButton } from '../../../../../../../components/features/CompanyChallenge/SignUp'
import TeamVisibility from '../../../../../elements/TeamVisibility'
import useSocialGroups from '../../../../../../hooks/useSocialGroups/useSocialGroups'

type CreatTeamStepProps = {
    challengeId: string
    teamName?: string
    setTeamName: (teamName: string) => void
    isPublicGroup?: boolean
    setIsPublicGroup: (isPublicGroup: boolean) => void
}

const messages = defineMessages({
    yourTeamName: {
        defaultMessage: 'Your team name',
        description: 'placeholder text for the team name text field'
    },
    enterTeamName: {
        defaultMessage: 'Enter a team name',
        description: 'error text for the team name text field'
    },
    teamNameTooLong: {
        defaultMessage: 'Team name must be 28 characters or less',
        description: 'error text for the team name text field'
    },
    somethingWentWrong: {
        defaultMessage: 'Something went wrong',
        description: 'description for an unexpected error'
    },
    teamExists: {
        defaultMessage:
            'It looks like this team already exists. Try a different name or join this team.',
        description: 'error text for the team name text field'
    }
})

const CreateTeamStep: React.FC<CreatTeamStepProps> = ({
    challengeId,
    teamName,
    setTeamName,
    isPublicGroup = false,
    setIsPublicGroup
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage, formatNumber } = useIntl()
    const {
        searchedTeamName,
        addDisable,
        setSearchedTeamName,
        setSocialGroup
    } = useCompanyChallengeSignUpProviderContext()
    const [invalidTeamNameText, setInvalidTeamNameText] = useState('')
    const [validationLoading, setValidationLoading] = useState<boolean>(false)
    const [isInputPristine, setIsInputPristine] = useState(!teamName)
    const [existingTeam, setExistingTeam] = useState<SocialGroup>()
    const { companyGroups } = useSocialGroups({
        challengeId
    })

    useEffect(() => {
        if (searchedTeamName) {
            setTeamName(searchedTeamName)
            setIsInputPristine(false)
        }
    }, [searchedTeamName, setTeamName, setIsInputPristine])

    const [validateDisplayName] = useValidateDisplayNameMutation({
        variables: {
            displayName: String(teamName)
        }
    })

    const debounceValidation = useMemo(
        () =>
            debounce((text) => {
                if (!isInputPristine && text === '') {
                    setInvalidTeamNameText(
                        formatMessage(messages.enterTeamName)
                    )
                    setExistingTeam(undefined)
                } else if (text?.length > 28) {
                    setInvalidTeamNameText(
                        formatMessage(messages.teamNameTooLong)
                    )
                    setExistingTeam(undefined)
                } else if (
                    !isInputPristine &&
                    companyGroups?.some(
                        (group: SocialGroup) => group.name === text
                    )
                ) {
                    setInvalidTeamNameText(formatMessage(messages.teamExists))
                    setExistingTeam(
                        companyGroups?.find(
                            (group: SocialGroup) => group.name === text
                        )
                    )
                } else {
                    setValidationLoading(true)
                    validateDisplayName()
                        .then((response) => {
                            const res =
                                response?.data?.socialGroups
                                    ?.validateDisplayName
                            const invalidFields = (res as InvalidInput)
                                ?.invalidFields

                            if (invalidFields?.length > 0) {
                                setInvalidTeamNameText(invalidFields[0].message)
                                setValidationLoading(false)
                            } else {
                                setInvalidTeamNameText('')
                                setExistingTeam(undefined)
                                setValidationLoading(false)
                            }
                        })
                        .catch(() => {
                            setValidationLoading(false)
                        })
                }
            }, 300),
        [
            isInputPristine,
            companyGroups,
            addDisable,
            formatMessage,
            validateDisplayName
        ]
    )

    useEffect(() => {
        debounceValidation(teamName)
    }, [debounceValidation, teamName, addDisable])

    const onChangeHandler = useCallback(
        (event: any) => {
            setTeamName(event.target.value)
            if (searchedTeamName) {
                setSearchedTeamName(undefined)
            }
            if (isInputPristine) {
                setIsInputPristine(false)
            }
            debounceValidation(event.target.value)
        },
        [
            debounceValidation,
            isInputPristine,
            searchedTeamName,
            addDisable,
            setTeamName,
            setSearchedTeamName
        ]
    )

    return (
        <Stack gap={3} width="100%">
            <Stack gap={1}>
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Create a team"
                        description="Title for the create a social group card inside the company sign up flow"
                    />
                </CoreTypography>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="Create a team name, the team name will appear in the community activity feed, and leaderboards."
                        description="description of what the social group name you enter is for"
                    />
                </CoreTypography>
            </Stack>
            <Stack gap={1}>
                <Stack gap={0.5}>
                    <Stack gap={1}>
                        <Stack width={isMobile ? '100%' : 320}>
                            <TextField
                                id="outlined-basic"
                                data-testid="team-name-text-field"
                                label={formatMessage(messages.yourTeamName)}
                                value={teamName}
                                error={!!invalidTeamNameText}
                                size="medium"
                                fullWidth={true}
                                onChange={onChangeHandler}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Fade in={validationLoading}>
                                                <Stack>
                                                    <LeafCircularProgress
                                                        size={20}
                                                    />
                                                </Stack>
                                            </Fade>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                        <CoreTypography variant="caption">
                            <FormattedMessage
                                defaultMessage="{ count } characters max."
                                description="description for maximum characters"
                                values={{ count: formatNumber(28) }}
                            />
                        </CoreTypography>
                    </Stack>
                    <Collapse in={!!invalidTeamNameText}>
                        <CoreTypography variant="caption" color="error.main">
                            {invalidTeamNameText}
                        </CoreTypography>
                    </Collapse>
                    <Box sx={{ mt: 2 }}>
                        <TeamVisibility
                            isPublicGroup={isPublicGroup}
                            setIsPublicGroup={setIsPublicGroup}
                        />
                    </Box>
                </Stack>
                {existingTeam && (
                    <Box>
                        <SwitchSignUpTypeButton
                            onSwitch={() => setSocialGroup(existingTeam)}
                        />
                    </Box>
                )}
            </Stack>
        </Stack>
    )
}

export default memo(CreateTeamStep)
