import { Stack } from '@mui/material'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactNode } from 'react'

export interface CompletedGoalProps {
    content: ReactNode
}

const CompletedGoal: React.FC<CompletedGoalProps> = ({ content }) => {
    return (
        <Stack
            data-testid="completed-goal"
            direction="row"
            alignItems="center"
            gap={1.5}
        >
            <LeafIcon
                icon="circle-check"
                fontSize="large"
                iconStyle="solid"
                color="success"
            />
            <Stack component="output" aria-live="polite">
                {content}
            </Stack>
        </Stack>
    )
}

export default CompletedGoal
