import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useGoalChallengeDataProviderContext } from '../../../../contexts/withGoalChallenges/useGoalChallengeData/useGoalChallengeDataProviderContext'

import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCard from '../WaveCard'

type CommunityActivityPointsProps = {
    loading: boolean
    challengeTotalSteps?: number
}

const messages = defineMessages({
    totalActivity: {
        defaultMessage: 'Activity points total',
        description: 'total community points title'
    }
})

const CommunityActivityPoints: React.FC<CommunityActivityPointsProps> = ({
    challengeTotalSteps,
    loading
}) => {
    const { formatMessage } = useIntl()

    return (
        <WaveCard
            title={formatMessage(messages.totalActivity)}
            loading={loading}
        >
            <ConditionalSkeleton showSkeleton={loading} variant="rounded">
                <CoreTypography
                    variant="h3"
                    fontSize={60}
                    color="text.secondary"
                >
                    {challengeTotalSteps}
                </CoreTypography>
            </ConditionalSkeleton>
        </WaveCard>
    )
}

export default memo(CommunityActivityPoints)
