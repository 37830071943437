import { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { errorMessages } from '../../constants/errorMessages'
import {
    SocialGroup,
    UnifiedChallenge,
    useChangeCommunityChallengeTeamMutation,
    useJoinCommunityChallengeMutation
} from '../../graphql/generated/autogenerated'

const messages = defineMessages({
    nameNotAllowed: {
        defaultMessage: 'Name not allowed',
        description: 'Entered team name is invalid or not allowed description'
    }
})

export interface useJoinGoalChallengeProps {
    challenge?: UnifiedChallenge
    socialGroup?: SocialGroup
    teamName?: string
    isChangeParticipation?: boolean
    isPublicGroup?: boolean
}

export const useJoinGoalChallengeMutation = ({
    challenge,
    socialGroup,
    teamName,
    isChangeParticipation,
    isPublicGroup
}: useJoinGoalChallengeProps) => {
    const { formatMessage } = useIntl()
    const [invalidText, setInvalidText] = useState('')

    const [joinCommunityChallenge] = useJoinCommunityChallengeMutation({
        variables: {
            challengeId: challenge?.id,
            socialGroupName: teamName === '' ? null : teamName,
            socialGroupId: socialGroup?.id,
            socialGroupPublic: isPublicGroup
        }
    })

    // query requires null value for socialGroupId when it's solo.
    const [changeCommunityChallenge] = useChangeCommunityChallengeTeamMutation({
        variables: {
            challengeId: challenge?.id,
            socialGroupId: socialGroup ? socialGroup.id : null
        }
    })

    const onJoinMutationComplete = useCallback(
        (response: any) => {
            const socialGroupResponse = isChangeParticipation
                ? response?.data?.unifiedChallenges.changeCommunityChallengeTeam
                : response?.data?.unifiedChallenges.joinCommunityChallenge

            if (socialGroupResponse.message) {
                // todo: handle this
                setInvalidText(
                    formatMessage(
                        isChangeParticipation
                            ? errorMessages.general
                            : messages.nameNotAllowed
                    )
                )

                return false
            }

            if (
                !socialGroup &&
                !!(socialGroupResponse as any)?.challengeActivity
            ) {
                return {
                    socialGroupId: (socialGroupResponse as any)
                        .challengeActivity.socialGroupId
                }
            }

            return true
        },
        [socialGroup, isChangeParticipation, formatMessage]
    )

    const joinChallenge = useCallback(
        () =>
            (isChangeParticipation
                ? changeCommunityChallenge
                : joinCommunityChallenge)().then(onJoinMutationComplete),
        [
            isChangeParticipation,
            changeCommunityChallenge,
            joinCommunityChallenge,
            onJoinMutationComplete
        ]
    )

    return { joinChallenge, invalidText }
}

export default useJoinGoalChallengeMutation
