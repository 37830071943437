import { useContext } from 'react'
import StorySubmissionStateProviderContext, {
    StorySubmissionStateProviderValue
} from './storySubmissionStateContext'

export const useStorySubmissionStateProvider =
    (): StorySubmissionStateProviderValue => {
        const context = useContext(StorySubmissionStateProviderContext)

        return context
    }
