import { Collapse, Divider, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    type ErrorMessage,
    type UnifiedChallenge,
    useRemoveUserFromChallengeMutation
} from '../../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../../../../../contexts/withCompanyChallengeProvider/useCompanyChallengeProviderContext'

export interface ParticipantProps {
    participant: any
    removed: boolean
    isAdmin: boolean
    challenge?: UnifiedChallenge
    onRemoved: () => void
}

const Participant: React.FC<ParticipantProps> = ({
    participant,
    removed,
    isAdmin,
    onRemoved,
    challenge
}) => {
    const { userId } = useAppSelector((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [removeUserFromChallengeMutation] =
        useRemoveUserFromChallengeMutation({
            variables: {
                challengeId: challenge?.id,
                userIdToRemove: participant.user.id
            }
        })

    const onDeleteMember = useCallback(() => {
        setLoading(true)
        removeUserFromChallengeMutation()
            .then((response) => {
                const res =
                    response?.data?.unifiedChallenges?.removeUserFromChallenge
                const errorMessage = res as ErrorMessage

                if (errorMessage?.message) {
                    setError(true)
                } else {
                    onRemoved()
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, [onRemoved, removeUserFromChallengeMutation])

    return (
        <Collapse in={!removed}>
            <Stack gap={1} pb={1}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    minHeight={44}
                >
                    <CoreTypography>{participant.displayName}</CoreTypography>
                    {userId !== participant.user.id && isAdmin && (
                        <LoadingButton
                            variant="text"
                            loading={loading}
                            fixWidth={true}
                            onClick={onDeleteMember}
                            sx={{ mr: 1 }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Remove"
                                    description="remove user from group button text"
                                />
                            </CoreTypography>
                        </LoadingButton>
                    )}
                </Stack>
                <Collapse in={!!error} mountOnEnter unmountOnExit>
                    <CoreTypography variant="caption" color="error.main">
                        <FormattedMessage
                            defaultMessage="Something went wrong"
                            description="description for an unexpected error"
                        />
                    </CoreTypography>
                </Collapse>
                <Divider />
            </Stack>
        </Collapse>
    )
}

export default memo(Participant)
