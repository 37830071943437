import { ResponsiveLine, Serie } from '@nivo/line'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import { useMediaQuery } from '@mui/material'

interface LineChartProps {
    legends?: {
        x?: string
        y?: string
    }
    data: Serie[]
}

const LineChart: React.FC<LineChartProps> = ({ data, legends }) => {
    const { palette, breakpoints } = useTheme()
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'))

    const gradientDefs = useMemo(
        () => [
            {
                colors: [
                    {
                        color: palette?.secondary?.main,
                        offset: 70
                    },
                    {
                        color: palette?.background.paper,
                        offset: 100,
                        opacity: 0
                    }
                ],
                id: 'gradient',
                type: 'linearGradient'
            }
        ],
        [palette?.background.paper, palette?.secondary?.main]
    )

    const fill = useMemo(
        () => [
            {
                id: 'gradient',
                match: '*'
            } as const
        ],
        []
    )

    const maxValue = useMemo(() => {
        const values = data.flatMap((serie) =>
            serie.data.map((d) => (d.y ? +d.y : 0))
        )
        return Math.max(...values, 0) * 1.1
    }, [data])

    const margin = useMemo(
        () => ({
            top: 10,
            right: 50,
            bottom: 50,
            left: isSmallScreen ? 55 : 60
        }),
        [isSmallScreen]
    )

    return (
        <ResponsiveLine
            data={data}
            defs={gradientDefs}
            fill={fill}
            margin={margin}
            xScale={{ type: 'point' }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: legends?.x,
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            yScale={{
                type: 'linear',
                min: 0,
                max: maxValue
            }}
            colors={['url(#gradient)']}
            yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={ReactNullValue}
            axisRight={ReactNullValue}
            animate={true}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: legends?.y,
                legendOffset: -55,
                legendPosition: 'middle'
            }}
            enablePoints={false}
            enableArea
            enableGridX={false}
            enableGridY={false}
            isInteractive={false}
            useMesh={true}
            areaOpacity={1}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontFamily: 'GT America LCG, sans-serif',
                            fontSize: 12,
                            color: palette.text.secondary
                        }
                    },
                    legend: {
                        text: {
                            fontFamily: 'GT America LCG, sans-serif',
                            fontSize: 12,
                            color: palette.text.secondary
                        }
                    }
                }
            }}
        />
    )
}

export default LineChart
