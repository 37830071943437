import { defineMessages, useIntl } from 'react-intl'
import { measurementMessages } from '../../components/features/Challenge/DailyGoals/Goals/HydrationGoal/HydrationDailyGoalDialog/DailyGoalMeasurement'
import {
    ArticleDetail,
    AudioResetGoalDetail,
    ExternalLinkDetail,
    GoalType,
    MeasureUnit,
    MicrostepGoalDetail,
    ResetGoalDetail,
    WaterGoalDetail
} from '../../graphql/generated/autogenerated'
import { useHydrationProviderContext } from '../hydrationProviders/withHydrationProvider'
import { YesterdaysRecapData } from '../useGetYesterdaysRecap/useGetYesterdaysRecap'

const messages = defineMessages({
    articleRead: {
        defaultMessage: 'You read {articleName}',
        description: 'cta text for article read goal'
    },
    articleWatched: {
        defaultMessage: 'You watched {articleName}',
        description: 'cta text for article watched goal'
    },
    microstepCheckIn: {
        defaultMessage: 'You did this Microstep: {microstepName}',
        description: 'cta text for check in goal'
    },
    externalLink: {
        defaultMessage: 'You viewed {link}',
        description: 'cta text for external link goal'
    },
    feelingQuestion: {
        defaultMessage: 'You took a moment to reflect on how rested you felt',
        description: 'cta text for feeling question goal'
    },
    pulseCheckIn: {
        defaultMessage: 'You viewed your Daily Check In',
        description: 'cta text for pulse check in goal'
    },
    recipeRead: {
        defaultMessage: 'You read {articleName}',
        description: 'cta text for recipe read goal'
    },
    recipeWatched: {
        defaultMessage: 'You watched {articleName}',
        description: 'cta text for recipe watched goal'
    },
    resetWatched: {
        defaultMessage: 'You watched {resetName}',
        description: 'cta text for reset watched goal'
    },
    resetListened: {
        defaultMessage: 'You listened to {audioResetName}',
        description: 'cta text for reset listened goal'
    },
    roleModelRead: {
        defaultMessage: 'You read {articleName}',
        description: 'cta text for role model read goal'
    },
    roleModelWatched: {
        defaultMessage: 'You watched {articleName}',
        description: 'cta text for role model watched goal'
    },
    steps: {
        defaultMessage: 'You achieved your activity point goal.',
        description: 'cta text for steps goal'
    },
    water: {
        defaultMessage: 'You drank {unit} {measurement} of water',
        description: 'cta text for water goal'
    }
})

export interface RecapGoalMetadata {
    text: string
    ctaText: string | undefined
    ctaClick: (() => void) | undefined
}

export const useGetRecapGoalMetadata = (
    goals?: YesterdaysRecapData['recap']
) => {
    const { formatMessage, formatNumber } = useIntl()
    const { intakeMeasurement } = useHydrationProviderContext()
    const completedGoals = goals?.filter((goal) => goal?.completed) || []
    return completedGoals?.map((goal) => {
        let text = ''
        let ctaText: string | undefined
        let ctaClick: (() => void) | undefined
        switch (goal?.challengeGoal?.goalType) {
            case GoalType.ArticleRead:
                text = formatMessage(messages.articleRead, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.ArticleWatched:
                text = formatMessage(messages.articleWatched, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.CheckIn:
                text = formatMessage(messages.microstepCheckIn, {
                    microstepName: (
                        goal?.goalEntityDetail as MicrostepGoalDetail
                    )?.microstep?.title
                })
                break
            case GoalType.ExternalLink:
                text = formatMessage(messages.externalLink, {
                    link: (goal?.goalEntityDetail as ExternalLinkDetail)?.name
                })
                break
            case GoalType.FeelingQuestion:
                text = formatMessage(messages.feelingQuestion)
                break
            case GoalType.PulseCheckIn:
                text = formatMessage(messages.pulseCheckIn)
                break
            case GoalType.RecipeRead:
                text = formatMessage(messages.recipeRead, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.RecipeWatched:
                text = formatMessage(messages.recipeWatched, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.ResetWatched:
                text = formatMessage(messages.resetWatched, {
                    resetName: (goal?.goalEntityDetail as ResetGoalDetail)
                        ?.resetInfo?.name
                })
                break
            case GoalType.ResetListened:
                text = formatMessage(messages.resetListened, {
                    audioResetName: (
                        goal?.goalEntityDetail as AudioResetGoalDetail
                    )?.audioReset?.name
                })
                break
            case GoalType.RoleModelRead:
                text = formatMessage(messages.roleModelRead, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.RoleModelWatched:
                text = formatMessage(messages.roleModelWatched, {
                    articleName: (goal?.goalEntityDetail as ArticleDetail)
                        ?.articleInfo?.title
                })
                break
            case GoalType.Steps:
                text = formatMessage(messages.steps)
                break
            case GoalType.Water:
                text = formatMessage(messages.water, {
                    unit: formatNumber(
                        (goal?.goalEntityDetail as WaterGoalDetail)?.water
                    ),
                    measurement:
                        intakeMeasurement === MeasureUnit.Ml
                            ? formatMessage(measurementMessages.milliliter)
                            : formatMessage(measurementMessages.ounce)
                })
                break
            default:
                break
        }
        return { text, ctaText, ctaClick }
    })
}

export default useGetRecapGoalMetadata
