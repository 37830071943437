import { Stack } from '@mui/material'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SignUpTypes } from '../../../../../enums'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import useJoinCompanyChallengeSocialGroup from '../../../../../hooks/useJoinCompanyChallengeSocialGroup/useJoinCompanyChallengeSocialGroup'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider'
import LabelledCheckbox from '../../../../elements/LabelledCheckbox/LabelledCheckbox'
import ConfirmDetailsBulletList from '../ConfirmDetails/ConfirmDetailsBulletList'

const CONFIRM_AND_SIGN = 'confirmAndSign'

const messages = defineMessages({
    iConfirm: {
        defaultMessage: 'I confirm my participation and agree to the above',
        description: 'confirm participation checkbox label'
    }
})

export interface ConfirmDetailsProps {
    challenge?: UnifiedChallenge
}

const ConfirmAndSign: React.FC<ConfirmDetailsProps> = ({ challenge }) => {
    const { formatMessage } = useIntl()
    const [confirmedParticipation, setConfirmedParticipation] = useState(false)
    const { teamName, socialGroup, addDisable } =
        useCompanyChallengeSignUpProviderContext()
    const { signUpType } = useChallengeSignUpModalContext()
    useJoinCompanyChallengeSocialGroup(
        challenge as UnifiedChallenge,
        signUpType
    )

    const isTeamSignUp = useMemo(
        () => signUpType !== SignUpTypes.solo && (!!teamName || !!socialGroup),
        [signUpType, teamName, socialGroup]
    )

    useEffect(() => {
        addDisable(CONFIRM_AND_SIGN, !confirmedParticipation)
    }, [addDisable, confirmedParticipation])

    return (
        <Stack gap={2} height="100%">
            <ConfirmDetailsBulletList isTeamSignUp={isTeamSignUp} />
            <LabelledCheckbox
                checked={confirmedParticipation}
                onChange={(event) =>
                    setConfirmedParticipation(event.target.checked)
                }
                label={formatMessage(messages.iConfirm)}
            />
        </Stack>
    )
}

export default memo(ConfirmAndSign)
