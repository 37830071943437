import { Stack } from '@mui/material'
import {
    CoreTypography,
    withTruncatedText
} from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { type UnifiedChallenge } from '../../../../../../graphql/generated/autogenerated'
import useGetCategory from '../../../../../hooks/useGetCategory'
import useTextTransform from '../../../../../hooks/useTextTransform'

const TruncatedCoreTypography = withTruncatedText(CoreTypography)

type ChallengeHeaderProps = {
    challenge: UnifiedChallenge
}

const CompletedChallengeCardHeader = ({ challenge }: ChallengeHeaderProps) => {
    const category = useGetCategory(challenge)
    const { toTitleCase } = useTextTransform()

    return (
        <Stack gap={1}>
            <Stack>
                {category && (
                    <CoreTypography variant="body2">
                        {toTitleCase(category)}
                    </CoreTypography>
                )}
                <TruncatedCoreTypography variant="h4" textLines={2}>
                    {challenge.name}
                </TruncatedCoreTypography>
            </Stack>
        </Stack>
    )
}

export default memo(CompletedChallengeCardHeader)
