import { defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'
import {
    ContentCompletedProperties,
    ContentStartedProperties,
    DciSelectedProperties,
    MicrostepCheckinCompletedProperties
} from '@thriveglobal/thrive-web-tracking/dist/Avo'
import { ReactNullValue } from './nulls'

export function createDefaultTrackingProps<TProps>(
    props: TProps
): TProps & typeof defaultTrackingProps {
    return { ...defaultTrackingProps, ...props }
}

const placeholder: any = ReactNullValue

export const defaultContentStartedProps =
    createDefaultTrackingProps<ContentStartedProperties>({
        activityType: placeholder,
        challengeId: placeholder,
        challengeTheme: placeholder,
        challengeType: placeholder,
        contentFormatType: placeholder,
        contentId: placeholder,
        contentType: placeholder,
        contentSource: placeholder,
        contentSubtype: placeholder,
        contentTitle: placeholder,
        dayNumber: placeholder,
        contentUrl: placeholder,
        conversationId: placeholder,
        featureType: placeholder,
        messageType: placeholder,
        resetType: placeholder,
        tabName: placeholder,
        teamId: placeholder,
        teamType: placeholder,
        transactionId: placeholder,
        tstPlatform: placeholder
    })

export const defaultContentCompletedProps =
    createDefaultTrackingProps<ContentCompletedProperties>({
        activityType: placeholder,
        challengeId: placeholder,
        challengeTheme: placeholder,
        challengeType: placeholder,
        contentFormatType: placeholder,
        contentId: placeholder,
        contentSource: placeholder,
        dayNumber: placeholder,
        contentSubtype: placeholder,
        contentTitle: placeholder,
        contentType: placeholder,
        contentUrl: placeholder,
        conversationId: placeholder,
        featureType: placeholder,
        messageType: placeholder,
        resetType: placeholder,
        teamId: placeholder,
        teamType: placeholder,
        transactionId: placeholder,
        tstPlatform: placeholder
    })

export const defaultMicrostepCheckinCompletedProps =
    createDefaultTrackingProps<MicrostepCheckinCompletedProperties>({
        activityType: placeholder,
        challengeId: placeholder,
        challengeTheme: placeholder,
        challengeType: placeholder,
        checkInDate: placeholder,
        dayNumber: placeholder,
        featureType: placeholder,
        firstJourney: placeholder,
        isAutomaticCheckin: placeholder,
        isOnboarding: placeholder,
        journey: placeholder,
        microstepId: placeholder,
        microstepName: placeholder,
        microstepBody: placeholder,
        pageSection: placeholder,
        teamId: placeholder,
        teamType: placeholder,
        journeyId: placeholder,
        journeyLevel: placeholder,
        journeyDay: placeholder,
        tabName: placeholder
    })

export const defaultDailyCheckInSelectedProps =
    createDefaultTrackingProps<DciSelectedProperties>({
        activityType: placeholder,
        challengeId: placeholder,
        challengeTheme: placeholder,
        challengeType: placeholder,
        dailyCheckInId: placeholder,
        dayNumber: placeholder,
        featureType: placeholder,
        teamId: placeholder,
        teamType: placeholder
    })
