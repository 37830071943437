// React and React-related
import { memo, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

// External libraries
import { Grid, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

// Types and enums
import {
    FeelingQuestionStats,
    UnifiedChallenge
} from '../../../graphql/generated/autogenerated'
import { ChallengeThemes } from '../../../enums/challengeThemes'

// Contexts
import { useCompanyTabControllerContext } from '../../contexts/withCompanyTabController'

// Components
import AverageSleepReflection from './Shared/AverageSleepReflection'
import ChallengeAbout from './Shared/ChallengeAbout'
import ChallengeCommunityActivity from './Shared/ChallengeCommunityActivity'
import CommunityActivityPoints from './Shared/ActivityPoints/CommunityActivityPoints'
import CommunityTodayPlantsEarned from './Shared/PlantsEarned/CommunityTodayPlantsEarned'
import CommunityTodayPlantsEarnedFallback from './Shared/PlantsEarned/CommunityTodayPlantsEarned/CommunityTodayPlantsEarnedFallback'
import CommunityTotalPlants from './Shared/TotalPlantsGraph/CommunityTotalPlants'
import CommunityWaterTotal from './Shared/WaterTotal/CommunityWaterTotal'
import ErrorBoundaryWrapper from '../elements/ErrorBoundaryWrapper'
import LeaderboardCard from './Shared/Leaderboards/LeaderboardCard'
import useGoalChallengeData from '../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'
import { useChallengeTheme } from '../../../hooks/useChallengeTheme/useChallengeTheme'
import useGetSocialGroups from '../../hooks/useGetSocialGroups/useGetSocialGroups'

export type CommunityContainerProps = {
    hideTitle?: boolean
    hideTotals?: boolean
    hideActivity?: boolean
    challenge: UnifiedChallenge
    refetch: () => void
    loading: boolean
}

const CommunityContainer: React.FC<CommunityContainerProps> = ({
    hideTitle,
    hideTotals,
    hideActivity,
    challenge,
    refetch,
    loading
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const size = useMemo(() => (isMobile ? 'small' : 'large'), [isMobile])
    const { tabIndex } = useCompanyTabControllerContext()
    const { socialGroup } = useGetSocialGroups(challenge?.id)

    const { deviceEnabled } = useChallengeTheme(challenge)

    const {
        feelingQuestionStats,
        groupedCommunityPlants,
        challengeTotalSteps,
        challengeTotalWater
    } = useGoalChallengeData({
        challenge: challenge as UnifiedChallenge,
        socialGroup,
        loading: false
    })

    useEffect(() => {
        refetch()
    }, [tabIndex, refetch])

    const themeCommunitySpecific = useMemo(() => {
        switch (challenge?.theme) {
            case ChallengeThemes.SLEEP:
                return (
                    <AverageSleepReflection
                        data={feelingQuestionStats as FeelingQuestionStats}
                    />
                )
            case ChallengeThemes.MOVEMENT:
                return (
                    <CommunityActivityPoints
                        challengeTotalSteps={challengeTotalSteps}
                        loading={loading}
                    />
                )
            case ChallengeThemes.HYDRATION:
                return (
                    <CommunityWaterTotal
                        challengeTotalWater={challengeTotalWater}
                        loading={loading}
                    />
                )
            default:
                return <></>
        }
    }, [challenge, challengeTotalSteps, feelingQuestionStats])

    return (
        <Grid container>
            {!hideTitle && (
                <Grid item sm={12}>
                    <CoreTypography variant="body1" fontSize={24} component="p">
                        <FormattedMessage
                            defaultMessage="Community progress"
                            description="title for the community progress tab"
                        />
                    </CoreTypography>
                </Grid>
            )}
            <Grid item xs={12} md={hideActivity ? 12 : 8}>
                <Grid container>
                    {!hideTotals && (
                        <Grid item xs={12} md={6}>
                            <ErrorBoundaryWrapper
                                boundaryName="CommunityTodayPlantsEarned"
                                boundaryType="Feature"
                                fallbackComponent={
                                    CommunityTodayPlantsEarnedFallback
                                }
                            >
                                <CommunityTodayPlantsEarned
                                    size={size}
                                    groupedCommunityPlants={
                                        groupedCommunityPlants
                                    }
                                />
                            </ErrorBoundaryWrapper>
                        </Grid>
                    )}
                    {!hideTotals && (
                        <Grid item xs={12} md={6}>
                            {themeCommunitySpecific}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <CommunityTotalPlants
                            communityGardenInfo={groupedCommunityPlants}
                            challenge={challenge}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LeaderboardCard
                            challenge={challenge}
                            socialGroup={socialGroup}
                            deviceEnabled={deviceEnabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!hideActivity && (
                <Grid item xs={12} md={4}>
                    <ChallengeCommunityActivity />
                </Grid>
            )}

            {!hideTotals && (
                <Grid item xs={12}>
                    <ChallengeAbout challenge={challenge as UnifiedChallenge} />
                </Grid>
            )}
        </Grid>
    )
}

export default memo(CommunityContainer)
