import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { memo } from 'react'

const EarnPrizes: React.FC<SvgIconProps> = ({ ...props }) => {
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 160 161'}
            sx={{ width: 160, height: 161, fill: 'transparent' }}
            {...props}
        >
            <g filter="url(#filter0_d_951_164342)">
                <circle cx="69.6115" cy="65.7219" r="49.3889" fill="white" />
                <circle
                    cx="69.6115"
                    cy="65.7219"
                    r="48.8889"
                    stroke="#253858"
                    stroke-opacity="0.16"
                />
            </g>
            <circle cx="110.445" cy="24.1104" r="21" fill="#70A3BD" />
            <path
                d="M89.2937 84.7782V63.0361H50.0225V84.7782C50.0225 85.7653 50.4146 86.7119 51.1125 87.4098C51.8105 88.1078 52.7571 88.4999 53.7441 88.4999H85.572C86.559 88.4999 87.5057 88.1078 88.2036 87.4098C88.9016 86.7119 89.2937 85.7653 89.2937 84.7782Z"
                stroke="#70A3BD"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M69.6582 88.4958V63.0283"
                stroke="#70A3BD"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.5018 57.0781V59.3111C92.5018 60.2982 92.1097 61.2448 91.4118 61.9428C90.7138 62.6407 89.7672 63.0328 88.7801 63.0328H50.5361C49.5491 63.0328 48.6025 62.6407 47.9045 61.9428C47.2066 61.2448 46.8145 60.2982 46.8145 59.3111V57.0781C46.8145 56.0911 47.2066 55.1445 47.9045 54.4465C48.6025 53.7485 49.5491 53.3564 50.5361 53.3564H88.7801C89.7672 53.3564 90.7138 53.7485 91.4118 54.4465C92.1097 55.1445 92.5018 56.0911 92.5018 57.0781Z"
                stroke="#70A3BD"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M69.6582 53.3561C69.6582 50.0997 65.8621 42.5 60.1604 42.5C50.8897 42.5 54.2653 53.3561 58.7053 53.3561"
                stroke="#70A3BD"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M69.6582 53.3561C69.6582 50.0997 73.4543 42.5 79.1559 42.5C88.4266 42.5 85.0511 53.3561 80.6111 53.3561"
                stroke="#70A3BD"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M110.813 32.4303C109.087 32.9186 107.238 32.7011 105.672 31.8258C104.106 30.9505 102.952 29.4891 102.464 27.7629C99.7937 18.3244 112.523 19.9606 116.71 13.8752C116.762 13.8194 116.828 13.7789 116.901 13.7582C116.974 13.7374 117.052 13.7373 117.125 13.7579C117.2 13.7679 117.272 13.7964 117.334 13.841C117.395 13.8855 117.444 13.9446 117.477 14.013C121.899 23.2735 116.276 30.8851 110.813 32.4303Z"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M111.082 23.4482C108.595 24.9538 106.424 26.928 104.689 29.2613L102.133 33.0931"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_951_164342"
                    x="1.22266"
                    y="1.33301"
                    width="158.777"
                    height="158.777"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="11" dy="15" />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.305882 0 0 0 0 0.529412 0 0 0 0 0.639216 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_951_164342"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_951_164342"
                        result="shape"
                    />
                </filter>
            </defs>
        </SvgIcon>
    )
}

export default memo(EarnPrizes)
