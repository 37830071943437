import { Box, Button, Grid, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ErrorBoundaryWrapper from '../../../../components/elements/ErrorBoundaryWrapper'
import HiddenStatusUpdate from '../../../../components/elements/HiddenStatusUpdate/HiddenStatusUpdate'
import SearchField from '../../../../components/elements/SearchField/SearchField'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import SocialGroupModal from '../../../../modals/SocialGroupModal/SocialGroupModal'
import SocialGroupsHeader from '../SocialGroupsHeader/SocialGroupsHeader'
import SocialGroupsList from '../SocialGroupsList/SocialGroupsList'
import SocialGroupsHomeContentFallback from './SocialGroupsHomeContentFallback'

const messages = defineMessages({
    search: {
        defaultMessage: 'Search',
        description:
            'placeholder text that is shown inside a search for social group text field'
    },
    searchAriaLabel: {
        defaultMessage: 'Social Groups list automatic search input',
        description: 'aria label describing what the search is for'
    },
    createAGroup: {
        defaultMessage: 'Create a group',
        description:
            'button text that will open up a modal that allows the user to create a new social group'
    },
    socialGroupListCount: {
        defaultMessage:
            'Social Groups list has {itemCount, plural, one {# item} other {# items}}',
        description: 'aria label describing how many items in the list'
    }
})

export type SocialGroupsHomeContentProps = {
    displayName?: string
    socialGroupsForUser?: SocialGroup[]
    socialGroupsForCompany?: SocialGroup[]
    disableActions: boolean
    socialGroupsHomeRefetch: () => Promise<any>
}

const SocialGroupsHomeContent: React.FC<SocialGroupsHomeContentProps> = ({
    displayName,
    socialGroupsForUser,
    socialGroupsForCompany,
    disableActions,
    socialGroupsHomeRefetch
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const [openSocialGroupModal, setOpenSocialGroupModal] =
        React.useState(false)
    const [socialGroupsToJoin, setSocialGroupsToJoin] = useState<SocialGroup[]>(
        []
    )
    const [joinGroupQueryActive, setJoinGroupQueryActive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const addToJoinList = useCallback(
        (socialGroup: SocialGroup, join: boolean) => {
            if (join) {
                setSocialGroupsToJoin((sg) => [...sg, socialGroup])
            } else {
                setSocialGroupsToJoin((sg) =>
                    sg.filter((s) => s.id !== socialGroup.id)
                )
            }
        },
        []
    )

    return (
        <>
            <PageLayout maxWidth={'md'}>
                <SocialGroupsHeader
                    socialGroups={socialGroupsToJoin}
                    displayName={displayName}
                    displayNameRefetch={socialGroupsHomeRefetch}
                    beforeJoinGroup={() => setJoinGroupQueryActive(true)}
                    onJoinGroup={() =>
                        new Promise((resolve) => {
                            socialGroupsHomeRefetch()
                            resolve(setJoinGroupQueryActive(false))
                        })
                    }
                    onJoinGroupSuccess={() => setSocialGroupsToJoin([])}
                />
                <Box
                    sx={{
                        mb: theme.spacing(4.75),
                        mt: theme.spacing(2.5)
                    }}
                    data-testid="social-groups-page"
                >
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            alignItems="center"
                            zeroMinWidth
                        >
                            <SearchField
                                setSearchTerm={setSearchTerm}
                                placeholder={intl.formatMessage(
                                    messages.search
                                )}
                                ariaLabel={intl.formatMessage(
                                    messages.searchAriaLabel
                                )}
                            />
                        </Grid>
                        <HiddenStatusUpdate
                            status={intl.formatMessage(
                                messages.socialGroupListCount,
                                {
                                    itemCount: socialGroupsForCompany?.filter(
                                        (socialGroup) =>
                                            socialGroup.name
                                                ?.toLowerCase()
                                                .includes(
                                                    searchTerm?.toLowerCase()
                                                )
                                    ).length
                                }
                            )}
                        />
                        <Grid item xs={12} sm={6} textAlign="end" zeroMinWidth>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={disableActions}
                                sx={{
                                    p: theme.spacing(2, 6)
                                }}
                                fullWidth={smDown}
                                data-testid="create-a-group-button"
                                onClick={() => setOpenSocialGroupModal(true)}
                                startIcon={
                                    <LeafIcon
                                        icon={'plus'}
                                        fontSize={'small'}
                                    />
                                }
                            >
                                <CoreTypography
                                    customVariant="buttonNormal"
                                    sx={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {intl.formatMessage(messages.createAGroup)}
                                </CoreTypography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <ErrorBoundaryWrapper
                    boundaryName="SocialGroupsList"
                    boundaryType="Feature"
                    fallbackComponent={SocialGroupsHomeContentFallback}
                >
                    <SocialGroupsList
                        searchTerm={searchTerm}
                        addToJoinList={addToJoinList}
                        joinGroupQueryActive={joinGroupQueryActive}
                        userSocialGroups={socialGroupsForUser}
                        allSocialGroups={socialGroupsForCompany}
                    />
                </ErrorBoundaryWrapper>
            </PageLayout>
            <SocialGroupModal
                open={openSocialGroupModal}
                handleOnClose={() => setOpenSocialGroupModal(false)}
                displayName={displayName}
                socialGroupRefetch={socialGroupsHomeRefetch}
            />
        </>
    )
}

export default SocialGroupsHomeContent
