import { Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ViewMoreDialog from '../ViewMoreDialog'
import ResetContentCard from './CompletedResetsCard'
import ResetPlayer from './CompletedResetsPlayer'
import type {
    ResetStats,
    ResetStatsDetail
} from '../../../../../graphql/generated/autogenerated'

type CompletedResetsDialogProps = {
    resetStats: ResetStats
    open: boolean
    onClose: () => void
}

const CompletedResetsDialog = ({
    resetStats,
    open,
    onClose
}: CompletedResetsDialogProps) => {
    const [isPlayerOpen, setIsPlayerOpen] = useState(false)
    const [reset, setReset] = useState<ResetStatsDetail>()

    return (
        <>
            <ViewMoreDialog open={open} onClose={onClose}>
                <Stack gap={5} pb={6}>
                    <Stack gap={2}>
                        <CoreTypography variant="h1" color="primary.main">
                            <FormattedMessage
                                defaultMessage="Resets"
                                description="resets usage description"
                            />
                        </CoreTypography>
                        <CoreTypography variant="h3" color="text.primary">
                            <FormattedMessage
                                defaultMessage="Taking just 60 seconds to reset from stress is key for well-being."
                                description="tips title"
                            />
                        </CoreTypography>
                    </Stack>
                    <Grid container>
                        {resetStats?.resets
                            ?.filter((resetStat) => resetStat?.completed)
                            ?.map((resetStat, index) => (
                                <Grid item key={index} xs={12} md={4}>
                                    <ResetContentCard
                                        reset={resetStat as ResetStatsDetail}
                                        onClick={() => {
                                            setReset(
                                                resetStat as ResetStatsDetail
                                            )
                                            setIsPlayerOpen(true)
                                        }}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Stack>
            </ViewMoreDialog>

            <ResetPlayer
                reset={reset}
                isOpen={isPlayerOpen}
                onClose={() => setIsPlayerOpen(false)}
            />
        </>
    )
}

export default React.memo(CompletedResetsDialog)
