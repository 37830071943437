import { CardMedia, Stack, Button } from '@mui/material'
import {
    CoreTypography,
    LeafChip,
    LeafIcon,
    withTruncatedText,
    LeafFixedMediaCard
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import useTextTransform from '../../../../../hooks/useTextTransform/useTextTransform'
import CompletedStats from '../../../../../shared/components/features/ChallengeCard/ChallengeCardStats/CompletedStats'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import { StatTitle } from '../../../../elements/Stats'
import ChallengeTypeIcon from '../../../../elements/ChallengeTypeIcon/ChallengeTypeIcon'

const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const messages = defineMessages({
    greatJob: {
        defaultMessage:
            'Great work! Be proud of all you accomplished in this challenge.',
        description: 'description for when challenge is finished'
    },
    writeStory: {
        defaultMessage: 'Write your story',
        description: 'description for write story button'
    },
    navigateAriaLabel: {
        defaultMessage: 'Navigate to challenge',
        description: 'description for navigate to challenge button'
    },
    totalGoalsCompleted: {
        defaultMessage: 'Goals Completed',
        description: 'Your total goals completed title'
    },
    totalPlants: {
        defaultMessage: 'Total plants',
        description: 'Total plants stat title (appears above the number)'
    },
    totalActive: {
        defaultMessage: 'total active days',
        description:
            'description text for the total number of days the user has been active in a challenge, the number is shown above the text'
    },
    totalCheckIns: {
        defaultMessage: 'total check-ins',
        description:
            'description text for the total number of microstep check-ins the user has completed in a challenge, the number is shown above the text'
    },
    view: {
        defaultMessage: 'View',
        description: 'description for view button'
    }
})

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
export interface ChallengePastCardProps {
    id: string
    participationId?: string
    name: string
    image: string
    category?: string
    challengeType: ChallengeType
    dateRange?: string
    totalActiveDays?: number
    totalCheckIns?: number
}

const ChallengePastCard: React.FC<ChallengePastCardProps> = ({
    id,
    participationId,
    name,
    image,
    category,
    challengeType,
    dateRange,
    totalActiveDays,
    totalCheckIns
}) => {
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        participationId,
        challengeType,
        isActive: true,
        isPast: true
    })
    const { formatMessage, formatNumber } = useIntl()
    const { toTitleCase } = useTextTransform()

    const renderStats = () => {
        const showCompletedStats =
            challengeType === ChallengeType.company ||
            challengeType === ChallengeType.group

        if (showCompletedStats) {
            return <CompletedStats challenge={{ id: id } as UnifiedChallenge} />
        }

        return (
            <Stack direction="row" gap={2}>
                <StatTitle
                    loading={false}
                    stat={formatNumber(Number(totalActiveDays))}
                    title={formatMessage(messages.totalActive)}
                />
                <StatTitle
                    loading={false}
                    stat={formatNumber(Number(totalCheckIns))}
                    title={formatMessage(messages.totalCheckIns)}
                />
            </Stack>
        )
    }

    const renderButton = () => {
        return (
            <Stack width={'100%'} alignItems="flex-end">
                <Button
                    onClick={navigate}
                    color="primary"
                    size="large"
                    variant="contained"
                    aria-label={formatMessage(messages.navigateAriaLabel)}
                    sx={{
                        gap: 1
                    }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        {formatMessage(messages.view)}
                    </CoreTypography>
                    <LeafIcon fontSize="small" icon="arrow-right" />
                </Button>
            </Stack>
        )
    }

    return (
        <Stack direction="column" gap={2}>
            <LeafFixedMediaCard
                alignMedia="top"
                mediaWidth="md"
                media={
                    <Stack position="relative">
                        <CardMedia
                            component="img"
                            image={image ? image : imagePlaceholder}
                            alt=""
                            height={125}
                        />
                        <Stack
                            position="absolute"
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            width={'100%'}
                            top={16}
                            left={0}
                            px={2}
                        >
                            <LeafChip
                                color={'info'}
                                label={dateRange}
                                variant="filled"
                            />
                            <Stack direction="row">
                                <ChallengeTypeIcon
                                    color="info"
                                    challengeType={challengeType}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                }
                actionAreaProps={{
                    onClick: navigate,
                    'aria-label': formatMessage(messages.navigateAriaLabel)
                }}
            >
                <Stack height={224} direction={'column'}>
                    <Stack justifyContent="space-between" height="100%">
                        <Stack gap={1}>
                            <Stack>
                                <CoreTypography variant="body2">
                                    {toTitleCase(category || '')}
                                </CoreTypography>
                                <TruncatedCoreTypography
                                    variant="h4"
                                    textLines={2}
                                >
                                    {name}
                                </TruncatedCoreTypography>
                            </Stack>
                        </Stack>
                        <Stack gap={0.5}>
                            {renderStats()}
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent={'space-between'}
                                gap={2}
                                pt={4}
                            >
                                {renderButton()}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </LeafFixedMediaCard>
        </Stack>
    )
}

export default memo(ChallengePastCard)
