import { Box } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import ConfirmActionModal from '../../../modals/ConfirmActionModal/ConfirmActionModal'

export type ConfirmActionProps = {
    confirmTitle: string
    confirmActionText: string
    cancelActionText: string
    confirmAction?: () => Promise<any>
    cancelAction?: () => Promise<any>
    children: ReactElement<any, any>
}

const ConfirmAction: React.FC<ConfirmActionProps> = ({
    confirmTitle,
    confirmActionText,
    cancelActionText,
    confirmAction = () => Promise.resolve(),
    cancelAction = () => Promise.resolve(),
    children
}) => {
    const [openAreYouSureModal, setOpenAreYouSureModal] =
        useState<boolean>(false)

    const renderJoinButton = useMemo(() => {
        // Force clone in the onclick property so the component will open the confirm leave on click of the custom button
        return React.cloneElement(children, {
            onClick: () => setOpenAreYouSureModal(true)
        })
    }, [children])

    return (
        <>
            <ConfirmActionModal
                isOpen={openAreYouSureModal}
                title={confirmTitle}
                confirmActionText={confirmActionText}
                cancelActionText={cancelActionText}
                confirmAction={() =>
                    confirmAction().finally(() => setOpenAreYouSureModal(false))
                }
                cancelAction={() =>
                    cancelAction().finally(() => setOpenAreYouSureModal(false))
                }
                handleOnClose={() => setOpenAreYouSureModal(false)}
            />
            <Box>{renderJoinButton}</Box>
        </>
    )
}

export default React.memo(ConfirmAction)
