import { useMemo } from 'react'
import { UnifiedChallenge } from '../../../graphql/generated/autogenerated'
import { CHALLENGE_THEME } from '../../constants'

export const useChallengeTheme = (challenge?: UnifiedChallenge) => {
    return useMemo(
        () => ({
            deviceEnabled: challenge?.theme === CHALLENGE_THEME.MOVEMENT,
            hydrationEnabled: challenge?.theme === CHALLENGE_THEME.HYDRATION,
            sleepEnabled: challenge?.theme === CHALLENGE_THEME.SLEEP
        }),
        [challenge]
    )
}

export default useChallengeTheme
