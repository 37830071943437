// External dependencies
import { Skeleton, Stack } from '@mui/material'
import format from 'date-fns/format'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

// Thrive dependencies
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

// Internal components
import {
    MicrostepCard,
    MicrostepState
} from '../../../../../../../components/todayLegacy'
import CompletedGoal from '../../CompletedGoal'
import GoalTitle from '../../GoalTitle'

// Types & utilities
import {
    ChallengeUserDailyGoal,
    MicrostepGoalDetail,
    UnifiedChallenge,
    useCheckInToMicrostepMutation,
    useCompleteCommunityChallengeDailyGoalMutation
} from '../../../../../../../graphql/generated/autogenerated'
import useZonedDate from '../../../../../../../hooks/useZonedDateTime/useZonedDateTime'
import { ModifiedFormattedMessageType } from 'src/types'
import { defaultMicrostepCheckinCompletedProps } from '../../../../../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../../../../../utils/nulls'
import { FEATURE_FLAGS } from '../../../../../../../constants'

const ModifiedFormattedMessage =
    FormattedMessage as ModifiedFormattedMessageType

const messages = defineMessages({
    illDoThisToday: {
        defaultMessage: "I'll do this today",
        description: 'custom microstep title for the microstep daily goal card'
    },
    illDoThisTodayAccentureFlag: {
        defaultMessage: "I've completed this today",
        description: 'custom microstep title for the microstep daily goal card'
    }
})

export type MicrostepGoalProps = {
    goal: ChallengeUserDailyGoal
    challenge: UnifiedChallenge | undefined
    loading?: boolean
    disabled?: boolean
    hideButton?: boolean
    onComplete?: () => void
}

const MicrostepGoal: React.FC<MicrostepGoalProps> = ({
    goal,
    challenge,
    loading,
    disabled,
    hideButton,
    onComplete
}) => {
    const { palette } = useTheme()
    const { getZonedDate } = useZonedDate()
    const { formatMessage } = useIntl()
    const [checkInToMicrostepMutation] = useCheckInToMicrostepMutation()

    const accentureFlag = useIsFeatureEnabled(
        FEATURE_FLAGS.ACCENTURE_CHALLENGE_TEXT,
        false,
        true,
        {
            challengeType: challenge?.challenge_type,
            theme: challenge?.theme
        }
    )

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    const { microstep, completed } = useMemo(() => {
        return {
            microstep: (goal?.goalEntityDetail as MicrostepGoalDetail)
                ?.microstep,
            completed: goal?.completed
        }
    }, [goal])

    const checkInToMicrostep = useCallback(async () => {
        await checkInToMicrostepMutation({
            variables: {
                microstepIds: [microstep?.id],
                date: format(getZonedDate(), 'yyyy-MM-dd')
            }
        })
        await completeDailyGoal()
        Avo.microstepCheckinCompleted({
            ...defaultMicrostepCheckinCompletedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            checkInDate: format(getZonedDate(), 'yyyy-MM-dd'),
            featureType: 'challenge',
            microstepId: microstep?.id,
            microstepBody: microstep?.body,
            microstepName: microstep?.title,
            tabName: 'Home'
        })
        onComplete?.()
    }, [
        challenge,
        microstep,
        onComplete,
        getZonedDate,
        completeDailyGoal,
        checkInToMicrostepMutation
    ])

    return (
        <Stack gap={2} height="100%" pb={1}>
            <Stack gap={1}>
                <GoalTitle title={microstep?.title} />
                <CoreTypography variant="body2">
                    {microstep?.body}
                </CoreTypography>
            </Stack>
            {completed ? (
                <CompletedGoal
                    content={
                        <CoreTypography
                            variant="body1"
                            component="output"
                            aria-live="polite"
                            color={palette.text.disabled}
                        >
                            <ModifiedFormattedMessage
                                id="message.completedMicrostep"
                                defaultMessage="Completed the Microstep: <b>{title}</b>"
                                description="title for the activity daily goal card"
                                values={{
                                    title: microstep.title,
                                    b: (chunks: React.ReactNode[]) => (
                                        <strong>{chunks}</strong>
                                    )
                                }}
                            />
                        </CoreTypography>
                    }
                />
            ) : loading ? (
                <Skeleton variant="rounded" width="100%" height={218} />
            ) : !hideButton ? (
                <MicrostepCard
                    elevation={0}
                    fillSpace={true}
                    disableRemove={true}
                    disableDialog={true}
                    isChecked={goal.completed}
                    microstep={microstep as any}
                    onChecked={checkInToMicrostep}
                    state={MicrostepState.CheckIn}
                    disabled={goal.completed || disabled}
                    customTitle={
                        accentureFlag
                            ? formatMessage(
                                  messages.illDoThisTodayAccentureFlag
                              )
                            : formatMessage(messages.illDoThisToday)
                    }
                />
            ) : (
                ReactNullValue
            )}
        </Stack>
    )
}

export default MicrostepGoal
