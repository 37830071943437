import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant16: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#133338',
    colorLayer2 = '#285E68',
    colorLayer3 = '#2AA382',
    colorLayer4 = '#FFF8E1',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M26.2043 49.729L25.735 50.612L47.3692 62.1103L47.8385 61.2272L26.2043 49.729Z"
                fill={colorLayer1}
            />
            <path
                d="M29.3691 57.9247C33.6991 58.0947 35.9591 52.8547 32.8691 49.8147C32.0591 49.0247 31.2391 48.3747 30.4791 48.0447C29.7191 47.7147 28.6891 47.5547 27.5491 47.5147C23.2191 47.3447 20.9591 52.5847 24.0491 55.6247C24.8591 56.4147 25.6791 57.0647 26.4391 57.3947C27.1991 57.7247 28.2291 57.8847 29.3691 57.9247Z"
                fill={colorLayer2}
            />
            <path
                d="M23.2091 49.9947C24.0191 50.7047 24.9791 51.3347 26.0591 51.8347C28.9791 53.1947 32.0491 53.2747 34.1991 52.2747C34.0291 51.3947 33.5991 50.5447 32.8591 49.8147C32.0491 49.0247 31.2291 48.3747 30.4691 48.0447C29.7091 47.7147 28.6791 47.5547 27.5391 47.5147C25.5491 47.4347 23.9991 48.5147 23.2091 49.9847V49.9947Z"
                fill={colorLayer1}
            />
            <path
                d="M28.0391 66.0247C29.3491 61.8047 33.8291 59.4447 38.0591 60.7547C42.2791 62.0647 44.6391 66.5447 43.3291 70.7747"
                fill={colorLayer3}
            />
            <path
                d="M43.3291 70.7747C42.0191 74.9947 37.5391 77.3547 33.3091 76.0447C29.0891 74.7347 26.7291 70.2547 28.0391 66.0247"
                fill={colorLayer2}
            />
            <path
                d="M28.3638 65.1753L28.0177 66.1135L54.0994 75.7356L54.4455 74.7974L28.3638 65.1753Z"
                fill={colorLayer1}
            />
            <path
                d="M21.7091 8.2047C22.6391 1.1147 31.1491 -1.91529 36.3791 2.9547C38.0991 4.55471 39.5191 6.32469 40.1591 8.12469C42.4391 14.5947 40.2291 21.2647 35.2291 23.0247C30.2291 24.7847 24.3291 20.9647 22.0591 14.4947C21.4291 12.7147 21.4091 10.4847 21.7091 8.2047Z"
                fill={colorLayer3}
            />
            <path
                d="M26.0691 30.3486L25.5706 31.2155L40.8974 40.0288L41.3959 39.1619L26.0691 30.3486Z"
                fill={colorLayer1}
            />
            <path
                d="M44.6791 17.4931L39.2595 35.5576L40.2173 35.845L45.6369 17.7805L44.6791 17.4931Z"
                fill={colorLayer1}
            />
            <path
                d="M47.1891 24.3947C44.9391 26.8547 40.8491 25.2547 40.8591 21.9247C40.8591 21.0547 40.9591 20.2547 41.1891 19.6547C41.4191 19.0547 41.8991 18.4047 42.4791 17.7647C44.7291 15.3047 48.8191 16.9047 48.8091 20.2347C48.8091 21.1047 48.7091 21.9047 48.4791 22.5047C48.2491 23.1047 47.7691 23.7547 47.1891 24.3947Z"
                fill={colorLayer2}
            />
            <path
                d="M30.7191 29.5847C32.7691 32.2147 30.5191 35.9847 27.2291 35.4247C26.3691 35.2747 25.5991 35.0547 25.0491 34.7247C24.4991 34.3947 23.9391 33.8247 23.3991 33.1347C21.3491 30.5047 23.5991 26.7347 26.8891 27.2947C27.7491 27.4447 28.5191 27.6647 29.0691 27.9947C29.6191 28.3247 30.1791 28.8947 30.7191 29.5847Z"
                fill={colorLayer1}
            />
            <path
                d="M20.4091 39.8547C18.9691 42.9447 21.3791 47.1047 25.7891 49.1547C30.1991 51.2047 34.9291 50.3647 36.3691 47.2747"
                fill={colorLayer2}
            />
            <path
                d="M36.3591 47.2847C37.7991 44.1947 35.3891 40.0347 30.9791 37.9847C26.5691 35.9347 21.8391 36.7747 20.3991 39.8647"
                fill={colorLayer3}
            />
            <path
                d="M20.618 39.4133L20.2236 40.3322L44.7314 50.8515L45.1259 49.9325L20.618 39.4133Z"
                fill={colorLayer1}
            />
            <path
                d="M54.4291 24.0747C51.4091 22.5047 47.3091 24.4247 45.2591 28.3547C43.2191 32.2847 44.0091 36.7447 47.0291 38.3147"
                fill={colorLayer3}
            />
            <path
                d="M47.0391 38.3147C50.0591 39.8847 54.1591 37.9647 56.2091 34.0347C58.2491 30.1047 57.4591 25.6447 54.4391 24.0747"
                fill={colorLayer2}
            />
            <path
                d="M53.9477 23.818L43.1138 46.4706L44.016 46.9021L54.8498 24.2495L53.9477 23.818Z"
                fill={colorLayer1}
            />
            <path
                d="M45.6791 54.4647L31.0691 13.8447C31.0691 13.8447 31.0991 13.8447 31.1191 13.8447C31.6291 13.7647 32.1091 13.5547 32.5591 13.2747L47.1591 53.8647C46.6491 53.9647 46.1491 54.1747 45.6891 54.4647H45.6791Z"
                fill={colorLayer4}
            />
            <path
                d="M27.2791 0.934723C27.2791 0.934723 27.6791 1.12472 28.3191 1.46472L35.8491 22.4047C35.5991 22.5347 35.3391 22.6547 35.0591 22.7547C34.8291 22.8347 34.5891 22.9047 34.3491 22.9547L26.8191 2.0047C27.0991 1.3347 27.2791 0.934723 27.2791 0.934723Z"
                fill={colorLayer1}
            />
            <path
                d="M56.8891 85.3647L36.1391 27.9447L37.7091 27.6147L58.6491 85.5747C58.0591 85.5047 57.4691 85.4447 56.8791 85.3647H56.8891Z"
                fill={colorLayer2}
            />
            <path
                d="M36.1391 27.9447L45.7191 54.4447C46.1691 54.1647 46.6591 53.9647 47.1491 53.8647L37.7191 27.6447V27.6247L36.1391 27.9547V27.9447Z"
                fill={colorLayer1}
            />
            <path
                d="M81.8291 20.6247C80.3391 14.5647 72.6691 12.7547 68.5991 17.4847C67.2591 19.0447 66.1991 20.7047 65.8191 22.3347C64.4691 28.1647 67.0191 33.7347 71.5291 34.7747C76.0291 35.8147 80.7791 31.9447 82.1291 26.1147C82.4991 24.5047 82.3091 22.5747 81.8291 20.6247Z"
                fill={colorLayer3}
            />
            <path
                d="M80.1499 40.1245L67.7034 49.2273L68.2937 50.0345L80.7402 40.9316L80.1499 40.1245Z"
                fill={colorLayer1}
            />
            <path
                d="M62.8803 30.824L61.9598 31.2149L68.3939 46.3653L69.3143 45.9744L62.8803 30.824Z"
                fill={colorLayer1}
            />
            <path
                d="M60.8991 36.5547C63.0891 38.4747 66.4791 36.6947 66.1491 33.8047C66.0591 33.0447 65.9091 32.3647 65.6491 31.8747C65.3891 31.3747 64.9191 30.8647 64.3491 30.3647C62.1591 28.4447 58.7691 30.2247 59.0991 33.1147C59.1891 33.8747 59.3391 34.5547 59.5991 35.0447C59.8591 35.5347 60.3291 36.0547 60.8991 36.5547Z"
                fill={colorLayer2}
            />
            <path
                d="M76.0691 40.0247C74.5391 42.4947 76.8591 45.5547 79.6491 44.7547C80.3791 44.5447 81.0291 44.2747 81.4691 43.9447C81.9191 43.6047 82.3491 43.0547 82.7491 42.4047C84.2791 39.9347 81.9591 36.8747 79.1691 37.6747C78.4391 37.8847 77.7891 38.1547 77.3491 38.4847C76.9091 38.8147 76.4691 39.3747 76.0691 40.0247Z"
                fill={colorLayer1}
            />
            <path
                d="M79.2386 75.2154C84.1734 72.0352 86.1827 66.3674 83.7264 62.556C81.2702 58.7447 75.2786 58.2331 70.3438 61.4133C65.409 64.5936 63.3997 70.2614 65.856 74.0727C68.3122 77.8841 74.3038 78.3957 79.2386 75.2154Z"
                fill={colorLayer3}
            />
            <path
                d="M83.7291 62.5647C83.7291 62.5647 83.7091 62.5347 83.6991 62.5147C81.0191 64.5447 66.6891 74.1847 65.9191 74.6747C68.6591 77.7447 74.7791 78.5947 79.3391 75.6547C84.2691 72.4747 86.1891 66.3747 83.7291 62.5647Z"
                fill={colorLayer1}
            />
            <path
                d="M61.5791 77.9047L61.0191 77.0647L83.4491 62.1547L83.9991 62.9847L61.5791 77.9047Z"
                fill={colorLayer1}
            />
            <path
                d="M57.5609 66.2395C61.2036 63.5025 61.2842 57.4609 57.7411 52.7453C54.1979 48.0297 48.3726 46.4257 44.7299 49.1627C41.0872 51.8997 41.0066 57.9412 44.5497 62.6569C48.0929 67.3725 53.9182 68.9765 57.5609 66.2395Z"
                fill={colorLayer2}
            />
            <path
                d="M44.6791 49.7647C46.6991 52.4047 49.0391 54.6447 50.9791 57.3847C53.0991 60.3947 55.5091 63.1547 58.0391 65.8247C61.1891 62.9247 61.1091 57.2447 57.7291 52.7547C54.1891 48.0447 48.3591 46.4347 44.7191 49.1747C44.6091 49.2547 44.5091 49.3547 44.4091 49.4447C44.4991 49.5447 44.5891 49.6447 44.6791 49.7647Z"
                fill={colorLayer3}
            />
            <path
                d="M45.1252 48.8547L44.3345 49.4669L62.0827 72.389L62.8734 71.7768L45.1252 48.8547Z"
                fill={colorLayer1}
            />
            <path
                d="M82.0691 50.3347C83.6091 52.8747 82.7991 56.1747 80.2591 57.7247C77.7191 59.2647 74.4191 58.4547 72.8691 55.9147"
                fill={colorLayer2}
            />
            <path
                d="M72.8791 55.9147C71.3391 53.3747 72.1492 50.0747 74.6892 48.5247C77.2292 46.9847 80.5291 47.7947 82.0791 50.3347"
                fill={colorLayer3}
            />
            <path
                d="M81.9743 50.1124L65.5235 58.9407L65.9963 59.8218L82.4472 50.9936L81.9743 50.1124Z"
                fill={colorLayer1}
            />
            <path
                d="M57.9091 39.7647C60.5891 38.4947 63.7991 39.6347 65.0691 42.3147C66.3391 44.9947 65.1991 48.2047 62.5191 49.4747"
                fill={colorLayer3}
            />
            <path
                d="M62.5191 49.4747C59.8391 50.7447 56.6291 49.6047 55.3591 46.9247C54.0891 44.2447 55.2291 41.0347 57.9091 39.7647"
                fill={colorLayer2}
            />
            <path
                d="M58.6327 39.4274L57.7067 39.8049L65.3023 58.4361L66.2283 58.0586L58.6327 39.4274Z"
                fill={colorLayer1}
            />
            <path
                d="M65.4791 63.0347L74.2491 26.4047C74.2491 26.4047 74.2191 26.4047 74.2091 26.4047C73.7591 26.3847 73.3191 26.2547 72.9091 26.0547L64.1491 62.6547C64.5991 62.6947 65.0591 62.8247 65.4891 63.0347H65.4791Z"
                fill={colorLayer4}
            />
            <path
                d="M76.3091 14.8647C76.3091 14.8647 75.9891 15.0647 75.4591 15.4247L70.9391 34.3047C71.1691 34.3947 71.4091 34.4747 71.6591 34.5347C71.8691 34.5847 72.0791 34.6147 72.2891 34.6347L76.8091 15.7547C76.5091 15.2047 76.3091 14.8747 76.3091 14.8747V14.8647Z"
                fill={colorLayer1}
            />
            <path
                d="M60.1691 85.2247L71.2091 39.1347L69.8191 38.9947L58.6591 85.5747C59.1691 85.4647 59.6691 85.3547 60.1691 85.2247Z"
                fill={colorLayer2}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant16
