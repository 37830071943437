// External imports
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from '@mui/material'
import { defineMessages, FormattedMessage } from 'react-intl'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

// Internal imports
import { CHALLENGE_SIGNUP_STEPS } from '../../../../../../pages/ChallengeSignup'
import DisplayNameWithValidation from '../../../../../elements/DisplayNameWithValidation'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    displayName: {
        defaultMessage: 'Choose a display name & participation method',
        description: 'display name title'
    },
    displayNameDescription: {
        defaultMessage:
            'Your display name will appear publicly in the Challenge activity feed and leaderboard.',
        description: 'display name description'
    },
    participationTypeHeader: {
        defaultMessage: 'How do you want to take part in the Challenge?',
        description: 'participation type title'
    },
    participationTypeIndividual: {
        defaultMessage: 'As an individual',
        description: 'participation type individual'
    },
    participationTypeIndividualDescription: {
        defaultMessage:
            'Next you’ll be able to search and join a team or create your own.',
        description: 'participation type individual description'
    },
    participationTypeTeam: {
        defaultMessage: 'As a team',
        description: 'participation type team'
    },
    participationTypeTeamDescription: {
        defaultMessage:
            'If you change your mind after signing up, you can still join a team.',
        description: 'participation type team description'
    }
})
export interface ParticipationStepProps {
    setStep: (step: number) => void
    challenge?: UnifiedChallenge
    displayName: string
    setDisplayName: (displayName: string) => void
    nameIsValid: boolean
    setNameIsValid: (nameIsValid: boolean) => void
    participationType: GroupChallengeType | undefined
    setParticipationType: (
        participationType: GroupChallengeType | undefined
    ) => void
    attemptExit: () => void
}

const ParticipationStep = ({
    setStep,
    challenge,
    displayName,
    setDisplayName,
    nameIsValid,
    setNameIsValid,
    participationType,
    setParticipationType,
    attemptExit
}: ParticipationStepProps) => {
    const { palette } = useTheme()

    const handleValidate = (valid: boolean) => {
        setNameIsValid(valid)
    }

    const handleNext = () => {
        if (participationType === GroupChallengeType.Group) {
            setStep(CHALLENGE_SIGNUP_STEPS.TEAM_SELECTION)
        } else {
            setStep(CHALLENGE_SIGNUP_STEPS.CONSENT_AND_CONFIRMATION)
        }
    }

    const renderNameInput = () => {
        return (
            <FormControl>
                <DisplayNameWithValidation
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    onValidate={handleValidate}
                    fullWidth={true}
                    onCreateTextField={(
                        defaultValue,
                        value,
                        error,
                        inputProps,
                        onChange
                    ) => (
                        <Stack gap={0.5} width="100%">
                            <CoreTypography
                                variant="body2"
                                color="primary.main"
                            >
                                <FormattedMessage
                                    defaultMessage="Display name"
                                    description="Display name text field label"
                                />
                            </CoreTypography>
                            <TextField
                                variant="outlined"
                                defaultValue={defaultValue}
                                value={value}
                                error={error}
                                InputProps={inputProps || {}}
                                onChange={onChange}
                                fullWidth={true}
                            />
                        </Stack>
                    )}
                />
            </FormControl>
        )
    }

    const renderRadioButton = ({
        value,
        label,
        description
    }: {
        value: GroupChallengeType
        label: React.ReactNode
        description: React.ReactNode
    }) => {
        return (
            <FormControlLabel
                value={value}
                control={<Radio />}
                sx={{
                    alignItems: 'flex-start'
                }}
                label={
                    <Stack direction="column" gap={1} marginTop={1}>
                        <CoreTypography variant="h5">{label}</CoreTypography>
                        <CoreTypography variant="body1">
                            {description}
                        </CoreTypography>
                    </Stack>
                }
            />
        )
    }

    const renderParticipationType = () => {
        return (
            <FormControl>
                <Stack direction="column" gap={1}>
                    <CoreTypography variant="h5">
                        <FormattedMessage
                            {...messages.participationTypeHeader}
                        />
                    </CoreTypography>
                    <RadioGroup
                        aria-labelledby="participation-type-radio-group"
                        name="participation-type-radio-group"
                        value={participationType}
                        defaultValue={participationType}
                        onChange={(event) =>
                            setParticipationType(
                                event.target.value as GroupChallengeType
                            )
                        }
                    >
                        <Stack direction="column" gap={2}>
                            {renderRadioButton({
                                value: GroupChallengeType.Group,
                                label: (
                                    <FormattedMessage
                                        {...messages.participationTypeTeam}
                                    />
                                ),
                                description: (
                                    <FormattedMessage
                                        {...messages.participationTypeTeamDescription}
                                    />
                                )
                            })}
                            {renderRadioButton({
                                value: GroupChallengeType.Personal,
                                label: (
                                    <FormattedMessage
                                        {...messages.participationTypeIndividual}
                                    />
                                ),
                                description: (
                                    <FormattedMessage
                                        {...messages.participationTypeIndividualDescription}
                                    />
                                )
                            })}
                        </Stack>
                    </RadioGroup>
                </Stack>
            </FormControl>
        )
    }

    const renderButtons = () => {
        return (
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                borderTop={`1px solid ${palette.divider}`}
                paddingTop={3}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={attemptExit}
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                >
                    <CoreTypography
                        customVariant="buttonLarge"
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Cancel"
                            description="Cancel button"
                        />
                    </CoreTypography>
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    disabled={!nameIsValid}
                    onClick={handleNext}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CoreTypography customVariant="buttonLarge">
                        <FormattedMessage
                            defaultMessage="Next"
                            description="Next button"
                        />
                    </CoreTypography>
                </Button>
            </Box>
        )
    }

    return (
        <Stack>
            <Stack gap={4}>
                <Stack gap={2}>
                    <CoreTypography variant="h2">
                        <FormattedMessage {...messages.displayName} />
                    </CoreTypography>

                    <CoreTypography variant="h5">
                        <FormattedMessage
                            {...messages.displayNameDescription}
                        />
                    </CoreTypography>
                </Stack>
                {renderNameInput()}
                {renderParticipationType()}
                {renderButtons()}
            </Stack>
        </Stack>
    )
}

export default ParticipationStep
