import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ChallengeGarden from '../ChallengeGarden'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { isChallengeStarted } from '../../../../utils/challenge'
import useGetSocialGroups from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import useGoalChallengeData from '../../../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'

interface ChallengePersonalGardenProps {
    challenge: UnifiedChallenge
}

const ChallengePersonalGarden: React.FC<ChallengePersonalGardenProps> = ({
    challenge
}) => {
    const challengeStarted = isChallengeStarted(challenge)
    const { socialGroup } = useGetSocialGroups(challenge?.id)
    const { plants } = useGoalChallengeData({
        challenge,
        socialGroup,
        loading: false
    })

    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Stack p={2} gap={2} height="100%">
                <CoreTypography variant="overline" color="text.disabled">
                    <FormattedMessage
                        defaultMessage="Your Garden"
                        description="description for the challenge garden card"
                    />
                </CoreTypography>
                <ChallengeGarden
                    columnSize={7}
                    rowSize={2}
                    challengeStarted={challengeStarted}
                    plants={plants}
                />
            </Stack>
        </Card>
    )
}

export default memo(ChallengePersonalGarden)
