import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { ChallengeGarden } from '../../Shared'
import TotalPlantsEarnedDialog from './TotalPlantsEarnedDialog'
import type { UserPlantInfo } from '../../../../../graphql/generated/autogenerated'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'

type TotalPlantsEarnedProps = {
    data: UserPlantInfo[]
}

const TotalPlantsEarned = ({ data }: TotalPlantsEarnedProps) => {
    const [openDialog, setOpenDialog] = useState(false)
    const { activePlants, totalPlants } = useMemo(
        () => ({
            totalPlants: data?.length ?? 0,
            activePlants: data ?? []
        }),
        [data]
    )

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="total plants earned"
                            description="total plants earned title"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={1}>
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            textAlign="left"
                            color="text.secondary"
                        >
                            <FormattedNumber value={totalPlants ?? 0} />
                        </CoreTypography>
                        <ChallengeGarden
                            challengeStarted={true}
                            columnSize={3}
                            rowSize={1}
                            plants={activePlants as UserPlantInfo[]}
                            hideEmptyStateText={true}
                            canLoadMore={false}
                        />
                    </Stack>
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View my garden"
                            description="View my garden button"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <TotalPlantsEarnedDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                data={data}
            />
        </>
    )
}

export default memo(TotalPlantsEarned)
