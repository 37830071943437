import React, {
    ComponentType,
    ReactElement,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'

export interface DisposableDialogProps {
    button?: ReactElement<any, any>
    disabled?: boolean
    Dialog: ComponentType<any>
    open?: boolean
    onOpen?: () => void
    onClose?: () => void
    dialogProps?: any
}

const DisposableDialog: React.FC<DisposableDialogProps> = ({
    button,
    Dialog,
    disabled = false,
    open,
    onOpen = () => ({}),
    onClose = () => ({}),
    dialogProps = {}
}) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [modalVisible, setModalVisible] = useState(false)

    const handleOnClose = useCallback(() => {
        setOpenDialog(false)
        onClose()

        setTimeout(() => {
            setModalVisible(false)
        }, 500)
    }, [onClose])

    const renderButton = useMemo(() => {
        return button
            ? React.cloneElement(button, {
                  onClick: () => {
                      if (disabled) return
                      setOpenDialog(true)
                      setModalVisible(true)
                      onOpen()
                  }
              })
            : null
    }, [button, disabled, onOpen])

    useEffect(() => {
        if (open) {
            setOpenDialog(true)
            setModalVisible(true)
            onOpen()
        }
    }, [open, onOpen])

    return (
        <>
            {renderButton}
            {modalVisible && (
                <Dialog
                    open={openDialog}
                    onClose={handleOnClose}
                    {...dialogProps}
                />
            )}
        </>
    )
}

export default memo(DisposableDialog)
