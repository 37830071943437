import { Box, Card, Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    ChallengeGoal,
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal
} from '../../../../../../graphql/generated/autogenerated'
import useDailyGoals from '../../../../../hooks/useDailyGoals/useDailyGoals'

export type DailyGoalPreviewProps = {
    goals: ChallengeTemplateGoal[] | ChallengeUserDailyGoal[]
}

const DailyGoalPreview: React.FC<DailyGoalPreviewProps> = ({ goals }) => {
    const dailyGoalsPreview = useDailyGoals({
        goals: (goals as ChallengeTemplateGoal[])
            ?.filter((goal) => goal.day === 1)
            .map(
                (goal) =>
                    ({
                        challengeGoal: goal as unknown | ChallengeGoal
                    } as ChallengeUserDailyGoal)
            ),
        preview: true,
        disabled: true
    })

    return (
        <Stack data-testid="daily-goal-preview" gap={3}>
            <CoreTypography variant="h3">
                <FormattedMessage
                    defaultMessage="Daily goal preview"
                    description="Title text shown above the daily goal preview card"
                />
            </CoreTypography>
            <Grid container>
                {dailyGoalsPreview?.map((dailyGoal, index) => (
                    <Grid item key={index} xs={12} md={4}>
                        <Card sx={{ width: '100%' }} elevation={0}>
                            <Box p={2}>{dailyGoal.component}</Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default memo(DailyGoalPreview)
