import { Card } from '@mui/material'
import React, { memo } from 'react'
import {
    PlantInfo,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import PlantIcon from './PlantIcon'
import { useCardStyled } from './useCardStyled'

export interface ChallengePlantCardProps {
    plant?: UserPlantInfo | PlantInfo
    dashed?: boolean
    useSimple?: boolean
    loading?: boolean
    getPlantIcon?: (plant?: UserPlantInfo | PlantInfo) => any
}

const ChallengePlantCard: React.FC<ChallengePlantCardProps> = ({
    plant,
    dashed,
    useSimple,
    loading,
    getPlantIcon
}) => {
    const cardStyles = useCardStyled(!!dashed, !!loading)

    return (
        <Card {...cardStyles}>
            <PlantIcon
                getPlantIcon={getPlantIcon}
                plant={plant}
                loading={loading}
                useSimple={useSimple}
            />
        </Card>
    )
}

export default memo(ChallengePlantCard)
