import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import RadioList from '../../../../../shared/components/elements/RadioList/RadioList'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { SignUpTypes } from '../../../../../enums'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import SocialGroupItem from '../../../../../components/features/SocialGroups/SocialGroupsList/SocialGroupItem/SocialGroupItem'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'

const messages = defineMessages({
    individualTitle: {
        defaultMessage: 'As an individual',
        description: 'title for the participation method section'
    },
    individualDescription: {
        defaultMessage:
            'If you change your mind after signing up you can join a team.',
        description: 'description for participation method section'
    },
    joinTeamTitle: {
        defaultMessage: 'Join a team',
        description: 'title for the participation method section'
    },
    joinDifferentTeamTitle: {
        defaultMessage: 'Join a different team',
        description: 'title for the participation method section'
    },
    joinTeamDescription: {
        defaultMessage:
            "Next you'll be able to search and join an existing team.",
        description: 'description for participation method section'
    },
    createTeamTitle: {
        defaultMessage: 'Create a team',
        description: 'title for the participation method section'
    },
    createTeamDescription: {
        defaultMessage:
            "Next you'll be able to create a team, and {size, select, null {coworkers can join you} other {up to {size} coworkers can join}}.",
        description: 'description for participation method section'
    }
})

const ParticipationMethod: React.FC = () => {
    const { formatMessage } = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const { setSignUpType, signUpType, inviteSocialGroup } =
        useChallengeSignUpModalContext()
    const { setTeamName, setSocialGroup, maxTeamSize } =
        useCompanyChallengeSignUpProviderContext()
    const { challenge } = useCompanyChallengeProviderContext()

    const onSetSignUpType = useCallback(
        (signUpType: SignUpTypes) => {
            setSignUpType(signUpType)
            setTeamName('')
            setSocialGroup(undefined)
        },
        [setTeamName, setSignUpType, setSocialGroup]
    )
    return (
        <Stack gap={2} px={0.125} pb={0.125}>
            <Stack gap={1}>
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Participation Method"
                        description="title for the participation method section"
                    />
                </CoreTypography>
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="How do you want to take part in the Challenge?"
                        description="description for participation method section"
                    />
                </CoreTypography>
            </Stack>
            <RadioList
                onChange={(type) => onSetSignUpType(type as SignUpTypes)}
                defaultValue={signUpType}
                value={signUpType}
                list={[
                    {
                        value: SignUpTypes.solo,
                        title: formatMessage(messages.individualTitle),
                        description: formatMessage(
                            messages.individualDescription
                        )
                    },
                    {
                        value: SignUpTypes.joinTeam,
                        title: formatMessage(
                            !inviteSocialGroup
                                ? messages.joinTeamTitle
                                : messages.joinDifferentTeamTitle
                        ),
                        description: formatMessage(messages.joinTeamDescription)
                    },
                    {
                        value: SignUpTypes.createTeam,
                        title: formatMessage(messages.createTeamTitle),
                        description: formatMessage(
                            messages.createTeamDescription,
                            {
                                size: challenge?.maxTeamSize
                                    ? challenge.maxTeamSize - 1
                                    : null
                            }
                        )
                    }
                ]}
            />
            {!!inviteSocialGroup && (
                <SocialGroupItem
                    socialGroup={inviteSocialGroup}
                    userId={userId}
                    isJoinList={true}
                    hasJoinedGroup={signUpType === SignUpTypes.inviteTeam}
                    hasAddedGroup={signUpType === SignUpTypes.inviteTeam}
                    addToJoinList={() => {
                        onSetSignUpType(SignUpTypes.inviteTeam)
                        setSocialGroup(inviteSocialGroup)
                    }}
                    disable={true}
                    disableNavigate={true}
                    maxCapacity={maxTeamSize}
                />
            )}
        </Stack>
    )
}

export default memo(ParticipationMethod)
