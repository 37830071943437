import { Apollo } from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePagination } from '../../../shared/modules/social/hooks'
import useGoalChallengeData from '../useGetCompanyChallengeDataQuery/useGoalChallengeData'
import {
    type UnifiedChallenge,
    type SocialGroup
} from '../../../graphql/generated/autogenerated'
export type leaderBoardTransform<T> = {
    totalEntries: number
    rankedList: T[]
}

export type useLeaderboardDataProps<
    T,
    K,
    R extends Apollo.OperationVariables
> = {
    skipQuery?: boolean
    rank: number
    rowsPerPage: number
    query: (
        baseOptions: Apollo.QueryHookOptions<K, R>
    ) => Apollo.QueryResult<K, R>
    dataTransform: (data: K) => leaderBoardTransform<T>
    challenge?: UnifiedChallenge
    socialGroup?: SocialGroup | null
}

export type LeaderboardDataValue<T> = {
    requesterRank: number
    scores: T[]
    totalEntries: number
    loading: boolean
    loadingNext: boolean
    hasMore: boolean
    error: boolean
    offset: number
    maximumOffsetLoaded: number
    rowsPerPage: number
    handleLoadMore: (next?: boolean) => Promise<any>
    refetch: () => Promise<any>
    setDefaultOffset: (offset: number) => void
    setOffset: (offset: number) => void
}

export function getDefaultContextValue<T>(): LeaderboardDataValue<T> {
    return {
        requesterRank: 0,
        scores: [],
        totalEntries: 0,
        loading: false,
        loadingNext: false,
        hasMore: false,
        error: false,
        offset: 0,
        maximumOffsetLoaded: 0,
        rowsPerPage: 0,
        handleLoadMore: () => Promise.resolve(null),
        refetch: () => Promise.resolve(null),
        setDefaultOffset: () => ({}),
        setOffset: () => ({})
    }
}

export function useLeaderboardData<T, K, R extends Apollo.OperationVariables>({
    skipQuery,
    rank,
    rowsPerPage,
    query,
    dataTransform,
    challenge,
    socialGroup
}: useLeaderboardDataProps<T, K, R>) {
    const [totalEntries, setTotalEntries] = useState(0)
    const [defaultOffset, setDefaultOffset] = useState(0)

    const { loading: goalChallengeLoading, refetch: refetchChallenge } =
        useGoalChallengeData({
            challenge,
            socialGroup,
            loading: false
        })

    const {
        data,
        offset,
        maximumOffsetLoaded,
        error,
        loading: leaderboardLoading,
        loadingNext,
        hasMore,
        setOffset,
        handleLoadMore,
        refetch: leaderBoardRefetch
    } = usePagination<T, K, R>({
        defaultOffset,
        queryProps: {
            challengeId: challenge?.id,
            socialGroupId: socialGroup?.id
        },
        skip: defaultOffset === 0 || goalChallengeLoading || skipQuery,
        query,
        dataTransform: (data) => {
            const { totalEntries, rankedList } = dataTransform(data)

            setTotalEntries(totalEntries)

            return rankedList
        }
    })

    useEffect(() => {
        if (!goalChallengeLoading) {
            const activeOffset = ~~(rank / rowsPerPage) * rowsPerPage

            setOffset(activeOffset < 0 ? 0 : activeOffset)
            setDefaultOffset(rowsPerPage)
        }
    }, [setOffset, setDefaultOffset, rank, rowsPerPage, goalChallengeLoading])

    const refetch = useCallback(
        () => refetchChallenge().then(leaderBoardRefetch),
        [refetchChallenge, leaderBoardRefetch]
    )

    return useMemo<LeaderboardDataValue<T>>(
        () => ({
            loading: leaderboardLoading,
            loadingNext,
            hasMore,
            requesterRank: rank,
            offset,
            maximumOffsetLoaded,
            scores: data as T[],
            totalEntries,
            error: Boolean(error),
            rowsPerPage,
            setOffset,
            handleLoadMore,
            refetch,
            setDefaultOffset
        }),
        [
            maximumOffsetLoaded,
            leaderboardLoading,
            totalEntries,
            loadingNext,
            rank,
            rowsPerPage,
            hasMore,
            data,
            offset,
            error,
            setOffset,
            handleLoadMore,
            refetch
        ]
    )
}

export default useLeaderboardData
