import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant5: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#447E8B',
    colorLayer2 = '#4C67A1',
    colorLayer3 = 'black',
    colorLayer4 = '#122964',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M41.4516 85.7075L40.9016 85.5675L43.3316 76.2375C44.0516 73.4675 45.3016 70.9075 47.0416 68.6275L66.9016 42.6975L67.3616 43.0475L47.5016 68.9775C45.8016 71.1975 44.5916 73.6875 43.8916 76.3775L41.4616 85.7075H41.4516Z"
                fill={colorLayer3}
            />
            <path
                d="M35.9716 31.6275C40.6516 41.6575 36.4216 46.1275 30.0316 49.1075C23.6416 52.0875 17.5016 52.4575 12.8216 42.4275C8.1416 32.3975 6.69162 15.7575 13.0916 12.7775C19.4816 9.79747 31.3016 21.5975 35.9816 31.6275H35.9716Z"
                fill={colorLayer2}
            />
            <path
                opacity="0.25"
                d="M17.6416 39.2475C19.9557 39.2475 21.8316 37.3715 21.8316 35.0575C21.8316 32.7434 19.9557 30.8675 17.6416 30.8675C15.3275 30.8675 13.4516 32.7434 13.4516 35.0575C13.4516 37.3715 15.3275 39.2475 17.6416 39.2475Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M21.5416 26.0775C22.7511 26.0775 23.7316 25.097 23.7316 23.8875C23.7316 22.678 22.7511 21.6975 21.5416 21.6975C20.3321 21.6975 19.3516 22.678 19.3516 23.8875C19.3516 25.097 20.3321 26.0775 21.5416 26.0775Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M29.4616 43.5775C32.6041 43.5775 35.1516 41.03 35.1516 37.8875C35.1516 34.745 32.6041 32.1975 29.4616 32.1975C26.3191 32.1975 23.7716 34.745 23.7716 37.8875C23.7716 41.03 26.3191 43.5775 29.4616 43.5775Z"
                fill="white"
            />
            <path
                d="M24.7416 47.2975C29.1216 52.4275 27.5916 57.2375 23.5816 60.6675C19.5616 64.0975 14.5716 64.8575 10.1916 59.7275C5.8116 54.5975 2.48159 44.1075 6.50159 40.6775C10.5216 37.2475 20.3616 42.1775 24.7416 47.3075V47.2975Z"
                fill={colorLayer4}
            />
            <path
                opacity="0.25"
                d="M13.2716 57.8675C14.8014 57.8675 16.0416 56.6273 16.0416 55.0975C16.0416 53.5676 14.8014 52.3275 13.2716 52.3275C11.7418 52.3275 10.5016 53.5676 10.5016 55.0975C10.5016 56.6273 11.7418 57.8675 13.2716 57.8675Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M17.9416 48.1675C18.7148 48.1675 19.3416 47.5407 19.3416 46.7675C19.3416 45.9943 18.7148 45.3675 17.9416 45.3675C17.1684 45.3675 16.5416 45.9943 16.5416 46.7675C16.5416 47.5407 17.1684 48.1675 17.9416 48.1675Z"
                fill="white"
            />
            <path
                d="M59.8816 26.0275C56.0116 33.7675 49.6216 34.3875 43.6116 31.3875C37.6016 28.3875 34.2616 22.8975 38.1316 15.1575C42.0016 7.41747 52.6816 -1.77253 58.6916 1.22747C64.7016 4.22747 63.7516 18.2975 59.8816 26.0275Z"
                fill={colorLayer4}
            />
            <path
                opacity="0.25"
                d="M46.7616 27.1675C49.6114 27.1675 51.9216 24.8573 51.9216 22.0075C51.9216 19.1577 49.6114 16.8475 46.7616 16.8475C43.9118 16.8475 41.6016 19.1577 41.6016 22.0075C41.6016 24.8573 43.9118 27.1675 46.7616 27.1675Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M56.1716 13.5875C56.6521 13.5875 57.0416 13.198 57.0416 12.7175C57.0416 12.237 56.6521 11.8475 56.1716 11.8475C55.6911 11.8475 55.3016 12.237 55.3016 12.7175C55.3016 13.198 55.6911 13.5875 56.1716 13.5875Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M48.2416 11.9575C49.059 11.9575 49.7216 11.2948 49.7216 10.4775C49.7216 9.66009 49.059 8.99747 48.2416 8.99747C47.4243 8.99747 46.7616 9.66009 46.7616 10.4775C46.7616 11.2948 47.4243 11.9575 48.2416 11.9575Z"
                fill="white"
            />
            <path
                d="M67.4616 68.7775C59.6016 79.0375 51.7017 77.5675 44.6817 72.1875C37.6617 66.8075 34.1816 59.5575 42.0316 49.3075C49.8816 39.0575 66.1017 29.6675 73.1217 35.0475C80.1417 40.4275 75.3116 58.5275 67.4616 68.7775Z"
                fill={colorLayer1}
            />
            <path
                opacity="0.25"
                d="M52.2416 60.7975C54.8153 60.7975 56.9016 58.7111 56.9016 56.1375C56.9016 53.5638 54.8153 51.4775 52.2416 51.4775C49.668 51.4775 47.5816 53.5638 47.5816 56.1375C47.5816 58.7111 49.668 60.7975 52.2416 60.7975Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M58.6216 43.8675C59.4943 43.8675 60.2016 43.1601 60.2016 42.2875C60.2016 41.4149 59.4943 40.7075 58.6216 40.7075C57.749 40.7075 57.0416 41.4149 57.0416 42.2875C57.0416 43.1601 57.749 43.8675 58.6216 43.8675Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M67.3616 54.7175C68.6816 54.7175 69.7516 53.6474 69.7516 52.3275C69.7516 51.0075 68.6816 49.9375 67.3616 49.9375C66.0417 49.9375 64.9716 51.0075 64.9716 52.3275C64.9716 53.6474 66.0417 54.7175 67.3616 54.7175Z"
                fill="white"
            />
            <path
                opacity="0.25"
                d="M56.3816 70.6375C57.6132 70.6375 58.6116 69.6391 58.6116 68.4075C58.6116 67.1759 57.6132 66.1775 56.3816 66.1775C55.15 66.1775 54.1516 67.1759 54.1516 68.4075C54.1516 69.6391 55.15 70.6375 56.3816 70.6375Z"
                fill="white"
            />
            <path
                d="M38.5216 85.6575C37.9216 75.6275 36.4716 66.8275 34.2016 59.4875C32.0116 52.3875 29.8216 49.3075 29.8016 49.2775L16.2816 20.3075L16.8016 20.0675L30.2916 48.9975C30.3516 49.0775 32.5216 52.1175 34.7516 59.3275C37.0316 66.7075 38.4916 75.5575 39.0916 85.6375L38.5216 85.6675V85.6575Z"
                fill={colorLayer3}
            />
            <path
                d="M34.7216 85.6575C33.5416 68.3975 40.3716 41.9575 42.4916 34.2675C43.0216 32.3575 43.7316 30.4975 44.6216 28.7175L56.0116 5.93747L56.5216 6.19747L45.1316 28.9775C44.2616 30.7275 43.5516 32.5575 43.0416 34.4275C40.9216 42.0975 34.1116 68.4575 35.2916 85.6275L34.7216 85.6675V85.6575Z"
                fill={colorLayer3}
            />
            <path
                d="M38.5216 85.6675C37.3916 76.0275 33.3516 69.8175 30.1716 66.2975C26.7216 62.4875 23.4916 60.9375 23.4616 60.9275L23.3616 60.8575L10.2816 45.5475L10.7216 45.1775L23.7616 60.4475C24.7116 60.9275 36.9616 67.4775 39.0916 85.6175L38.5216 85.6875V85.6675Z"
                fill={colorLayer3}
            />
            <path
                d="M38.7116 81.0275L38.1416 80.9375C39.7316 70.7475 44.8316 66.2275 45.0416 66.0775L50.8916 61.2875L51.2516 61.7275L45.3916 66.5275C45.3316 66.5675 40.2516 71.0875 38.7116 81.0275Z"
                fill={colorLayer3}
            />
            <path
                d="M51.7816 47.9875C42.9016 52.0875 42.0016 59.3475 45.2116 66.3075L79.8316 50.3075C76.6216 43.3475 60.6616 43.8775 51.7816 47.9875Z"
                fill={colorLayer4}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant5
