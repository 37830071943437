import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import AverageActivity from '../../Recap/AverageActivity'
import ActivityRecapDialog from './ActivityRecapDialog'

import type {
    UnifiedChallenge,
    UnifiedChallengesQuery
} from '../../../../../graphql/generated/autogenerated'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../../Recap/RecapWidget'

type ActivityRecapProps = {
    challenge: UnifiedChallenge
    data: Pick<
        UnifiedChallengesQuery,
        'getChallengeStats' | 'getSoloPlayerRank'
    >
}

const ActivityRecap = ({ challenge, data }: ActivityRecapProps) => {
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Your activity points"
                            description="activity card title"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Your activity over the course of the challenge."
                            description="activity card description"
                        />
                    }
                />
                <RecapWidgetContent>
                    <AverageActivity
                        elevation={6}
                        averageIndividualSteps={
                            data?.getChallengeStats?.activityStats
                                ?.averageIndividualSteps
                        }
                        userTotalSteps={data?.getSoloPlayerRank.totalSteps}
                    />
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View details"
                            description="view details button"
                        />
                    }
                    onClick={() => setOpenDialog(true)}
                />
            </RecapWidget>
            <ActivityRecapDialog
                challenge={challenge}
                data={data}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
        </>
    )
}

export default ActivityRecap
