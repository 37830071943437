import { Avatar, Stack, Tooltip } from '@mui/material'
import {
    LeafIcon,
    useTheme,
    CoreTypography
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeType } from '../../../shared/enums/challengeType'

const messages = defineMessages({
    iconAriaLabel: {
        defaultMessage: '{type} Challenge type',
        description: 'description of icon'
    },
    companyChallenge: {
        defaultMessage: 'Company Challenge',
        description: 'description of company challenge'
    },
    groupChallenge: {
        defaultMessage: 'Group Challenge',
        description: 'description of group challenge'
    },
    individualChallenge: {
        defaultMessage: 'Individual Challenge',
        description: 'description of individual challenge'
    }
})

export type ChallengeTypeIconProps = {
    challengeType: ChallengeType
    color?: 'secondary' | 'info'
}

const ChallengeTypeIcon: React.FC<ChallengeTypeIconProps> = ({
    challengeType,
    color = 'info'
}) => {
    const { palette } = useTheme()
    const intl = useIntl()
    const icon = useMemo(() => {
        switch (challengeType) {
            case ChallengeType.company:
                return 'buildings'
            case ChallengeType.group:
                return 'users'
            case ChallengeType.biotype:
            case ChallengeType.journey:
            case ChallengeType.prize:
                return 'user'
            default:
                break
        }
    }, [challengeType])

    const tooltipTitle = useMemo(() => {
        switch (challengeType) {
            case ChallengeType.company:
                return intl.formatMessage(messages.companyChallenge)
            case ChallengeType.group:
                return intl.formatMessage(messages.groupChallenge)
            case ChallengeType.biotype:
            case ChallengeType.journey:
            case ChallengeType.prize:
                return intl.formatMessage(messages.individualChallenge)
            default:
                return ''
        }
    }, [challengeType])

    if (!icon) return null

    return (
        <Tooltip
            title={
                <CoreTypography variant="body1">{tooltipTitle}</CoreTypography>
            }
            placement="top"
        >
            <Avatar
                alt={intl.formatMessage(messages.iconAriaLabel, {
                    type: challengeType.toLocaleLowerCase()
                })}
                sx={{
                    background: palette[color]?.main,
                    color: palette[color]?.contrastText,
                    height: 32,
                    width: 32
                }}
            >
                <Stack p={1.25}>
                    <LeafIcon icon={icon} fontSize="small" iconStyle="solid" />
                </Stack>
            </Avatar>
        </Tooltip>
    )
}

export default memo(ChallengeTypeIcon)
