import { TextField } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useState
} from 'react'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider'
import StoryQuestion from '.'

export type StoryTextQuestionProps = {
    questionData: QuestionData
    isActive: boolean
    containerRef?: MutableRefObject<any>
    index: number
}

const StoryTextQuestion: React.FC<StoryTextQuestionProps> = ({
    questionData,
    isActive,
    containerRef,
    index
}) => {
    const theme = useTheme()
    const [showData, setShowData] = useState(false)
    const [answer, setAnswer] = useState(questionData.answer)

    const { onAnswer } = useStorySubmissionStateProvider()

    // input values from one question seems to slide in on the next question
    // just hide the input until the slide completes
    useEffect(() => {
        setShowData(false)

        if (isActive) {
            const showDataTimer = setTimeout(() => setShowData(true), 350)

            return () => {
                clearTimeout(showDataTimer)
            }
        }
    }, [isActive])

    const onAnswerTextQuestion = useCallback(
        (newAnswer: any) => {
            setAnswer(newAnswer)
            onAnswer(index, newAnswer)
        },
        [onAnswer, index]
    )

    return (
        <StoryQuestion
            questionData={questionData}
            isActive={isActive}
            containerRef={containerRef}
        >
            <TextField
                multiline
                rows={6}
                sx={{
                    '& .MuiInputBase-multiline': {
                        p: 0,
                        pb: theme.spacing(2)
                    },
                    '& .MuiInputBase-inputMultiline': {
                        p: theme.spacing(2),
                        pb: 0,
                        borderRadius: theme.spacing(1),
                        borderColor: theme.palette.primary.main,
                        borderWidth: theme.spacing(3)
                    },
                    '& .MuiOutlinedInput-root': {
                        borderWidth: theme.spacing(2),
                        '& fieldset': {
                            borderColor: theme.palette.primary.main,
                            borderWidth: theme.spacing(0.125)
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.main,
                            borderWidth: theme.spacing(0.125)
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                            borderWidth: theme.spacing(0.125)
                        }
                    },
                    '& textarea': {},
                    mb: theme.spacing(2)
                }}
                value={showData ? answer : ''}
                onChange={(event) => onAnswerTextQuestion(event.target.value)}
                inputProps={{
                    'data-testid': 'answer-input',
                    maxLength: 10000,
                    'aria-labeledby': 'story-question'
                }}
                data-testid="answer-input-a"
            />
        </StoryQuestion>
    )
}

export default StoryTextQuestion
