import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'
import { v4 as uuid } from 'uuid'

const CommunityPlant4: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#285E68',
    colorLayer2 = '#133338',
    colorLayer3 = '#81C254',
    useSimple,
    base
}) => {
    const id = uuid()

    return (
        <BasePlant useSimple={useSimple} base={base}>
            <g>
                <g>
                    <path
                        d="M77.0346 41.1075C75.3204 41.2088 73.6867 41.267 71.997 41.3944C67.8453 41.8422 64.0252 44.0774 61.3128 47.2519C60.6393 47.775 60.0544 48.4002 59.3625 48.96C59.7324 48.1628 60.1216 47.37 60.5687 46.5903C64.2888 42.2104 71.4336 38.6009 77.0346 41.1075Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M31.4013 48.4709L31.8516 42.0337L36.4694 35.2138L37.2878 35.7694L32.8186 42.3771L32.3884 48.5421L31.4013 48.4709Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M30.9661 20.1623L28.1791 36.0509C27.9266 34.7735 34.5066 54.9087 34.4539 54.5994L34.2049 54.3069C37.1052 55.9315 39.2742 58.3837 41.0297 61.1005C44.4656 66.5988 45.824 73.2842 45.2012 79.7231L44.3769 89.1605L42.3559 88.9561L43.4374 79.5466C44.5399 70.6339 41.5436 60.2028 33.7608 55.2102L33.5713 55.1056L33.5118 54.9177L27.2217 36.1197L27.1781 35.997L27.2064 35.8702L29.9837 19.9794L30.9587 20.1503L30.9661 20.1623Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M50.2785 65.1048C50.2785 65.1048 50.8 55.8444 59.0262 52.6556C67.262 49.4689 80.0896 53.5509 80.0896 53.5509L50.2667 65.1123L50.2785 65.1048Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M53.026 57.4486C50.531 61.1031 50.2881 65.107 50.2881 65.107L67.584 58.3988C64.0554 57.5945 58.1703 56.5915 53.026 57.4486Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M76.9779 34.8418C71.4513 38.2359 64.1891 36.3655 64.1891 36.3655C64.1891 36.3655 65.8929 28.9839 71.4218 25.5801C76.9485 22.186 84.2106 24.0564 84.2106 24.0564C84.2106 24.0564 82.5068 31.438 76.9779 34.8418Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M44.3303 70.0736L43.3998 69.7488C43.4171 69.6708 45.9077 62.3045 48.8737 54.3496C51.8872 46.2722 70.6464 31.2153 71.4468 30.5777L72.0619 31.3637C71.8664 31.5139 52.7216 46.8929 49.7978 54.7037C46.8361 62.6391 44.3573 69.9978 44.3325 70.0639L44.3303 70.0736Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M80.4806 27.3885C76.8272 32.7881 67.8024 32.9658 67.8024 32.9658C67.8024 32.9658 64.6979 24.3858 68.3513 18.9862C72.0046 13.5865 81.0294 13.4088 81.0294 13.4088C81.0294 13.4088 84.1339 21.9888 80.4806 27.3885Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M81.0198 13.4066C80.6409 14.3351 80.2064 15.2406 79.774 16.1364L78.1164 18.5774L74.8085 23.4716L71.5007 28.3657L69.8527 30.809C69.1646 31.5337 68.5033 32.2748 67.7907 32.9734C68.1792 32.0471 68.6212 31.1535 69.0364 30.2436L70.6844 27.8004L73.9923 22.9063L77.3001 18.0121L78.9503 15.5591C79.6191 14.83 80.2997 14.0933 81.0124 13.3947L81.0198 13.4066Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M68.8387 35.1495C68.2778 41.6743 60.4976 46.3179 60.4976 46.3179C60.4976 46.3179 53.6233 40.3843 54.182 33.8693C54.7408 27.3543 62.523 22.701 62.523 22.701C62.523 22.701 69.3974 28.6345 68.8387 35.1495Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M49.5493 56.5024L49.3302 55.5299C57.1977 53.7251 59.7886 46.8635 60.0156 46.208L60.8757 36.2457L61.8584 36.3365L60.9937 46.4104L60.9806 46.4689C60.9536 46.5448 58.335 54.4866 49.5493 56.5024Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M62.523 22.701C62.6445 23.7025 62.706 24.7006 62.7675 25.6986L62.5051 28.6525L62.0017 34.5548L61.4983 40.4571L61.2455 43.4132C60.9995 44.3821 60.7823 45.3577 60.4976 46.3179C60.3858 45.3186 60.3436 44.3249 60.2628 43.3224L60.5156 40.3664L61.019 34.4641L61.5224 28.5617L61.7752 25.6057C62.002 24.6323 62.2384 23.6612 62.523 22.701Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M33.9961 9.69006C35.7865 15.9819 30.1816 23.1406 30.1816 23.1406C30.1816 23.1406 21.6601 20.1016 19.8675 13.8195C18.0771 7.52761 23.682 0.36891 23.682 0.36891C23.682 0.36891 32.2035 3.40794 33.9961 9.69006Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M46.3177 27.7409C42.6644 33.1406 33.6396 33.3183 33.6396 33.3183C33.6396 33.3183 30.5351 24.7383 34.1884 19.3386C37.8418 13.939 46.8666 13.7613 46.8666 13.7613C46.8666 13.7613 49.9711 22.3413 46.3177 27.7409Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M46.3178 27.7409C42.6644 33.1406 33.6396 33.3183 33.6396 33.3183L46.8688 13.7515C46.8688 13.7515 49.9733 22.3315 46.3199 27.7312L46.3178 27.7409Z"
                        fill={colorLayer2}
                    />
                    <path
                        d="M45.018 36.6292C41.3647 42.0288 32.3399 42.2065 32.3399 42.2065C32.3399 42.2065 29.2354 33.6265 32.8887 28.2269C36.5421 22.8272 45.5669 22.6496 45.5669 22.6496C45.5669 22.6496 48.6714 31.2295 45.018 36.6292Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M21.2574 52.0837C16.1161 48.1033 15.3803 40.3502 15.3803 40.3502C15.3803 40.3502 22.9741 39.0468 28.1057 43.025C33.247 47.0055 33.9829 54.7586 33.9829 54.7586C33.9829 54.7586 26.3891 56.062 21.2574 52.0837Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M44.8783 57.9184C45.1295 51.3743 50.9696 46.2988 50.9696 46.2988C50.9696 46.2988 56.396 51.8203 56.1448 58.3644C55.8936 64.9085 50.0534 69.984 50.0534 69.984C50.0534 69.984 44.6271 64.4625 44.8783 57.9184Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M36.7846 57.0988C36.7846 57.0988 33.4825 50.1343 38.0748 45.5227C42.667 40.9111 53.4078 40.3967 53.4078 40.3967L36.7846 57.0988Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M28.9686 68.684C22.7997 70.6402 16.1006 66.7864 16.1006 66.7864C16.1006 66.7864 19.4187 59.7523 25.5973 57.7984C31.7663 55.8422 38.4654 59.696 38.4654 59.696C38.4654 59.696 35.1472 66.7301 28.9686 68.684Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M18.1782 20.9993C25.6207 25.7913 27.6949 35.9508 27.6949 35.9508C27.6949 35.9508 17.712 38.3395 10.2791 33.5497C2.83663 28.7578 0.762389 18.5983 0.762389 18.5983C0.762389 18.5983 10.7453 16.2096 18.1782 20.9993Z"
                        fill={colorLayer3}
                    />
                    <path
                        d="M23.682 0.368914C24.1511 1.26512 24.558 2.16765 24.9745 3.07238L25.7789 5.92085L27.3996 11.6103L29.0202 17.2997L29.8342 20.1503C29.9461 21.1496 30.0934 22.1262 30.172 23.1384C29.7125 22.2444 29.3174 21.3344 28.8816 20.4252L28.0676 17.5746L26.4469 11.8852L24.8263 6.19576L24.0101 3.35484C23.8768 2.36091 23.7509 1.37893 23.6723 0.366714L23.682 0.368914Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M0.762388 18.5983C1.9978 19.1464 3.18909 19.7561 4.39654 20.3389L7.76257 22.5104L14.4946 26.8534L21.2266 31.1964L24.5927 33.3679C25.6268 34.2289 26.677 35.0628 27.6927 35.9606C26.4595 35.4027 25.2682 34.7929 24.0608 34.2102L20.6947 32.0387L13.9627 27.6957L7.23067 23.3527L3.86246 21.1909C2.82838 20.33 1.77814 19.4961 0.762388 18.5983Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M45.5691 22.6398C45.1902 23.5682 44.7556 24.4738 44.3233 25.3695L42.6656 27.8106L39.3578 32.7047L36.0499 37.5988L34.4019 40.0421C33.7139 40.7668 33.0525 41.5079 32.3399 42.2065C32.7284 41.2803 33.1704 40.3867 33.5857 39.4768L35.2337 37.0335L38.5415 32.1394L41.8494 27.2453L43.4995 24.7922C44.1683 24.0631 44.8489 23.3265 45.5616 22.6278L45.5691 22.6398Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M15.3803 40.3502C16.2773 40.7801 17.142 41.2642 18.0067 41.7483L20.3309 43.5491L24.9791 47.1507L29.6273 50.7522L31.9514 52.553C32.6278 53.2914 33.3226 53.9931 33.9754 54.7466C33.0805 54.3069 32.2298 53.8055 31.3511 53.3387L29.027 51.5379L24.3787 47.9364L19.7305 44.3348L17.4064 42.534C16.716 41.8129 16.0352 41.094 15.3846 40.3307L15.3803 40.3502Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M16.1006 66.7864C16.9713 66.2878 17.8579 65.8544 18.7445 65.4209L21.5436 64.5418L27.1343 62.7717L32.725 61.0015L35.5166 60.1104C36.4988 59.9755 37.4683 59.807 38.4536 59.7035C37.5851 60.1923 36.691 60.6138 35.8097 61.069L33.0181 61.9601L27.4274 63.7303L21.8367 65.5004L19.0451 66.3915C18.0682 66.5481 17.0934 66.6949 16.0984 66.7962L16.1006 66.7864Z"
                        fill={colorLayer1}
                    />
                    <path
                        d="M50.0534 69.984C49.889 68.9932 49.7772 67.9939 49.6728 67.0066L49.7937 64.0512L50.0281 58.1286L50.2625 52.206L50.3738 49.2485C50.5814 48.2707 50.7406 47.2819 50.9771 46.3108C51.1415 47.3016 51.2244 48.2943 51.3577 49.2882L51.2464 52.2457L51.012 58.1684L50.7776 64.091L50.6663 67.0485C50.4781 68.0307 50.2995 69.015 50.0513 69.9937L50.0534 69.984Z"
                        fill={colorLayer1}
                    />
                </g>
                <defs>
                    <clipPath id={id}>
                        <rect width="85" height="88" fill="white" />
                    </clipPath>
                </defs>
            </g>
        </BasePlant>
    )
}

export default CommunityPlant4
