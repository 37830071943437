import { Card, Stack } from '@mui/material'
import { Datum, Serie } from '@nivo/line'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useZonedDate from '../../../../../hooks/useZonedDateTime/useZonedDateTime'
import LineChart from '../../../elements/Charts/LineChart'
import ViewMoreDialog from '../ViewMoreDialog'
import type {
    UnifiedChallenge,
    UnifiedChallengesQuery
} from '../../../../../graphql/generated/autogenerated'

export type HydrationRecapDialogProps = {
    data: Pick<
        UnifiedChallengesQuery,
        | 'getChallengeStats'
        | 'getChallengeTotalWater'
        | 'getMeasureUnitSettings'
        | 'getUserTotalWater'
    >
    challenge: UnifiedChallenge
    open: boolean
    onClose: () => void
}

const messages = defineMessages({
    day: {
        defaultMessage: 'Day',
        description: 'day legend label'
    },
    cups: {
        defaultMessage: 'Glasses of Water',
        description:
            'legend label indicating how many glasses of water a user consumed'
    }
})

const HydrationRecapDialog: React.FC<HydrationRecapDialogProps> = ({
    open,
    onClose,
    data,
    challenge
}) => {
    const { getZonedDate } = useZonedDate()

    const { formatMessage } = useIntl()

    const mappedHydrationData = useMemo<Serie[]>(() => {
        const challengeStartDate = getZonedDate(
            parseISO(challenge.companyChallengeStartDay)
        )

        const days = Array.from(Array(challenge?.duration)).map(
            (_, index) =>
                ({
                    x: index + 1,
                    y: 0
                } as Datum)
        )
        data?.getChallengeStats?.waterStats?.dailyIndividualStats?.forEach(
            (stat) => {
                const daysSinceStart = differenceInDays(
                    getZonedDate(new Date(stat.year, stat.month - 1, stat.day)),
                    challengeStartDate
                )

                const day = days[daysSinceStart]

                if (day) {
                    day.y = stat.water
                }
            }
        )

        return [
            {
                id: 'water',
                data: days
            }
        ]
    }, [
        getZonedDate,
        challenge?.companyChallengeStartDay,
        challenge?.duration,
        data?.getChallengeStats?.waterStats?.dailyIndividualStats
    ])

    const legends = useMemo(
        () => ({
            x: formatMessage(messages.day),
            y: formatMessage(messages.cups)
        }),
        [formatMessage]
    )

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={4.75}>
                <CoreTypography variant="h3" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Here’s a look at your daily hydration over the course of the Challenge."
                        description="hydration dialog title"
                    />
                </CoreTypography>
                <Card>
                    <Stack p={2} gap={3}>
                        <CoreTypography
                            variant="overline"
                            color="text.disabled"
                        >
                            <FormattedMessage
                                defaultMessage="Your hydration"
                                description="your hydration title"
                            />
                        </CoreTypography>
                        <Stack width="100%" height={500}>
                            <LineChart
                                data={mappedHydrationData}
                                legends={legends}
                            />
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(HydrationRecapDialog)
