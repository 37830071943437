import { Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    ChallengeTemplateGoal,
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../graphql/generated/autogenerated'
import useTimeline from '../../../../hooks/useTimeline/useTimeline'
import { ChallengeType } from '../../../../../shared'
import { useSocialMemberContext } from '../../../../../shared/modules/social/hooks/useSocialMemberProvider'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import ParticipantsDialog from '../ParticipantsDialog'
import ChallengeIconInfo from '../ChallengeIconInfo'
import {
    isChallengeExpired,
    isChallengeStarted,
    getChallengeDates
} from '../../../../utils/challenge'
import useGoalChallengeData from '../../../../hooks/useGetCompanyChallengeDataQuery/useGoalChallengeData'
import useGetSocialGroups from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import useGetChallengeUserChallenge from '../../../../hooks/useGetChallengeUserChallenge/useGetChallengeUserChallenge'
import useChallengeGoalPreview from '../../../../hooks/useChallengeGoalPreview/useChallengeGoalPreview'

interface ChallengeAboutIconsProps {
    challenge: UnifiedChallenge
}

const ChallengeAboutIcons: React.FC<ChallengeAboutIconsProps> = ({
    challenge
}) => {
    const [goalCount, setGoalCount] = useState<number | undefined>()
    const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false)

    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const challengeExpired = isChallengeExpired(challenge)
    const challengeStarted = isChallengeStarted(challenge)
    const { startDate, endDate } = getChallengeDates(challenge)
    const timeline = useTimeline(startDate, endDate)
    const isTemplate = false

    const { participation } = useGetChallengeUserChallenge({
        challengeId: challenge?.id
    })
    const { goals: previewGoals, error: previewError } =
        useChallengeGoalPreview({
            challengeId: challenge?.id
        })
    const { socialGroup } = useGetSocialGroups(challenge?.id)
    const { goals, error } = useGoalChallengeData({
        challenge,
        socialGroup,
        loading: false
    })

    useEffect(() => {
        setGoalCount(
            challengeStarted && participation
                ? goals?.length
                : (previewGoals as ChallengeTemplateGoal[] | undefined)?.filter(
                      (goal) => goal?.day === 1
                  )?.length
        )
    }, [challengeStarted, participation, goals, previewGoals])

    const canShowDailyGoalCount = useMemo(() => {
        switch (challenge?.challenge_type) {
            case ChallengeType.company:
                if (!!participation && challengeStarted) {
                    return true
                } else {
                    return false
                }
            default:
                return true
        }
    }, [challenge?.challenge_type, participation, challengeStarted])

    if (error || previewError) return ReactNullValue

    const showSocialMembersList =
        challenge?.challenge_type !== ChallengeType.company &&
        challenge?.totalParticipants > 0

    return (
        <Stack gap={isMobile ? 1 : 2} sx={{ whiteSpace: 'nowrap' }}>
            {!isTemplate && (
                <ChallengeIconInfo icon="calendar" info={<>{timeline}</>} />
            )}
            {!challengeExpired && (
                <React.Fragment>
                    <ChallengeIconInfo
                        icon="clock"
                        info={
                            <FormattedMessage
                                defaultMessage="{duration} day Challenge"
                                description="total number of days in the challenge"
                                values={{
                                    duration: challenge?.duration
                                }}
                            />
                        }
                    />
                    {canShowDailyGoalCount && (
                        <ChallengeIconInfo
                            icon="circle-check"
                            info={
                                <FormattedMessage
                                    defaultMessage="{goalCount, plural, one {# daily goal} other {# daily goals}}"
                                    description="daily goals count"
                                    values={{
                                        goalCount
                                    }}
                                />
                            }
                        />
                    )}
                </React.Fragment>
            )}
            {challenge && challenge.totalParticipants > 0 && (
                <React.Fragment>
                    <ChallengeIconInfo
                        clickable={showSocialMembersList}
                        icon="user"
                        onClick={() => setParticipantsDialogOpen(true)}
                        info={
                            <FormattedMessage
                                defaultMessage="{totalParticipants, plural, one {# participant} other {# participants}}"
                                description="count of participants in the challenge"
                                values={{
                                    totalParticipants:
                                        challenge.totalParticipants
                                }}
                            />
                        }
                    />
                    <ParticipantsDialog
                        open={participantsDialogOpen}
                        onClose={() => setParticipantsDialogOpen(false)}
                        challenge={challenge}
                        socialGroup={socialGroup}
                    />
                </React.Fragment>
            )}
        </Stack>
    )
}

export default memo(ChallengeAboutIcons)
