import { Box, CardMedia, Grid } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import ReviewYourStoryQuestionWrapper from '../ReviewYourStory/ReviewYourStoryQuestionWrapper'

const messages = defineMessages({
    uploadedImage: {
        defaultMessage: 'uploaded image',
        description: 'alt text for the uploaded image'
    }
})

export type YourStoryAnswersProps = {
    questionsData: QuestionData[]
}

const YourStoryAnswers: React.FC<YourStoryAnswersProps> = ({
    questionsData
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    return (
        <>
            {questionsData?.map((questionData, index: number) => (
                <Box key={index}>
                    {!questionData.image?.uri && (
                        <ReviewYourStoryQuestionWrapper
                            questionData={questionData}
                            disableNavigation={true}
                        >
                            <CoreTypography
                                variant="body1"
                                sx={{
                                    wordBreak: 'break-word'
                                }}
                            >
                                {questionData.answer}
                            </CoreTypography>
                        </ReviewYourStoryQuestionWrapper>
                    )}

                    {!!questionData.image?.uri && (
                        <Grid container>
                            <Grid item sm={6}>
                                <CoreTypography
                                    variant="h5"
                                    sx={{
                                        flexGrow: 1,
                                        color: theme.palette.primary.main
                                    }}
                                >
                                    {questionData.question}
                                </CoreTypography>
                            </Grid>
                            <Grid item sm={6}>
                                <CardMedia
                                    component="img"
                                    image={questionData?.image?.uri}
                                    sx={{
                                        minWidth: '100%'
                                    }}
                                    alt={formatMessage(messages.uploadedImage)}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            ))}
        </>
    )
}

export default memo(YourStoryAnswers)
