import { ComponentType, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    StoryType,
    UnifiedChallenge,
    UnifiedChallengeActivity,
    useGetStorySubmissionQuery
} from '../../../../graphql/generated/autogenerated'
import {
    StoryData,
    useParseStorySubmissionData
} from '../useParseStorySubmissionData'
import StorySubmissionContext, {
    StorySubmissionContextValue
} from './storySubmissionContext'
import { stripBOM } from '../../../../shared/utils/bom'

export default function withStorySubmissionProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const {
            challengeId: paramChallengeId,
            userChallengeActivityId: paramUserChallengeActivityId
        } = useParams<{
            challengeId: string
            userChallengeActivityId: string
        }>()
        const [challenge, setChallenge] = useState<UnifiedChallenge>()
        const [participation, setParticipation] =
            useState<UnifiedChallengeActivity>()
        const [challengeStoryData, setChallengeStoryData] =
            useState<StoryData>()

        const [onClose, setOnClose] = useState<() => void>()

        const { data, loading, error, refetch } = useGetStorySubmissionQuery({
            variables: {
                challengeId: challenge
                    ? challenge.id
                    : stripBOM(paramChallengeId)
            },
            skip: !challenge || !paramChallengeId || !!challengeStoryData
        })

        const storyProviderData = useParseStorySubmissionData({
            availableInteraction: data?.unifiedChallenges
                ?.availableInteractions?.[0] as StoryType,
            participationId: participation
                ? participation?.id
                : paramUserChallengeActivityId,
            stories: data?.unifiedChallenges?.getStories
        })

        const storyData = useMemo(
            () => (challengeStoryData ? challengeStoryData : storyProviderData),
            [challengeStoryData, storyProviderData]
        )

        const state = useMemo<StorySubmissionContextValue>(
            () => ({
                loading: loading,
                error: Boolean(error),
                ...storyData,
                challenge,
                participation,
                storyComplete: storyData?.stories?.length > 0,
                setChallenge,
                setParticipation,
                setChallengeStoryData
            }),
            [
                challengeStoryData,
                loading,
                error,
                storyData,
                challenge,
                participation
            ]
        )
        return (
            <StorySubmissionContext.Provider value={state}>
                <Component {...props} />
            </StorySubmissionContext.Provider>
        )
    }
}
