import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { FeelingButtonPromiseProps } from '../../../../../components/features/Challenge/DailyGoals/Goals/FeelingGoal/FeelingIconButton'
import FeelingsCard from '../../../../../components/features/Challenge/DailyGoals/Goals/FeelingGoal/FeelingsCard'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'

const SLEEP_HABITS = 'sleepHabits'

const SleepHabits: React.FC = () => {
    const { sleepHabit, setSleepHabit, addDisable } =
        useCompanyChallengeSignUpProviderContext()

    useEffect(() => {
        addDisable(SLEEP_HABITS, !sleepHabit)
    }, [sleepHabit, addDisable])

    return (
        <Stack gap={2}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Take a moment to reflect on your current sleep routine. How do you feel about your bedtime habits?"
                    description="sleep goal question"
                />
            </CoreTypography>
            <Box width="100%" p={1}>
                <FeelingsCard
                    feelingAnswer={sleepHabit}
                    darkBackground={true}
                    promise={(props: FeelingButtonPromiseProps) => {
                        setSleepHabit(props.answer)

                        return Promise.resolve()
                    }}
                />
            </Box>
        </Stack>
    )
}

export default memo(SleepHabits)
