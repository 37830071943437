import { Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    type ChallengeUserDailyGoal,
    type UnifiedChallenge,
    useAnswerFeelingQuestionMutation,
    useCompleteCommunityChallengeDailyGoalMutation
} from '../../../../../../../graphql/generated/autogenerated'
import GoalTitle from '../../GoalTitle'
import { FeelingButtonPromiseProps } from './FeelingIconButton'
import FeelingsCard from './FeelingsCard'

const messages = defineMessages({
    reflect: {
        defaultMessage: 'How well-rested do you feel today?',
        description: 'sleep goal question'
    }
})

export type FeelingGoalProps = {
    goal: ChallengeUserDailyGoal
    challenge: UnifiedChallenge
    disabled?: boolean
    onComplete?: () => void
    feelingAnswer?: number
}

const FeelingGoal: React.FC<FeelingGoalProps> = ({
    goal,
    challenge,
    disabled,
    onComplete,
    feelingAnswer
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { formatMessage } = useIntl()

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    const [answerFeelingQuestionMutation] = useAnswerFeelingQuestionMutation()

    const handleFeelingAnswer = useCallback(
        async (props: FeelingButtonPromiseProps) => {
            await answerFeelingQuestionMutation({
                variables: { ...props, challengeId: challenge?.id }
            })
            await completeDailyGoal()
            await onComplete?.()
        },
        [
            answerFeelingQuestionMutation,
            completeDailyGoal,
            onComplete,
            challenge?.id
        ]
    )
    return (
        <Stack
            gap={2}
            height="100%"
            flexWrap="wrap"
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
        >
            <Stack flex={1}>
                <GoalTitle title={formatMessage(messages.reflect)} />
            </Stack>
            <Box>
                <FeelingsCard
                    feelingAnswer={feelingAnswer}
                    disabled={disabled}
                    promise={(props: FeelingButtonPromiseProps) =>
                        handleFeelingAnswer(props)
                    }
                />
            </Box>
        </Stack>
    )
}

export default FeelingGoal
