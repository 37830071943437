import { useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafConfirmationDialog,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ConfirmDetails, CreateDisplayName, HowItWorks, JoinTeam } from '..'
import OverlappingComponentsList from '../../../../../components/elements/OverlappingComponentsList/OverlappingComponentsList'
import { SignUpSteps, SignUpTypes } from '../../../../../enums'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import { useInterstitialNavigation } from '../../../../../hooks/withInterstitialNavigation/useInterstitialNavigation'
import PrivacyInfo from '../../PrivacyInfo/PrivacyInfo'
import ConfirmActivity from '../ConfirmActivity/ConfirmActivity'
import ConfirmAndSign from '../ConfirmAndSign/ConfirmAndSign'
import ConfirmHydration from '../ConfirmHydration/ConfirmHydration'
import { CreateTeamWithProvider } from '../CreateTeam'
import ParticipationMethod from '../ParticipationMethod/ParticipationMethod'
import SignUpNavigation from '../SignUpNavigation/SignUpNavigation'
import SleepHabits from '../SleepHabits/SleepHabits'

const messages = defineMessages({
    Confirm: {
        defaultMessage: 'Confirm and join Challenge',
        description:
            'button text that completes the sign up to company challenge process'
    },
    yourSure: {
        defaultMessage: 'Are you sure?',
        description:
            'Question whether a user is certain they want to leave the challenge sign up process'
    },
    loseProgress: {
        defaultMessage: 'All of your sign-up details will be lost.',
        description:
            'Description on what will happen on leaving the sign up process'
    },
    imSure: {
        defaultMessage: 'Yes, I’m sure',
        description: 'leave Sign up button text'
    },
    cancel: {
        defaultMessage: 'Cancel',
        description: 'cancel confirmation dialog button text'
    }
})
export interface SignUpInterstitialProps extends JSX.IntrinsicAttributes {
    open: boolean
    challenge?: UnifiedChallenge
    onComplete?: () => Promise<any>
    handleOnClose: () => void
}

const SignUpInterstitial: React.FC<SignUpInterstitialProps> = ({
    open,
    challenge,
    onComplete,
    handleOnClose
}) => {
    const { breakpoints } = useTheme()
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const [stepTracked, setStepTracked] = useState<string>()
    const { formatMessage } = useIntl()
    const {
        isInvite,
        showPrivacy,
        maxTeamSize,
        setShowPrivacy,
        clearMutations,
        clearDisables,
        setTeamName,
        setSocialGroup,
        onSignUpComplete
    } = useCompanyChallengeSignUpProviderContext()
    const { hydrationEnabled, deviceEnabled, sleepEnabled } =
        useCompanyChallengeProviderContext()
    const {
        signUpType,
        isChangeParticipation,
        inviteSocialGroup,
        setSignUpType
    } = useChallengeSignUpModalContext()
    const {
        keys,
        activeKey,
        activeIndex,
        close,
        next,
        prev,
        setClose,
        setKeys
    } = useInterstitialNavigation()

    const onClose = useCallback(() => {
        handleOnClose()
        clearMutations()
        clearDisables?.()
        setTeamName(undefined)
        setSocialGroup(undefined)
    }, [
        clearMutations,
        clearDisables,
        handleOnClose,
        setTeamName,
        setSocialGroup
    ])

    useEffect(() => {
        if (open) {
            if (activeKey && activeKey !== stepTracked) {
                Avo.challengeSelected({
                    activityType: 'challenge_signup_form_served',
                    challengeId: challenge?.id,
                    challengeTheme: challenge?.theme,
                    challengeType: challenge?.challenge_type,
                    dayNumber: null,
                    featureType: 'challenge',
                    tabName: 'Home',
                    teamId: null,
                    teamType: null
                })
                setStepTracked(activeKey)
            }
        }
    }, [
        open,
        isInvite,
        challenge,
        activeKey,
        signUpType,
        stepTracked,
        isChangeParticipation
    ])

    useEffect(() => {
        // If the social group capacity has been reached we switch the signUpType from invite to jointeam
        if (inviteSocialGroup) {
            if (Number(inviteSocialGroup.memberCount) < Number(maxTeamSize)) {
                setSocialGroup(inviteSocialGroup)
            } else {
                setSignUpType(SignUpTypes.joinTeam)
            }
        }
    }, [inviteSocialGroup, maxTeamSize, setSocialGroup, setSignUpType])

    const onBack = useCallback(() => {
        if (activeIndex > 0) {
            clearMutations()
            clearDisables?.()
            prev()
        }
    }, [activeIndex, clearMutations, clearDisables, prev])

    const onNext = useCallback(
        (responses?: any) => {
            return activeKey === SignUpSteps.sign && !!onComplete
                ? onComplete().finally(() => onSignUpComplete(responses))
                : Promise.resolve(next())
        },
        [next, onComplete, onSignUpComplete, activeKey]
    )

    const { nextButtonText, showBackButton } = useMemo(() => {
        setClose(() => onClose)

        return {
            nextButtonText:
                activeKey === SignUpSteps.sign
                    ? formatMessage(messages.Confirm)
                    : undefined,
            showBackButton: activeIndex > 0
        }
    }, [activeKey, activeIndex, setClose, onClose, formatMessage])

    const signUpComponents = useMemo(() => {
        const activeSteps = {
            ...(!isChangeParticipation && {
                [SignUpSteps.displayName]: <CreateDisplayName />
            }),
            ...(!isChangeParticipation && {
                [SignUpSteps.participation_method]: <ParticipationMethod />
            }),
            ...(signUpType === SignUpTypes.createTeam && {
                [SignUpSteps.createTeam]: <CreateTeamWithProvider />
            }),
            ...(signUpType === SignUpTypes.joinTeam && {
                [SignUpSteps.joinTeam]: <JoinTeam />
            }),
            ...(deviceEnabled &&
                challenge?.allowCustomSteps &&
                !isChangeParticipation && {
                    [SignUpSteps.activity]: <ConfirmActivity />
                }),
            ...(hydrationEnabled &&
                !isChangeParticipation && {
                    [SignUpSteps.hydration]: <ConfirmHydration />
                }),
            ...(sleepEnabled &&
                !isChangeParticipation && {
                    [SignUpSteps.sleep]: <SleepHabits />
                }),
            [SignUpSteps.confirm]: <ConfirmDetails />,
            [SignUpSteps.sign]: <ConfirmAndSign challenge={challenge} />
        }
        setKeys(Object.keys(activeSteps))

        return activeSteps
    }, [
        signUpType,
        challenge,
        hydrationEnabled,
        sleepEnabled,
        deviceEnabled,
        isChangeParticipation,
        setKeys
    ])

    const onCloseHandler = useCallback(
        (confirm = true) => {
            if (confirm) {
                setConfirmationOpen(true)
            } else {
                close()
            }
        },
        [close]
    )

    return (
        <>
            <LeafDialog
                open={open}
                onClose={onCloseHandler}
                maxWidth="sm"
                fullScreen={isMobile}
                fullWidth
                PaperProps={{
                    sx: {
                        height: isMobile ? '100%' : '750px'
                    }
                }}
                dialogTitle={
                    <CoreTypography variant="h3">
                        <FormattedMessage
                            defaultMessage="{challengeName} Sign-up"
                            description="Title for the sign up to company challenge E.G Healthy Movement Sign-up"
                            values={{
                                challengeName: challenge?.name
                            }}
                        />
                    </CoreTypography>
                }
            >
                <SignUpNavigation
                    onBack={onBack}
                    onNext={onNext}
                    activeStep={activeIndex + 1}
                    totalSteps={keys?.length - 2}
                    nextButtonText={nextButtonText}
                    showBackButton={showBackButton}
                >
                    <OverlappingComponentsList
                        components={signUpComponents}
                        activeKey={String(activeKey)}
                    />
                </SignUpNavigation>
            </LeafDialog>
            <LeafDialog
                open={showPrivacy}
                onClose={() => setShowPrivacy(false)}
                maxWidth="sm"
                fullScreen={isMobile}
                fullWidth
                hideBackdrop
                dialogTitle={
                    <CoreTypography variant="h3">
                        <FormattedMessage
                            defaultMessage="Learn more"
                            description="Privacy card title"
                        />
                    </CoreTypography>
                }
                PaperProps={{
                    sx: {
                        height: isMobile ? '100%' : '750px'
                    }
                }}
            >
                <PrivacyInfo />
            </LeafDialog>
            <LeafConfirmationDialog
                open={confirmationOpen}
                fullWidth
                maxWidth="xs"
                dialogContent={formatMessage(messages.loseProgress)}
                dialogTitle={formatMessage(messages.yourSure)}
                onClose={() => setConfirmationOpen(false)}
                primaryButtonOnClick={() => {
                    onClose()
                    setConfirmationOpen(false)
                }}
                primaryButtonText={formatMessage(messages.imSure)}
                secondaryButtonOnClick={() => setConfirmationOpen(false)}
                secondaryButtonText={formatMessage(messages.cancel)}
            />
        </>
    )
}

export default memo(SignUpInterstitial)
