import { Stack } from '@mui/material'
import React, { memo } from 'react'
import ViewMoreDialog from '../ViewMoreDialog'
import TeamTotalPlants from '../../Shared/TotalPlantsGraph/TeamTotalPlants'
import type {
    CommunityGardenInfo,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'

export type TeamCardDialogProps = {
    groupedTeamPlants: CommunityGardenInfo[]
    challenge: UnifiedChallenge
    open: boolean
    onClose: () => void
}

const TeamRecapDialog: React.FC<TeamCardDialogProps> = ({
    open,
    onClose,
    groupedTeamPlants,
    challenge
}) => {
    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={3} pb={6}>
                <TeamTotalPlants
                    groupedTeamPlants={groupedTeamPlants}
                    challenge={challenge}
                />
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(TeamRecapDialog)
