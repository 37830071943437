import { Box, Button, Grid } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { MutableRefObject, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { StoryImageQuestion, StoryTextQuestion } from '..'
import { QuestionType } from '../../../../../enums/questionType'
import { QuestionData } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider/storySubmissionContext'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider'
import { useInterstitialNavigation } from '../../../../contexts/withInterstitialNavigation'
import { sortByordering } from '../../../../../utils'

export type QuestionWrapperProps = {
    questionData: QuestionData
    activeSubStep: number
    index: number
    containerRef?: MutableRefObject<any>
}

const messages = defineMessages({
    previous: {
        defaultMessage: 'Previous question',
        description:
            'button text that will navigate the user to the previously answered question in a list of questions'
    },
    back: {
        defaultMessage: 'Back',
        description:
            'Button text that will navigate the user back a step in the current process'
    }
})

const StoryQuestions: React.FC = () => {
    const theme = useTheme()
    const intl = useIntl()
    const containerRef = React.useRef(null)

    const { activeKeySubIndex, prev } = useInterstitialNavigation()

    const { answeredQuestions } = useStorySubmissionStateProvider()

    const questionsAfterOneActive = useMemo(
        () => Number(activeKeySubIndex) > 0,
        [activeKeySubIndex]
    )

    const sortedQuestions = useMemo(
        () => answeredQuestions.sort(sortByordering),
        [answeredQuestions]
    )

    return (
        <Box
            data-testid="story-question"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                height: theme.spacing(65)
            }}
            ref={containerRef}
        >
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    maxWidth: '100%',
                    color: theme.palette.primary.main
                }}
                direction="column"
            >
                <Grid item>
                    <Box
                        sx={{
                            transition: 'all 0.5s ease',
                            [theme.breakpoints.up('sm')]: {
                                ...(questionsAfterOneActive && {
                                    transform: `translate(calc(50% - ${theme.spacing(
                                        31.875
                                    )}), 0)`
                                })
                            }
                        }}
                    >
                        <Button
                            onClick={() => prev()}
                            data-testid="back-button"
                            aria-label={
                                questionsAfterOneActive
                                    ? intl.formatMessage(messages.previous)
                                    : intl.formatMessage(messages.back)
                            }
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <LeafIcon
                                    icon={'arrow-left'}
                                    sx={{
                                        fontSize: theme.spacing(2.5),
                                        transition: 'all 0.3s ease',
                                        mr: theme.spacing(1),
                                        ...(questionsAfterOneActive && {
                                            transform: 'rotate(90deg)'
                                        })
                                    }}
                                />
                                {questionsAfterOneActive
                                    ? intl.formatMessage(messages.previous)
                                    : intl.formatMessage(messages.back)}
                            </CoreTypography>
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative'
                    }}
                >
                    {sortedQuestions?.map((questionData, index: number) => (
                        <Grid key={index} container>
                            <QuestionWrapper
                                questionData={questionData}
                                activeSubStep={Number(activeKeySubIndex)}
                                index={index}
                                containerRef={containerRef}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    )
}

export const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
    questionData,
    activeSubStep,
    index,
    containerRef
}) => {
    const questionComponents = {
        [QuestionType.image]: StoryImageQuestion,
        [QuestionType.text]: StoryTextQuestion
    }
    const QuestionComponent =
        questionComponents[questionData?.type as QuestionType]

    return (
        <Grid key={index} item>
            <QuestionComponent
                index={index}
                questionData={questionData}
                isActive={activeSubStep === Number(questionData.ordering) - 1}
                containerRef={containerRef}
            />
        </Grid>
    )
}

export default StoryQuestions
