import { Box, Button, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { FC, useCallback, useMemo } from 'react'
import { defineMessage, useIntl } from 'react-intl'

type ActionsProps = {
    startOfSteps: boolean
    endOfSteps: boolean
    handleBack: () => void
    handleNext: () => void
    onClose: () => void
}

const BACK_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Back',
    description: 'Text for the back button'
})

const NEXT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Next',
    description: 'Text for the next button'
})

const SEE_CHALLENGE_BUTTON_TEXT = defineMessage({
    defaultMessage: 'See challenge',
    description:
        'Text of the button when user reaches the end of the steps, when clicking it, the unboxing modal closes and user is back to the Insights page'
})

const Actions: FC<ActionsProps> = ({
    startOfSteps,
    endOfSteps,
    handleBack,
    handleNext,
    onClose
}) => {
    const intl = useIntl()
    const { breakpoints } = useTheme()
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'))

    const handleClick = useCallback(() => {
        if (endOfSteps) {
            onClose()
        } else {
            handleNext()
        }
    }, [endOfSteps, handleNext, onClose])

    const buttonText = useMemo(
        () =>
            intl.formatMessage(
                endOfSteps ? SEE_CHALLENGE_BUTTON_TEXT : NEXT_BUTTON_TEXT
            ),
        [endOfSteps, intl]
    )

    return (
        <Box
            display="flex"
            justifyContent={isSmallScreen ? undefined : 'flex-end'}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            width="100%"
            gap={1}
        >
            {!startOfSteps && (
                <Button
                    onClick={handleBack}
                    disabled={startOfSteps}
                    size={isSmallScreen ? 'medium' : 'large'}
                    variant="contained"
                    color="secondary"
                    fullWidth={isSmallScreen}
                >
                    <CoreTypography customVariant="buttonNormal">
                        {intl.formatMessage(BACK_BUTTON_TEXT)}
                    </CoreTypography>
                </Button>
            )}
            <Button
                onClick={handleClick}
                variant="contained"
                color="primary"
                size={'medium'}
                fullWidth={isSmallScreen}
            >
                <CoreTypography customVariant="buttonNormal">
                    {buttonText}
                </CoreTypography>
            </Button>
        </Box>
    )
}
export default Actions
