import { IconButton, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeType } from '../../../../../../shared/enums'
import DisposableDialog from '../../../../elements/DisposableDialog'
import CompanyChallengeSettings from '../../CompanyChallengeSettings'
import GroupChallengeSettings from '../../GroupChallengeSettings'
import type {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../../graphql/generated/autogenerated'
import type { SocialGroupsValues } from '../../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import ChangeParticipationModal from '../../../Active/ChallengeParticipation'
import { SIGNUP_VARIANT_TYPE } from 'src/v2/constants'

const messages = defineMessages({
    settingsButtonAriaLabel: {
        defaultMessage:
            'Open settings modal to change your display name and team name as it applies',
        description: 'Aria label for the settings button'
    }
})

export interface GoalChallengeSettingsButtonProps {
    color?: string
    variant?: string
    challenge?: UnifiedChallenge
    socialGroups?: SocialGroupsValues
    participation?: UnifiedChallengeActivity
    refetchUnifiedChallengeAvailability?: () => void
}

const GoalChallengeSettingsButton: React.FC<
    GoalChallengeSettingsButtonProps
> = ({
    color,
    variant,
    challenge,
    socialGroups,
    participation,
    refetchUnifiedChallengeAvailability
}) => {
    const { formatMessage } = useIntl()
    const [openChangeParticipationModal, setOpenChangeParticipationModal] =
        useState(false)
    const [participationVariant, setParticipationVariant] =
        useState<SIGNUP_VARIANT_TYPE | null>(null)

    const DialogComponent = useMemo(
        () =>
            challenge?.challenge_type == ChallengeType.group
                ? GroupChallengeSettings
                : CompanyChallengeSettings,
        [challenge]
    )

    const onChangeParticipation = (
        participationVariant: SIGNUP_VARIANT_TYPE
    ) => {
        setParticipationVariant(participationVariant)
        setOpenChangeParticipationModal(true)
    }

    return (
        <>
            <DisposableDialog
                button={
                    <IconButton
                        {...(color && { color })}
                        {...(variant && { variant })}
                        aria-label={formatMessage(
                            messages.settingsButtonAriaLabel
                        )}
                    >
                        <LeafIcon icon={'gear'} />
                    </IconButton>
                }
                Dialog={DialogComponent}
                dialogProps={{
                    challenge,
                    socialGroups,
                    participation,
                    onChangeParticipation,
                    refetchUnifiedChallengeAvailability
                }}
            />
            <DisposableDialog
                open={openChangeParticipationModal}
                onClose={() => setOpenChangeParticipationModal(false)}
                Dialog={ChangeParticipationModal}
                dialogProps={{
                    participationVariant,
                    challenge,
                    socialGroups,
                    participation,
                    onChangeParticipation,
                    refetchUnifiedChallengeAvailability
                }}
            />
        </>
    )
}

export default memo(GoalChallengeSettingsButton)
