import { useMemo } from 'react'
import {
    type ChallengeTemplateGoal,
    type UnifiedChallenge
} from '../../../graphql/generated/autogenerated'
import useGetChallengeIntro from '../../hooks/useGetChallengeIntro/useGetChallengeIntro'
import useGetPreviewGoals from '../../hooks/useGetPreviewGoals/useGetPreviewGoals'
import { getChallengeDates } from '../../utils/challenge'

interface ChallengeIntroValue {
    error?: Error
    loading: boolean
    challenge?: UnifiedChallenge
    isTemplate: boolean
    goals: ChallengeTemplateGoal[]
    startDate?: Date | undefined
    endDate?: Date | undefined
}

const useChallengeIntro = (
    challengeId: string,
    isTemplate: boolean
): ChallengeIntroValue => {
    const {
        challenge,
        loading: challengeLoading,
        error: challengeError
    } = useGetChallengeIntro({ challengeId, isTemplate })

    const {
        goals,
        loading: goalsLoading,
        error: goalsError
    } = useGetPreviewGoals(challengeId)

    const loading = useMemo(
        () => challengeLoading || goalsLoading,
        [challengeLoading, goalsLoading]
    )

    const error = useMemo(
        () => challengeError || goalsError,
        [challengeError, goalsError]
    )

    const { startDate, endDate } = useMemo(
        () => getChallengeDates(challenge),
        [challenge]
    )

    if (error) throw error

    return {
        error,
        loading,
        isTemplate,
        challenge: challenge as UnifiedChallenge,
        goals,
        startDate,
        endDate
    }
}

export default useChallengeIntro
