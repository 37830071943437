import { createContext } from 'react'
import {
    ChallengeUserDailyGoal,
    MeasureUnit
} from '../../../graphql/generated/autogenerated'

export interface HydrationValue {
    waterIntake: number
    loading: boolean
    error: boolean
    glasses: number
    addIntakeLoading: boolean
    goal?: ChallengeUserDailyGoal
    goalCount: number
    intakeMeasurement?: MeasureUnit
    setGoalCount: (count: number) => void
    setIntakeMeasurement: (measurement: MeasureUnit) => void
    saveWaterGoal: (
        waterAmount: number,
        measureUnit: MeasureUnit
    ) => Promise<unknown>
    addIntake: (add: boolean) => void
    setGlasses: (glasses: number) => void
    setGoal: (goal: ChallengeUserDailyGoal) => void
}

export const defaultContextValue: HydrationValue = {
    waterIntake: 0,
    loading: false,
    error: false,
    glasses: 0,
    addIntakeLoading: false,
    goal: undefined,
    goalCount: 8,
    intakeMeasurement: undefined,
    setGoalCount: () => null,
    setIntakeMeasurement: () => null,
    saveWaterGoal: () => Promise.resolve(),
    addIntake: () => null,
    setGlasses: () => null,
    setGoal: () => null
}

export default createContext(defaultContextValue)
