import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import SearchField from '../../../../../../../components/elements/SearchField/SearchField'
import { SwitchSignUpTypeButton } from '../../../../../../../components/features/CompanyChallenge/SignUp'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../../../hooks/withChallengeSignUpModalProvider'

const messages = defineMessages({
    search: {
        defaultMessage: 'Search',
        description:
            'placeholder text that is shown inside a search for social group text field'
    },
    searchAriaLabel: {
        defaultMessage: 'Social Groups list automatic search input',
        description: 'aria label describing what the search is for'
    }
})

export interface JoinTeamActionsHeaderProps {
    setSearchTerm: (searchTeam: string) => void
    isEmpty: boolean
}

const JoinTeamActionsHeader: React.FC<JoinTeamActionsHeaderProps> = ({
    setSearchTerm,
    isEmpty
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { setSocialGroup } = useCompanyChallengeSignUpProviderContext()
    const { isChangeParticipation } = useChallengeSignUpModalContext()

    return (
        <Stack gap={1}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <CoreTypography variant="h4" component="p">
                    <FormattedMessage
                        defaultMessage="Join a team"
                        description="choose a team description"
                    />
                </CoreTypography>
                {!isChangeParticipation && !isEmpty && (
                    <SwitchSignUpTypeButton
                        onSwitch={() => setSocialGroup(undefined)}
                    />
                )}
            </Stack>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={2}
                justifyContent="space-between"
            >
                <Stack width={isMobile ? '100%' : 343}>
                    <SearchField
                        setSearchTerm={setSearchTerm}
                        placeholder={formatMessage(messages.search)}
                        ariaLabel={formatMessage(messages.searchAriaLabel)}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default memo(JoinTeamActionsHeader)
