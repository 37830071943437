import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCard from '../WaveCard'

const messages = defineMessages({
    totalWater: {
        defaultMessage: 'Team water total',
        description: 'total water title'
    }
})

type TeamWaterTotalProps = {
    teamTotalWater: number
    loading: boolean
    error: boolean
}

const TeamWaterTotal: React.FC<TeamWaterTotalProps> = ({
    teamTotalWater,
    loading,
    error
}) => {
    const { formatMessage } = useIntl()

    return (
        <>
            {!error && (
                <WaveCard
                    title={formatMessage(messages.totalWater)}
                    loading={loading}
                >
                    <ConditionalSkeleton
                        showSkeleton={loading}
                        variant="rounded"
                    >
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            color="text.secondary"
                        >
                            {teamTotalWater}
                        </CoreTypography>
                    </ConditionalSkeleton>
                </WaveCard>
            )}
        </>
    )
}

export default memo(TeamWaterTotal)
