import { Button, CardContent, CardMedia, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafFixedMediaCard,
    LeafFlexMediaCard
} from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import ContentCardSkeleton from './Skeleton'

export interface ContentCardProps {
    title?: string
    contentType?: string
    description?: string
    duration?: string
    image?: string
    cta?: ContentCardCTA
    loading?: boolean
    elevation?: number
    disabled?: boolean
    alignMedia?: 'left' | 'top'
    onClick?: () => void
}

export interface ContentCardCTA {
    label: string
    onClick: () => void
}

const ContentCard: React.FC<ContentCardProps> = ({
    title,
    contentType,
    description,
    duration,
    image,
    cta,
    loading = false,
    elevation = 1,
    alignMedia,
    disabled,
    onClick
}) => {
    const ariaId = uuid()

    const generateCardContent = useMemo(() => {
        return (
            <CardContent sx={{ p: 0, pb: '0!important' }}>
                <Stack gap={2} height="100%" p={1}>
                    <Stack
                        gap={1}
                        textAlign="left"
                        justifyContent="space-between"
                    >
                        <Stack gap={1}>
                            <Stack>
                                {!!contentType && (
                                    <CoreTypography
                                        variant="body2"
                                        color="text.disabled"
                                    >
                                        {contentType}
                                    </CoreTypography>
                                )}
                                <CoreTypography variant="h5" component="p">
                                    {title}
                                </CoreTypography>
                            </Stack>
                            {description && (
                                <CoreTypography
                                    variant="caption"
                                    id={`description-${ariaId}`}
                                >
                                    {description}
                                </CoreTypography>
                            )}
                        </Stack>
                        {duration && (
                            <CoreTypography
                                variant="body2"
                                color="text.disabled"
                            >
                                {duration}
                            </CoreTypography>
                        )}
                    </Stack>
                    {cta && (
                        <Stack alignItems="flex-end" justifyContent="center">
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={cta.onClick}
                                disabled={disabled}
                                aria-describedby={`description-${ariaId}`}
                            >
                                <CoreTypography variant="h5">
                                    {cta.label}
                                </CoreTypography>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </CardContent>
        )
    }, [cta, description, title, ariaId, disabled, contentType, duration])

    if (loading) {
        return <ContentCardSkeleton />
    }

    return image ? (
        <LeafFixedMediaCard
            media={<CardMedia image={image} />}
            sx={{ height: '100%' }}
            alignMedia={alignMedia ? alignMedia : 'left'}
            mediaWidth="md"
            elevation={elevation}
            {...(!disabled &&
                onClick && {
                    actionAreaProps: {
                        onClick,
                        'aria-describedby': `description-${ariaId}`
                    }
                })}
        >
            {generateCardContent}
        </LeafFixedMediaCard>
    ) : (
        <LeafFlexMediaCard
            elevation={elevation}
            sx={{ height: '100%' }}
            {...(!disabled &&
                onClick && {
                    actionAreaProps: {
                        onClick,
                        'aria-describedby': `description-${ariaId}`
                    }
                })}
        >
            {generateCardContent}
        </LeafFlexMediaCard>
    )
}

export default ContentCard
