import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    Box,
    CardActionArea,
    CardMedia,
    CircularProgress,
    Stack,
    useTheme
} from '@mui/material'
import { previewCardHeight, previewCardWidth } from './constants'

export type PostResetNextResetPreviewProps = {
    thumbnail: string
    name: string
    description: string
    onImageClick: () => void
}

const messages = defineMessages({
    playResetLabel: {
        defaultMessage: 'Play reset',
        description: 'Play reset label'
    }
})

function PostResetNextResetPreview(props: PostResetNextResetPreviewProps) {
    const { name, thumbnail, description, onImageClick } = props

    const theme = useTheme()
    const intl = useIntl()

    return (
        <Stack
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: theme.palette.primary.contrastText,
                alignItems: 'center',
                pt: theme.spacing(6.5)
            }}
            data-testid="PostResetNextResetPreview"
        >
            <CoreTypography
                variant="body2"
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.primary.contrastText}
            >
                <FormattedMessage
                    defaultMessage="NEXT UP..."
                    description="Post thrive reset watch next title"
                />
            </CoreTypography>

            <Box
                sx={{
                    mt: theme.spacing(2.5),
                    mb: theme.spacing(3),
                    width: previewCardWidth,
                    height: previewCardHeight
                }}
            >
                {thumbnail ? (
                    <CardActionArea
                        onClick={onImageClick}
                        aria-label={intl.formatMessage(messages.playResetLabel)}
                    >
                        <CardMedia
                            component="img"
                            draggable={false}
                            sx={{
                                height: '100%',
                                borderRadius: theme.spacing(0.5)
                            }}
                            image={thumbnail}
                            alt={description}
                        />
                    </CardActionArea>
                ) : (
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        height="100%"
                        width="100%"
                    >
                        <CircularProgress color="secondary" />
                    </Box>
                )}
            </Box>
            <CoreTypography
                variant="h3"
                color={theme.palette.primary.contrastText}
                data-testid="NextResetName"
            >
                {name}
            </CoreTypography>
        </Stack>
    )
}

export default React.memo(PostResetNextResetPreview)
