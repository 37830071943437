import { Stack } from '@mui/material'
import React, { memo } from 'react'

export type CenterAlignProps = {
    children: React.ReactNode
}

const CenterAlign: React.FC<CenterAlignProps> = ({ children }) => (
    <Stack justifyContent="center" alignItems="center">
        {children}
    </Stack>
)

export default memo(CenterAlign)
