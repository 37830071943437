const DirtSimplified = () => (
    <g>
        <path
            d="M60.8538 63.242C60.8238 63.332 60.8538 63.402 60.9538 63.352C61.0938 63.272 61.2538 63.182 61.2638 62.962C61.2238 62.852 61.1538 62.732 61.1238 62.602C61.1038 62.502 61.1038 62.402 60.9638 62.412C60.8738 62.412 60.7838 62.412 60.7538 62.512C60.7138 62.612 60.6838 62.782 60.7638 62.812C61.0538 62.912 60.9238 63.072 60.8538 63.232V63.242Z"
            fill="#DCCCC1"
        />
        <path
            d="M61.5938 67.022C61.8838 67.222 62.1138 66.762 62.4238 67.102C62.3338 66.812 62.3638 66.552 62.0938 66.492C61.5938 66.382 61.8538 66.922 61.5938 67.032V67.022Z"
            fill="#DCCCC1"
        />
        <path
            d="M55.4238 71.632C56.1038 70.122 57.8038 69.742 59.8038 70.902C61.4438 71.852 63.5838 76.022 63.5238 76.312L55.4238 71.632Z"
            fill="#B09F93"
        />
        <path
            d="M20.3538 62.662C21.2938 60.562 23.6538 60.052 26.4138 61.652C28.6938 62.962 31.6438 68.732 31.5638 69.142L20.3438 62.662H20.3538Z"
            fill="#B09F93"
        />
        <path
            d="M55.6938 87.972C56.3638 86.492 58.0238 86.122 59.9838 87.252C61.5938 88.182 63.6838 92.262 63.6338 92.552L55.6938 87.962V87.972Z"
            fill="#B09F93"
        />
        <path
            d="M9.7438 71.502C10.7338 69.282 13.2338 68.742 16.1538 70.432C18.5638 71.822 21.6738 77.922 21.6038 78.352L9.75378 71.512L9.7438 71.502Z"
            fill="#B09F93"
        />
        <path
            d="M85.2838 95.372C85.1338 95.372 67.7038 85.252 67.5538 85.182C67.4038 85.112 67.3338 84.932 67.4038 84.782C67.7238 84.072 68.2538 83.582 68.9438 83.352C69.8538 83.062 70.9738 83.262 72.1238 83.922C72.6738 84.242 73.2138 84.652 73.7238 85.152C75.0438 84.122 77.0238 84.202 79.0938 85.402C82.2838 87.242 85.0138 91.292 85.5838 95.032C85.6038 95.192 85.4938 95.352 85.3338 95.372C85.3238 95.372 85.3038 95.372 85.2938 95.372H85.2838Z"
            fill="#B09F93"
        />
        <path
            d="M46.3538 94.952C44.7138 94.002 43.2738 94.092 42.4338 94.992C41.4038 90.602 38.1738 85.962 34.3338 83.752C30.1838 81.352 26.7438 82.622 26.0338 86.482L41.9838 95.692L42.6438 96.072L50.7238 100.742C50.0438 98.452 48.3438 96.122 46.3538 94.972V94.952Z"
            fill="#B09F93"
        />
    </g>
)

export default DirtSimplified
