import { createContext } from 'react'
import { SocialGroup } from '../../graphql/generated/autogenerated'

export interface CompanyChallengeSignUpValue {
    loading: boolean
    error: boolean
    disabled: boolean
    teamName?: string
    searchedTeamName?: string
    socialGroup?: SocialGroup
    showPrivacy: boolean
    isInvite: boolean
    maxTeamSize?: number
    isPublicGroup: boolean
    sleepHabit?: number
    activityGoal?: number
    setActivityGoal: (activityGoal: number) => void
    setSleepHabit: (sleepHabit: number) => void
    setIsPublicGroup: (isPublicGroup: boolean) => void
    setLoading: (loading: boolean) => void
    setShowPrivacy: (showPrivacy: boolean) => void
    setTeamName: (teamName?: string) => void
    setSearchedTeamName: (searchedTeamName?: string) => void
    setSocialGroup: (socialGroup?: SocialGroup) => void
    addDisable: (key: string, disable: boolean) => void
    clearDisables?: () => void
    triggerMutations: (
        externalPromise?: (responses?: any[]) => Promise<any>
    ) => Promise<any>
    addMutation: ({
        key,
        mutation,
        onMutationComplete
    }: {
        key?: string
        mutation: () => Promise<any>
        onMutationComplete?: (
            response: any
        ) => boolean | { socialGroupId: string }
    }) => void
    clearMutations: () => void
    onSignUpComplete: (
        responses?: (boolean | { socialGroupId: string })[]
    ) => void
}

export const defaultContextValue: CompanyChallengeSignUpValue = {
    loading: false,
    error: false,
    disabled: false,
    teamName: undefined,
    searchedTeamName: undefined,
    socialGroup: undefined,
    showPrivacy: false,
    isInvite: false,
    maxTeamSize: 10,
    isPublicGroup: false,
    sleepHabit: undefined,
    activityGoal: undefined,
    setActivityGoal: () => ({}),
    setSleepHabit: () => ({}),
    setIsPublicGroup: () => ({}),
    setLoading: () => ({}),
    setShowPrivacy: () => ({}),
    setTeamName: () => ({}),
    setSearchedTeamName: () => ({}),
    setSocialGroup: () => ({}),
    addDisable: () => ({}),
    clearDisables: () => ({}),
    triggerMutations: () => Promise.resolve(),
    addMutation: () => ({}),
    clearMutations: () => ({}),
    onSignUpComplete: () => ({})
}

export default createContext(defaultContextValue)
