import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback, useMemo } from 'react'
import {
    ChallengeUserDailyGoal,
    FeelingAnswer,
    GoalType,
    MeasureUnit,
    SocialGroup,
    UnifiedChallenge
} from '../../../graphql/generated/autogenerated'
import { ReactNullValue } from '../../../shared/utils/Nulls'
import ActivityGoal from '../../components/features/Shared/DailyGoals/Goals/ActivityGoal'
import ArticleGoal from '../../components/features/Shared/DailyGoals/Goals/ArticleGoal'
import ArticleGoalByQuery from '../../components/features/Shared/DailyGoals/Goals/ArticleGoal/ArticleGoalByQuery'
import AudioResetGoal from '../../components/features/Shared/DailyGoals/Goals/AudioResetGoal'
import AudioResetGoalByQuery from '../../components/features/Shared/DailyGoals/Goals/AudioResetGoal/AudioResetGoalByQuery'
import DailyCheckInGoal from '../../components/features/Shared/DailyGoals/Goals/DailyCheckInGoal'
import ExternalLinkGoal from '../../components/features/Shared/DailyGoals/Goals/ExternalLinkGoal'
import { FeelingGoal } from '../../components/features/Shared/DailyGoals/Goals/FeelingGoal'
import HydrationGoal from '../../components/features/Shared/DailyGoals/Goals/HydrationGoal/HydrationGoal'
import MicrostepGoal from '../../components/features/Shared/DailyGoals/Goals/MicrostepGoal'
import MicrostepGoalByQuery from '../../components/features/Shared/DailyGoals/Goals/MicrostepGoal/MicrostepGoalByQuery'
import ResetGoal from '../../components/features/Shared/DailyGoals/Goals/ResetGoal'
import ResetGoalByQuery from '../../components/features/Shared/DailyGoals/Goals/ResetGoal/ResetGoalByQuery'
import { getChallengeDates } from '../../utils/challenge'

export interface useDailyGoalsProps {
    day?: number
    isTemplate?: boolean
    challenge?: UnifiedChallenge
    socialGroup?: SocialGroup
    goals?: ChallengeUserDailyGoal[]
    disabled?: boolean
    preview?: boolean
    setResetPlaying?: (resetPlaying: boolean) => void
    dailyGoalRefetch?: () => Promise<void>
    userTodaySteps?: number
    feelingAnswer?: number
    waterIntake?: number
    measureUnitSettings?: MeasureUnit | null
}

export const useDailyGoals = ({
    day,
    isTemplate,
    challenge,
    socialGroup,
    goals,
    disabled,
    preview,
    setResetPlaying,
    dailyGoalRefetch,
    userTodaySteps,
    feelingAnswer,
    waterIntake,
    measureUnitSettings
}: useDailyGoalsProps) => {
    const onChallengeGoalCompleted = useCallback(
        (goal: ChallengeUserDailyGoal) => {
            Avo.challengeGoalCompleted({
                featureType: 'challenge',
                activityType: 'daily_goals_completed',
                challengeGoalId: goal?.challengeGoal?.id,
                challengeId: challenge?.id,
                challengeTheme: challenge?.theme,
                challengeType: challenge?.challenge_type,
                dayNumber: day,
                teamId: !socialGroup?.isSoloChallenge
                    ? socialGroup?.id
                    : ReactNullValue,
                teamType: ReactNullValue
            })
            dailyGoalRefetch?.()
        },
        [challenge, day, socialGroup, dailyGoalRefetch]
    )

    const articleGoal = useCallback(
        (goal: any) =>
            preview ? (
                <ArticleGoalByQuery
                    id={goal.challengeGoal.goalEntityId}
                    type={goal.challengeGoal.goalType}
                    challenge={challenge as UnifiedChallenge}
                    socialGroup={socialGroup as SocialGroup}
                />
            ) : (
                <ArticleGoal
                    goal={goal}
                    challenge={challenge as UnifiedChallenge}
                    socialGroup={socialGroup as SocialGroup}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                />
            ),
        [preview, onChallengeGoalCompleted, challenge, socialGroup]
    )

    const dailyGoalsComponents = useMemo<{
        [key: string]: (goal: ChallengeUserDailyGoal) => JSX.Element
    }>(
        () => ({
            [GoalType.PulseCheckIn]: (goal) => (
                <DailyCheckInGoal
                    challenge={challenge}
                    isTemplate={isTemplate}
                    goal={goal}
                    disabled={preview || disabled}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                />
            ),
            [GoalType.CheckIn]: (goal) =>
                preview ? (
                    <MicrostepGoalByQuery
                        id={goal.challengeGoal.goalEntityId}
                        type={goal.challengeGoal.goalType as GoalType}
                    />
                ) : (
                    <MicrostepGoal
                        goal={goal}
                        challenge={challenge}
                        disabled={preview || disabled}
                        onComplete={() => onChallengeGoalCompleted(goal)}
                    />
                ),
            [GoalType.ResetWatched]: (goal) =>
                preview ? (
                    <ResetGoalByQuery
                        challenge={challenge}
                        id={goal.challengeGoal.goalEntityId}
                        type={goal.challengeGoal.goalType as GoalType}
                    />
                ) : (
                    <ResetGoal
                        challenge={challenge}
                        goal={goal}
                        onStart={() => setResetPlaying?.(true)}
                        onComplete={() => {
                            setResetPlaying?.(false)
                            onChallengeGoalCompleted(goal)
                        }}
                    />
                ),
            [GoalType.ResetListened]: (goal) =>
                preview ? (
                    <AudioResetGoalByQuery
                        id={goal.challengeGoal.goalEntityId}
                        challenge={challenge as UnifiedChallenge}
                        type={goal.challengeGoal.goalType as GoalType}
                        onStart={() => setResetPlaying?.(true)}
                        onComplete={() => setResetPlaying?.(false)}
                    />
                ) : (
                    <AudioResetGoal
                        goal={goal}
                        challenge={challenge as UnifiedChallenge}
                        onStart={() => setResetPlaying?.(true)}
                        onComplete={() => {
                            setResetPlaying?.(false)
                            onChallengeGoalCompleted(goal)
                        }}
                    />
                ),
            [GoalType.ExternalLink]: (goal) => (
                <ExternalLinkGoal
                    goal={goal}
                    challenge={challenge}
                    disabled={preview || disabled}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                />
            ),
            [GoalType.Water]: (goal) => (
                <HydrationGoal
                    goal={goal}
                    waterIntake={waterIntake}
                    measureUnitSettings={measureUnitSettings}
                    challenge={challenge as UnifiedChallenge}
                    disabled={preview || disabled}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                    dailyGoalRefetch={dailyGoalRefetch}
                />
            ),
            [GoalType.RoleModelRead]: articleGoal,
            [GoalType.RoleModelWatched]: articleGoal,
            [GoalType.ArticleWatched]: articleGoal,
            [GoalType.ArticleRead]: articleGoal,
            [GoalType.RecipeRead]: articleGoal,
            [GoalType.RecipeWatched]: articleGoal,
            [GoalType.Steps]: (goal) => (
                <ActivityGoal
                    goal={goal}
                    startDate={getChallengeDates(challenge).startDate}
                    disabled={preview || disabled}
                    userTodaySteps={userTodaySteps}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                    dailyGoalRefetch={dailyGoalRefetch}
                />
            ),
            [GoalType.FeelingQuestion]: (goal) => (
                <FeelingGoal
                    goal={goal}
                    challenge={challenge as UnifiedChallenge}
                    disabled={preview || disabled}
                    onComplete={() => onChallengeGoalCompleted(goal)}
                    feelingAnswer={feelingAnswer}
                />
            )
        }),
        [
            preview,
            disabled,
            challenge,
            isTemplate,
            articleGoal,
            setResetPlaying,
            onChallengeGoalCompleted,
            userTodaySteps,
            feelingAnswer,
            waterIntake,
            measureUnitSettings
        ]
    )

    return useMemo(
        () =>
            goals?.map((goal) => {
                const goalComponent =
                    dailyGoalsComponents[String(goal.challengeGoal.goalType)]

                return {
                    component: goalComponent
                        ? goalComponent(goal)
                        : ReactNullValue,
                    goal
                }
            }),
        [goals, dailyGoalsComponents]
    )
}

export default useDailyGoals
