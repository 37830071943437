import { Stack } from '@mui/material'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LabelledCheckbox from '../../../../../elements/LabelledCheckbox'
import SignupTemplateTermsStepDetails from './TermsStepDetails'

const messages = defineMessages({
    confirm: {
        defaultMessage: 'I confirm my participation and agree to the above',
        description: 'label for the checkbox to confirm participation method'
    }
})

export interface TermsProps {
    deviceEnabled: boolean
    confirmed: boolean | undefined
    setConfirmed: (event: any) => void
    isChallengeTemplate: boolean
    isTeamSignUp: boolean
}

const TermsStep: React.FC<TermsProps> = ({
    confirmed,
    setConfirmed,
    deviceEnabled,
    isChallengeTemplate,
    isTeamSignUp
}) => {
    const { formatMessage } = useIntl()
    return (
        <Stack gap={2}>
            <Stack gap={2}>
                <SignupTemplateTermsStepDetails
                    isTemplate={isChallengeTemplate}
                    isTeamSignUp={isTeamSignUp}
                    deviceEnabled={deviceEnabled}
                />
                <LabelledCheckbox
                    checked={confirmed || false}
                    onChange={() => setConfirmed(!confirmed)}
                    label={formatMessage(messages.confirm)}
                />
            </Stack>
        </Stack>
    )
}

export default memo(TermsStep)
