import { Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo } from 'react'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import {
    ArticleContentType,
    ArticleDetail,
    ChallengeUserDailyGoal,
    GoalType,
    SocialGroup,
    UnifiedChallenge
} from '../../../../../../../graphql/generated/autogenerated'
import { ChallengeType } from '../../../../../../../shared'
import ContentCard from '../../../../../../../shared/components/elements/ContentCard/ContentCard'
import { getDurationInMinutes } from '../../../../../../../utils'
import { defaultContentStartedProps } from '../../../../../../utils/defaultTrackingProps'
import GoalTitle from '../../GoalTitle'

const messages = defineMessages({
    readArticle: {
        defaultMessage: 'Read an article',
        description: 'title for the article goal card'
    },
    watchArticle: {
        defaultMessage: 'Watch a video',
        description: 'title for the article goal card'
    },
    tryRecipe: {
        defaultMessage: 'Check out this Recipe',
        description: 'title for the recipe article/video goal card'
    },
    getInspired: {
        defaultMessage: 'Get inspired by your coworkers',
        description: 'title for the role model goal card'
    },
    hasReadArticle: {
        defaultMessage: 'Read <link>{title}</link>',
        description: 'title for the activity daily goal card'
    },
    hasWatchedArticle: {
        defaultMessage: 'Watched <link>{title}</link>',
        description: 'title for the activity daily goal card'
    },
    backToChallengeLabelText: {
        defaultMessage: 'Back to company challenge',
        description: 'label for the back to challenge link'
    },
    article: {
        defaultMessage: 'Article',
        description: 'content type name'
    },
    video: {
        defaultMessage: 'Video',
        description: 'content type name'
    },
    recipe: {
        defaultMessage: 'Recipe',
        description: 'content type name'
    },
    youRead: {
        defaultMessage: 'You read',
        description:
            'After reading an article the title will change to you read with the article appearing below'
    },
    youWatched: {
        defaultMessage: 'You watched',
        description:
            'After watching a video the title will change to you watched with the video appearing below'
    },
    watch: {
        defaultMessage: '{durationInMinutes} minute watch',
        description: 'description of the duration in minutes the video runs for'
    },
    read: {
        defaultMessage: '{durationInMinutes} minute read',
        description:
            'description of the duration in minutes the article reads for'
    }
})

export type ArticleGoalProps = {
    challenge: UnifiedChallenge
    socialGroup: SocialGroup
    goal: ChallengeUserDailyGoal
    loading?: boolean
    disabled?: boolean
    onComplete?: () => void
}

const ArticleGoal: React.FC<ArticleGoalProps> = ({
    challenge,
    socialGroup,
    goal,
    loading,
    disabled,
    onComplete
}) => {
    const { formatMessage } = useIntl()
    const navigate = useCrossAppNavigation()

    const { article, completed, durationInMinutes, isVideo } = useMemo(() => {
        const articleDetail = goal?.goalEntityDetail as ArticleDetail
        const isVideo =
            articleDetail?.articleInfo.articleContentType?.toLowerCase() ===
            ArticleContentType.Video.toLowerCase()

        return {
            article: (goal?.goalEntityDetail as ArticleDetail)?.articleInfo,
            completed: goal?.completed,
            durationInMinutes: getDurationInMinutes(
                articleDetail?.article?.durationSeconds
            ),
            isVideo
        }
    }, [goal])

    const getCompletionTitle = useCallback(
        (defaultMessage: MessageDescriptor) => {
            let title = defaultMessage

            if (completed) {
                title = isVideo ? messages.youWatched : messages.youRead
            }

            return formatMessage(title)
        },
        [completed, isVideo, formatMessage]
    )

    const { title, contentType } = useMemo(() => {
        if (
            goal?.challengeGoal?.goalType === GoalType.RecipeRead ||
            goal?.challengeGoal?.goalType === GoalType.RecipeWatched
        ) {
            return {
                title: getCompletionTitle(messages.tryRecipe),
                contentType: formatMessage(messages.recipe)
            }
        } else if (
            goal?.challengeGoal?.goalType === GoalType.RoleModelRead ||
            goal?.challengeGoal?.goalType === GoalType.RoleModelWatched
        ) {
            return {
                title: getCompletionTitle(messages.getInspired),
                contentType: formatMessage(
                    isVideo ? messages.video : messages.article
                )
            }
        } else {
            return isVideo
                ? {
                      title: getCompletionTitle(messages.watchArticle),
                      contentType: formatMessage(messages.video)
                  }
                : {
                      title: getCompletionTitle(messages.readArticle),
                      contentType: formatMessage(messages.article)
                  }
        }
    }, [goal, isVideo, formatMessage, getCompletionTitle])

    const handleNavigate = useCallback(() => {
        const referralParams =
            challenge?.challenge_type === ChallengeType.company
                ? `?referral=company_challenge&company_challenge_id=${challenge?.id}`
                : `?referral=challenge&challenge_id=${challenge?.id}`

        let route = `/learn/articles/${article?.id}` + referralParams

        if (socialGroup?.id) {
            route += `&social_group_id=${socialGroup.id}`
        }

        Avo.contentStarted({
            ...defaultContentStartedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: 'pdf',
            contentId: article?.id,
            contentSubtype: 'article',
            contentTitle: article?.title,
            contentType: 'article',
            contentUrl: route,
            conversationId: socialGroup?.id,
            featureType: 'challenge',
            tabName: 'Home',
            teamId: socialGroup?.id
        })
        onComplete?.()
        navigate(route)
    }, [navigate, onComplete, article, challenge, socialGroup])

    return (
        <Stack gap={2} height="100%">
            <GoalTitle title={title} />
            <ContentCard
                elevation={1}
                loading={loading}
                disabled={disabled}
                onClick={handleNavigate}
                contentType={contentType}
                title={article?.title ?? undefined}
                image={article?.imageUrl ?? undefined}
                description={article?.summary ?? undefined}
                duration={formatMessage(
                    isVideo ? messages.watch : messages.read,
                    { durationInMinutes }
                )}
            />
        </Stack>
    )
}

export default ArticleGoal
