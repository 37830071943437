export enum SignUpSteps {
    introduction = 'introduction',
    participation_method = 'participation_method',
    displayName = 'display_name',
    createTeam = 'create_team',
    joinTeam = 'join_team',
    hydration = 'hydration',
    activity = 'activity',
    sleep = 'sleep',
    confirm = 'confirm',
    sign = 'sign'
}
