import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useGetWearablesSleepSummary from '../../../../hooks/useGetWearablesSleepSummary'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import useCurrentDateTime from '../../../../hooks/useCurrentDateTime/useCurrentDateTime'
import { Stack } from '@mui/material'
import { getChallengeDates } from '../../../../utils/challenge'
import type { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetHeader
} from '../RecapWidget'

type ConnectedSleepDevicesProps = {
    challenge: UnifiedChallenge
}

const ConnectedSleepDevices = ({ challenge }: ConnectedSleepDevicesProps) => {
    const { formatTime } = useIntl()
    const { startDate, endDate } = useMemo(
        () => getChallengeDates(challenge),
        [challenge]
    )

    const {
        averageBedTimeStart,
        loading: sleepLoading,
        error
    } = useGetWearablesSleepSummary(startDate, endDate)

    // Will be used to format the average bedtime to a time string
    const currentDateTime = useCurrentDateTime()
    const formattedTime = useMemo(() => {
        if (!averageBedTimeStart || !currentDateTime) return

        // Set time to the start of the day UTC
        currentDateTime.setUTCHours(0, 0, 0, 0)
        // Add the average bedtime to the current time
        currentDateTime.setSeconds(
            currentDateTime.getSeconds() + averageBedTimeStart
        )

        return formatTime(currentDateTime)
    }, [averageBedTimeStart, currentDateTime, formatTime])

    if (averageBedTimeStart === undefined || sleepLoading || error) {
        return ReactNullValue
    }

    return (
        <RecapWidget>
            <RecapWidgetHeader
                title={
                    <FormattedMessage
                        defaultMessage="Connected devices"
                        description="Connected devices title"
                    />
                }
            />
            <RecapWidgetContent>
                <Stack gap={1}>
                    <CoreTypography variant="body2" color="primary.main">
                        <FormattedMessage
                            defaultMessage="Your average bedtime."
                            description="avg bedtime subtitle"
                        />
                    </CoreTypography>
                    <CoreTypography variant="h2" color="text.secondary">
                        {formattedTime}
                    </CoreTypography>
                </Stack>
            </RecapWidgetContent>
        </RecapWidget>
    )
}

export default ConnectedSleepDevices
