import { Box } from '@mui/material'
import { LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ChallengeTimePoint,
    UnifiedChallenge,
    useSetSleepHabitsMutation
} from '../../../../../../graphql/generated/autogenerated'
import { FeelingButtonPromiseProps } from '../../../../features/Shared/DailyGoals/Goals/FeelingGoal/FeelingIconButton'
import FeelingsCard from '../../../../features/Shared/DailyGoals/Goals/FeelingGoal/FeelingsCard'

const messages = defineMessages({
    habitTitle: {
        defaultMessage:
            'Congratulations on completing the {challengeName}! How do you feel about your bedtime habits now?',
        description: 'sleep goal question'
    }
})

interface SleepHabitsChallengeEndDialogProps {
    open: boolean
    onClose: () => void
    challenge: UnifiedChallenge
}

const SleepHabitsChallengeEndDialog: React.FC<
    SleepHabitsChallengeEndDialogProps
> = ({ open, onClose, challenge }) => {
    const { formatMessage } = useIntl()
    const [setSleepHabitsMutation] = useSetSleepHabitsMutation()

    return (
        <LeafDialog
            open={open}
            onClose={() => onClose()}
            PaperProps={{
                sx: {
                    position: 'relative',
                    overflow: 'hidden'
                }
            }}
            dialogTitle={formatMessage(messages.habitTitle, {
                challengeName: challenge?.name
            })}
        >
            <Box width="100%" p={1}>
                <FeelingsCard
                    darkBackground={true}
                    promise={(props: FeelingButtonPromiseProps) => {
                        return setSleepHabitsMutation({
                            variables: {
                                challengeId: challenge?.id,
                                answer: props.answer,
                                challengeTimePoint: ChallengeTimePoint.End
                            }
                        }).then(onClose)
                    }}
                />
            </Box>
        </LeafDialog>
    )
}

export default memo(SleepHabitsChallengeEndDialog)
