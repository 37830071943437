import { Stack } from '@mui/material'
import { CoreTypography, LeafTextField } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useEffect } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'

const CONFIRM_ACTIVITY = 'ConfirmActivity'

const messages = defineMessages({
    activityGoal: {
        defaultMessage: 'Activity Goal',
        description: 'activity goal on what the user needs to achieve'
    }
})

const ConfirmActivity: React.FC = () => {
    const { formatMessage } = useIntl()
    const { activityGoal, setActivityGoal, addDisable } =
        useCompanyChallengeSignUpProviderContext()

    useEffect(() => {
        addDisable(CONFIRM_ACTIVITY, !activityGoal || activityGoal === 0)
    }, [activityGoal, addDisable])

    const onChangeActivityGoal = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = Math.max(0, Number(event.target.value))
            setActivityGoal(value)
        },
        [setActivityGoal]
    )

    return (
        <Stack gap={2}>
            <CoreTypography variant="h4">
                <FormattedMessage
                    defaultMessage="Set your daily activity goal for the challenge"
                    description="title for the confirm activity page"
                />
            </CoreTypography>
            <Stack gap={2} width="100%">
                <Stack width="100%" maxWidth={320}>
                    <LeafTextField
                        aria-label={formatMessage(messages.activityGoal)}
                        id="activity-goal-input"
                        variant="outlined"
                        type="number"
                        label={formatMessage(messages.activityGoal)}
                        value={String(activityGoal)}
                        onChange={onChangeActivityGoal}
                    />
                </Stack>

                <CoreTypography variant="body2">
                    <FormattedMessage
                        defaultMessage="Thrive suggests aiming for an activity goal between 8,000 and 10,000."
                        description="subtitle for the confirm hydration page"
                    />
                </CoreTypography>
                <CoreTypography variant="body2">
                    <FormattedMessage
                        defaultMessage="Imagine that earning an activity point is akin to taking a step forward. If you were to walk at a moderate pace for a minute, that effort would translate to 64 points"
                        description="subtitle for the confirm hydration page"
                    />
                </CoreTypography>
            </Stack>
        </Stack>
    )
}

export default memo(ConfirmActivity)
