import { Button, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CreateDisplayNameModal from '../../../../../shared/modules/social/components/CreateDisplayNameModal/CreateDisplayNameModal'

export interface ClaimYourNameProps {
    refetchCommunity: () => Promise<any>
    onOpenCreateDisplayName: () => void
    onCreateDisplayName: (displayName: string) => void
}

const ClaimYourName: React.FC<ClaimYourNameProps> = ({
    refetchCommunity,
    onOpenCreateDisplayName,
    onCreateDisplayName
}) => {
    const [openCreateDisplayName, setOpenCreateDisplayName] = useState(false)

    const handleOpenCreateDisplayName = useCallback(() => {
        setOpenCreateDisplayName(true)
        onOpenCreateDisplayName?.()
    }, [onOpenCreateDisplayName])

    const handleOnCreateDisplayName = useCallback(
        (displayName: string) => {
            onCreateDisplayName?.(displayName)
            refetchCommunity()
        },
        [onCreateDisplayName, refetchCommunity]
    )

    return (
        <>
            <CreateDisplayNameModal
                open={openCreateDisplayName}
                onCreateDisplayName={(displayName) =>
                    handleOnCreateDisplayName(displayName)
                }
                handleOnClose={() => setOpenCreateDisplayName(false)}
            />
            <Stack
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                gap={1}
                px={2}
            >
                <CoreTypography variant="h3" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Share your Journey"
                        description="claim your name title"
                    />
                </CoreTypography>
                <CoreTypography variant="body1" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Create a display name to like and share updates."
                        description="claim your name description"
                    />
                </CoreTypography>
                <Stack mt={1}>
                    <Button
                        data-testid="get-started-button"
                        variant="outlined"
                        onClick={handleOpenCreateDisplayName}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Get started"
                                description="button text that will open the create a display name popup"
                            />
                        </CoreTypography>
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default ClaimYourName
