import React, { memo, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useCompanyChallengeProviderContext } from '../../../../../contexts/withCompanyChallengeProvider'
import LeaderboardTable from '../../../../elements/LeaderboardTable'
import { GQLNullValue } from '../../../../../utils/nulls'
import {
    useGetTeamsPlantsLeaderboardByAvgQuery,
    useGetTeamsPlantsLeaderboardQuery
} from '../../../../../../graphql/generated/autogenerated'
import RequestorRank from '../../../../elements/RequestorRank'

type TeamPlantLeaderboardProps = {
    challengeId: string
    socialGroupId: string
    maxTeamSize?: number | null
    teamPlantsTotalPoints?: number
    teamPlantsRankDisplayName?: string
    teamPlantsRank?: number
}

const ROWS_PER_PAGE = 10

const messages = defineMessages({
    rank: {
        defaultMessage: 'Rank',
        description: 'Table header for rank'
    },
    team: {
        defaultMessage: 'Team Name',
        description: 'Table header for team'
    },
    totalPlants: {
        defaultMessage: 'Total',
        description: 'Table header for total plants'
    },
    avgPlants: {
        defaultMessage: 'Average per Person',
        description: 'Table header for average plants'
    },
    teamPlants: {
        defaultMessage: 'Team Plants',
        description: 'Leaderboard title for plants'
    }
})

const TeamPlantLeaderboard: React.FC<TeamPlantLeaderboardProps> = ({
    challengeId,
    maxTeamSize,
    socialGroupId,
    teamPlantsTotalPoints,
    teamPlantsRankDisplayName,
    teamPlantsRank
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const [offset, setOffset] = useState(0)
    const { socialGroup } = useCompanyChallengeProviderContext()

    const showAvgLeaderboard = useMemo(
        () => maxTeamSize === GQLNullValue || maxTeamSize === undefined,
        [maxTeamSize]
    )

    const {
        data: avgData,
        loading: avgLoading,
        refetch: refetchAvg
    } = useGetTeamsPlantsLeaderboardByAvgQuery({
        variables: {
            challengeId,
            offset,
            limit: ROWS_PER_PAGE,
            socialGroupId
        },
        skip: !showAvgLeaderboard
    })

    const {
        data: data,
        loading: dataLoading,
        refetch: refetchData
    } = useGetTeamsPlantsLeaderboardQuery({
        variables: {
            challengeId,
            offset,
            limit: ROWS_PER_PAGE,
            socialGroupId
        },
        skip: showAvgLeaderboard
    })

    const scores = useMemo(() => {
        if (showAvgLeaderboard) {
            return avgData?.retail.getTeamsPlantsLeaderboardByAverage.rankedList
        }
        return data?.retail.getTeamsPlantsLeaderboard.rankedList
    }, [showAvgLeaderboard, avgData, data])

    const totalEntries = useMemo(() => {
        if (showAvgLeaderboard) {
            return avgData?.retail.getTeamsPlantsLeaderboardByAverage
                .totalEntries
        }
        return data?.retail.getTeamsPlantsLeaderboard.totalEntries
    }, [showAvgLeaderboard, avgData, data])

    const requesterRank = useMemo(() => {
        if (showAvgLeaderboard) {
            return avgData?.retail.getTeamsPlantsLeaderboardByAverage
                .requesterRank
        }
        return data?.retail.getTeamsPlantsLeaderboard.requesterRank
    }, [showAvgLeaderboard, avgData, data])

    const anchorIndex = useMemo(
        () =>
            scores?.findIndex(
                (score) =>
                    score?.rank === requesterRank &&
                    score?.entityId === socialGroup?.id
            ),
        [requesterRank, scores, socialGroup?.id]
    )

    const maximumOffsetLoaded = useMemo(() => {
        return offset + ROWS_PER_PAGE
    }, [offset])

    const hasMore = useMemo(() => {
        return (totalEntries || 0) > maximumOffsetLoaded
    }, [totalEntries, maximumOffsetLoaded])

    useEffect(() => {
        if (showAvgLeaderboard) {
            refetchAvg({ offset })
        } else {
            refetchData({ offset })
        }
    }, [offset, refetchAvg, refetchData, showAvgLeaderboard])

    const showLoading = showAvgLeaderboard ? avgLoading : dataLoading
    return (
        <LeaderboardTable
            loading={showLoading}
            loadingNext={showLoading}
            userRank={
                <RequestorRank
                    columnDataTitle={formatMessage(messages.team)}
                    columnDataPointsTitle={
                        showAvgLeaderboard
                            ? formatMessage(messages.avgPlants)
                            : formatMessage(messages.totalPlants)
                    }
                    displayName={teamPlantsRankDisplayName}
                    rank={teamPlantsRank}
                    points={teamPlantsTotalPoints}
                />
            }
            title={formatMessage(messages.teamPlants)}
            columnData={[
                {
                    title: formatMessage(messages.rank),
                    width: '20%',
                    valueName: 'rank',
                    showAnchor: (value, entityId) =>
                        value === requesterRank && entityId === socialGroup?.id
                },
                {
                    title: formatMessage(messages.team),
                    valueName: 'displayName'
                },
                {
                    title: showAvgLeaderboard
                        ? formatMessage(messages.avgPlants)
                        : formatMessage(messages.totalPlants),
                    valueName: 'totalPlants',
                    transform: (value) => formatNumber(value)
                }
            ]}
            values={scores ?? []}
            requesterRank={requesterRank}
            totalEntries={totalEntries}
            anchorOnIndex={anchorIndex}
            rowsPerPage={ROWS_PER_PAGE}
            elevation={0}
            onNavigate={(value) => {
                setOffset(value ? offset + 1 : offset - 1)
            }}
            disableNext={!hasMore}
            disablePrev={offset === 0}
        />
    )
}

export default memo(TeamPlantLeaderboard)
