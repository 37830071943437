import { Icon, Radio, Stack } from '@mui/material'
import {
    LeafBackgroundIcon,
    CoreTypography,
    IconButton,
    LeafFlexMediaCard,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { SocialGroup } from '../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../routes'

export type SocialGroupItemProps = {
    socialGroup: SocialGroup
    userId?: string | null
    isJoinList?: boolean
    addToJoinList: (join: boolean) => void
    hasJoinedGroup: boolean
    hasAddedGroup?: boolean
    disable: boolean
    disableNavigate?: boolean
    maxCapacity?: number
}

const messages = defineMessages({
    memberCount: {
        defaultMessage: `{memberCount, plural, one {# member} other {# members}}`,
        description: 'text showing the total number of members in a group'
    },
    createByYou: {
        defaultMessage: 'Created by You',
        description: 'text describing that the group was created by the user'
    },
    joinedGroup: {
        defaultMessage: 'joined {groupName} group',
        description:
            'aria label description for a button that is disabled when a user has already joined the social group'
    },
    joinAGroup: {
        defaultMessage: 'add {groupName} to join group list',
        description:
            'aria label text on a button that will add a social group to a list of social groups the user can then join through a separate action'
    }
})

const radioStyles = {
    '& svg': {
        width: '1.5em',
        height: '1.5em'
    }
}

const SocialGroupItem: React.FC<SocialGroupItemProps> = ({
    socialGroup,
    userId,
    isJoinList,
    addToJoinList,
    hasJoinedGroup,
    hasAddedGroup,
    disable,
    disableNavigate,
    maxCapacity
}) => {
    const intl = useIntl()
    const history = useHistory()
    const [selected, setSelected] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [newlyJoined, setNewlyJoined] = useState<boolean>(false)

    const { captionText, atCapacity } = useMemo(() => {
        let captionText = ''
        const memberCount = socialGroup.memberCount
        if (String(userId) === socialGroup.createdBy) {
            captionText = `${intl.formatMessage(messages.createByYou)}  ·  `
        }
        captionText += intl.formatMessage(messages.memberCount, { memberCount })
        return {
            captionText,
            atCapacity: !!maxCapacity && (memberCount as number) >= maxCapacity
        }
    }, [
        intl,
        socialGroup.createdBy,
        socialGroup.memberCount,
        userId,
        maxCapacity
    ])

    const onJoinGroup = useCallback(() => {
        setSelected(!selected)
        addToJoinList(!selected)
    }, [selected, addToJoinList])

    // on successfully joining a group we want to keep any newly selected groups disabled
    useEffect(() => {
        if (disable) {
            setDisabled(true)

            if (disable && selected) {
                setNewlyJoined(true)
            }
        } else {
            setDisabled(selected && newlyJoined)
        }
    }, [disable, selected, newlyJoined])

    const onNavigateToSocialGroup = useCallback(
        () =>
            history.push(
                generatePath(ROUTES.SINGLE_SOCIAL_GROUP, {
                    socialGroupId: socialGroup.id
                }),
                {
                    previousLocation: ROUTES.SOCIAL_GROUPS
                }
            ),
        [history, socialGroup]
    )

    const { canNavigateToSocialGroup, canListJoinGroup } = useMemo(() => {
        return {
            canNavigateToSocialGroup:
                !disableNavigate &&
                !isJoinList &&
                (hasJoinedGroup || hasAddedGroup),
            canListJoinGroup: isJoinList && !atCapacity
        }
    }, [atCapacity, disableNavigate, hasAddedGroup, hasJoinedGroup, isJoinList])

    return (
        <LeafFlexMediaCard
            elevation={8}
            {...(canNavigateToSocialGroup && {
                actionAreaProps: { onClick: onNavigateToSocialGroup }
            })}
            {...(canListJoinGroup && {
                actionAreaProps: { onClick: onJoinGroup }
            })}
            sx={{ height: '100%' }}
        >
            <Stack
                p={2}
                gap={1.5}
                direction="row"
                alignItems="center"
                justifyContent={isJoinList ? 'flex-start' : 'space-between'}
                height="100%"
            >
                {isJoinList && (
                    <Radio
                        sx={radioStyles}
                        disabled={atCapacity}
                        checked={hasJoinedGroup || hasAddedGroup}
                    />
                )}
                <Stack
                    gap={2}
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Stack>
                        <LeafBackgroundIcon
                            color={'accent'}
                            icon={<LeafIcon icon={'user-group'} />}
                        />
                    </Stack>
                    <Stack flex={1} gap={0.5}>
                        <CoreTypography
                            variant="h4"
                            color={
                                atCapacity ? 'text.disabled' : 'primary.main'
                            }
                        >
                            {socialGroup.name}
                        </CoreTypography>
                        <CoreTypography
                            variant="caption"
                            color={
                                atCapacity ? 'text.disabled' : 'text.secondary'
                            }
                        >
                            {atCapacity ? (
                                <FormattedMessage
                                    defaultMessage="Team capacity reached"
                                    description="text describing that the group is at capacity"
                                />
                            ) : (
                                captionText
                            )}
                        </CoreTypography>
                    </Stack>
                    {!isJoinList && (
                        <Stack>
                            <IconButton
                                variant={
                                    selected || hasJoinedGroup || hasAddedGroup
                                        ? 'contained'
                                        : 'outlined'
                                }
                                disabled={
                                    hasJoinedGroup || disabled || atCapacity
                                }
                                onClick={onJoinGroup}
                                aria-label={
                                    selected || hasJoinedGroup
                                        ? intl.formatMessage(
                                              messages.joinedGroup,
                                              {
                                                  groupName: socialGroup.name
                                              }
                                          )
                                        : intl.formatMessage(
                                              messages.joinAGroup,
                                              {
                                                  groupName: socialGroup.name
                                              }
                                          )
                                }
                                data-testid={
                                    selected || hasJoinedGroup
                                        ? 'joined-button'
                                        : 'join-button'
                                }
                            >
                                <LeafIcon
                                    icon={
                                        selected || hasJoinedGroup
                                            ? 'check'
                                            : 'plus'
                                    }
                                />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </LeafFlexMediaCard>
    )
}

export default SocialGroupItem
