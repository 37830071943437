import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant3: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#365D67',
    colorLayer2 = '#E93323',
    colorLayer3 = '#21513A',
    colorLayer4 = '#52A185',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M45.9103 49.7037L45.3103 48.9037C44.9503 49.1437 43.2703 51.8037 41.6903 54.3737L41.3303 1.01367H40.3303L39.7603 86.0137H41.9003L41.7303 60.6837C41.7103 57.7437 42.5403 54.8537 44.1103 52.3737C45.0203 50.9237 45.7203 49.8737 45.9003 49.7037H45.9103Z"
                fill={colorLayer1}
            />
            <path
                d="M52.9703 17.0537C52.5003 17.0537 52.0203 17.0137 51.5403 16.9337L51.7103 15.9537C54.9803 16.5337 58.5103 14.5237 58.5403 14.5037L59.0403 15.3737C58.9003 15.4537 56.0803 17.0637 52.9603 17.0637L52.9703 17.0537Z"
                fill={colorLayer1}
            />
            <path
                d="M49.0803 22.7037L48.1303 22.4037C51.5003 11.7237 60.5303 7.74367 60.9103 7.57367L61.3003 8.49367C61.2103 8.53367 52.3103 12.4737 49.0803 22.7037Z"
                fill={colorLayer1}
            />
            <path
                d="M61.1103 9.61367C61.9829 9.61367 62.6903 8.90628 62.6903 8.03367C62.6903 7.16106 61.9829 6.45367 61.1103 6.45367C60.2377 6.45367 59.5303 7.16106 59.5303 8.03367C59.5303 8.90628 60.2377 9.61367 61.1103 9.61367Z"
                fill={colorLayer2}
            />
            <path
                d="M58.8003 16.5137C59.6729 16.5137 60.3803 15.8063 60.3803 14.9337C60.3803 14.0611 59.6729 13.3537 58.8003 13.3537C57.9277 13.3537 57.2203 14.0611 57.2203 14.9337C57.2203 15.8063 57.9277 16.5137 58.8003 16.5137Z"
                fill={colorLayer2}
            />
            <path
                d="M36.5903 43.8237C36.5903 43.8237 37.2403 29.4937 29.1403 23.1437C21.0403 16.7837 7.25035 20.8137 7.25035 20.8137L36.6003 43.8337L36.5903 43.8237Z"
                fill={colorLayer3}
            />
            <path
                d="M40.8803 0.0136719C40.8803 0.0136719 29.1803 9.32367 29.1603 20.8237C29.1403 32.3237 40.7903 41.6537 40.7903 41.6537C40.7903 41.6537 52.4903 32.3437 52.5103 20.8437C52.5303 9.34367 40.8803 0.0136719 40.8803 0.0136719Z"
                fill={colorLayer4}
            />
            <path
                d="M21.8903 43.0837C27.8403 47.5037 27.6504 57.8437 27.6504 57.8437C27.6504 57.8437 17.8203 61.0037 11.8803 56.5837C5.93034 52.1637 6.12036 41.8237 6.12036 41.8237C6.12036 41.8237 15.9503 38.6637 21.8903 43.0837Z"
                fill={colorLayer4}
            />
            <path
                d="M64.4803 21.7337C64.4803 21.7337 58.3103 21.8337 55.4503 26.2037C52.5903 30.5737 54.1403 37.5637 54.1403 37.5637C54.1403 37.5637 60.3103 37.4637 63.1703 33.0937C66.0303 28.7237 64.4803 21.7337 64.4803 21.7337Z"
                fill={colorLayer4}
            />
            <path
                d="M73.6103 31.4237C73.6103 31.4237 60.3204 26.6837 52.0303 32.4437C43.7403 38.2037 43.6003 52.3037 43.6003 52.3037C43.6003 52.3037 49.4103 47.3137 57.7003 41.5537C65.9903 35.7937 73.6103 31.4337 73.6103 31.4337V31.4237Z"
                fill={colorLayer3}
            />
            <path
                d="M41.3303 64.1937C39.6703 51.3237 29.8803 44.7437 29.7903 44.6837L29.2403 45.5137C29.3103 45.5637 34.5903 49.1237 37.9203 56.1137C34.3103 55.7837 31.0903 53.1037 31.0603 53.0737L30.4203 53.8337C30.5603 53.9537 33.3703 56.2937 36.8503 56.9537C37.8603 57.1437 38.6903 57.8637 39.0303 58.8337C39.6203 60.5037 40.0803 62.3237 40.3403 64.3137L41.3303 64.1837V64.1937Z"
                fill={colorLayer1}
            />
            <path
                d="M29.5103 46.9337C30.521 46.9337 31.3403 46.1143 31.3403 45.1037C31.3403 44.093 30.521 43.2737 29.5103 43.2737C28.4996 43.2737 27.6803 44.093 27.6803 45.1037C27.6803 46.1143 28.4996 46.9337 29.5103 46.9337Z"
                fill={colorLayer2}
            />
            <path
                d="M30.7303 55.2937C31.741 55.2937 32.5603 54.4743 32.5603 53.4637C32.5603 52.453 31.741 51.6337 30.7303 51.6337C29.7197 51.6337 28.9003 52.453 28.9003 53.4637C28.9003 54.4743 29.7197 55.2937 30.7303 55.2937Z"
                fill={colorLayer2}
            />
            <path
                d="M41.3303 76.5837H40.3303C40.3303 64.8037 6.48034 42.6837 6.14034 42.4637L6.68034 41.6237C8.09034 42.5437 41.3203 64.2637 41.3203 76.5837H41.3303Z"
                fill={colorLayer1}
            />
            <path
                d="M50.8103 64.1737C50.8103 64.1737 44.7403 52.4437 52.9603 46.3137C60.5703 40.6437 71.8603 41.5537 80.8203 45.1637C68.9303 47.6737 57.9003 54.1937 50.8103 64.1737Z"
                fill={colorLayer4}
            />
            <path
                d="M48.7803 68.5537L47.9003 68.0837C55.4103 54.0937 67.6403 48.9837 80.5603 44.5837L80.8803 45.5337C68.1503 49.8637 56.1203 54.8937 48.7803 68.5637V68.5537Z"
                fill={colorLayer1}
            />
            <path
                d="M47.9232 68.0558L40.3827 79.6493L41.221 80.1945L48.7614 68.601L47.9232 68.0558Z"
                fill={colorLayer1}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant3
