import { Grid, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useGoalChallengeDataProviderContext } from '../../../../contexts/withGoalChallenges/useGoalChallengeData/useGoalChallengeDataProviderContext'
import ContentCard from '../../../../../shared/components/elements/ContentCard/ContentCard'
import { getDurationInMinutes } from '../../../../../utils'
import ViewMoreDialog from '../ViewMoreDialog'
import type { ArticleStats } from '../../../../../graphql/generated/autogenerated'
export type CompletedArticlesDialogProps = {
    open: boolean
    articleStats: ArticleStats
    onClose: () => void
}

const messages = defineMessages({
    article: {
        defaultMessage: 'Article',
        description: 'content type description'
    },
    watch: {
        defaultMessage: '{durationInMinutes} minute watch',
        description: 'description of the duration in minutes the video runs for'
    }
})

const CompletedArticlesDialog: React.FC<CompletedArticlesDialogProps> = ({
    articleStats,
    open,
    onClose
}) => {
    const { formatMessage } = useIntl()

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={5} pb={6}>
                <Stack gap={2}>
                    <CoreTypography variant="h1" color="primary.main">
                        <FormattedMessage
                            defaultMessage="Articles"
                            description="article usage description"
                        />
                    </CoreTypography>
                    <CoreTypography variant="h3" color="text.primary">
                        <FormattedMessage
                            defaultMessage="These tips help you continue to build on your progress."
                            description="tips title"
                        />
                    </CoreTypography>
                </Stack>
                <Grid container>
                    {articleStats?.articles
                        ?.filter((articleStat) => articleStat?.completed)
                        ?.map((articleStat, index) => {
                            const article =
                                articleStat?.articleDetail?.articleInfo

                            return (
                                <Grid item key={index} xs={12} md={4}>
                                    <ContentCard
                                        image={article?.imageUrl ?? ''}
                                        title={article?.title ?? ''}
                                        contentType={formatMessage(
                                            messages.article
                                        )}
                                        duration={formatMessage(
                                            messages.watch,
                                            {
                                                durationInMinutes:
                                                    getDurationInMinutes(
                                                        articleStat
                                                            ?.articleDetail
                                                            ?.article
                                                            ?.durationSeconds
                                                    )
                                            }
                                        )}
                                        alignMedia="top"
                                        description={article?.summary ?? ''}
                                        elevation={1}
                                    />
                                </Grid>
                            )
                        })}
                </Grid>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(CompletedArticlesDialog)
