import { Box, Card, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { useFeelingIcon } from '../../../../../../hooks/useFeelingIcon/useFeelingIcon'
import FeelingIconButton, {
    FeelingButtonPromiseProps
} from './FeelingIconButton'

export type FeelingsCardProps = {
    feelingAnswer?: number
    darkBackground?: boolean
    disabled?: boolean
    promise?: (options: FeelingButtonPromiseProps) => Promise<unknown>
}

const FeelingsCard: React.FC<FeelingsCardProps> = ({
    feelingAnswer,
    darkBackground,
    disabled,
    promise
}) => {
    const { palette } = useTheme()
    const getFeelingIcon = useFeelingIcon()

    return (
        <Card elevation={12}>
            <Stack
                justifyContent="space-between"
                direction="row"
                flexWrap="wrap"
                width="100%"
                gap={1.6875}
                p={2}
                sx={{
                    ...(!!darkBackground && { background: palette.grey[100] })
                }}
            >
                {Array.from(Array(5)).map((_, index: number) => {
                    const answer = index + 1
                    const { icon, label } = getFeelingIcon(answer)

                    return (
                        <FeelingIconButton
                            key={index}
                            answer={answer}
                            icon={icon}
                            label={label}
                            completed={feelingAnswer === answer}
                            disabled={disabled}
                            promise={promise}
                        />
                    )
                })}
            </Stack>
        </Card>
    )
}

export default memo(FeelingsCard)
