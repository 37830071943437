import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { memo } from 'react'

const CompleteDailyGoals: React.FC<SvgIconProps> = ({ ...props }) => {
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 160 161'}
            sx={{ width: 160, height: 161, fill: 'transparent' }}
            {...props}
        >
            <g filter="url(#filter0_d_951_164182)">
                <circle cx="69.6115" cy="65.7219" r="49.3889" fill="white" />
                <circle
                    cx="69.6115"
                    cy="65.7219"
                    r="48.8889"
                    stroke="#253858"
                    stroke-opacity="0.16"
                />
            </g>
            <path
                d="M66.1117 58.3063C66.1117 58.3063 62.145 65.0989 56.3894 64.1396C44.7228 62.1952 46.6672 46.6396 42.7783 40.8063C46.5452 39.2664 50.623 38.6398 54.6784 38.9778C58.7338 39.3157 62.6516 40.6086 66.1117 42.7507C71.9372 46.6396 71.9372 52.4729 66.1117 58.3063Z"
                stroke="#87CFD4"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M78.8722 64.0051C78.8722 64.0051 81.6281 68.7184 85.6259 68.0573C93.7277 66.7066 92.3666 55.9032 95.0785 51.851C92.462 50.7824 89.6299 50.3477 86.8133 50.5824C83.9968 50.8172 81.2757 51.7147 78.8722 53.2017C74.8226 55.9032 74.8226 59.9554 78.8722 64.0051Z"
                stroke="#87CFD4"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M58.334 50.5283C58.334 50.5283 70.0007 56.3617 71.9451 75.8061C75.834 64.1394 81.6673 60.2505 81.6673 60.2505"
                stroke="#87CFD4"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M80.0732 95.2511H63.8177C63.3694 95.2512 62.9349 95.0964 62.5877 94.8129C62.2404 94.5295 62.0018 94.1347 61.9121 93.6955L58.801 78.14C58.7433 77.8574 58.7492 77.5656 58.8183 77.2856C58.8873 77.0056 59.0177 76.7445 59.2001 76.5212C59.3825 76.2978 59.6123 76.1178 59.8729 75.9942C60.1334 75.8707 60.4182 75.8066 60.7066 75.8066H83.1843C83.4729 75.8062 83.758 75.87 84.0189 75.9934C84.2797 76.1168 84.5099 76.2968 84.6925 76.5202C84.8752 76.7435 85.0059 77.0048 85.0751 77.285C85.1443 77.5652 85.1502 77.8572 85.0925 78.14L81.9814 93.6955C81.8912 94.1349 81.652 94.5297 81.3044 94.8132C80.9567 95.0966 80.5218 95.2513 80.0732 95.2511Z"
                stroke="#87CFD4"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="110.445" cy="24.1104" r="21" fill="#87CFD4" />
            <path
                d="M121.383 24.8398V18.2773H114.82"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M121.383 18.2773L113.664 25.9958C113.529 26.1313 113.368 26.2388 113.191 26.3122C113.014 26.3855 112.824 26.4233 112.633 26.4233C112.441 26.4233 112.252 26.3855 112.075 26.3122C111.898 26.2388 111.737 26.1313 111.601 25.9958L108.56 22.9547C108.425 22.8192 108.264 22.7117 108.087 22.6383C107.91 22.565 107.72 22.5272 107.529 22.5272C107.337 22.5272 107.147 22.565 106.97 22.6383C106.793 22.7117 106.633 22.8192 106.497 22.9547L99.5078 29.944"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_951_164182"
                    x="1.22266"
                    y="1.33301"
                    width="158.777"
                    height="158.777"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="11" dy="15" />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.529412 0 0 0 0 0.811765 0 0 0 0 0.831373 0 0 0 0.31 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_951_164182"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_951_164182"
                        result="shape"
                    />
                </filter>
            </defs>
        </SvgIcon>
    )
}

export default memo(CompleteDailyGoals)
