import { SvgIcon, SvgIconProps } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

const WaterGlass: React.FC<SvgIconProps> = ({ ...props }) => {
    const { palette } = useTheme()
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 26 38'}
            sx={{ width: 26, height: 38 }}
            {...props}
        >
            <path
                opacity="0.2"
                d="M23.3496 14.5H3.09961L5.34961 36.1H20.6496L23.3496 14.5Z"
                fill={palette.success.main}
            />
            <path
                d="M21.3773 35.5645C21.3612 35.752 21.2751 35.9266 21.1361 36.0536C20.9972 36.1806 20.8156 36.2507 20.6273 36.25H5.36935C5.18112 36.2507 4.99952 36.1806 4.86058 36.0536C4.72164 35.9266 4.63554 35.752 4.61935 35.5645L1.74985 2.56451C1.74089 2.46073 1.75365 2.35622 1.78731 2.25765C1.82096 2.15907 1.87479 2.06859 1.94534 1.99197C2.0159 1.91534 2.10165 1.85425 2.19712 1.81259C2.29259 1.77093 2.39569 1.74962 2.49985 1.75001H23.4998C23.604 1.74962 23.7071 1.77093 23.8026 1.81259C23.898 1.85425 23.9838 1.91534 24.0544 1.99197C24.1249 2.06859 24.1787 2.15907 24.2124 2.25765C24.2461 2.35622 24.2588 2.46073 24.2498 2.56451L21.3773 35.5645Z"
                stroke={palette.text.disabled}
                stroke-width="2"
                strokeLinecap="round"
                fill="transparent"
                strokeLinejoin="round"
            />
            <path
                d="M2.72461 13.75H23.2746"
                stroke={palette.text.disabled}
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    )
}

export default memo(WaterGlass)
