import { useMemo, useState } from 'react'
import { stripBOM } from '../../../shared/utils/bom'
import {
    SocialGroup,
    useCompanyChallengeSocialGroupsQuery
} from '../../../graphql/generated/autogenerated'
import { useParams } from 'react-router-dom'

type UseSocialGroups = {
    challengeId: string
}

const useSocialGroups = ({ challengeId }: UseSocialGroups): any => {
    const { socialGroupId } = useParams<{
        socialGroupId: string
    }>()
    const { loading, data, error, refetch } =
        useCompanyChallengeSocialGroupsQuery({
            variables: {
                challengeId: stripBOM(challengeId)
            },
            skip: !socialGroupId
        })

    const { soloGroup, mainGroup, socialGroup, companyGroups, displayName } =
        useMemo(
            () => ({
                soloGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.isSoloChallenge
                    ) as SocialGroup,
                mainGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.isMainCommunitySocialGroup
                    ) as SocialGroup,
                socialGroup:
                    data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.id === socialGroupId
                    ) ||
                    (data?.socialGroups?.companyChallengeSocialGroups?.find(
                        (socialGroup) => socialGroup.isMainCommunitySocialGroup
                    ) as SocialGroup),
                companyGroups:
                    data?.socialGroups?.companyChallengeSocialGroups?.filter(
                        (socialGroup) =>
                            !socialGroup.isSoloChallenge &&
                            !socialGroup.isMainCommunitySocialGroup
                    ) as SocialGroup[],
                displayName: data?.socialGroups?.displayName?.displayName
            }),
            [data, socialGroupId, challengeId]
        )

    return {
        loading,
        soloGroup,
        mainGroup,
        socialGroup,
        companyGroups,
        displayName,
        error,
        refetch
    }
}

export default useSocialGroups
