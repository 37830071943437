import { CircularProgress, Stack } from '@mui/material'
import {
    ResetIframePlayerWithFallback,
    ResetPlayerPopup
} from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense } from 'react'
import type { ResetStatsDetail } from '../../../../../graphql/generated/autogenerated'

type CompletedResetsPlayerProps = {
    reset?: ResetStatsDetail
    isOpen: boolean
    onClose: () => void
}

const CompletedResetsPlayer = ({
    reset,
    isOpen,
    onClose
}: CompletedResetsPlayerProps) => {
    return (
        <ResetPlayerPopup isOpen={isOpen} onClose={onClose}>
            <Stack height="100%" width="100%">
                <Suspense fallback={<CircularProgress />}>
                    {reset?.resetDetail?.resetInfo?.landscape?.iframe && (
                        <ResetIframePlayerWithFallback
                            src={reset.resetDetail.resetInfo.landscape?.iframe}
                            muted={false}
                            autoplay
                            controls
                        />
                    )}
                </Suspense>
            </Stack>
        </ResetPlayerPopup>
    )
}

export default React.memo(CompletedResetsPlayer)
