import { Card, Stack } from '@mui/material'
import React, { memo } from 'react'
import ViewMoreDialog from '../ViewMoreDialog'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'
import ChallengeGarden from '../../Shared/ChallengeGarden'
import type { UserPlantInfo } from '../../../../../graphql/generated/autogenerated'

export type TotalPlantsEarnedDialogProps = {
    open: boolean
    onClose: () => void
    data: UserPlantInfo[]
}

const TotalPlantsEarnedDialog = ({
    open,
    onClose,
    data
}: TotalPlantsEarnedDialogProps) => {
    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={3} pb={6}>
                <Card elevation={0} sx={{ height: '100%' }}>
                    <Stack p={2} gap={2} height="100%">
                        <CoreTypography
                            variant="overline"
                            color="text.disabled"
                        >
                            <FormattedMessage
                                defaultMessage="Your Garden"
                                description="description for the challenge garden card"
                            />
                        </CoreTypography>
                        <ChallengeGarden
                            columnSize={7}
                            rowSize={2}
                            plants={data}
                            challengeStarted={true}
                        />
                    </Stack>
                </Card>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(TotalPlantsEarnedDialog)
