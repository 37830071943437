import { Button } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import ActionModal from '../ActionModal'
import { defineMessages, useIntl } from 'react-intl'

export type ErrorModalProps = {
    isOpen: boolean
    handleOnClose: () => void
}

const messages = defineMessages({
    somethingWrong: {
        defaultMessage: 'Something went wrong!',
        description: 'error popup title'
    },
    pleaseTryAgain: {
        defaultMessage: 'Please try again.',
        description: 'error popup description'
    },
    tryAgain: {
        defaultMessage: 'Try again',
        description:
            'Button text that will close the error modal and try the failed action again'
    }
})

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, handleOnClose }) => {
    const theme = useTheme()
    const intl = useIntl()

    return (
        <ActionModal
            isOpen={isOpen}
            handleOnClose={handleOnClose}
            icon={
                <LeafIcon
                    icon={'triangle-exclamation'}
                    fontSize={'large'}
                    color={'primary'}
                />
            }
            title={intl.formatMessage(messages.somethingWrong)}
            body={intl.formatMessage(messages.pleaseTryAgain)}
            size="small"
            actions={
                <Button
                    variant="contained"
                    sx={{
                        alignItems: 'center',
                        p: `${theme.spacing(2)} ${theme.spacing(10)}`,
                        [theme.breakpoints.down('sm')]: {
                            p: `${theme.spacing(1)} ${theme.spacing(2)}`
                        }
                    }}
                    data-testid="close-error-modal-button"
                    onClick={handleOnClose}
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        sx={{ textAlign: 'center' }}
                    >
                        {intl.formatMessage(messages.tryAgain)}
                    </CoreTypography>
                </Button>
            }
            data-testid="error-modal"
        />
    )
}

export default ErrorModal
