import { isToday } from '@thriveglobal/thrive-web-core'
import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactNullValue } from '../../../../../../shared/utils/Nulls'
import PlantsEarnedCard from '../PlantsEarnedCard'
import { UserPlantInfo } from '../../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    todayCount: {
        defaultMessage: "today's plants earned",
        description: "description for today's plant count"
    }
})

export type TeamTodayPlantsEarnedProps = {
    size?: 'small' | 'large'
    error: boolean
    teamPlants: UserPlantInfo[]
}

const TeamTodayPlantsEarned: React.FC<TeamTodayPlantsEarnedProps> = ({
    size,
    error,
    teamPlants
}) => {
    const { formatMessage } = useIntl()
    const totalPlants = useMemo(
        () => teamPlants?.filter((plant) => isToday(plant.createdAt)).length,
        [teamPlants]
    )

    if (error) return ReactNullValue

    return (
        <PlantsEarnedCard
            title={formatMessage(messages.todayCount)}
            stat={totalPlants}
            size={size}
        />
    )
}

export default memo(TeamTodayPlantsEarned)
