import { useTheme } from '@thriveglobal/thrive-web-leafkit'

export const useCardStyled = (dashed: boolean, loading: boolean) => {
    const { palette } = useTheme()

    return {
        'data-testid': 'challenge-plant-card',
        elevation: dashed ? 0 : 6,
        sx: {
            ...(loading && {
                animation: 'animation-c7515d 1.5s ease-in-out 0.5s infinite',
                background: loading ? 'rgba(23, 34, 53, 0.11)' : 'transparent'
            }),
            position: 'relative',
            aspectRatio: '1 / 1',
            border: `1px solid ${palette.grey[200]}`,
            ...(dashed && {
                border: `1px dashed ${palette.grey[200]}`
            }),
            minHeight: 37
        }
    }
}

export default useCardStyled
