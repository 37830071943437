import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    GroupChallengeType,
    type SocialGroup,
    type UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import ChangeParticipation from './ChangeParticipation'
import EditDisplayName from './EditDisplayName'
import EditTeamName from './EditTeamName'
import type { SocialGroupsValues } from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'

export interface GoalChallengeSettingsProps {
    open: boolean
    settings: React.ReactNode
    action: React.ReactNode
    challenge?: UnifiedChallenge
    socialGroups?: SocialGroupsValues
    onClose: () => void
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const GoalChallengeSettings: React.FC<GoalChallengeSettingsProps> = ({
    open,
    settings,
    action,
    challenge,
    socialGroups,
    onClose,
    refetchUnifiedChallengeAvailability
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const canShowChangeParticipation = useMemo(
        () =>
            challenge?.groupChallengeType === GroupChallengeType.Personal ||
            (challenge?.groupChallengeType === GroupChallengeType.Group &&
                challenge.totalParticipants === 1),
        [challenge?.groupChallengeType, challenge?.totalParticipants]
    )

    const { socialGroup, displayName } = socialGroups ?? {}

    const refetchSocialGroups = useCallback(async () => {
        await socialGroups?.socialGroupsRefetch()
    }, [socialGroups])

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                sx: {
                    maxWidth: '672px',
                    width: '100%'
                }
            }}
            fullScreen={isMobile}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="Challenge Settings"
                        description="Challenge Settings Dialog Title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <Stack gap={3} data-testid="goal-challenge-settings-dialog">
                    <EditDisplayName
                        displayName={displayName}
                        refetchSocialGroups={refetchSocialGroups}
                    />
                    {!socialGroup?.isSoloChallenge && (
                        <EditTeamName
                            socialGroup={socialGroup as SocialGroup}
                            refetchSocialGroups={refetchSocialGroups}
                        />
                    )}
                    {settings}
                    {canShowChangeParticipation && (
                        <ChangeParticipation
                            challenge={challenge}
                            onClose={onClose}
                            refetchUnifiedChallengeAvailability={
                                refetchUnifiedChallengeAvailability
                            }
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap={1}
                    width="100%"
                >
                    <Box>{action}</Box>
                    <Stack direction="row" gap={1}>
                        <Button variant="contained" onClick={onClose}>
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Close"
                                    description="close button text"
                                />
                            </CoreTypography>
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(GoalChallengeSettings)
