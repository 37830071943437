import { useMemo } from 'react'
import { ApolloError } from '@apollo/client/errors'

import { ChallengeThemes } from '../../../enums/challengeThemes'
import {
    useGetUnifiedChallengeRecapQuery,
    useGetChallengePlantsQuery,
    type SocialGroup,
    type GetUnifiedChallengeRecapQuery,
    type GetChallengePlantsQuery
} from '../../../graphql/generated/autogenerated'
import useGetSocialGroups from '../useGetSocialGroups/useGetSocialGroups'

export type ChallengeRecap = {
    challenge?: GetUnifiedChallengeRecapQuery
    socialGroups?: {
        mainCommunitySocialGroup?: SocialGroup | null
        socialGroup?: SocialGroup | null
    } | null
    plants?: GetChallengePlantsQuery
    loading: boolean
    challengeError: ApolloError | undefined
    socialGroupsError: ApolloError | undefined
    plantsError: ApolloError | undefined
}

const useChallengeRecap = (
    challengeId: string,
    theme: string
): ChallengeRecap => {
    const isValidChallenge = Boolean(challengeId)
    const {
        data: challenge,
        loading: recapLoading,
        error: challengeError
    } = useGetUnifiedChallengeRecapQuery({
        variables: {
            challengeId,
            challengeIds: [challengeId],
            isHydration: theme === ChallengeThemes.HYDRATION,
            isMovement: theme === ChallengeThemes.MOVEMENT,
            isSleep: theme === ChallengeThemes.SLEEP
        },
        skip: !isValidChallenge
    })

    const {
        mainCommunitySocialGroup,
        socialGroup,
        loading: socialGroupsLoading,
        error: socialGroupsError
    } = useGetSocialGroups(challengeId)

    const {
        data: plantsData,
        loading: plantsLoading,
        error: plantsError
    } = useGetChallengePlantsQuery({
        variables: {
            challengeId,
            socialGroupId: socialGroup?.id,
            includeTeamPlants: !socialGroup?.isSoloChallenge
        },
        skip: !isValidChallenge || !socialGroup
    })
    return {
        challenge,
        socialGroups: {
            mainCommunitySocialGroup,
            socialGroup
        },
        plants: plantsData,
        loading: recapLoading || socialGroupsLoading || plantsLoading,
        challengeError,
        socialGroupsError,
        plantsError
    }
}

export default useChallengeRecap
