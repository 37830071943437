import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'

const IntroSignupCompanyChallengeDialogLearnMore: React.FC = () => {
    const { setShowPrivacy } = useCompanyChallengeSignUpProviderContext()

    return (
        <Button
            data-testid="privacy-button"
            variant="text"
            onClick={() => setShowPrivacy(true)}
        >
            <CoreTypography customVariant="buttonNormal">
                <FormattedMessage
                    defaultMessage="Learn More"
                    description="button text for the learn more popup"
                />
            </CoreTypography>
        </Button>
    )
}

export default IntroSignupCompanyChallengeDialogLearnMore
