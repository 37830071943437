import { GoalChallengeContextType } from '../../../enums/goalChallengeContextType'
import { useGoalChallengeContextProvider } from './useGoalChallengeContextProvider'

export function useGetGoalChallengeContext<T>(
    contextType: GoalChallengeContextType,
    defaultValue: T
) {
    const { contextValues } = useGoalChallengeContextProvider()

    return !!contextValues && !!contextValues?.[contextType]
        ? (contextValues[contextType] as T)
        : defaultValue
}

export default useGetGoalChallengeContext
