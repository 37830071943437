import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { TutorialWithProvider } from '../../../../../../shared/components/features/Tutorial'
import { getStepDetails } from '../../../../../../shared/components/features/Tutorial/Steps/config'
import { UnifiedChallenge } from '../../../../../../graphql/generated/autogenerated'
import { isSoloChallenge } from '../../../../../utils/challenge'
import { useChallengeTheme } from '../../../../../../hooks/useChallengeTheme/useChallengeTheme'

export const tutorialMessages = defineMessages({
    dialogTitle: {
        defaultMessage: 'How it works',
        description: 'Title for the tutorial dialog'
    },
    tutorialButtonAriaLabel: {
        defaultMessage:
            'Open tutorial modal to learn about the details of company challenges page',
        description: 'Aria label for the unboxing button'
    }
})

export interface GoalChallengeShowTutorialProps {
    open: boolean
    onClose: () => void
    challenge?: UnifiedChallenge
}

const GoalChallengeShowTutorial: React.FC<GoalChallengeShowTutorialProps> = ({
    open,
    onClose,
    challenge
}) => {
    const { formatMessage } = useIntl()

    const soloChallenge = isSoloChallenge(challenge)
    const { deviceEnabled, sleepEnabled } = useChallengeTheme(challenge)

    return (
        <TutorialWithProvider
            open={open}
            onClose={onClose}
            title={formatMessage(tutorialMessages.dialogTitle)}
            stepDetails={getStepDetails(
                deviceEnabled,
                sleepEnabled,
                !soloChallenge
            )}
        />
    )
}

export default memo(GoalChallengeShowTutorial)
