import { Box, Button, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { JoinGroupButton, SocialGroupControls } from '..'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import { useNavigateBack } from '../../../../hooks/useNavigateBack/useNavigateBack'

export type SingleSocialGroupHeaderProps = {
    socialGroup: SocialGroup
    displayName: string
    hasJoined: boolean
    socialGroupRefetch: () => Promise<any>
    displayNameRefetch: () => Promise<any>
}

const messages = defineMessages({
    joinGroup: {
        defaultMessage: 'Join Group',
        description: 'join group button text'
    },
    back: {
        defaultMessage: 'Back',
        description: 'button text that will navigate the user back one page'
    },
    backAria: {
        defaultMessage: 'Navigate Back',
        description: 'aria button text that will describe the button action'
    },
    members: {
        defaultMessage: `{membersCount, plural, one {# member} other {# members}}`,
        description:
            'Description on completing a challenge to encourage a user to start another'
    }
})

const SingleSocialGroupHeader: React.FC<SingleSocialGroupHeaderProps> = ({
    socialGroup,
    displayName,
    hasJoined,
    socialGroupRefetch,
    displayNameRefetch
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const onBackNavigate = useNavigateBack()

    return (
        <Stack
            sx={{
                borderBottom: `${theme.spacing(0.125)} solid ${
                    theme.palette.grey[300]
                }`,
                pb: theme.spacing(4),
                pt: theme.spacing(6),
                mb: theme.spacing(4),
                [theme.breakpoints.down('md')]: {
                    mb: theme.spacing(0),
                    p: theme.spacing(2)
                }
            }}
            data-testid="single-social-group-header"
        >
            {isMobile && (
                <IconButton
                    variant="text"
                    aria-label={intl.formatMessage(messages.backAria)}
                    onClick={onBackNavigate}
                    data-testid="arrow-back-icon"
                >
                    <LeafIcon icon={'chevron-left'} />
                </IconButton>
            )}
            {!isMobile && (
                <Button
                    variant="text"
                    onClick={onBackNavigate}
                    data-testid="social-group-challenges-link"
                    startIcon={
                        <LeafIcon icon={'arrow-left'} fontSize={'small'} />
                    }
                    sx={{
                        width: 'fit-content'
                    }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        {intl.formatMessage(messages.back)}
                    </CoreTypography>
                </Button>
            )}
            <Stack
                direction="row"
                sx={{
                    pt: theme.spacing(3)
                }}
            >
                <Stack
                    sx={{
                        mr: theme.spacing(2),
                        [theme.breakpoints.down('sm')]: {
                            mr: 0
                        }
                    }}
                >
                    <CoreTypography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            mb: theme.spacing(1)
                        }}
                    >
                        {socialGroup?.name}
                    </CoreTypography>
                    <CoreTypography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.primary
                        }}
                    >
                        {intl.formatMessage(messages.members, {
                            membersCount: socialGroup.memberCount
                        })}
                    </CoreTypography>
                </Stack>
                {!isMobile && (
                    <Box
                        sx={{
                            zIndex: 1,
                            ml: 'auto'
                        }}
                    >
                        <SocialGroupControls
                            hasJoined={hasJoined}
                            socialGroup={socialGroup}
                            displayName={displayName}
                            socialGroupRefetch={socialGroupRefetch}
                            displayNameRefetch={displayNameRefetch}
                        />
                    </Box>
                )}

                {!hasJoined && isMobile && (
                    <Box
                        sx={{
                            position: 'fixed',
                            display: 'flex',
                            width: '100vw',
                            maxWidth: '100%',
                            justifyContent: 'center',
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                            ml: 'auto',
                            p: theme.spacing(2.25),
                            pt: theme.spacing(3),
                            pb: theme.spacing(0.5),
                            background: `linear-gradient(180deg, ${theme.palette.background.paper}00 0%, ${theme.palette.background.paper} 45.89%)`
                        }}
                    >
                        <JoinGroupButton
                            socialGroups={[socialGroup]}
                            displayName={displayName}
                            displayNameRefetch={displayNameRefetch}
                            onJoinGroup={socialGroupRefetch}
                            disabled={hasJoined}
                            text={intl.formatMessage(messages.joinGroup)}
                        />
                    </Box>
                )}
            </Stack>
        </Stack>
    )
}

export default React.memo(SingleSocialGroupHeader)
