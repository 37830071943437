import { Box, Button, Grid, Skeleton, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    SocialGroup,
    UnifiedChallenge
} from '../../../../../../../graphql/generated/autogenerated'
import { SocialGroupsList } from '../../../../../../../components/features/SocialGroups'
import JoinTeamActionsHeader from './JoinTeamActionsHeader'
import useGetCompanySocialGroups from '../../../../../../hooks/useGetCompanySocialGroups/useGetCompanySocialGroups'
import { useAppSelector } from '@thriveglobal/thrive-web-core'

type JoinTeamProps = {
    challenge: UnifiedChallenge
    teamId?: string
    setTeam: (id?: string, name?: string) => void
    switchParticipationType?: () => void
}

const messages = defineMessages({
    nameDoesNotExistCreate: {
        defaultMessage:
            "This team doesn't exist. Try a different name or create this team.",
        description: 'team name does not exist description'
    },
    nameDoesNotExist: {
        defaultMessage: "This team doesn't exist. Try a different name.",
        description: 'team name does not exist description'
    },
    createATeam: {
        defaultMessage: 'Create a team',
        description: 'create a team button on company challenge sign up process'
    },
    joinATeam: {
        defaultMessage: 'Join this team',
        description: 'join a team button on company challenge sign up process'
    }
})

const JoinTeamStep: React.FC<JoinTeamProps> = ({
    challenge: { id, maxTeamSize },
    teamId,
    setTeam,
    switchParticipationType
}) => {
    const { userId } = useAppSelector((state) => state.user)
    const { formatMessage } = useIntl()
    const [empty, setEmpty] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState('')

    const { companyGroups, loading } = useGetCompanySocialGroups(id)

    const onSetSearchTerm = useCallback((searchTerm: string) => {
        setSearchTerm(searchTerm)
    }, [])

    const publicCompanyGroups = useMemo(
        () =>
            companyGroups?.filter(
                (group) => group?.isPublic && group?.createdBy !== userId
            ),
        [companyGroups, userId]
    )

    return (
        <Stack gap={2} display="flex" px={0.125} pb={0.125}>
            <JoinTeamActionsHeader
                isEmpty={empty}
                setSearchTerm={onSetSearchTerm}
            />
            <Box
                pb={1}
                px={0.125}
                pt={0.125}
                overflow="auto"
                data-testid="social-groups-wrapper"
            >
                {loading && (
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Skeleton width={'100%'} height={120}></Skeleton>
                        </Grid>
                    </Grid>
                )}
                {!loading && (
                    <SocialGroupsList
                        joinedSocialGroups={teamId ? [teamId] : []}
                        allSocialGroups={publicCompanyGroups as SocialGroup[]}
                        isJoinList={true}
                        addToJoinList={({ id, name }: SocialGroup) =>
                            setTeam(id, name)
                        }
                        excludeSocialGroups={[]}
                        joinGroupQueryActive={false}
                        searchTerm={searchTerm}
                        activeById={teamId}
                        disableNavigate={true}
                        maxCapacity={maxTeamSize || 0}
                        setEmpty={setEmpty}
                    />
                )}
            </Box>
            {empty && (
                <Stack gap={1}>
                    {searchTerm?.length > 0 && (
                        <CoreTypography variant="body1">
                            {formatMessage(messages.nameDoesNotExistCreate)}
                        </CoreTypography>
                    )}
                    {switchParticipationType && (
                        <Box>
                            <Button
                                data-testid="switch-sign-up-type-button"
                                onClick={switchParticipationType}
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    {formatMessage(messages.createATeam)}
                                </CoreTypography>
                            </Button>
                        </Box>
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default memo(JoinTeamStep)
