import { Box, Collapse, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import RadioList from '../../../shared/components/elements/RadioList/RadioList'

const messages = defineMessages({
    private: {
        defaultMessage: 'Private',
        description: 'text of a radio button to make a group private'
    },
    public: {
        defaultMessage: 'Public',
        description: 'text of a radio button to make a group public'
    }
})

export type TeamVisibilityProps = {
    isPublicGroup: boolean
    setIsPublicGroup: (isPublicGroup: boolean) => void
}

const TeamVisibility: React.FC<TeamVisibilityProps> = ({
    isPublicGroup,
    setIsPublicGroup
}) => {
    const { formatMessage } = useIntl()

    return (
        <Stack gap={1}>
            <CoreTypography variant="h5">
                <FormattedMessage
                    defaultMessage="Team visibility setting"
                    description="Choose Privacy of the social group title"
                />
            </CoreTypography>

            <RadioList
                onChange={(isPublic: string) =>
                    setIsPublicGroup(isPublic === 'true')
                }
                defaultValue={isPublicGroup}
                excludeSpacing={true}
                row={true}
                list={[
                    {
                        value: true,
                        title: formatMessage(messages.public)
                    },
                    {
                        value: false,
                        title: formatMessage(messages.private)
                    }
                ]}
            />
            <Box>
                <Collapse in={!isPublicGroup}>
                    <CoreTypography variant="caption">
                        <FormattedMessage
                            defaultMessage="When you make a team private, other users can join by invite only. It will still be visible on leaderboards and the community activity feed."
                            description="description for what making a team private will do for the social group"
                        />
                    </CoreTypography>
                </Collapse>
            </Box>
        </Stack>
    )
}

export default memo(TeamVisibility)
