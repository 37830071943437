import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton'
import WaveCardContainer, { WaveCardContainerProps } from './WaveCardContainer'

export type MultiStatWaveCardProps = WaveCardContainerProps & {
    hideTitles?: boolean
    stats: { name: string; value: number | string }[]
}

const MultiStatWaveCard: React.FC<MultiStatWaveCardProps> = ({
    stats,
    elevation,
    loading,
    waveColor,
    hideTitles = false
}) => (
    <WaveCardContainer
        elevation={elevation}
        waveColor={waveColor}
        loading={loading}
    >
        <Stack gap={1.5} zIndex={1}>
            <ConditionalSkeleton showSkeleton={loading}>
                <Stack direction="row" gap={1}>
                    {stats.map((stat) => (
                        <Stack
                            key={stat.name}
                            width="100%"
                            justifyContent="center"
                            gap={0}
                        >
                            {!hideTitles && (
                                <CoreTypography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {stat.name}
                                </CoreTypography>
                            )}
                            <CoreTypography
                                variant="body2"
                                fontSize={32}
                                color="text.secondary"
                            >
                                {stat.value}
                            </CoreTypography>
                        </Stack>
                    ))}
                </Stack>
            </ConditionalSkeleton>
        </Stack>
    </WaveCardContainer>
)

export default memo(MultiStatWaveCard)
