import {
    Box,
    CircularProgress,
    DialogTitle,
    Stack,
    TextField,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    UnifiedChallenge,
    Visibility
} from '../../../../../graphql/generated/autogenerated'
import useStorySubmissionData from '../../../../contexts/withStorySubmission/useStorySubmissionData'
import { useStorySubmissionContext } from '../../../../contexts/withStorySubmission/withStorySubmissionProvider'
import { useStorySubmissionStateProvider } from '../../../../contexts/withStorySubmission/withStorySubmissionStateProvider/useStorySubmissionStateProvider'
import TermsAndConditions from '../../../../../shared/components/elements/TermsAndConditions/TermsAndConditions'
import { useShowWalmartBetterChoicesChallenge } from '../../../../../shared/hooks'
import GetFeatured from '../GetFeatured'

export type SingleQuestionStoryProps = {
    challenge?: UnifiedChallenge
}

const messages = defineMessages({
    writeYourStory: {
        defaultMessage: 'Write your Story',
        description: 'choose story page title'
    },
    reflecting: {
        defaultMessage:
            'Reflecting on your experience is an important step as you close out your Challenge.',
        description: 'description on the importance of submitting a story'
    },
    storySuggestion: {
        defaultMessage: 'Story suggestions',
        description: 'suggestions title on what to write in your story'
    },
    done: {
        defaultMessage: 'Done',
        description:
            'button text that will submit a story for a completed challenge'
    },
    walmartSubmitEntry: {
        defaultMessage: 'Submit your entry for a chance to win cash prizes',
        description: 'Some companies offer additional cash incentives'
    }
})

const SingleQuestionStory: React.FC<SingleQuestionStoryProps> = ({
    challenge
}) => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    const isWalmartPrizeChallenge =
        useShowWalmartBetterChoicesChallenge(challenge)
    const { submitLoading, onSubmit } = useStorySubmissionData()
    const { answeredQuestions, visibility, onAnswer, onVisibility } =
        useStorySubmissionStateProvider()
    const { title, subTitle, termsAndConditionsUrl } =
        useStorySubmissionContext()
    const [answer, setAnswer] = useState(answeredQuestions[0].answer)

    const onStoryTypeChange = useCallback(
        (event: any) =>
            onVisibility(
                event.target.checked ? Visibility.Public : Visibility.Private
            ),
        [onVisibility]
    )

    const onAnswerTextQuestion = useCallback(
        (newAnswer: any) => {
            setAnswer(newAnswer)
            onAnswer(0, newAnswer)
        },
        [onAnswer]
    )

    return (
        <Box
            data-testid="single-story-question"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: theme.spacing(24),
                [theme.breakpoints.down('md')]: {
                    mt: theme.spacing(12)
                }
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    maxWidth: theme.spacing(133.5)
                }}
                direction="column"
            >
                <DialogTitle
                    sx={{
                        p: 0
                    }}
                >
                    <CoreTypography
                        variant="h1"
                        sx={{
                            mb: theme.spacing(1),
                            color: theme.palette.primary.main
                        }}
                    >
                        {title ? title : formatMessage(messages.writeYourStory)}
                    </CoreTypography>
                </DialogTitle>
                {(termsAndConditionsUrl || isWalmartPrizeChallenge) && (
                    <CoreTypography
                        variant="body1"
                        sx={{
                            fontSize: theme.spacing(2),
                            mb: 2
                        }}
                    >
                        {isWalmartPrizeChallenge &&
                            formatMessage(messages.walmartSubmitEntry)}
                        <TermsAndConditions
                            termsAndConditionsUrl={termsAndConditionsUrl}
                        />
                    </CoreTypography>
                )}
                <CoreTypography
                    variant="body1"
                    sx={{
                        fontSize: theme.spacing(2),
                        mb: theme.spacing(6)
                    }}
                    id="story-reflecting"
                >
                    {subTitle ? subTitle : formatMessage(messages.reflecting)}
                </CoreTypography>
                <Box
                    display="flex"
                    height="100%"
                    width="100%"
                    justifyContent="start"
                    sx={{
                        mb: 6,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            mb: 5
                        }
                    }}
                >
                    <TextField
                        multiline
                        rows={6}
                        label={answeredQuestions[0].question}
                        sx={{
                            maxWidth: theme.spacing(85),
                            flexGrow: 1,
                            '& .MuiInputBase-multiline': {
                                p: 0,
                                pb: theme.spacing(2),
                                borderRadius: theme.spacing(1)
                            },
                            '& .MuiInputBase-inputMultiline': {
                                p: theme.spacing(2),
                                pb: 0,
                                borderColor: theme.palette.primary.main,
                                borderWidth: theme.spacing(3)
                            },
                            '& .MuiOutlinedInput-root': {
                                borderWidth: theme.spacing(2),
                                '& fieldset, &:hover fieldset, &.Mui-focused fieldset, & .MuiInputLabel-root':
                                    {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: theme.spacing(0.125),
                                        whiteSpace: 'nowrap'
                                    },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.grey[700]
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.grey[700],
                                whiteSpace: 'break-spaces',
                                '&.MuiFormLabel-filled, &.Mui-focused': {
                                    whiteSpace: 'nowrap'
                                }
                            },
                            '& textarea': {}
                        }}
                        value={answer}
                        onChange={(event) =>
                            onAnswerTextQuestion(event.target.value)
                        }
                        inputProps={{
                            'data-testid': 'answer-input',
                            maxLength: 10000,
                            'aria-describedby':
                                'story-reflecting story-suggestions'
                        }}
                    />
                    <Stack
                        sx={{
                            maxWidth: theme.spacing(40.5),
                            ml: 4,
                            [theme.breakpoints.down('md')]: {
                                ml: 0,
                                mt: 3
                            }
                        }}
                        id="story-suggestions"
                    >
                        <CoreTypography
                            variant="h4"
                            sx={{ mb: 2 }}
                            color="primary.main"
                        >
                            {formatMessage(messages.storySuggestion)}
                        </CoreTypography>
                        <Box display="flex" width="100%" sx={{ mb: 1 }}>
                            <LeafIcon
                                icon={'comment'}
                                color={'accent'}
                                sx={{ mr: 1, my: 'auto' }}
                            />
                            <CoreTypography>
                                <FormattedMessage
                                    defaultMessage="Why did you take on this challenge?"
                                    description="suggestions on what to write in your story"
                                />
                            </CoreTypography>
                        </Box>
                        <Box display="flex" width="100%" sx={{ mb: 1 }}>
                            <LeafIcon
                                icon={'comment'}
                                color={'accent'}
                                sx={{ mr: 1, my: 'auto' }}
                            />
                            <CoreTypography>
                                <FormattedMessage
                                    defaultMessage="What changes did you make?"
                                    description="suggestions on what to write in your story"
                                />
                            </CoreTypography>
                        </Box>
                        <Box display="flex" width="100%" sx={{ mb: 1 }}>
                            <LeafIcon
                                icon={'comment'}
                                color={'accent'}
                                sx={{ mr: 1, my: 'auto' }}
                            />
                            <CoreTypography>
                                <FormattedMessage
                                    defaultMessage="What did you learn?"
                                    description="suggestions on what to write in your story"
                                />
                            </CoreTypography>
                        </Box>
                    </Stack>
                </Box>
                {!isWalmartPrizeChallenge && (
                    <GetFeatured
                        checked={visibility === Visibility.Public}
                        onStoryTypeChange={onStoryTypeChange}
                    />
                )}

                {isWalmartPrizeChallenge && (
                    <CoreTypography
                        customVariant="buttonLarge"
                        sx={{
                            fontSize: theme.spacing(2),
                            mb: theme.spacing(2)
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Submit your entry to be considered for cash prizes!"
                            description="description text describing how a user can get cash prizes by submitting a story on their completed challenge"
                        />
                    </CoreTypography>
                )}
                <Box
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center'
                        }
                    }}
                >
                    <LoadingButton
                        disabled={submitLoading}
                        loading={submitLoading}
                        fixWidth={true}
                        variant="contained"
                        sx={{
                            mb: theme.spacing(3),
                            ...(!isWalmartPrizeChallenge && {
                                mt: theme.spacing(6)
                            })
                        }}
                        fullWidth={smDown}
                        onClick={onSubmit}
                        loader={
                            <Box textAlign="center">
                                <CircularProgress color="accent" />
                            </Box>
                        }
                        data-testid="done-story-submission-button"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(messages.done)}
                        </CoreTypography>
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}

export default SingleQuestionStory
