import { Divider, Skeleton, Stack } from '@mui/material'
import React, { memo } from 'react'

const ParticipantSkeleton: React.FC = () => (
    <Stack gap={1} pb={1}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Skeleton width={110} height={22} />
            <Skeleton width={75} height={44} variant="rounded" sx={{ mr: 1 }} />
        </Stack>
        <Divider />
    </Stack>
)

export default memo(ParticipantSkeleton)
