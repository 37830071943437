import { Stack } from '@mui/material'
import React, { memo } from 'react'
import TeamMemberProgressCard from '../TeamMemberProgressCard'
import { useGetSocialGroupGoalsActivity } from '../../../../hooks/useGetSocialGroupGoalsActivity/useGetSocialGroupGoalsActivity'

const TeamMembersProgressList: React.FC = () => {
    const { teamDailyGoalProgress } = useGetSocialGroupGoalsActivity()

    return (
        <Stack direction="column" gap={2} py={2}>
            {teamDailyGoalProgress.map((userGoals, index: number) => (
                <TeamMemberProgressCard
                    key={index}
                    name={userGoals.userDisplayName}
                    userDailyGoals={userGoals.userDailyGoals}
                />
            ))}
        </Stack>
    )
}

export default memo(TeamMembersProgressList)
