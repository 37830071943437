import { Button } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { SignUpTypes } from '../../../../enums'
import { useChallengeSignUpModalContext } from '../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { useCompanyChallengeProviderContext } from '../../../../hooks/withCompanyChallengeProvider'

export type JoinCompanyChallengeButtonProps = {
    variant?: 'contained' | 'outlined'
}

const JoinCompanyChallengeButton: React.FC<JoinCompanyChallengeButtonProps> = ({
    variant = 'outlined'
}) => {
    const { challenge } = useCompanyChallengeProviderContext()
    const { refetch } = useCompanyChallengeProviderContext()

    const {
        setChallenge,
        setInviteSocialGroup,
        setIsChangeParticipation,
        onOpen,
        setOnComplete,
        setSignUpType
    } = useChallengeSignUpModalContext()

    const onSignUp = useCallback(() => {
        setOnComplete({ onComplete: refetch })
        setChallenge(challenge)
        setIsChangeParticipation(false)
        setSignUpType(SignUpTypes.solo)
        onOpen()
    }, [
        challenge,
        setChallenge,
        setIsChangeParticipation,
        onOpen,
        refetch,
        setOnComplete,
        setSignUpType
    ])

    return (
        <Button
            variant={variant}
            onClick={() => {
                setInviteSocialGroup(undefined)
                onSignUp()
            }}
        >
            <CoreTypography customVariant="buttonNormal">
                <FormattedMessage
                    defaultMessage="Join this Challenge"
                    description="text for button to join the challenge"
                />
            </CoreTypography>
        </Button>
    )
}

export default memo(JoinCompanyChallengeButton)
