import { IconButton, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import ConditionalSkeleton from '../ConditionalSkeleton/ConditionalSkeleton'
import { IconName } from '@fortawesome/fontawesome-common-types'

export interface IntakeButtonProps {
    loading: boolean
    disabled?: boolean
    onClick: () => void
    label: string
    dataTestid: string
    icon: IconName
}

const IntakeButton: React.FC<IntakeButtonProps> = ({
    loading,
    disabled,
    onClick,
    label,
    dataTestid,
    icon
}) => (
    <ConditionalSkeleton showSkeleton={loading} variant="circular">
        <IconButton
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            aria-label={label}
            data-testid={dataTestid}
        >
            <LeafIcon icon={icon} />
        </IconButton>
    </ConditionalSkeleton>
)

export default memo(IntakeButton)
