import { Box, Card, DialogContent, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { groupBy } from 'lodash'
import { memo, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    ChallengeGoal,
    ChallengeTemplateGoal,
    ChallengeUserDailyGoal
} from '../../../../../../graphql/generated/autogenerated'
import { useDailyGoals } from '../../../../../hooks/useDailyGoals/useDailyGoals'

const messages = defineMessages({
    dailyGoalsPreview: {
        defaultMessage: ' Daily goals preview',
        description: 'daily goals dialog title'
    }
})

export type DailyGoalPreviewDialogButton = {
    open: boolean
    onClose: () => void
    goals: ChallengeTemplateGoal[] | ChallengeUserDailyGoal[]
}

const DailyGoalPreviewDialogButton: React.FC<DailyGoalPreviewDialogButton> = ({
    open,
    onClose,
    goals
}) => {
    const { breakpoints } = useTheme()
    const { formatMessage } = useIntl()

    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const dailyGoalsPreview = useDailyGoals({
        goals: (goals as unknown as ChallengeGoal[]).map(
            (goal) =>
                ({
                    challengeGoal: goal as unknown | ChallengeGoal
                } as ChallengeUserDailyGoal)
        ),
        preview: true,
        disabled: true
    })

    const mappedToDays = useMemo(
        () =>
            groupBy(
                dailyGoalsPreview,
                (preview) => preview.goal.challengeGoal.day
            ),
        [dailyGoalsPreview]
    )

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={isMobile}
            dialogTitle={formatMessage(messages.dailyGoalsPreview)}
        >
            <DialogContent>
                <Stack p={2} gap={4}>
                    {Object.keys(mappedToDays)?.map((key, index) => (
                        <Stack key={index} gap={2}>
                            <CoreTypography variant="h3">
                                <FormattedMessage
                                    defaultMessage="Day {day}"
                                    description="day description"
                                    values={{ day: key }}
                                />
                            </CoreTypography>
                            {mappedToDays[key]?.map((goal, index) => (
                                <Box key={index}>
                                    <Card
                                        sx={{
                                            overflow: 'visible'
                                        }}
                                    >
                                        <Stack p={2} gap={2}>
                                            {goal.component}
                                        </Stack>
                                    </Card>
                                </Box>
                            ))}
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
        </LeafDialog>
    )
}

export default memo(DailyGoalPreviewDialogButton)
