import { Stack } from '@mui/material'
import { fetchAndStoreAchievements } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { defineMessages, useIntl } from 'react-intl'
import {
    AudioResetGoalDetail,
    ChallengeUserDailyGoal,
    UnifiedChallenge,
    useCompleteCommunityChallengeDailyGoalMutation,
    useFireThriveAudioResetListenedEventMutation
} from '../../../../../../../graphql/generated/autogenerated'
import {
    ThriveAudioResetPlayer,
    ThriveAudioResetPopup
} from '../../../../../../../temp/ThriveAudioResets/ThriveAudioResetPlayer'
import { getDurationInMinutes } from '../../../../../../../utils'
import {
    defaultContentCompletedProps,
    defaultContentStartedProps
} from '../../../../../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../../../../../utils/nulls'
import ContentCard from '../../../../../elements/ContentCard'
import GoalTitle from '../../GoalTitle'

const messages = defineMessages({
    listenToAReset: {
        defaultMessage: 'Listen to a reset',
        description: 'title for the reset daily goal card'
    },
    youListenedTo: {
        defaultMessage: 'You listened to',
        description:
            'title of the audio reset a user has listened to, there is no text following this, there is a video below it'
    },
    audioReset: {
        defaultMessage: 'Audio Reset',
        description: 'content type description'
    },
    duration: {
        defaultMessage: '{durationInMinutes} minute listen',
        description: 'description of the duration in minutes the audio runs for'
    }
})

export type AudioResetGoalProps = {
    goal: ChallengeUserDailyGoal
    challenge: UnifiedChallenge
    loading?: boolean
    disabled?: boolean
    onStart: () => void
    onComplete: () => void
}

const RESET_THRESHOLD = 80

const AudioResetGoal: React.FC<AudioResetGoalProps> = ({
    goal,
    challenge,
    loading,
    disabled,
    onStart,
    onComplete
}) => {
    const { formatMessage } = useIntl()
    const playerRef = useRef<ReactAudioPlayer>(ReactNullValue)
    const [isPlayerOpen, setIsPlayerOpen] = useState(false)
    const [resetThresholdHit, setResetThresholdHit] = useState(false)
    const [fireThriveAudioResetListenedEvent] =
        useFireThriveAudioResetListenedEventMutation()

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    const { audioReset, durationInMinutes, completed } = useMemo(() => {
        const resetEntityDetail = goal?.goalEntityDetail as
            | AudioResetGoalDetail
            | undefined
        const durationInSeconds =
            resetEntityDetail?.audioReset?.durationInSeconds ?? 0

        return {
            audioReset: resetEntityDetail?.audioReset,
            durationInMinutes: getDurationInMinutes(durationInSeconds),
            completed: goal?.completed
        }
    }, [goal])

    const clickHandler = useCallback(() => {
        Avo.contentStarted({
            ...defaultContentStartedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: 'audio',
            contentId: audioReset?.id,
            featureType: 'challenge',
            contentTitle: audioReset?.name,
            contentType: 'reset',
            resetType: 'challenge_reset',
            tabName: 'Home'
        })
        setIsPlayerOpen(true)
        onStart()
    }, [challenge, audioReset, onStart])

    const closeHandler = useCallback(() => {
        setIsPlayerOpen(false)
        if (resetThresholdHit) onComplete()
    }, [resetThresholdHit, onComplete])

    const onEndedHandler = useCallback(async () => {
        if (!audioReset) return
        await fireThriveAudioResetListenedEvent({
            variables: {
                thriveResetAudioId: audioReset.id
            }
        })
        await completeDailyGoal()
        Avo.contentCompleted({
            ...defaultContentCompletedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            contentFormatType: 'audio',
            contentId: audioReset.id,
            contentTitle: audioReset.name,
            contentType: 'reset',
            featureType: 'challenge',
            resetType: 'challenge_reset'
        })
        await fetchAndStoreAchievements()
    }, [
        challenge,
        audioReset,
        completeDailyGoal,
        fireThriveAudioResetListenedEvent
    ])

    const onHandleTimeUpdate = useCallback(() => {
        const audioElement = playerRef?.current?.audioEl?.current
        const percentageComplete =
            ((audioElement?.currentTime ?? 0) / (audioElement?.duration ?? 0)) *
            100

        if (percentageComplete >= RESET_THRESHOLD && !resetThresholdHit) {
            setResetThresholdHit(true)
            onEndedHandler()
        }
    }, [playerRef, resetThresholdHit, onEndedHandler])

    return (
        <Stack gap={2} height="100%">
            <GoalTitle
                title={formatMessage(
                    completed ? messages.youListenedTo : messages.listenToAReset
                )}
            />
            <ContentCard
                elevation={1}
                loading={loading}
                disabled={disabled}
                onClick={clickHandler}
                title={audioReset?.name}
                image={audioReset?.images.xl}
                description={audioReset?.description}
                contentType={formatMessage(messages.audioReset)}
                duration={formatMessage(messages.duration, {
                    durationInMinutes
                })}
            />

            {!!audioReset && isPlayerOpen && (
                <ThriveAudioResetPopup
                    open={isPlayerOpen}
                    onClose={closeHandler}
                >
                    <ThriveAudioResetPlayer
                        thriveAudioReset={audioReset}
                        ref={playerRef}
                        open={isPlayerOpen}
                        onClose={closeHandler}
                        utmSource={''}
                        onTimeUpdate={onHandleTimeUpdate}
                        autoPlay
                    />
                </ThriveAudioResetPopup>
            )}
        </Stack>
    )
}

export default AudioResetGoal
