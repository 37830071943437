import { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useGoalChallengeDataProviderContext } from '../../../../../contexts/withGoalChallenges/useGoalChallengeData/useGoalChallengeDataProviderContext'
import { ReactNullValue } from '../../../../../../shared/utils/Nulls'
import PlantsEarnedCard from '../PlantsEarnedCard'
import { UserPlantInfo } from '../../../../../../graphql/generated/autogenerated'
const messages = defineMessages({
    todayCount: {
        defaultMessage: 'plants earned',
        description: 'description for total plant count'
    }
})

export type YourTotalPlantsEarnedProps = {
    size?: 'small' | 'large'
    plants: UserPlantInfo[]
    error: boolean
}

const YourTotalPlantsEarned: React.FC<YourTotalPlantsEarnedProps> = ({
    size,
    plants,
    error
}) => {
    const { formatMessage } = useIntl()
    const totalPlants = useMemo(() => plants.length, [plants])

    if (error) return ReactNullValue

    return (
        <PlantsEarnedCard
            title={formatMessage(messages.todayCount)}
            stat={totalPlants}
            fullPlant={true}
            size={size}
        />
    )
}

export default memo(YourTotalPlantsEarned)
