import { useMemo } from 'react'
import {
    UnifiedChallenge,
    useGetTemplateQuery,
    useGetUnifiedChallengeIntroQuery
} from '../../../graphql/generated/autogenerated'

export interface ChallengeIntroData {
    challenge?: UnifiedChallenge
    isTemplate: boolean
    loading: boolean
    error?: Error
}

interface UseGetChallengeIntroProps {
    challengeId: string
    isTemplate: boolean
}

export const useGetChallengeIntro = ({
    challengeId,
    isTemplate
}: UseGetChallengeIntroProps): ChallengeIntroData => {
    const templateQuery = useGetTemplateQuery({
        variables: { templateId: challengeId },
        skip: !isTemplate || !challengeId
    })

    const unifiedQuery = useGetUnifiedChallengeIntroQuery({
        variables: { challengeId },
        skip: isTemplate || !challengeId
    })

    return useMemo(
        () => ({
            challenge: isTemplate
                ? (templateQuery.data?.unifiedChallenges
                      ?.groupChallengeTemplate as unknown as UnifiedChallenge)
                : (unifiedQuery.data?.unifiedChallenges
                      ?.unifiedChallenge as UnifiedChallenge),
            isTemplate,
            loading: isTemplate ? templateQuery.loading : unifiedQuery.loading,
            error: isTemplate ? templateQuery.error : unifiedQuery.error
        }),
        [isTemplate, templateQuery, unifiedQuery]
    )
}

export default useGetChallengeIntro
