import { useCallback, useEffect } from 'react'
import { SignUpTypes } from '../../enums'
import {
    ChallengeTimePoint,
    MeasureUnit,
    SocialGroup,
    UnifiedChallenge,
    useSetWaterCustomizationMutation,
    useSetSleepHabitsMutation,
    useSetStepsCustomizationMutation
} from '../../graphql/generated/autogenerated'
import { useChallengeSignUpModalContext } from '../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { useHydrationProviderContext } from '../hydrationProviders/withHydrationProvider'
import { useCompanyChallengeSignUpProviderContext } from '../withChallengeCompanySignUp'
import { useCompanyChallengeProviderContext } from '../withCompanyChallengeProvider'
import useJoinGoalChallengeMutation from './useJoinGoalChallengeMutation'

const SOCIAL_GROUP_MUTATION = 'socialGroupMutation'
const WATER_CUSTOMIZATION_MUTATION = 'waterCustomizationMutation'
const STEPS_CUSTOMIZATION_MUTATION = 'stepsCustomizationMutation'
const SLEEP_HABITS_MUTATION = 'sleepHabitMutation'

export const useJoinCompanyChallengeSocialGroup = (
    challenge: UnifiedChallenge,
    signUpType: SignUpTypes
) => {
    const {
        teamName,
        socialGroup,
        isPublicGroup,
        sleepHabit,
        activityGoal,
        addMutation,
        setSocialGroup
    } = useCompanyChallengeSignUpProviderContext()
    const { hydrationEnabled, sleepEnabled, deviceEnabled } =
        useCompanyChallengeProviderContext()
    const { goalCount, intakeMeasurement } = useHydrationProviderContext()
    const { isChangeParticipation } = useChallengeSignUpModalContext()
    const { joinChallenge, invalidText } = useJoinGoalChallengeMutation({
        challenge,
        socialGroup,
        teamName,
        isChangeParticipation,
        isPublicGroup
    })

    const [setWaterCustomizationMutation] = useSetWaterCustomizationMutation({
        variables: {
            challengeId: challenge?.id,
            waterAmount: goalCount,
            measureUnit: intakeMeasurement as MeasureUnit
        }
    })

    const [setStepsCustomizationMutation] = useSetStepsCustomizationMutation({
        variables: {
            challengeId: challenge?.id,
            stepsAmount: activityGoal
        }
    })

    const [setSleepHabitsMutation] = useSetSleepHabitsMutation({
        variables: {
            challengeId: challenge?.id,
            answer: sleepHabit as number,
            challengeTimePoint: ChallengeTimePoint.Start
        }
    })

    const onJoinMutationComplete = useCallback(
        (response: any) => {
            if (!response?.socialGroupId) {
                setSocialGroup({
                    id: response?.socialGroupId
                } as SocialGroup)
            }

            return response
        },
        [setSocialGroup]
    )

    useEffect(() => {
        addMutation({
            key: SOCIAL_GROUP_MUTATION,
            mutation: joinChallenge,
            onMutationComplete: onJoinMutationComplete
        })

        if (hydrationEnabled && !isChangeParticipation) {
            addMutation({
                key: WATER_CUSTOMIZATION_MUTATION,
                mutation: setWaterCustomizationMutation,
                onMutationComplete: () => {
                    return true
                }
            })
        }

        if (
            deviceEnabled &&
            challenge?.allowCustomSteps &&
            !isChangeParticipation
        ) {
            addMutation({
                key: STEPS_CUSTOMIZATION_MUTATION,
                mutation: setStepsCustomizationMutation,
                onMutationComplete: () => {
                    return true
                }
            })
        }

        if (sleepEnabled && !isChangeParticipation) {
            addMutation({
                key: SLEEP_HABITS_MUTATION,
                mutation: setSleepHabitsMutation,
                onMutationComplete: () => true
            })
        }
    }, [
        signUpType,
        socialGroup?.id,
        hydrationEnabled,
        sleepEnabled,
        activityGoal,
        challenge,
        deviceEnabled,
        isChangeParticipation,
        goalCount,
        intakeMeasurement,
        addMutation,
        onJoinMutationComplete,
        joinChallenge,
        setWaterCustomizationMutation,
        setStepsCustomizationMutation,
        setSleepHabitsMutation
    ])

    return invalidText
}

export default useJoinCompanyChallengeSocialGroup
