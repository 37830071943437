import {
    Collapse,
    Fade,
    InputAdornment,
    Stack,
    useMediaQuery
} from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTypography,
    LeafCircularProgress,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import debounce from 'lodash/debounce'
import React, {
    ReactElement,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
    InvalidInput,
    useValidateDisplayNameMutation
} from '../../../../graphql/generated/autogenerated'
import StyledTextField from '../StyledTextField'
import { GQLNull } from '../../../../shared/utils/Nulls'

const messages = defineMessages({
    yourDisplayName: {
        defaultMessage: 'Your display name',
        description: 'placeholder text for the display name text field'
    },
    enterDisplayName: {
        defaultMessage: 'Enter a display name',
        description: 'error text for the display name text field'
    },
    displayNameTooLong: {
        defaultMessage: 'Display name must be 28 characters or less',
        description: 'error text for the display name text field'
    }
})

export interface DisplayNameWithValidationProps {
    displayName: string | GQLNull
    altInvalidDisplayNameText?: string
    onCreateTextField?: (
        defaultValue: string | GQLNull,
        value: string,
        error: boolean,
        inputProps: unknown,
        onChange: (event: any) => void
    ) => ReactElement<any, any>
    setDisplayName: (displayName: string) => void
    onValidate: (valid: boolean) => void
    fullWidth?: boolean
}

const DisplayNameWithValidation: React.FC<DisplayNameWithValidationProps> = ({
    displayName,
    altInvalidDisplayNameText,
    onCreateTextField,
    setDisplayName,
    onValidate,
    fullWidth
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage, formatNumber } = useIntl()
    const [error, setError] = useState(false)
    const { fullName } = useAppSelector((state) => state.user)
    const [validationLoading, setValidationLoading] = useState<boolean>(false)
    const [invalidDisplayNameText, setInvalidDisplayNameText] = useState('')
    const [isInputPristine, setIsInputPristine] = useState(true)

    const [validateDisplayName] = useValidateDisplayNameMutation({
        variables: {
            displayName: displayName ?? ''
        }
    })

    const debounceValidation = useMemo(
        () =>
            debounce((text) => {
                if (text === '') {
                    onValidate(false)
                    setInvalidDisplayNameText(
                        formatMessage(messages.enterDisplayName)
                    )
                } else if (text?.length > 28) {
                    onValidate(false)
                    setInvalidDisplayNameText(
                        formatMessage(messages.displayNameTooLong)
                    )
                } else {
                    setValidationLoading(true)
                    validateDisplayName()
                        .then((response) => {
                            const res =
                                response?.data?.socialGroups
                                    ?.validateDisplayName
                            const invalidFields = (res as InvalidInput)
                                ?.invalidFields

                            if (invalidFields?.length > 0) {
                                setInvalidDisplayNameText(
                                    invalidFields[0].message
                                )
                                setValidationLoading(false)
                                onValidate(false)
                            } else {
                                setInvalidDisplayNameText('')
                                setValidationLoading(false)
                                onValidate(true)
                            }
                        })
                        .catch(() => {
                            setValidationLoading(false)
                            onValidate(false)
                            setError(true)
                        })
                }
            }, 300),
        [formatMessage, onValidate, validateDisplayName]
    )

    const onChangeHandler = useCallback(
        (event: any) => {
            onValidate(false)
            setDisplayName(event.target.value)
            setIsInputPristine(false)
            debounceValidation(event.target.value)
        },
        [debounceValidation, setDisplayName, onValidate]
    )

    const inputProps = useMemo(
        () => ({
            endAdornment: (
                <InputAdornment position="end">
                    <Fade in={validationLoading}>
                        <Stack>
                            <LeafCircularProgress color="accent" size={20} />
                        </Stack>
                    </Fade>
                </InputAdornment>
            )
        }),
        [validationLoading]
    )

    const textFieldComponent = useMemo(() => {
        const externalTextField = onCreateTextField?.(
            fullName,
            displayName ?? '',
            !!invalidDisplayNameText,
            inputProps,
            onChangeHandler
        )

        return externalTextField ? (
            externalTextField
        ) : (
            <StyledTextField
                id="outlined-basic"
                data-testid="display-name-text-field"
                label={formatMessage(messages.yourDisplayName)}
                defaultValue={fullName}
                value={displayName}
                error={!!invalidDisplayNameText}
                variant="filled"
                size="medium"
                fullWidth={true}
                onChange={onChangeHandler}
                InputProps={inputProps}
            />
        )
    }, [
        displayName,
        fullName,
        invalidDisplayNameText,
        inputProps,
        onChangeHandler,
        formatMessage,
        onCreateTextField
    ])

    useEffect(() => {
        if (isInputPristine && !displayName) {
            setDisplayName(String(fullName))
        }
    }, [isInputPristine, displayName, fullName, setDisplayName])

    useEffect(() => {
        if (altInvalidDisplayNameText) {
            setInvalidDisplayNameText(altInvalidDisplayNameText)
        }
    }, [altInvalidDisplayNameText])

    return (
        <Stack gap={1}>
            <Stack width={isMobile || fullWidth ? '100%' : 320}>
                {textFieldComponent}
            </Stack>
            <CoreTypography variant="caption">
                <FormattedMessage
                    defaultMessage="{ count } characters max."
                    description="description for maximum characters"
                    values={{ count: formatNumber(28) }}
                />
            </CoreTypography>
            <Collapse in={!!invalidDisplayNameText} mountOnEnter unmountOnExit>
                <CoreTypography variant="caption" color="error.main">
                    {invalidDisplayNameText}
                </CoreTypography>
            </Collapse>
            <Collapse in={error} mountOnEnter unmountOnExit>
                <CoreAlert severity="error">
                    <CoreTypography variant="h5">
                        <FormattedMessage
                            defaultMessage="Something went wrong"
                            description="description for an unexpected error"
                        />
                    </CoreTypography>
                </CoreAlert>
            </Collapse>
        </Stack>
    )
}

export default memo(DisplayNameWithValidation)
