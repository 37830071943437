import {
    Button,
    Dialog,
    DialogTitle,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

export type ViewMoreDialogProps = {
    open: boolean
    onClose: () => void
    children: React.ReactNode
}

const ViewMoreDialog: React.FC<ViewMoreDialogProps> = ({
    open,
    onClose,
    children
}) => {
    const { gradient, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const content = useMemo(
        () =>
            isMobile ? (
                children
            ) : (
                <PageLayout maxWidth="lg"> {children}</PageLayout>
            ),
        [children, isMobile]
    )

    return (
        <Dialog
            open={open}
            fullScreen={true}
            onClose={onClose}
            PaperProps={{
                sx: {
                    background: gradient.main,
                    py: 1
                }
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Button
                        onClick={onClose}
                        startIcon={
                            <LeafIcon icon="arrow-left" fontSize="small" />
                        }
                    >
                        <CoreTypography customVariant="buttonLarge">
                            <FormattedMessage
                                defaultMessage="Back"
                                description="back button"
                            />
                        </CoreTypography>
                    </Button>
                    <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                    >
                        <LeafIcon icon="close" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <PageLayout maxWidth="lg">
                <Stack py={2}>{content}</Stack>
            </PageLayout>
        </Dialog>
    )
}

export default memo(ViewMoreDialog)
