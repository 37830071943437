export enum PlantIcons {
    CommunityPlant1 = 'plant.community.1',
    CommunityPlant2 = 'plant.community.2',
    CommunityPlant3 = 'plant.community.3',
    CommunityPlant4 = 'plant.community.4',
    CommunityPlant5 = 'plant.community.5',
    CommunityPlant6 = 'plant.community.6',
    CommunityPlant7 = 'plant.community.7',
    CommunityPlant8 = 'plant.community.8',
    CommunityPlant9 = 'plant.community.9',
    CommunityPlant10 = 'plant.community.10',
    CommunityPlant11 = 'plant.community.11',
    CommunityPlant12 = 'plant.community.12',
    CommunityPlant13 = 'plant.community.13',
    CommunityPlant14 = 'plant.community.14',
    CommunityPlant15 = 'plant.community.15',
    CommunityPlant16 = 'plant.community.16',
    CommunityPlant17 = 'plant.community.17',
    CommunityPlant18 = 'plant.community.18',
    CommunityCherryBlossomPlant = 'plant.community.cherryblossom'
}
