import { Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ModifiedFormattedMessageType } from 'src/types'
import {
    ChallengeUserDailyGoal,
    UnifiedChallenge,
    useCompleteCommunityChallengeDailyGoalMutation
} from '../../../../../../../graphql/generated/autogenerated'
import useGetDailyCheckIn from '../../../../../../hooks/useGetDailyCheckIn/useGetDailyCheckIn'
import { defaultDailyCheckInSelectedProps } from '../../../../../../utils/defaultTrackingProps'
import ContentCard from '../../../../../elements/ContentCard'
import CompletedGoal from '../../CompletedGoal'
import GoalTitle from '../../GoalTitle'

const ModifiedFormattedMessage =
    FormattedMessage as ModifiedFormattedMessageType

const messages = defineMessages({
    viewCheckIn: {
        defaultMessage: 'View Daily Check-In',
        description: 'aria status for the daily check-in goal card'
    }
})

export type DailyCheckInGoalProps = {
    challenge: UnifiedChallenge | undefined
    isTemplate?: boolean
    goal: ChallengeUserDailyGoal
    disabled?: boolean
    onComplete?: () => void
}

const DailyCheckInGoal: React.FC<DailyCheckInGoalProps> = ({
    challenge,
    isTemplate,
    goal,
    disabled,
    onComplete
}) => {
    const { data, loading, responded } = useGetDailyCheckIn()
    const { formatMessage } = useIntl()
    const navigate = useCrossAppNavigation()

    const [completeDailyGoal] = useCompleteCommunityChallengeDailyGoalMutation({
        variables: {
            challengeId: challenge?.id,
            challengeGoalId: goal?.challengeGoal?.id
        }
    })

    useEffect(() => {
        if (data && !loading && responded && !goal.completed && !isTemplate) {
            completeDailyGoal()
        }
    }, [data, loading, completeDailyGoal, responded, isTemplate, goal])

    const { survey, completed } = useMemo(() => {
        return {
            survey: data?.pulse?.surveys?.requestCheckIn?.survey,
            completed: goal?.completed
        }
    }, [data, goal])

    const handleNavigate = useCallback(() => {
        Avo.dciSelected({
            ...defaultDailyCheckInSelectedProps,
            activityType: 'daily_activity_completed',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            dailyCheckInId: survey?.id,
            featureType: 'challenge'
        })
        onComplete?.()
        navigate('/journeys')
    }, [navigate, onComplete, survey, challenge])

    return (
        <Stack gap={1} height="100%" data-testid="daily-check-in-goal">
            <GoalTitle
                title={
                    <FormattedMessage
                        defaultMessage="Complete your daily check-in"
                        description="title for the microstep daily goal card"
                    />
                }
            />

            {completed ? (
                <CompletedGoal
                    content={
                        <CoreTypography
                            variant="body1"
                            color="text.disabled"
                            component="output"
                            aria-live="polite"
                        >
                            <ModifiedFormattedMessage
                                id="message.completedDailyCheckIn"
                                defaultMessage="Completed the Daily Check-In: <b>{title}</b>"
                                description="title for the activity daily goal card"
                                values={{
                                    title: survey?.label,
                                    b: (chunks: React.ReactNode[]) => (
                                        <strong>{chunks}</strong>
                                    )
                                }}
                            />
                        </CoreTypography>
                    }
                />
            ) : (
                <ContentCard
                    elevation={1}
                    title={survey?.label}
                    loading={loading}
                    disabled={disabled}
                    cta={{
                        label: formatMessage(messages.viewCheckIn),
                        onClick: handleNavigate
                    }}
                />
            )}
        </Stack>
    )
}

export default DailyCheckInGoal
