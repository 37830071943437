import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import RadioList from '../../../../../shared/components/elements/RadioList/RadioList'
import { useSocialActivityContext } from '../../../../../shared/modules/social/hooks/useSocialActivityProvider/useSocialActivityContext'
import TeamMembersProgressList from '../TeamMembersProgressList'
import NotStartedChallengeActivityFeed from '../NotStartedChallengeActivityFeed'

import { SocialGroup } from '../../../../../graphql/generated/autogenerated'
import SocialActivityFeed from '../SocialActivityFeed'

const messages = defineMessages({
    feed: {
        defaultMessage: 'Feed',
        description: 'feed radio item'
    },
    goals: {
        defaultMessage: 'Goals',
        description: 'goals radio item'
    }
})

export enum ActivityType {
    Feed,
    Goals
}

interface ChallengeTeamActivityProps {
    challengeStarted: boolean
    loading: boolean
    socialGroup: SocialGroup
}

const ChallengeTeamActivity: React.FC<ChallengeTeamActivityProps> = ({
    challengeStarted,
    loading,
    socialGroup
}) => {
    const { formatMessage } = useIntl()
    const [activityType, setActivityType] = useState<ActivityType>(
        ActivityType.Feed
    )

    const { setSocialGroupId } = useSocialActivityContext()

    const getActivityComponent = useMemo(() => {
        const activityComponents = {
            [ActivityType.Feed]: challengeStarted ? (
                <SocialActivityFeed />
            ) : (
                <NotStartedChallengeActivityFeed
                    loading={loading}
                    hideTitle={true}
                />
            ),
            [ActivityType.Goals]: <TeamMembersProgressList />
        }

        return activityComponents[activityType]
    }, [activityType, challengeStarted, loading])

    useEffect(() => {
        if (socialGroup) {
            setSocialGroupId?.(socialGroup.id)
        }
    }, [socialGroup, setSocialGroupId])

    return (
        <Card elevation={0}>
            <Stack p={2} gap={1}>
                <CoreTypography variant="overline" color="text.disabled">
                    <FormattedMessage
                        defaultMessage="Team Activity"
                        description="description for the challenge team activity card"
                    />
                </CoreTypography>
                <Stack gap={2}>
                    <RadioList
                        onChange={(type) =>
                            setActivityType(type as ActivityType)
                        }
                        defaultValue={activityType}
                        excludeSpacing={true}
                        row={true}
                        list={[
                            {
                                value: ActivityType.Feed,
                                title: formatMessage(messages.feed)
                            },
                            {
                                value: ActivityType.Goals,
                                title: formatMessage(messages.goals)
                            }
                        ]}
                    />
                    {getActivityComponent}
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeTeamActivity)
