import { defineMessages } from 'react-intl'

export const SocialGroupErrorMessages = defineMessages({
    nameNotAllowed: {
        defaultMessage: 'Name not allowed.',
        description:
            'error message for when there is an error automatically planting in garden'
    }
})

export const SocialGroupErrors = {
    ERROR_NAME_NOT_ALLOWED: {
        code: 'Name not allowed.',
        message: SocialGroupErrorMessages.nameNotAllowed
    }
}
