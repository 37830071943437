import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'
import QuoteIcon from '../../../../../shared/components/icons/Quote'
import { ReactNullValue } from '../../../../../shared/utils/Nulls'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'

interface ChallengeQuoteProps {
    challenge?: UnifiedChallenge | null
    loading: boolean
}

const ChallengeQuote: React.FC<ChallengeQuoteProps> = ({
    challenge,
    loading
}) => {
    const day = challenge?.dayOfChallenge
    const activeQuote = useMemo(
        () =>
            day && day > 0
                ? challenge?.quotes?.find((quote) => quote.day === day)
                : challenge?.quotes?.find((quote) => quote.day === 1),
        [challenge, day]
    )

    if (!activeQuote || loading) return ReactNullValue

    return (
        <Stack alignItems="center" justifyContent="center">
            <Stack
                alignItems="center"
                justifyContent="center"
                position="relative"
                maxWidth={672}
                width="100%"
            >
                <Stack
                    height={42.57}
                    width={46.37}
                    position="absolute"
                    top={-24}
                    left={-19}
                >
                    <QuoteIcon />
                </Stack>
                <Stack gap={2} alignContent="center" alignItems="center">
                    <CoreTypography
                        variant="body1"
                        fontSize={24}
                        textAlign="center"
                        lineHeight={1.6}
                    >
                        {activeQuote?.body}
                    </CoreTypography>
                    <CoreTypography
                        variant="body2"
                        fontSize={14}
                        fontWeight={400}
                        textAlign="center"
                    >
                        {activeQuote?.author}
                    </CoreTypography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default memo(ChallengeQuote)
