import { useAppSelector } from '@thriveglobal/thrive-web-core'
import React, { memo, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LeaderboardTable from '../../../../elements/LeaderboardTable'
import { useGetUsersLeaderboardQuery } from '../../../../../../graphql/generated/autogenerated'
import RequestorRank from '../../../../elements/RequestorRank'

type UserActivityLeaderboardProps = {
    deviceEnabled: boolean
    challengeId: string
    userActivityRank?: number
    userActivityTotalPoints?: number
    userActivityRankDisplayName?: string
}

const messages = defineMessages({
    rank: {
        defaultMessage: 'Rank',
        description: 'Table header for rank'
    },
    user: {
        defaultMessage: 'User',
        description: 'Table header for team'
    },
    activityPoints: {
        defaultMessage: 'Activity Points',
        description: 'Table header for activity Points'
    },
    soloActivity: {
        defaultMessage: 'Solo Activity',
        description: 'Leaderboard title for Activity'
    }
})

const ROWS_PER_PAGE = 10

const UserActivityLeaderboard: React.FC<UserActivityLeaderboardProps> = ({
    deviceEnabled,
    challengeId,
    userActivityRank,
    userActivityTotalPoints,
    userActivityRankDisplayName
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const [offset, setOffset] = useState(0)

    const { loading, data: userLeaderboardData } = useGetUsersLeaderboardQuery({
        variables: {
            challengeId,
            offset,
            limit: ROWS_PER_PAGE
        },
        skip: !deviceEnabled
    })

    const scores = useMemo(() => {
        return userLeaderboardData?.unifiedChallenges?.getUsersLeaderboard
            ?.rankedList
    }, [userLeaderboardData])

    const requesterRank = useMemo(() => {
        return scores?.find((score) => score?.entityId === userId)?.rank
    }, [scores, userId])

    const anchorIndex = useMemo(
        () =>
            scores?.findIndex(
                (score) =>
                    score?.rank === requesterRank && score?.entityId === userId
            ),
        [scores, requesterRank, userId]
    )

    const totalEntries = useMemo(() => {
        return userLeaderboardData?.unifiedChallenges?.getUsersLeaderboard
            ?.totalEntries
    }, [userLeaderboardData])

    const maximumOffsetLoaded = useMemo(() => offset + ROWS_PER_PAGE, [offset])

    const hasMore = useMemo(
        () => (totalEntries || 0) > maximumOffsetLoaded,
        [totalEntries, maximumOffsetLoaded]
    )

    return (
        <LeaderboardTable
            loading={loading}
            loadingNext={loading}
            userRank={
                <RequestorRank
                    columnDataTitle={formatMessage(messages.user)}
                    columnDataPointsTitle={formatMessage(
                        messages.activityPoints
                    )}
                    displayName={userActivityRankDisplayName}
                    rank={userActivityRank}
                    points={userActivityTotalPoints}
                />
            }
            title={formatMessage(messages.soloActivity)}
            columnData={[
                {
                    title: formatMessage(messages.rank),
                    width: '20%',
                    valueName: 'rank',
                    showAnchor: (value, entityId) =>
                        value === requesterRank && entityId === userId
                },
                {
                    title: formatMessage(messages.user),
                    valueName: 'requesterDisplayName'
                },
                {
                    title: formatMessage(messages.activityPoints),
                    valueName: 'totalSteps',
                    transform: (value) => formatNumber(value)
                }
            ]}
            values={scores ?? []}
            requesterRank={requesterRank}
            totalEntries={totalEntries}
            anchorOnIndex={anchorIndex}
            rowsPerPage={ROWS_PER_PAGE}
            elevation={0}
            onNavigate={(value) => {
                setOffset(value ? offset + 1 : offset - 1)
            }}
            disableNext={!hasMore}
            disablePrev={offset === 0}
        />
    )
}

export default memo(UserActivityLeaderboard)
