import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import { Box, Button } from '@mui/material'
import {
    useMultiStepFormContext,
    withMultiStepForm
} from '../../../../../contexts/withMultiStepForm/withMultiStepForm'
import { SIGNUP_VARIANT } from '../../../../../constants'
import MultiStepFormNavigation from '../../../Shared/MultiStepForm/MultiStepFormNavigation'
import IntroSignupCompanyChallengeDialogSteps from './IntroSignupCompanyChallengeDialogSteps'
import useCompanyChallengeSignup from '../../../../../hooks/useCompanyChallengeSignup/useCompanyChallengeSignup'
import useChallengeTheme from '../../../../../hooks/useChallengeTheme/useChallengeTheme'
import PrivacyInfoStep from '../Steps/PrivacyInfoStep'
import useGetSocialGroupById from '../../../../../hooks/useGetSocialGroupById/useGetSocialGroupById'
import type { RefetchUnifiedChallengeAvailability } from '../../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import { UnifiedChallenge } from '../../../../../../graphql/generated/autogenerated'

type IntroJoinCompanyChallengeDialogProps = {
    open: boolean
    onClose: () => void
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
    challenge?: UnifiedChallenge
}

const IntroSignupCompanyChallengeDialog: React.FC<
    IntroJoinCompanyChallengeDialogProps
> = ({ open, onClose, refetchUnifiedChallengeAvailability, challenge }) => {
    const {
        formData,
        updateFormData,
        setIsDisplayNameValid,
        loading,
        onSubmit
    } = useCompanyChallengeSignup()
    const { socialGroupId } = useParams<{
        socialGroupId: string
    }>()

    const { data: socialGroup } = useGetSocialGroupById({
        challengeId: challenge?.id,
        socialGroupId
    })

    const { deviceEnabled } = useChallengeTheme(challenge)
    const { setCurrentStepName } = useMultiStepFormContext()

    const [showPrivacyInfo, setShowPrivacyInfo] = useState(false)

    useEffect(() => {
        if (socialGroup) {
            updateFormData({
                signupVariant: SIGNUP_VARIANT.INVITE_TEAM,
                joinTeam: { id: socialGroup.id, name: socialGroup.name }
            })
        }
    }, [socialGroup])

    if (!challenge) return null

    return (
        <LeafDialog
            open={open}
            onClose={() => {
                if (showPrivacyInfo) {
                    setShowPrivacyInfo(false)
                    return
                }
                onClose()
            }}
            maxWidth="md"
            fullWidth
            dialogTitle={
                showPrivacyInfo ? (
                    <FormattedMessage
                        defaultMessage="Learn more"
                        description="title for the privacy information popup"
                    />
                ) : (
                    challenge.name
                )
            }
        >
            {showPrivacyInfo && (
                <Box sx={{ pb: 10 }}>
                    <PrivacyInfoStep deviceEnabled={deviceEnabled} />
                </Box>
            )}
            {!showPrivacyInfo && (
                <>
                    <IntroSignupCompanyChallengeDialogSteps
                        challenge={challenge}
                        formData={formData}
                        updateFormData={updateFormData}
                        setCurrentStepName={setCurrentStepName}
                        setIsDisplayNameValid={setIsDisplayNameValid}
                        socialGroup={socialGroup}
                    />
                    <MultiStepFormNavigation
                        startActions={
                            <Button
                                data-testid="privacy-button"
                                variant="text"
                                onClick={() => setShowPrivacyInfo(true)}
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        defaultMessage="Learn More"
                                        description="button text for the learn more popup"
                                    />
                                </CoreTypography>
                            </Button>
                        }
                        loading={loading}
                        onSubmit={async () => {
                            await onSubmit()
                            await refetchUnifiedChallengeAvailability()
                            onClose()
                        }}
                        onSubmitButtonLabel={
                            <FormattedMessage
                                defaultMessage="Confirm and join Challenge"
                                description="Confirm and join Challenge"
                            />
                        }
                    />
                </>
            )}
        </LeafDialog>
    )
}

export default withMultiStepForm(IntroSignupCompanyChallengeDialog)
