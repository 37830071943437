import { ComponentType, useCallback, useMemo, useState } from 'react'
import { SignUpInterstitialWithProvider } from '../../components/features/CompanyChallenge/SignUp/SignUpInterstitial'
import { SignUpTypes } from '../../enums'
import {
    SocialGroup,
    UnifiedChallenge
} from '../../graphql/generated/autogenerated'
import ChallengeSignUpModalProviderContext, {
    ChallengeSignUpModalValue
} from './challengeSignUpModalProviderContext'

export default function withChallengeSignUpModalProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [open, setOpen] = useState(false)
        const [modalVisible, setModalVisible] = useState(false)
        const [isInvite, setIsInvite] = useState(false)
        const [displayName, setDisplayName] = useState<string>()
        const [challenge, setChallenge] = useState<UnifiedChallenge>()
        const [inviteSocialGroup, setInviteSocialGroup] =
            useState<SocialGroup>()
        const [excludeSocialGroups, setExcludeSocialGroups] = useState<
            string[]
        >([])
        const [signUpType, setSignUpType] = useState<SignUpTypes>(
            SignUpTypes.solo
        )
        const [isChangeParticipation, setIsChangeParticipation] =
            useState(false)
        const [onComplete, setOnComplete] = useState<{
            onComplete: () => Promise<any>
        }>({ onComplete: () => Promise.resolve(true) })

        const onOpen = useCallback((isInvite?: boolean) => {
            setModalVisible(true)
            setOpen(true)
            setIsInvite(!!isInvite)
        }, [])

        const handleOnClose = useCallback(() => {
            setOpen(false)

            setTimeout(() => {
                setModalVisible(false)
            }, 500)
        }, [])

        const state = useMemo<ChallengeSignUpModalValue>(
            () => ({
                signUpType,
                displayName,
                isChangeParticipation,
                excludeSocialGroups,
                inviteSocialGroup,
                isInvite,
                setDisplayName,
                setChallenge,
                setInviteSocialGroup,
                onOpen,
                setOnComplete,
                setSignUpType,
                setIsChangeParticipation,
                setExcludeSocialGroups
            }),
            [
                signUpType,
                displayName,
                isChangeParticipation,
                excludeSocialGroups,
                inviteSocialGroup,
                isInvite,
                setDisplayName,
                setChallenge,
                setInviteSocialGroup,
                onOpen,
                setOnComplete,
                setSignUpType,
                setIsChangeParticipation,
                setExcludeSocialGroups
            ]
        )

        return (
            <ChallengeSignUpModalProviderContext.Provider value={state}>
                <>
                    {modalVisible && (
                        <SignUpInterstitialWithProvider
                            open={open}
                            challenge={challenge}
                            onComplete={onComplete.onComplete}
                            handleOnClose={handleOnClose}
                        />
                    )}
                    <Component {...props} />
                </>
            </ChallengeSignUpModalProviderContext.Provider>
        )
    }
}
