import { Button, Stack, useMediaQuery } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useGoalCompletedLocalState from '../../../../contexts/withGoalsCompleted/useGoalsCompletedLocalState'
import GoalChallengeSettings from '../GoalChallengeSettings'
import LeaveChallengeButton from '../LeaveChallengeButton'
import UpdateTeamVisibility from './UpdateTeamVisibility'

import type {
    SocialGroup,
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../graphql/generated/autogenerated'
import { SIGNUP_VARIANT, SIGNUP_VARIANT_TYPE } from '../../../../constants'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import type { SocialGroupsValues } from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'

export interface CompanyChallengeSettingsProps {
    open: boolean
    onClose: () => void
    challenge?: UnifiedChallenge
    socialGroups: SocialGroupsValues
    participation?: UnifiedChallengeActivity
    onChangeParticipation: (participationVariant: SIGNUP_VARIANT_TYPE) => void
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const messages = defineMessages({
    yourProgress: {
        defaultMessage:
            'Your progress will be lost if you leave the Challenge. If you want to participate as an individual or join another team, select leave Challenge and sign up again.',
        description: 'description on what leaving this challenge will cause'
    },
    joinATeam: {
        defaultMessage: 'Join a team',
        description: 'button description for a solo participant to join a team'
    },
    changeTeam: {
        defaultMessage: 'Join a different team',
        description: 'button description to change team'
    },
    changeToSolo: {
        defaultMessage: 'Participate individually',
        description: 'button description to change to solo'
    }
})

const CompanyChallengeSettings: React.FC<CompanyChallengeSettingsProps> = ({
    open,
    onClose,
    challenge,
    socialGroups,
    participation,
    onChangeParticipation,
    refetchUnifiedChallengeAvailability
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { formatMessage } = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const [activeSettingChange, setActiveSettingChange] = useState(false)
    const { deleteAllCompletedState } = useGoalCompletedLocalState()

    const { socialGroup } = socialGroups

    const isAdmin = useMemo(
        () => socialGroup?.createdBy === userId,
        [userId, socialGroup]
    )

    return (
        <GoalChallengeSettings
            open={open}
            challenge={challenge as UnifiedChallenge}
            socialGroups={socialGroups}
            onClose={onClose}
            refetchUnifiedChallengeAvailability={
                refetchUnifiedChallengeAvailability
            }
            settings={
                <Stack gap={4}>
                    <Stack direction={isMobile ? 'column' : 'row'} gap={2}>
                        <Button
                            variant="outlined"
                            disabled={activeSettingChange}
                            onClick={() => {
                                onClose()
                                onChangeParticipation(SIGNUP_VARIANT.JOIN_TEAM)
                            }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {formatMessage(
                                    socialGroup?.isSoloChallenge
                                        ? messages.joinATeam
                                        : messages.changeTeam
                                )}
                            </CoreTypography>
                        </Button>
                        {!socialGroup?.isSoloChallenge && (
                            <Button
                                variant="outlined"
                                disabled={activeSettingChange}
                                onClick={() => {
                                    onClose()
                                    onChangeParticipation(SIGNUP_VARIANT.SOLO)
                                }}
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    {formatMessage(messages.changeToSolo)}
                                </CoreTypography>
                            </Button>
                        )}
                    </Stack>
                    {isAdmin && !socialGroup?.isSoloChallenge && (
                        <UpdateTeamVisibility
                            disabled={activeSettingChange}
                            socialGroup={socialGroup as SocialGroup}
                            refetchSocialGroups={
                                socialGroups.socialGroupsRefetch
                            }
                        />
                    )}

                    {/* Add list in once a remove from group mutation exists */}
                    {/* {!socialGroup?.isSoloChallenge && <ParticipantsListWithProvider />} */}
                </Stack>
            }
            action={
                <LeaveChallengeButton
                    onLeave={() => {
                        Avo.challengeLeft({
                            activityType: 'challenge_left',
                            challengeId: challenge?.id,
                            challengeTheme: challenge?.theme,
                            challengeType: challenge?.challenge_type,
                            dailyGoalsCompleted: null,
                            dayNumber: null,
                            featureType: 'challenge',
                            teamId: socialGroup?.id,
                            teamType: socialGroup?.isSoloChallenge
                                ? 'individual'
                                : 'team'
                        })
                        deleteAllCompletedState(challenge?.id)
                        onClose()
                    }}
                    disabled={activeSettingChange}
                    onLeaving={setActiveSettingChange}
                    description={formatMessage(messages.yourProgress)}
                    userChallengeActivityId={participation?.id}
                    refetchChallenge={async () =>
                        await refetchUnifiedChallengeAvailability()
                    }
                />
            }
        />
    )
}

export default memo(CompanyChallengeSettings)
