import { Box, Fade, OutlinedInput, Stack } from '@mui/material'
import {
    ReferralEmailRequestBody,
    ReferralType,
    SendReferralEmailResultType,
    sendReferralEmail,
    useAppSelector,
    useBranchReferralLink
} from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTypography,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { GQLNull } from '../../../../shared/utils/Nulls'
import BulletList from '../BulletList'
import ConditionalSkeleton from '../ConditionalSkeleton'

const messages = defineMessages({
    invalidEmails: {
        defaultMessage:
            'The following emails are invalid, please correct them and try again:',
        description:
            'Text shown when a user tries to send a Thrive invite using emails that are not formatted correctly'
    },
    erroredEmails: {
        defaultMessage:
            'We where unable to send an invite link for the following emails:',
        description:
            'Text shown when a user tries to send a Thrive invite to an email or emails and there was an error'
    },
    somethingWentWrong: {
        defaultMessage:
            'Something went wrong, We were unable to send an invite link',
        description:
            'Text shown when a user tries to send and it fails for an unspecified reason'
    },
    sentSuccessfully: {
        defaultMessage: 'Invite sent for the following emails:',
        description:
            'Text shown when a user tries to send and it fails for an unspecified reason'
    }
})

export interface EmailInviteInputProps {
    disabled?: boolean
    companyName?: string | GQLNull
    referrerName?: string | GQLNull
    redirectUrl: string
    ariaLabel: string
    onEmailError: (error: boolean) => void
    onEmailsSent?: (emails: string[]) => void
}

const EmailInviteInput: React.FC<EmailInviteInputProps> = ({
    disabled,
    companyName,
    referrerName,
    redirectUrl,
    ariaLabel,
    onEmailError,
    onEmailsSent
}) => {
    const { formatMessage } = useIntl()
    const { userId, companyId } = useAppSelector((state) => state.user)
    const [emailLoading, setEmailLoading] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [successfulEmails, setSuccessfulEmails] = useState<string[]>([])
    const [invalidEmails, setInvalidEmails] = useState<string[]>([])
    const [erroredEmails, setErroredEmails] = useState<string[]>([])
    const [emailValues, setEmailValues] = useState<string>('')
    const {
        error: branchError,
        loading: branchLoading,
        coworkerReferral
    } = useBranchReferralLink(
        companyName as string,
        referrerName as string,
        redirectUrl
    )

    const isValidEmail = (email: string) => {
        const emailRegex =
            /^[\w-]+(\.[\w-]+)*(\+[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/

        return emailRegex.test(email)
    }

    const getEmails = useCallback(
        () =>
            emailValues
                .split(',')
                .map((email) => email.replace(' ', ''))
                .filter((email) => !!email),
        [emailValues]
    )

    const sendEmails = useCallback(
        async (emails: string[]) => {
            const requestBody = {
                emailAddresses: emails,
                coworkerReferralLink: coworkerReferral?.link,
                allowExistingUsers: true,
                clientId: 'frontend',
                companyId,
                referralType: ReferralType.Challenge
            } as ReferralEmailRequestBody

            return sendReferralEmail(requestBody)
                .then((resp) => {
                    if (resp.status === SendReferralEmailResultType.Success) {
                        setErroredEmails(resp?.data?.failedToSendEmailTo || [])
                        setSuccessfulEmails(
                            emails.filter(
                                (email) =>
                                    !resp?.data?.failedToSendEmailTo?.includes(
                                        email
                                    )
                            ) || []
                        )
                        onEmailsSent?.(emails)
                    } else {
                        setEmailError(true)
                    }
                })
                .catch(() => {
                    setEmailError(true)
                })
                .finally(() => {
                    setEmailLoading(false)
                })
        },
        [coworkerReferral, companyId, onEmailsSent]
    )

    const onSendEmails = useCallback(() => {
        setEmailLoading(true)
        setEmailError(false)
        setInvalidEmails([])
        setErroredEmails([])
        setSuccessfulEmails([])

        const emailArray = getEmails()
        const invalidEmails = emailArray.filter((email) => !isValidEmail(email))

        if (invalidEmails?.length > 0) {
            setInvalidEmails(invalidEmails)
            setEmailLoading(false)
        } else {
            sendEmails(emailArray)
        }
    }, [getEmails, sendEmails])

    const getEmailError = useCallback(
        (error: any, emails?: string[], severity: any = 'error') => (
            <Fade in>
                <Box>
                    <CoreAlert severity={severity}>
                        <CoreTypography variant="h5">
                            {formatMessage(error)}
                        </CoreTypography>
                        {emails && (
                            <BulletList
                                list={emails.map((email) => (
                                    <CoreTypography variant="body2">
                                        {email}
                                    </CoreTypography>
                                ))}
                            />
                        )}
                    </CoreAlert>
                </Box>
            </Fade>
        ),
        [formatMessage]
    )

    useEffect(() => {
        if (branchError) {
            onEmailError(!!branchError)
        }
    }, [branchError, onEmailError])

    return (
        <Stack gap={2}>
            <Stack direction="row" gap={1}>
                <ConditionalSkeleton
                    showSkeleton={branchLoading}
                    variant="rounded"
                    sx={{
                        width: '100%',
                        maxWidth: '684px'
                    }}
                >
                    <OutlinedInput
                        disabled={disabled}
                        fullWidth
                        sx={{
                            width: '100%',
                            maxWidth: '684px'
                        }}
                        value={emailValues}
                        onChange={(event) =>
                            setEmailValues(event.target.value as string)
                        }
                    />
                </ConditionalSkeleton>
                <ConditionalSkeleton
                    showSkeleton={branchLoading}
                    variant="rounded"
                >
                    <LoadingButton
                        loading={emailLoading}
                        onClick={onSendEmails}
                        disabled={emailLoading || !emailValues || disabled}
                        fixWidth={true}
                        aria-label={ariaLabel}
                        variant="outlined"
                        data-testid="send-invite"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Send"
                                description="send email referral button text"
                            />
                        </CoreTypography>
                    </LoadingButton>
                </ConditionalSkeleton>
            </Stack>
            {successfulEmails?.length > 0 &&
                getEmailError(
                    messages.sentSuccessfully,
                    successfulEmails,
                    'success'
                )}
            {invalidEmails?.length > 0 &&
                getEmailError(messages.invalidEmails, invalidEmails)}
            {erroredEmails?.length > 0 &&
                getEmailError(messages.erroredEmails, erroredEmails)}
            {emailError && getEmailError(messages.somethingWentWrong)}
        </Stack>
    )
}

export default memo(EmailInviteInput)
