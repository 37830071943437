import { GoalChallengeContextType } from '../../../enums/goalChallengeContextType'
import { useGetGoalChallengeContext } from '../withGoalChallengeContextProvider/useGetGoalChallengeContext'
import {
    GoalChallengeDataValue,
    defaultContextValue
} from './goalChallengeDataContext'

export const useGoalChallengeDataProviderContext =
    (): GoalChallengeDataValue => {
        const context = useGetGoalChallengeContext<GoalChallengeDataValue>(
            GoalChallengeContextType.GoalChallengeData,
            defaultContextValue
        )

        return context
    }
