import {
    Card,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import LeaderboardTableSkeleton from './LeaderboardTableSkeleton'

const tableRowStyle = {
    '&:last-child td, &:last-child th': {
        border: 0
    }
}

const messages = defineMessages({
    currentUserRankInfo: {
        defaultMessage:
            'On the {title} leaderboard you are rank {requesterRank} out of {totalEntries}',
        description:
            'description of the users current rank within the leaderboard'
    },
    noRankInfo: {
        defaultMessage: 'On the {title} leaderboard you do not have a rank',
        description:
            'leaderboard description when the user does not have a rank'
    }
})

export type ColumnData = {
    title: string
    width?: string
    valueName: string
    showAnchor?: (value: any, entityId: string) => boolean
    transform?: (value: any) => any
}

export interface LeaderboardTableProps {
    columnData: ColumnData[]
    values: any[]
    title: string
    anchorOnIndex?: number
    requesterRank?: number
    rowsPerPage?: number
    totalEntries?: number
    loading?: boolean
    loadingNext?: boolean
    disableNext?: boolean
    disablePrev?: boolean
    elevation?: number
    userRank?: React.ReactNode
    onNavigate?: (next: boolean) => void
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({
    columnData,
    values,
    title,
    anchorOnIndex = 0,
    requesterRank,
    rowsPerPage = 10,
    totalEntries,
    loading,
    loadingNext,
    disableNext,
    disablePrev,
    elevation,
    userRank,
    onNavigate
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const theme = useTheme()
    const [page, setPage] = useState(
        anchorOnIndex >= 0 ? Math.floor(anchorOnIndex / rowsPerPage) : 0
    )

    const handleOnNavigate = useCallback(
        (next: boolean) => {
            setPage((page) => (page > 0 || next ? page + (next ? 1 : -1) : 0))
            onNavigate?.(next)
        },
        [onNavigate]
    )

    const slicedValues = useMemo(() => {
        return values.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )
    }, [values, page, rowsPerPage])

    const [offsetStart, offsetEnd] = useMemo(() => {
        return [
            slicedValues?.[0]?.rank,
            slicedValues?.length >
            slicedValues?.[slicedValues?.length - 1]?.rank
                ? slicedValues?.length
                : slicedValues?.[slicedValues?.length - 1]?.rank
        ]
    }, [slicedValues])

    useEffect(() => {
        if (loading) {
            setPage(
                anchorOnIndex >= 0 ? Math.floor(anchorOnIndex / rowsPerPage) : 0
            )
        }
    }, [loading, anchorOnIndex, rowsPerPage])

    return (
        <TableContainer component={Card} elevation={elevation}>
            {!!userRank && userRank}

            {loading || loadingNext ? (
                <LeaderboardTableSkeleton
                    loadingNext={loadingNext}
                    columnData={columnData}
                    rowsPerPage={rowsPerPage}
                />
            ) : (
                <Table
                    aria-label={
                        requesterRank
                            ? formatMessage(messages.currentUserRankInfo, {
                                  title,
                                  requesterRank,
                                  totalEntries
                              })
                            : formatMessage(messages.noRankInfo, { title })
                    }
                >
                    <TableHead>
                        <TableRow>
                            {columnData.map((column, index: number) => (
                                <TableCell
                                    key={index}
                                    width={column.width}
                                    {...(index + 1 === columnData.length && {
                                        align: 'right'
                                    })}
                                >
                                    <CoreTypography
                                        variant="h5"
                                        color="primary"
                                        component="p"
                                    >
                                        {column.title}
                                    </CoreTypography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? slicedValues : values)?.map(
                            (row, index: number) => (
                                <TableRow key={index} sx={tableRowStyle}>
                                    {columnData?.map(
                                        (column, _index: number) => {
                                            const showAnchor =
                                                column.showAnchor &&
                                                column.showAnchor(
                                                    row[column.valueName],
                                                    row['entityId']
                                                )
                                            const transformedValue =
                                                column.transform
                                                    ? column.transform(
                                                          row[column.valueName]
                                                      )
                                                    : row[column.valueName]

                                            return (
                                                <TableCell
                                                    key={_index}
                                                    {...(_index + 1 ===
                                                        columnData?.length && {
                                                        align: 'right'
                                                    })}
                                                >
                                                    {showAnchor ? (
                                                        <Stack
                                                            direction="row"
                                                            data-testid="anchor-icon"
                                                            sx={{
                                                                ...(showAnchor && {
                                                                    ml: theme.spacing(
                                                                        -2.5
                                                                    )
                                                                })
                                                            }}
                                                        >
                                                            <LeafIcon
                                                                icon="caret-right"
                                                                iconStyle="solid"
                                                                color="primary"
                                                            />
                                                            <CoreTypography variant="body1">
                                                                {
                                                                    transformedValue
                                                                }
                                                            </CoreTypography>
                                                        </Stack>
                                                    ) : (
                                                        <CoreTypography variant="body1">
                                                            {transformedValue}
                                                        </CoreTypography>
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    )}
                                </TableRow>
                            )
                        )}
                    </TableBody>
                    {values?.length > 0 && (
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    align="right"
                                    sx={{ borderBottomWidth: 0 }}
                                >
                                    <Stack
                                        gap={2}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="end"
                                        minWidth="100%"
                                    >
                                        <Stack>
                                            <CoreTypography variant="body2">
                                                <FormattedMessage
                                                    defaultMessage={
                                                        '{offsetStart}-{offsetEnd} of {totalEntries}'
                                                    }
                                                    description="description for pagination"
                                                    values={{
                                                        offsetStart:
                                                            formatNumber(
                                                                offsetStart
                                                            ),
                                                        offsetEnd:
                                                            formatNumber(
                                                                offsetEnd
                                                            ),
                                                        totalEntries:
                                                            formatNumber(
                                                                Number(
                                                                    totalEntries
                                                                )
                                                            )
                                                    }}
                                                />
                                            </CoreTypography>
                                        </Stack>
                                        <Stack gap={2} direction="row">
                                            <IconButton
                                                size="small"
                                                disabled={disablePrev}
                                                variant="text"
                                                data-testid="leaderboard-prev"
                                                onClick={() =>
                                                    handleOnNavigate(false)
                                                }
                                            >
                                                <LeafIcon icon="chevron-left" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={disableNext}
                                                variant="text"
                                                data-testid="leaderboard-next"
                                                onClick={() =>
                                                    handleOnNavigate(true)
                                                }
                                            >
                                                <LeafIcon icon="chevron-right" />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            )}

            {values?.length === 0 && (
                <Stack p={2}>
                    <CoreTypography
                        variant="h5"
                        color="text.disabled"
                        component="p"
                    >
                        <FormattedMessage
                            defaultMessage="No data available"
                            description="description for pagination"
                        />
                    </CoreTypography>
                </Stack>
            )}
        </TableContainer>
    )
}

export default memo(LeaderboardTable)
