import { Box, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SuccessLoadingButton from '../../../elements/SuccessLoadingButton'
import TeamVisibility from '../../../elements/TeamVisibility'
import {
    useEditSocialGroupMutation,
    type SocialGroup
} from '../../../../../graphql/generated/autogenerated'
import type { SocialGroupsRefetch } from '../../../../hooks/useGetSocialGroups/useGetSocialGroups'

export type UpdateTeamVisibilityProps = {
    disabled: boolean
    socialGroup: SocialGroup
    refetchSocialGroups: SocialGroupsRefetch
}

const UpdateTeamVisibility: React.FC<UpdateTeamVisibilityProps> = ({
    disabled,
    socialGroup,
    refetchSocialGroups
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const [isPublicGroup, setIsPublicGroup] = useState(
        Boolean(socialGroup?.isPublic)
    )

    const [editSocialGroupMutation] = useEditSocialGroupMutation({
        variables: {
            updatedSocialGroup: {
                isPublic: isPublicGroup,
                name: String(socialGroup?.name),
                socialGroupId: socialGroup?.id
            }
        }
    })

    return (
        <Stack direction="row" gap={2} width={isMobile ? '100%' : 443}>
            <TeamVisibility
                isPublicGroup={isPublicGroup}
                setIsPublicGroup={setIsPublicGroup}
            />
            <Box>
                <SuccessLoadingButton
                    promise={async () => {
                        await editSocialGroupMutation()
                        await refetchSocialGroups()
                    }}
                    disabled={
                        disabled || socialGroup?.isPublic === isPublicGroup
                    }
                    fixWidth={true}
                    variant="outlined"
                    data-testid="save-button"
                    sx={{
                        maxHeight: 7
                    }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Save"
                            description="Save Button Text"
                        />
                    </CoreTypography>
                </SuccessLoadingButton>
            </Box>
        </Stack>
    )
}

export default memo(UpdateTeamVisibility)
