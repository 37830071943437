import { useMultiStepFormContext } from '../../contexts/withMultiStepForm/withMultiStepForm'
import { useEffect } from 'react'

type UseSignupFormDataProps<T> = {
    defaultValues?: T
}

const useSignupFormData = <T extends object>({
    defaultValues
}: UseSignupFormDataProps<T>) => {
    const { formData, updateFormData } = useMultiStepFormContext()
    const mergedFormData = { ...defaultValues, ...formData }

    const handleUpdateFormData = (value: Partial<T>) => {
        updateFormData(value)
    }

    useEffect(() => {
        if (defaultValues) {
            updateFormData(defaultValues)
        }
    }, [])

    return {
        formData: mergedFormData as T,
        updateFormData: handleUpdateFormData
    }
}

export default useSignupFormData
