import { Button } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement, useMemo } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import {
    SocialGroup,
    useDeleteSocialGroupMutation
} from '../../../../../graphql/generated/autogenerated'
import ConfirmAction from '../../../../elements/ConfirmAction/ConfirmAction'

const messages = defineMessages({
    areYouSure: {
        defaultMessage: 'Are you sure you want to delete “{socialGroup}”?',
        description:
            'description text for the delete social group confirmation pop up'
    },
    deleteThisTeam: {
        defaultMessage: 'Delete this team',
        description: 'button text for confirming deleting the social group'
    },
    cancel: {
        defaultMessage: 'Cancel',
        description: 'button text for cancelling deleting the social group'
    }
})

export type DeleteGroupProps = {
    socialGroup?: SocialGroup
    onDeleteGroup: () => void
    children?: ReactElement<any, any>
}

const DeleteGroup: React.FC<DeleteGroupProps> = ({
    socialGroup,
    onDeleteGroup,
    children
}) => {
    const intl = useIntl()

    const [deleteSocialGroup] = useDeleteSocialGroupMutation({
        variables: {
            socialGroupId: socialGroup?.id
        }
    })

    const renderButton = useMemo(() => {
        if (children) {
            return children
        } else {
            return (
                <Button
                    variant="text"
                    data-testid="delete-group-button"
                    color="error"
                    startIcon={<LeafIcon icon={'trash'} fontSize={'small'} />}
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        sx={{ textAlign: 'center' }}
                    >
                        <FormattedMessage
                            defaultMessage="Delete group"
                            description="button text for opening the are you sure you want to delete the group popup"
                        />
                    </CoreTypography>
                </Button>
            )
        }
    }, [children])

    return (
        <ConfirmAction
            confirmTitle={intl.formatMessage(messages.areYouSure, {
                socialGroup: socialGroup?.name
            })}
            confirmActionText={intl.formatMessage(messages.deleteThisTeam)}
            cancelActionText={intl.formatMessage(messages.cancel)}
            confirmAction={() =>
                deleteSocialGroup().then(() => {
                    onDeleteGroup()
                })
            }
        >
            {renderButton}
        </ConfirmAction>
    )
}

export default React.memo(DeleteGroup)
