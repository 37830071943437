import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SocialGroupsListWithProvider } from '../../../../../components/features/SocialGroups/SocialGroupsList'
import { SocialGroup } from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeSignUpProviderContext } from '../../../../../hooks/withChallengeCompanySignUp'
import { useChallengeSignUpModalContext } from '../../../../../hooks/withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import SwitchSignUpTypeButton from '../SwitchSignUpTypeButton/SwitchSignUpTypeButton'
import JoinTeamActionsHeader from './JoinTeamActionsHeader/JoinTeamActionsHeader'

const JOIN_SOCIAL_GROUP = 'joinSocialGroup'

const messages = defineMessages({
    nameDoesNotExistCreate: {
        defaultMessage:
            "This team doesn't exist. Try a different name or create this team.",
        description: 'team name does not exist description'
    },
    nameDoesNotExist: {
        defaultMessage: "This team doesn't exist. Try a different name.",
        description: 'team name does not exist description'
    }
})

const JoinTeam: React.FC = () => {
    const { formatMessage } = useIntl()
    const [empty, setEmpty] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState('')
    const {
        socialGroup,
        maxTeamSize,
        setSocialGroup,
        setSearchedTeamName,
        addDisable
    } = useCompanyChallengeSignUpProviderContext()
    const { excludeSocialGroups, isChangeParticipation } =
        useChallengeSignUpModalContext()

    useEffect(() => {
        addDisable(JOIN_SOCIAL_GROUP, !socialGroup || empty)
    }, [addDisable, socialGroup, empty])

    const onSetSearchTerm = useCallback(
        (searchTerm: string) => {
            setSearchTerm(searchTerm)
            setSearchedTeamName(searchTerm)
        },
        [setSearchedTeamName]
    )

    return (
        <Stack gap={2} display="flex" px={0.125} pb={0.125} overflow="auto">
            <JoinTeamActionsHeader
                isEmpty={empty}
                setSearchTerm={onSetSearchTerm}
            />
            <Box
                pb={1}
                px={0.125}
                pt={0.125}
                overflow="auto"
                data-testid="social-groups-wrapper"
            >
                <SocialGroupsListWithProvider
                    joinedSocialGroups={
                        socialGroup?.id ? [socialGroup?.id] : []
                    }
                    searchTerm={searchTerm}
                    addToJoinList={(socialGroup: SocialGroup) =>
                        setSocialGroup(socialGroup)
                    }
                    excludeSocialGroups={excludeSocialGroups}
                    joinGroupQueryActive={false}
                    activeById={socialGroup?.id}
                    disableNavigate={true}
                    maxCapacity={maxTeamSize}
                    isJoinList={true}
                    setEmpty={setEmpty}
                />
            </Box>
            {empty && (
                <Stack gap={1}>
                    {searchTerm?.length > 0 && (
                        <CoreTypography variant="body1">
                            {formatMessage(
                                isChangeParticipation
                                    ? messages.nameDoesNotExist
                                    : messages.nameDoesNotExistCreate
                            )}
                        </CoreTypography>
                    )}
                    {!isChangeParticipation && (
                        <Box>
                            <SwitchSignUpTypeButton
                                onSwitch={() => setSocialGroup(undefined)}
                            />
                        </Box>
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default memo(JoinTeam)
