import {
    ErrorBoundary,
    FallbackProps,
    FullAppFallback
} from '@thriveglobal/thrive-web-errors'
import React from 'react'
import { MFEFallback } from '../../../components/fallback/MFEFallback'
import PageFallback from '../../components/layout/PageFallback'
import { ReactNullValue } from '../../utils/nulls'

export enum BoundaryName {
    CHALLENGE = 'Challenge',
    CHALLENGE_INTRO = 'ChallengeIntro',
    CHALLENGE_ACTIVE = 'ChallengeActive',
    CHALLENGE_RECAP = 'ChallengeRecap'
}

export enum BoundaryType {
    PAGE = 'Page',
    FEATURE = 'Feature',
    COMPONENT = 'Component',
    MICRO_FRONTEND = 'MicroFrontend'
}

const EmptyFallback: React.ComponentType<FallbackProps> = () => ReactNullValue

export const withErrorBoundary = <P extends object>(
    boundaryName: BoundaryName,
    boundaryType: BoundaryType,
    Component: React.FunctionComponent<P>,
    fallbackComponent?: React.ComponentType<FallbackProps>
) => {
    const getFallbackComponent = (): React.ComponentType<FallbackProps> => {
        switch (boundaryType) {
            case BoundaryType.PAGE:
                return PageFallback
            case BoundaryType.FEATURE:
                return EmptyFallback
            case BoundaryType.COMPONENT:
                return EmptyFallback
            case BoundaryType.MICRO_FRONTEND:
                return MFEFallback
            default:
                return FullAppFallback
        }
    }
    return (props: P) => (
        <ErrorBoundary
            boundaryName={boundaryName}
            boundaryType={boundaryType}
            packageName={process.env.APP_NAME as string}
            packageOwner={process.env.PROJECT_OWNER as string}
            packageVersion={process.env.APP_VERSION as string}
            fallbackComponent={fallbackComponent ?? getFallbackComponent()}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
