import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import InterstitialNavigationContext, {
    InterstitialNavigationProviderValue
} from './interstitialNavigationContext'

export default function withInterstitialNavigationProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [activeKey, setActiveKey] = useState('')
        const [activeIndex, setActiveIndex] = useState(0)
        const [queuedNavigateKey, setQueuedNavigateKey] = useState<string>()
        const [keys, setKeys] = useState<string[]>([])
        const [subKeySteps, setSubKeySteps] = useState<{
            [key: string]: { current: number; max: number }
        }>({})
        const [close, setClose] = useState<() => void>(() => ({}))

        const navigateToStep = useCallback(
            (navigatingStep: number) => {
                const navigatingKey = keys[navigatingStep]

                if (navigatingStep < 0 || !navigatingKey) {
                    close()
                } else {
                    setActiveIndex(navigatingStep)
                    setActiveKey(navigatingKey)
                }
            },
            [keys, close]
        )

        const navigateToStepKey = useCallback(
            (key: string, subStep?: number) => {
                if (!!subStep && subStep >= 0) {
                    setSubKeySteps((subKeys) => ({
                        ...subKeys,
                        [key]: {
                            max: subKeys[key].max,
                            current: subStep
                        }
                    }))
                }

                navigateToStep(keys.indexOf(key))
            },
            [keys, navigateToStep]
        )

        const navigateToNextStep = useCallback(
            (forward: boolean) => {
                const nextStep = keys.indexOf(activeKey) + (forward ? 1 : -1)

                return navigateToStep(nextStep)
            },
            [keys, activeKey, navigateToStep]
        )

        const navigateToNextSubStep = useCallback(
            (stepKey: string, forward: boolean) => {
                const subStep = subKeySteps[stepKey]
                const nextStep = subStep?.current + (forward ? 1 : -1)

                if (nextStep >= subStep.max || nextStep < 0) {
                    navigateToNextStep(forward)
                } else {
                    setSubKeySteps({
                        ...subKeySteps,
                        [stepKey]: {
                            max: subStep.max,
                            current: nextStep
                        }
                    })
                }
            },
            [subKeySteps, navigateToNextStep]
        )

        const { next, prev } = useMemo(() => {
            const hasSubSteps = !!subKeySteps[activeKey]
            const navigateNext = (forward: boolean) =>
                hasSubSteps
                    ? navigateToNextSubStep(activeKey, forward)
                    : navigateToNextStep(forward)

            return {
                next: () => navigateNext(true),
                prev: () => navigateNext(false)
            }
        }, [subKeySteps, activeKey, navigateToNextSubStep, navigateToNextStep])

        useEffect(() => {
            if (activeIndex === 0 && keys.length > 0) {
                setActiveKey(keys[0])
                setActiveIndex(0)
            }
        }, [keys, activeIndex])

        const onSetSubKeys = useCallback(
            (subKeyPages: { [key: string]: number }) => {
                const subKeyStates: {
                    [key: string]: { max: number; current: number }
                } = {}
                subKeyPages &&
                    Object.keys(subKeyPages).forEach((key) => {
                        subKeyStates[key] = {
                            max: subKeyPages[key],
                            current: 0
                        }
                    })

                setSubKeySteps(subKeyStates)
            },
            []
        )

        const activeKeySubIndex = useMemo(
            () => (subKeySteps[activeKey] ? subKeySteps[activeKey].current : 0),
            [activeKey, subKeySteps]
        )

        useEffect(() => {
            // If we change the SignUpType in the middle of the sign up process and we want the process to start from a specific point
            // we want to queue up a navigate action that will trigger the next time the navigation keys are updated
            if (queuedNavigateKey && keys.includes(queuedNavigateKey)) {
                navigateToStepKey(queuedNavigateKey)
                setQueuedNavigateKey(undefined)
            }
        }, [queuedNavigateKey, keys, navigateToStepKey])

        // Add query when available
        const state = useMemo<InterstitialNavigationProviderValue>(
            () => ({
                keys,
                subKeySteps,
                activeKey,
                activeIndex,
                activeKeySubIndex,
                next,
                prev,
                close,
                navigateToKey: navigateToStepKey,
                navigateToStep: navigateToStep,
                queueNavigateToKey: setQueuedNavigateKey,
                setClose,
                setKeys,
                setSubKeySteps: onSetSubKeys
            }),
            [
                keys,
                subKeySteps,
                activeKey,
                activeIndex,
                activeKeySubIndex,
                next,
                prev,
                close,
                navigateToStepKey,
                navigateToStep,
                setQueuedNavigateKey,
                setClose,
                onSetSubKeys
            ]
        )

        return (
            <InterstitialNavigationContext.Provider value={state}>
                <Component {...props} />
            </InterstitialNavigationContext.Provider>
        )
    }
}
