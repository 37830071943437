import { Button, Divider, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    RecapWidget,
    RecapWidgetContent,
    RecapWidgetFooter,
    RecapWidgetHeader
} from '../RecapWidget'
import CompletedResetsDialog from './CompletedResetsDialog'
import type {
    ResetStats,
    ResetStatsDetail
} from '../../../../../graphql/generated/autogenerated'

import CompletedResetsCard from './CompletedResetsCard'
import CompletedResetsPlayer from './CompletedResetsPlayer'

type CompletedResetsProps = {
    resetStats: ResetStats
}

const CompletedResets = ({ resetStats }: CompletedResetsProps) => {
    const { formatNumber } = useIntl()
    const [isPlayerOpen, setIsPlayerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const { totalResets, reset } = useMemo(
        () => ({
            totalResets: resetStats?.total ?? 0,
            reset: resetStats?.resets?.[0]
        }),
        [resetStats]
    )

    return (
        <>
            <RecapWidget>
                <RecapWidgetHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Completed Resets"
                            description="Completed resets section title"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Taking just 60 seconds to reset from stress is key for well-being."
                            description="Completed resets section description"
                        />
                    }
                />
                <RecapWidgetContent>
                    <Stack gap={2} direction="row" alignItems="center">
                        <CoreTypography
                            variant="h3"
                            fontSize={60}
                            textAlign="left"
                            color="text.secondary"
                        >
                            {formatNumber(totalResets)}
                        </CoreTypography>
                        <CoreTypography variant="body2" color="primary.main">
                            <FormattedMessage
                                defaultMessage="Taking just 60 seconds to reset from stress is key for well-being."
                                description="reset usage description"
                            />
                        </CoreTypography>
                    </Stack>
                    <Divider />
                    <CompletedResetsCard
                        reset={reset as ResetStatsDetail}
                        onClick={() => setIsPlayerOpen(true)}
                    />
                </RecapWidgetContent>
                <RecapWidgetFooter
                    label={
                        <FormattedMessage
                            defaultMessage="View more Resets"
                            description="View more Resets button"
                        />
                    }
                    onClick={() => setIsDialogOpen(true)}
                />
            </RecapWidget>

            <CompletedResetsPlayer
                reset={reset as ResetStatsDetail}
                isOpen={isPlayerOpen}
                onClose={() => setIsPlayerOpen(false)}
            />
            <CompletedResetsDialog
                resetStats={resetStats}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    )
}

export default React.memo(CompletedResets)
