import { Card, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import SocialActivityFeed from '../../../../../shared/modules/social/components/SocialActivityFeed/SocialActivityFeed'
import withCommunitySocialGroupActivityProvider from '../../../../../shared/modules/social/hooks/useSocialActivityProvider/withCommunitySocialGroupActivityProvider'
import { useCompanyChallengeProviderContext } from '../../../../contexts/withCompanyChallengeProvider'
import { ChallengeType } from '../../../../../shared'

const ChallengeCommunityActivity: React.FC<JSX.IntrinsicAttributes> =
    withCommunitySocialGroupActivityProvider(() => {
        const { challenge } = useCompanyChallengeProviderContext()
        return (
            <Card elevation={0}>
                <Stack p={2} gap={2}>
                    <CoreTypography variant="overline" color="text.disabled">
                        <FormattedMessage
                            defaultMessage="Community Activity"
                            description="description for the challenge community activity card"
                        />
                    </CoreTypography>
                    <SocialActivityFeed
                        hideJoinedTeamMessage={
                            challenge?.challenge_type === ChallengeType.group
                        }
                    />
                </Stack>
            </Card>
        )
    })

export default memo(ChallengeCommunityActivity)
