import { Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDebounce } from '../../../../../hooks/useDebounce/useDebounce'
import Participant from './Participant'
import ParticipantSkeleton from './ParticipantSkeleton'
import type { UnifiedChallenge } from '../../../../../../graphql/generated/autogenerated'
import useGetSocialMembers from '../../../../../hooks/useGetSocialMembers/useGetSocialMembers'
import type { SocialGroupsValues } from '../../../../../hooks/useGetSocialGroups/useGetSocialGroups'
type ParticipantsListProps = {
    challenge: UnifiedChallenge
    socialGroups: SocialGroupsValues
}

const ParticipantsList: React.FC<ParticipantsListProps> = ({
    challenge,
    socialGroups: { mainCommunitySocialGroup, socialGroup, socialGroupsRefetch }
}) => {
    const [removedList, setRemovedList] = useState<String[]>([])
    const { userId } = useAppSelector((state) => state.user)
    const { actionOnTimeout: debounceRefetch } =
        useDebounce(socialGroupsRefetch)

    const isAdmin = useMemo(
        () =>
            challenge?.createdBy === userId ||
            socialGroup?.createdBy === userId,
        [userId, challenge, socialGroup]
    )

    const { socialMembers, refetch, loadingNext, hasMore, loading } =
        useGetSocialMembers({
            socialGroupId: socialGroup?.id
        })

    const totalParticipants = useMemo(
        () =>
            mainCommunitySocialGroup
                ? mainCommunitySocialGroup?.memberCount
                : socialGroup?.memberCount,
        [mainCommunitySocialGroup, socialGroup]
    )

    return (
        <Stack gap={1}>
            <CoreTypography variant="h5">
                <FormattedMessage
                    defaultMessage="Participants ({totalParticipants})"
                    description="participants total description"
                    values={{ totalParticipants }}
                />
            </CoreTypography>
            {isAdmin && (
                <CoreTypography variant="body1">
                    <FormattedMessage
                        defaultMessage="View and edit participants that have signed up for your Challenge."
                        description="participants total description"
                    />
                </CoreTypography>
            )}
            <Stack>
                {loading && !loadingNext
                    ? Array.from(
                          Array(
                              Number(totalParticipants) > 10
                                  ? 10
                                  : totalParticipants
                          )
                      ).map((_, index: number) => (
                          <Stack key={index}>
                              <ParticipantSkeleton />
                          </Stack>
                      ))
                    : socialMembers.map((participant) => (
                          <Stack key={participant.user.id}>
                              <Participant
                                  participant={participant}
                                  challenge={challenge}
                                  isAdmin={isAdmin}
                                  removed={removedList.includes(
                                      participant?.user?.id
                                  )}
                                  onRemoved={() => {
                                      debounceRefetch()
                                      setRemovedList((list) => [
                                          ...list,
                                          participant.user.id
                                      ])
                                  }}
                              />
                          </Stack>
                      ))}
                {hasMore && !loading && (
                    <LoadingButton
                        loading={loadingNext}
                        disabled={loadingNext}
                        fixWidth={true}
                        variant="text"
                        size="medium"
                        data-testid="load-more-members-button"
                        onClick={refetch}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Load more"
                                description="Button text that will load more posts on a community activity feed"
                            />
                        </CoreTypography>
                    </LoadingButton>
                )}
            </Stack>
        </Stack>
    )
}

export default memo(ParticipantsList)
