import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import {
    useGetTutorialWatchedStatusQuery,
    useSetTutorialWatchedStatusMutation
} from '../../../graphql/generated/autogenerated'

interface UseTutorialWatchedValues {
    tutorialWatched: boolean
    setTutorialWatched: () => void
    loading: boolean
    error: ApolloError | undefined
    refetch: () => void
}

const useTutorialWatched = (): UseTutorialWatchedValues => {
    const { data, loading, error, refetch } = useGetTutorialWatchedStatusQuery()

    const [setTutorialWatched] = useSetTutorialWatchedStatusMutation({
        variables: {
            status: true
        }
    })

    const tutorialWatched = useMemo(() => {
        return !!data?.unifiedChallenges.getTutorialWatchedStatus
    }, [data])

    return {
        tutorialWatched,
        setTutorialWatched,
        loading,
        error,
        refetch
    }
}

export default useTutorialWatched
