import { IconName } from '@fortawesome/fontawesome-common-types'
import { Avatar, Box, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'

export interface ChallengeIconInfoProps {
    icon: IconName
    info: JSX.Element
    clickable?: boolean
    onClick?: () => void
}

const ChallengeIconInfo: React.FC<ChallengeIconInfoProps> = ({
    icon,
    info,
    clickable,
    onClick
}) => {
    const theme = useTheme()

    return (
        <Stack direction="row" alignItems="center" gap={1.5}>
            <Box>
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        color: theme.palette.info.contrastText
                    }}
                >
                    <LeafIcon icon={icon} />
                </Avatar>
            </Box>
            {clickable ? (
                <Link href="javascript:void(0)" onClick={onClick}>
                    <CoreTypography
                        pr={6}
                        color="text.primary"
                        customVariant="form"
                    >
                        {info}
                    </CoreTypography>
                </Link>
            ) : (
                <CoreTypography
                    pr={6}
                    color="text.primary"
                    customVariant="form"
                >
                    {info}
                </CoreTypography>
            )}
        </Stack>
    )
}

export default memo(ChallengeIconInfo)
