import { CardActionArea, CardMedia, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    useTheme,
    withTruncatedText,
    LeafFixedMediaCard
} from '@thriveglobal/thrive-web-leafkit'
import { memo, useEffect, useRef } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useCheckLineCount from '../../../../../hooks/useCheckLineCount/useCheckLineCount'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import useTextTransform from '../../../../../hooks/useTextTransform/useTextTransform'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import ChallengeDuration from '../../../../elements/ChallengeDuration/ChallengeDuration'
import ChallengeTypeIcon from '../../../../elements/ChallengeTypeIcon/ChallengeTypeIcon'
import { alignProperty } from '@mui/material/styles/cssUtils'

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const messages = defineMessages({
    navigateTo: {
        defaultMessage: `{isGroup, select, 
            true {{challengeName} (group Challenge)}
            other {{challengeName}}}`,
        description:
            'text describing what challenge the user will navigate to and if it is a group Challenge'
    }
})

export interface ChallengeCardProps {
    id: string
    image?: string
    category?: string
    name: string
    description?: string
    duration: number
    challengeType: ChallengeType
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
    id,
    image,
    category,
    name,
    description,
    duration,
    challengeType
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const textRef = useRef(null)
    const { formatMessage } = useIntl()
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        challengeType: challengeType
    })
    const { toTitleCase } = useTextTransform()

    const singleLineTextHeight = 24
    const { checkLineCount, nameMultiLine } = useCheckLineCount(
        textRef,
        singleLineTextHeight
    )

    useEffect(() => checkLineCount, [checkLineCount])

    const renderChallengeTypeIcons = () => {
        return (
            <Stack
                position="absolute"
                direction="row"
                right={8}
                top={8}
                gap={0.5}
            >
                {challengeType === ChallengeType.group && (
                    <ChallengeTypeIcon challengeType={ChallengeType.journey} />
                )}
                <ChallengeTypeIcon challengeType={challengeType} />
            </Stack>
        )
    }

    return (
        <Stack position="relative">
            <LeafFixedMediaCard
                media={
                    <CardMedia
                        component="img"
                        height={isMobile ? '100%' : 119}
                        image={image ? image : imagePlaceholder}
                        alt=""
                    />
                }
                actionAreaProps={{
                    onClick: navigate,
                    'aria-label': formatMessage(messages.navigateTo, {
                        isGroup: challengeType === ChallengeType.group,
                        challengeName: name
                    })
                }}
                alignMedia="top"
            >
                <Stack
                    height={158}
                    direction={isMobile ? 'row-reverse' : 'column'}
                >
                    <Stack justifyContent="space-between" height="100%">
                        <Stack gap={isMobile ? 0.5 : 1}>
                            <Stack>
                                {category && (
                                    <CoreTypography variant="body2">
                                        {toTitleCase(category)}
                                    </CoreTypography>
                                )}
                                <div ref={textRef}>
                                    <TruncatedCoreTypography
                                        variant="h4"
                                        textLines={2}
                                    >
                                        {name}
                                    </TruncatedCoreTypography>
                                </div>
                            </Stack>
                            <Stack>
                                <TruncatedCoreTypography
                                    variant="body2"
                                    textLines={
                                        nameMultiLine || isMobile ? 2 : 3
                                    }
                                >
                                    {description}
                                </TruncatedCoreTypography>
                            </Stack>
                        </Stack>
                        <ChallengeDuration duration={duration} />
                    </Stack>
                </Stack>
            </LeafFixedMediaCard>
            {renderChallengeTypeIcons()}
        </Stack>
    )
}

export default memo(ChallengeCard)
