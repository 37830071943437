import { useAppSelector } from '@thriveglobal/thrive-web-core'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import startOfDay from 'date-fns/startOfDay'
import { useCallback } from 'react'

/**
 *
 * @returnsA function returning a date object in the timezone of the user's settings. If no timezone exists in the user's settings the default date object will be returned.
 */
export const useZonedDate = () => {
    const { timezone } = useAppSelector((state) => state.settings)

    const getZonedDate = useCallback(
        (date: Date = new Date()) =>
            timezone ? utcToZonedTime(date, timezone) : date,
        [timezone]
    )
    const getUTCDate = useCallback(
        (date: Date = new Date()) =>
            timezone ? zonedTimeToUtc(date, timezone) : date,
        [timezone]
    )
    const getStartOfDay = useCallback(
        (date: Date = new Date()) =>
            getUTCDate(startOfDay(getZonedDate(date))).setDate(date.getDate()),
        [getUTCDate, getZonedDate]
    )

    return {
        getZonedDate,
        getUTCDate,
        getStartOfDay
    }
}

export default useZonedDate
