import React from 'react'
import { useParams } from 'react-router-dom'
import {
    GroupChallengeType,
    type UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import IntroCreateChallengeDialog from '../IntroSignup/IntroSignupTemplateChallengeDialog/IntroCreateChallengeDialog'
import IntroJoinChallengeDialog from '../IntroSignup/IntroSignupTemplateChallengeDialog/IntroJoinChallengeDialog'
import IntroJoinCompanyChallengeDialog from '../IntroSignup/IntroSignupCompanyChallengeDialog'
import type { RefetchUnifiedChallengeAvailability } from '../../../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import useChallengeIntro from '../../../../hooks/useChallengeIntro/useChallengeIntro'

export type DialogType = 'create' | 'join' | 'joinCompany' | null

interface IntroDialogManagerProps {
    challenge: UnifiedChallenge
    isTemplate: boolean
    groupChallengeType?: GroupChallengeType
    activeDialog: DialogType
    onClose: () => void
    refetchUnifiedChallengeAvailability: RefetchUnifiedChallengeAvailability
}

const IntroDialogManager: React.FC<IntroDialogManagerProps> = ({
    challenge,
    isTemplate,
    groupChallengeType,
    activeDialog,
    onClose,
    refetchUnifiedChallengeAvailability
}) => {
    if (!activeDialog) return null

    return (
        <>
            {activeDialog === 'create' && (
                <IntroCreateChallengeDialog
                    open={true}
                    groupChallengeType={groupChallengeType}
                    onClose={onClose}
                    challenge={challenge}
                />
            )}
            {activeDialog === 'join' && (
                <IntroJoinChallengeDialog
                    open={true}
                    groupChallengeType={GroupChallengeType.Group}
                    onClose={onClose}
                    challenge={challenge}
                />
            )}
            {activeDialog === 'joinCompany' && (
                <IntroJoinCompanyChallengeDialog
                    open={true}
                    onClose={onClose}
                    refetchUnifiedChallengeAvailability={
                        refetchUnifiedChallengeAvailability
                    }
                    challenge={challenge}
                />
            )}
        </>
    )
}

export default IntroDialogManager
