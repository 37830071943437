import {
    Button,
    DialogActions,
    DialogContent,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessage, useIntl } from 'react-intl'

export const WHAT_ARE_ACTIVITY_POINTS_TITLE = defineMessage({
    defaultMessage: 'What are activity points?',
    description: 'What are activity points title'
})

export const WHAT_ARE_ACTIVITY_POINTS_MESSAGE = defineMessage({
    defaultMessage:
        'Activity points are calculated by combining your reported daily activities. For step and step-based activities, we calculate that 1 step = 1 activity point. For non-step activities (i.e. swimming), Thrive calculates points based on Metabolic Equivalent of Task (METS).',
    description: 'explanation of activity points'
})

export type WhatAreActivityPointsProps = {
    open: boolean
    onClose: () => void
}

const WhatAreActivityPoints: React.FC<WhatAreActivityPointsProps> = ({
    open,
    onClose
}) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={isSmallScreen}
            dialogTitle={
                <CoreTypography variant="h3">
                    <FormattedMessage
                        defaultMessage="What are activity points?"
                        description="What are activity points title"
                    />
                </CoreTypography>
            }
        >
            <DialogContent>
                <CoreTypography variant="body1" pt={1.5}>
                    {formatMessage(WHAT_ARE_ACTIVITY_POINTS_MESSAGE)}
                </CoreTypography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Close"
                            description="close popup button text"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(WhatAreActivityPoints)
