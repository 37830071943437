import { Card, Icon, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo, memo } from 'react'
import { ChallengeUserDailyGoal } from '../../../../../graphql/generated/autogenerated'
import EcoIcon from '../../../../../shared/components/icons/EcoIcon'

export interface TeamMemberProgressCardProps {
    name: string
    userDailyGoals: ChallengeUserDailyGoal[]
}

const TeamMemberProgressCard: React.FC<TeamMemberProgressCardProps> = ({
    name,
    userDailyGoals
}) => {
    const theme = useTheme()
    const allCompleted = useMemo(
        () => userDailyGoals.every((goal) => goal.completed),
        [userDailyGoals]
    )

    return (
        <Card
            sx={{ border: `1px solid ${theme.palette.grey[200]}` }}
            elevation={0}
        >
            <Stack
                p={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
            >
                <CoreTypography
                    variant="h5"
                    sx={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {name}
                </CoreTypography>
                {userDailyGoals?.length > 0 && (
                    <Stack direction="row" gap={1} alignItems="center">
                        {userDailyGoals.map((goal, index: number) =>
                            goal.completed ? (
                                <LeafIcon
                                    key={index}
                                    icon={'circle-check'}
                                    fontSize={'large'}
                                    iconStyle={'solid'}
                                    color={'success'}
                                />
                            ) : (
                                <LeafIcon
                                    key={index}
                                    icon={'circle-check'}
                                    fontSize={'large'}
                                    sx={{ color: theme.palette.grey[500] }}
                                />
                            )
                        )}
                        <Icon
                            component={EcoIcon}
                            fill={
                                allCompleted
                                    ? theme.palette.success.main
                                    : undefined
                            }
                        />
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}

export default memo(TeamMemberProgressCard)
