import { useIntl } from 'react-intl'
import { CardMedia, Stack } from '@mui/material'
import { LeafChip } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'

import ChallengeTypeIcon from '../../../../elements/ChallengeTypeIcon'
import {
    getChallengeDates,
    getChallengeType
} from '../../../../../utils/challenge'
import type { UnifiedChallenge } from '../../../../../../graphql/generated/autogenerated'

type CompletedChallengeCardMediaProps = {
    challenge: UnifiedChallenge
}

const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const MediaContainer = React.memo(
    ({ children }: { children: React.ReactNode }) => (
        <Stack position="relative">{children}</Stack>
    )
)

const OverlayContainer = React.memo(
    ({ children }: { children: React.ReactNode }) => (
        <Stack
            position="absolute"
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
            top={16}
            left={0}
            px={2}
        >
            {children}
        </Stack>
    )
)

const CompletedChallengeCardMedia = ({
    challenge
}: CompletedChallengeCardMediaProps) => {
    const { startDate, endDate } = getChallengeDates(challenge)
    const challengeType = getChallengeType(challenge)
    const { formatDate } = useIntl()

    const dateRange = useMemo(() => {
        const formattedStartDate = formatDate(startDate, {
            month: 'short',
            day: '2-digit'
        })
        const formattedEndDate = formatDate(endDate, {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        })
        return `${formattedStartDate} - ${formattedEndDate}`
    }, [startDate, endDate, formatDate])

    const thumbnailUrl = useMemo(
        () => challenge?.thumbnail ?? imagePlaceholder,
        [challenge?.thumbnail]
    )

    return (
        <MediaContainer>
            <CardMedia
                component="img"
                role="img"
                image={thumbnailUrl}
                alt=""
                height={125}
            />
            <OverlayContainer>
                <LeafChip color={'info'} label={dateRange} variant="filled" />
                <Stack direction="row">
                    <ChallengeTypeIcon
                        color="info"
                        challengeType={challengeType}
                    />
                </Stack>
            </OverlayContainer>
        </MediaContainer>
    )
}

export default React.memo(CompletedChallengeCardMedia)
