import { Box, Stack } from '@mui/material'
import React from 'react'

interface IconYAxisProps {
    icons: { [key: string]: React.ReactNode }
}

const IconYAxis: React.FC<IconYAxisProps> = ({ icons }) => {
    return (
        <Stack height="100%" justifyContent="space-between">
            {Object.keys(icons)
                ?.reverse()
                ?.map((key, index) => (
                    <Box key={index}>{icons[key]}</Box>
                ))}
        </Stack>
    )
}

export default IconYAxis
