import { Avo } from '@thriveglobal/thrive-web-tracking'
import { ComponentType, useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { SignUpTypes } from '../../enums/signUpType'
import { SocialGroup } from '../../graphql/generated/autogenerated'
import { ROUTES } from '../../routes'
import { useSocialActivityContext } from '../../shared/modules/social/hooks/useSocialActivityProvider/useSocialActivityContext'
import useGetMaxTeamSize from '../goalChallenges/useGetMaxTeamSize/useGetMaxTeamSize'
import { useHydrationProviderContext } from '../hydrationProviders/withHydrationProvider/useHydrationProviderContext'
import { useChallengeSignUpModalContext } from '../withChallengeSignUpModalProvider/useChallengeSignUpModalContext'
import { useCompanyTabControllerContext } from '../withCompanyTabController/useCompanyTabControllerContext'
import { useInterstitialNavigation } from '../withInterstitialNavigation/useInterstitialNavigation'
import CompanyChallengeSignUpContext, {
    CompanyChallengeSignUpValue
} from './companyChallengeSignUpContext'
import { stripBOM } from '../../shared/utils/bom'

export type MutationKeyType = {
    key?: string
    mutation: () => Promise<any>
    onMutationComplete?: (response: any) => boolean | { socialGroupId: string }
}

export default function withCompanyChallengeSignUpProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const history = useHistory()
        const { challengeId } = useParams<{
            challengeId: string
        }>()

        const [loading, setLoading] = useState<boolean>(false)
        const [error, setError] = useState<boolean>(false)
        const [showPrivacy, setShowPrivacy] = useState(false)
        const [teamName, setTeamName] = useState<string | undefined>('')
        const [isPublicGroup, setIsPublicGroup] = useState<boolean>(true)
        const [sleepHabit, setSleepHabit] = useState<number>()
        const [activityGoal, setActivityGoal] = useState<number>(0)
        const [searchedTeamName, setSearchedTeamName] = useState<
            string | undefined
        >('')
        const [socialGroup, setSocialGroup] = useState<SocialGroup>()
        // A little annoying but we can't set the mutation directly here or it will trigger straight away
        // to avoid this we wrap it inside an object
        const [mutationInfo, setMutationInfo] = useState<{
            [key: string]: {
                mutation: () => Promise<boolean>
                onMutationComplete?: (response: any) =>
                    | boolean
                    | {
                          socialGroupId: string
                      }
            }
        }>({})
        const [disableConditions, setDisableConditions] = useState<{
            [key: string]: boolean
        }>({})

        const { signUpType, isInvite, displayName, isChangeParticipation } =
            useChallengeSignUpModalContext()
        const { refetch: communityRefetch } = useSocialActivityContext()
        const { goalCount, intakeMeasurement } = useHydrationProviderContext()
        const { handleTabChange } = useCompanyTabControllerContext()
        const { close } = useInterstitialNavigation()

        const { maxTeamSize } = useGetMaxTeamSize({ challengeId })

        const clearMutation = useCallback(
            (key: string) =>
                setMutationInfo((mutationInfo) => ({
                    ...mutationInfo,
                    [key]: {
                        mutation: () => Promise.resolve(true),
                        onMutationComplete: () => true
                    }
                })),
            []
        )

        const clearDisables = useCallback(() => setDisableConditions({}), [])
        const clearMutations = useCallback(() => setMutationInfo({}), [])

        const triggerMutation = useCallback(
            (
                key: string,
                mutation: () => Promise<boolean>,
                onMutationComplete?: (response: any) =>
                    | boolean
                    | {
                          socialGroupId: string
                      }
            ) =>
                mutation()
                    .then((response) => {
                        const success = onMutationComplete?.(response)

                        if (success) {
                            clearMutation(key)
                        } else {
                            setError(true)
                        }

                        return success
                    })
                    .catch((er) => {
                        setError(true)
                        return false
                    }),
            [clearMutation]
        )

        const triggerMutations = useCallback(
            async (
                externalPromise = (response: any) => Promise.resolve(true)
            ) => {
                setLoading(true)
                setError(false)
                const responses: any[] = []
                const responsesSuccessful = () =>
                    !responses.some((response) => !response)

                // If the mutation completed successfully we clear the mutation so we don't trigger it a second time
                for (const key of Object.keys(mutationInfo)) {
                    if (responsesSuccessful()) {
                        try {
                            const response = await triggerMutation(
                                key,
                                mutationInfo[key].mutation,
                                mutationInfo[key].onMutationComplete
                            )
                            responses.push(response)
                        } catch (err) {
                            setError(true)
                            setLoading(false)
                        }
                    }
                }

                if (responsesSuccessful()) {
                    setMutationInfo({})

                    try {
                        await externalPromise(responses)
                    } catch (err) {
                        setError(true)
                        setLoading(false)
                    }
                }

                return Promise.resolve(responsesSuccessful()).finally(() =>
                    setLoading(false)
                )
            },
            [mutationInfo, triggerMutation]
        )

        const addDisable = useCallback(
            (key: string, disable: boolean) =>
                setDisableConditions((disableConditions) => ({
                    ...disableConditions,
                    [key]: disable
                })),
            []
        )

        // We pass the mutation with a key so that if we have any unintended entires they will always overwrite instead of adding a new mutation
        const addMutation = useCallback(
            ({
                key = 'default',
                mutation,
                onMutationComplete = () => true
            }: MutationKeyType) => {
                setMutationInfo((mutationInfo) => ({
                    ...mutationInfo,
                    [key]: {
                        mutation,
                        onMutationComplete
                    }
                }))
            },
            []
        )

        const disabled = useMemo(
            () =>
                Object.values(disableConditions).some(
                    (disableCondition) => disableCondition
                ),
            [disableConditions]
        )

        const onSignUpComplete = useCallback(
            (responses?: (boolean | { socialGroupId: string })[]) => {
                const socialGroupId = (
                    responses as { socialGroupId: string }[]
                )?.find((response) => response?.socialGroupId)?.socialGroupId

                Avo.challengeStarted({
                    activityType: 'challenge_started',
                    challengeId: stripBOM(challengeId),
                    challengeTheme: 'company',
                    challengeType: 'company',
                    dayNumber: null,
                    featureType: 'challenge',
                    inviteSource: isInvite ? 'invite' : 'signup',
                    tabName: 'Home',
                    teamId: socialGroupId,
                    teamType:
                        signUpType === SignUpTypes.solo ? 'individual' : 'team'
                })
                //navigation is required regardless of what page the user is on as they can arrive through the invite flow and we want to pull them out of that flow afterwards
                history.push(
                    generatePath(ROUTES.COMPANY_CHALLENGE_HOME, {
                        challengeId: stripBOM(challengeId),
                        socialGroupId:
                            signUpType === SignUpTypes.solo
                                ? undefined
                                : socialGroupId
                    })
                )

                communityRefetch()
                handleTabChange(null, 0)
                close()
            },
            [
                close,
                history,
                isInvite,
                signUpType,
                challengeId,
                handleTabChange,
                communityRefetch
            ]
        )

        const state = useMemo<CompanyChallengeSignUpValue>(
            () => ({
                loading,
                error,
                disabled,
                teamName,
                searchedTeamName,
                showPrivacy,
                socialGroup,
                isInvite,
                maxTeamSize,
                isPublicGroup,
                sleepHabit,
                activityGoal,
                setActivityGoal,
                setSleepHabit,
                setIsPublicGroup,
                setLoading,
                setSocialGroup,
                setShowPrivacy,
                setTeamName,
                setSearchedTeamName,
                addDisable,
                clearDisables,
                triggerMutations,
                addMutation,
                clearMutations,
                onSignUpComplete
            }),
            [
                loading,
                error,
                disabled,
                teamName,
                searchedTeamName,
                showPrivacy,
                socialGroup,
                isInvite,
                isPublicGroup,
                sleepHabit,
                activityGoal,
                setActivityGoal,
                setSleepHabit,
                setIsPublicGroup,
                setLoading,
                setSocialGroup,
                setShowPrivacy,
                setTeamName,
                setSearchedTeamName,
                addDisable,
                clearDisables,
                triggerMutations,
                addMutation,
                clearMutations,
                onSignUpComplete,
                maxTeamSize
            ]
        )

        return (
            <CompanyChallengeSignUpContext.Provider value={state}>
                <Component {...props} />
            </CompanyChallengeSignUpContext.Provider>
        )
    }
}
