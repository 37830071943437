import {
    CoreTypography,
    LeafConfirmationDialog,
    LeafIcon,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useRemoveGroupChallengeMutation } from '../../../../../graphql/generated/autogenerated'
import { Stack } from '@mui/material'

const messages = defineMessages({
    yourSure: {
        defaultMessage: 'Are you sure you want to leave?',
        description:
            'Question whether a user is certain they want to leave a challenge'
    },
    loseProgress: {
        defaultMessage:
            'All current progress for this challenge will be lost, and all participants will be removed from the challenge.',
        description: 'Description on what will happen on leaving a challenge'
    },
    deleteChallenge: {
        defaultMessage: 'Delete Challenge',
        description: 'leave challenge button text'
    },
    cancel: {
        defaultMessage: 'Cancel',
        description: 'cancel confirmation dialog button text'
    }
})

export interface RemoveChallengeButtonProps {
    challengeId: string
    disabled?: boolean
    onRemoving?: (removing: boolean) => void
    onRemove?: () => void
}

const RemoveChallengeButton: React.FC<RemoveChallengeButtonProps> = ({
    challengeId,
    disabled,
    onRemoving,
    onRemove
}) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [removeGroupChallengeMutation] = useRemoveGroupChallengeMutation({
        variables: {
            challengeId
        }
    })

    const onRemoveAction = useCallback(() => {
        onRemoving?.(true)
        setLoading(true)
        setOpen(false)

        removeGroupChallengeMutation()
            .then(onRemove)
            .finally(() => {
                setLoading(false)
                onRemoving?.(false)
            })
    }, [removeGroupChallengeMutation, onRemove, onRemoving])

    return (
        <>
            <LeafConfirmationDialog
                open={open}
                fullWidth
                maxWidth="sm"
                dialogTitle=""
                dialogContent={
                    <Stack
                        width="100%"
                        gap={1}
                        alignItems="center"
                        textAlign="center"
                    >
                        <LeafIcon
                            icon={'triangle-exclamation'}
                            fontSize={'large'}
                        />
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Are you sure?"
                                description="confirm remove challenge title"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="All current progress for this Challenge will be lost, and all participants will be removed from the Challenge."
                                description="Description on what will happen on leaving a challenge"
                            />
                        </CoreTypography>
                    </Stack>
                }
                onClose={() => setOpen(false)}
                primaryButtonOnClick={onRemoveAction}
                primaryButtonText={formatMessage(messages.deleteChallenge)}
                secondaryButtonOnClick={() => setOpen(false)}
                secondaryButtonText={formatMessage(messages.cancel)}
            />
            <LoadingButton
                loading={loading}
                disabled={loading || disabled}
                fixWidth={true}
                onClick={() => setOpen(true)}
                data-testid="remove-challenge-button"
            >
                <CoreTypography customVariant="buttonNormal">
                    {formatMessage(messages.deleteChallenge)}
                </CoreTypography>
            </LoadingButton>
        </>
    )
}

export default memo(RemoveChallengeButton)
