import React from 'react'
import BasePlant, { CommunityPlantProps } from './basePlant'

const CommunityPlant11: React.FC<CommunityPlantProps> = ({
    colorLayer1 = '#133338',
    colorLayer2 = '#2AA382',
    colorLayer3 = '#285E68',
    colorLayer4 = '#377F8A',
    colorLayer5 = '#365D67',
    useSimple,
    base
}) => (
    <BasePlant useSimple={useSimple} base={base}>
        <g>
            <path
                d="M42.5449 61.4537L49.9449 73.4537L50.405 72.1537L41.4949 58.0337L42.5449 61.4537Z"
                fill={colorLayer1}
            />
            <path
                d="M61.4449 70.6537L60.6749 70.0237C59.1849 71.8337 55.6549 75.7537 52.9149 77.6437C51.9449 78.3137 50.6149 77.5937 50.6149 76.4137V74.7437C50.6149 68.1837 59.315 56.7837 59.405 56.6737L58.6149 56.0637C58.3249 56.4437 52.8949 63.5537 50.6149 69.9237V64.9337H49.6149V81.0437C48.6849 79.5337 46.895 77.2337 43.455 74.1537C37.165 68.5337 33.1449 55.0337 33.1049 54.8937L32.145 55.1737C32.315 55.7337 36.2949 69.0837 42.7949 74.8937C48.6649 80.1537 49.5449 83.0137 49.6249 83.3137V85.7937H50.6249V81.9637C50.6249 80.8037 51.245 79.7137 52.275 79.1937C55.855 77.3837 60.805 71.4637 61.455 70.6737L61.4449 70.6537Z"
                fill={colorLayer5}
            />
            <path
                d="M15.155 4.83371L32.9849 58.6337L37.275 52.9237C42.315 46.2237 42.0649 36.9337 36.6749 30.5037L15.155 4.82372V4.83371Z"
                fill={colorLayer1}
            />
            <path
                d="M76.525 7.83371L64.075 63.1237L59.245 57.8637C53.575 51.6937 52.905 42.4237 57.635 35.4937L76.525 7.82372V7.83371Z"
                fill={colorLayer1}
            />
            <path
                d="M25.2349 2.4837L45.455 30.5437L47.2449 26.9837C49.3449 22.8037 47.6549 17.6837 43.1649 14.6437L25.2349 2.4837Z"
                fill={colorLayer2}
            />
            <path
                d="M25.2349 2.4837L41.2549 29.4937L42.905 25.8937C44.835 21.6737 43.6049 16.6737 39.9349 13.8337L25.2449 2.4837H25.2349Z"
                fill={colorLayer4}
            />
            <path
                d="M65.515 49.7537C65.515 60.6337 58.6249 64.9237 50.1249 64.9237C41.6249 64.9237 34.7349 60.6237 34.7349 49.7537C34.7349 38.8737 50.1249 0.783707 50.1249 0.783707C50.1249 0.783707 65.515 38.8737 65.515 49.7537Z"
                fill={colorLayer2}
            />
            <path
                d="M65.515 49.7537C65.515 38.8737 50.1249 0.783707 50.1249 0.783707V64.9237C58.6249 64.9237 65.515 60.6237 65.515 49.7537Z"
                fill={colorLayer3}
            />
            <path
                d="M75.6149 71.0637C70.4549 78.3137 63.8249 77.9037 58.1649 73.8737C52.5049 69.8437 49.9449 63.7137 55.1049 56.4637C60.2649 49.2137 88.585 31.1437 88.585 31.1437C88.585 31.1437 80.7749 63.8237 75.6149 71.0637Z"
                fill={colorLayer2}
            />
            <path
                d="M75.6149 71.0637C80.7749 63.8137 88.585 31.1437 88.585 31.1437L58.1649 73.8737C63.8249 77.9037 70.4549 78.3137 75.6149 71.0637Z"
                fill={colorLayer3}
            />
            <path
                d="M1.33496 37.1237L41.1149 77.5037L42.4349 70.4837C43.9849 62.2437 39.6649 54.0237 31.9949 50.6437L1.33496 37.1237Z"
                fill={colorLayer4}
            />
        </g>
    </BasePlant>
)

export default CommunityPlant11
