import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { memo } from 'react'

const CompleteDailyGoals: React.FC<SvgIconProps> = ({ ...props }) => {
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox={'0 0 160 161'}
            sx={{ width: 160, height: 161, fill: 'transparent' }}
            {...props}
        >
            <g filter="url(#filter0_d_951_164141)">
                <circle cx="69.6115" cy="65.7224" r="49.3889" fill="white" />
                <circle
                    cx="69.6115"
                    cy="65.7224"
                    r="48.8889"
                    stroke="#253858"
                    stroke-opacity="0.16"
                />
            </g>
            <path
                d="M81.2099 51.6147L69.1083 63.7141C68.9544 63.8682 68.7716 63.9905 68.5705 64.0739C68.3693 64.1573 68.1536 64.2002 67.9358 64.2002C67.718 64.2002 67.5023 64.1573 67.3011 64.0739C67.0999 63.9905 66.9171 63.8682 66.7632 63.7141L62.958 59.911"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M71.2441 77.4746V93.4035"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M85.3262 94.0674H57.1631"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.8243 55.9066C92.8243 61.6274 90.5517 67.1139 86.5065 71.1591C82.4613 75.2044 76.9748 77.4769 71.254 77.4769C65.5331 77.4769 60.0466 75.2044 56.0014 71.1591C51.9562 67.1139 49.6836 61.6274 49.6836 55.9066V47.6081C49.6836 46.7279 50.0332 45.8839 50.6556 45.2615C51.2779 44.6392 52.122 44.2896 53.0021 44.2896H89.5058C90.3859 44.2896 91.23 44.6392 91.8523 45.2615C92.4747 45.8839 92.8243 46.7279 92.8243 47.6081V55.9066Z"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M93.34 47.2783H98.466C99.346 47.2783 100.19 47.628 100.813 48.2503C101.435 48.8726 101.784 49.7167 101.784 50.5968V56.3069C101.784 62.6342 98.977 68.0832 92.7736 69.3243L88.5215 70.1739"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.1682 47.2783H44.0422C43.162 47.2783 42.3179 47.628 41.6956 48.2503C41.0733 48.8726 40.7236 49.7167 40.7236 50.5968V56.3069C40.7236 62.6342 43.5311 68.0832 49.7345 69.3243L53.9866 70.1739"
                stroke="#A68CD6"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="110.445" cy="24.1113" r="21" fill="#A68CD6" />
            <path
                d="M109.305 23.5166H109.935C110.102 23.5166 110.262 23.583 110.381 23.7012C110.499 23.8194 110.565 23.9797 110.565 24.1469V28.5587"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M109.305 28.5586H111.826"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M118.759 15.7432H102.372C101.676 15.7432 101.111 16.3075 101.111 17.0037V30.8694C101.111 31.5656 101.676 32.1299 102.372 32.1299H118.759C119.455 32.1299 120.019 31.5656 120.019 30.8694V17.0037C120.019 16.3075 119.455 15.7432 118.759 15.7432Z"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M101.111 20.7852H120.019"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M106.153 17.634V13.2222"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M114.977 17.634V13.2222"
                stroke="white"
                stroke-width="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_951_164141"
                    x="1.22266"
                    y="1.3335"
                    width="158.777"
                    height="158.778"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="11" dy="15" />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.65098 0 0 0 0 0.54902 0 0 0 0 0.839216 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_951_164141"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_951_164141"
                        result="shape"
                    />
                </filter>
            </defs>
        </SvgIcon>
    )
}

export default memo(CompleteDailyGoals)
