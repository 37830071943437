// External imports
import { Button } from '@mui/material'
import { LeafFlexMediaCard } from '@thriveglobal/thrive-web-leafkit'
import { useState } from 'react'

// Internal imports
import { CHALLENGE_SIGNUP_STEPS } from '../../../../pages/ChallengeSignup'
import {
    GroupChallengeType,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import ParticipationStep from './components/ParticipationStep'
import ChallengeSignupSkeleton from '../SkeletonLoader'
export interface BodyProps {
    step: number
    setStep: (step: number) => void
    challenge?: UnifiedChallenge
    attemptExit: () => void
    loading: boolean
}

const Body = ({
    step,
    setStep,
    challenge,
    attemptExit,
    loading
}: BodyProps) => {
    const [displayName, setDisplayName] = useState('')
    const [nameIsValid, setNameIsValid] = useState(true)
    const [participationType, setParticipationType] = useState<
        GroupChallengeType | undefined
    >(GroupChallengeType.Group)

    const renderStep = () => {
        if (loading) return <ChallengeSignupSkeleton />

        switch (step) {
            case CHALLENGE_SIGNUP_STEPS.PARTICIPATION_SELECTION:
                return (
                    <ParticipationStep
                        challenge={challenge}
                        setStep={setStep}
                        displayName={displayName}
                        setDisplayName={setDisplayName}
                        nameIsValid={nameIsValid}
                        setNameIsValid={setNameIsValid}
                        participationType={participationType}
                        setParticipationType={setParticipationType}
                        attemptExit={attemptExit}
                    />
                )
            case CHALLENGE_SIGNUP_STEPS.TEAM_SELECTION:
                return (
                    <div>
                        <div>Team Selection</div>
                        <Button
                            onClick={() =>
                                setStep(
                                    CHALLENGE_SIGNUP_STEPS.PARTICIPATION_SELECTION
                                )
                            }
                        >
                            Back
                        </Button>
                    </div>
                )
            case CHALLENGE_SIGNUP_STEPS.CONSENT_AND_CONFIRMATION:
                return (
                    <div>
                        Consent and Confirmation
                        <Button
                            onClick={() =>
                                setStep(
                                    CHALLENGE_SIGNUP_STEPS.PARTICIPATION_SELECTION
                                )
                            }
                        >
                            Back
                        </Button>
                    </div>
                )
            default:
                return null
        }
    }
    return (
        <LeafFlexMediaCard sx={{ padding: 1 }}>
            {renderStep()}
        </LeafFlexMediaCard>
    )
}

export default Body
