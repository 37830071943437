import { Stack } from '@mui/material'
import { BarDatum } from '@nivo/bar'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo } from 'react'

export interface BarYAxisProps {
    data: BarDatum[]
}

const numMaxTicks = 6

const BarYAxis: React.FC<BarYAxisProps> = ({ data }) => {
    const maxTotal = useMemo(
        () =>
            data.reduce((acc: number, curr: any) => {
                const currValue = parseInt(curr.value, 10) // Convert value to a number
                if (currValue > acc) {
                    return currValue
                }
                return acc
            }, 0),
        [data]
    )

    return (
        <Stack
            height="100%"
            justifyContent="space-between"
            direction="column-reverse"
        >
            {calculateEvenlySpacedTicks(maxTotal, numMaxTicks).map((tick) => (
                <Stack
                    key={tick}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <CoreTypography
                        variant="body2"
                        component="p"
                        fontSize={12}
                        width="100%"
                        textAlign="center"
                    >
                        {tick}
                    </CoreTypography>
                </Stack>
            ))}
        </Stack>
    )
}

const calculateEvenlySpacedTicks = (maxValue: number, numMaxTicks: number) => {
    if (maxValue === 0) return [0]
    const numUniqueValues = Math.min(maxValue + 1, numMaxTicks)
    const step = Math.max(1, Math.floor(maxValue / (numUniqueValues - 1)))
    return Array.from({ length: numUniqueValues }, (_, i) => i * step)
}

export default memo(BarYAxis)
