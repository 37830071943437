import { Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { MeasureUnit } from '../../../../../../../graphql/generated/autogenerated'
import RadioList from '../../../../../../../shared/components/elements/RadioList/RadioList'

export const measurementMessages = defineMessages({
    milliliter: {
        defaultMessage: 'Milliliters (mL)',
        description:
            'Milliliter, measurement values selected from radio buttons'
    },
    ounce: {
        defaultMessage: 'Ounces (oz)',
        description: 'Ounce, measurement values selected from radio buttons'
    }
})

export type DailyGoalMeasurementProps = {
    measurement: MeasureUnit
    setMeasurement: (measurement: MeasureUnit) => void
}

const DailyGoalMeasurement: React.FC<DailyGoalMeasurementProps> = ({
    measurement,
    setMeasurement
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { formatMessage } = useIntl()

    return (
        <Card variant="outlined" sx={{ height: '100%' }}>
            <Stack gap={1} p={2}>
                <CoreTypography variant="h5">
                    <FormattedMessage
                        defaultMessage="Select your preferred unit of measure"
                        description="update your daily goal hydration measurement title"
                    />
                </CoreTypography>
                <RadioList
                    onChange={(measurement) =>
                        setMeasurement(measurement as MeasureUnit)
                    }
                    defaultValue={measurement}
                    excludeSpacing={true}
                    row={!isMobile}
                    list={[
                        {
                            value: MeasureUnit.Ml,
                            title: formatMessage(measurementMessages.milliliter)
                        },
                        {
                            value: MeasureUnit.Oz,
                            title: formatMessage(measurementMessages.ounce)
                        }
                    ]}
                />
            </Stack>
        </Card>
    )
}

export default memo(DailyGoalMeasurement)
