import { Box, StandardTextFieldProps, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export interface TextFieldNewInputProps extends StandardTextFieldProps {
    onNewInput: (newValue: string) => void
}

const TextFieldNewInput: React.FC<TextFieldNewInputProps> = ({
    onNewInput,
    ...props
}) => {
    const [currentInput, setCurrentInput] = useState('')

    const handleInputChange = useCallback(
        (value: string) => {
            setCurrentInput(value)
            onNewInput(value !== props.defaultValue ? value : '')
        },
        [props, onNewInput]
    )

    useEffect(() => {
        if (currentInput === props.defaultValue) {
            setCurrentInput('')
            onNewInput('')
        }
    }, [props.defaultValue, currentInput, onNewInput])

    return (
        <Box display="flex">
            <TextField
                data-testid="text-field-new-input"
                onChange={(event) => handleInputChange(event.target.value)}
                {...(props.disabled && { variant: 'filled' })}
                {...props}
            />
        </Box>
    )
}

export default React.memo(TextFieldNewInput)
