import { Card, Collapse, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { GQLNull } from '../../../../../shared/utils/Nulls'

export type ConfirmFieldType = {
    title: string
    value: string | GQLNull
    invalidText?: string
}

const ConfirmField: React.FC<ConfirmFieldType> = ({
    title,
    value,
    invalidText
}) => {
    const { palette } = useTheme()

    return (
        <Stack gap={1}>
            <Card
                elevation={0}
                sx={{
                    bgcolor: palette.grey[100],
                    ...(!!invalidText && {
                        bgcolor: palette.error.light
                    })
                }}
            >
                <Stack gap={1} p={2}>
                    <CoreTypography variant="overline" color="text.primary">
                        {title}
                    </CoreTypography>
                    <CoreTypography variant="body1">{value}</CoreTypography>
                </Stack>
            </Card>
            <Collapse in={!!invalidText}>
                <CoreTypography variant="caption" color="error.main">
                    {invalidText}
                </CoreTypography>
            </Collapse>
        </Stack>
    )
}

export default memo(ConfirmField)
