import { Box, Button, Card, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { GroupChallengeType } from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    individually: {
        defaultMessage: 'Individually',
        description: 'individual text'
    },
    withAGroup: {
        defaultMessage: 'With a Group',
        description: 'group text'
    },
    makeItPersonal: {
        defaultMessage:
            'Make it personal and take this Challenge on by yourself.',
        description: 'individual description'
    },
    createAGroup: {
        defaultMessage:
            'Create a Group Challenge and invite your team to join in on the fun.',
        description: 'group description'
    },
    createAPersonalChallenge: {
        defaultMessage: 'Create a Personal Challenge',
        description: 'create personal challenge button text'
    },
    createAGroupChallenge: {
        defaultMessage: 'Create a Group Challenge',
        description: 'create group challenge button text'
    }
})

export type ParticipationCardProps = {
    type: GroupChallengeType
    onSelect?: () => void
}

const ParticipationCard: React.FC<ParticipationCardProps> = ({
    type,
    onSelect
}) => {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Card variant="outlined">
            <Stack p={2} gap={2}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <LeafIcon
                        icon={
                            type === GroupChallengeType.Personal
                                ? 'user'
                                : 'users'
                        }
                        iconStyle="solid"
                        fontSize="small"
                    />
                    <CoreTypography variant="overline" color="text.secondary">
                        {type === GroupChallengeType.Personal
                            ? formatMessage(messages.individually)
                            : formatMessage(messages.withAGroup)}
                    </CoreTypography>
                </Stack>
                <CoreTypography
                    variant="h5"
                    color="text.secondary"
                    pr={!isMobile ? 8 : 0}
                >
                    {type === GroupChallengeType.Personal
                        ? formatMessage(messages.makeItPersonal)
                        : formatMessage(messages.createAGroup)}
                </CoreTypography>
                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onSelect}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {type === GroupChallengeType.Personal
                                ? formatMessage(
                                      messages.createAPersonalChallenge
                                  )
                                : formatMessage(messages.createAGroupChallenge)}
                        </CoreTypography>
                    </Button>
                </Box>
            </Stack>
        </Card>
    )
}

export default ParticipationCard
