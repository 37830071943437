import { Card, Stack } from '@mui/material'
import { Datum, Serie } from '@nivo/line'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useZonedDate from '../../../../../hooks/useZonedDateTime/useZonedDateTime'
import LineChart from '../../../elements/Charts/LineChart'
import ViewMoreDialog from '../../Recap/ViewMoreDialog'
import type {
    UnifiedChallenge,
    UnifiedChallengesQuery
} from '../../../../../graphql/generated/autogenerated'
export type ActivityRecapDialogProps = {
    open: boolean
    onClose: () => void
    challenge: UnifiedChallenge
    data: Pick<
        UnifiedChallengesQuery,
        'getChallengeStats' | 'getSoloPlayerRank'
    >
}

const messages = defineMessages({
    day: {
        defaultMessage: 'Day',
        description: 'day legend label'
    },
    activityPoints: {
        defaultMessage: 'Points',
        description: 'activity legend label'
    }
})

const ActivityRecapDialog = ({
    challenge,
    data,
    open,
    onClose
}: ActivityRecapDialogProps) => {
    const { getZonedDate } = useZonedDate()
    const { formatMessage } = useIntl()

    const activityStats = data?.getChallengeStats?.activityStats

    const mappedActivityData = useMemo<Serie[]>(() => {
        const challengeStartDate = getZonedDate(
            parseISO(challenge?.companyChallengeStartDay)
        )

        const days = Array.from(Array(challenge?.duration)).map(
            (_, index) =>
                ({
                    x: index + 1,
                    y: 0
                } as Datum)
        )
        activityStats?.dailyIndividualStats?.forEach((stat) => {
            const daysSinceStart = differenceInDays(
                getZonedDate(new Date(stat.year, stat.month - 1, stat.day)),
                challengeStartDate
            )

            const day = days[daysSinceStart]

            if (day) {
                day.y = stat.steps
            }
        })

        return [
            {
                id: 'activity',
                data: days
            }
        ]
    }, [
        challenge?.companyChallengeStartDay,
        challenge?.duration,
        activityStats?.dailyIndividualStats,
        getZonedDate
    ])

    const legends = useMemo(
        () => ({
            x: formatMessage(messages.day),
            y: formatMessage(messages.activityPoints)
        }),
        [formatMessage]
    )

    return (
        <ViewMoreDialog open={open} onClose={onClose}>
            <Stack gap={4.75}>
                <CoreTypography variant="h3" color="text.primary">
                    <FormattedMessage
                        defaultMessage="Here’s a look at your daily activity over the course of the Challenge."
                        description="activity dialog title"
                    />
                </CoreTypography>
                <Card>
                    <Stack p={2} gap={3}>
                        <CoreTypography
                            variant="overline"
                            color="text.disabled"
                        >
                            <FormattedMessage
                                defaultMessage="Your activity points"
                                description="activity card title"
                            />
                        </CoreTypography>
                        <Stack width="100%" height={500}>
                            <LineChart
                                data={mappedActivityData}
                                legends={legends}
                            />
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </ViewMoreDialog>
    )
}

export default memo(ActivityRecapDialog)
