import {
    CoreTypography,
    LeafConfirmationDialog,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useLeaveChallengeMutation } from '../../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    yourSure: {
        defaultMessage: 'Are you sure you want to leave?',
        description:
            'Question whether a user is certain they want to leave a challenge'
    },
    loseProgress: {
        defaultMessage: 'You will lose all progress on this Challenge',
        description: 'Description on what will happen on leaving a challenge'
    },
    leaveChallenge: {
        defaultMessage: 'Leave Challenge',
        description: 'leave challenge button text'
    },
    cancel: {
        defaultMessage: 'Cancel',
        description: 'cancel confirmation dialog button text'
    }
})

export interface LeaveChallengeButtonProps {
    userChallengeActivityId: string
    description?: string
    disabled?: boolean
    onLeaving?: (leaving: boolean) => void
    onLeave?: () => void
    refetchChallenge: () => Promise<any>
}

const LeaveChallengeButton: React.FC<LeaveChallengeButtonProps> = ({
    userChallengeActivityId,
    description,
    disabled,
    onLeaving,
    onLeave,
    refetchChallenge
}) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [leaveChallengeMutation] = useLeaveChallengeMutation({
        variables: {
            userChallengeActivityId
        }
    })

    const onLeaveAction = useCallback(() => {
        onLeaving?.(true)
        setLoading(true)
        setOpen(false)

        leaveChallengeMutation()
            .then(refetchChallenge)
            .then(onLeave)
            .finally(() => {
                setLoading(false)
                onLeaving?.(false)
            })
    }, [leaveChallengeMutation, refetchChallenge, onLeave, onLeaving])

    return (
        <>
            <LeafConfirmationDialog
                open={open}
                fullWidth
                maxWidth="xs"
                dialogContent={
                    description
                        ? description
                        : formatMessage(messages.loseProgress)
                }
                dialogTitle={formatMessage(messages.yourSure)}
                onClose={() => setOpen(false)}
                primaryButtonOnClick={onLeaveAction}
                primaryButtonText={formatMessage(messages.leaveChallenge)}
                secondaryButtonOnClick={() => setOpen(false)}
                secondaryButtonText={formatMessage(messages.cancel)}
            />
            <LoadingButton
                loading={loading}
                disabled={loading || disabled}
                fixWidth={true}
                onClick={() => setOpen(true)}
                data-testid="leave-challenge-button"
            >
                <CoreTypography customVariant="buttonNormal">
                    {formatMessage(messages.leaveChallenge)}
                </CoreTypography>
            </LoadingButton>
        </>
    )
}

export default memo(LeaveChallengeButton)
