import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useEffect, useMemo } from 'react'
import { SocialGroup } from '../../../../graphql/generated/autogenerated'
import SocialGroupItem from './SocialGroupItem/SocialGroupItem'

export type SocialGroupsListProps = {
    userSocialGroups?: SocialGroup[]
    allSocialGroups?: SocialGroup[]
    joinedSocialGroups?: string[]
    excludeSocialGroups?: string[]
    joinGroupQueryActive: boolean
    activeById?: string
    searchTerm: string
    disableNavigate?: boolean
    maxCapacity?: number
    isJoinList?: boolean
    addToJoinList: (socialGroup: SocialGroup, join: boolean) => void
    setEmpty?: (empty: boolean) => void
}

export type hasJoinedSocialGroup = {
    hasJoinedGroup: boolean
    socialGroup: SocialGroup
}

const SocialGroupsList: React.FC<SocialGroupsListProps> = ({
    userSocialGroups = [],
    allSocialGroups = [],
    joinedSocialGroups = [],
    excludeSocialGroups = [],
    joinGroupQueryActive,
    activeById,
    searchTerm,
    disableNavigate,
    maxCapacity,
    isJoinList = false,
    addToJoinList,
    setEmpty
}) => {
    const { userId } = useAppSelector((state) => state.user)
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))

    const socialListMapped: hasJoinedSocialGroup[] = useMemo(
        () =>
            allSocialGroups?.reduce((filtered, socialGroup) => {
                if (!excludeSocialGroups.includes(socialGroup.id)) {
                    filtered.push({
                        hasJoinedGroup: userSocialGroups?.some(
                            (userSocialGroup) =>
                                userSocialGroup.id === socialGroup.id
                        ),
                        socialGroup
                    })
                }

                return filtered
            }, [] as hasJoinedSocialGroup[]),
        [allSocialGroups, userSocialGroups, excludeSocialGroups]
    )

    const sortByMembers = (
        a: hasJoinedSocialGroup,
        b: hasJoinedSocialGroup
    ): number => {
        const hasJoinedGroupA = b.hasJoinedGroup
        const hasJoinedGroupB = a.hasJoinedGroup
        const bothJoined = hasJoinedGroupB && hasJoinedGroupA
        const anyJoined = hasJoinedGroupA || hasJoinedGroupB

        if (
            (hasJoinedGroupA && !hasJoinedGroupB) ||
            (bothJoined &&
                (a?.socialGroup?.memberCount ?? -1) <
                    (b?.socialGroup?.memberCount ?? -1)) ||
            (!anyJoined &&
                (a?.socialGroup?.memberCount ?? -1) <
                    (b?.socialGroup?.memberCount ?? -1))
        ) {
            return 1
        }

        if (
            (!hasJoinedGroupA && hasJoinedGroupB) ||
            (bothJoined &&
                (a?.socialGroup?.memberCount ?? -1) >
                    (b?.socialGroup?.memberCount ?? -1)) ||
            (!anyJoined &&
                (a?.socialGroup?.memberCount ?? -1) >
                    (b?.socialGroup?.memberCount ?? -1))
        ) {
            return -1
        }

        return 0
    }

    const filteredList = useMemo(() => {
        return socialListMapped.filter((slm) =>
            slm.socialGroup.name
                ?.toLowerCase()
                .includes(searchTerm?.toLowerCase())
        )
    }, [socialListMapped, searchTerm])

    useEffect(() => {
        setEmpty?.(filteredList.length === 0)
    }, [filteredList.length, setEmpty])

    if (filteredList?.length === 0) return <></>

    return (
        <Stack>
            <Grid
                container
                data-testid="social-groups-list"
                id="social-groups-list"
                role="listbox"
                spacing={isMobile ? 2 : 3}
            >
                {socialListMapped
                    ?.slice(0)
                    ?.sort(sortByMembers)
                    ?.map((socialGroup, index: number) => {
                        const visibility = socialGroup.socialGroup.name
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase())

                        return (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                md={6}
                                display={visibility ? 'block' : 'none'}
                            >
                                <SocialGroupItem
                                    socialGroup={socialGroup.socialGroup}
                                    userId={userId}
                                    isJoinList={isJoinList}
                                    hasJoinedGroup={socialGroup.hasJoinedGroup}
                                    hasAddedGroup={joinedSocialGroups?.includes(
                                        socialGroup.socialGroup.id
                                    )}
                                    addToJoinList={(join: boolean) =>
                                        addToJoinList(
                                            socialGroup.socialGroup,
                                            join
                                        )
                                    }
                                    disable={
                                        joinGroupQueryActive ||
                                        (!!activeById &&
                                            socialGroup.socialGroup.id !==
                                                activeById)
                                    }
                                    disableNavigate={disableNavigate}
                                    maxCapacity={maxCapacity}
                                />
                            </Grid>
                        )
                    })}
            </Grid>
        </Stack>
    )
}

export default SocialGroupsList
