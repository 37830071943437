import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useState, useMemo, useCallback } from 'react'

import CompletedChallengeGrid from '../../../components/features/Completed/CompletedChallengeGrid'
import ChallengesEmptyState from '../../../components/features/Shared/ChallengesEmptyState'
import ChallengesFilter, {
    ChallengeFilterSelection
} from '../../../components/features/Shared/ChallengesFilter'

import { CHALLENGES_ROUTES } from '../../../constants'

import useGetChallenges from '../../../hooks/useGetChallenges'
import type { UnifiedChallengeParticipation } from '../../../../graphql/generated/autogenerated'

type JourneyFilter = string | undefined

const CompletedChallenges = () => {
    const [filter, setFilter] = useState<JourneyFilter>(undefined)
    const { data, loading } = useGetChallenges({ inProgress: false })
    const navigate = useCrossAppNavigation()

    const noData =
        !data?.unifiedChallenges?.unifiedUserChallenges?.length && !loading

    const sortChallengesByDate = (
        challenges: UnifiedChallengeParticipation[] | undefined
    ) => {
        if (!challenges) return []

        return [...challenges].sort((a, b) => {
            const getDate = (challenge: UnifiedChallengeParticipation) =>
                new Date(
                    challenge.participation[0]?.completedOn ||
                        challenge.participation[0]?.expiredOn ||
                        0
                ).getTime()

            return getDate(b) - getDate(a)
        })
    }

    const sortedData = sortChallengesByDate(
        data?.unifiedChallenges
            ?.unifiedUserChallenges as UnifiedChallengeParticipation[]
    )

    const filteredData = useMemo(
        () =>
            filter
                ? sortedData.filter(
                      (challenge) =>
                          challenge.challenge.journeys?.[0]?.name === filter
                  )
                : sortedData,
        [filter, sortedData]
    )

    const handleFilterChange = useCallback(
        (values: ChallengeFilterSelection) => {
            setFilter(values.topic)
        },
        []
    )

    if (loading) {
        return (
            <Stack gap={4}>
                <Typography variant="h3">
                    <FormattedMessage
                        defaultMessage="Completed"
                        description="Completed Challenges tab title"
                    />
                </Typography>
                <ChallengesFilter
                    onChange={(values) => setFilter(values.topic)}
                />
                <CompletedChallengeGrid loading={loading} data={[]} />
            </Stack>
        )
    }

    if (noData) {
        return (
            <ChallengesEmptyState
                title={
                    <FormattedMessage
                        defaultMessage="No completed Challenges... yet!"
                        description="No completed challenges title"
                    />
                }
                description={
                    <FormattedMessage
                        defaultMessage="Join a Challenge on the Discover page."
                        description="No completed challenges description"
                    />
                }
                ctaLabel={
                    <FormattedMessage
                        defaultMessage="Discover Challenges"
                        description="Start a challenge button label"
                    />
                }
                ctaOnClick={() =>
                    navigate(CHALLENGES_ROUTES.DISCOVER_CHALLENGES)
                }
            />
        )
    }

    return (
        <Stack gap={4}>
            <Typography variant="h3">
                <FormattedMessage
                    defaultMessage="Completed"
                    description="Completed Challenges tab title"
                />
            </Typography>
            <ChallengesFilter onChange={handleFilterChange} />
            <CompletedChallengeGrid
                loading={loading}
                data={filteredData as UnifiedChallengeParticipation[]}
            />
        </Stack>
    )
}

export default CompletedChallenges
