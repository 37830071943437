import { useGetChallengeStatsQuery } from '../../../graphql/generated/autogenerated'

/**
 * Custom hook to get challenge statistics.
 *
 * @param {string} challengeId - The ID of the challenge.
 * @returns {Object} An object containing loading state, total earned points, and total completed days.
 */
const useGetChallengeStats = (challengeId: string) => {
    const { data, loading } = useGetChallengeStatsQuery({
        variables: {
            challengeId: challengeId
        }
    })

    return {
        loading,
        totalEarnedPoints: data?.retail.getUserCommunityChallengePlants.length,
        totalCompletedDays:
            data?.unifiedChallenges.communityChallengeTotalDailyGoalsCompleted
    }
}

export default useGetChallengeStats
