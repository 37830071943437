import { generatePath } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'

import CompletedChallengeCard from '../CompletedChallengeCard'
import CompletedChallengeGridSkeleton from './CompletedChallengeGridSkeleton'

import { CHALLENGES_ROUTES } from '../../../../constants'
import type { UnifiedChallengeParticipation } from '../../../../../graphql/generated/autogenerated'

type ChallengeGridProps = {
    loading: boolean
    data: UnifiedChallengeParticipation[]
}

const CompletedChallengeGrid = ({ data, loading }: ChallengeGridProps) => {
    const navigate = useCrossAppNavigation()

    if (loading) {
        return <CompletedChallengeGridSkeleton count={9} />
    }
    return (
        <Grid container spacing={4}>
            {data?.map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item.challenge.id}>
                    <CompletedChallengeCard
                        challenge={item.challenge}
                        navigateToChallenge={() => {
                            navigate(
                                generatePath(CHALLENGES_ROUTES.CHALLENGE, {
                                    challengeId: item.challenge.id
                                })
                            )
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default CompletedChallengeGrid
