import { Icon, Link, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

type RecapGoalProps = {
    text: string
    ctaText?: string
    ctaClick?: () => void
}

const RecapGoal: React.FC<RecapGoalProps> = ({ text, ctaText, ctaClick }) => {
    return (
        <Stack direction="row" alignItems="center" gap={1.5}>
            <LeafIcon icon={'circle-check'} color={'disabled'} />
            <CoreTypography variant="body1" component="p" fontSize={16}>
                {text}
            </CoreTypography>
            {ctaText && !!ctaClick && (
                <Link onClick={ctaClick} href={'javascript:void(0);'}>
                    <CoreTypography variant="body2">{ctaText}</CoreTypography>
                </Link>
            )}
        </Stack>
    )
}

export default RecapGoal
