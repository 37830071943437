import { useContext } from 'react'
import CompanyChallengeSocialGroupProviderContext, {
    CompanyChallengeSocialGroupProviderContextValue
} from './CompanyChallengeSocialGroupProviderContext'

export const useCompanyChallengeSocialGroupContext =
    (): CompanyChallengeSocialGroupProviderContextValue => {
        const context = useContext(CompanyChallengeSocialGroupProviderContext)

        return context
    }
