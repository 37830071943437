import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactElement } from 'react'
import Dirt from './dirt'
import DirtSimplified from './dirtSimplified'

export interface BasePlantProps extends SvgIconProps {
    excludeBase?: boolean
    useSimple?: boolean
    base?: JSX.Element
    children: ReactElement<any, any>
}

export interface CommunityPlantProps {
    colorLayer1?: string
    colorLayer2?: string
    colorLayer3?: string
    colorLayer4?: string
    colorLayer5?: string
    colorLayer6?: string
    useSimple?: boolean
    base?: JSX.Element
}

const BasePlant: React.FC<BasePlantProps> = ({
    excludeBase,
    useSimple,
    base,
    children,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill="none"
        viewBox="-10 -4 115 115"
        preserveAspectRatio="xMaxYMax slice"
        sx={{ height: '100%', width: '100%' }}
        {...props}
    >
        {base}
        {!excludeBase && !base && (useSimple ? <DirtSimplified /> : <Dirt />)}
        {children}
    </svg>
)

export default BasePlant
